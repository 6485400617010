
	import Messages from '~/app/utils/globals/Messages'
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import NuxtDropzone from 'nuxt-dropzone'
	import 'nuxt-dropzone/dropzone.css'
	import { getLastModificationDate } from '~/app/utils/libs/FileUtil'
	import { FileUploadSettings } from '~/app/domain/assets/FileUpload'

	@Component({
		components: {
			NuxtDropzone,
		},
	})
	export default class Dropzone extends Vue {
		@Prop({ default: 'Remove' }) removeFileText
		@Prop({ default: null }) label: string
		@Prop({ default: null }) nodeId: string
		@Prop({ type: Number, default: undefined }) maxFileSizeMb: number
		@Prop({ type: Boolean, default: false }) removeSizeLimit: boolean
		@Prop({ default: true }) title: boolean
		@Prop({ default: null }) placeholder: string
		@Prop({ type: Function, default: null }) uploadFunction: any
		@Prop({ default: null }) acceptedFiles: string
		@Prop({ default: null }) associatedWith: string
		@Prop({ default: null }) dropText: string
		@Prop({ type: Boolean, default: true }) resetOnMount: boolean
		@Prop({ default: false, type: Boolean }) notifyUpload: boolean
		@Prop({ default: null, type: Number }) maxFiles: number

		get options() {
			return {
				url: '/',
				useCustomSlot: true,
				thumbnailMethod: 'crop',
				addRemoveLinks: true,
				dictRemoveFile: this.removeFileText,
				thumbnailWidth: 120,
				thumbnailHeight: 120,
				autoQueue: false,
				maxFiles: this.maxFiles,
				acceptedFiles: this.acceptedFiles,
			}
		}

		instance: any = null
		count = 0
		errorMessage = null

		mounted() {
			// Everything is mounted and you can access the dropzone instance
			this.instance = (this.$refs.mainDz as any).dropzone
			if (this.resetOnMount) this.$emit('changed', [])
		}

		getQueued() {
			return this.files
		}

		reset() {
			this.instance.removeAllFiles(true)
			this.files = []
		}

		get allowedFileSize() {
			return this.maxFileSizeMb ? 1024 ** 2 * this.maxFileSizeMb : (FileUploadSettings.allowFileSize as number)
		}

		// event emitted on new file
		files: File[] = []
		fileCount: number = 0
		uploadingCount: number = 0
		uploadedCount: number = 0
		async filesAdded(files) {
			for (const f of files) {
				if (!this.removeSizeLimit && f.size > this.allowedFileSize) {
					await this.$store.dispatch(
						'snackbar/error',
						this.$t('errors.maxFileSizeExceeded', {
							maxFileSize: `${this.allowedFileSize / 1024 ** 2}mb`,
						}),
					)
					this.reset()
					return
				}
			}

			// control the file size
			const fNum = files.length
			this.uploadingCount += fNum
			this.uploadedCount = 0
			let reset = true
			const toWait = []
			for (const file of files || []) {
				this.files.push(file)
				if (this.uploadFunction) {
					const p = await this.uploadFunction(file)
					toWait.push(p)
				} else if (!this.nodeId) {
					// add to the list
					this.fileCount += file.length
					// this.files.concat(rFiles)
					this.$emit('filesUpdated', this.fileCount)
					this.$emit('changed', this.getQueued())
					reset = false
				} else {
					let lastResponse = null
					try {
						// send it straight, then remove
						const fb = new FormData()
						fb.set('node', this.nodeId)
						fb.append('attachment', file)
						if (this.associatedWith) fb.set('associatedWith', this.associatedWith)
						fb.append('fileModificationDate', getLastModificationDate(file).toISOString())
						fb.set('notify', this.notifyUpload ? 'true' : 'false')
						lastResponse = await this.$axios.$post('etg24:internal:command:content.uploadattachment', fb)
						this.$emit('filesUploaded', lastResponse)
					} catch (e) {
						await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
					}
				}
				if (this.uploadedCount < this.uploadingCount - 1) this.uploadedCount++
			}
			if (reset) this.reset()
			await Promise.all(toWait)
			this.uploadingCount -= fNum
		}

		filesRemoved(file) {
			this.fileCount--
			const i = this.files.indexOf(file)
			if (i >= 0) this.files.splice(i, 1)
			this.$emit('filesUpdated', this.fileCount)
			this.$emit('changed', this.getQueued())
		}
	}
