
	import { Component, Prop, Vue, Model, Watch } from 'nuxt-property-decorator'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import CompanyCard from '~/components/domain/company/CompanyCard.vue'
	import { objectSearchKeys } from '~/app/domain/property/Object'
	import ObjectCard from '~/components/domain/object/ObjectCard.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import Image from '~/app/utils/globals/Image'

	@Component({
		components: { BasicDialog, ObjectCard, CompanyCard, SearchLayout },
	})
	export default class ObjectPicker extends Vue {
		// keep the visibility synced
		@Model('change', { type: Boolean }) visible: boolean
		@Prop({ required: true }) objects: Object[]
		@Prop({ default: null }) title: string
		@Prop({ type: Array, default: () => [] }) selected
		@Prop({ type: Boolean, default: true }) resetAfterSend

		Image = Image
		localVisible = false

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
			if (this.localVisible && this.selected.length) {
				this.selectedObjects = this.selected
			}
		}

		/**
		 * Company filtering
		 */
		searchText: string = ''
		get filteredObjects() {
			let filtered = this.objects
			if (this.searchText) {
				filtered =
					search(filtered, this.searchText, {
						fuzzy: true,
						sort: true,
						// @ts-ignore
						keys: objectSearchKeys,
					}) || []
			}
			return filtered
		}

		selectedObjects: string[] = []
		selectObject(object) {
			if (!this.selectedObjects.includes(object)) {
				this.selectedObjects.push(object)
			} else {
				this.selectedObjects = this.selectedObjects.filter((c) => {
					// @ts-ignore
					return c.id !== object.id
				})
			}
		}

		sendSelected() {
			this.$emit('selected', this.selectedObjects)
			if (this.resetAfterSend) {
				this.selectedObjects = []
			}
			this.localVisible = false
		}

		toggleAll() {
			// @ts-ignore
			this.selectedObjects = !this.allSelected ? this.objects : []
		}

		get allSelected() {
			return this.selectedObjects.length === this.objects.length
		}
	}
