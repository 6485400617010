import sort, { Collation, SortOrder } from '~/app/common/filters/iterator/Sort'

interface Item {
	id: string
}

interface State {
	initialized: boolean
	processing: boolean
	items: { [key: string]: Item }
	timestamp: number
}

interface ItemStoreGetterConfig {
	lifetime: number
	sortIteratee?: string
	sortOrder?: SortOrder
	sortCollation?: Collation
}

export const itemStoreGetters = (config: ItemStoreGetterConfig) => ({
	isClean: (state: State, getters) => () => getters.isInitialized && state.timestamp >= Date.now() - config.lifetime,

	_sort(state, getters) {
		if (!config.sortIteratee) {
			return (items) => items
		}

		return (items) => sort(items, config.sortIteratee, config.sortOrder || 'asc', config.sortCollation || 'natural')
	},

	isInitialized: (state: State, getters) => state.initialized,

	isProcessing: (state: State, getters) => state.processing,

	all: (state: State, getters) => getters._sort(Object.values(state.items)),

	byId: (state: State, getters) => (id) => state.items[id],
})

interface ItemStoreMutationConfig {
	sortIteratee?: string
	sortOrder?: SortOrder
}

export const itemStoreMutations = (config: ItemStoreMutationConfig) => ({
	setInitialized(state: State, initialized: boolean): void {
		state.initialized = initialized
	},

	setItem(state: State, item: Item): void {
		state.items = { ...state.items, [item.id]: item }
	},

	setItems(state: State, items: Item[]): void {
		const newState = {}
		items.forEach((item) => (newState[item.id] = item))
		state.items = newState
		state.timestamp = Date.now()
	},

	addItem(state: State, itemToAdd: Item): void {
		state.items = { ...state.items, [itemToAdd.id]: itemToAdd }
	},

	addItems(state: State, itemsToAdd: Item[]): void {
		const newState = { ...state.items }
		itemsToAdd.forEach((itemToAdd) => (newState[itemToAdd.id] = itemToAdd))
		state.items = newState
	},

	removeItem(state: State, id: string): void {
		const newState = { ...state.items }
		delete newState[id] // we can mutate this new object safely before we put it in the state
		state.items = newState
	},

	removeItems(state: State, ids: string[]): void {
		const newState = { ...state.items }
		ids.forEach((id) => delete newState[id]) // we can mutate this new object safely before we put it in the state
		state.items = newState
	},

	updateItem(state: State, updatedItem: Item): void {
		state.items = { ...state.items, [updatedItem.id]: updatedItem }
	},

	updateItems(state: State, updatedItems: Item[]): void {
		const newState = { ...state.items }
		updatedItems.forEach((updatedItem) => (newState[updatedItem.id] = updatedItem))
		state.items = newState
	},
})

interface ItemStoreActionConfig {}

export const itemStoreActions = (config: ItemStoreActionConfig) => ({})
