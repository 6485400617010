import deepEqual from './deepEqual'

type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends (infer U)[]
		? DeepPartial<U>[]
		: T[P] extends readonly (infer U)[]
		? readonly DeepPartial<U>[]
		: DeepPartial<T[P]>
}

/**
 * Returns the difference between two objects or arrays
 * @param obj1 - The first object or array
 * @param obj2 - The second object or array
 * @returns - The difference between the two objects or arrays
 * @example
 * ```ts
 * diff({ a: 1 }, { a: 2 }) // { a: 2 }
 * diff([1], [2]) // [1, 2]
 * ```
 * @example
 * ```ts
 * const obj1 = { a: 1, b: 2 }
 * const obj2 = { a: 1, b: 3 }
 * diff(obj1, obj2) // { b: 3 }
 * ```
 */
function diff<T extends unknown[]>(obj1: T, obj2: T): T
function diff<T extends object>(obj1: T, obj2: T): DeepPartial<T>
function diff<T extends object | unknown[]>(obj1: T, obj2: T) {
	if (Array.isArray(obj1) && Array.isArray(obj2)) {
		const result: Partial<T>[] = []
		obj1.forEach((item1) => {
			if (!obj2.some((item2) => deepEqual(item1, item2))) {
				result.push(item1 as Partial<T>)
			}
		})
		obj2.forEach((item2) => {
			if (!obj1.some((item1) => deepEqual(item2, item1))) {
				result.push(item2 as Partial<T>)
			}
		})
		return result
	} else {
		const result: DeepPartial<T> = {}
		const keys = Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)])) as (keyof T)[]

		keys.forEach((key) => {
			const obj1Key = obj1[key]
			const obj2Key = obj2[key]
			if (Array.isArray(obj1Key) && Array.isArray(obj2Key)) {
				const difference = diff(obj1Key, obj2Key)
				if (difference.length) {
					result[key] = difference as DeepPartial<T>[keyof T]
				}
			} else if (
				typeof obj1Key === 'object' &&
				obj1Key !== null &&
				typeof obj2Key === 'object' &&
				obj2Key !== null
			) {
				const difference = diff(obj1Key, obj2Key)
				if (Object.keys(difference).length) {
					result[key] = difference as DeepPartial<T>[keyof T]
				}
			} else if (obj1Key !== obj2Key) {
				result[key] = obj2Key as DeepPartial<T>[keyof T]
			}
		})

		return result
	}
}

export default diff
