import MarkdownIt from 'markdown-it';
var handlePlugin = function handlePlugin(plugin) {
  return plugin.default || plugin;
};
export default (function (_ref, inject) {
  var app = _ref.app;
  var md = new MarkdownIt('default', {
    "linkify": true,
    "breaks": true,
    "html": true
  });
  inject('md', md);
});