
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Process } from '~/app/domain/content/Process'
	import AttachmentsLine from '~/components/domain/attachments/AttachmentsLine.vue'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import Date from '~/components/utils/Date.vue'
	import ContextVisibility from '~/components/utils/ContextVisibility.vue'
	import { Node } from '~/app/domain/content/Common'
	import ContextLocation from '~/components/utils/ContextLocation.vue'
	import html2text from '~/app/common/filters/text/Html2text'
	import ContextEmployee from '~/components/utils/ContextEmployee.vue'
	import ContextGeneric from '~/components/utils/ContextGeneric.vue'
	import PersistedContractSelectionSummary from '~/components/domain/contract/PersistedContractSelectionSummary.vue'

	@Component({
		components: {
			PersistedContractSelectionSummary,
			ContextGeneric,
			ContextEmployee,
			ContextLocation,
			ContextVisibility,
			Date,
			UserLine,
			CardLargeContainer,
			AttachmentsLine,
		},
	})
	export default class ProcessCard extends Vue {
		@Model('change', { required: true }) value: Process
		@Prop({ default: null }) to: string
		@Prop({ default: false }) backOffice: boolean
		@Prop({ default: false }) simple: boolean
		@Prop({ default: '100%' }) width: string
		@Prop({ default: null }) nodes: Node[]

		Image = Image

		get latestUpdate() {
			const updateCount = this.value.updates.length
			if (updateCount > 0) {
				return this.value.updates[updateCount - 1]
			} else {
				return null
			}
		}

		html2text = html2text
	}
