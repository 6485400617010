
	import { Component, Vue, Watch } from 'nuxt-property-decorator'

	@Component({})
	export default class EtgApp extends Vue {
		/**
		 * Dragging logic
		 */
		dragging = false
		count = 0
		get window() {
			return window
		}

		@Watch('window', { immediate: true }) windowUpdated() {
			// detect global drag events
			if (window) {
				window.ondragenter = () => {
					this.count++
					if (this.count > 0) {
						this.dragging = true
					}
				}

				window.ondragleave = () => {
					this.count--
					if (this.count === 0) {
						this.dragging = false
					}
				}

				window.ondrop = () => {
					console.log('drop')
				}
			}
		}
	}
