
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'

	@Component({
		components: { UserAvatar },
	})
	export default class UserMenu extends Vue {
		@Prop({ default: true }) dark: boolean

		get user() {
			return this.$auth.loggedIn ? this.$auth.user : null
		}
	}
