import { render, staticRenderFns } from "./ConversationDisplay.vue?vue&type=template&id=582a60bc&scoped=true"
import script from "./ConversationDisplay.vue?vue&type=script&setup=true&lang=ts"
export * from "./ConversationDisplay.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582a60bc",
  null
  
)

export default component.exports