
	import Messages from '~/app/utils/globals/Messages'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import { Faq } from '~/app/domain/content/Faq'
	import ContentLocationAction from '~/components/domain/location/ContentLocationAction.vue'

	@Component({
		components: {
			ContentLocationAction,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
		},
	})
	export default class FaqActions extends Vue {
		@Model('change', { required: true }) faq: Faq
		@Prop({ default: false }) dark: boolean
		@Prop({ type: Array, default: () => [] }) nodes: Node[]

		get actions() {
			return [
				{
					icon: 'fal fa-pencil',
					callback: () => {
						this.$emit('changePage', 'editFaq')
					},
					attributes: {
						'data-qa': 'faq-actions:edit-faq',
					},
				},
				{
					callback: () => {
						this.deleteDialog = true
					},
					icon: 'fal fa-trash',
					color: 'error',
					attributes: {
						'data-qa': 'faq-actions:delete-faq',
					},
				},
			]
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 1),
					value: r,
				}
			})
		}

		/**
		 * Delete logic
		 */
		deleteDialog: boolean = false
		async deleteFaq() {
			try {
				await this.$axios.$post('etg24:internal:command:content.delete', {
					ids: [this.faq.id],
				})
				this.$emit('deleted')
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		deleteText: string = ''
	}
