
	import { Component, Prop, Vue, Model, Watch } from 'nuxt-property-decorator'

	interface AppendText {
		tooltip?: string
		text: string
	}

	@Component({
		components: {},
	})
	export default class SearchInput extends Vue {
		@Model('change', { required: true }) text: string
		@Prop({ default: 80 }) height: number
		@Prop({ default: null }) placeholder: string
		@Prop({ default: false }) loading: boolean
		@Prop({ default: false }) autofocus: boolean
		@Prop({ default: false }) noResult: boolean
		@Prop({ default: false }) disabled: false
		@Prop({ default: false }) appendText: AppendText

		localText: string = ''
		@Watch('text', { immediate: true })
		updateText() {
			if (this.localText !== this.text) this.localText = this.text
		}

		@Watch('localText')
		updateLocalText() {
			this.$emit('change', this.localText)
		}

		@Watch('disabled')
		focusWhenEnabled(newValue: boolean, prevValue: boolean) {
			if (!newValue && prevValue && this.autofocus) {
				setTimeout(this.focus, 50)
			}
		}

		focus() {
			;(this.$refs as any).searchInput.focus()
		}

		get isFocused() {
			return (this.$refs as any).searchInput?.isFocused
		}
	}
