
	import { Vue, Component, Prop, Watch, Model } from 'nuxt-property-decorator'
	import { resolveApiLink } from '~/app/utils/apiLinks'
	import { FileAsset } from '~/app/domain/assets/File'
	import RemoteAssetRenderer from '~/components/asset-renderers/RemoteAssetRenderer.vue'
	import { downloadUrlAsFile } from '~/app/utils/libs/FileUtil'

	interface FileConfig {
		file: FileAsset
		endpoint?: string
		queryParameters?: { [key: string]: string | boolean | number }
	}

	@Component({
		components: {
			RemoteAssetRenderer,
		},
	})
	export default class FilePreview extends Vue {
		// the files that can be previewed by this component
		@Prop({ required: true }) elements: FileConfig[]

		get fileConfig(): FileConfig {
			return this.elements.find((element) => element.file.id === this.$route.query.file)
		}

		get fileExtension() {
			return this.fileConfig?.file.name.split('.').pop()
		}

		get downloadLink() {
			if (this.fileConfig.file.url) {
				return `${this.fileConfig.file.url}&download`
			}
			return resolveApiLink(this.$store, this.fileConfig.endpoint, this.fileConfig.queryParameters)
		}

		async downloadDocument() {
			await downloadUrlAsFile(this.downloadLink, this.fileConfig.file.name)
		}

		close() {
			this.$router.push({
				query: { ...this.$route.query, file: undefined },
			})
		}
	}
