
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'

	export interface Bar {
		color: string
		tooltip?: string
		count: number
	}
	@Component({})
	export default class ProgressBar extends Vue {
		@Prop({ required: true }) bars: Bar[]

		get total() {
			let result = 0
			for (const bar of this.bars) result += bar.count
			return result
		}

		get stats() {
			if (this.bars)
				return this.bars.map((b) => {
					return {
						...b,
						width: (b.count * 100) / this.total,
					}
				})
		}
	}
