/**
 * Deeply compares two objects
 * @param obj1 - The first object
 * @param obj2 - The second object
 * @returns - Whether the two objects are deeply equal
 * @example
 * ```ts
 * deepEqual({ a: 1 }, { a: 1 }) // true
 * deepEqual({ a: 1 }, { a: 2 }) // false
 * ```
 * @example
 * ```ts
 * deepEqual([1], [1]) // true
 * deepEqual([1], [2]) // false
 * ```
 */
export default function deepEqual<T>(obj1: T, obj2: T): boolean {
	if (obj1 === obj2) return true

	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false

	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)

	if (keys1.length !== keys2.length) return false

	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false
	}

	return true
}
