
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { HelpArticle } from '~/app/domain/content/Help'
	import SanityBlockStyles from '~/components/sanity/SanityBlockStyles.vue'
	import { DefaultBlockContentSerializers } from '~/app/domain/sanity/common'

	@Component({
		components: { SanityBlockStyles },
	})
	export default class HelpArticleDetail extends Vue {
		Utils = Utils
		@Prop({ required: true }) article: HelpArticle

		get baseUrl() {
			return `${Utils.appPath()}/help`
		}

		get serializers() {
			return DefaultBlockContentSerializers
		}

		tagClick(tag) {
			this.$router.push(`${this.baseUrl}?tag=${tag}`)
		}
	}
