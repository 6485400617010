
	import Utils from '~/app/utils/globals/Utils'
	import { Vue, Component, Prop } from 'nuxt-property-decorator'
	import { getSubdomainInfo } from '~/app/utils/libs/Subdomain'
	@Component({
		components: {},
	})
	export default class SubdomainSelect extends Vue {
		Utils = Utils
		@Prop({ default: false }) large: boolean
		@Prop({ default: false }) frontOffice: boolean
		@Prop({ default: true }) toolbar: boolean

		mounted() {}

		get subdomains() {
			if (!this.$auth.loggedIn) return []
			return Object.values(this.$auth.user.domains).map((x: any) => {
				return getSubdomainInfo.call(this, x)
			})
		}

		get selectedSubdomain() {
			const sd = this.$route.params.subdomain
			return sd
				? this.subdomains.find((s) => {
						return s.subdomain === sd
				  })
				: this.subdomains[0]
		}

		get homePath() {
			return Utils.appPath()
		}

		get maxTitleWidth() {
			if (this.$vuetify.breakpoint.xsOnly) {
				return 150
			} else if (this.$vuetify.breakpoint.mdAndDown) {
				return 200
			} else {
				return this.frontOffice ? 600 : 200
			}
		}

		goToTop() {
			this.$vuetify.goTo(-150)
		}
	}
