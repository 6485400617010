
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Model, Watch, Vue } from 'nuxt-property-decorator'
	import UserMenu from '~/components/navigation/elements/UserMenu.vue'
	import SubdomainSelect from '~/components/navigation/elements/SubdomainSelect.vue'
	import { getNavtree } from '~/app/utils/libs/Navtree'
	import { Button } from '~/app/common/types/Button'

	@Component({
		components: { SubdomainSelect, UserMenu },
	})
	export default class FoDrawer extends Vue {
		Utils = Utils
		@Model('change', { required: true }) visible: boolean

		localVisible: boolean = false

		@Watch('visible', { immediate: true })
		updateVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		updateLocalVisible() {
			if (this.visible !== this.localVisible) this.$emit('change', this.localVisible)
		}

		closeCard() {
			this.localVisible = false
		}

		get tenant() {
			const t = this.$store.state.tenant
			return t && t.tenant
		}

		get basePath() {
			if (this.$route.params.object_id) {
				return `${Utils.appPath()}/objects/${this.$route.params.object_id || ''}`
			} else {
				return `${Utils.appPath()}/${this.$route.params.company_id || ''}`
			}
		}

		get tabs(): Button[] {
			const appPath = Utils.appPath()
			if (!appPath) return []
			const basePath = `/app/${this.$route.params.subdomain}`

			if (this.$route.path.startsWith(`${basePath}/property/knowledge`)) {
				return [
					{
						text: this.$t('layout.home'),
						to: '',
						exact: true,
						icon: 'fal fa-home-alt',
					},
				]
			} else if (appPath.startsWith(`${basePath}/property/fo`)) {
				return getNavtree.call(this, 'PropertyFo').concat([
					{
						text: this.$t('domain.knowledgeBase.base'),
						fullTo: `/app/${this.$route.params.subdomain}/property/knowledge/${this.country}`,
						icon: 'fal fa-question-circle',
					},
				])
			} else if (appPath.startsWith(`${basePath}/provider/fo`)) {
				return getNavtree.call(this, 'ProviderFo')
			}
			return []
		}

		get currentRoute() {
			return this.$route.path
		}

		get country() {
			return (this.tenant && this.tenant.country) || this.$auth.user.locale
		}

		@Watch('currentRoute')
		routeUpdated() {
			this.localVisible = false
		}
	}
