import { render, staticRenderFns } from "./InputPdfArea.vue?vue&type=template&id=51ecb157&scoped=true"
import script from "./InputPdfArea.vue?vue&type=script&setup=true&lang=ts"
export * from "./InputPdfArea.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ecb157",
  null
  
)

export default component.exports