
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import { Contact } from '~/app/domain/contact/Contact'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import ContactForm from '~/components/domain/contact/ContactForm.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import { addressToDTO } from '~/app/common/model/Address'

	@Component({
		components: { UserNotifications, ContactForm, DetailLayout },
	})
	export default class ContactEdit extends Vue {
		@Model('change', { required: true }) contact: Contact
		@Prop({ default: false }) newContact: boolean
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		localContact: Contact | any = null
		@Watch('contact', { immediate: true }) updatePerson() {
			if (!this.localContact) this.localContact = { ...this.contact }
		}

		@Watch('localContact', { deep: true }) updateLocalPerson() {
			this.$emit('change', this.localContact)
		}

		/**
		 * Submit
		 */
		get mobile() {
			return this.$vuetify.breakpoint.smAndDown
		}

		get canSubmit() {
			return true
		}

		submitting = false
		async submit() {
			this.submitting = true
			try {
				const newContact =
					this.localContact.personType === 'natural'
						? {
								...this.localContact,
								companyName: undefined,
								url: undefined,
								avatar: undefined,
						  }
						: {
								...this.localContact,
								salutation: undefined,
								firstName: undefined,
								lastName: undefined,
								avatar: undefined,
						  }
				const response = await this.$axios.$post(
					this.newContact
						? 'etg24:internal:command:propertymanagement.contact.create'
						: 'etg24:internal:command:propertymanagement.contact.update',
					{
						contact: {
							...newContact,
							phoneNumbers: this.localContact.phoneNumbers?.map((p) => {
								return {
									id: p.id || undefined,
									number: p.number,
									note: p.note,
								}
							}),
							user: undefined,
							legalPersonHash: undefined,
							secondaryEmailAddresses: undefined,
							address: addressToDTO(newContact.address),
						},
					},
				)
				if (!this.newContact) {
					this.$emit('changePage', null)
					this.$emit('updateContact', response.contact)
				} else {
					await this.$router.push({
						query: {
							...this.$route.query,
							newContent: undefined,
							newContentId: undefined,
							contentType: 'contact',
							contentId: response.contact.id,
						},
					})
				}
			} catch (e) {
				Utils.handleError(e)
			}
			this.submitting = false
		}
	}
