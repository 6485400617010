/* eslint-disable no-case-declarations */
import { groupBy, maxBy, sortBy, uniq } from 'lodash'
import sha1 from 'sha1'
import { DateString, EmailAddressString, HtmlString, ShortString, UrlString, Uuid } from '../Types'
import { FileAsset } from '../assets/File'
import { Entity } from '../common/Interfaces'
import { PropertyObjectId } from '../property/Object'
import { Unit, UnitId } from '../property/Unit'
import { Contract, ContractType } from '~/app/domain/property/Contract'
import { MicroUser, UserId } from '~/app/domain/identifyAndAccess/User'
import { Address } from '~/app/domain/common/Address'
import sort from '~/app/common/filters/iterator/Sort'

/*
 * Model
 */

export type VotingId = Uuid
export type ItemId = Uuid
export type AddendumId = Uuid
export type VotingRightId = Uuid
export type VoteId = Uuid
export type AttachmentId = Uuid
export type ParticipantId = Uuid

export enum VotingType {
	online_resolution = 'online_resolution',
	meeting = 'meeting',
	survey = 'survey',
}

export enum ItemApproval {
	yes = 'yes',
	no = 'no',
	none = 'none',
}

export enum AddendumType {
	note = 'note',
	challenge = 'challenge',
	annulment = 'annulment',
}

export enum VotingStatus {
	draft = 'draft',
	active = 'active',
	meeting = 'meeting',
	verification = 'verification',
	published = 'published',
	canceled = 'canceled',
	invalid = 'invalid',
}

export enum MajorityMode {
	unanimous = 'unanimous',
	simple = 'simple',
	manual = 'manual',
}

export enum VotingWeightMode {
	units = 'units',
	shares = 'shares',
	headcounts = 'headcounts',
	specific = 'specific',
}

export enum ResultVisualization {
	off = 'off',
	onPublish = 'onPublish',
	live = 'live',
}

export enum ResultVisualizationStyle {
	anonymous = 'anonymous',
	covered = 'covered',
	uncovered = 'uncovered',
}

export enum ParticipationType {
	unknown = 'unknown',
	none = 'none',
	physical = 'physical',
	online = 'online',
}

export enum ParticipantType {
	participant = 'participant',
	suggestion = 'suggestion',
}

export enum CastVotesMethod {
	showOfHands = 'showOfHands',
	app = 'app',
}

export type ActiveParticipationType = ParticipationType.physical | ParticipationType.online

export interface Participant extends Entity<ParticipantId> {
	legalPersonHash: string | null
	votingId: VotingId
	castVotesMethod: CastVotesMethod | null
	firstName: ShortString
	lastName: ShortString
	participation: ParticipationType
	rsvp: ParticipationType | null
	authority: boolean
	representative: ParticipantId | null
	leftOn: DateString | null
}

export interface Voter {
	firstName: ShortString | null
	lastName: ShortString | null
	emailAddress: EmailAddressString | null
	userId: string | null
	legalPersonHash: string
}

export enum Choice {
	yes = 'yes',
	no = 'no',
	abstention = 'abstention',
}

export interface Instruction {
	givenOn: DateString
	choice: Choice
}

export interface Attachment extends Entity<AttachmentId> {
	file: FileAsset
	number: number
	title: ShortString
	description: ShortString | null
}

export interface Vote extends Entity<VoteId> {
	votingRightId: VotingRightId | null
	choice: Choice
	castBy: UserId | null
	castOn: DateString
}

export interface VotingRight extends Entity<VotingRightId> {
	itemId: ItemId
	unitData: {
		id: string
		number: string
		extId: string | null
	}
	weight: number
	isAnonymized: boolean
	voters: Voter[]
	vote: Vote | null // always null if anonymized
	instruction: Instruction | null
}
export interface VoteSummary {
	absolute: {
		[Choice.yes]: number
		[Choice.no]: number
		[Choice.abstention]: number
		participation: number
		nonParticipation: number
		total: number
	}
	weighted: {
		[Choice.yes]: number
		[Choice.no]: number
		[Choice.abstention]: number
		participation: number
		nonParticipation: number
		total: number
	}
}

export interface DynamicVotes {
	/**
	 * Array of voting rights that can cast a vote probably renamed to activeVotingRights in the future
	 */
	votes: VotingRight[]
	/**
	 * Summary of the votes
	 */
	voteSummary: VoteSummary
}

export interface StaticVotes {
	/**
	 * All the votingRights of all participants => empty array for FrontOffice
	 */
	votingRights: VotingRight[]

	/**
	 * VotingRights where you can cast a vote own because you own it
	 */
	ownedVotingRights: VotingRight[]

	/**
	 * VotingRights where you can cast a vote own because you are a representative
	 */
	givenVotingRights: VotingRight[]

	/**
	 * Currently not used in the client
	 */
	isAnonimized: boolean
}

export enum VotingItemType {
	decision = 'decision',
	ruleOfProcedure = 'ruleOfProcedure',
	information = 'information',
}

export interface Addendum extends Entity<AddendumId> {
	type: AddendumType
	text: string
	date: DateString
}

export interface VotingItem extends Entity<ItemId> {
	votingId: VotingId
	type: VotingItemType
	number: number
	title: ShortString
	text: string
	description: string
	majorityMode: MajorityMode
	votingWeightMode: VotingWeightMode
	resultVisualization: ResultVisualization
	resultVisualizationStyle: ResultVisualizationStyle
	approval: ItemApproval | null
}

export interface VotingItemWithDetails extends VotingItem {
	unitIds: UnitId[]
	attachments: AttachmentId[]
	addendums: Addendum[]
	process: { id: Uuid; title: ShortString } | null
}

enum ProtocolSignatoryType {
	local = 'local',
	fromRequest = 'fromRequest',
}

export interface ProtocolSignatory {
	firstName: string
	lastName: string
	role: string
	signature: string
	type: ProtocolSignatoryType
	id?: Uuid
}

export interface RequestedSignature {
	id: Uuid
	user: MicroUser
}

export interface Meeting {
	startsOn: DateString
	endsOn: DateString
	startedOn: DateString | null
	endedOn: DateString | null
	participationTypes: ActiveParticipationType[] // must be at least one
	addressNote: ShortString | null
	address: Address | null
	note: HtmlString | null
	participationLink: UrlString | null
	activeItemId: ItemId | null
	startedOnProtocol: DateString
	endedOnProtocol: DateString
	protocolNotes: string
	signatoryCount: number
}

// Voting
export interface Voting extends Entity<VotingId> {
	objectId: PropertyObjectId
	title: ShortString
	description: string
	type: VotingType
	votingPeriodStartedOn: DateString | null
	autoCloseOn: DateString | null
	votingPeriodEndedOn: DateString | null
	resultsPublishedOn: DateString | null
	status: VotingStatus
	items: VotingItem[]
	protocol: Document | null
	earlyAccess: boolean
	isArchived: boolean
	attachments: Attachment[]
	meeting: Meeting | null // votings with type meeting always have a meeting, others never do
	event: { id: Uuid; title: ShortString } | null
}

export type VotingDownloadPart = 'protocol' | 'participantsList' | 'votingSheet' | 'instructionSheet' | 'itemVotesList'

// Several available options depend on the voting type
export const optionsPerVotingType = {
	[VotingType.survey]: {
		item: {
			weightMode: [
				VotingWeightMode.units,
				VotingWeightMode.shares,
				VotingWeightMode.headcounts,
				VotingWeightMode.specific,
			],
			majorityMode: [MajorityMode.unanimous, MajorityMode.simple, MajorityMode.manual],
			resultVisualization: [ResultVisualization.off, ResultVisualization.onPublish, ResultVisualization.live],
			resultVisualizationStyle: [
				ResultVisualizationStyle.anonymous,
				ResultVisualizationStyle.covered,
				ResultVisualizationStyle.uncovered,
			],
		},
	},
	[VotingType.meeting]: {
		item: {
			weightMode: [
				VotingWeightMode.units,
				VotingWeightMode.shares,
				VotingWeightMode.headcounts,
				VotingWeightMode.specific,
			],
			majorityMode: [MajorityMode.unanimous, MajorityMode.simple, MajorityMode.manual],
			resultVisualization: [ResultVisualization.onPublish, ResultVisualization.live],
			resultVisualizationStyle: [ResultVisualizationStyle.covered, ResultVisualizationStyle.uncovered],
		},
	},
	[VotingType.online_resolution]: {
		item: {
			weightMode: [
				VotingWeightMode.units,
				VotingWeightMode.shares,
				VotingWeightMode.headcounts,
				VotingWeightMode.specific,
			],
			majorityMode: [MajorityMode.unanimous, MajorityMode.simple, MajorityMode.manual],
			resultVisualization: [ResultVisualization.onPublish, ResultVisualization.live],
			resultVisualizationStyle: [ResultVisualizationStyle.covered, ResultVisualizationStyle.uncovered],
		},
	},
} as const

/*
 * Validation
 */

/*
 * Search
 */

export const votingSearchKeys: (keyof Voting)[] = ['title', 'description']

/*
 * Api
 */

/*
 * Sorting
 */

/*
 * Functions
 */

/**
 * Calculates the contract's headcount id, use only while not yet in payload
 */
export const calculateContractHeadCountId = (contract: Contract) => {
	/**
	 * A contract is considered equal to another (headcount wise) if
	 * the same set of voters exists (respecting firstName, lastName, emailAddress)
	 */
	const headcountRelevantData = calculateVotersFromContract(contract)
		.map((voter) => ({
			firstName: voter.firstName,
			lastName: voter.lastName,
			emailAddress: voter.emailAddress,
		}))
		.map((data) => {
			// make sure empty voters won't get the same hash (they have to count as different voters)
			if (!(data.firstName || data.lastName || data.emailAddress)) {
				data.firstName = sha1(Math.random().toString())
			}
			return data
		})
	return sha1(
		// create signature hash
		JSON.stringify(
			// stringify
			sortBy(headcountRelevantData, ['lastName', 'firstName', 'emailAddress']), // make sure order won't change the result
		),
	)
}

/**
 * Calculates the contract's voters, use only while not yet in payload
 */
export const calculateVotersFromContract = (contract: Contract): Voter[] => {
	// Voters are non-agent owner signatories
	// - Two different signatories without filled properties are not considered equal
	const relevantSignatories = contract.signatories.filter((s) => !s.isAgent)
	const voters = relevantSignatories.map((signatory) => {
		return {
			firstName: signatory.firstName || signatory.user?.firstName || '',
			lastName: signatory.lastName || signatory.user?.lastName || '',
			emailAddress: signatory.emailAddress || signatory.user?.emailAddress || '',
			userId: signatory.user?.id,
			legalPersonHash: signatory.legalPersonHash,
		}
	})
	return sortBy(voters, ['lastName', 'firstName', 'emailAddress']) // make sure order won't change the result
}

/**
 * Calculates the unit's voting weight, use only while not yet in payload
 */
export const calculateUnitVotingWeight = (
	_: Voting,
	weightMode: VotingWeightMode,
	unitId: UnitId,
	units: Unit[],
	contracts: Contract[],
): number => {
	switch (weightMode) {
		case VotingWeightMode.units:
			return 1
		case VotingWeightMode.shares:
			return units.find((unit) => unit.id === unitId).share
		case VotingWeightMode.headcounts:
			const validContracts = contracts
				.filter((c) => c.isActive)
				.filter((c) => c.contractType === ContractType.owner)
			const contract = validContracts.find((c) => c.unitId === unitId)
			if (!contract) {
				return 1 // if there's no contract, we have to assume it's a different head
			}
			const headId = calculateContractHeadCountId(contract)
			// todo: probably instead of rendering a fraction, we should render head count weights differently in the UI
			return 1 / validContracts.filter((c) => calculateContractHeadCountId(c) === headId).length
		case VotingWeightMode.specific:
			return units.find((unit) => unit.id === unitId).votingWeight
	}
}

export const getUniqueUnitIdsWithVotingRight = (voting: Voting): UnitId[] => {
	return uniq(voting.items.map((item) => item.unitIds).flat())
}

export const getVotingResultColor = (choice: Choice | string) => {
	switch (choice) {
		case Choice.no:
			return '#dc3545'
		case Choice.yes:
			return '#28a745'
		case 'none':
		case Choice.abstention:
			return '#090909'
		default:
			return '#9e9e9e'
	}
}

export const getParticipationColor = (choice: ParticipationType) => {
	switch (choice) {
		case ParticipationType.online:
			return '#a2ffb7'
		case ParticipationType.physical:
			return '#28a745'
		case ParticipationType.none:
			return '#363636'
		default:
			return '#b7b7b7'
	}
}

export const getVotingItemDefaults = (voting: Voting): Partial<VotingItem> => {
	switch (voting.type) {
		case VotingType.survey:
			return {}
		case VotingType.online_resolution:
			return {
				majorityMode: MajorityMode.unanimous,
				votingWeightMode: VotingWeightMode.units,
				resultVisualization: ResultVisualization.onPublish,
				resultVisualizationStyle: ResultVisualizationStyle.covered,
			}
		case VotingType.meeting:
			// if the voting already has items, copy the last item's config as defaults
			const defaults = {
				majorityMode: MajorityMode.simple,
				votingWeightMode: VotingWeightMode.shares,
				resultVisualization: ResultVisualization.live,
				resultVisualizationStyle: ResultVisualizationStyle.uncovered,
			}
			if (voting.items.length) {
				const lastItem = maxBy(voting.items, 'number')

				const lastNonInformationItem = maxBy(
					voting.items.filter((item) => item.type !== VotingItemType.information),
					'number',
				)

				if (lastItem && lastNonInformationItem?.id === lastItem?.id) {
					return {
						majorityMode: lastItem.majorityMode,
						votingWeightMode: lastItem.votingWeightMode,
						resultVisualization: lastItem.resultVisualization,
						resultVisualizationStyle: lastItem.resultVisualizationStyle,
					}
				}
				if (lastNonInformationItem) {
					return {
						type: VotingItemType.information,
						majorityMode: lastNonInformationItem.majorityMode,
						votingWeightMode: lastNonInformationItem.votingWeightMode,
						resultVisualization: lastNonInformationItem.resultVisualization,
						resultVisualizationStyle: lastNonInformationItem.resultVisualizationStyle,
					}
				}
				return {
					type: VotingItemType.information,
					...defaults,
				}
			}
			// otherwise use these defaults
			return defaults
	}
	return {}
}

export const isVotingRightOwnedBy = (votingRight: VotingRight, legalPersonHash: string) => {
	return votingRight.voters.some((voter) => voter.legalPersonHash === legalPersonHash)
}

export function votingsSort(votings: Voting[]) {
	return sort(votings, ['resultsPublishedOn', 'votingPeriodEndedOn'], ['desc', 'desc'])
}

export function showVotingItemApprovalAnnouncement(voting: Voting, item: VotingItem) {
	switch (voting.type) {
		case VotingType.survey: {
			return false
		}
		case VotingType.online_resolution: {
			return voting.status === VotingStatus.published && !!item.approval
		}
		case VotingType.meeting: {
			return !!item.approval
		}
		default:
			return false
	}
}

export const getUnitsForLegalPerson = (legalPersonHash: string, contracts: Contract[], units: Unit[]): Unit[] => {
	if (!legalPersonHash || !contracts || !units) {
		return []
	}
	const unitIds = contracts
		.filter(
			(contract) =>
				contract.isActive &&
				contract.signatories.some(
					(signatory) => signatory.canVote && signatory.legalPersonHash === legalPersonHash,
				),
		)
		.map((contract) => contract.unitId)
	return units.filter((unit) => unitIds.includes(unit.id))
}

export const groupVotingRightsByContractualPartner = (votingRights: VotingRight[]) => {
	// fixme: we technically just group by contractual partner hash, but since we don't have that data here we replicate the algorithm
	const votingRightToKey = (votingRight: VotingRight) => {
		return JSON.stringify(
			votingRight.voters.map((voter) => ({
				firstName: voter.firstName,
				lastName: voter.lastName,
				emailAddress: voter.emailAddress,
			})),
		)
	}
	return Object.values(groupBy(votingRights, votingRightToKey)).map((votingRights) => ({
		voters: votingRights[0].voters,
		votingRights,
	}))
}

export function canCreateItems(voting: Voting) {
	switch (voting.type) {
		case VotingType.survey:
		case VotingType.online_resolution:
			return voting.status === VotingStatus.draft
		case VotingType.meeting: // meetings can get new items before being published
			return [VotingStatus.draft, VotingStatus.active, VotingStatus.meeting].includes(voting.status)
	}
}
