export default function useScreen() {
  var width = ref(window.innerWidth);
  var height = ref(window.innerHeight);
  function handleResize() {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  }
  onMounted(function () {
    window.addEventListener('resize', handleResize);
  });
  onUnmounted(function () {
    window.removeEventListener('resize', handleResize);
  });
  return {
    width: width,
    height: height
  };
}