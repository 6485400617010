
	import Utils from '~/app/utils/globals/Utils'
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
	import { groupBy } from 'lodash'
	import { Process, processSearchKeys, processSort, ProcessStatuses } from '~/app/domain/content/Process'
	import AttachmentsLine from '~/components/domain/attachments/AttachmentsLine.vue'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import ProcessCard from '~/components/domain/process/ProcessCard.vue'
	import ButtonToggle from '~/components/inputs/ButtonToggle.vue'
	import SearchSplash from '~/components/utils/SearchSplash.vue'
	import ListPaginator from '~/components/containers/ListPaginator.vue'
	import FEmployeePicker from '~/components/domain/filters/FEmployeePicker.vue'
	import FObjectGroupPicker from '~/components/domain/filters/FObjectGroupPicker.vue'
	import FStatusSelector from '~/components/domain/filters/FStatusSelector.vue'
	import ProcessDetail from '~/components/domain/process/ProcessDetail.vue'
	import FDatePicker from '~/components/domain/filters/FDatePicker.vue'
	import FYearPicker from '~/components/domain/filters/FYearPicker.vue'
	import FArchiveFilter from '~/components/domain/filters/FArchiveFilter.vue'

	@Component({
		components: {
			FArchiveFilter,
			FYearPicker,
			FDatePicker,
			ProcessDetail,
			FStatusSelector,
			FObjectGroupPicker,
			FEmployeePicker,
			ListPaginator,
			SearchSplash,
			ButtonToggle,
			ProcessCard,
			SearchLayout,
			UserLine,
			CardLargeContainer,
			AttachmentsLine,
		},
	})
	export default class ProcessList extends Vue {
		@Prop({ default: false }) objectInfo: boolean
		@Prop({ required: false, default: null }) nodeId: string
		@Prop({ required: false, default: undefined }) contractId: string
		@Prop({ default: false }) editable: boolean
		@Prop({ type: Boolean, default: true }) helpBtn
		@Prop({ type: Boolean, default: false }) standalone: boolean
		@Prop({ default: null }) userId: string
		@Prop({ type: Boolean, default: false }) disableNew

		Image = Image

		created() {
			this.$nuxt.$on('processUpdated', this.processUpdated)
			this.$nuxt.$on('processDeleted', this.processDeleted)
			this.$nuxt.$on('processAdded', this.processAdded)
		}

		beforeDestroy() {
			this.$nuxt.$off('processUpdated')
			this.$nuxt.$off('processDeleted')
			this.$nuxt.$off('processAdded')
		}

		/**
		 * Fetch
		 */
		response = null
		loading = true
		async fetchData() {
			this.loading = true

			// work out the node idp
			const node = this.nodeId ? this.nodeId : undefined
			const nodeIds = this.nodeId || this.contractId ? undefined : this.filters.nodeIds

			// fetch the response
			this.response = await this.$axios.$post('etg24:internal:query:content.processes', {
				nodeIds,
				nodeId: node,
				contractId: this.contractId,
				status: this.filters.archive ? ['completed'] : this.filters.status,
				assignee: this.filters.selectedEmployee || undefined,
				fromDate: this.filters.archive && this.filters.year ? `${this.filters.year}-01-01` : undefined,
				toDate: this.filters.archive && this.filters.year ? `${this.filters.year}-12-31` : undefined,
			})
			this.loading = false
		}

		@Watch('nodeId') async updateNodeId() {
			await this.fetchData()
		}

		@Watch('contractId') async updateContractId() {
			await this.fetchData()
		}

		/**
		 * Search & filtering logic
		 */
		searchText: string = null
		monthStrings = {}
		filtering = false
		get filteredProcesses(): Process[] | any {
			if (!this.response) {
				return []
			}
			this.filtering = true
			let filtered = processSort(this.response.processes)
			if (this.searchText) {
				filtered =
					search(filtered, this.searchText, {
						fuzzy: true,
						sort: true,
						keys: processSearchKeys,
					}) || []
			}

			if (this.filters.archive) {
				// @ts-ignore
				filtered = groupBy(
					filtered
						.filter((d) => !!d.publishedOn)
						.map((e) => {
							const isoDate = this.$dateFns.parseISO(e.lastActivityOn)
							const dateNum = this.$dateFns.format(isoDate, 'yyyy-MM')
							this.monthStrings[dateNum] = Utils.dateFormat(e.lastActivityOn, 'month')
							return {
								...e,
								month: dateNum,
							}
						}),
					(e) => {
						return e.month
					},
				)
			}
			this.filtering = false
			return filtered
		}

		get listeners() {
			return {
				click: this.$listeners?.click,
				new: this.$listeners?.new,
			}
		}

		processUpdated(process) {
			const fIndex = this.response.processes.findIndex((p) => p.id === process?.id)
			if (fIndex >= 0) {
				this.response.processes.splice(fIndex, 1, process)
				this.response = {
					...this.response,
					processes: this.response.processes,
				}
			}
		}

		processDeleted(process) {
			const fIndex = this.response.processes.findIndex((p) => p.id === process?.id)
			if (fIndex >= 0) {
				this.response.processes.splice(fIndex, 1)
				this.response = {
					...this.response,
					processes: this.response.processes,
				}
			}
		}

		processAdded(process) {
			this.response.processes.push(process)
		}

		/**
		 * Init & loading logic
		 */
		initialized = {
			objects: false,
			teams: false,
		}

		firstLoad = false
		@Watch('initialized', { immediate: true, deep: true })
		async hasInitialized() {
			if (
				!this.firstLoad &&
				(!this.standalone || this.initialized.objects) &&
				(!this.editable || this.initialized.teams)
			) {
				await this.fetchData()
				this.firstLoad = true
			}
		}

		@Watch('filters', { deep: true }) async updateFilters() {
			if (this.firstLoad) {
				await this.fetchData()
			}
		}

		get states() {
			return [
				{
					text: this.$t('domain.process.status.in_progress'),
					value: ProcessStatuses.in_progress,
				},
				{
					text: this.$t('domain.process.status.waiting'),
					value: ProcessStatuses.waiting,
				},
			]
		}

		/**
		 * Filtering
		 */
		filters: any = {
			nodeIds: [],
			// @ts-ignore
			selectedEmployee: this.userId || undefined,
			status: [ProcessStatuses.in_progress, ProcessStatuses.waiting],
			archive: false,
		}

		/**
		 * Show process
		 */
		showProcess(process) {
			this.$emit('click', process)
		}

		async updateProcess() {
			await this.fetchData()
		}

		async deleted() {
			await this.fetchData()
		}
	}
