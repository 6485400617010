
	import { Component, Vue, Watch, Prop, Model } from 'nuxt-property-decorator'

	@Component({})
	export default class SFTextField extends Vue {
		@Prop({ default: null }) rules: string
		@Prop({ default: null }) label: string
		@Prop({ default: null }) modelName: string
		@Prop({ default: null }) validatorPrefix: string
		@Prop({ default: 'search' }) type: string
		@Prop({ type: Boolean, default: false }) required: boolean
		@Prop({ type: Boolean, default: false }) hideLabel: boolean
		@Prop({ type: Boolean, default: false }) autofocus: boolean
		@Prop({ default: null }) min: number
		@Model('changed', { default: '' }) value: string
		@Prop({ default: 'off' }) autocomplete: string
		@Prop({ type: Boolean, default: false }) clearable: boolean
		@Prop({ type: Boolean, default: false }) disabled: boolean
		@Prop({ default: null }) placeholder: string
		@Prop({ default: null }) suffix: string
		@Prop({ default: null }) prefix: string
		@Prop({ default: null }) max: number
		@Prop({ default: 250 }) maxlength: number
		@Prop({ default: 1 }) step: number
		@Prop({ type: Boolean, default: false }) translate: boolean
		@Prop({ default: () => ({}), type: Object }) inputAttributes: {
			[key: string]: string
		}

		localValue = null
		@Watch('value', { immediate: true })
		updateValue() {
			this.localValue = this.value
		}

		@Watch('localValue')
		updateLocalValue() {
			this.$emit('changed', this.localValue)
		}

		get rLabel() {
			if (this.label && !this.hideLabel)
				return `${this.translate ? this.$t(this.label) : this.label}${this.required ? '*' : ''}`
		}

		/**
		 * focus functionality
		 */
		focus() {
			;(this.$refs.input as any).focus()
		}
	}
