
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import VueReadMoreSmooth from 'vue-read-more-smooth'

	@Component({ components: { VueReadMoreSmooth } })
	export default class ReadMore extends Vue {
		@Prop({ type: Boolean, default: false }) hidden: boolean
		@Prop({ type: Number, default: 15 }) lines: number
		@Prop({ type: Boolean, default: false }) insetLeft: boolean
		@Prop({ type: String, default: null }) name: string

		get moreText() {
			return this.name
				? this.$t('components.readMore.moreNamed', {
						name: this.name,
				  })
				: this.$t('components.readMore.more')
		}

		get lessText() {
			return this.name
				? this.$t('components.readMore.lessNamed', {
						name: this.name,
				  })
				: this.$t('components.readMore.less')
		}

		public async recalculate() {
			await this.$nextTick() // waiting for the DOM to update

			// @ts-ignore
			this.$refs.readmore.$refs.to.style.setProperty(
				'max-height',
				// @ts-ignore
				this.$refs.readmore.$refs.to.scrollHeight + 'px',
			)
		}
	}
