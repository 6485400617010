import { FileExtension } from '~/app/domain/Types'
import IframeRenderer from '~/components/asset-renderers/IframeRenderer.vue'
import PdfRenderer from '~/components/asset-renderers/PdfRenderer.vue'
import ImageRenderer from '~/components/asset-renderers/ImageRenderer.vue'
import AudioRenderer from '~/components/asset-renderers/AudioRenderer.vue'
import VideoRenderer from '~/components/asset-renderers/VideoRenderer.vue'
import HtmlRenderer from '~/components/asset-renderers/HtmlRenderer.vue'

export const canBeRendered = (extension: FileExtension) => {
	return !!getRenderer(extension)
}

export const getRenderer = (extension: FileExtension) => {
	switch (extension) {
		// image
		case 'bmp':
		case 'gif':
		case 'jpeg':
		case 'jpg':
		case 'jpe':
		case 'png':
		case 'svg':
		case 'svgz':
		case 'webp':
			return ImageRenderer

		// audio
		case 'weba':
		case 'mpga':
		case 'mp2':
		case 'mp2a':
		case 'mp3':
		case 'm2a':
		case 'm3a':
		case 'wav':
		case 'ogv':
			return AudioRenderer

		// video
		case 'mpeg':
		case 'mpg':
		case 'mpe':
		case 'm1v':
		case 'm2v':
		case 'webm':
		case 'mp4':
		case 'mp4v':
		case 'mpg4':
			return VideoRenderer

		// text
		case 'txt':
		case 'text':
		case 'conf':
		case 'def':
		case 'list':
		case 'log':
		case 'in':
			return IframeRenderer // todo: implement a nice plaintext renderer

		case 'pdf':
			return PdfRenderer

		// html
		case 'html':
		case 'htm':
			return HtmlRenderer
	}
	return null
}
