import { EntityId } from '~/app/domain/common/Interfaces'
import { DateString, ShortString } from '@shared/types'

export enum AccountRoles {
	house_money = 'house_money',
	reserve_funds = 'reserve_funds',
	rent = 'rent',
	deposit = 'deposit',
}

export interface BankAccountInfo {
	id: EntityId
	iban: string
	bic: string
	accountHolderName: ShortString
	instituteName: ShortString
}

export interface DirectDebitMandate {
	id: EntityId
	iban: string
	mandateReference: string
	purposes: AccountRoles[]
	consentObtainedVia?: 'manual'
	validFrom: DateString
}
