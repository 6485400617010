
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'

	@Component({ components: {} })
	export default class ReadMoreCard extends Vue {
		@Prop({ type: Array, default: () => [] }) items: any[]

		get shownItems() {
			if (!this.items.length) return []
			return this.opened ? this.items : [this.items[0]]
		}

		opened: boolean = false
		open() {
			if (!this.opened) this.opened = true
		}
	}
