
	import { Vue, Component, Prop, Watch, Model } from 'nuxt-property-decorator'
	import { Document, DocumentId, documentToLinks } from '~/app/domain/content/Document'
	import { uppercase } from '~/app/common/filters/text/Case'

	@Component({
		components: {},
	})
	export default class ChipDocumentList extends Vue {
		// The Documents to be displayed within the list
		@Prop({ required: true, type: Array }) documents: Document[]

		documentToLinks = documentToLinks
		uppercase = uppercase
	}
