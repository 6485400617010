/**
 * Preload data base on the location
 * @param objectId
 */
export async function preloadData() {
	const subdomain = this.$route.params.subdomain
	const currentPath = this.$route.path
	if (subdomain && currentPath.startsWith(`/app/${subdomain}/provider/bo`)) await _preloadProviderBo.call(this)
	else if (subdomain && currentPath.startsWith(`/app/${subdomain}/provider/fo`)) {
		await _preloadProviderFo.call(this)
	} else if (subdomain && currentPath.startsWith(`/app/${subdomain}/property/bo`)) {
		await _preloadPropertyBo.call(this)
	} else if (
		(subdomain && currentPath.startsWith(`/app/${subdomain}/property/fo`)) ||
		currentPath.startsWith(`/app/${subdomain}/property/knowledge`)
	) {
		await _preloadPropertyFo.call(this)
	}
}

async function _preloadProviderBo() {
	await this.$store.dispatch('provider/companies/fetchAll')
	await this.$store.dispatch('provider/companyGroups/fetchAll')
	await this.$store.dispatch('team/fetchAll')
}

async function _preloadProviderFo() {
	await this.$store.dispatch('provider/companies/fetchAll')
}

async function _preloadPropertyBo() {
	await this.$store.dispatch('property/objects/fetchAll')
	await this.$store.dispatch('property/objectGroups/fetchAll')
	await this.$store.dispatch('team/fetchAll')
}

async function _preloadPropertyFo() {
	await this.$store.dispatch('property/objects/fetchAll')
}
