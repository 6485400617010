
	import { Component, Prop, Vue } from 'nuxt-property-decorator'
	import sort from '~/app/common/filters/iterator/Sort'
	import { ContractSelectionSummary, translateContractSelectionSummary } from '~/app/domain/property/Contract'

	@Component
	export default class PersistedContractSelectionSummary extends Vue {
		@Prop({ required: true, type: Object })
		summary: ContractSelectionSummary

		get text() {
			return translateContractSelectionSummary(this.summary, this)
		}

		maxNames = 6
		get tooltip() {
			if (!this.summary?.names?.length) {
				return 0
			}
			let names = sort(this.summary.names)
			if (names.length > this.maxNames) {
				names = [...names.slice(0, this.maxNames), `+${names.length - this.maxNames}`]
			}

			return names.join('<br>')
		}
	}
