
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Model, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { Offer, OfferRequest, OfferStatus, Process } from '~/app/domain/content/Process'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'
	import SfSelect from '~/components/domain/conversation/service_forms/blocks/SfSelect.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import DateFt from '~/components/utils/Date.vue'
	import { username } from '~/app/common/filters/domain/User'
	import ReadMore from '~/components/utils/ReadMore.vue'
	import OfferCard from '~/components/domain/process/offer/OfferCard.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import ProviderPicker from '~/components/domain/provider/ProviderPicker.vue'
	import WaitUntilInput from '~/components/inputs/WaitUntilInput.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'

	@Component({
		components: {
			DetailCard,
			WaitUntilInput,
			ProviderPicker,
			Attachments,
			ActionButton,
			ReadMore,
			OfferCard,
			SfDate,
			SfSelect,
			SfTextArea,
			SfTextField,
			CategoryContainer,
			DetailLayout,
			DateFt,
		},
	})
	export default class ProcessOfferRequestShow extends Vue {
		@Model('change', { required: false }) process: Process
		@Prop({ required: true }) offerId: string
		@Prop({ type: Object, default: null }) parent: any

		username = username
		offer: OfferRequest = null
		activePanel = null

		@Watch('process', { immediate: true }) updateOfferId() {
			if (this.offerId) this.offer = this.process.offerRequests.find((o) => o.id === this.offerId)
		}

		get info() {
			if (this.offer)
				return [
					{
						title: this.$t('domain.process.offerRequest.properties.requestOfferStrategy.label'),
						value: this.$t(
							`domain.process.offerRequest.properties.requestOfferStrategy.options.${this.offer.requestOfferStrategy}.label`,
						),
					},
					{
						title: this.$t('domain.process.offerRequest.properties.automaticAcceptLimit.label'),
						value: this.offer.automaticAcceptLimit ? this.offer.automaticAcceptLimit + ' €' : null,
					},
					{
						title: this.$t('domain.process.offerRequest.properties.offerExpectedUntil.label'),
						type: 'date',
						value: this.offer.offerExpectedUntil,
					},
					{
						title: this.$t('domain.process.offerRequest.properties.offerExpectedUntil.label'),
						type: 'date',
						value: this.offer.offerExpectedUntil,
					},
					{
						title: this.$t('domain.process.offerRequest.properties.expectedFulfilmentDate.label'),
						type: 'date',
						value: this.offer.expectedFulfilmentDate,
					},
					{
						title: this.$t('domain.process.offerRequest.properties.maximumCost.label'),
						value: this.offer.maximumCost ? this.offer.maximumCost + ' €' : null,
					},
				].filter((i) => !!i.value)
		}

		/**
		 * Add provider
		 */
		addProviderDialog = false
		async addProviders(providers) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.addproviders', {
					offerRequest: this.offer.id,
					providers: providers.map((p) => p.id),
				})
				this.$emit('updateProcess', response.process)
				this.addProviderDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Cancel Request
		 */
		cancellingRequest: boolean = false
		async cancelRequest() {
			this.cancellingRequest = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.cancel', {
					offerRequest: this.offer.id,
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
			this.cancellingRequest = false
		}

		/**
		 * Offer actions
		 */
		acceptingOffer: boolean = false
		async acceptOffer(offer: Offer) {
			this.acceptingOffer = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.offer.accept', {
					offer: offer.id,
				})
				this.$emit('updateProcess', response.process)
				this.activePanel = null
			} catch (e) {
				Utils.handleError(e)
			}
			this.acceptingOffer = false
		}

		rejectingOffer: boolean = false
		async rejectOffer(offer: Offer) {
			this.rejectingOffer = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.offer.decline', {
					offer: offer.id,
				})
				this.$emit('updateProcess', response.process)
				this.activePanel = null
			} catch (e) {
				Utils.handleError(e)
			}
			this.rejectingOffer = false
		}

		get acceptedOffer(): Offer | null {
			if (!this.offer) {
				return null
			}
			const acceptedOffers = this.offer.offers.filter((offer) => offer.status === OfferStatus.accepted)
			return acceptedOffers.length === 1 ? acceptedOffers[0] : null
		}

		creatingOrder: boolean = false
		async createOrder() {
			this.creatingOrder = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.createfromoffer', {
					offer: this.acceptedOffer.id,
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', `orderEdit_${response.orderId}`)
				this.activePanel = null
			} catch (e) {
				Utils.handleError(e)
			}
			this.creatingOrder = false
		}

		/**
		 * Attachments
		 */
		async uploadAttachment(file) {
			// build the form
			const formData = new FormData()
			formData.append('file', file)
			formData.set('offerRequest', this.offer.id)

			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:offers.request.attachment.create',
					formData,
					{
						headers: { 'content-type': 'multipart/form-data' },
					},
				)
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}

		async deleteAttachment(file) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.attachment.delete', {
					offerRequest: this.offer.id,
					attachment: file.id,
				})
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}

		/**
		 * Snooze
		 */
		async snooze(waitUntil) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.update', {
					offerRequest: {
						id: this.offer.id,
						waitUntil,
					},
				})
				this.$emit('updateProcess', response.process)
			} catch (e) {
				Utils.handleError(e)
			}
		}
	}
