var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":960,"overlay-opacity":_vm.$vuetify.breakpoint.smAndDown ? 1 : undefined},on:{"click:outside":function($event){_setup.model = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_setup.model = false}},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}},[_c('div',{staticClass:"tw-bg-background-soft tw-w-full tw-h-full md:tw-h-[90vh] tw-@container/deliveryDownload"},[_c('div',{staticClass:"tw-flex tw-flex-col @2xl/deliveryDownload:tw-flex-row tw-h-full"},[_c('div',{staticClass:"tw-flex-auto tw-h-full tw-max-h-full tw-overflow-y-auto tw-p-6 tw-flex tw-flex-col"},[_c('span',{staticClass:"tw-text-2xl tw-leading-6"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.download.base'))+"\n\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mt-4"},[_c('div',{staticClass:"tw-text-sm tw-uppercase tw-tracking-wide tw-font-bold"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.download.include.question'))+"\n\t\t\t\t\t")]),_vm._v(" "),(_setup.contractsByContractType)?_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4 tw-w-full"},[_c('div',{staticClass:"tw-text-sm tw-uppercase tw-tracking-wide tw-font-bold tw-text-text-softer"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.download.contracts.types.question'))+"\n\t\t\t\t\t\t")]),_vm._v(" "),_vm._l((Object.keys(_setup.contractsByContractType || {})),function(type,typeId){return _c(_setup.UiInputCheckbox,{key:typeId,attrs:{"model-value":!!_setup.downloadParams.contractTypes[type],"label":`${_vm.$t(`domain.delivery.download.contracts.types.${type}.title`)} (${
								_setup.contractsByContractType[type].length
							})`,"text":`${_vm.$t(`domain.delivery.download.contracts.types.${type}.description`)}`,"disabled":_setup.contractsByContractType[type].length === 0,"variant":"card"},on:{"update:modelValue":function($event){_setup.downloadParams = {
									..._setup.downloadParams,
									contractTypes: {
										..._setup.downloadParams.contractTypes,
										[type]: $event,
									},
								}}}})})],2):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.values(_setup.selectedContractsByExportGroup).some((a) => a.length > 0)),expression:"Object.values(selectedContractsByExportGroup).some((a) => a.length > 0)"}],staticClass:"tw-flex tw-flex-col tw-gap-4 tw-w-full"},[_c('div',{staticClass:"tw-text-sm tw-uppercase tw-tracking-wide tw-font-bold tw-text-text-softer"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.download.units.question'))+"\n\t\t\t\t\t\t")]),_vm._v(" "),_vm._l((Object.keys(_setup.selectedContractsByExportGroup)),function(state,stateId){return _c(_setup.UiInputCheckbox,{directives:[{name:"show",rawName:"v-show",value:(_setup.selectedContractsByExportGroup[state].length > 0),expression:"selectedContractsByExportGroup[state].length > 0"}],key:stateId,attrs:{"model-value":!!_setup.downloadParams.states[state],"label":`${_vm.$t(`domain.delivery.exportGroup.${state}.title`)} (${
								_setup.selectedContractsByExportGroup[state].length
							})`,"text":`${_vm.$t(`domain.delivery.exportGroup.${state}.description`)}`,"variant":"card"},on:{"update:modelValue":function($event){_setup.downloadParams = {
									..._setup.downloadParams,
									states: {
										..._setup.downloadParams.states,
										[state]: $event,
									},
								}}}})})],2)]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mt-10"},[_c('div',{staticClass:"tw-text-sm tw-uppercase tw-tracking-wide tw-font-bold"},[_vm._v("\n\t\t\t\t\t\t2. "+_vm._s(_vm.$t('domain.delivery.download.mergeStrategy.question'))+"\n\t\t\t\t\t")]),_vm._v(" "),_vm._l((['none', 'folder', 'all']),function(mergeStrategy,mergeStrategyId){return _c(_setup.UiInputRadio,{key:mergeStrategyId,attrs:{"model-value":_setup.downloadParams.mergeStrategy,"value":mergeStrategy,"label":String(_vm.$t(`domain.delivery.download.mergeStrategy.${mergeStrategy}.title`)),"text":String(_vm.$t(`domain.delivery.download.mergeStrategy.${mergeStrategy}.description`)),"variant":"card"},on:{"update:modelValue":function($event){_setup.downloadParams = {
								..._setup.downloadParams,
								mergeStrategy: $event,
							}}}})})],2)]),_vm._v(" "),_c('div',{staticClass:"tw-w-full tw-flex-shrink-0 @2xl/deliveryDownload:tw-w-[295px] @2xl/deliveryDownload:tw-max-h-full @2xl/deliveryDownload:tw-overflow-y-auto @2xl/deliveryDownload:tw-overflow-x-hidden tw-bg-background-base tw-h-auto md:tw-min-h-full tw-p-6 tw-flex-col tw-flex tw-gap-4 tw-pt-16"},[_c('v-btn',{staticClass:"tw-fixed tw-top-2.5 tw-right-3.5",attrs:{"color":"primary","icon":"","text":"","exact":"","tabindex":"-1"},on:{"click":function($event){_setup.model = false}}},[_c(_setup.UiIcon,{attrs:{"icon":"fal fa-times-circle"}})],1),_vm._v(" "),_c('div',{staticClass:"tw-text-sm tw-uppercase tw-tracking-wide tw-font-bold"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.download.settings.question'))+"\n\t\t\t\t")]),_vm._v(" "),_vm._l((_setup.settings),function(setting){return _c(_setup.UiToggle,{key:setting,attrs:{"label":String(_vm.$t(`domain.delivery.download.${setting}.title`)),"info-text":String(_vm.$t(`domain.delivery.download.${setting}.description`)),"model-value":_setup.downloadParams[setting]},on:{"update:modelValue":function($event){_setup.downloadParams = { ..._setup.downloadParams, [setting]: $event }}}})}),_vm._v(" "),_c('v-btn',{staticClass:"tw-mt-auto tw-w-full",attrs:{"color":"primary","large":_vm.$vuetify.breakpoint.mdAndUp,"disabled":_setup.contractsToDownload.length === 0,"loading":_vm.downloading},on:{"click":function($event){return _vm.$emit('download', _setup.downloadParams, _setup.contractIds)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-download")]),_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('layout.download'))+"\n\t\t\t\t")],1)],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }