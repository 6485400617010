
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { DayString, TimeString } from '~/app/domain/Types'
	import DateDisplay from '~/components/utils/Date.vue'

	@Component({
		components: { DateDisplay },
	})
	export default class DateDetail extends Vue {
		@Model('change', { required: true }) date: DayString
		@Prop({ default: null }) label: string
		@Prop({ default: null }) time: TimeString

		get fullDate() {
			return this.$dateFns.format(
				this.$dateFns.parse(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm', new Date()),
				String(this.$t('dates.time')),
			)
		}
	}
