import { getSystemLanguageCode } from '~/app/utils/capacitor'

/**
 * Set's the system locale from the user or the system
 */
export async function setSystemLocale() {
	let userPreferredLocale = null
	if (this.$auth.loggedIn) {
		// get the user's persisted locale
		userPreferredLocale = this.$auth.user.locale
	} else {
		// get the locale from cookie, fallback to system
		userPreferredLocale = (await getSystemLanguageCode()).split('-')[0]
	}

	// check if the locale is supported, fallback to english
	const supportedLocales = this.$i18n.locales.map((l) => {
		return l.code
	})
	if (!supportedLocales.includes(userPreferredLocale)) userPreferredLocale = 'en'
	if (userPreferredLocale !== this.$i18n.locale) {
		this.$i18n.setLocale(userPreferredLocale)
	}
	this.veeLocale = userPreferredLocale
}
