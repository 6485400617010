
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'

	@Component({
		components: {},
	})
	export default class FStatusSelector extends Vue {
		@Model('changed', { default: () => [] }) value: string[]
		@Prop({ type: Number, default: 50 }) height
		@Prop({ type: Array, default: () => [] }) states
		@Prop({ type: String, default: 'fStatus' }) queryName
		@Prop({ type: Boolean, default: false }) exclusive: boolean
		@Prop({ type: String, required: true }) label: string

		mounted() {
			const q = this.$route.query[this.queryName]
			if (q) {
				if (typeof q === 'string') this.localValue = [q]
				else this.localValue = q
			} else {
				this.localValue = this.value?.length ? this.value : this.states.map((s) => s.value)
			}
		}

		localValue: string[] = []
		@Watch('value', { immediate: true })
		valueUpdated() {
			this.localValue = this.value
		}

		@Watch('localValue')
		localValueUpdated() {
			this.$emit('changed', this.localValue)
			if (this.localValue !== null && this.localValue !== this.$route.query[this.queryName])
				this.$router
					.replace({
						query: {
							...this.$route.query,
							[this.queryName]: this.localValue,
						},
					})
					.catch(() => true)
		}

		get btnText() {
			if (this.exclusive) {
				return this.localValue?.[0]
			}
			return this.label
		}

		toggleState(state) {
			if (this.exclusive) {
				this.localValue = [state]
			} else if (this.localValue.includes(state)) {
				this.localValue.splice(this.localValue.indexOf(state), 1)
			} else {
				this.localValue.push(state)
			}
		}

		get isActive() {
			const activeStates = this.value.length
			return activeStates > 0 && activeStates !== this.states.length
		}
	}
