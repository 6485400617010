var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-mb-4"},[_c(_setup.UiTypography,{attrs:{"tag":"h3","variant":"subtitle1"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('domain.callRecord.earlierEntries'))+"\n\t\t")]),_vm._v(" "),(!_vm.isNewRecord)?_c(_setup.UiTooltip,{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_setup.UiButton,{attrs:{"icon":"fal fa-plus-circle","size":"lg","variant":"text","rounded":""},on:{"click":_setup.newCallRecord}})]},proxy:true}],null,false,101071998)},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.callRecord.createTitle'))+"\n\t\t\t")])]):_vm._e()],1),_vm._v(" "),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(!_vm.loading)?_c('div',[(_vm.qRecordNumber && _setup.records && _setup.records.length)?_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2.5"},_vm._l((_setup.records || []),function(record,recordIndex){return _c(_setup.CallRecordCard,{key:recordIndex,attrs:{"active":record.id === _vm.currentRecord.id,"record":record},on:{"click":function($event){return _vm.$router.push({
							query: {
								..._vm.$route.query,
								callRecord: record.id,
								callRecordNumber: undefined,
								direction: undefined,
							},
						})}}})}),1):_c('div',{staticClass:"py-8"},[_c(_setup.UiPlaceholder,{attrs:{"title":String(_vm.$t('domain.callRecord.emptyList.title')),"text":String(_vm.$t('domain.callRecord.emptyList.body')),"icon":"fal fa-phone"}})],1)]):_c('div',{key:"loading",staticClass:"tw-py-12"},[_c(_setup.UiLoader)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }