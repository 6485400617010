import { PhoneNumberString } from '../../domain/Types'
import { parsePhoneNumber } from 'awesome-phonenumber'

/**
 * Formats the provided phone number string in a localized way.
 */
export default function phone(number: PhoneNumberString) {
	return parsePhoneNumber(number).number.international
}

/**
 * Tries to format the provided input string as a E.164 phone number.
 */
export function parseInputAsE164(input: string, strict = false): PhoneNumberString {
	// Strip non-digit chars
	input = input.replace(/[^+0-9]/g, '')

	// Convert leading double zero to a plus
	input = input.replace(/^00([1-9][0-9]+)$/, '+$1')

	const phone = parsePhoneNumber(input, { regionCode: 'DE' })

	if (strict && !phone.valid) {
		return null
	}

	return phone.number.e164
}
