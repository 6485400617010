
	import { Component, Vue, Prop, Model, Watch } from 'nuxt-property-decorator'

	@Component({})
	export default class ListSelector extends Vue {
		@Model('change', { required: true }) selected: any
		@Prop({ required: true }) items: any[]
		@Prop({ default: null }) icon: string
		@Prop({ required: false }) label: string
		@Prop({ default: false }) multiple: boolean
		@Prop({ default: 'text' }) itemText: string
		@Prop({ default: 'value' }) itemValue: string
		@Prop({ default: false }) action: boolean
		@Prop({ default: false }) readonly: boolean
		@Prop({ default: false }) defaultStyle: boolean
		@Prop({ default: null }) placeholder: string

		/**
		 * State sync
		 */

		mounted() {
			this.localSelected = this.localFromSelected()
		}

		localSelected: any | any[] = 0

		@Watch('selected')
		updateSelected() {
			this.localSelected = this.localFromSelected()
		}

		@Watch('localSelected')
		updateLocalSelected() {
			const result = this.selectedFromLocal()
			if (this.multiple) {
				if (result.length === (this.selected || []).length) {
					return
				} else if (result === this.selected) return
			}
			this.$emit('change', result)
		}

		get selectedInternal() {
			const defaultLabel = {
				text: this.placeholder || this.label,
				value: null,
			}
			if (!this.localSelected || (Array.isArray(this.localSelected) && this.localSelected.length === 0))
				return [defaultLabel]
			if (this.multiple)
				return this.localSelected.map((s) => {
					return {
						text: this.getText(s),
						value: s,
					}
				})
			else
				return [
					{
						text: this.getText(this.localSelected),
						value: this.localSelected,
					} || defaultLabel,
				]
		}

		/**
		 * Translate between composed and single states
		 */
		getText(s) {
			if (s == null) return null
			return this.itemText ? s[this.itemText] : s
		}

		getValue(s) {
			if (s == null) return null
			return this.itemValue ? s[this.itemValue] : s
		}

		localFromSelected() {
			if (this.multiple) {
				if (!this.selected) return []
				return this.selected.map((s) => {
					return this.items.find((i) => {
						return i === s || this.getValue(i) === s
					})
				})
			} else {
				return this.items.find((i) => {
					return i === this.selected || this.getValue(i) === this.selected
				})
			}
		}

		selectedFromLocal() {
			if (this.multiple) {
				if (!this.localSelected) return []
				return this.localSelected.map((i) => {
					return this.getValue(i)
				})
			} else {
				return this.getValue(this.localSelected)
			}
		}

		menuVisible: boolean = false
		toggleItem(item) {
			this.$emit('selected', item)
			if (this.multiple) {
				if (!this.localSelected) this.localSelected = []
				if (this.includesItem(item)) this.localSelected.splice(this.localSelected.indexOf(item), 1)
				else this.localSelected.push(item)
			} else {
				this.localSelected = item
			}
			this.menuVisible = false
		}

		includesItem(item) {
			if (this.multiple) {
				return (this.localSelected || []).includes(item)
			} else {
				return this.localSelected === item
			}
		}
	}
