var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Teleport,{attrs:{"to":"body"}},[_c('div',{ref:"snackbar",staticClass:"tw-fixed tw-top-auto tw-my-0 tw-mx-auto tw-overflow-hidden tw-bg-transparent tw-p-0 tw-pb-4",attrs:{"popover":"manual"}},[_c('TransitionGroup',{staticClass:"tw-flex tw-flex-col tw-gap-2",attrs:{"name":"expand","tag":"div"},on:{"enter":_setup.trueHeight,"after-enter":_setup.removeHeight,"before-leave":_setup.trueHeight}},_vm._l((_setup.snackbars),function({ id, message, type, dismissable }){return _c('div',{key:id,staticClass:"tw-max-w-64 tw-rounded tw-px-4 tw-py-2 tw-text-white tw-shadow-md md:tw-max-w-96",class:{
					'tw-bg-success-base': type === _setup.SnackbarType.Success,
					'tw-bg-error-base': type === _setup.SnackbarType.Error,
					'tw-bg-warning-soft tw-text-warning-strong': type === _setup.SnackbarType.Warning,
					'tw-bg-info-soft tw-text-info-strong': type === _setup.SnackbarType.Info,
					'!tw-pr-2': dismissable,
				},attrs:{"data-qa":`snackbar:${type}`}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-1"},[_c(_setup.UiTypography,{staticClass:"tw-py-2",attrs:{"variant":"body"}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(message)+"\n\t\t\t\t\t")]),_vm._v(" "),(dismissable)?_c(_setup.UiButton,{staticClass:"tw-ml-auto",attrs:{"variant":"text","color":"white","icon":"fal fa-times"},on:{"click":function($event){return _setup.SnackbarService.removeSnackbar(id)}}}):_vm._e()],1)])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }