import { Uuid } from '../Types'
import { Entity } from '../common/Interfaces'
import { Address, addressSearchKeys } from '../common/Address'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { Validators } from '../../common/validation/Validators'
import Papa from 'papaparse'
import { Unit, UnitType } from '~/app/domain/property/Unit'
import {
	ContactInformation,
	Contract,
	ContractType,
	getMergedSignatoryContactInformation,
	hasOnlineAccess,
	isActiveContract,
	isPastContract,
	Signatory,
} from '~/app/domain/property/Contract'
import { NuxtI18nInstance } from 'nuxt-i18n'
import formatDate, { FORMAT_NUMBERS, PRECISION_DAY } from '~/app/common/filters/Date'
import { ISO_3166_1 } from '~/app/common/static/ISO_3166_1'
import sort from '~/app/common/filters/iterator/Sort'
import { groupBy, sumBy } from 'lodash'
import { ImageAsset } from '~/app/domain/assets/Image'

/*
 * Model
 */

export type PropertyObjectId = Uuid
export interface Object extends Entity<PropertyObjectId> {
	title: string
	description: string
	managementType: ObjectManagementType
	totalShares: number
	totalArea: number
	extId: string
	numberOfUnits: string
	image: ImageAsset
	userAccess: boolean
}
export interface PropertyObject extends Object {}
export enum ObjectManagementType {
	owner = 'owner',
	rental = 'rental',
}

export enum ObjectBuildingType {
	multifamily = 'multifamily',
	rowhouse = 'rowhouse',
	twofamily = 'twofamily',
	detached = 'detached',
	semidetached = 'semidetached',
	holiday = 'holiday',
	business = 'business',
	garages = 'garages',
}

export enum ObjectHeatingType {
	central = 'central',
	oven = 'oven',
	storey = 'storey',
	district = 'district',
	block = 'block',
}

export enum ObjectEnergySource {
	gas = 'gas',
	oil = 'oil',
	wood = 'wood',
	pellets = 'pellets',
	biomass = 'biomass',
	geothermal = 'geothermal',
	electricity = 'electricity',
	coal = 'coal',
}

export enum ObjectPreservationOrder {
	none = 'none',
	ground = 'ground',
	architectural = 'architectural',
	garden = 'garden',
	area = 'area',
}

export type ObjectAddressId = Uuid
export interface ObjectAddress extends Entity<ObjectAddressId> {
	address: Address
	objectId: PropertyObjectId
	buildingType: ObjectBuildingType | null
	constructionFinishedOn: number | null
	coreRenovatedOn: number | null
	heatingDatedOn: number | null
	heatingType: ObjectHeatingType | null
	heatingEnergySource: ObjectEnergySource | null
	preservationOrder: ObjectPreservationOrder | null
}

/*
 * Validation
 */

export const objectValidations = {
	title: {
		required: Validators.required,
	},
	numberOfUnits: {
		required: Validators.required,
		integer: Validators.integer,
		maxDigits: Validators.maxDigits(3),
		positive: Validators.positive,
		notZero: Validators.notZero,
	},
	extId: {},
	description: {
		maxCharacters: Validators.maxCharacters(250),
	},
	managementType: {
		required: Validators.required,
	},
}

/*
 * Search
 */

export const objectSearchKeys: (keyof PropertyObject)[] = ['title', 'description', 'extId']
export const objectaddressSearchKeys: (keyof ObjectAddress)[] = [
	...(prefixSearchKeys('address', addressSearchKeys) as (keyof ObjectAddress)[]),
]

export function objectSort(objects: Object[]) {
	return sort(objects, 'title', 'asc', 'natural')
}

/*
 * Api
 */

/*
 * Functions
 */

const createCsv = (header: any[], rows: any[]) => {
	return Papa.unparse(
		{
			// values
			fields: header,
			data: rows,
		},
		{
			// config
			quotes: true,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ';', // a semicolon so Excel opens it correctly with a double click
			newline: '\r\n',
			skipEmptyLines: true,
		},
	)
}

export function createObjectCsv($i18n: NuxtI18nInstance, datasets: { object: PropertyObject; units: Unit[] }[]) {
	const header = [
		$i18n.t('domain.object.fields.title'),
		$i18n.t('domain.object.fields.extId'),
		$i18n.t('domain.object.managementType.label'),
		$i18n.t('layout.description'),
		$i18n.t('domain.object.fields.numberOfUnits'),
		$i18n.t('domain.unit.edit.share.base'),
		$i18n.t('domain.unit.edit.area.base'),
		$i18n.t('domain.unit.edit.type.options.apartment.label'),
		$i18n.t('domain.unit.edit.type.options.business.label'),
		$i18n.t('domain.unit.edit.type.options.room.label'),
		$i18n.t('domain.unit.edit.type.options.garage.label'),
		$i18n.t('domain.unit.edit.type.options.parking_space.label'),
		$i18n.t('domain.object.edit.userAccess.base'),
	]

	const objectToArray = (object: PropertyObject, units: Unit[]) => {
		return [
			object.title,
			object.extId,
			$i18n.t(`domain.object.managementType.${object.managementType}.long`),
			object.description,
			object.numberOfUnits,
			object.totalShares,
			object.totalArea,
			units.filter((unit) => unit.type === UnitType.apartment).length,
			units.filter((unit) => unit.type === UnitType.business).length,
			units.filter((unit) => unit.type === UnitType.room).length,
			units.filter((unit) => unit.type === UnitType.garage).length,
			units.filter((unit) => unit.type === UnitType.parking_space).length,
			$i18n.t(object.userAccess ? 'layout.yes' : 'layout.no'),
		]
	}

	// build rows
	const rows = []
	datasets.forEach((dataset) => rows.push(objectToArray(dataset.object, dataset.units)))

	return createCsv(header, rows)
}

export function createObjectAddressCsv(
	$i18n: NuxtI18nInstance,
	datasets: {
		object: PropertyObject
		objectAddresses: ObjectAddress[]
		units: Unit[]
	}[],
) {
	const header = [
		$i18n.t('domain.object.fields.title'),
		$i18n.t('domain.object.fields.extId'),
		$i18n.t('domain.object.managementType.label'),
		$i18n.t('domain.unit.edit.share.base'),
		$i18n.t('domain.unit.edit.area.base'),

		$i18n.tc('domain.unit.base', 2),
		$i18n.t('domain.unit.edit.type.options.apartment.label'),
		$i18n.t('domain.unit.edit.type.options.business.label'),
		$i18n.t('domain.unit.edit.type.options.room.label'),
		$i18n.t('domain.unit.edit.type.options.garage.label'),
		$i18n.t('domain.unit.edit.type.options.parking_space.label'),

		$i18n.tc('domain.address.street', 1),
		$i18n.tc('domain.address.houseNumber', 1),
		$i18n.tc('domain.address.zip', 1),
		$i18n.tc('domain.address.city', 1),
		$i18n.tc('domain.address.country', 1),

		$i18n.t('domain.objectAddress.properties.buildingType.label'),
		$i18n.t('domain.objectAddress.properties.constructionFinishedOn.label'),
		$i18n.t('domain.objectAddress.properties.coreRenovatedOn.label'),
		$i18n.t('domain.objectAddress.properties.heatingDatedOn.label'),
		$i18n.t('domain.objectAddress.properties.heatingType.label'),
		$i18n.t('domain.objectAddress.properties.heatingEnergySource.label'),
		$i18n.t('domain.objectAddress.properties.preservationOrder.label'),
	]

	const objectAddressToArray = (object: PropertyObject, objectAddress: ObjectAddress, units: Unit[]) => {
		const objectAddressUnits = units.filter((unit) => unit.addressId === objectAddress.id)
		return [
			object.title,
			object.extId,
			$i18n.t(`domain.object.managementType.${object.managementType}.long`),
			sumBy(objectAddressUnits, 'share'),
			sumBy(objectAddressUnits, 'area'),

			objectAddressUnits.length,
			objectAddressUnits.filter((unit) => unit.type === UnitType.apartment).length,
			objectAddressUnits.filter((unit) => unit.type === UnitType.business).length,
			objectAddressUnits.filter((unit) => unit.type === UnitType.room).length,
			objectAddressUnits.filter((unit) => unit.type === UnitType.garage).length,
			objectAddressUnits.filter((unit) => unit.type === UnitType.parking_space).length,

			objectAddress.address.street,
			objectAddress.address.houseNumber,
			objectAddress.address.zip,
			objectAddress.address.city,
			objectAddress.address.country,

			objectAddress.buildingType
				? $i18n.t(`domain.objectAddress.properties.buildingType.options.${objectAddress.buildingType}.label`)
				: '',
			objectAddress.constructionFinishedOn,
			objectAddress.coreRenovatedOn,
			objectAddress.heatingDatedOn,
			objectAddress.heatingType
				? $i18n.t(`domain.objectAddress.properties.heatingType.options.${objectAddress.heatingType}.label`)
				: '',
			objectAddress.heatingEnergySource
				? $i18n.t(
						`domain.objectAddress.properties.heatingEnergySource.options.${objectAddress.heatingEnergySource}.label`,
				  )
				: '',
			objectAddress.preservationOrder
				? $i18n.t(
						`domain.objectAddress.properties.preservationOrder.options.${objectAddress.preservationOrder}.label`,
				  )
				: '',
		]
	}

	// build rows
	const rows = []
	datasets.forEach((dataset) =>
		dataset.objectAddresses.forEach((objectAddress) =>
			rows.push(objectAddressToArray(dataset.object, objectAddress, dataset.units)),
		),
	)

	return createCsv(header, rows)
}

function createObjectList($i18n: NuxtI18nInstance, objects: PropertyObject[]) {
	// transforms an object to an array of values that match the above header
}

export function createObjectUserCsv(
	$i18n: NuxtI18nInstance,
	datasets: {
		object: PropertyObject
		objectAddresses: ObjectAddress[]
		units: Unit[]
		contracts: Contract[]
	}[],
) {
	// headers as expected from MS Outlook
	const header = [
		// first addressee
		`Person ${$i18n.t('domain.contract.signatory.edit.salutation.base')}`,
		`Person ${$i18n.t('domain.contract.signatory.edit.firstName.base')}`,
		`Person ${$i18n.t('domain.contract.signatory.edit.lastName.base')}`,
		`Person ${$i18n.t('domain.contract.signatory.isAddressee')}`,
		`Person ${$i18n.t('domain.contract.signatory.edit.deliveryInstructions.base')}`,
		`Person ${$i18n.tc('domain.address.street', 1)}`,
		`Person ${$i18n.tc('domain.address.city', 1)}`,
		`Person ${$i18n.t('domain.address.zip')}`,
		`Person ${$i18n.tc('domain.address.country', 1)}`,
		`Person ${$i18n.t('domain.contract.signatory.edit.email.base')}`,
		`Person ${$i18n.t('domain.phone.work')}`,
		`Person ${$i18n.t('domain.phone.landline')}`,
		`Person ${$i18n.t('domain.phone.mobile')}`,

		// second addressee
		`Person 2 ${$i18n.t('domain.contract.signatory.edit.salutation.base')}`,
		`Person 2 ${$i18n.t('domain.contract.signatory.edit.firstName.base')}`,
		`Person 2 ${$i18n.t('domain.contract.signatory.edit.lastName.base')}`,

		// object
		`${$i18n.tc('domain.object.base', 1)} ${$i18n.t('domain.object.fields.title')}`,
		`${$i18n.tc('domain.object.base', 1)} ${$i18n.t('domain.object.fields.extId')}`,

		// unit
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.t('domain.unit.edit.numberDivision.full')}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.t('domain.unit.edit.extId.base')}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.t('domain.unit.edit.share.base')}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.t('domain.unit.edit.area.base')}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.t('domain.unit.edit.position.base')}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.tc('domain.address.base', 1)} ${$i18n.tc(
			'domain.address.street',
			1,
		)}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.tc('domain.address.base', 1)} ${$i18n.tc(
			'domain.address.houseNumber',
			1,
		)}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.tc('domain.address.base', 1)} ${$i18n.tc('domain.address.zip', 1)}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.tc('domain.address.base', 1)} ${$i18n.tc(
			'domain.address.city',
			1,
		)}`,
		`${$i18n.tc('domain.unit.base', 1)} ${$i18n.tc('domain.address.base', 1)} ${$i18n.tc(
			'domain.address.country',
			1,
		)}`,

		// contract
		`${$i18n.tc('domain.contract.base', 1)} ${$i18n.tc('domain.user.role.base', 1)}`,
		$i18n.t('domain.contract.signatory.isAgent'),
		`${$i18n.tc('domain.contract.base', 1)} ${$i18n.t('domain.contract.validity.status')}`,
		`${$i18n.tc('domain.contract.base', 1)} ${$i18n.t('domain.contract.edit.extId.base')}`,
		`${$i18n.tc('domain.contract.base', 1)} ${$i18n.t('domain.contract.edit.isPostal.base')}`,
		`${$i18n.tc('domain.contract.base', 1)} ${$i18n.t('domain.contract.edit.validFrom.base')}`,
		`${$i18n.tc('domain.contract.base', 1)} ${$i18n.t('domain.contract.edit.validTo.base')}`,
		// 'Kategorien',
	]

	const rows = datasets.reduce((rows, dataset) => {
		return [
			...rows,
			...createObjectUserList($i18n, dataset.object, dataset.objectAddresses, dataset.units, dataset.contracts),
		]
	}, [])

	return createCsv(header, rows)
}
export function createObjectRegistrationStatisticsCsv(
	$i18n: NuxtI18nInstance,
	datasets: {
		object: PropertyObject
		objectAddresses: ObjectAddress[]
		units: Unit[]
		contracts: Contract[]
	}[],
) {
	// headers
	const header = [
		// object
		$i18n.t('domain.object.fields.title'),
		$i18n.t('domain.object.fields.extId'),
		$i18n.t('domain.object.managementType.label'),
		$i18n.t('domain.object.edit.userAccess.base'),
		$i18n.t('domain.object.fields.numberOfUnits'),
		$i18n.t('domain.object.registrationStatistics.unitsCount'),

		// statistics
		$i18n.t('domain.object.registrationStatistics.unitsWithOwners.all'),
		$i18n.t('domain.object.registrationStatistics.unitsWithOwners.registered'),
		$i18n.t('domain.object.registrationStatistics.unitsWithOwners.invited'),
		$i18n.t('domain.object.registrationStatistics.unitsWithOwners.offline'),

		$i18n.t('domain.object.registrationStatistics.unitsWithTenants.all'),
		$i18n.t('domain.object.registrationStatistics.unitsWithTenants.registered'),
		$i18n.t('domain.object.registrationStatistics.unitsWithTenants.invited'),
		$i18n.t('domain.object.registrationStatistics.unitsWithTenants.offline'),

		$i18n.t('domain.object.registrationStatistics.owners.all'),
		$i18n.t('domain.object.registrationStatistics.owners.registered'),
		$i18n.t('domain.object.registrationStatistics.owners.invited'),
		$i18n.t('domain.object.registrationStatistics.owners.offline'),

		$i18n.t('domain.object.registrationStatistics.tenants.all'),
		$i18n.t('domain.object.registrationStatistics.tenants.registered'),
		$i18n.t('domain.object.registrationStatistics.tenants.invited'),
		$i18n.t('domain.object.registrationStatistics.tenants.offline'),

		$i18n.t('domain.object.registrationStatistics.ownerAgents.all'),
		$i18n.t('domain.object.registrationStatistics.ownerAgents.registered'),
		$i18n.t('domain.object.registrationStatistics.ownerAgents.invited'),
		$i18n.t('domain.object.registrationStatistics.ownerAgents.offline'),
	]

	const rows = datasets.reduce((rows, dataset) => {
		const stats = getObjectRegistrationStatistics(
			dataset.object,
			dataset.objectAddresses,
			dataset.units,
			dataset.contracts,
		)

		return [
			...rows,
			[
				// object
				dataset.object.title,
				dataset.object.extId,
				$i18n.t(`domain.object.managementType.${dataset.object.managementType}.long`),
				$i18n.t(dataset.object.userAccess ? 'layout.yes' : 'layout.no'),
				dataset.object.numberOfUnits,
				dataset.units.length,

				// statistics

				stats.unitsWithOwners.all,
				stats.unitsWithOwners.registered,
				stats.unitsWithOwners.invited,
				stats.unitsWithOwners.offline,

				stats.unitsWithTenants.all,
				stats.unitsWithTenants.registered,
				stats.unitsWithTenants.invited,
				stats.unitsWithTenants.offline,

				stats.owners.all,
				stats.owners.registered,
				stats.owners.invited,
				stats.owners.offline,

				stats.tenants.all,
				stats.tenants.registered,
				stats.tenants.invited,
				stats.tenants.offline,

				stats.ownerAgents.all,
				stats.ownerAgents.registered,
				stats.ownerAgents.invited,
				stats.ownerAgents.offline,
			],
		]
	}, [])

	return createCsv(header, rows)
}

function createObjectUserList(
	$i18n: NuxtI18nInstance,
	object: PropertyObject,
	objectAddresses: ObjectAddress[],
	units: Unit[],
	contracts: Contract[],
) {
	interface SignatoryData {
		signatory: Signatory
		contract: Contract
		unit: Unit
		objectAddress: ObjectAddress
	}

	// transforms a signatory to an array of values that match the above header
	const signatoryToArray = (data: SignatoryData) => {
		const contactInformation = getMergedSignatoryContactInformation(data.signatory)
		// if it's a tenant and the address is empty, we fill in the object address
		if (data.contract.contractType === ContractType.tenant && !contactInformation.address) {
			contactInformation.address = data.objectAddress.address
		}

		let secondAddresseeContactInformation: ContactInformation = null
		// we add second addressee data if
		// - this signatory is the only addressee
		// AND
		// - there is exactly one more non-agent
		if (
			data.signatory.isAddressee &&
			data.contract.signatories.filter((s) => s.isAddressee).length === 1 &&
			data.contract.signatories.filter((s) => !s.isAddressee && !s.isAgent).length === 1
		) {
			secondAddresseeContactInformation = getMergedSignatoryContactInformation(
				data.contract.signatories.find((s) => !s.isAddressee && !s.isAgent),
			)
		}

		return [
			// first addressee
			contactInformation.salutation,
			contactInformation.firstName,
			contactInformation.lastName,
			$i18n.t(data.signatory.isAddressee ? 'layout.yes' : 'layout.no'),
			contactInformation.deliveryInstructions,
			(contactInformation.address &&
				`${contactInformation.address.street} ${contactInformation.address.houseNumber}`) ||
				'',
			(contactInformation.address && contactInformation.address.city) || '',
			(contactInformation.address && contactInformation.address.zip) || '',
			(contactInformation.address && ISO_3166_1[contactInformation.address.country]) || '',
			// (contactInformation.address &&
			// 	contactInformation.address.country) ||
			// 	'',
			contactInformation.emailAddress,
			contactInformation.phoneWork,
			contactInformation.phoneLandline,
			contactInformation.phoneMobile,

			// second addressee
			secondAddresseeContactInformation ? secondAddresseeContactInformation.salutation : null,
			secondAddresseeContactInformation ? secondAddresseeContactInformation.firstName : null,
			secondAddresseeContactInformation ? secondAddresseeContactInformation.lastName : null,

			// object
			object.title,
			object.extId,

			// unit
			data.unit.number,
			data.unit.extId,
			data.unit.share,
			data.unit.area,
			data.unit.position,
			data.objectAddress.address.street,
			data.objectAddress.address.houseNumber,
			data.objectAddress.address.zip,
			data.objectAddress.address.city,
			ISO_3166_1[data.objectAddress.address.country],

			// contract
			$i18n.tc(`domain.user.role.${data.contract.contractType}`, 1),
			$i18n.t(data.signatory.isAgent ? 'layout.yes' : 'layout.no'),
			isActiveContract(data.contract)
				? $i18n.t('domain.contract.active')
				: isPastContract(data.contract)
				? $i18n.t('domain.contract.state.past')
				: $i18n.t('domain.contract.state.future'),
			data.contract.extId,
			$i18n.t(data.contract.isPostal ? 'layout.yes' : 'layout.no'),
			$i18n.t(formatDate($i18n, data.contract.validFrom, FORMAT_NUMBERS, PRECISION_DAY)),
			$i18n.t(
				data.contract.validTo ? formatDate($i18n, data.contract.validTo, FORMAT_NUMBERS, PRECISION_DAY) : '',
			),
			// [ // categories
			// 	`Objekt object.extId (object.title)`, // object
			// 	translateRole(signatory.contract.contractType as string as RoleType), // role
			// ].join(';'),
		]
	}

	// build rows
	return (
		contracts
			// .filter(contract => contract.isActive) // only active contracts? no,
			.map((contract) =>
				contract.signatories.map((signatory) => {
					const unit = units.find((unit) => unit.id === contract.unitId)
					return {
						signatory,
						contract,
						objectAddress: objectAddresses.find((a) => a.id === unit.addressId),
						unit,
					}
				}),
			)
			.flat(1)
			// .filter(signatory => signatory.isTheOneWeWantToExport) // export only marked signatories
			.map(signatoryToArray)
	)
}

interface SignatoryRecord {
	signatory: Signatory
	contract: Contract
}

interface Person {
	isOnline: boolean
	isRegistered: boolean
	signatoryRecords: SignatoryRecord[]
}

interface RegistrationNumbers {
	all: number
	registered: number
	invited: number
	offline: number
}

interface ObjectRegistrationStatistics {
	unitsWithOwners: RegistrationNumbers
	unitsWithTenants: RegistrationNumbers
	persons: RegistrationNumbers
	owners: RegistrationNumbers
	ownerAgents: RegistrationNumbers
	tenants: RegistrationNumbers
	tenantAgents: RegistrationNumbers
}

function getObjectRegistrationStatistics(
	object: PropertyObject,
	objectAddresses: ObjectAddress[],
	units: Unit[],
	contracts: Contract[],
): ObjectRegistrationStatistics {
	// get all signatories from active contracts and create a signatory/contract pair

	const activeContractSignatoryRecords: SignatoryRecord[] = []
	contracts.forEach((contract) => {
		// only consider active contracts
		if (!isActiveContract(contract)) {
			return
		}
		contract.signatories.forEach((signatory) => {
			activeContractSignatoryRecords.push({
				signatory,
				contract,
			})
		})
	})

	// group them by person
	const signatoryRecordsWithoutUser = activeContractSignatoryRecords.filter(
		(record) => !hasOnlineAccess(record.signatory),
	)
	const signatoryRecordsGroupedByUser = Object.values(
		groupBy(
			activeContractSignatoryRecords.filter((record) => hasOnlineAccess(record.signatory)),
			'signatory.user.id',
		),
	)

	// persons in active contracts
	const personsInActiveContracts: Person[] = [
		...signatoryRecordsWithoutUser.map((record) => ({
			isOnline: false,
			isRegistered: false,
			signatoryRecords: [record],
		})),
		...signatoryRecordsGroupedByUser.map((signatoryRecords) => ({
			isOnline: true,
			isRegistered: signatoryRecords.some((signatoryRecord) => signatoryRecord.signatory.user.isRegistered),
			signatoryRecords,
		})),
	]

	const hasRoleType = (person: Person, contractType: ContractType, isAgent: boolean) => {
		return person.signatoryRecords.some((signatoryRecord) => {
			return (
				signatoryRecord.contract.contractType === contractType && signatoryRecord.signatory.isAgent === isAgent
			)
		})
	}

	const persons = {
		all: personsInActiveContracts,
		offline: personsInActiveContracts.filter((person) => !person.isOnline),
		invited: personsInActiveContracts.filter((person) => person.isOnline && !person.isRegistered),
		registered: personsInActiveContracts.filter((person) => person.isOnline && person.isRegistered),
	}

	const owners = {
		all: persons.all.filter((person) => hasRoleType(person, ContractType.owner, false)),
		offline: persons.offline.filter((person) => hasRoleType(person, ContractType.owner, false)),
		invited: persons.invited.filter((person) => hasRoleType(person, ContractType.owner, false)),
		registered: persons.registered.filter((person) => hasRoleType(person, ContractType.owner, false)),
	}

	const ownerAgents = {
		all: persons.all.filter((person) => hasRoleType(person, ContractType.owner, true)),
		offline: persons.offline.filter((person) => hasRoleType(person, ContractType.owner, true)),
		invited: persons.invited.filter((person) => hasRoleType(person, ContractType.owner, true)),
		registered: persons.registered.filter((person) => hasRoleType(person, ContractType.owner, true)),
	}

	const tenants = {
		all: persons.all.filter((person) => hasRoleType(person, ContractType.tenant, false)),
		offline: persons.offline.filter((person) => hasRoleType(person, ContractType.tenant, false)),
		invited: persons.invited.filter((person) => hasRoleType(person, ContractType.tenant, false)),
		registered: persons.registered.filter((person) => hasRoleType(person, ContractType.tenant, false)),
	}

	const tenantAgents = {
		all: persons.all.filter((person) => hasRoleType(person, ContractType.tenant, true)),
		offline: persons.offline.filter((person) => hasRoleType(person, ContractType.tenant, true)),
		invited: persons.invited.filter((person) => hasRoleType(person, ContractType.tenant, true)),
		registered: persons.registered.filter((person) => hasRoleType(person, ContractType.tenant, true)),
	}

	const hasAccessToUnit = (person: Person, unit: Unit) => {
		return person.signatoryRecords.some((signatoryRecord) => signatoryRecord.contract.unitId === unit.id)
	}

	// units with owners
	const unitsWithOwner = {
		all: [],
		offline: [],
		invited: [],
		registered: [],
	}

	units.forEach((unit) => {
		// check if has any
		const hasAnyOwner = owners.all.some((person) => hasAccessToUnit(person, unit))
		if (hasAnyOwner) {
			unitsWithOwner.all.push(unit)
		}

		// check if has at least one registered
		const hasRegisteredOwner = owners.registered.some((person) => hasAccessToUnit(person, unit))
		if (hasRegisteredOwner) {
			unitsWithOwner.registered.push(unit)
			return
		}

		// check if has at least one invited
		const hasInvitedOwner = owners.invited.some((person) => hasAccessToUnit(person, unit))
		if (hasInvitedOwner) {
			unitsWithOwner.invited.push(unit)
			return
		}

		// otherwise offline
		unitsWithOwner.offline.push(unit)
	})

	// units with tenants
	const unitsWithTenant = {
		all: [],
		offline: [],
		invited: [],
		registered: [],
	}

	units.forEach((unit) => {
		// check if has any
		const hasAnyTenant = tenants.all.some((person) => hasAccessToUnit(person, unit))
		if (hasAnyTenant) {
			unitsWithTenant.all.push(unit)
		}

		// check if has at least one registered
		const hasRegisteredTenant = tenants.registered.some((person) => hasAccessToUnit(person, unit))
		if (hasRegisteredTenant) {
			unitsWithTenant.registered.push(unit)
			return
		}

		// check if has at least one invited
		const hasInvitedTenant = tenants.invited.some((person) => hasAccessToUnit(person, unit))
		if (hasInvitedTenant) {
			unitsWithTenant.invited.push(unit)
			return
		}

		// otherwise offline
		unitsWithTenant.offline.push(unit)
	})

	return {
		unitsWithOwners: {
			all: unitsWithOwner.all.length,
			registered: unitsWithOwner.registered.length,
			invited: unitsWithOwner.invited.length,
			offline: unitsWithOwner.offline.length,
		},
		unitsWithTenants: {
			all: unitsWithTenant.all.length,
			registered: unitsWithTenant.registered.length,
			invited: unitsWithTenant.invited.length,
			offline: unitsWithTenant.offline.length,
		},
		persons: {
			all: persons.all.length,
			registered: persons.registered.length,
			invited: persons.invited.length,
			offline: persons.offline.length,
		},
		owners: {
			all: owners.all.length,
			registered: owners.registered.length,
			invited: owners.invited.length,
			offline: owners.offline.length,
		},
		ownerAgents: {
			all: ownerAgents.all.length,
			registered: ownerAgents.registered.length,
			invited: ownerAgents.invited.length,
			offline: ownerAgents.offline.length,
		},
		tenants: {
			all: tenants.all.length,
			registered: tenants.registered.length,
			invited: tenants.invited.length,
			offline: tenants.offline.length,
		},
		tenantAgents: {
			all: tenantAgents.all.length,
			registered: tenantAgents.registered.length,
			invited: tenantAgents.invited.length,
			offline: tenantAgents.offline.length,
		},
	}
}
