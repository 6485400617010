import { Pixels, UrlString } from '../../domain/Types'
import { ImageAsset, ImageSha1AndName } from '../../domain/assets/Image'

export type ImageTransform = 'resize' | 'crop' | 'download'

// Images to be shown when no image is available.
// todo: use images from local package
const FallbackImagePaths = {
	default: '/images/thumbs/image_missing.jpg',
	photo: '/images/thumbs/image_missing.jpg',
	avatar: '/images/thumbs/image_avatar.png',
}

export type FallbackImage = keyof typeof FallbackImagePaths

/**
 * Overall this needs a few adjustments.
 * Replace maxHeight, maxWidth and transformation with a transform object
 * Adjust the methods to transform consequently
 * You could resize, crop, and download in the same transform
 */
export const imageSrc = (
	image: ImageAsset | null = null,
	maxWidth?: Pixels,
	maxHeight?: Pixels,
	transformation: ImageTransform = 'resize',
	fallback: FallbackImage = 'default',
): UrlString => {
	// todo: remove me in feature/cloud-storage-unification, after images are fully migrated to the bucket
	if (image?.collectionName === 'persistent') {
		return legacyUrl(image, transformation, maxWidth, maxHeight, fallback)
	}
	return url(image, transformation, maxWidth, maxHeight, fallback)
}

/**
 * Generates the image URL to the twicpics media gateway
 *
 * For transformations see https://www.twicpics.com/docs/reference/transformations
 */
function url(
	image: ImageAsset | null = null,
	transformation?: ImageTransform,
	width?: Pixels,
	height?: Pixels,
	fallback: FallbackImage = 'default',
): UrlString {
	if (!image) {
		return FallbackImagePaths[fallback]
	}

	// https://img.etg24.de/img-test/sha
	let url = `https://img.etg24.de/${getTwicpicsPath(image)}/${image.sha1}`

	let transformations: string[] = []

	if (!width && !height && transformation !== 'download') {
		return url
	}

	switch (transformation) {
		case 'resize':
			transformations = [...transformations, `resize=${width ?? '-'}${height ? `x${height}` : ''}`]
			break
		case 'crop':
			transformations = [...transformations, `crop=${width ?? '-'}${height ? `x${height}` : ''}`]
			break
		case 'download':
			transformations = [...transformations, `download=${image.id}.jpeg`, 'output=jpeg']
			break
	}
	if (transformations.length > 0) {
		url += '?twic=v1/' + transformations.join('/')
	}

	return url
}

const getTwicpicsPath = (image: ImageAsset) => {
	if ($nuxt.$config.apiDomain == 'etg24test.de') {
		// todo: remove when we replaced staging with deploy previews
		return 'img-test'
	}
	if ($nuxt.$config.deployPreview) {
		return `preview/${$nuxt.$config.deployPreview}`
	}
	return 'img'
}

/**
 * Returns the URL to the desired Image, with optional GD-lib filters applied.
 */
export function legacyUrl(
	image: ImageSha1AndName | ImageAsset,
	filter?: ImageTransform,
	width?: Pixels,
	height?: Pixels,
	fallback: FallbackImage = 'default',
): UrlString {
	function ensureImageUrl(subject?: ImageSha1AndName | ImageAsset): ImageSha1AndName | null {
		if (!subject) {
			return null
		}

		if (typeof subject === 'string') {
			return subject
		}

		if (typeof subject === 'object') {
			return subject.src
		}

		return null
	}
	const legacyImageUrl = ensureImageUrl(image)
	if (!legacyImageUrl) {
		return FallbackImagePaths[fallback]
	}
	const ImageEndpointFilters = {
		resize: 'f=resize&w={width}&h={height}',
		crop: 'f=crop&w={width}&h={height}',
	}
	const ImageEndpoint = '/img/{url}'

	let url = ImageEndpoint.replace('{url}', legacyImageUrl)

	if (filter && filter in ImageEndpointFilters) {
		url +=
			'?' +
			ImageEndpointFilters[filter]
				.replace('{width}', String(width || ''))
				.replace('{height}', String(height || ''))
	}

	return `https://images.${$nuxt['$store'].getters['api/override'] || $nuxt['$store'].$config.apiDomain}${url}`
}
