import {
	format as dateFnsFormat,
	isThisYear,
	isToday,
	isTomorrow,
	isYesterday,
	isThisISOWeek,
	addWeeks,
} from 'date-fns'
import en from 'date-fns/locale/en-US'
import de from 'date-fns/locale/de'
import { DateString, DayString } from '~/app/domain/Types'
import { unref } from '@nuxt/bridge/dist/runtime'

const locales = {
	default: en,
	de,
	en,
}
export const getDateFnsLocaleFromI18n = (i18n: any) => {
	return locales[unref(i18n.locale)] || locales.default
}

// The expected input type for this filter
export type DateValue = Date | DayString | DateString

// necessary to translate the format
export type dateFormattingTranslator = (string) => string

// Available complex formats
export const FORMAT_SHORT = 'short'
export const FORMAT_FULL = 'full'
export const FORMAT_NUMBERS = 'numbers'
export const FORMAT_MONTH = 'month'
export const FORMAT_TIME = 'time'
export const FORMAT_WEEK = 'week'
export const FORMAT_DEFAULT = FORMAT_SHORT

export type dateFormattingStrategy = 'short' | 'full' | 'numbers' | 'month' | 'time' | 'week'

// Available precisions
export const PRECISION_DAY = 'day'
export const PRECISION_MINUTE = 'minute'
export const PRECISION_DEFAULT = PRECISION_DAY

export type dateFormattingPrecision = 'day' | 'minute'

/**
 * formats the provided date using the desired format
 */
export default function formatDate(
	i18n: any,
	value: DateValue,
	formattingStrategy: dateFormattingStrategy = FORMAT_DEFAULT,
	precision: dateFormattingPrecision = PRECISION_DEFAULT,
): string {
	if (!i18n || !value) return ''

	const date = value instanceof Date ? value : new Date(value)
	let template = ''

	switch (formattingStrategy) {
		case FORMAT_SHORT:
			template = getShortTemplate(i18n, date, precision)
			break

		case FORMAT_FULL:
			template = getFullTemplate(i18n, date, precision)
			break

		case FORMAT_NUMBERS:
			template = getNumbersTemplate(i18n, date, precision)
			break

		case FORMAT_MONTH:
			template = getMonthTemplate(i18n, date, precision)
			break

		case FORMAT_WEEK:
			template = getWeekTemplate(i18n, date, precision)
			break

		case FORMAT_TIME:
			template = getTimeTemplate(i18n, date, precision)
			break
	}

	try {
		// fixme: this is an ugly workaround for the escaping syntax of dateFns: two ' are interpreted as an escaped ', which occurs when two escaped strings are glued together
		// example: 'foo' => foo, '' => ', 'today'' at ' => today' at
		// so we remove two concatenated ' because we assume we don't have escaped ' in our templates
		template = template.replace("''", '')

		return dateFnsFormat(date, template, {
			locale: getDateFnsLocaleFromI18n(i18n),
		})
	} catch (e) {
		// fallback if the dateFns format function errors out, most probably because it couldn't read the template right
		return dateFnsFormat(date, '[yyyy-MM-dd]')
	}
}

const getShortTemplate = (i18n: any, date: Date, precision: dateFormattingPrecision) => {
	switch (true) {
		case isToday(date):
			return precision === PRECISION_MINUTE ? getTimeTemplate(i18n, date, precision) : i18n.t('dates.today')
		case isYesterday(date):
			let yesterday = i18n.t('dates.yesterday')
			if (precision === PRECISION_MINUTE) {
				yesterday += i18n.t('dates.dateTimeGlue') + getTimeTemplate(i18n, date, precision)
			}
			return yesterday
		case isTomorrow(date):
			let tomorrow = i18n.t('dates.tomorrow')
			if (precision === PRECISION_MINUTE) {
				tomorrow += i18n.t('dates.dateTimeGlue') + getTimeTemplate(i18n, date, precision)
			}
			return tomorrow
		case isThisYear(date):
			return i18n.t('dates.monthDay')
		default:
			return i18n.t('dates.yearMonthDay')
	}
}

const getFullTemplate = (i18n: any, date: Date, precision: dateFormattingPrecision) => {
	let result = i18n.t('dates.full')
	if (precision === PRECISION_MINUTE) {
		result += i18n.t('dates.dateTimeGlue') + getTimeTemplate(i18n, date, precision)
	}
	return result
}

const getNumbersTemplate = (i18n: any, date: Date, precision: dateFormattingPrecision) => {
	let result = i18n.t('dates.yearMonthDay')
	if (precision === PRECISION_MINUTE) {
		result += ' ' + getTimeTemplate(i18n, date, precision)
	}
	return result
}

const getMonthTemplate = (i18n: any, date: Date, precision: dateFormattingPrecision) => {
	return isThisYear(date) ? i18n.t('dates.month') : i18n.t('dates.yearMonth')
}

const getWeekTemplate = (i18n: any, date: Date, precision: dateFormattingPrecision) => {
	if (isThisYear(date)) {
		if (isThisISOWeek(date)) {
			return i18n.t('dates.thisWeek')
		}
		if (isThisISOWeek(addWeeks(date, 1))) {
			return i18n.t('dates.lastWeek')
		}
		if (isThisISOWeek(addWeeks(date, -1))) {
			return i18n.t('dates.nextWeek')
		}
		return i18n.t('dates.week')
	}
	return i18n.t('dates.weekYear')
}

const getTimeTemplate = (i18n: any, date: Date, precision: dateFormattingPrecision) => {
	return i18n.t('dates.time')
}
