
	import { Component, Prop, Vue, Model } from 'nuxt-property-decorator'
	import { Object } from '~/app/domain/property/Object'
	import Image from '~/app/utils/globals/Image'

	@Component({})
	export default class ObjectCard extends Vue {
		@Model('change', { required: true }) object: Object
		@Prop({ default: null }) to: string
		@Prop({ type: String, default: null }) icon: string

		Image = Image

		get mobile() {
			return this.$vuetify.breakpoint.smAndDown
		}
	}
