import { ShortString, Uuid } from '../Types'
import { RoleType } from '../identifyAndAccess/Role'
import { Entity } from '../common/Interfaces'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { makeHashMap } from '~/app/domain/utility/ContentUtilities'
import { contactPersonResponsibilitySort, Responsibility } from '~/app/domain/content/ContactPerson'

/*
 * Model
 */

export type CategoryGroupId = Uuid
export interface CategoryGroup {
	id: CategoryGroupId
	title: ShortString
	roleTypes: RoleType[]
	prominence: number
	// description?: LongString
	// color?: HexColor
}

export type FinancialYearMode = 'disabled' | 'optional' | 'enabled'
export type CategoryId = Uuid
export interface Category extends Entity<CategoryId> {
	title: ShortString
	groupId: CategoryGroupId
	prominence: number
	financialYearMode: FinancialYearMode
	// description?: LongString,
	// color?: HexColor,
}

/*
 * Validation
 */

/*
 * Search
 */

export const categoryGroupSearchKeys: (keyof CategoryGroup)[] = [
	'title',
	// 'description',
]
export const categorySearchKeys: (keyof Category)[] = [
	'title',
	// 'description',
	...(prefixSearchKeys('group', categoryGroupSearchKeys) as (keyof Category)[]),
]

/*
 * Api
 */

/*
 * Functions
 */

export interface WithCategoryId {
	categoryId: CategoryId | null
}

export interface WithCategory {
	category: Category & { group: CategoryGroup }
}

export function addCategoryWithGroup<T extends { categoryId: CategoryId }>(
	categories: Category[],
	categoryGroups: CategoryGroup[],
): (item: T) => T & WithCategory {
	// return a non-mutative function if there are no categories
	if (!categories.length || !categoryGroups.length) {
		return (item) => item as any
	}

	const categoryMap = makeHashMap(categories)
	const categoryGroupMap = makeHashMap(categoryGroups)

	return (item: T) => ({
		...(item as any),
		category: {
			...categoryMap[item.categoryId],
			group: {
				...categoryGroupMap[categoryMap[item.categoryId].groupId],
			},
		},
	})
}

export function groupResponsibilities(
	responsibilities: Responsibility[],
	categories: Category[],
	categoryGroups: CategoryGroup[],
) {
	const sortedResponsibilities = contactPersonResponsibilitySort(responsibilities)
	const groups = []
	const categoryMap = makeHashMap(categories)
	const categoryGroupMap = makeHashMap(categoryGroups)

	for (const res of sortedResponsibilities) {
		const category = categoryMap[res.categoryId]
		if (category) {
			let group = groups.find((g) => {
				return g.id === category.groupId
			})
			if (!group) {
				const newGroup = {
					...categoryGroupMap[category.groupId],
					responsibilities: [],
				}
				group = newGroup
				groups.push(group)
			}
			group.responsibilities.push({
				...res,
				category: category,
			})
		}
	}

	return groups
}
