import { CountryCodeString, ShortString } from '../Types'

export interface Address {
	street: ShortString
	houseNumber: ShortString
	zip: ShortString
	city: ShortString
	country: CountryCodeString
	isValid: boolean | null
}

export function addressToString(address: Address, long = false) {
	if (!address) return null
	return `${address.street || ''} ${address.houseNumber || ''}${
		long ? `, ${address.zip || ''} ${address.city || ''}` : ''
	}`
}

export const addressSearchKeys: (keyof Address)[] = ['street', 'houseNumber', 'zip', 'city']

/**
 * Validation
 */
export const vvAddress = {
	street: 'required|min:2',
	houseNumber: 'required',
	zip: 'required|min:3|max:7',
	city: 'required|min:2',
	country: 'required|min:2',
}

export const vvAddressOptional = {
	street: 'min:2',
	houseNumber: '',
	zip: 'min:3|max:7',
	city: 'min:2',
	country: 'min:2',
}
