import Fuse from 'fuse.js'
import { compact, get } from 'lodash'

export function prefixSearchKeys<T extends string, K extends string | number>(prefix: T, keys: K[]) {
	return (keys || []).map((key) => `${prefix}.${key}`) as `${T}.${K}`[]
}

export interface SearchOptions<T> {
	keys: (keyof T)[] | Readonly<(keyof T)[]> // an array of item property paths to search in
	fuzzy?: boolean // if the search should be fuzzy
	sort?: boolean // if the results should be sorted by relevance (only if fuzzy)
}

const defaultSearchOptions = {
	fuzzy: false,
	sort: false,
}

function convertToFuseOptions<T>(options: SearchOptions<T>): Fuse.IFuseOptions<T> {
	return {
		keys: options.keys as string[], // https://fusejs.io/api/options.html#keys
		threshold: options.fuzzy ? 0.25 : 0.125, // https://fusejs.io/api/options.html#threshold
		// minMatchCharLength: 3, // https://fusejs.io/api/options.html#minmatchcharlength
		ignoreLocation: true, // https://fusejs.io/api/options.html#ignorelocation
		shouldSort: options.sort, // https://fusejs.io/api/options.html#shouldsort
	}
}
/**
 * Searches an array of items and returns what are considered matches
 *
 * @param items the items to filter
 * @param searchString the string that should be searched in the items
 * @param options
 * @example
 *
 * search( [...], 'foo', {keys: ['title'] )
 * search( [...], 'bar', {keys: ['title', 'child.description'], fuzzy: true} )
 */
export function search<T>(items: T[], searchString: string, options: SearchOptions<T>): T[] {
	// return if there's no search string
	if (!searchString || !searchString.trim()) {
		return items
	}

	// filter out null or undefined values, this breaks fuzzy.js
	items = items.filter((item) => item !== undefined && item !== null)

	// skip if there's nothing to filter
	if (items.length === 0) {
		return items
	}

	// set defaults
	options = { ...defaultSearchOptions, ...options }

	// Split the provided search term on non-searchterm-characters (i.e. split by non-word-characters)
	const tokens = compact(searchString.split(/[^\w\d.:_\-\u00C0-\u024F]+/g))

	// Reduce array by applying the fuzzy search iteratively for each search term token
	// Special case: use strict filter when a token contains a digit
	return tokens.reduce((arr, token) => {
		return /\d/.test(token) ? filterForNumericString(arr, token, options) : filterFuzzy(arr, token, options)
	}, items)
}

/**
 * This "searches" one single item instead of filtering an array of items.
 *
 * This is basically syntax sugar for the case where you have only one item and want to check if it meets the search criteria
 */
export function searchTestOne<T>(item: T, searchString: string, options: SearchOptions<T>): T | null {
	const result = search([item], searchString, options)

	return result.length === 1 ? result[0] : null
}

/**
 * vue.js filter: applies a fuzzy search algorithm to the provided array
 */
function filterFuzzy<T>(items: T[], searchTerm: string, options: SearchOptions<T>): T[] {
	return new Fuse(items, convertToFuseOptions<T>(options)).search(searchTerm).map((i) => i.item)
}

/**
 * Filters the provided array's items for the desired numeric string.
 */
function filterForNumericString<T>(items: T[], numstr: string, options: SearchOptions<T>): T[] {
	return items.filter((item: any) => {
		// number => convert to string and continue
		if (typeof item === 'number') {
			item = String(item)
		}
		// string => check if numstr is part of it
		if (typeof item === 'string') {
			return (item as string).indexOf(numstr) > -1
		}
		// array => check if numstr is an item
		else if (item instanceof Array) {
			return (item as any[]).indexOf(numstr) > -1
		}
		// object => use options.keys to check key's value against numstr
		else if (item instanceof Object && options && options.keys instanceof Array) {
			return options.keys.some((key) => {
				let val: any = get(item, key)
				if (typeof val === 'number') val = val.toString()
				return typeof val === 'string' && val.indexOf(numstr) > -1
			})
		}

		return false
	})
}
