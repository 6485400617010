export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: (context) => import('~/locales/vue-i18n-config.js').then(m => m.default(context)),
  vueI18nLoader: false,
  locales: [{"code":"de","file":"de_loader.js"},{"code":"en","file":"en_loader.js"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"user.locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"de","file":"de_loader.js"},{"code":"en","file":"en_loader.js"}],
  localeCodes: ["de","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "e",
  4: "_",
  5: "l",
  6: "o",
  7: "a",
  8: "d",
  9: "e",
  10: "r",
  11: ".",
  12: "j",
  13: "s",
  14: "\"",
  15: ":",
  16: "\"",
  17: ".",
  18: ".",
  19: "/",
  20: "l",
  21: "o",
  22: "c",
  23: "a",
  24: "l",
  25: "e",
  26: "s",
  27: "/",
  28: "d",
  29: "e",
  30: "_",
  31: "l",
  32: "o",
  33: "a",
  34: "d",
  35: "e",
  36: "r",
  37: ".",
  38: "j",
  39: "s",
  40: "\"",
  41: ",",
  42: "\"",
  43: "e",
  44: "n",
  45: "_",
  46: "l",
  47: "o",
  48: "a",
  49: "d",
  50: "e",
  51: "r",
  52: ".",
  53: "j",
  54: "s",
  55: "\"",
  56: ":",
  57: "\"",
  58: ".",
  59: ".",
  60: "/",
  61: "l",
  62: "o",
  63: "c",
  64: "a",
  65: "l",
  66: "e",
  67: "s",
  68: "/",
  69: "e",
  70: "n",
  71: "_",
  72: "l",
  73: "o",
  74: "a",
  75: "d",
  76: "e",
  77: "r",
  78: ".",
  79: "j",
  80: "s",
  81: "\"",
  82: "}",
}

export const localeMessages = {
  'de_loader.js': () => import('../../locales/de_loader.js' /* webpackChunkName: "lang-de_loader.js" */),
  'en_loader.js': () => import('../../locales/en_loader.js' /* webpackChunkName: "lang-en_loader.js" */),
}
