import { search } from '~/app/common/filters/iterator/Search'

export const filterFn = (
	elements: Record<string, unknown>[],
	searchTerm: string,
	appliedFilters: Record<string, string | string[]>,
) => {
	if (!searchTerm && !Object.keys(appliedFilters).length) {
		return elements
	}

	let filtered = [...elements]

	if (searchTerm) {
		filtered = search(filtered, searchTerm, {
			keys: Object.keys(filtered[0]),
			fuzzy: true,
			sort: true,
		})
	}

	if (Object.keys(appliedFilters).length) {
		filtered = filtered.filter((element) => {
			return Object.keys(appliedFilters).every((filter) => {
				const filterValue = appliedFilters[filter]
				if (!filterValue) {
					return true
				}
				const elementValue = element[filter]
				if (typeof elementValue === 'string') {
					return filterValue.includes(elementValue)
				}
				return false
			})
		})
	}

	return filtered
}
