
	import { Component, Prop, Vue } from 'nuxt-property-decorator'
	import { ConfigurationOption } from '~/app/common/types/Button'

	@Component({})
	export default class ConfigurableActionMenu extends Vue {
		@Prop({ type: Array, required: true })
		configuration: ConfigurationOption[]

		@Prop({ type: Function, required: true })
		action: (...args: any) => any

		@Prop({ type: String, required: true })
		label: string

		@Prop({ type: String, required: false, default: () => '' })
		icon: string

		@Prop({ type: String, required: false, default: () => '' })
		title: string

		loading = false

		currentConfigObject: Record<string, any> = {}

		onConfigChange(name: string, newValue: boolean) {
			this.currentConfigObject = {
				...this.currentConfigObject,
				[name]: newValue,
			}
		}

		get selected() {
			return this.configuration.map(({ name }) => name).filter((key) => this.currentConfigObject[key])
		}

		async onActionTrigger() {
			try {
				this.loading = true
				await this.action(this.selected)
			} finally {
				this.loading = false
			}
		}
	}
