export const state = () => ({})

export const getters = {
	isInPresentationMode: (state, getters, rootState, rootGetters): boolean => {
		return rootState.route.query.presentationMode !== undefined
	},
}

export const mutations = {
	setPresentationMode: (state, mode, rootState, rootGetters) => {
		$nuxt.$router.push({
			query: {
				// @ts-expect-error because nuxt has a $router property, but it's not in the types
				...$nuxt.$route.query,
				presentationMode: mode,
			},
		})
	},
}

export const actions = {
	activatePresentationMode: (context, mode = null) => {
		context.commit('setPresentationMode', mode)
		window.document.documentElement.requestFullscreen()
	},
	deactivatePresentationMode: (context) => {
		window.document.exitFullscreen()
		context.commit('setPresentationMode', undefined)
	},
}
