import { defer } from 'lodash'
import { Capacitor } from '@capacitor/core'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { FileOpener } from '@capacitor-community/file-opener'
import { DataUriString } from '../../domain/Types'

/**
 * Extracts the last modification date from the provided File instance.
 */
export function getLastModificationDate(file: File): Date {
	if ('lastModified' in file) {
		return new Date((file as any).lastModified)
	}

	// bloody fallback for MS Edge
	if ('lastModifiedDate' in file) {
		return (file as any).lastModifiedDate
	}

	return null
}

/**
 * Checks whether the two provided File instances point towards the same physical file.
 */
export function filesAreEqual(a: File, b: File): boolean {
	return (
		a === b ||
		(a.name === b.name &&
			a.size === b.size &&
			getLastModificationDate(a).getTime() === getLastModificationDate(b).getTime())
	)
}

/**
 * Maps the provided File[] into a Promise of DataUriString[].
 */
export function readDataUrisFromFiles(files: File[]): Promise<(DataUriString | null)[]> {
	return new Promise((resolve) => {
		defer(() => {
			;(window as any).etg24.Utility.readDataUrisFromFiles(files, resolve)
		})
	})
}
export function readDataUriFromFile(file: File): Promise<DataUriString | null> {
	return readDataUrisFromFiles([file]).then((arr) => arr[0])
}

/**
 * Extracts the provided File's title.
 */
export function getFileName(file: File) {
	return file.name
}

/**
 * Extracts the provided File's extension.
 */
export function getFileExtension(file: File) {
	const parts = getFileName(file).split('.')

	return parts.length > 1 ? parts.pop().toUpperCase() : ''
}

export async function getBlobFromUri(uri: DataUriString) {
	const response = await fetch(uri, {
		credentials: 'include',
	})
	return await response.blob()
}

export function blobToBase64(blob: Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onerror = reject
		reader.onload = () => {
			resolve(reader.result as string)
		}
		reader.readAsDataURL(blob)
	})
}

export async function writeFileToDocuments(filename: string, data: string) {
	try {
		const { uri } = await Filesystem.writeFile({
			path: filename,
			data,
			directory: Directory.Documents,
			recursive: true,
		})
		console.log('File written successfully:', uri)
		return uri
	} catch (error) {
		console.error('Error writing file:', error)
	}
}

export async function downloadUrlAsFile(url: string, filename: string) {
	const blob = await getBlobFromUri(url)
	await downloadBlobAsFile(blob, filename)
}

/**
 * Downloads this blob (maybe from an ajax response) as a file download
 */
export async function downloadBlobAsFile(blob: Blob, filename: string) {
	if (Capacitor.isNativePlatform()) {
		const base64Data = await blobToBase64(blob)
		const filePath = (await writeFileToDocuments(filename, base64Data)) as string

		FileOpener.open({
			filePath,
		})
	} else {
		// IE workaround for "HTML7007: One or more blob URLs were revoked by
		// closing the blob for which they were created. These URLs will no
		// longer resolve as the data backing the URL has been freed.
		/// @ts-ignore
		if (typeof window.navigator.msSaveBlob !== 'undefined')
			// @ts-ignore
			return window.navigator.msSaveBlob(blob, filename)

		const URL = window.URL || (window as any).webkitURL
		const downloadUrl = URL.createObjectURL(blob)

		// use HTML5 a[download] attribute to specify filename
		const a = document.createElement('a') as HTMLAnchorElement
		// safari doesn't support this yet
		if (typeof a.download === 'undefined') {
			;(window as any).location = downloadUrl
		} else {
			a.href = downloadUrl
			a.download = filename
			document.body.appendChild(a)
			a.click()
		}

		setTimeout(() => {
			URL.revokeObjectURL(downloadUrl)
		}, 100)
	}
}

/**
 * Downloads this string as a text file
 */
export function downloadStringAsFile(content: string, filename: string) {
	const URL = window.URL || (window as any).webkitURL
	const downloadUrl = URL.createObjectURL(new Blob([content]))

	// use HTML5 a[download] attribute to specify filename
	const a = document.createElement('a') as HTMLAnchorElement
	// safari doesn't support this yet
	if (typeof a.download === 'undefined') {
		;(window as any).location = downloadUrl
	} else {
		a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
		a.setAttribute('download', filename)
		document.body.appendChild(a)
		a.click()
	}

	setTimeout(() => {
		URL.revokeObjectURL(downloadUrl)
	}, 100)
}

// prepends a UTF-8 input BOM to the string before downloading
export function downloadUtf8StringAsFile(content: string, filename: string) {
	downloadStringAsFile('\uFEFF' + content, filename)
}
