
	import { Vue, Component, Prop, Watch, Model } from 'nuxt-property-decorator'
	import bytes from '~/app/common/filters/Bytes'
	import { resolveApiLink } from '~/app/utils/apiLinks'
	import PdfRenderer from '~/components/asset-renderers/PdfRenderer.vue'
	import { getRenderer } from '~/components/asset-renderers/renderUtils'
	import Md from '~/components/utils/Md.vue'

	@Component({
		components: {
			Md,
			PdfRenderer,
		},
	})
	export default class RemoteAssetRenderer extends Vue {
		@Prop({ required: true }) fileExtension: string
		@Prop({ required: true }) endpoint: string
		@Prop({ required: false }) url: string
		@Prop({ default: {} }) queryParameters: {
			[key: string]: string | number | boolean | null
		}

		bytes = bytes

		get renderer() {
			return getRenderer(this.fileExtension)
		}

		get downloadUrl() {
			if (this.url) {
				return `${this.url}&download`
			}
			return resolveApiLink(this.$store, this.endpoint, this.queryParameters)
		}

		get previewUrl() {
			if (this.url) {
				return this.url
			}
			return resolveApiLink(this.$store, this.endpoint, {
				...this.queryParameters,
				preview: true,
			})
		}
	}
