import strip from 'strip'
import { AllHtmlEntities } from 'html-entities'
const entities = new AllHtmlEntities()

/**
 * vue.js filter: removes all HTML tags and converts html entities to their corresponding characters
 */
export default function html2text(subject: string): string {
	if (subject) {
		return entities.decode(
			// escape html characters
			strip(
				// remove html tags
				subject.replace(/(<\/p>|<br\s*\/?>|<\/ul>|<\/ol>|<\/li>|<\/div>)/g, '$1 '), // add a whitespace after block/break html tags
			),
		)
	}

	return subject
}
