import Vue from 'vue';
import DialogComponent from '~/components/utils/ProgrammaticDialog.vue';
// TODO: abstract these helpers for programmatic components
export default (function (_, inject) {
  var DialogConstructor = Vue.extend(DialogComponent);

  // let currentInstance: any = null

  function mountDialog() {
    var rootInstance = getRootInstance();

    // if (currentInstance) {
    // 	currentInstance.onOk()
    // }

    var instance = new DialogConstructor({
      parent: rootInstance
    });
    instance.$mount();
    var etg24Element = document.getElementById('etg24');
    if (!etg24Element) {
      throw new Error('etg24 element not found');
    }
    etg24Element.appendChild(instance.$el);

    // currentInstance = instance

    return instance;
  }
  inject('dialog', function (options) {
    return mountDialog().open(options);
  });
  inject('confirm', function (options) {
    return mountDialog().confirm(options);
  });
});
function getRootInstance() {
  var _document$getElementB;
  // @ts-ignore
  return (_document$getElementB = document.getElementById('__nuxt')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.__vue__;
}