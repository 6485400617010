
	import sleepDiff from '~/app/utils/sleepDiff'
	import { Vue, Component, Watch } from 'nuxt-property-decorator'
	import ConversationDetail from '~/components/domain/conversation/ConversationDetail.vue'
	import ProcessDetail from '~/components/domain/process/ProcessDetail.vue'
	import VotingDetail from '~/components/domain/votings/VotingDetail.vue'
	import EventDetail from '~/components/domain/event/EventDetail.vue'
	import ContactPersonDetail from '~/components/domain/contact_person/ContactPersonDetail.vue'
	import PostDetails from '~/components/domain/post/PostDetails.vue'
	import FaqDetails from '~/components/domain/faq/FaqDetails.vue'
	import DeliveryDetail from '~/components/domain/deliveries/DeliveryDetail.vue'
	import DeliveryBoDetail from '~/components/domain/deliveries/DeliveryBoDetail.vue'
	import InboundEmailDetail from '~/components/domain/email/InboundEmailDetail.vue'
	import ContactDetail from '~/components/domain/contact/ContactDetail.vue'
	@Component({
		components: {
			ContactDetail,
			InboundEmailDetail,
			DeliveryDetail,
			DeliveryBoDetail,
			FaqDetails,
			PostDetails,
			ContactPersonDetail,
			VotingDetail,
			ProcessDetail,
			ConversationDetail,
			EventDetail,
		},
	})
	export default class DetailModal extends Vue {
		get contentData() {
			const type = this.$route.query.contentType
			const ids = (String(this.$route.query.contentId) || '').split('_')
			if (type && ids.length > 0) {
				return {
					type,
					id: ids[0],
					secondaryId: ids.length > 1 ? ids[1] : undefined,
				}
			}
			return null
		}

		content: any = null
		contentDialog = false
		baseUrl: string = null
		loadingContent = false
		failed = false
		typeData = {}

		@Watch('contentData', { immediate: true }) async updateContentData(val, oldVal) {
			if (!this.contentData) {
				this.contentDialog = false
			} else if (val.type !== oldVal?.type || val.id !== oldVal?.id) {
				this.loadingContent = true
				this.contentDialog = true
				await this.loadContent()
			}
		}

		async loadContent(isUpdate: boolean = false) {
			this.failed = false
			const startLoading = +new Date()
			try {
				const td = this.getTypeData()
				if (!td) {
					return
				}
				if (td.payload) {
					this.content = td.payload
				} else if (td.url) {
					this.content = (
						await this.$axios.request({
							url: td.url,
							method: td.method || 'GET',
							params: { ...td.params },
						})
					)?.data
				}
				this.baseUrl = td.pathFn ? td.pathFn(this.content) : null

				// update globally
				if (!isUpdate) await sleepDiff(startLoading, 1000)
				else {
					console.log('contentUpdated:', this.contentData?.type, this.content)
					this.$nuxt.$emit(`contentUpdated:${this.contentData?.type}`, this.content)
				}
			} catch (e) {
				console.error(e)
				this.failed = true
			}

			this.loadingContent = false
		}

		/**
		 * Content CRUD
		 */

		async updateContent() {
			await this.loadContent(true)
		}

		async deleteContent() {
			this.$nuxt.$emit(`contentDeleted:${this.contentData?.type}`, this.content)
			this.closeContent()
		}

		getTypeData() {
			switch (this.contentData?.type) {
				case 'conversation':
					return {
						url: 'etg24:internal:command:communication.conversations.read',
						method: 'POST',
						params: {
							conversation: this.contentData.id,
						},
					}
				case 'faq':
					return {
						url: 'etg24:internal:query:content.faq',
						params: {
							faq: this.contentData.id,
						},
					}
				case 'process':
					return {
						url: 'etg24:internal:query:content.process',
						params: {
							process: this.contentData.id,
						},
						pathFn: (content) => {
							return this.$store.getters['routing/pathToProcesses'](content.process.nodeId, content.nodes)
						},
					}
				case 'voting': {
					return {}
				}
				case 'inboundEmail': {
					return {}
				}
				case 'event':
					return {
						url: 'etg24:internal:query:content.event',
						params: {
							event: this.contentData.id,
						},
						pathFn: (content) => {
							return this.$store.getters['routing/pathToEvents'](content.event.nodeId, content.nodes)
						},
					}
				case 'contact': {
					return {
						url: 'etg24:internal:query:propertymanagement.contact',
						params: {
							contact: this.contentData.id,
						},
					}
				}
				case 'contactPerson': {
					return {
						url: 'etg24:internal:query:content.contactperson',
						params: {
							contactPerson: this.contentData.id,
						},
					}
				}
				case 'contactPersonNode': {
					return {
						url: 'etg24:internal:query:content.contactperson.atnode',
						params: {
							contactPerson: this.contentData.id,
							nodeId: this.contentData.secondaryId,
						},
					}
				}
				case 'post': {
					return {
						url: 'etg24:internal:query:content.post',
						params: {
							post: this.contentData.id,
							node: this.contentData.secondaryId,
						},
					}
				}
				case 'delivery': {
					return {}
				}
				case 'deliveryFo': {
					return {
						url: 'etg24:internal:query:communication.delivery.forreceiver',
						params: {
							delivery: this.contentData.id,
						},
					}
				}
			}
		}

		closeContent() {
			this.contentDialog = false
			this.content = null
			this.$router.push({
				path: this.$route.path,
				query: {
					...this.$route.query,
					contentType: undefined,
					contentId: undefined,
					contentPage: undefined,
				},
			})
		}

		updateContentPl(content) {
			this.content = content
		}

		get isFo() {
			return this.$store.getters['routing/isFrontOffice']
		}

		get presentationMode() {
			return this.$store.getters['ui/isInPresentationMode']
		}
	}
