
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import { Offer, OfferRequest, Order, OrderStatus, Process } from '~/app/domain/content/Process'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'
	import SfSelect from '~/components/domain/conversation/service_forms/blocks/SfSelect.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import DateFt from '~/components/utils/Date.vue'
	import { username } from '~/app/common/filters/domain/User'
	import ReadMore from '~/components/utils/ReadMore.vue'
	import OfferCard from '~/components/domain/process/offer/OfferCard.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import WaitUntilInput from '~/components/inputs/WaitUntilInput.vue'
	import formatDate from '~/app/common/filters/Date'

	@Component({
		components: {
			WaitUntilInput,
			ProviderListItem,
			Attachments,
			ConfirmationDialog,
			ActionButton,
			ReadMore,
			OfferCard,
			SfDate,
			SfSelect,
			SfTextArea,
			SfTextField,
			CategoryContainer,
			DetailLayout,
			DateFt,
		},
	})
	export default class ProcessOrderShow extends Vue {
		@Model('change', { required: false }) process: Process
		@Prop({ required: true }) orderId: string
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		username = username
		order: Order = null

		@Watch('process', { immediate: true, deep: true }) updateOrderId() {
			if (this.orderId) this.order = this.process.orders.find((o) => o.id === this.orderId)
		}

		get info() {
			if (!this.order) {
				return null
			}
			return [
				{
					title: this.$t('domain.process.order.placedOn.show', {
						date: '',
					}),
					value: this.order.placedOn
						? formatDate(this.$i18n, this.order.placedOn, 'numbers', 'minute')
						: null,
				},
				{
					title: this.$t('domain.process.order.properties.deadline.label'),
					type: 'date',
					value: this.order.deadline,
				},
				{
					title: this.$t('domain.process.order.properties.agreedCost.label'),
					value: this.order.agreedCost ? this.order.agreedCost + ' €' : null,
				},
				{
					title: this.$t('domain.process.order.properties.maximumCost.label'),
					value: this.order.maximumCost ? this.order.maximumCost + ' €' : null,
				},
				{
					title: this.$t('domain.process.order.properties.mustBeConfirmed.label'),
					value: this.order.mustBeConfirmed ? this.$t('layout.yes') : this.$t('layout.no'),
				},
			].filter((i) => !!i.value)
		}

		/**
		 * Actions
		 */
		confirmDialog: boolean = false

		async confirmOrder() {
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.confirm', {
					order: this.order.id,
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
				this.confirmDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
		}

		rejectDialog: boolean = false

		async rejectOrder() {
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.reject', {
					order: this.order.id,
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
				this.rejectDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Status change
		 */
		get availableStates() {
			return [
				{
					text: this.$t('domain.process.order.orderStatus.waiting'),
					value: 'waiting',
					callback: async () => {
						try {
							const response = await this.$axios.$post(
								'etg24:internal:command:orders.order.markwaiting',
								{
									order: this.order.id,
								},
							)
							this.$emit('updateProcess', response.process)
						} catch (e) {
							Utils.handleError(e)
						}
					},
				},
				{
					text: this.$t('domain.process.order.orderStatus.active'),
					value: 'active',
					callback: async () => {
						try {
							const response = await this.$axios.$post('etg24:internal:command:orders.order.markactive', {
								order: this.order.id,
							})
							this.$emit('updateProcess', response.process)
						} catch (e) {
							Utils.handleError(e)
						}
					},
				},
				{
					text: this.$t('domain.process.order.orderStatus.done'),
					value: 'done',
					callback: async () => {
						try {
							const response = await this.$axios.$post('etg24:internal:command:orders.order.markdone', {
								order: this.order.id,
							})
							this.$emit('updateProcess', response.process)
						} catch (e) {
							Utils.handleError(e)
						}
					},
				},
			]
		}

		/**
		 * Attachments
		 */
		async uploadAttachment(file) {
			// build the form
			const formData = new FormData()
			formData.append('file', file)
			formData.set('order', this.order.id)

			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:orders.order.attachment.create',
					formData,
					{
						headers: { 'content-type': 'multipart/form-data' },
					},
				)
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}

		async deleteAttachment(file) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.attachment.delete', {
					order: this.order.id,
					attachment: file.id,
				})
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}

		get canChangeStatus() {
			return [OrderStatus.active, OrderStatus.waiting, OrderStatus.done].includes(this.order.orderStatus)
		}

		/**
		 * Snooze
		 */
		async snooze(waitUntil) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.update', {
					order: {
						id: this.order.id,
						waitUntil,
					},
				})
				this.$emit('updateProcess', response.process)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Conversations
		 */
		get conversationBtn() {
			if (this.order.conversation) {
				return {
					icon: Image.getIconForDomain('conversation'),
					text: this.$t('domain.conversation.actions.navigate'),
					callback: () => {
						this.goToConversation(this.order.conversation)
					},
				}
			} else {
				return {
					icon: 'fal fa-comment-plus',
					text: this.$t('domain.conversation.actions.create.label'),
					callback: async () => {
						await this.createConversation()
					},
				}
			}
		}

		creatingConversation: boolean = false
		async createConversation() {
			try {
				this.creatingConversation = true
				const response = await this.$axios.$post('etg24:internal:command:orders.order.createconversation', {
					order: this.order.id,
				})
				this.creatingConversation = false

				await this.goToConversation(response.conversation)

				// notify
				await this.$store.dispatch(
					'snackbar/success',
					this.$t('domain.conversation.actions.create.successMessage'),
				)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		async goToConversation(conversation) {
			await this.$router.push({
				query: {
					...this.$route.query,
					contentType: 'conversation',
					contentId: conversation.id,
					contentPage: undefined,
				},
			})
		}
	}
