import { HtmlString, LongString, ShortString, Uuid } from '../Types'
import { addressSearchKeys } from '../common/Address'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { Category, CategoryGroup, categorySearchKeys, CategoryId, WithCategory } from './/Category'
import { Content, ContentId, Node } from './/Common'
import sort from '~/app/common/filters/iterator/Sort'
import VCard from 'vcf'
import html2text from '~/app/common/filters/text/Html2text'
import { ISO_3166_1 } from '~/app/common/static/ISO_3166_1'
import Papa from 'papaparse'
import { PhoneNumber } from '~/app/domain/common/PhoneNumber'
import { AnyContact, contactTitle } from '~/app/domain/contact/Contact'
import { DeepKeyOf, DeepNotNullableObject } from '@shared/types/helpers'
import { name } from '~/app/common/filters/Name'

/*
 * Model
 */
export type ContactPersonId = ContentId

export interface ContactPerson extends Content<ContactPersonId> {
	company: AnyContact | null
	description: HtmlString | null
}

export type ResponsibilityId = ContentId

export interface Responsibility extends Content<ResponsibilityId> {
	providerId: ProviderId
	contactPersonId: ContactPersonId
	note: LongString
	job: Category
	isInternal: boolean
	categoryId: CategoryId
}

export interface AssembledContactPerson extends ContactPerson {
	responsibilities: (Responsibility & WithCategory)[]
	groups: unknown[]
}

export type ProviderId = Uuid
export interface Provider {
	id: ProviderId
	contactPersonId: ContactPersonId
	company: ShortString | null // just a copy of the contact person's company
	note: ShortString | null
	contact: AnyContact
}

/*
 * Search
 */
export const contactPersonSearchKeys: DeepKeyOf<DeepNotNullableObject<AssembledContactPerson>>[] = [
	'description',
	'company.emailAddress',
	'company.companyName',
	...prefixSearchKeys('company.address', addressSearchKeys),
	...prefixSearchKeys('responsibilities.category', categorySearchKeys),
]

export const providerSearchKeys: DeepKeyOf<Provider>[] = [
	'contact.emailAddress',
	'company',
	'contact.salutation',
	'contact.firstName',
	'contact.lastName',
	'note',
]

/*
 * Api
 */
export interface ContactPersonsQueryResponse {
	responsibilities: Responsibility[]
	contactPersons: ContactPerson[]
	providers: Provider[]
	categories: Category[]
	categoryGroups: CategoryGroup[]
	nodes: Node[]
}

export interface ContactPersonsShowResponse {
	responsibilities: Responsibility[]
	contactPerson: ContactPerson
	categories: Category[]
	categoryGroups: CategoryGroup[]
	documents: Document[]
}

/*
 * Functions
 */
export const getContactPersonOrderValue = (contactPerson: AssembledContactPerson): number => {
	return Math.max(...contactPerson.responsibilities.map(getResponsibilityOrderValue))
}

export const getResponsibilityOrderValue = (responsibility: Responsibility & WithCategory): number => {
	if (!responsibility.category) return 1
	return responsibility.category.group.prominence + responsibility.category.prominence
}

export function contactPersonSort(contactPersons: AssembledContactPerson[]) {
	return sort(
		contactPersons,
		(cp: AssembledContactPerson) => {
			return getContactPersonOrderValue(cp).toString()
		},
		'desc',
	)
}

export function contactPersonAlphabeticalSort(contactPersons: Array<ContactPerson & { providers: Provider[] }>) {
	return sort(
		contactPersons,
		(contactPerson: ContactPerson) => {
			return contactPersonTitle(contactPerson, contactPerson.providers).toLowerCase()
		},
		'asc',
	)
}

export function contactPersonResponsibilitySort(responsibilities: Responsibility[]) {
	return sort(
		responsibilities,
		(r: Responsibility & WithCategory) => {
			return getResponsibilityOrderValue(r).toString()
		},
		'desc',
	)
}

export function convertContactPersonToVCF(contactPerson: ContactPerson): string {
	const vCard = new VCard()

	if (contactPerson.description) {
		vCard.set('note', html2text(contactPerson.description))
	}
	const address = contactPerson.company?.address
	if (address) {
		vCard.set(
			'adr',
			`;;${address.street} ${address.houseNumber};${address.city};;${address.zip};${ISO_3166_1[address.country]}`,
			{ type: 'work' },
		)
	}
	contactPerson.company?.phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
		vCard.add('tel', phoneNumber.number, {
			type: phoneNumber.type,
		})
	})
	if (contactPerson.company?.emailAddress) {
		vCard.add('email', contactPerson.company.emailAddress)
	}
	if (contactPerson.company?.companyName) {
		vCard.add('org', contactPerson.company.companyName)
	}
	if (contactPerson.company?.url) {
		vCard.add('url', contactPerson.company.url)
	}

	return vCard.toString('4.0')
}

export const isProviderActive = (provider: Provider) =>
	!!provider.contact.emailAddress &&
	(!!provider.contact.salutation ||
		!!provider.contact.firstName ||
		!!provider.contact.lastName ||
		!!provider.contact.companyName)

/**
 * Validators
 */
export const vvContactPerson = {
	firstName: '',
	lastName: '',
	salutation: '',
	description: '',
	phone: '',
	phoneMobile: '',
	phonePrivate: '',
	phoneWork: '',
	phoneEmergency: '',
	fax: '',
	emailAddress: 'email',
	company: '',
	url: 'url',
	phoneLandline: '',
	locale: '',
}

export function createContactPersonCsv(contactPersons: ContactPerson[], providers: Provider[]) {
	// headers as expected from MS Outlook
	const header = [
		'Vorname',
		'Nachname',
		'Anrede',
		'Notizen',
		'Straße geschäftlich',
		'Postleitzahl geschäftlich',
		'Ort Geschäftlich',
		'Haupttelefon',
		'Mobiltelefon',
		'Telefon (privat)',
		'Telefon geschäftlich',
		'Telefon Firma',
		'Fax geschäftlich',
		'E-Mail-Adresse',
		'Firma',
		'Webseite',
	]

	const rows = createContactPersonList(contactPersons, providers)

	// create csv
	return Papa.unparse(
		{
			// values
			fields: header,
			data: rows,
		},
		{
			// config
			quotes: true,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ';', // a semicolon so Excel opens it correctly with a double click
			newline: '\r\n',
			skipEmptyLines: true,
		},
	)
}

function createContactPersonList(contactPersons: ContactPerson[], providers: Provider[]) {
	// transforms an object to an array of values that match the above header
	const providerToArray = (provider: Provider) => {
		const contactPerson = contactPersons.find((cp) => cp.id === provider.contactPersonId)

		const allPhoneNumbers = [
			...provider.contact.phoneNumbers,
			...(contactPerson?.company?.phoneNumbers || []).filter(
				({ number }) =>
					!provider.contact.phoneNumbers.some((providerPhoneNumber) => providerPhoneNumber.number === number),
			),
		].map(({ number }) => number)

		//ensuring there is at most 6 phone numbers (since I guess the csv was expecting those columns)
		const phoneNumbers = Array.from({ length: 6 }, (_, index) => allPhoneNumbers[index] || null)
		return [
			provider.contact.firstName,
			provider.contact.lastName,
			provider.contact.salutation,
			html2text(contactPerson?.description || ''),
			...(contactPerson && contactPerson.company?.address
				? [
						`${contactPerson.company.address.street} ${contactPerson.company.address.houseNumber}`,
						contactPerson.company.address.zip,
						contactPerson.company.address.city,
				  ]
				: [null, null, null]),
			...phoneNumbers,
			provider.contact.emailAddress || contactPerson?.company?.emailAddress,
			contactPerson?.company?.companyName,
			contactPerson?.company?.url,
		]
	}

	// build rows
	return providers.map(providerToArray)
}

export function contactPersonTitle(contactPerson: ContactPerson, providers?: Provider[]): string {
	if (contactPerson.company?.companyName) return contactPerson.company.companyName
	if (contactPerson.company?.firstName || contactPerson.company?.lastName)
		return name(
			contactPerson.company.firstName,
			contactPerson.company.lastName,
			contactPerson.company.salutation,
			false,
		)
	if (providers?.length) {
		return providers[0].company ?? contactTitle(providers[0].contact)
	}
	return ''
}
