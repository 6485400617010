
	import Utils from '~/app/utils/globals/Utils'
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import groupBy from 'lodash/groupBy'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import {
		eventSearchKeys,
		eventSort,
		EventsQueryResponse,
		isFutureEvent,
		isPastEvent,
		isPresentEvent,
	} from '~/app/domain/content/Event'
	import DateRange from '~/components/domain/date/DateRange.vue'
	import AddressDetail from '~/components/domain/address/AddressDetail.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import EventCard from '~/components/domain/event/EventCard.vue'
	import ButtonToggle from '~/components/inputs/ButtonToggle.vue'
	import SearchSplash from '~/components/utils/SearchSplash.vue'

	@Component({
		components: {
			SearchSplash,
			ButtonToggle,
			EventCard,
			SearchLayout,
			AddressDetail,
			DateRange,
			CategoryContainer,
		},
	})
	export default class EventList extends Vue {
		@Model('change', { required: true }) response: EventsQueryResponse
		@Prop({ required: false }) baseUrl: string
		@Prop({ default: false }) backOffice: boolean
		@Prop({ type: Boolean, default: true }) helpBtn
		@Prop({ type: Boolean, default: false }) pinnedAddBtn: boolean
		@Prop({ type: Boolean, default: false }) disableNew
		@Prop({ type: Boolean, default: false }) loading

		Image = Image

		// TODO: this.processAdded does not exist
		created() {
			this.$nuxt.$on('eventUpdated', this.eventUpdated)
			this.$nuxt.$on('eventDeleted', this.eventDeleted)
			// @ts-ignore
			this.$nuxt.$on('eventAdded', this.processAdded)
		}

		beforeDestroy() {
			this.$nuxt.$off('eventUpdated')
			this.$nuxt.$off('eventDeleted')
			// @ts-ignore
			this.$nuxt.$on('eventAdded', this.processAdded)
		}

		eventUpdated(event) {
			const cIndex = this.response.events.findIndex((c) => c.id === event.id)
			if (cIndex >= 0) {
				this.response.events.splice(cIndex, 1, event)
			}
		}

		eventDeleted(event) {
			const cIndex = this.response.events.findIndex((c) => c.id === event.id)
			if (cIndex >= 0) {
				this.response.events.splice(cIndex, 1)
			}
		}

		eventAdded(event) {
			this.response.events.push(event)
		}

		mounted() {
			if (this.$route.query.status) {
				this.filters.status = this.statusItems.findIndex((i) => {
					return i.value === this.$route.query.status
				})
			}
		}

		/**
		 * Additional filters
		 */
		filters: any = {
			status: 0,
		}

		get statusItems() {
			return [
				{
					id: 'upcoming-btn',
					text: this.$t('domain.event.filter.upcoming'),
					value: 'upcoming',
				},
				{
					id: 'past-btn',
					text: this.$t('domain.event.filter.past'),
					value: 'past',
				},
			]
		}

		/**
		 * Search & filtering logic
		 */
		searchString = ''
		monthStrings = {}
		get filteredEvents() {
			if (!this.response) return
			let filtered = this.response.events
			if (this.searchString) {
				filtered = search(filtered, this.searchString, {
					fuzzy: true,
					sort: true,
					keys: eventSearchKeys,
				})
			}

			const futureEvents = this.statusItems[this.filters.status].value === 'upcoming'
			filtered = eventSort(
				filtered.filter((e) => {
					return futureEvents ? isFutureEvent(e) || isPresentEvent(e) : isPastEvent(e)
				}),
				!futureEvents,
			)
			if (!futureEvents) {
				filtered = filtered.reverse()
			}
			return groupBy(
				filtered.map((e) => {
					// console.log(Utils.dateFormat(e.fromDate, 'month'))
					const isoDate = this.$dateFns.parseISO(e.fromDate)
					const dateNum = this.$dateFns.format(isoDate, 'yyyy-MM')
					this.monthStrings[dateNum] = Utils.dateFormat(e.fromDate, 'month')
					return {
						...e,
						month: dateNum,
					}
				}),
				(e) => e.month,
			)
		}

		get listeners() {
			return {
				click: this.$listeners?.click,
				new: this.$listeners?.new,
			}
		}

		showEvent(event) {
			this.$emit('click', event)
		}
	}
