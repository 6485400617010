// extract the subdomain
import { Tenant } from '~/app/domain/identifyAndAccess/Tenant'
export interface SubdomainIndex {
	[key: string]: Subdomain
}
export interface Subdomain {
	accessAreas: AccessAreas
	tenant: Tenant
}
export interface AccessAreas {
	backOffice: boolean
	frontOffice: boolean
}

/**
 * Find out the redirect path from the user domains
 * @param domains
 */
export function getRedirect(subdomain, tenants: string, redirect?: string): string {
	// get subdomain
	const allTenants = Object.keys(tenants)
	if (redirect) return redirect
	if (!subdomain) {
		return '/app/account/user'
	}
	if (!tenants[subdomain]) return `/app/${subdomain}/account/no_access`
	const domain = tenants[subdomain] || tenants[allTenants[0]]
	const realm = domain.tenant.tenantType === 'provider' ? 'provider' : 'property'

	// get the access
	const cAccess = {
		realm,
		area: domain.accessAreas.frontOffice ? 'fo' : 'bo',
	}

	/**
	 * Redirect login, @see {@link https://gitlab.com/etg24/app/app/-/blob/develop/pages/account/Authentication.md}
	 */
	const allowedAccess = `/app/${domain.tenant.subdomain}/${cAccess.realm}/${cAccess.area}`
	if (redirect && redirect.startsWith(allowedAccess)) {
		return redirect
	} else {
		return allowedAccess
	}
}

export function getSubdomainInfo(subdomain, text = true) {
	if (!subdomain || !subdomain.tenant) return
	// get the subdomain url
	const type = subdomain.tenant.tenantType.startsWith('property') ? 'property' : 'provider'
	const access = subdomain.accessAreas.frontOffice ? 'fo' : 'bo'

	// redirects
	const redirects = {
		to: null,
		href: null,
	}
	redirects.to = `/app/${subdomain.tenant.subdomain}/${type}/${access}`

	// populate the list
	return {
		...redirects,
		name: subdomain.tenant.title || subdomain.tenant.companyName,
		accessText: text
			? this.$t(`domain.subdomain.${subdomain.accessAreas.frontOffice ? 'access.fo' : 'access.bo'}`)
			: undefined,
		subdomain: subdomain.tenant.subdomain,
	}
}
