import { Context } from '@nuxt/types'
import { setupWorker } from 'msw/browser'
import { RequestHandler } from 'msw'
import { url } from './axios'

function loadHandlers(apiDomain: string, mockedModules: string[]) {
	//@ts-expect-error webpack require
	const context = require.context('../modules', true, /core\/mock\.ts$/)
	const handlers: RequestHandler[] = []

	context
		.keys()
		.filter((key) => mockedModules.some((mockedModule) => key.includes(mockedModule)))
		.forEach((key) => {
			const module = context(key)
			if (module.handlerFactory) {
				handlers.push(...module.handlerFactory(url(apiDomain)))
			}
			if (module.handlers) {
				handlers.push(...module.handlers)
			}
		})

	return handlers
}

export default async (context: Context) => {
	try {
		const mockedModules = context.$config?.mockedModules
		if (!mockedModules) {
			return
		}

		const mockedModulesArray = mockedModules.split(',')

		if (!mockedModules.length) {
			return
		}

		const apiDomain = context.store.getters['api/override'] || context.$config.apiDomain

		const handlers = loadHandlers(apiDomain, mockedModulesArray)
		console.log({ handlers })

		if (!handlers.length) {
			return
		}
		const worker = setupWorker(...handlers)

		await worker.start()
	} catch (e) {
		console.log(e)
	}
}
