import { NotificationConfig } from '@shared/types/notification'
import { ScopeType } from '@shared/types/note'
import { goToContactByUser } from '@shared/types/contact'

// default config that most notifications should have
const defaultConfig: Partial<NotificationConfig> = {
	dense: () => false,
	alert: () => null,
	markReadOnSelect: () => false,
	title: (notification, i18n) => {
		// if an i18n key is given, use it
		if (i18n.te(`domain.generalNotifications.type.${notification.type}.title`)) {
			return i18n.t(`domain.generalNotifications.type.${notification.type}.title`, notification.data)
		}
		// if a title is given in data, use it
		if (notification.data.title) {
			return notification.data.title
		}
		return undefined
	},
	subtitle: (notification, i18n) => {
		// if an i18n key is given, use it
		if (i18n.te(`domain.generalNotifications.type.${notification.type}.text`)) {
			return i18n.t(`domain.generalNotifications.type.${notification.type}.text`, notification.data)
		}
		// if a text is given in data, use it
		if (notification.data.text) {
			return notification.data.text
		}
		return undefined
	},
	icon: () => 'fal fa-circle',
}

const notificationConfigurations: {
	[key: string]: Partial<NotificationConfig>
} = {
	/**
	 * General notification expected to have
	 * - data.i18n as object with key and values if translated, otherwise data.html
	 * - data.target as object with url or route and optional newTab
	 * - data.color
	 */
	'system.alert': {
		alert: (notification, i18n) => ({
			html: notification.data.i18n
				? i18n.t(notification.data.i18n.key, notification.data.i18n.values)
				: notification.data.html,
			color: notification.isRead ? 'primary' : notification.data.color ?? 'purple',
		}),
		title: () => null,
		subtitle: () => null,
		select: async (notification, instance) => {
			// handle absolute urls
			if (notification.data.target.url) {
				// open in new tab if wished
				window.open(notification.data.target.url, notification.data.target.newTab ? '_blank' : '_self')
			}

			// handle vue router navigation
			if (notification.data.target.route) {
				// open in new tab if wished
				notification.data.target.newTab
					? window.open(instance.$router.resolve(notification.data.target.route).href, '_blank')
					: instance.$router.push(notification.data.target.route)
			}
			// not a known strategy, not doing anything
		},
		markReadOnSelect: () => true, // always mark read
		icon: () => null,
	},
	'conversation.added': {
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'conversation',
					contentId: notification.reference,
				},
			})
		},
		icon: (_, instance) => instance.$etg.image.getIconForDomain('conversation'),
	},
	'conversation.waiting_ended': {
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'conversation',
					contentId: notification.reference,
				},
			})
		},
		icon: (_, instance) => instance.$etg.image.getIconForDomain('conversation'),
	},
	'content.document.published': {
		dense: (_) => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('document'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					document: notification.reference,
				},
			})
		},
	},
	'content.document.replaced': {
		dense: (_) => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('document'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					document: notification.reference,
				},
			})
		},
	},
	'content.event.canceled': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('event'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'event',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.event.published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('event'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'event',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.event.updated': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('event'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'event',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.faq.published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('faq'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'faq',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.faq.updated': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('faq'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'faq',
					contentId: notification.reference,
				},
			})
		},
	},
	'note.assigned': {
		title: (notification) => notification.data.note,
		icon: (notification, instance) => {
			const scopeTypeToIcon = {
				[ScopeType['communication.conversation']]: instance.$etg.image.getIconForDomain('conversation'),
				[ScopeType['communication.delivery']]: instance.$etg.image.getIconForDomain('delivery'),
				[ScopeType['communication.voting']]: instance.$etg.image.getIconForDomain('voting'),
				[ScopeType['communication.votingItem']]: instance.$etg.image.getIconForDomain('voting'),
				[ScopeType['content.event']]: instance.$etg.image.getIconForDomain('event'),
				[ScopeType['content.post']]: instance.$etg.image.getIconForDomain('post'),
				[ScopeType['content.process']]: instance.$etg.image.getIconForDomain('process'),
				[ScopeType['contact']]: instance.$etg.image.getIconForDomain('contact'),
			} as const
			return scopeTypeToIcon[notification.data.scopeType as keyof typeof scopeTypeToIcon]
		},
		select: async (notification, instance) => {
			switch (notification.data.scopeType) {
				case ScopeType.contact:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'contact',
							contentId: notification.data.scope,
						},
					})
					break
				case ScopeType['communication.conversation']:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'conversation',
							contentId: notification.data.scope,
						},
					})
					break
				case ScopeType['communication.delivery']:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'delivery',
							contentId: notification.data.scope,
						},
					})
					break
				case ScopeType['communication.voting']:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'voting',
							contentId: notification.data.scope,
						},
					})
					break
				case ScopeType['communication.votingItem']:
					const result = await instance.$axios.get('etg24:internal:query:communication.voting.item', {
						params: {
							item: notification.data.scope,
						},
					})
					if (!result || !result.data) {
						return
					}
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'voting',
							contentId: result.data.item.votingId,
							contentPage: `itemShow_${notification.data.scope}`,
						},
					})
					break
				case ScopeType['content.event']:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'event',
							contentId: notification.data.scope,
						},
					})
					break
				case ScopeType['content.post']:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'post',
							contentId: notification.data.scope,
						},
					})
					break
				case ScopeType['content.process']:
					await instance.$router.push({
						query: {
							...instance.$route.query,
							contentType: 'process',
							contentId: notification.data.scope,
						},
					})
					break
			}
		},
	},
	'propertyManagement.contract.activated': {
		subtitle: (notification, i18n) =>
			`${i18n.tc('domain.unit.base', 1)} ${notification.data.unit.number}, ${i18n.t(
				'domain.user.role.' + notification.data.contract.contractType,
			)}`,
		icon: () => 'fal fa-calendar-check',
		select: async (notification, instance) => {
			await instance.$router.push(
				`/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
			)
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.contract.postalDisabledByCustomer': {
		title: (notification, i18n) => i18n.t(`domain.generalNotifications.type.${notification.type}.title`),
		subtitle: (notification, i18n) =>
			i18n.t(`domain.generalNotifications.type.${notification.type}.text`, {
				name: notification.data.user.name,
				unitNumber: notification.data.unit.number,
			}),
		icon: () => 'fal fa-mail-bulk',
		select: async (notification, instance) => {
			await instance.$router.push(
				`/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
			)
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.user.registered': {
		icon: () => 'fal fa-user-plus',
		select: async (notification, instance) => {
			await goToContactByUser.bind(instance)({
				id: notification.reference,
			})
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.user.dataUpdated': {
		icon: () => 'fal fa-id-card',
		select: async (notification, instance) => {
			await goToContactByUser.bind(instance)({
				id: notification.reference,
			})
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.contract.createdByCustomer': {
		title: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.title`,
			),
		subtitle: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.text`,
				{
					name: notification.data.user.name,
					unitNumber: notification.data.unit.number,
					validFrom: notification.data.contract.validFrom,
				},
			),
		icon: () => 'fal fa-user-plus',
		select: async (notification, instance) => {
			await instance.$router.push(
				`/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
			)
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.contract.updatedByCustomer': {
		title: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.title`,
			),
		subtitle: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.text`,
				{
					name: notification.data.user.name,
					unitNumber: notification.data.unit.number,
				},
			),
		icon: () => 'fal fa-user-edit',
		select: async (notification, instance) => {
			await instance.$router.push(
				`/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
			)
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.signatory.createdByCustomer': {
		title: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.title`,
			),
		subtitle: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}${notification.data.signatory.isAgent ? 'Agent' : ''}.text`,
				{
					name: notification.data.user.name,
					unitNumber: notification.data.unit.number,
					newUser:
						`${notification.data.signatory.firstName} ${notification.data.signatory.lastName}`.trim() ||
						notification.data.signatory.emailAddress,
				},
			),
		icon: () => 'fal fa-user-plus',
		select: async (notification, instance) => {
			await instance.$router.push({
				path: `/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
				query: {
					...instance.$route.query,
				},
			})
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.signatory.updatedByCustomer': {
		title: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.title`,
			),
		subtitle: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}${notification.data.signatory.isAgent ? 'Agent' : ''}.text`,
				{
					name: notification.data.user.name,
					unitNumber: notification.data.unit.number,
					newUser:
						`${notification.data.signatory.firstName} ${notification.data.signatory.lastName}`.trim() ||
						notification.data.signatory.emailAddress,
				},
			),
		icon: () => 'fal fa-user-edit',
		select: async (notification, instance) => {
			await instance.$router.push({
				path: `/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
				query: {
					...instance.$route.query,
				},
			})
		},
		markReadOnSelect: () => true,
	},
	'propertyManagement.signatory.deletedByCustomer': {
		title: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}.title`,
			),
		subtitle: (notification, i18n) =>
			i18n.t(
				`domain.generalNotifications.type.${notification.type}.${notification.data.contract.contractType}${notification.data.signatory.isAgent ? 'Agent' : ''}.text`,
				{
					name: notification.data.user.name,
					unitNumber: notification.data.unit.number,
					newUser:
						`${notification.data.signatory.firstName} ${notification.data.signatory.lastName}`.trim() ||
						notification.data.signatory.emailAddress,
				},
			),
		icon: () => 'fal fa-user-minus',
		select: async (notification, instance) => {
			await instance.$router.push({
				path: `/app/${instance.$route.params.subdomain}/property/bo/objects/${notification.data.object.id}/units/${notification.data.unit.id}/contracts/${notification.data.contract.id}`,
			})
		},
		markReadOnSelect: () => true,
	},
	'note.deadline_today': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('note'),
		select: async (_, instance) => {
			await instance.$router.push(`/app/${instance.$route.params.subdomain}/property/bo/todos`)
		},
		markReadOnSelect: () => true,
	},
	'process.offerRequest.resubmission': {
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.reference,
					contentPage: `offerShow_${notification.data.offerRequestId}`,
				},
			})
		},
		icon: () => 'fal fa-alarm-exclamation',
	},
	'process.offer.made': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('offer-request'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.offer.rejected': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('offer-request'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.offer.withdrawn': {
		markReadOnSelect: (notification) => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('offer-request'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.order.resubmission': {
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.reference,
					contentPage: `orderShow_${notification.data.orderId}`,
				},
			})
		},
		icon: () => 'fal fa-alarm-exclamation',
	},
	'process.order.confirmed': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('order'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.order.expired': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('order'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.order.markedActive': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('order'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.order.markedDone': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('order'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.order.markedWaiting': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('order'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'process.order.rejected': {
		markReadOnSelect: () => true,
		icon: (_, instance) => instance.$etg.image.getIconForDomain('order'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'content.post.published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('post'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'post',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.post.updated': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('post'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'post',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.process.published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('process'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.process.updated': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('process'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.process.update_published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('process-update'),
		select: async (notification, instance) => {
			await instance.$router.push({
				query: {
					...instance.$route.query,
					contentType: 'process',
					contentId: notification.data.processId,
				},
			})
		},
	},
	'content.voting.canceled': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.voting.meeting.started': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.voting.period.ended': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.voting.published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.voting.results_unpublished': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.voting.results_published': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.reference,
				},
			})
		},
	},
	'content.voting.item.addendum_created': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		title: (notification, i18n) =>
			i18n.t(`domain.voting.item.addendum.type.values.${notification.data.addendumType}.label`),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.data.votingId,
					contentPage: `itemShowFo_${notification.data.itemId}`,
				},
			})
		},
	},
	'content.voting.signatureRequested': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.data.votingId,
					contentPage: `signatoryRequest_${notification.reference}`,
				},
			})
		},
	},
	'content.voting.signatureProvided': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'voting',
					contentId: notification.data.votingId,
					contentPage: undefined,
				},
			})
		},
	},
	'inboundEmail.unprocessed': {
		icon: (_, instance) => instance.$etg.image.getIconForDomain('voting'),
		title: (notification, i18n) => {
			return i18n.t(`domain.generalNotifications.type.${notification.type}.${notification.data.reason}.text`, {
				...notification.data,
				from: notification.data.fromName ?? notification.data.fromEmailAddress,
			})
		},
		select: async (notification, instance) => {
			instance.$router.replace({
				query: {
					...instance.$route.query,
					contentType: 'inboundEmail',
					contentId: notification.reference,
					contentPage: undefined,
				},
			})
		},
	},
} as const

// final config for each notification, merged with the defaults
export const NotificationConfigurations: {
	[key: string]: NotificationConfig
} = Object.keys(notificationConfigurations).reduce(
	(acc, type) => ({
		...acc,
		[type]: {
			...defaultConfig,
			...notificationConfigurations[type],
		},
	}),
	{},
)
