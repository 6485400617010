import { findClosestAncestorOfType, getPathFromRootNode, Node, NodeId, NodeType } from '~/app/domain/content/Common'

export const getters = {
	rootPath: (state, getters, rootState, rootGetters) => `/app/${rootGetters['tenant/tenant'].subdomain}/provider/fo`,

	pathToLocationNode: (state, getters, rootState, rootGetters) => (locationNodeId: NodeId, nodes: Node[]) => {
		let path = getters.rootPath

		getPathFromRootNode(locationNodeId, nodes).forEach((node) => {
			switch (node.type) {
				case NodeType['provider']:
					break

				case NodeType['provider.company']:
					path += `/${node.id}`
					break
			}
		})

		return path
	},

	pathToCompany: (state, getters, rootState, rootGetters) => (locationNodeId: NodeId, nodes: Node[]) => {
		return '/' + findClosestAncestorOfType(locationNodeId, nodes, NodeType['provider.company'])?.id
	},

	pathToFaqs: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/faqs`,

	pathToProcesses: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/processes`,
}

export const mutations = {}

export const actions = {}
