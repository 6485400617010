
	import { Component, Vue, Model, Watch, Prop } from 'nuxt-property-decorator'
	import { Address, vvAddress, vvAddressOptional } from '~/app/domain/common/Address'
	import { ISO_3166_1 } from '~/app/common/static/ISO_3166_1'

	@Component({})
	export default class AddressForm extends Vue {
		@Model('changed', { default: () => {} }) address: Address
		@Prop({ type: Boolean, default: false }) multiHouseNumber: boolean
		@Prop({ type: Boolean, default: false }) canHide: boolean
		@Prop({ type: Boolean, default: false }) required: boolean

		get validator() {
			return this.required || !this.isEmpty ? vvAddress : vvAddressOptional
		}

		localAddress = null
		@Watch('address', { immediate: true })
		updateAddress() {
			this.localAddress = this.address || {}
			if (this.canHide && !this.isEmpty) this.hidden = false
			else this.hidden = true
		}

		@Watch('localAddress', { deep: true })
		updateLocalAddress() {
			this.$emit(
				'changed',
				Object.values(this.localAddress || {}).filter((v) => !!v).length === 0 ? null : this.localAddress,
			)
		}

		get isEmpty() {
			for (const f of ['street', 'houseNumber', 'zip', 'country', 'city']) {
				if ((this.localAddress || {})[f]) return false
			}
			return true
		}

		get countries() {
			const iso = ISO_3166_1
			return Object.keys(iso).map((k) => ({
				text: iso[k],
				value: k,
			}))
		}

		focusInput(inputName) {
			let input = this.$refs[inputName]
			if (input) {
				if (Array.isArray(input)) input = input[0]
				if ((input as any).focus)
					setTimeout(() => {
						;(input as any).focus()
					})
			}
		}

		/**
		 * Hidden status
		 */
		hidden: boolean = true
		savedAddress: Address = null
		addAddress() {
			this.localAddress = this.savedAddress || {
				country: this.$config.defaultLocale,
			}
			this.hidden = false
		}

		removeAddress() {
			this.savedAddress = this.localAddress
			this.localAddress = null
			this.hidden = true
		}

		req(t) {
			if (this.required || !this.isEmpty) return `${t} *`
			else return t
		}
	}
