/* eslint-disable import/no-duplicates */
import { format, formatDistanceToNow } from 'date-fns'
import { enUS, de } from 'date-fns/locale'
import { DayString } from '~/app/domain/Types'

export function getCurrentDayString(): DayString {
	return format(new Date(), 'yyyy-MM-dd')
}

const localeToDateFnsLocaleMap = {
	en: enUS,
	de,
}
export function getDateDifferenceString(date: string | Date, locale = 'de') {
	const a = formatDistanceToNow(new Date(date), {
		addSuffix: true,
		locale: localeToDateFnsLocaleMap[locale] || localeToDateFnsLocaleMap.de,
	})
	return a
}
