
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model } from 'nuxt-property-decorator'
	import { Contract, isPastContract } from '~/app/domain/property/Contract'
	import ContractIcons from '~/components/domain/contract/ContractIcons.vue'

	@Component({
		components: { ContractIcons },
	})
	export default class ContractDate extends Vue {
		Utils = Utils
		@Model('change', { required: true }) contract: Contract

		get isPast() {
			return isPastContract(this.contract)
		}
	}
