
	import Messages from '~/app/utils/globals/Messages'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import QrCodeDisplay from '~/components/utils/QrCodeDisplay.vue'
	import { downloadStringAsFile } from '~/app/utils/libs/FileUtil'
	import { Address } from '~/app/domain/common/Address'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import ContentLocationAction from '~/components/domain/location/ContentLocationAction.vue'
	import {
		Contact,
		ContactObjectData,
		ContactWithObjectsData,
		convertContactToVCF,
	} from '~/app/domain/contact/Contact'
	import { username } from '~/app/common/filters/domain/User'

	@Component({
		components: {
			ContentLocationAction,
			BasicDialog,
			QrCodeDisplay,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
		},
	})
	export default class ContactActions extends Vue {
		@Model('change', { required: true }) contact: Contact
		@Prop({ default: false }) dark: boolean
		@Prop({ type: Boolean, default: false }) atNode: boolean
		@Prop({ default: null }) info: { address: Address }
		@Prop({ default: false }) nodeId: string
		@Prop({ type: Array, default: () => [] }) nodes: Node[]
		@Prop({ type: Object, required: true }) objects: Record<string, ContactObjectData>

		contacts: ContactWithObjectsData[] = []

		async fetchDuplicates() {
			try {
				const duplicates = await this.$axios.get<{
					contacts: ContactWithObjectsData[]
				}>('etg24:internal:query:propertymanagement.contact.duplicates', {
					params: {
						contact: this.contact.id,
					},
				})

				return duplicates.data.contacts
			} catch (e) {
				// @ts-expect-error unknown is not assignable to AxiosError
				Utils.handleError(e)
				return []
			}
		}

		async mergeDuplicates(duplicates: Contact['id'][]) {
			try {
				const response = await this.$axios.post<{
					contact: Contact
				}>('etg24:internal:command:propertymanagement.contact.merge', {
					contactToKeep: this.contact.id,
					contactsToMerge: duplicates,
				})
				this.$nuxt.$emit('updateContact', response.data.contact)
			} catch (e) {
				// @ts-expect-error unknown is not assignable to AxiosError
				Utils.handleError(e)
			}
		}

		get editActions() {
			let actions: any = []
			/**
			 * Edit and delete
			 */
			if (!this.atNode) {
				actions = [
					...actions,
					{
						icon: 'fal fa-pencil',
						size: 6,
						callback: () => {
							this.$emit('changePage', 'editContact')
						},
						attributes: {
							'data-qa': 'contact-actions:edit',
						},
					},
					{
						callback: () => {
							this.deleteDialog = true
						},
						icon: 'fal fa-trash',
						color: 'error',
						size: 6,
						hidden: this.hasObjects,
						attributes: {
							'data-qa': 'contact-actions:delete',
						},
					},
				]
			}
			return actions
		}

		get hasObjects() {
			return Object.keys(this.objects).length > 0
		}

		get actions() {
			let actions: any = []

			/**
			 * Download
			 */
			actions = [
				...actions,
				{
					hidden: !this.contact.user,
					text: this.$t('domain.user.userSearch.sendLoginHelp'),
					callback: async () => {
						await this.sendLoginHelp()
					},
					icon: 'fal fa-paper-plane',
					size: 12,
					attributes: {
						'data-qa': 'user-actions:send-login-help',
					},
				},
				{
					text: this.$t('domain.contact.actions.merge.title'),
					callback: async () => {
						const pickResult = await this.$pickContacts({
							multiple: true,
							fetchFn: this.fetchDuplicates,
							explanation: {
								text: String(this.$t('domain.contact.actions.merge.description')),
								color: 'blue',
							},
							submitButton: {
								text: String(this.$t('layout.merge')),
							},
						})

						if (!pickResult) return

						await this.mergeDuplicates(pickResult)
					},
					icon: 'fal fa-users-crown',
					size: 12,
					attributes: {
						'data-qa': 'contact-actions:merge-duplicates',
					},
				},
			]

			return actions.filter((a) => !a.hidden)
		}

		/**
		 * Delete logic
		 */
		deleteDialog = false
		async deleteContact() {
			try {
				await this.$axios.$post('etg24:internal:command:propertymanagement.contact.delete', {
					contact: this.contact.id,
				})
				this.$emit('deleted')
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		/**
		 * QR code
		 */
		get fullName() {
			return username(this.contact as any, false)
		}

		qrDialog = false
		get qrCode() {
			return convertContactToVCF(this.contact)
		}

		downloadQr() {
			downloadStringAsFile(convertContactToVCF(this.contact), `${this.fullName || 'no_name'}.vcf`)
		}

		/**
		 * Login help
		 */
		async sendLoginHelp() {
			try {
				await this.$axios.$post('etg24:internal:command:identityandaccess.sendloginhelp', {
					user: this.contact.user.id,
				})
				await this.$store.dispatch('snackbar/success', this.$t('domain.user.userSearch.sendLoginHelpSuccess'))
			} catch (e) {
				Utils.handleError(e)
			}
		}
	}
