/**
 * Reverse a string
 * @param str The string to reverse
 * @returns The reversed string
 * @complexity O(n) where n is the length of the string
 *
 * @example
 * ```ts
 * reverseString('hello') // returns 'olleh'
 * ```
 *
 * @example
 * ```ts
 * reverseString('world') // returns 'dlrow'
 * ```
 */
export default function reverseString(str: string) {
	let reversed = ''
	//for loop is used in favour of performance
	for (let i = str.length - 1; i >= 0; i--) {
		reversed += str[i]
	}
	return reversed
}
