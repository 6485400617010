import { EmailAddressString, PhoneNumberString, ShortString, Uuid } from '../Types'
import { Entity } from '../common/Interfaces'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { Address, addressSearchKeys, vvAddress } from '../common/Address'
import { ImageAsset } from '../assets/Image'
import { SubdomainIndex } from '~/app/utils/libs/Subdomain'
import VCard from 'vcf'
import { ISO_3166_1 } from '~/app/common/static/ISO_3166_1'
import sort from '~/app/common/filters/iterator/Sort'
import { PhoneNumber } from '~/app/domain/common/PhoneNumber'

/*
 * Model
 */

export type UserId = Uuid
export interface MicroUser extends Entity<UserId> {
	firstName: ShortString
	lastName: ShortString
	emailAddress: EmailAddressString
	image: ImageAsset
	isRegistered: boolean
	domains?: SubdomainIndex // todo: remove as it's not part of the spec
}

export type UserLocale = 'de' | 'en'

export interface User extends MicroUser {
	address: Address
	locale: UserLocale
	emailDelivery: {
		isActive: boolean | null
		hasIssue: boolean | null
	}
	phoneNumbers: PhoneNumber[]

	// deprecated properties
	phoneLandline: PhoneNumberString
	phoneMobile: PhoneNumberString
	phoneWork: PhoneNumberString
}

/*
 * Validation
 */

export const vvMicroUser = {
	firstName: 'required|min:2',
	lastName: 'required|min:2',
	emailAddress: 'required|email',
	image: '',
}

export const vvUser = Object.assign(
	{
		address: vvAddress,
		phoneLandline: '',
		phoneMobile: '',
		phoneWork: '',
		locale: '',
	},
	vvMicroUser,
)

export const vvPassword = 'required|min:8'

/*
 * Search
 */

export const microUserSearchKeys: (keyof MicroUser)[] = ['firstName', 'lastName', 'emailAddress']

export function sortUsers(users: User[] | any[], prefix = '') {
	let userSortIteratee = ['lastName', 'firstName', 'emailAddress']
	if (prefix) {
		userSortIteratee = prefixSearchKeys(prefix, userSortIteratee)
	}
	const groups = {
		withName: [],
		withoutName: [],
	}
	for (const user of users) {
		const hasName = prefix ? user[prefix].firstName || user[prefix].lastName : user.firstName || user.lastName
		groups[hasName ? 'withName' : 'withoutName'].push(user)
	}
	for (const group of Object.keys(groups)) {
		groups[group] = sort(groups[group], userSortIteratee)
	}
	return [...groups.withName, ...groups.withoutName]
}

export const userSearchKeys: (keyof User)[] = [
	'firstName',
	'lastName',
	'emailAddress',
	'phoneNumbers.number',
	...(prefixSearchKeys('address', addressSearchKeys) as (keyof User)[]),
]

/*
 * Api
 */

/*
 * Functions
 */

export function convertUserToVCF(user: User): string {
	const vCard = new VCard()

	// email address
	vCard.add('email', user.emailAddress)

	// nothing more to add if the user is not registered
	if (!user.isRegistered) {
		return vCard.toString('4.0')
	}

	// full name
	vCard.set('fn', [user.firstName, user.lastName].filter((x) => x).join(' '))
	// standard name
	vCard.set('n', `${user.lastName};${user.firstName};;`)

	// address
	if (user.address)
		vCard.set(
			'adr',
			`;;${user.address.street} ${user.address.houseNumber};${user.address.city};;${user.address.zip};${
				ISO_3166_1[user.address.country]
			}`,
			{ type: 'home' },
		)

	// phone numbers
	if (user.phoneMobile) {
		vCard.add('tel', user.phoneMobile, { type: 'CELL' })
	}
	if (user.phoneLandline) {
		vCard.add('tel', user.phoneLandline, { type: 'HOME' })
	}
	if (user.phoneWork) {
		vCard.add('tel', user.phoneWork, { type: 'WORK' })
	}

	return vCard.toString('4.0')
}
