
	import { Component, Vue, Prop } from 'nuxt-property-decorator'

	@Component({})
	export default class CardLargeContainer extends Vue {
		@Prop({ default: null }) icon: string
		@Prop({ default: false }) smallIcon: boolean
		@Prop({ default: null }) tooltip: string

		get iconSlot() {
			return this.$slots['icon']
		}

		get tooltipEnabled() {
			return this.tooltip != null
		}
	}
