// CompanyGroup
import { Uuid } from '../Types'
import { CompanyId } from './Provider'
import { Entity } from '../common/Interfaces'

/*
 * Model
 */

export type CompanyGroupId = Uuid
export interface CompanyGroup extends Entity<CompanyId> {
	title: string
	description: string
	companies: CompanyId[]
}

/*
 * Validation
 */

/*
 * Search
 */

export const companyGroupSearchKeys: (keyof CompanyGroup)[] = ['title', 'description']

/*
 * Api
 */

/*
 * Functions
 */
