import { name } from '../Name'
import { MicroUser } from '~/app/domain/identifyAndAccess/User'

/**
 * renders the provided user as string
 *
 * @param user the user to render
 * @param formal true = "Doe, John", false = "John Doe"
 */
export function username(user: MicroUser, formal: boolean = true): string {
	if (!user) return ''
	if (!user.firstName) return user.lastName || user.emailAddress

	return name(user.firstName, user.lastName, null, formal)
}
