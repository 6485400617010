
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { DateString } from '~/app/domain/Types'
	import { format as dateFnsFormat } from 'date-fns'
	import { getDateFnsLocaleFromI18n } from '~/app/common/filters/Date'

	@Component({})
	export default class Time extends Vue {
		@Prop({ default: null }) date: Date | DateString

		get dateString(): DateString {
			switch (typeof this.date) {
				case 'object':
					return this.date.toISOString()
			}
			return this.date
		}

		get dateObject(): Date {
			switch (typeof this.date) {
				case 'string':
					return new Date(this.date)
			}
			return this.date
		}

		get value() {
			return dateFnsFormat(this.dateObject, this.$t('dates.time') as string, {
				locale: getDateFnsLocaleFromI18n(this.$i18n),
			})
		}
	}
