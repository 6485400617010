
	import Image from '~/app/utils/globals/Image'
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { MicroUser } from '~/app/domain/identifyAndAccess/User'

	@Component({})
	export default class ContextEmployee extends Vue {
		@Prop({ default: false, type: Object }) employee: MicroUser
		Image = Image

		get name() {
			if (!this.employee) {
				return null
			}
			if (this.employee.isRegistered) {
				return `${this.employee.firstName} ${this.employee.lastName}`
			}
			return this.employee.emailAddress
		}
	}
