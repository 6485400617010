import { itemStoreActions, itemStoreGetters, itemStoreMutations } from '~/app/store/ItemStore'
import { pick } from 'lodash'
import { ObjectManagementType, PropertyObjectId, PropertyObject } from '~/app/domain/property/Object'

export const state = () => ({
	initialized: false,
	processing: false,
	items: [],
	context: null,
})

export const getters = {
	...itemStoreGetters({
		sortIteratee: 'title',
		lifetime: 30e3,
	}),
}

export const mutations = {
	...itemStoreMutations({ sortIteratee: 'title' }),

	resetForContext(state, context: string): void {
		state.initialized = false
		state.processing = false
		state.items = []
		state.context = context
	},
}

export const actions = {
	...itemStoreActions({}),

	async fetchAll(context) {
		// check if we need to reset because we're in a new context
		const currentContext = `${context.rootGetters['tenant/subdomain']}-${
			context.rootGetters['routing/isBackoffice'] ? 'bo' : 'fo'
		}`
		if (!context.state.context || context.state.context !== currentContext) {
			context.commit('resetForContext', currentContext)
		}

		// don't reload if we're already reloading
		if (context.getters.isProcessing) {
			return
		}
		// only reload if we're not initialized or if reload is forced
		if (context.getters.isInitialized) {
			return
		}
		const objects = (await this.$axios.get('etg24:internal:query:propertymanagement.objects')).data
		context.commit('setItems', objects)
		context.commit('setInitialized', true)
	},

	async fetch(context, objectId: PropertyObjectId) {
		const object = (
			await this.$axios.get('etg24:internal:query:propertymanagement.object', {
				params: { object: objectId },
			})
		).data
		context.commit('setItem', object)
	},

	async create(context, objects) {
		// reduce to allowed properties
		objects = objects.map((object) =>
			pick(object, ['title', 'managementType', 'description', 'numberOfUnits', 'extId', 'addresses']),
		)
		try {
			const response = await this.$axios.post('etg24:internal:command:propertymanagement.objects.create', {
				objects,
			})
			context.commit('addItems', response.data)
		} catch (e) {
			throw e
		}
	},

	async update(context, object) {
		// reduce to allowed properties
		object = pick(object, ['id', 'title', 'description', 'numberOfUnits', 'extId'])
		try {
			const response = (
				await this.$axios.post('etg24:internal:command:propertymanagement.objects.update', { object })
			).data
			context.commit('updateItem', response)
			return response
		} catch (e) {
			throw e
		}
	},

	async updateMultiple(context, objects: PropertyObject[]) {
		// reduce to allowed properties
		const transformedObjects = objects.map((object) =>
			pick(object, ['id', 'title', 'description', 'numberOfUnits', 'extId']),
		)
		const response = (
			await this.$axios.post('etg24:internal:command:propertymanagement.objects.updatemultiple', {
				objects: transformedObjects,
			})
		).data as PropertyObject[]
		context.commit('updateItems', response)
		return response
	},

	async updateImage(context, payload: { objectId: PropertyObjectId; image: File }) {
		const data = new FormData()
		data.append('object', payload.objectId)
		data.append('image', payload.image)
		const object = (
			await this.$axios.post('etg24:internal:command:propertymanagement.objects.uploadimage', data, {
				headers: { 'content-type': 'multipart/form-data' },
			})
		).data
		context.commit('updateItem', object)
		return object
	},

	async changeManagementType(
		context,
		payload: {
			objectId: PropertyObjectId
			managementType: ObjectManagementType
		},
	) {
		try {
			const response = await this.$axios.post(
				'etg24:internal:command:propertymanagement.objects.changemanagementtype',
				{
					object: payload.objectId,
					managementType: payload.managementType,
				},
			)
			context.commit('updateItem', response.data)
		} catch (e) {
			throw e
		}
	},

	async setUserAccess(
		context,
		payload: {
			objectId: PropertyObjectId
			userAccess: boolean
		},
	) {
		try {
			const response = await this.$axios.post('etg24:internal:command:propertymanagement.objects.setuseraccess', {
				object: payload.objectId,
				userAccess: payload.userAccess,
			})
			context.commit('updateItem', response.data)
		} catch (e) {
			throw e
		}
	},

	async delete(context, objectId) {
		try {
			await this.$axios.post('etg24:internal:command:propertymanagement.objects.delete', { object: objectId })
			context.commit('removeItem', objectId)
		} catch (e) {
			throw e
		}
	},
}
