
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import { contactPersonSearchKeys, Provider, Responsibility } from '~/app/domain/content/ContactPerson'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import ContactPersonResponsibilityCard from '~/components/domain/contact_person/ContactPersonResponsibilityCard.vue'
	import { Category, CategoryGroup } from '~/app/domain/content/Category'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import sort from '~/app/common/filters/iterator/Sort'
	import { search } from '~/app/common/filters/iterator/Search'
	import ObjectPicker from '~/components/domain/object/ObjectPicker.vue'
	import CompanyPicker from '~/components/domain/company/CompanyPicker.vue'
	import SfSelect from '~/components/domain/conversation/service_forms/blocks/SfSelect.vue'
	import CompanyGroupPicker from '~/components/domain/company_group/CompanyGroupPicker.vue'
	import ProviderPicker from '~/components/domain/provider/ProviderPicker.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'

	@Component({
		components: {
			UserNotifications,
			ProviderListItem,
			ProviderPicker,
			CompanyGroupPicker,
			SfSelect,
			CompanyPicker,
			ObjectPicker,
			BasicDialog,
			ContactPersonResponsibilityCard,
			CategoryContainer,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
		},
	})
	export default class ContactPersonResponsibilitiesDialog extends Vue {
		@Model('change', { required: true }) visible: boolean
		@Prop({ required: true }) responsibility: Responsibility | any
		@Prop({ default: false }) newResponsibility: boolean
		@Prop({ default: null }) nodeId: string
		@Prop({ required: true }) categories: Category[]
		@Prop({ required: true }) categoryGroups: CategoryGroup[]
		@Prop({ type: Boolean, default: false }) mustSelectProvider: boolean

		showProviderDialog = false
		selectedProvider: Provider = null

		localVisible: boolean = false
		@Watch('visible')
		updateVisible() {
			this.localVisible = this.visible
		}

		@Watch('localVisible')
		updateLocalVisible() {
			this.$emit('change', this.localVisible)
		}

		/**
		 * Assemble categories
		 */
		get categoryWithGroups() {
			const groups = []
			for (const category of this.categories || []) {
				let group = groups.find((cg) => {
					return cg.id === category.groupId
				})
				if (!group) {
					const oGroup = (this.categoryGroups || []).find((cg) => {
						return cg.id === category.groupId
					})
					group = {
						...oGroup,
						categories: [],
					}
					groups.push(group)
				}
				group.categories.push(category)
			}

			// flatten it
			const result = []
			for (const group of groups) {
				const title = group.title
				// if (group.roleTypes.length) {
				// 	title = `${title} | ${group.roleTypes
				// 		.map((t) => this.$tc(`domain.user.role.${t}`, 2))
				// 		.join(', ')}`
				// }
				result.push({
					header: title,
				})
				result.push({ divider: true })
				for (const cat of sort(group.categories, ['prominence', 'title'], ['desc', 'asc'])) result.push(cat)
			}
			return result
		}

		get canSubmit() {
			// required are job, provider and target(s)
			return !!(
				this.responsibility.job &&
				(this.responsibility.provider || this.selectedProvider) &&
				(!this.newResponsibility || this.targets.length || this.nodeId)
			)
		}

		submitting: boolean = false
		async submit() {
			this.submitting = true
			try {
				if (!this.newResponsibility) {
					// update responsibility
					const response = await this.$axios.$post(
						'etg24:internal:command:content.contactpersons.responsibilities.update',
						{
							responsibility: {
								id: this.responsibility.id,
								note: this.responsibility.note || '',
								isInternal: this.responsibility.isInternal || false,
							},
						},
					)
					this.$emit('updated', {
						responsibility: response,
					})
				} else {
					// create responsibilities
					const targets =
						this.targets.length > 0
							? this.targets
							: [
									{
										id: this.nodeId,
									},
							  ]
					const response = await this.$axios.$post(
						'etg24:internal:command:content.contactpersons.responsibilities.assignmultiple',
						{
							responsibilities: targets.map((target) => ({
								...this.responsibility,
								provider: this.responsibility.provider || this.selectedProvider.id,
								parentNode: target.id,
							})),
						},
					)
					this.$emit('added', response)
					this.targets = []
					this.selectedProvider = null
				}

				// return the info
				this.localVisible = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.submitting = false
		}

		revoking: boolean = false
		async revoke() {
			this.revoking = true
			try {
				await this.$axios.$post('etg24:internal:command:content.contactpersons.responsibilities.revoke', {
					responsibility: this.responsibility.id,
				})
				this.$emit('revoked', this.responsibility.id)
				this.localVisible = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.revoking = false
		}

		/**
		 * Targets
		 */
		targets: any[] = []
		objectPickerDialog: boolean = false
		companyPickerDialog: boolean = false
		companyGroupPickerDialog: boolean = false

		get companies() {
			if (!this.isProvider) return []
			return this.$store.getters['provider/companies/all']
		}

		get objects() {
			if (this.isProvider) return []
			return this.$store.getters['property/objects/all']
		}

		get companyGroups() {
			if (!this.isProvider) return []
			return this.$store.getters['provider/companyGroups/all']
		}

		get isProvider() {
			return this.$store.getters['routing/isProvider']
		}

		targetType: string = 'companies'
		get targetTypes() {
			return [
				{
					text: `${this.$t('Target')}: ${this.$t('Companies')}`,
					value: 'companies',
				},
				{
					text: `${this.$t('Target')}: ${this.$t('Company Groups')}`,
					value: 'companyGroups',
				},
			]
		}
	}
