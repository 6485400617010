
	import { Vue, Component } from 'nuxt-property-decorator'
	import FooterFocused from '~/components/navigation/footers/FooterFocused.vue'
	import LoadingScreen from '~/components/utils/LoadingScreen.vue'
	import { setSystemLocale } from '~/app/utils/libs/Locales'
	import LoginPageBackground from '~/components/domain/tenant/branding/LoginPageBackground.vue'
	import ConfigPanel from '~/components/utils/ConfigPanel.vue'
	import EtgSnackbar from '~/components/EtgSnackbar.vue'

	@Component({
		components: {
			ConfigPanel,
			LoginPageBackground,
			LoadingScreen,
			FooterFocused,
			EtgSnackbar,
		},
	})
	export default class FocusedLayout extends Vue {
		sTime = +new Date()
		loading = true

		async mounted() {
			// load locales
			await setSystemLocale.call(this)

			this.loading = false
		}

		get branding() {
			const t = this.$store.state.tenant
			return t && t.branding
		}

		get disabledBackground() {
			return !this.$route.params.subdomain || this.$route.path === `/app/${this.$route.params.subdomain}/imprint`
		}

		configPanelVisible = false
	}
