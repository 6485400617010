
	import { Component, Vue, Model, Watch, Prop } from 'nuxt-property-decorator'
	import { isEmail } from '~/app/common/validation/Validation'

	@Component({
		components: {},
	})
	export default class EmailsInput extends Vue {
		@Model('changed', { required: true }) value: any
		@Prop({ default: null }) placeholder: string
		@Prop({ default: false }) autofocus: boolean
		@Prop({ default: null }) dataQa: string

		localValue = []
		@Watch('value', { immediate: true })
		updateValue() {
			this.localValue = this.value
		}

		@Watch('localValue')
		updateLocalValue() {
			this.$emit('changed', this.localValue)
		}

		checkEmail = isEmail
	}
