
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Model, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { Process, Order } from '~/app/domain/content/Process'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'
	import SfSelect from '~/components/domain/conversation/service_forms/blocks/SfSelect.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import MultiBtn from '~/components/inputs/MultiBtn.vue'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import ProviderPicker from '~/components/domain/provider/ProviderPicker.vue'
	import InvoiceRecipient from '~/components/domain/process/InvoiceRecipient.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'

	@Component({
		components: {
			ProviderListItem,
			InvoiceRecipient,
			ProviderPicker,
			Attachments,
			Dropzone,
			MultiBtn,
			ConfirmationDialog,
			SearchLayout,
			BasicDialog,
			UserLine,
			RichTextEditor,
			SfDate,
			SfSelect,
			SfTextArea,
			SfTextField,
			CategoryContainer,
			DetailLayout,
		},
	})
	export default class ProcessOrderEdit extends Vue {
		@Model('change', { required: false }) process: Process
		@Prop({ required: false }) orderId: string
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		today = this.$dateFns.format(new Date(), 'yyyy-MM-dd')

		order: Order | any = {}

		async mounted() {
			await this.loadProviders()
		}

		// reload after attachments upload
		@Watch('process') updateProcess() {
			const fOrder = this.process.orders.find((o: any) => o.id === this.orderId)
			if (this.order && fOrder) {
				this.order = {
					...this.order,
					// @ts-ignore
					files: fOrder.files,
					// @ts-ignore
					images: fOrder.images,
				}
			}
		}

		@Watch('orderId', { immediate: true }) updateOrderId() {
			this.loadOrder()
		}

		loadOrder() {
			if (this.orderId) {
				const fOrder = this.process.orders.find((o: any) => o.id === this.orderId)
				if (fOrder) {
					this.order = {
						...fOrder,
						deadline: fOrder.deadline ? this.$dateFns.format(new Date(fOrder.deadline), 'yyyy-MM-dd') : '',
						provider: fOrder.provider?.id,
					}
					this.selectedProviderId = this.order.provider
				}
			}
		}

		get newOrder() {
			return !this.orderId
		}

		get published() {
			return this.order?.orderStatus !== 'draft'
		}

		/**
		 * Save
		 */
		get canSave() {
			return !!this.order.title && !!this.order.description && !!this.order.invoiceRecipient
		}

		saving = false

		getOrderPl() {
			return {
				id: this.newOrder ? undefined : this.orderId,
				process: this.newOrder ? this.process.id : undefined,
				provider: this.order.provider,
				title: this.order.title,
				description: this.order.description,
				deadline: this.order.deadline
					? this.$dateFns.parse(this.order.deadline, 'yyyy-MM-dd', new Date()).toISOString() || undefined
					: undefined,
				mustBeConfirmed: this.order.mustBeConfirmed,
				maximumCost: this.order.maximumCost,
				agreedCost: this.order.agreedCost,
				invoiceRecipient: this.order.invoiceRecipient,
			}
		}

		async save() {
			this.saving = true
			try {
				const response = await this.$axios.$post(
					this.newOrder
						? 'etg24:internal:command:orders.order.create'
						: 'etg24:internal:command:orders.order.update',
					{
						order: this.getOrderPl(),
					},
				)
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
			this.saving = false
		}

		/**
		 * Publish
		 */
		get canPublish() {
			return this.canSave && !!this.selectedProviderId
		}

		publishing = false

		async publish() {
			this.publishing = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.publish', {
					order: this.getOrderPl(),
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
			this.publishing = false
		}

		/**
		 * Remove
		 */

		removeDialog = false

		async remove() {
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.delete', {
					order: this.order.id,
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Providers
		 */
		providersResponse = null
		providers = []
		providerDialog = false

		async loadProviders() {
			try {
				this.providersResponse = await this.$axios.$get('etg24:internal:query:providers')
				this.providers = this.providersResponse.providers
			} catch (e) {
				Utils.handleError(e)
			}
		}

		selectedProviderId = null

		selectProvider(providers) {
			if (providers.length) {
				const providerId = providers[0].id
				this.order.provider = providerId
				this.selectedProviderId = providerId
				this.providerDialog = false
			}
		}

		get selectedProvider() {
			if (this.selectedProviderId)
				return this.providers.find((p) => {
					return p.id === this.selectedProviderId
				})
		}

		/**
		 * Attachments
		 */
		async uploadAttachment(file) {
			// build the form
			const formData = new FormData()
			formData.append('file', file)
			formData.set('order', this.order.id)

			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:orders.order.attachment.create',
					formData,
					{
						headers: { 'content-type': 'multipart/form-data' },
					},
				)
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}

		async deleteAttachment(file) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:orders.order.attachment.delete', {
					order: this.order.id,
					attachment: file.id,
				})
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}
	}
