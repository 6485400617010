export const state = () => ({
	defaults: {
		// set default feature values here
	},
	overrides: {
		// set override feature values here
	},
})

export const getters = {
	fromTenant: (state, getters, rootState, rootGetters) => {
		return rootGetters['tenant/features'] || null
	},

	fromUser: (state, getters, rootState, rootGetters) => {
		return rootState.auth?.user?.features || null
	},

	merged: (state, getters, rootState, rootGetters) => {
		// first, we'll take the defaults
		let features = { ...state.defaults }

		// if present, we'll override with the tenant settings
		if (getters['fromTenant']) {
			features = { ...features, ...getters['fromTenant'] }
		}

		// if present, we'll override with the tenant settings
		if (getters['fromUser']) {
			features = { ...features, ...getters['fromUser'] }
		}

		// finally, we add overrides
		features = { ...features, ...state.overrides }

		return features
	},

	// high level access to the feature switches, including implicit features
	isEnabled:
		(state, getters, rootState, rootGetters) =>
		(featureName: string): boolean => {
			return getters['merged'][featureName]
		},
}

export const mutations = {
	setFromTenant: (state, features) => {
		state.isSetFromTenant = true
		state.fromTenant = features
	},
	setFromUser: (state, features) => {
		state.isSetFromUser = true
		state.fromUser = features
	},
	addOverrides: (state, overrides) => {
		state.overrides = {
			...state.overrides,
			...overrides,
		}
	},
	removeOverride: (state, featureName) => {
		const overrides = state.overrides
		delete overrides[featureName]
		state.overrides = { ...overrides }
	},
}

export const actions = {
	setFromTenant: async (context, features) => {
		context.commit('setFromTenant', features)
	},

	setFromUser: async (context, features) => {
		context.commit('setFromUser', features)
	},

	// this is used for testing purposes only
	override: async (context, features) => {
		Object.keys(features).forEach((id) => {
			if (typeof features[id] === 'boolean') {
				context.commit('addOverrides', { [id]: features[id] })
			} else {
				context.commit('removeOverride', id)
			}
		})
	},
}
