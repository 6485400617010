
	import Utils from '~/app/utils/globals/Utils'
	import { goToContactByUser } from '@shared/types/contact'
	import { Component, Model, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { Participant } from '~/app/domain/communication/Conversation'
	import { MicroUser } from '~/app/domain/identifyAndAccess/User'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import ProviderPicker from '~/components/domain/provider/ProviderPicker.vue'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import UserInfoMenu from '~/components/domain/user/UserInfoMenu.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import UserListItem from '~/components/domain/user/UserListItem.vue'
	import UserPicker from '~/components/domain/user/UserPicker.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import Date from '~/components/utils/Date.vue'
	import { Object as PropertyObject } from '~/app/domain/property/Object'

	@Component({
		components: {
			ProviderPicker,
			ProviderListItem,
			UserPicker,
			UserInfoMenu,
			UnitContractPicker,
			SearchInput,
			BasicDialog,
			UserListItem,
			Date,
			UserLine,
			CardLargeContainer,
		},
	})
	export default class ConversationParticipants extends Vue {
		@Model('change', { required: true, default: () => [] })
		value: Participant[]
		@Prop({ required: true }) objectId: string
		@Prop({ default: false }) toggleable: boolean
		@Prop({ default: false }) editable: boolean
		@Prop({ default: false }) alwaysActive: boolean

		/**
		 * Local state management
		 */
		@Watch('value', { immediate: true })
		updateValue() {
			this.localValue = this.value
		}

		localValue: Participant[] = []
		@Watch('localValue')
		updateLocalValue() {
			this.$emit('change', this.localValue)
		}

		get tenantName() {
			const t = this.$store.state.tenant
			return t && t.tenant && t.tenant.companyName
		}

		object: PropertyObject | null = null

		@Watch('objectId', { immediate: true }) async updateObject() {
			if (this.objectId) {
				await this.$store.dispatch('property/objects/fetch', this.objectId)
				const object = this.$store.getters['property/objects/byId'](this.objectId)

				// in standalone mode, load all the additional data
				if (this.objectId) {
					await Promise.all([
						this.$store.dispatch('property/contracts/loadForObject', this.objectId),
						this.$store.dispatch('property/units/loadForObject', this.objectId),
						this.$store.dispatch('property/objectAddresses/loadForObject', this.objectId),
					])
				}

				// store the object
				this.object = object
			}
		}

		/**
		 * Add participants
		 */
		get addItems() {
			// add user participants
			return [
				{
					text: this.$tc('domain.conversation.addUsers', 2),
					callback: () => {
						this.addParticipantsDialog = true
					},
				},
				{
					text: this.$tc('domain.provider.add', 1),
					callback: () => {
						this.addProvidersDialog = true
					},
				},
			]
		}

		addParticipantsDialog = false
		participantSearchText = ''
		selectedUsers = []
		selectedUsersDialog = false
		selectUser(user: MicroUser) {
			if (user) {
				const i = this.selectedUsers.findIndex((u) => u.id === user.id)
				if (i >= 0) {
					this.selectedUsers.splice(i, 1)
				} else {
					this.selectedUsers.push(user)
				}
			}
		}

		setUsers(users: MicroUser[]) {
			this.selectedUsers = users.filter((u) => u?.id || u)
		}

		addUsers() {
			if (this.selectedUsers.length) {
				this.$emit('addUsers', {
					users: this.selectedUsers.filter((s) => !!s && !this.localValue.find((p) => p.id === s.id)),
				})
				this.addParticipantsDialog = false
				this.selectedUsers = []
			}
		}

		async toggleParticipant(participant) {
			try {
				const response = await this.$axios.$post(
					participant.isActive
						? 'etg24:internal:command:communication.conversations.activateparticipant'
						: 'etg24:internal:command:communication.conversations.deactivateparticipant',
					{
						participant: participant.id,
					},
				)
				this.$emit('updateConversation', response)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		async goToContact(user: MicroUser | null) {
			if (!user) {
				return
			}
			await goToContactByUser.bind(this)(user)
		}

		/**
		 * Add providers
		 */
		addProvidersDialog = false

		addProviders(providers) {
			this.$emit('addProviders', { providers })
			this.addProvidersDialog = false
		}
	}
