
	import Vue from 'vue'
	import { Component, Model, Prop, Watch } from 'nuxt-property-decorator'
	import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
	import {
		HardBreak,
		OrderedList,
		BulletList,
		ListItem,
		TodoItem,
		TodoList,
		Bold,
		Italic,
		Link,
		Strike,
		Underline,
		History,
	} from 'tiptap-extensions'

	@Component({
		components: {
			EditorContent,
			EditorMenuBar,
			EditorMenuBubble,
		},
	})
	export default class RichTextEditor extends Vue {
		@Model('update', { required: true }) text: string
		@Prop({ default: '100%' }) maxWidth: string
		@Prop({ default: null }) label: string
		@Prop({ default: null }) initialText: string
		@Prop({ default: null }) errorMessages: string[]
		@Prop({ type: Number, default: 0 }) timeout: number
		@Prop({ type: Boolean, default: false }) autofocus: boolean
		@Prop({ type: Boolean, default: false }) sync: boolean

		// tells if the editor has focus
		hasFocus = false
		hasFocusedOnce = false

		// timeout internal
		timeoutInternal: NodeJS.Timeout | null = null

		// editor variable
		editor: Editor | null = null
		mounted() {
			this.editor = new Editor({
				extensions: [
					new BulletList(),
					new HardBreak(),
					new ListItem(),
					new OrderedList(),
					new TodoItem(),
					new TodoList(),
					new Link({
						openOnClick: false,
					}),
					new Bold(),
					new Italic(),
					new Strike(),
					new Underline(),
					new History(),
				],
				content: this.initialText || this.text,
				onUpdate: ({ getHTML }) => {
					let state = getHTML()
					if (state === '<p></p>') state = ''
					this.$emit('update', state)
					if (this.timeout) {
						if (this.timeout && this.timeoutInternal) clearInterval(this.timeoutInternal)
						this.timeoutInternal = setInterval(() => {
							if (!this.timeoutInternal) return
							clearInterval(this.timeoutInternal)
							this.$emit('timeout')
						}, this.timeout)
					}
				},
				onFocus: () => {
					this.hasFocus = true
					this.hasFocusedOnce = true
				},
				onBlur: () => {
					this.hasFocus = false
					this.$emit('blur')
				},
			})

			if (!this.editor) {
				return
			}

			if (!this.autofocus) {
				return
			}

			this.editor.focus(this.initialText || this.text ? 'end' : null)
		}

		beforeDestroy() {
			this.editor.destroy()
		}

		// link logic
		linkUrl = null
		linkMenuIsActive = false
		showLinkMenu(attrs) {
			this.linkUrl = attrs.href
			this.linkMenuIsActive = true
			this.$nextTick(() => {
				;(this.$refs.linkInput as HTMLInputElement).focus()
			})
		}

		hideLinkMenu() {
			this.linkUrl = null
			this.linkMenuIsActive = false
		}

		setLinkUrl(command, url) {
			command({ href: url })
			this.hideLinkMenu()
		}

		focus() {
			this.editor.focus()
		}

		@Watch('initialText')
		initialTextUpdated() {
			// updating the text straight away creates a glitch with the cursor
			this.editor.setContent(this.initialText)
		}

		@Watch('text')
		textUpdated() {
			if (this.sync && this.editor && this.editor.getHTML() !== this.text) {
				// updating the text straight away creates a glitch with the cursor
				this.editor.setContent(this.text)
			}
		}

		reset() {
			this.editor.setContent('')
		}
	}
