var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'nuxt-link' : 'span',{tag:"Component",staticClass:"tw-flex tw-items-center tw-overflow-hidden tw-p-1",class:{
		'tw-text-text-base': (_vm.variant === 'default' && _vm.color === 'default') || _vm.variant !== 'default',
		'tw-text-primary-contrast': _vm.variant === 'default' && _vm.color !== 'default',
		'tw-bg-[var(--base)]': _vm.variant === 'default',
		'tw-bg-background-soft tw-ring-1 tw-ring-[var(--base)]': _vm.variant === 'outlined',
		'tw-max-h-8': _vm.size === 'md',
		'tw-max-h-7 tw-text-sm': _vm.size === 'sm',
		'tw-max-h-6 tw-text-[0.82rem]': _vm.size === 'xs',
		'tw-pl-3': !_vm.$slots.prev,
		'tw-pr-3': !_vm.$slots.next,
		'tw-justify-center': !_vm.$slots.prev && !_vm.$slots.next,
		'tw-justify-between': _vm.$slots.prev || _vm.$slots.next,
		'hoverable tw-cursor-pointer': _vm.clickable || _vm.to,
		'tw-rounded-md': _vm.label,
		'tw-rounded-full': !_vm.label,
		'tw-pointer-events-none tw-opacity-50': _vm.disabled,
	},style:(`--base: ${_setup.baseColors[_vm.color]};`),attrs:{"to":_vm.to}},[(_vm.$slots.left)?_c('span',{staticClass:"tw-mr-2"},[_vm._t("left")],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap"},[_vm._t("default",function(){return [_vm._v("\n\t\t\t"+_vm._s(_vm.text)+"\n\t\t")]})],2),_vm._v(" "),(_vm.$slots.right)?_c('span',{staticClass:"tw-ml-2"},[_vm._t("right")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }