import { Button } from '~/app/common/types/Button'
import { reduceToFrontendRoleTypes, RoleType } from '~/app/domain/identifyAndAccess/Role'
import Image from '~/app/utils/globals/Image'
import Utils from '~/app/utils/globals/Utils'

const isDev = process.env.isDev

/**
 * Get a navtree
 * @param objectId
 */
export function getNavtree(type: string, params?: any): Button[] {
	switch (type) {
		// Property FO
		case 'PropertyFo':
			return _getNavtreePropertyFo.call(this)
		case 'PropertyFoUnit':
			return _getNavtreePropertyFoUnit.call(this)
		case 'PropertyFoDocuments':
			return _getNavtreePropertyFoDocuments.call(this, params)
		// Provider FO
		case 'ProviderFo':
			return _getNavtreeProviderFo.call(this)
		case 'ProviderFoDocuments':
			return _getNavtreeProviderFoDocuments.call(this, params)
		// Property BO
		case 'PropertyBoLayout':
			return _getNavtreePropertyBo.call(this)
		case 'PropertyBoObject':
			return _getNavtreePropertyBoObject.call(this, params)
		case 'PropertyBoObjectUnit':
			return _getNavtreePropertyBoObjectUnit.call(this)
		case 'PropertyBoObjectUnitContract':
			return _getNavtreePropertyBoObjectUnitContract.call(this)
		case 'PropertyBoObjectCommunication':
			return _getNavtreePropertyBoObjectCommunication.call(this, params.objectId)
		case 'PropertyBoObjectGroup':
			return _getNavtreePropertyBoObjectGroup.call(this)
		// Privder BO
		case 'ProviderBoLayout':
			return _getNavtreeProviderBo.call(this)
		case 'ProviderBoCompany':
			return _getNavtreeProviderBoCompany.call(this)
		case 'ProviderBoCompanyGroup':
			return _getNavtreeProviderBoCompanyGroup.call(this)
		case 'ProviderBoCompanyObject':
			return _getNavtreeProviderBoCompanyObject.call(this)
		case 'ProviderBoGeneralContent':
			return _getNavtreeProviderBoGeneralContent.call(this)
		// Others
		case 'SettingsBranding':
			return _getNavtreeSettingsBranding.call(this)
		default:
			return []
	}
}

function _getNavtreePropertyFo(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			exact: true,
			icon: 'fal fa-home-alt',
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			icon: Image.getIconForDomain('document'),
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			icon: Image.getIconForDomain('process'),
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
		{
			text: this.$tc('domain.post.base', 2),
			to: '/posts',
			hidden: !isDev,
			icon: Image.getIconForDomain('post'),
		},
		{
			text: this.$tc('domain.event.base', 2),
			to: '/events',
			hidden: !this.$store.getters['features/isEnabled']('content:events'),
			icon: Image.getIconForDomain('event'),
		},
		{
			text: this.$tc('domain.contactPerson.base', 2),
			to: '/contact-persons',
			icon: Image.getIconForDomain('contact-person'),
			hidden: !this.$store.getters['features/isEnabled']('content:contactPersons'),
		},
		{
			text: this.$tc('domain.voting.base', 2),
			to: '/votings',
			icon: Image.getIconForDomain('voting'),
			id: 'voting',
			hidden: !this.$store.getters['features/isEnabled']('pm:votings'),
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: '/faqs',
			icon: Image.getIconForDomain('faq'),
			hidden: !this.$store.getters['features/isEnabled']('content:faqs'),
		},
		{
			text: this.$tc('domain.service.base', 2),
			to: '/conversations',
			icon: Image.getIconForDomain('conversation'),
			hidden: !this.$store.getters['features/isEnabled']('communication:conversations'),
		},
	]
}

function _getNavtreePropertyFoUnit(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			exact: true,
			icon: 'fal fa-home-alt',
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			icon: Image.getIconForDomain('document'),
		},
	]
}

function _getNavtreeProviderFo(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			exact: true,
			icon: 'fal fa-home-alt',
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			icon: Image.getIconForDomain('document'),
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			icon: Image.getIconForDomain('process'),
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
		{
			text: this.$tc('domain.contactPerson.base', 2),
			to: '/contact-persons',
			icon: Image.getIconForDomain('contact-person'),
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: '/faqs',
			icon: Image.getIconForDomain('faq'),
			hidden: !this.$store.getters['features/isEnabled']('content:faqs'),
		},
		{
			text: this.$tc('domain.service.base', 2),
			to: '/conversations',
			icon: Image.getIconForDomain('conversation'),
			hidden: !this.$store.getters['features/isEnabled']('communication:conversations'),
		},
	]
}

function _getNavtreeProviderBo(): any {
	const baseObjectPath = Utils.appPath()
	return {
		top: [
			{
				text: this.$tc('domain.customer.base', 2),
				to: `${baseObjectPath}/companies`,
				icon: Image.getIconForDomain('customer', 'solid'),
			},
			{
				text: this.$tc('domain.customerGroup.base', 2),
				to: `${baseObjectPath}/company-groups`,
				icon: Image.getIconForDomain('company-group', 'solid'),
			},
			{
				text: this.$tc('domain.contactPerson.base', 2),
				to: `${baseObjectPath}/contact-persons`,
				icon: Image.getIconForDomain('contact-person', 'solid'),
			},
		],
		bottom: [
			{
				text: this.$t('layout.general'),
				to: `${baseObjectPath}/settings`,
				exact: true,
				icon: Image.getIconForDomain('settings', 'solid'),
			},
			{
				text: this.$tc('domain.employee.base', 2),
				to: `${baseObjectPath}/employees`,
				exact: true,
				icon: Image.getIconForDomain('contact-person', 'solid'),
			},
			{
				text: this.$tc('domain.serviceForm.base', 2),
				to: `${baseObjectPath}/settings/service-forms`,
				icon: Image.getIconForDomain('service-form', 'solid'),
			},
			{
				text: this.$t('domain.settings.branding.base'),
				to: `${baseObjectPath}/settings/branding`,
				icon: 'fas fa-paint-roller',
			},
		],
	}
}

function _getNavtreePropertyBo(): any {
	const baseObjectPath = Utils.appPath()
	return {
		top: [
			{
				text: this.$t('domain.note.todos.title'),
				to: `${baseObjectPath}/todos`,
				icon: 'fas fa-tasks',
				hidden: !this.$store.getters['features/isEnabled']('content:notes'),
			},
			{
				text: this.$t('layout.calendar'),
				to: `${baseObjectPath}/calendar`,
				icon: 'fas fa-calendar',
				hidden: !this.$store.getters['features/isEnabled']('content:events'),
			},
			{
				text: this.$tc('domain.callRecord.base', 2),
				to: `${baseObjectPath}/calls`,
				icon: Image.getIconForDomain('callRecord'),
				hidden: !this.$store.getters['features/isEnabled']('pm:callRecords'),
			},
			{
				text: this.$tc('domain.process.base', 2),
				to: `${baseObjectPath}/processes`,
				icon: Image.getIconForDomain('process', 'solid'),
				hidden: !this.$store.getters['features/isEnabled']('content:processes'),
			},
			{
				text: this.$t('domain.inboundEmail.inbox.label'),
				to: `${baseObjectPath}/inbound-emails`,
				icon: Image.getIconForDomain('email'),
				hidden: !this.$store.getters['features/isEnabled']('softlaunch:inboundEmail'),
			},
			{
				text: this.$tc('domain.conversation.base', 2),
				to: `${baseObjectPath}/conversations`,
				icon: Image.getIconForDomain('conversation', 'solid'),
				hidden: !this.$store.getters['features/isEnabled']('communication:conversations'),
			},
			{
				text: this.$tc('domain.object.base', 2),
				to: `${baseObjectPath}/objects`,
				icon: Image.getIconForDomain('object', 'solid'),
			},
			{
				text: this.$tc('domain.objectGroup.base', 2),
				to: `${baseObjectPath}/object-groups`,
				icon: Image.getIconForDomain('object-group', 'solid'),
				hidden: !this.$store.getters['features/isEnabled']('pm:objectGroups'),
			},
			{
				text: this.$tc('domain.contactPerson.base', 2),
				to: `${baseObjectPath}/contact-persons`,
				icon: Image.getIconForDomain('contact-person', 'solid'),
			},
			{
				text: this.$tc('domain.contact.base', 2),
				to: `${baseObjectPath}/contacts`,
				icon: Image.getIconForDomain('contact', 'solid'),
			},
		],
		bottom: [
			{
				text: this.$t('layout.general'),
				to: `${baseObjectPath}/settings/general`,
				icon: Image.getIconForDomain('settings', 'solid'),
			},
			{
				text: this.$tc('domain.employee.base', 2),
				to: `${baseObjectPath}/employees`,
				icon: Image.getIconForDomain('contact-person', 'solid'),
			},
			{
				text: this.$tc('domain.serviceForm.base', 2),
				to: `${baseObjectPath}/settings/service-forms`,
				icon: Image.getIconForDomain('service-form', 'solid'),
			},
			{
				text: this.$t('domain.settings.branding.base'),
				to: `${baseObjectPath}/settings/branding`,
				icon: 'fas fa-paint-roller',
				hidden: !this.$store.getters['features/isEnabled']('pm:branding'),
			},
			{
				text: this.$t('domain.settings.connect.base'),
				to: `${baseObjectPath}/settings/connect`,
				icon: Image.getIconForDomain('connect', 'solid'),
				hidden: !this.$store.getters['features/isEnabled']('pm:connect'),
			},
			{
				text: this.$t('domain.addonsIntegrations.title'),
				to: `${baseObjectPath}/addons-integrations`,
				icon: Image.getIconForDomain('integration'),
				hidden: !this.$store.getters['features/isEnabled']('softlaunch:integrations'),
			},
			{
				text: this.$t('plans.upgradePage.navbarTitle'),
				to: `${baseObjectPath}/upgrade`,
				icon: Image.getIconForDomain('upgrade', 'solid'),
				color: 'upgrade',
				hidden: this.$store.getters['features/isEnabled']('pm:featureSet:30'),
			},
		],
	}
}

function _getNavtreeProviderBoCompany(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			icon: Image.getIconForDomain('company'),
			exact: true,
		},
		{
			text: this.$tc('domain.user.base', 2),
			to: '/users',
			exact: true,
			icon: Image.getIconForDomain('contact-person'),
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			icon: Image.getIconForDomain('document'),
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			icon: Image.getIconForDomain('process'),
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
		{
			text: this.$tc('domain.contactPerson.base', 2),
			to: '/contact-persons',
			icon: Image.getIconForDomain('contact-person'),
		},
		{
			text: this.$tc('domain.service.base', 2),
			to: '/conversations',
			exact: true,
			icon: Image.getIconForDomain('conversation'),
			hidden: !this.$store.getters['features/isEnabled']('communication:conversations'),
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: '/faqs',
			icon: Image.getIconForDomain('faq'),
		},
	]
}

function _getNavtreePropertyFoDocuments(params): Button[] {
	return [
		{
			text: this.$t('domain.document.target.object'),
			to: `${params.basePath || ''}/documents/object`,
			icon: 'fal fa-home',
			exact: true,
		},
		{
			text: this.$t('domain.document.target.unit'),
			to: `${params.basePath || ''}/documents/units/${params.selectedUnitId}`,
			icon: 'fal fa-file-alt',
			hidden: params.units.length === 0,
		},
	]
}

function _getNavtreeSettingsBranding(): Button[] {
	const basePath = '/settings/branding'
	return [
		{
			text: this.$t('domain.settings.branding.loginScreen.base'),
			to: `${basePath}/login-screen`,
		},
		{
			text: this.$t('domain.settings.branding.footer.base'),
			to: `${basePath}/footer`,
		},
		{
			text: this.$t('domain.settings.branding.pdfBackgrounds.base'),
			to: `${basePath}/pdf-backgrounds`,
			hidden: !this.$store.getters['features/isEnabled']('softlaunch:letters'),
		},
	]
}

function _getNavtreeProviderFoDocuments(params): Button[] {
	const basePath = `${Utils.appPath()}/${params.companyId}`
	return [
		{
			text: this.$t('domain.document.target.company'),
			to: `${basePath}/documents/company`,
			icon: 'fal fa-home',
			exact: true,
		},
		{
			text: this.$t('domain.document.target.object'),
			to: `${basePath}/documents/objects/${params.objects && params.objects.length > 0 && params.objects[0].id}`,
			icon: 'fal fa-file-alt',
			hidden: params.objects.length === 0,
		},
	]
}

function _getNavtreeProviderBoCompanyGroup(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			icon: Image.getIconForDomain('company'),
			exact: true,
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			icon: Image.getIconForDomain('document'),
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.contactPerson.base', 2),
			to: '/contact-persons',
			icon: Image.getIconForDomain('contact-person'),
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: '/faqs',
			icon: Image.getIconForDomain('faq'),
		},
	]
}

function _getNavtreeProviderBoCompanyObject(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			icon: Image.getIconForDomain('company'),
			exact: true,
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			icon: Image.getIconForDomain('document'),
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			icon: Image.getIconForDomain('process'),
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
	]
}

function _getNavtreeProviderBoGeneralContent(): Button[] {
	const basePath = `${Utils.appPath()}/general-content`
	return [
		{
			text: this.$tc('domain.document.base', 2),
			to: `${basePath}/documents`,
			icon: Image.getIconForDomain('document'),
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.contactPerson.base', 2),
			to: `${basePath}/contact-persons`,
			icon: Image.getIconForDomain('contact-person'),
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: `${basePath}/faqs`,
			icon: Image.getIconForDomain('faq'),
		},
	]
}

function _checkHiddenUserRole(role) {
	if (role === RoleType.advisor) {
		return !this.$store.getters['features/isEnabled'](['pm:objectAdvisors'])
	}
	if (role === RoleType.supplier) {
		return !this.$store.getters['features/isEnabled'](['pm:rolesTypes:supplier'])
	}
}

function _getNavtreePropertyBoObject(objectId): Button[] {
	const object = this.$store.getters['property/objects/byId'](objectId)
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			exact: true,
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.user.base', 2),
			to: '/users',
			icon: Image.getIconForDomain('contact-person'),
			items: reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 2),
					to: `/users/${r}`,
					icon: Image.getIconForDomain(r),
					hidden: _checkHiddenUserRole.call(this, r),
				}
			}),
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
		{
			text: this.$tc('domain.event.base', 2),
			to: '/events',
			hidden: !this.$store.getters['features/isEnabled']('content:events'),
		},
		{
			text: this.$tc('domain.contactPerson.base', 2),
			to: '/contact-persons',
		},
		{
			text: this.$tc('domain.communication.base', 1),
			to: '/communication',
			hidden: !this.$store.getters['features/isEnabled']('communication:conversations'),
			items: [
				{
					text: this.$tc('domain.conversation.base', 2),
					to: '/communication/conversations',
					icon: Image.getIconForDomain('conversation'),
				},
				{
					text: this.$tc('domain.post.base', 2),
					to: '/communication/posts',
					icon: Image.getIconForDomain('post'),
				},
				{
					text: this.$tc('domain.delivery.base', 2),
					to: '/communication/deliveries',
					icon: Image.getIconForDomain('delivery'),
					hidden: !this.$store.getters['features/isEnabled']('pm:deliveries'),
				},
				{
					text: this.$tc('domain.voting.base', 2),
					to: '/communication/votings',
					icon: Image.getIconForDomain('voting'),
					id: 'voting',
					hidden:
						!this.$store.getters['features/isEnabled']('pm:votings') || object?.managementType !== 'owner',
				},
				{
					text: this.$tc('domain.serviceForm.base', 2),
					to: '/communication/service-forms',
					icon: Image.getIconForDomain('service-form'),
				},
			],
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: '/faqs',
		},
		{
			hidden: this.$store.getters['features/isEnabled']('pm:featureSet:30'),
			text: `${this.$t('layout.more').toLowerCase()}...`,
			color: 'upgrade',
			fullTo: `${Utils.appPath()}/upgrade`,
		},
	]
}

function _getNavtreePropertyBoObjectUnit(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '/',
			exact: true,
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
		},
	]
}

function _getNavtreePropertyBoObjectUnitContract(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '/',
			exact: true,
		},
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.conversation.base', 2),
			to: '/conversations',
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
		{
			text: this.$tc('domain.event.base', 2),
			to: '/events',
			hidden: !this.$store.getters['features/isEnabled']('content:events'),
		},
	]
}

function _getNavtreePropertyBoObjectGroup(): Button[] {
	return [
		{
			text: this.$t('layout.home'),
			to: '',
			exact: true,
		},
		// {
		// 	text: this.$t('domain.note.todos.title'),
		// 	to: `/todos`,
		// 	hidden: !this.$store.getters['features/isEnabled']('content:notes'),
		// },
		{
			text: this.$tc('domain.document.base', 2),
			to: '/documents',
			hidden: !this.$store.getters['features/isEnabled']('content:documents'),
		},
		{
			text: this.$tc('domain.process.base', 2),
			to: '/processes',
			hidden: !this.$store.getters['features/isEnabled']('content:processes'),
		},
		{
			text: this.$tc('domain.event.base', 2),
			to: '/events',
			hidden: !this.$store.getters['features/isEnabled']('content:events'),
		},
		{
			text: this.$tc('domain.communication.base', 1),
			to: '/communication/posts',
			hidden: !this.$store.getters['features/isEnabled']('communication:conversations'),
		},
		{
			text: this.$tc('domain.faq.base', 2),
			to: '/faqs',
		},
		{
			hidden: this.$store.getters['features/isEnabled']('pm:featureSet:30'),
			text: `${this.$t('layout.more').toLowerCase()}...`,
			color: 'upgrade',
			fullTo: `${Utils.appPath()}/upgrade`,
		},
	]
}

function _getNavtreePropertyBoObjectCommunication(objectId): Button[] {
	const baseObjectPath = `${Utils.appPath()}/objects/${objectId}/communication`
	return [
		{
			text: this.$tc('domain.conversation.base', 2),
			to: `${baseObjectPath}/conversations`,
			icon: Image.getIconForDomain('conversation'),
		},
		{
			text: this.$tc('domain.post.base', 2),
			to: `${baseObjectPath}/posts`,
			icon: Image.getIconForDomain('post'),
		},
		{
			text: this.$tc('domain.delivery.base', 2),
			to: `${baseObjectPath}/deliveries`,
			icon: Image.getIconForDomain('delivery'),
			hidden: !this.$store.getters['features/isEnabled']('pm:deliveries'),
		},
		{
			text: this.$tc('domain.voting.base', 2),
			to: `${baseObjectPath}/votings`,
			icon: Image.getIconForDomain('voting'),
			id: 'voting',
			hidden: !this.$store.getters['features/isEnabled']('pm:votings'),
		},
		{
			text: this.$tc('domain.serviceForm.base', 2),
			to: `${baseObjectPath}/service-forms`,
			icon: Image.getIconForDomain('service-form'),
		},
	]
}
