var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-@container/contact-information tw-grid tw-gap-2 tw-grid-cols-12"},_vm._l((_setup.categories),function(category,index){return _c('div',{key:index,staticClass:"tw-col-span-12",class:{
			'@md/contact-information:tw-col-span-6': _setup.categories.length > 1,
		}},[(_setup.categories.length > 1)?_c(_setup.UiTypography,{staticClass:"tw-text-center tw-text-text-base tw-pb-3",attrs:{"variant":"overline"}},[_vm._v("\n\t\t\t"+_vm._s(category.text)+"\n\t\t")]):_vm._e(),_vm._v(" "),(category.value === 'user' && !!_setup.userNote && _setup.definedIds.length)?_c(_setup.UiAlert,{attrs:{"accent":"","icon":`fal fa-${_setup.userNote.icon}`,"text":String(_vm.$t(_setup.userNote.messageKey, _setup.userNote.messageArgs)),"variant":"info","bordered":""}}):[(_setup.categories.length > 1)?_c('div',{staticClass:"tw-inline-flex tw-justify-between tw-w-full tw-items-center tw-pr-2 tw-pb-3",staticStyle:{"height":"40px"}},[_c(_setup.UiTypography,{staticClass:"tw-text-text-base",attrs:{"variant":"overline"}},[_c('div',[_vm._v(_vm._s(category.profile.name))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(category.profile.subname))])]),_vm._v(" "),(category.value === 'contact' && category.profile.mailAddress)?_c(_setup.UiButton,{attrs:{"icon":"fal fa-copy","variant":"text","dense":"","rounded":""},on:{"click":function($event){return _setup.copyToClipboard(category.profile.mailAddress)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t('domain.contact.actions.copyContactMailingAddress.label'))+":")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_setup.nl2br(category.profile.mailAddress))}})]},proxy:true}],null,true)}):(category.value === 'user')?_c(_setup.UserAvatar,{attrs:{"image":category.profile.image,"size":"lg"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-grid tw-gap-2 tw-grid-cols-12"},[_vm._l((_setup.definedIds),function(id){return [(_setup.getCategoryAttribute(category.value, id))?_c('div',{key:id,staticClass:"tw-col-span-12",class:{
							'@md/contact-information:tw-col-span-6': _setup.categories.length === 1,
						}},[(_setup.getCategoryAttribute(category.value, id))?_c(_setup.AttributeCard,_vm._b({scopedSlots:_vm._u([(
									category.value === 'user' &&
									_setup.getCategoryAttribute(category.value, id).canTransfer
								)?{key:"quick-action",fn:function(){return [_c(_setup.UiButton,{attrs:{"icon":"fal fa-bolt","variant":"text","dense":"","rounded":"","loading":_setup.currentlyCopiedAttribute === id,"tooltip":String(_vm.$t('domain.contact.copyFromOnlineProfileToContact'))},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_setup.copyFromProfile(
											id,
											_setup.getCategoryAttribute('user', id)?.copyValue ||
												_setup.getCategoryAttribute('user', id)?.value,
										)}}})]},proxy:true}:null],null,true)},'AttributeCard',_setup.getCategoryAttribute(category.value, id),false)):_vm._e()],1):_vm._e()]})],2)]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }