import { PDFDocument } from 'pdf-lib'
import { getDocument } from 'pdfjs-dist'
import { getMergedTextsFromItems, getPageItems } from './libs/PdfParser'
import type { ModelValue as AreaDefinition } from '~/components/inputs/InputArea.vue'

function normalizeString(str: string) {
	return str.toLowerCase().replace(/[^a-z0-9]/g, '')
}

//TODO: this should probably end up in our own PDF-LIB PDFJS-DIST WRAPPER i.e libs/PdfLib.ts

// Function signature for splitting by number of pages
async function splitPDF(sourcePDFFile: File, pagesPerPDF: number, abortSignal?: AbortSignal): Promise<File[]>
// Function signature for splitting by marker
async function splitPDF(
	sourcePDFFile: File,
	marker: string,
	abortSignal?: AbortSignal,
	isMarkerStart?: boolean,
	area?: AreaDefinition,
): Promise<File[]>
// Function implementation
async function splitPDF(
	sourcePDFFile: File,
	arg2: string | number,
	abortSignal?: AbortSignal,
	isMarkerStart?: boolean,
	area?: AreaDefinition,
): Promise<File[]> {
	const reader = new FileReader()
	const fileArrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
		reader.onload = () => resolve(reader.result as ArrayBuffer)
		reader.onerror = reject
		reader.readAsArrayBuffer(sourcePDFFile)
	})

	if (abortSignal?.aborted) {
		throw new Error('Aborted')
	}
	const sourcePDFBuffer = new Uint8Array(fileArrayBuffer)
	if (abortSignal?.aborted) {
		throw new Error('Aborted')
	}
	const sourcePDF = await PDFDocument.load(sourcePDFBuffer)
	const totalPages = sourcePDF.getPageCount()
	const runId = crypto.randomUUID()

	if (typeof arg2 === 'number') {
		const pagesPerPDF = arg2
		const numPDFs = Math.ceil(totalPages / pagesPerPDF)

		return await Promise.all(
			Array.from({ length: numPDFs }, async (_, index) => {
				if (abortSignal?.aborted) {
					throw new Error('Aborted')
				}
				const start = index * pagesPerPDF
				const end = Math.min(start + pagesPerPDF, totalPages)
				const newPDF = await PDFDocument.create()

				const indices = Array.from({ length: end - start }, (_, i) => start + i)
				const pages = await newPDF.copyPages(sourcePDF, indices)
				pages.forEach((page) => newPDF.addPage(page))

				const newPDFBuffer = await newPDF.save()
				const newPDFBlob = new Blob([newPDFBuffer.buffer], { type: 'application/pdf' })
				const newPDFFile = new File([newPDFBlob], `output_${runId}_${index + 1}.pdf`, {
					type: 'application/pdf',
				})

				return newPDFFile
			}),
		)
	} else if (typeof arg2 === 'string') {
		const marker = arg2

		const pdfjsDocument = await getDocument({ data: sourcePDFBuffer.buffer as Int8Array }).promise
		if (abortSignal?.aborted) {
			throw new Error('Aborted')
		}
		const textContents = await Promise.all(
			Array.from({ length: totalPages }, async (_, i) => {
				return getMergedTextsFromItems(await getPageItems(pdfjsDocument, i + 1, area))
			}),
		)

		const markerPages = textContents
			.map((content, i) => ({ content, i }))
			//TODO: use StringMatcher and MatchCertainty to determine if the marker is found
			.filter(({ content }) => {
				return content.some((contentString) => {
					return normalizeString(contentString).includes(normalizeString(marker))
				})
			})
			.map(({ i }) => i)

		pdfjsDocument.destroy()

		return await Promise.all(
			markerPages.map(async (markerPage, index) => {
				if (abortSignal?.aborted) {
					throw new Error('Aborted')
				}
				const calculatedStart = isMarkerStart ? markerPage : index === 0 ? 0 : markerPages[index - 1] + 1
				const calculatedEnd = isMarkerStart ? markerPages[index + 1] - 1 : markerPage
				const end = calculatedEnd >= 0 && calculatedEnd <= totalPages - 1 ? calculatedEnd : totalPages - 1
				const newPDF = await PDFDocument.create()

				const indices = Array.from({ length: end - calculatedStart + 1 }, (_, i) => calculatedStart + i)
				if (abortSignal?.aborted) {
					throw new Error('Aborted')
				}
				const pages = await newPDF.copyPages(sourcePDF, indices)
				pages.forEach((page) => newPDF.addPage(page))

				const newPDFBuffer = await newPDF.save()
				const newPDFBlob = new Blob([newPDFBuffer.buffer], { type: 'application/pdf' })
				const newPDFFile = new File([newPDFBlob], `output_${runId}_${index + 1}.pdf`, {
					type: 'application/pdf',
				})
				return newPDFFile
			}),
		)
	} else {
		console.log({ arg2, area })
		throw new Error('Invalid arguments.')
	}
}

export default splitPDF
