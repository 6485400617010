
	import { Component, Prop, Vue, Model, Watch } from 'nuxt-property-decorator'
	import { Company } from '~/app/domain/provider/Provider'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import CompanyCard from '~/components/domain/company/CompanyCard.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import { CompanyGroup, companyGroupSearchKeys } from '~/app/domain/provider/CompanyGroup'
	import CompanyGroupCard from '~/components/domain/company_group/CompanyGroupCard.vue'

	@Component({
		components: {
			CompanyGroupCard,
			BasicDialog,
			CompanyCard,
			SearchLayout,
		},
	})
	export default class CompanyGroupPicker extends Vue {
		// keep the visibility synced
		@Model('change', { type: Boolean }) visible: boolean
		@Prop({ required: true }) groups: CompanyGroup[]
		@Prop({ default: null }) title: string
		@Prop({ type: Array, default: () => [] }) selected

		localVisible = false

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
			if (this.localVisible && this.selected.length) {
				this.selectedGroups = this.selected
			}
		}

		/**
		 * Company filtering
		 */
		searchText = ''
		filteredGroups: Company[] = []
		get filteredGroup() {
			let filtered = this.groups
			if (this.searchText) {
				filtered =
					search(filtered, this.searchText, {
						fuzzy: true,
						sort: true,
						keys: companyGroupSearchKeys,
					}) || []
			}
			return filtered
		}

		selectedGroups: string[] = []
		selectGroup(group) {
			if (!this.selectedGroups.includes(group)) {
				this.selectedGroups.push(group)
			} else {
				this.selectedGroups = this.selectedGroups.filter((c) => {
					return c !== group.id
				})
			}
		}

		sendSelected() {
			this.$emit('selected', this.selectedGroups)
			this.selectedGroups = []
			this.localVisible = false
		}
	}
