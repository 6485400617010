import { isPast, isToday } from 'date-fns'
import { Entity, EntityId } from '../common/Interfaces'
import { DateString, DayString, LongString, ShortString, Uuid } from '../Types'
import { MicroUser, microUserSearchKeys, UserId } from '../identifyAndAccess/User'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import sort from '../../common/filters/iterator/Sort'
import { NodeId } from '~/app/domain/content/Common'
import { ScopeType } from '@shared/types'

/*
 * Model
 */

// export type CollaboratorId = EntityId
export interface Collaborator /*extends Entity<CollaboratorId>*/ {
	user: MicroUser // collaborators get cascade-removed with users
	noticedOn: DateString | null
	// reminders: Reminder[]
}

export { ScopeType } from '@shared/types'

export type NoteId = EntityId
export interface Note extends Entity<NoteId> {
	scope: Uuid
	scopeType: ScopeType
	scopeTitle: ShortString
	rootScope: Uuid | null
	nodeId: NodeId | null
	description: LongString
	// todos: Todo[]
	deadline: DayString | null
	doneOn: DateString | null
	contentDate: DateString
	collaborators: Collaborator[]
}

export interface NoteList {
	relevant: Note[]
	others: Note[]
	done: Note[]
}

/*
 * Validation
 */

/*
 * Search
 */

export const collaboratorSearchKeys: (keyof Collaborator)[] = [
	...(prefixSearchKeys('user', microUserSearchKeys) as (keyof Collaborator)[]),
]

export const noteSearchKeys: (keyof Note)[] = [
	'description',
	// 'todos',
	...(prefixSearchKeys('collaborators', collaboratorSearchKeys) as (keyof Note)[]),
]

/*
 * Api
 */

export interface NotesQueryResponse {
	notes: Note[]
	nodes: Node[]
}

/*
 * Functions
 */

export const isNoteDone = (note: Note) => {
	return !!note.doneOn
}

export const hasCollaborators = (note: Note) => {
	return !!note.collaborators.length
}

const getCollaborator = (note: Note, userId: UserId) => {
	return note.collaborators.find((collaborator) => collaborator.user.id === userId)
}

export const isUserCollaborator = (note: Note, userId: UserId) => {
	return !!getCollaborator(note, userId)
}

export const isUserCollaboratorAndBehind = (note: Note, userId: UserId) => {
	const collaborator = getCollaborator(note, userId)
	return collaborator && collaborator.noticedOn && collaborator.noticedOn < note.contentDate
}

export const hasNoteBeenOpenedByUser = (note: Note, userId: UserId) => {
	const collaborator = getCollaborator(note, userId)
	return collaborator && collaborator.noticedOn !== null
}

export const isNoteOverdue = (note: Note) => {
	if (!note.deadline) return false
	const date = new Date(note.deadline)
	return !isToday(date) && isPast(date)
}

export const getNoteLists = (notes: Note[], userId: UserId): NoteList => {
	return {
		relevant: [
			...sort(
				notes.filter((note) => isUserCollaborator(note, userId) && !isNoteDone(note)),
				'deadline',
				'asc',
			),
			...sort(
				notes.filter((note) => !hasCollaborators(note) && !isNoteDone(note)),
				'contentDate',
				'desc',
			),
		],
		others: sort(
			notes.filter((note) => hasCollaborators(note) && !isUserCollaborator(note, userId) && !isNoteDone(note)),
			'contentDate',
			'desc',
		),
		done: sort(notes.filter(isNoteDone), 'doneOn', 'desc'),
	}
}

export enum NoteStatus {
	default = 'default',
	new = 'new',
	updated = 'updated',
}
export const getNoteStatus = (note: Note, userId: UserId): NoteStatus => {
	// todo: new notes without collaborator should be marked as new,
	//  but this is currently impossible to identify data-wise
	if (!isUserCollaborator(note, userId)) {
		return NoteStatus.default
	}
	if (!hasNoteBeenOpenedByUser(note, userId)) {
		return NoteStatus.new
	}
	if (isUserCollaboratorAndBehind(note, userId)) {
		return NoteStatus.updated
	}
	return NoteStatus.default
}
