
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import formatDate, { FORMAT_NUMBERS } from '~/app/common/filters/Date'

	@Component({
		components: { BasicDialog },
	})
	export default class SfDate extends Vue {
		@Model('changed', { default: '' }) value: string
		@Prop({ default: null }) rules: string
		@Prop({ default: null }) label: string
		@Prop({ default: null }) description: string
		@Prop({ type: Boolean, default: false }) required: false
		@Prop({ default: null }) validatorPrefix: string
		@Prop({ default: null }) placeholder: string
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: null }) prependIcon: string
		@Prop({ default: null }) min: string
		@Prop({ default: null }) max: string
		@Prop({ default: true }) clearable: boolean
		@Prop({ default: false }) arrows: boolean

		Image = Image

		isMenuOpen = false
		// _supressNextBlur = false

		localValue: string = null
		@Watch('value', { immediate: true })
		valueUpdated() {
			this.localValue = this.value
		}

		@Watch('localValue', { immediate: true })
		localValueUpdated() {
			this.$emit('changed', this.localValue)
		}

		@Watch('isMenuOpen', { immediate: false })
		closeDialog() {
			if (!this.isMenuOpen) (this.$refs.textfield as any).focus()
		}

		get rLabel() {
			return `${this.label || ''}${this.required ? '*' : ''}`
		}

		/**
		 * focus functionality
		 */
		focus() {
			;(this.$refs.textfield as any).focus()
			this.isMenuOpen = true
		}

		/**
		 * Increment / decrement day
		 */
		dayFormat = 'yyyy-MM-dd'
		get canIncrement() {
			if (this.max) {
				const cDate = this.$dateFns.parse(this.localValue, this.dayFormat, new Date())
				const maxDate = this.$dateFns.parse(this.max, this.dayFormat, new Date())
				if (this.localValue === this.max || this.$dateFns.isAfter(cDate, maxDate)) return false
			}
			return true
		}

		incrementDay() {
			const cDate = this.$dateFns.parse(this.localValue, this.dayFormat, new Date())
			// TODO:check this
			this.localValue = this.$dateFns.format(
				this.$dateFns.addDays(cDate, 1),
				this.dayFormat,
				// @ts-ignore
				new Date(),
			)
		}

		get canDecrement() {
			if (this.min) {
				const cDate = this.$dateFns.parse(this.localValue, this.dayFormat, new Date())
				const minDate = this.$dateFns.parse(this.min, this.dayFormat, new Date())
				if (this.localValue === this.min || this.$dateFns.isBefore(cDate, minDate)) return false
			}
			return true
		}

		decrementDay() {
			const cDate = this.$dateFns.parse(this.localValue, this.dayFormat, new Date())
			// TODO:check this
			this.localValue = this.$dateFns.format(
				this.$dateFns.addDays(cDate, -1),
				this.dayFormat,
				// @ts-ignore
				new Date(),
			)
		}

		get rValue() {
			return formatDate(this.$i18n, this.localValue, FORMAT_NUMBERS)
		}
	}
