
	import Image from '~/app/utils/globals/Image'
	import { ScopeType } from '@shared/types'
	import { Component, Model, Prop, Vue } from 'nuxt-property-decorator'
	import { countAttachments } from '~/app/domain/assets/Attachments'
	import { Event } from '~/app/domain/content/Event'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import AddressDetail from '~/components/domain/address/AddressDetail.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import DateRange from '~/components/domain/date/DateRange.vue'
	import EventActions from '~/components/domain/event/EventActions.vue'
	import EventEdit from '~/components/domain/event/EventEdit.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'
	import RichText from '~/components/utils/RichText.vue'

	@Component({
		components: {
			EventEdit,
			RichText,
			DetailCard,
			EventActions,
			AddressDetail,
			DateRange,
			Attachments,
			CategoryContainer,
		},
	})
	export default class EventDetail extends Vue {
		@Model('change', { required: true }) event: Event
		@Prop({ default: false }) editable: boolean
		@Prop({ default: null }) baseUrl: string
		@Prop({ default: false }) standalone: boolean
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: Array, default: () => [] }) nodes: Node[]

		Image = Image
		ScopeType = ScopeType

		get isPast() {
			if (this.event)
				return this.$dateFns.isPast(
					// @ts-ignore
					this.$dateFns.parse(this.event.fromDate, 'yyyy-MM-dd'),
				)
		}

		get attachmentsCount() {
			if (this.event?.attachments) return countAttachments(this.event.attachments)
		}

		/**
		 * Pages
		 */
		get qPage() {
			return this.$route.query.contentPage
		}

		setPage(page) {
			this.$router.push({
				query: {
					...this.$route.query,
					contentPage: page,
				},
			})
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		eventUpdated(event) {
			console.log('here', event)
			this.$emit('eventUpdated', event)
			this.$nuxt.$emit('eventUpdated', event)
		}

		eventDeleted() {
			this.$emit('deleted')
			this.$nuxt.$emit('eventDeleted', this.event)
		}

		get parent() {
			return {
				text: this.event?.title,
				to: { query: { ...this.$route.query, contentPage: undefined } },
			}
		}

		get processParent() {
			if (this.event?.process && this.$store.getters['routing/isBackoffice'])
				return {
					text: this.event.process?.title,
					to: {
						query: {
							...this.$route.query,
							contentPage: undefined,
							contentType: 'process',
							contentId: this.event.process.id,
						},
					},
				}
		}
	}
