
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Prop, Vue, Model, Watch } from 'nuxt-property-decorator'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import sort from '~/app/common/filters/iterator/Sort'
	import {
		ContactPerson,
		contactPersonTitle,
		isProviderActive,
		Provider,
		ProviderId,
		providerSearchKeys,
	} from '~/app/domain/content/ContactPerson'
	import MultiBtn from '~/components/inputs/MultiBtn.vue'

	@Component({
		methods: { contactPersonTitle },
		components: {
			MultiBtn,
			ProviderListItem,
			BasicDialog,
			SearchLayout,
		},
	})
	export default class ProviderPicker extends Vue {
		// keep the visibility synced
		@Model('change', { type: Boolean }) visible: boolean
		@Prop({ default: null }) title: string
		@Prop({ type: Array, default: () => [] }) selected
		@Prop({ type: Boolean, default: false }) multiple: boolean
		@Prop({ type: Boolean, default: false }) onlyActiveProviders: boolean
		@Prop({ type: Boolean, default: false }) dontLoad: boolean
		@Prop({ type: Object, default: null }) providersResponse

		async mounted() {
			if (this.dontLoad) return
			this.loading = true
			try {
				const response = await this.$axios.$get('etg24:internal:query:providers')
				this.loadFromResponse(response)
			} catch (e) {
				Utils.handleError(e)
			}
			this.loading = false
		}

		loadFromResponse(response) {
			this.providers = response.providers
			this.contactPersons = sort(
				response.contactPersons.map((cp) => {
					const filteredProviders = this.providers.filter((p) => p.contactPersonId === cp.id)
					return {
						...cp,
						name: contactPersonTitle(cp, filteredProviders),
						providers: sort(filteredProviders, ['company', 'lastName']),
					}
				}),
				['name'],
			)
		}

		@Watch('providersResponse', { immediate: true })
		providersResponseSet() {
			if (this.providersResponse) this.loadFromResponse(this.providersResponse)
		}

		loading: boolean = false
		localVisible = false
		providers: Provider[] = []
		contactPersons: any[] = []

		// the contact persons with providers that can be selected by the user
		get relevantContactPersons() {
			return this.contactPersons
				.map((contactPerson) => ({
					...contactPerson,
					providers: this.onlyActiveProviders
						? contactPerson.providers.filter(isProviderActive)
						: contactPerson.providers,
				}))
				.filter((contactPerson) => contactPerson.providers.length)
		}

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
			if (this.localVisible && this.selected.length) {
				this.selectedProviders = [...this.selected]
			}
		}

		/**
		 * Company filtering
		 */
		searchText: string = ''

		get filteredPersons() {
			let filtered = this.relevantContactPersons
			if (this.searchText) {
				filtered =
					filtered
						.map((cp) => {
							return {
								...cp,
								providers: search(cp.providers, this.searchText, {
									fuzzy: true,
									sort: true,
									keys: providerSearchKeys,
								}),
							}
						})
						.filter((p) => p.providers.length > 0) || []
			}
			return filtered
		}

		selectedProviders: ProviderId[] = []
		selectProvider(provider) {
			if (!this.multiple) {
				this.$emit('selected', [provider])
				return
			}
			if (!this.selectedProviders.includes(provider.id)) {
				this.selectedProviders.push(provider.id)
			} else {
				this.selectedProviders.splice(this.selectedProviders.indexOf(provider.id), 1)
			}
		}

		sendSelected() {
			this.$emit(
				'selected',
				this.providers.filter((p) => this.selectedProviders.includes(p.id)),
			)
			this.$emit('selectedIds', this.selectedProviders)
			this.selectedProviders = []
			this.localVisible = false
		}

		/**
		 * Controls
		 */
		selectAll() {
			const result = []
			for (const cp of this.relevantContactPersons)
				for (const p of cp.providers) {
					result.push(p.id)
				}
			this.selectedProviders = result
		}

		deselectAll() {
			this.selectedProviders = []
		}

		getTitle(contact: ContactPerson) {
			return contactPersonTitle(contact)
		}
	}
