
	import Vue from 'vue'
	import { Component, Model, Prop } from 'nuxt-property-decorator'
	import { Button } from '~/app/common/types/Button'

	@Component({
		components: {},
	})
	export default class MultiBtn extends Vue {
		@Prop({ type: Array, required: true }) btns: Button[]
		@Prop({ type: Number, default: 36 }) height: number

		get text() {
			return this.$slots.default.length && this.$slots.default[0].text
		}
	}
