
	import { Component, Vue, Model, Watch, Prop } from 'nuxt-property-decorator'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'

	@Component({
		components: { BasicDialog },
	})
	export default class SfTime extends Vue {
		@Model('changed', { default: '' }) value: string
		@Prop({ default: null }) rules: string
		@Prop({ default: null }) label: string
		@Prop({ default: null }) description: string
		@Prop({ type: Boolean, default: false }) required: false
		@Prop({ default: null }) validatorPrefix: string
		@Prop({ type: Boolean, default: false }) disabled: boolean
		@Prop({ default: null }) min: string
		@Prop({ default: null }) max: string
		@Prop({ default: undefined }) allowedMinutes: any[]
		@Prop({ default: true }) clearable: boolean

		localValue = null

		@Watch('value', { immediate: true })
		updateValue() {
			this.localValue = this.value
		}

		@Watch('localValue')
		updateLocalValue() {
			this.$emit('changed', this.localValue)
		}

		isMenuOpen = false
		@Watch('isMenuOpen', { immediate: false })
		closeDialog() {
			if (!this.isMenuOpen) (this.$refs.textfield as any).focus()
		}

		get rLabel() {
			if (!this.label) return null
			return `${this.label}${this.required ? '*' : ''}`
		}
	}
