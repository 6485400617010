
	export default {
		functional: true,

		props: {
			// Tells if the visual should be a label
			label: {
				default: false,
				type: Boolean,
			},
			spaced: {
				default: false,
				type: Boolean,
			},
			condensed: {
				default: false,
				type: Boolean,
			},
			multiline: {
				default: false,
				type: Boolean,
			},
			icon: {
				default: false,
				type: Boolean,
			},
			clickable: {
				default: false,
				type: Boolean,
			},
			selectable: {
				default: false,
				type: Boolean,
			},
			selected: {
				default: false,
				type: Boolean,
			},
			divided: {
				default: false,
				type: Boolean,
			},
			hover: {
				default: false,
				type: Boolean,
			},
			consistentControls: {
				default: false,
				type: Boolean,
			},
			card: {
				default: false,
				type: Boolean,
			},
			// An optional color variant whenever this item should be highlighted: boolean|string
			highlight: {
				default: null,
				type: String,
			},
			// A target if the content should be a router link
			to: {
				default: null,
				type: Object,
			},
			// A target if the content should be a normal link
			href: {
				default: null,
				type: String,
			},
			key: {
				default: null,
				type: String,
			},
		},

		methods: {
			isMultiline($slots, props) {
				return (
					props.card ||
					!!$slots.default ||
					[!!$slots.title, !!$slots.text, !!$slots.context].reduce((cur, next) => cur + (next ? 1 : 0), 0) > 1
				)
			},
			getContentComponentTagName({ to, href, label }) {
				if (label) {
					return 'label'
				} else if (to) {
					return 'router-link'
				} else if (href) {
					return 'a'
				}
				return 'div'
			},
			getContentComponentProps({ to, href }, { attrs }) {
				if (to) {
					return { to, ...attrs }
				} else if (href) {
					return { href, ...attrs }
				}
				return {}
			},
		},
	}
