import { AxiosResponse } from 'axios'
import { Fraction, ShortString, FileExtension, FileSize } from '../Types'
import { MatchResult } from '~/app/utils/libs/StringMatcher'
import generateRandomString from '~/app/utils/generateRandomString'
import { getFileName } from '~/app/utils/libs/FileUtil'

export enum FileUploadQueueState {
	QUEUED, // File is waiting to be uploaded.
	UPLOADING, // File is currently being sent to the server.
	FINISHED, // File has successfully finished uploading.
	INVALID, // File is invalid and will not be sent to the server; `description` holds human-readable information.
	ABORTED, // File upload has been aborted by the user.
	FAILED, // File could not be sent to the server.
}

export enum FileParsingState {
	UNREAD, // File is waiting to be read.
	READING, // File is currently being read.
	READ, // File has successfully been read.
	UNREADABLE, // File is not readable.
	ERROR, // File reading produced an error.
}

export interface FileInUploadQueue {
	id: string // Unique id used by v-for/key; not related to anything.
	file: File // The raw File object as received from the browser.
	formdata: object | string // Optional data to be sent to the server along with the file.
	progress: Fraction // The upload progress of this file.
	state: FileUploadQueueState // The current state of this file.
	parsingState: FileParsingState // The current state of reading the file contents
	title: ShortString // The title to display in the upload list.
	description: ShortString | ShortString[] // The description to display in the upload list.
	manual?: boolean
}

export interface FileUploadResponse extends AxiosResponse {
	queued: FileInUploadQueue
}

export interface MatcherFileInUploadQueue extends FileInUploadQueue {
	match: MatchResult | boolean | null // null = still matching, false = no match, true = user override
}

export const FileUploadSettings = {
	// The maximum allowed file size for user uploads
	allowFileSize: (25 * 1024 * 1024) as FileSize,

	// Globally allowed file extensions
	allowFileTypes: [
		// Allow any file type that isn't blacklisted
	] as FileExtension[],

	// Allowed file extensions for Images
	allowImageFileTypes: ['png', 'jpg', 'jpeg', 'gif'] as FileExtension[],

	// Allowed file extensions for ContactPersons
	allowContactFileTypes: ['msg', 'vcard', 'vcf'] as FileExtension[],

	// Globally denied file extensions
	denyFileTypes: ['bash', 'bat', 'cmd', 'com', 'db', 'dmg', 'exe', 'lnk', 'run', 'sh', 'url'] as FileExtension[],
}

export function fileToFileUpload(file): FileInUploadQueue {
	return {
		id: generateRandomString(8),
		file,
		formdata: {},
		progress: 0,
		state: FileUploadQueueState.QUEUED,
		parsingState: FileParsingState.UNREAD,
		title: getFileName(file),
		description: '',
	}
}
