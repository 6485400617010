
	import { Pie } from 'vue-chartjs'
	import { Component, Prop, Vue } from 'nuxt-property-decorator'
	@Component({ extends: Pie })
	export default class PieChart extends Vue {
		@Prop({ required: true }) data
		@Prop({ default: {} }) options

		mounted() {
			this.renderChart(this.data, this.options)
		}
	}
