
	import Vue from 'vue'
	import { Component, Model, Prop } from 'nuxt-property-decorator'
	import { Button } from '~/app/common/types/Button'
	import MaybeTooltip from '~/components/utils/MaybeTooltip.vue'

	@Component({
		components: {
			MaybeTooltip,
		},
	})
	export default class ActionButton extends Vue {
		@Model('changed') btn: Button
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: false }) dashed: boolean

		/**
		 * Action exec + loading state
		 */
		loading = false
		async exec() {
			this.$emit('click')
			if (!this.btn.callback) return
			this.loading = true
			await this.btn?.callback?.()
			this.loading = false
		}

		get hasText() {
			return !!(this.btn.text || this.$slots.text)
		}
	}
