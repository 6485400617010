
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { FileAsset } from '~/app/domain/assets/File'
	import FilePreview from '~/components/domain/file/FilePreview.vue'
	import { resolveApiLink } from '~/app/utils/apiLinks'

	interface FileConfig {
		file: FileAsset
		endpoint: string
		queryParameters: { [key: string]: string | boolean | number }
	}

	@Component({
		components: {
			FilePreview,
		},
	})
	export default class FileList extends Vue {
		Image = Image
		@Prop({ required: true }) elements: FileConfig[]
		@Prop({ type: Boolean, default: false }) deleteable: boolean

		getDownloadLink(element: FileConfig) {
			if (this.deleteable || !this.$route.params.subdomain) return null
			return resolveApiLink(this.$store, element.endpoint, element.queryParameters)
		}

		openFile(file: FileAsset) {
			this.$router.push({
				query: { ...this.$route.query, file: file.id },
			})
		}
	}
