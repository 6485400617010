import { AxiosError } from 'axios'
import formatDate, {
	dateFormattingPrecision,
	dateFormattingStrategy,
	DateValue,
	FORMAT_DEFAULT,
	PRECISION_DEFAULT,
} from '~/app/common/filters/Date'
import { DayString } from '~/app/domain/Types'
import Messages from './Messages'
import { getSubdomainInfo } from '~/app/utils/libs/Subdomain'

export interface IUtils {
	dateFormat
	withinDateRange
	sleepDiff(start: number, min: number): void
	sleep(ms: number): void
	handleError
	execCb(action: any): void
	isEmail(email: string): boolean
}

export interface handleError {
	(error: AxiosError): any
}

export interface dateFormat {
	(date: DateValue, format?: dateFormattingStrategy, precision?: dateFormattingPrecision): string
}

interface DateRangeConditions {
	fromDay?: DayString | null
	toDay?: DayString | null
}
export interface withinDateRange {
	(conditions: DateRangeConditions): boolean
}

export default {
	/**
	 * Format a date given the date and the desired format key
	 * @param date
	 * @param format
	 * @param precision
	 */
	dateFormat(
		date: DateValue,
		format: dateFormattingStrategy = FORMAT_DEFAULT,
		precision: dateFormattingPrecision = PRECISION_DEFAULT,
	): string {
		return formatDate($nuxt.$i18n, date, format, precision)
	},

	appPath: () => {
		// fetch the subdomain
		const subdomain = $nuxt.$route.params.subdomain
		if (!subdomain) return ''

		// fetch the domain
		const domain = $nuxt.$auth.user && $nuxt.$auth.user.domains && $nuxt.$auth.user.domains[subdomain]

		// return the subdomain info
		const info = getSubdomainInfo.call($nuxt, domain, false)
		return info && info.to
	},

	/**
	 * Takes
	 * @param error
	 */
	async handleError(error: AxiosError | any) {
		console.error(error)
		// client received an error response (5xx, 4xx), show error snackbar
		const resData = error.response && error.response.data
		let message = null
		if (resData) {
			if (resData.message) {
				message = $nuxt.$t(resData.message, resData.messageData)
			}
		}
		await $nuxt.$store.dispatch(
			'snackbar/error',
			error.response ? message || Messages.format.call($nuxt, error) : $nuxt.$t('errors.offline'),
		)
	},
}
