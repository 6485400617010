
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Prop, Vue } from 'nuxt-property-decorator'
	import { Offer, OfferRequest, OfferRequestStatus, OfferStatus } from '~/app/domain/content/Process'
	import OfferDetails from '~/components/domain/process/offer/OfferDetails.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import formatDate from '~/app/common/filters/Date'

	@Component({
		components: {
			ProviderListItem,
			OfferDetails,
		},
	})
	export default class OfferCard extends Vue {
		@Prop({ required: true, type: Object }) offerRequest: OfferRequest
		@Prop({ required: true, type: Object }) offer: Offer
		@Prop({ required: true, type: Boolean }) processingAction: boolean

		Image = Image

		OfferStatus = OfferStatus
		formatDate = formatDate

		get isDisabled() {
			return this.offer.status === OfferStatus.pending
		}

		get statusData() {
			switch (this.offer.status) {
				case OfferStatus.pending: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.pending'),
						icon: 'fal fa-hourglass',
						iconColor: 'grey',
					}
				}
				case OfferStatus.rejected: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.rejected'),
						icon: 'fal fa-handshake-slash',
						// iconColor: 'grey',
					}
				}
				case OfferStatus.available: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.available'),
						icon: 'fal fa-hand-holding-usd',
						iconColor: 'success',
					}
				}
				case OfferStatus.accepted: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.accepted'),
						icon: 'far fa-check',
						iconColor: 'success',
					}
				}
				case OfferStatus.declined: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.declined'),
						icon: 'fal fa-handshake-slash',
						// iconColor: 'grey',
					}
				}
				case OfferStatus.expired: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.expired'),
						icon: 'fal fa-calendar-exclamation',
						// iconColor: 'grey',
					}
				}
				case OfferStatus.waiting: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.waiting'),
						icon: 'fal fa-eye-slash',
						// iconColor: 'grey',
					}
				}
				case OfferStatus.withdrawn: {
					return {
						statusText: this.$t('domain.process.offerRequest.offer.status.withdrawn'),
						icon: 'fal fa-handshake-slash',
						// iconColor: 'grey',
					}
				}
				default: {
					return {
						statusText: this.$t('layout.errorOccurred'),
						icon: 'fal fa-exclamation-triangle',
						iconColor: 'error',
					}
				}
			}
		}

		get canAcceptOffer() {
			return (
				this.offerRequest.requestStatus === OfferRequestStatus.active &&
				[OfferStatus.available, OfferStatus.declined].includes(this.offer.status)
			)
		}

		get canRejectOffer() {
			return (
				this.offerRequest.requestStatus === OfferRequestStatus.active &&
				this.offer.status === OfferStatus.available
			)
		}

		/**
		 * Conversations
		 */
		creatingConversation: boolean = false
		async createConversation(offer) {
			try {
				this.creatingConversation = true
				const response = await this.$axios.$post('etg24:internal:command:offers.offer.createconversation', {
					offer: offer.id,
				})
				this.creatingConversation = false

				await this.goToConversation(response.conversation)

				// notify
				await this.$store.dispatch(
					'snackbar/success',
					this.$t('domain.conversation.actions.create.successMessage'),
				)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		async goToConversation(conversation) {
			await this.$router.push({
				query: {
					...this.$route.query,
					contentType: 'conversation',
					contentId: conversation.id,
					contentPage: undefined,
				},
			})
		}
	}
