
	import { Component, Prop, Vue } from 'nuxt-property-decorator'
	import { Offer, OfferStatus } from '~/app/domain/content/Process'
	import DateFt from '~/components/utils/Date.vue'
	import ReadMore from '~/components/utils/ReadMore.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'

	@Component({
		components: {
			Attachments,
			ReadMore,
			DateFt,
		},
	})
	export default class OfferDetails extends Vue {
		@Prop({ required: true, type: Object }) offer: Offer

		get shouldShow() {
			return !!this.offer.cost
		}

		get properties() {
			return [
				{
					text: this.$t('domain.process.offerRequest.offer.properties.cost.label'),
					value: this.offer.cost ? `${this.offer.cost} €` : null,
				},
				{
					text: this.$t('domain.process.offerRequest.offer.properties.offerNumber.label'),
					value: this.offer.offerNumber,
				},
				{
					text: this.$t('domain.process.offerRequest.offer.properties.expectedStart.label'),
					type: 'date',
					value: this.offer.expectedStart,
				},
				{
					text: this.$t('domain.process.offerRequest.offer.properties.expectedEnd.label'),
					type: 'date',
					value: this.offer.expectedEnd,
				},
				{
					text: this.$t('domain.process.offerRequest.offer.properties.validUntil.label'),
					type: 'date',
					value: this.offer.validUntil,
				},
			].filter((property) => !!property.value)
		}
	}
