
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { Document, documentToLinks } from '~/app/domain/content/Document'
	import { FileAsset } from '~/app/domain/assets/File'
	import Date from '~/components/utils/Date.vue'

	@Component({
		components: { Date },
	})
	export default class FileLine extends Vue {
		@Prop({ required: true }) file: FileAsset | Document
		@Prop({ default: true }) link: boolean

		get downloadLinks() {
			return documentToLinks(this.$route.params.subdomain, this.file, this.$store)
		}
	}
