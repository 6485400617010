
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Model, Watch, Vue } from 'nuxt-property-decorator'
	import NewsMenu from '../elements/NewsMenu.vue'
	import UserMenu from '~/components/navigation/elements/UserMenu.vue'
	import SubdomainSelect from '~/components/navigation/elements/SubdomainSelect.vue'
	import { getNavtree } from '~/app/utils/libs/Navtree'
	import { Button } from '~/app/common/types/Button'
	import UiBadge from '~/components/atoms/UiBadge.vue'

	@Component({
		components: { SubdomainSelect, UserMenu, NewsMenu, UiBadge },
	})
	export default class BoDrawer extends Vue {
		Utils = Utils
		@Model('change', { required: true }) visible: boolean

		localVisible = false

		@Watch('visible', { immediate: true })
		updateVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		updateLocalVisible() {
			if (this.visible !== this.localVisible) this.$emit('change', this.localVisible)
		}

		get tabs(): Button[] {
			const isProperty = this.$store.getters['routing/isProperty']
			return getNavtree.call(this, isProperty ? 'PropertyBoLayout' : 'ProviderBoLayout')
		}

		get appPath() {
			return Utils.appPath()
		}
	}
