var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.label)?_c(_setup.UiLabel,{attrs:{"label":_vm.label,"info-text":_vm.infoText}},[_vm._t("info-text")],2):_vm._e(),_vm._v(" "),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_setup.model),expression:"model"}],ref:"textArea",staticClass:"tw-flex tw-rounded tw-outline-none tw-font-normal tw-shadow-md tw-ring-1 tw-ring-background-stronger tw-appearance-none tw-resize-none tw-w-full tw-p-4 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 focus:tw-shadow-accent focus:tw-ring-2 focus:tw-ring-accent legacy:tw-bg-background-strong legacy:tw-shadow-none legacy:tw-ring-transparent",domProps:{"value":(_setup.model)},on:{"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')},"input":function($event){if($event.target.composing)return;_setup.model=$event.target.value}}},'textarea',{
			autocomplete: _vm.autocomplete,
			autofocus: _vm.autofocus,
			rows: _vm.rows,
			cols: _vm.cols,
			disabled: _vm.disabled,
			form: _vm.form,
			maxlength: _vm.maxlength,
			minlength: _vm.minlength,
			name: _vm.name,
			placeholder: _vm.placeholder,
			readonly: _vm.readonly,
			required: _vm.required,
			spellcheck: _vm.spellcheck,
		},false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }