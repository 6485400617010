import { scopedItemStoreActions, scopedItemStoreGetters, scopedItemStoreMutations } from '~/app/store/ScopedItemStore'
import { PropertyObjectId } from '~/app/domain/property/Object'
import { UnitGroup, UnitGroupId } from '~/app/domain/property/UnitGroup'

export const state = () => ({
	scopes: {},
	items: {},
	scopePropertyName: 'objectId',
})

export const getters = {
	...scopedItemStoreGetters({
		sortIteratee: 'title',
		lifetime: 30e3,
	}),

	byObject: (state, getters) => (objectId: PropertyObjectId) => getters.byProperty('objectId', objectId),
}

export const mutations = {
	...scopedItemStoreMutations({ sortIteratee: 'title' }),
}

export const actions = {
	...scopedItemStoreActions({}),

	async loadForObject(context, objectId: PropertyObjectId) {
		// don't reload if we're already reloading
		if (context.getters.isProcessing(objectId)) {
			return
		}
		// don't reload if we have clean data
		if (context.getters._isClean(objectId)) {
			return
		}

		// set scope processing
		context.commit('setScopeProcessing', objectId)

		const response = (
			await this.$axios.get('etg24:internal:query:propertymanagement.unitgroups', {
				params: { object: objectId },
			})
		).data
		await context.dispatch('setScopeWithItems', {
			key: objectId,
			items: response.unitGroups,
		})
	},

	async fetch(context, unitGroupId: UnitGroupId) {
		const response = (
			await this.$axios.get('etg24:internal:query:propertymanagement.unitgroup', {
				params: { unitGroup: unitGroupId },
			})
		).data
		context.commit('setItems', [response.unitGroup])
	},

	async create(context, unitGroup: UnitGroup) {
		const response = (
			await this.$axios.post('etg24:internal:command:propertymanagement.unitgroups.create', {
				unitGroup: unitGroup,
			})
		).data
		context.commit('setItems', [response.unitGroup])
		return response.unitGroup.id
	},

	async update(context, unitGroup: UnitGroup) {
		const response = (
			await this.$axios.post('etg24:internal:command:propertymanagement.unitgroups.update', {
				unitGroup: unitGroup,
			})
		).data
		context.commit('setItems', [response.unitGroup])
	},

	async delete(context, unitGroupId: UnitGroupId) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.unitgroups.delete', {
			unitGroup: unitGroupId,
		})
		context.commit('removeItems', [unitGroupId])
	},
}
