import { UrlString } from '../../../domain/Types'
import { Address } from '../../model/Address'
import AddressExtractFilter from '../Address'

export const GoogleMapsTypes = {
	map: 'm',
	hybrid: 'h',
	satellite: 'k',
	terrain: 'p',
}

export const GoogleMapsFormats = {
	map: 'q',
	local: 'l',
	directions: 'd',
}

type GoogleMapsType = keyof typeof GoogleMapsTypes
type GoogleMapsFormat = keyof typeof GoogleMapsFormats
type GoogleMapsZoom = number

/**
 * vue.js filter: returns a UrlString to Google Maps, showing the provided Address.
 */
export default function url(
	query: string | Address,
	type: GoogleMapsType = 'map',
	format: GoogleMapsFormat = 'map',
	zoom: GoogleMapsZoom = 15,
): UrlString {
	if (typeof query === 'object') {
		query = AddressExtractFilter(query)
	}

	if (type in GoogleMapsTypes) {
		type = GoogleMapsTypes[type] as any
	}

	if (format in GoogleMapsFormats) {
		format = GoogleMapsFormats[format] as any
	}

	return `https://maps.google.de/maps?ie=UTF8&hl=de&q=${query}&z=${zoom}&t=${type}&f=${format}`
}
