
	import { Component, Vue, Watch } from 'nuxt-property-decorator'
	import UpdateDetails from '~/components/sanity/UpdateDetails.vue'
	import UpdateIcon from '~/components/sanity/UpdateIcon.vue'
	import { NewUpdate } from '~/app/domain/sanity/common'
	import { getDateDifferenceString } from '~/app/utils/date'

	@Component({
		components: {
			UpdateDetails,
			UpdateIcon,
		},
		async fetch() {
			try {
				// we already sort the news when fetching them from sanity
				const newUpdates = (await this.$sanity.fetch(
					'*[_type == "newUpdate"] | order(publishedAt desc)',
				)) as NewUpdate[]
				this.news = [...newUpdates]
			} finally {
				this.loading = false
			}
		},
	})
	export default class NewsMenu extends Vue {
		news: NewUpdate[] = []
		loading = true
		menu = false
		selectedNewInitialPage = 1
		selectedNewModalVisible = false
		modalVisible = false

		get unreadNews() {
			if (!this.news?.length) {
				return []
			}
			const lastChecked = this.$store.getters['user/newsCheckedOn']
			if (!lastChecked) {
				return this.news
			}
			return this.news.filter((newsItem) => new Date(newsItem.publishedAt) > new Date(lastChecked))
		}

		get unreadForceOpenNews() {
			return this.unreadNews.filter((newsItem) => newsItem.forceOpen)
		}

		get hasUnreadNews() {
			return this.unreadNews?.length > 0
		}

		get hasUnreadForceOpenNews() {
			// ignore force open news in cypress, as they block die UI
			if (this.$store.getters['platform/isCypress']) {
				return false
			}
			return this.unreadForceOpenNews?.length > 0
		}

		getDateDifferenceString(date: string) {
			return getDateDifferenceString(date, this.$i18n.locale)
		}

		updateNewsCheckedDate() {
			this.$store.dispatch('user/newsChecked')
		}

		onModalClose() {
			this.updateNewsCheckedDate()
		}

		onClickNew(id: string) {
			this.selectedNewModalVisible = true
			this.selectedNewInitialPage = this.news.findIndex((n) => n._id === id) + 1
		}

		onSelectedNewModalClose() {
			this.selectedNewModalVisible = false
			this.selectedNewInitialPage = null
			this.updateNewsCheckedDate()
		}

		@Watch('menu') menuWatcher(curr: boolean, old: boolean) {
			if (!curr && old) this.updateNewsCheckedDate()
		}
	}
