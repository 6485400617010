import { Entity, EntityId } from '../common/Interfaces'
import { DateString, ShortString, Uuid } from '../Types'

// Content
export type ContentId = EntityId
export interface Content<T extends ContentId = ContentId> extends Entity<T> {
	nodeId: NodeId
	createdOn: DateString
	publishedOn: DateString | null
}
export type ContentType = keyof typeof ContentTypes
export enum ContentTypes {
	document = 'document',
	process = 'process',
	post = 'post',
	faq = 'faq',
	contactperson = 'contactperson',
	responsibility = 'responsibility',
	event = 'event',
}

// Node
export type NodeId = Uuid
export interface Node {
	id: NodeId
	type: NodeType
	title: ShortString
	parentId: NodeId | null
}

export enum NodeType {
	// PropertyManagement
	'propertymanagement' = 'propertymanagement',
	'propertymanagement.object' = 'propertymanagement.object',
	'propertymanagement.objectgroup' = 'propertymanagement.objectgroup',
	'propertymanagement.objectunit' = 'propertymanagement.objectunit',
	'propertymanagement.contract' = 'propertymanagement.contract',

	// Provider
	'provider' = 'provider',
	'provider.company' = 'provider.company',
	'provider.companygroup' = 'provider.companygroup',
	'provider.object' = 'provider.object',

	// Content
	'content.contactperson' = 'content.contactperson',
	'content.responsibility' = 'content.responsibility',
	'content.document' = 'content.document',
	'content.faq' = 'content.faq',
	'content.post' = 'content.post',
	'content.process' = 'content.process',
	'content.event' = 'content.event',
}

export const rootNodeTypes = [NodeType.propertymanagement, NodeType.provider]

// Type-guard function for NodeType
export function isNodeType(subject: any): subject is NodeType {
	return typeof subject === 'string' && subject in NodeType
}

// Type-guard function for Node
export function isNode(subject: any): subject is Node {
	return typeof subject === 'object' && typeof subject.id === 'string' && isNodeType(subject.type)
}

export function getNode(nodeId: NodeId, nodes: Node[]): Node {
	return nodes.find((node) => node.id === nodeId)
}

function getRootNode(nodeId: NodeId, nodes: Node[]): Node {
	let node = getNode(nodeId, nodes)
	while (node.parentId) {
		node = getNode(node.parentId, nodes)
	}
	return node
}

export function findClosestAncestorOfType(nodeId: NodeId, nodes: Node[], nodeType: NodeType) {
	return findClosestAncestorOfTypes(nodeId, nodes, [nodeType])
}

function findClosestAncestorOfTypes(nodeId: NodeId, nodes: Node[], nodeTypes: NodeType[]): Node | null {
	let node = getNode(nodeId, nodes)
	while (node) {
		if (nodeTypes.includes(node.type)) {
			return node
		} else {
			node = getNode(node.parentId, nodes)
		}
	}
	return null
}

// function getRootNode(nodeId: NodeId, store: any): Node {
// 	const node = store.getters['nodes/byId'](nodeId) as Node
//
// 	return rootNodeTypes.includes(node.type)
// 		? node
// 		: getRootNode(node.parentId, store)
// }

export function doNodesHaveTheSameRoot(nodeId1: NodeId, nodeId2: NodeId, store: any) {
	return getRootNode(nodeId1, store).id === getRootNode(nodeId2, store).id
}

export const getNodeBreadcrumbs = (entryPointNodeId: NodeId, targetNodeId: NodeId, nodes: Node[]): Node[] => {
	let breadcrumbs = []

	// if we're already on the desired level, we just return an empty array
	if (targetNodeId === entryPointNodeId) {
		return breadcrumbs
	}

	// if the entry point is not included in the nodes, we create breadcrumbs from the root node
	if (!nodes.some((node) => node.id === entryPointNodeId)) {
		return getNodeBreadcrumbs(getRootNode(targetNodeId, nodes).id, targetNodeId, nodes)
	}

	// now we'll walk up the node tree and add each node as a breadcrumb until we reach the entryPoint
	let node: Node = nodes.find((node) => node.id === targetNodeId)
	while (node.id !== entryPointNodeId) {
		breadcrumbs = [node, ...breadcrumbs]
		if (node.parentId) {
			// walk one step up the node tree
			node = nodes.find((n) => n.id === node.parentId)
		} else {
			// we reached a root node

			if (node.id === getRootNode(entryPointNodeId, nodes).id) {
				// if the two nodes share the same root node,
				// but the target node is not an ancestor of the entry point (because node.id !== entryPointNodeId), then...

				// ...if the target node is a root node, we return that root node as the only breadcrumb
				// if (breadcrumbs.length === 1) {
				// 	return breadcrumbs
				// }

				// ...if the target node is not a root node, then we create Breadcrumbs from the common root node to the targetNode
				return getNodeBreadcrumbs(node.id, targetNodeId, nodes)
			} else {
				// if they both come from a different root node,
				// we use the Breadcrumbs from the target's root node to the target node
				break
			}
		}
	}

	return breadcrumbs
}

export const getNodeBreadcrumbsStore = (entryPointNodeId: NodeId, targetNodeId: NodeId, store: any): Node[] => {
	let breadcrumbs = []

	// if we're already on the desired level, we just return an empty array
	if (targetNodeId === entryPointNodeId) {
		return breadcrumbs
	}

	// now we'll walk up the node tree and add each node as a breadcrumb until we reach the entryPoint
	let node: Node = store.getters['nodes/byId'](targetNodeId)
	while (node.id !== entryPointNodeId) {
		breadcrumbs = [node, ...breadcrumbs]
		if (node.parentId) {
			// walk one step up the node tree
			node = store.getters['nodes/byId'](node.parentId)
		} else {
			// we reached a root node

			if (node.id === getRootNode(entryPointNodeId, store).id) {
				// if the two nodes share the same root node,
				// but the target node is not an ancestor of the entry point (because node.id !== entryPointNodeId), then...

				// ...if the target node is a root node, we return that root node as the only breadcrumb
				// if (breadcrumbs.length === 1) {
				// 	return breadcrumbs
				// }

				// ...if the target node is not a root node, then we create Breadcrumbs from the common root node to the targetNode
				return getNodeBreadcrumbsStore(node.id, targetNodeId, store)
			} else {
				// if they both come from a different root node,
				// we use the Breadcrumbs from the target's root node to the target node
				break
			}
		}
	}

	return breadcrumbs
}

export const getPathFromRootNode = (targetNodeId: NodeId, nodes: Node[]): Node[] => {
	let ancestors = []

	// we'll walk up the node tree and add each node as a breadcrumb until we reach the entryPoint
	let node = getNode(targetNodeId, nodes)
	const rootNode = getRootNode(targetNodeId, nodes)
	while (node.id !== rootNode.id) {
		ancestors = [node, ...ancestors]
		if (node.parentId) {
			// walk one step up the node tree
			node = getNode(node.parentId, nodes)
		}
	}

	return ancestors
}
