
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import { DocumentId, documentSearchKeys } from '~/app/domain/content/Document'
	import DocumentList from '~/components/domain/document/DocumentList.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import { openEndpointInNewWindow } from '~/app/utils/apiLinks'

	@Component({
		components: { BasicDialog, DocumentList, SearchLayout },
	})
	export default class DocumentListStandalone extends Vue {
		@Prop({ required: true }) documents: Document[]
		@Prop({ required: true }) nodeId: string
		@Prop({ default: false, type: Boolean }) editable: boolean
		@Prop({ default: () => [], type: Array }) nodes: Node[]
		@Prop({ type: Boolean, default: false }) showLocation

		/**
		 * Search logic
		 */

		searchText = ''
		get filteredDocuments() {
			let result = this.documents
			// filter by search string
			if (this.searchText) {
				result = search(result, this.searchText, {
					fuzzy: true,
					// @ts-ignore
					keys: documentSearchKeys,
				})
			}
			return result
		}

		/**
		 * Selection
		 */
		selection: DocumentId[] = []

		/**
		 * Download functionality
		 */
		downloadDocuments(ids: DocumentId[]) {
			openEndpointInNewWindow(this.$store, 'etg24:internal:query:download.document.zip', { documents: ids })
		}

		/**
		 * Delete documents
		 */
		selectedDocuments: DocumentId[] = []
		deleteDialog = false
		deleting = false
		openDeleteDialog(documents) {
			this.selectedDocuments = documents
			this.deleteDialog = true
		}

		async deleteDocuments() {
			this.deleting = true
			try {
				await this.$axios.$post('etg24:internal:command:content.delete', {
					ids: this.selectedDocuments,
				})
				this.selection = []
				this.$emit('delete', this.selectedDocuments)
				this.deleteDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.deleting = false
		}
	}
