/**
 * Adapted from https://github.com/intlify/vue-i18n-composable/tree/master/src
 * This is temporary to allow us to use VueI18n 8.x with the Composition API and Vue 2
 * Once we upgrade to Vue 3 (which allows an upgrade to VueI18n 9.x), we can remove this
 * in favor of VueI18n's useI18n() hook
 */

import Vue from 'vue';
export function useI18n() {
  var instance = getCurrentInstance();
  var vm = (instance === null || instance === void 0 ? void 0 : instance.proxy) || instance || new Vue({});
  var i18n = vm.$i18n;
  if (!i18n) throw new Error('vue-i18n not initialized');
  var locale = computed({
    get: function get() {
      return i18n.locale;
    },
    set: function set(v) {
      i18n.locale = v;
    }
  });
  return {
    locale: locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm)
  };
}