export const state = () => ({
	override: null,
})

export const getters = {
	// high level access to the feature switches, including implicit features
	override: (state): string | null => {
		return state.override
	},
}

export const mutations = {
	setOverride: (state, override) => {
		state.override = override
	},
}

export const actions = {
	// this is used for testing purposes only
	setOverride: (context, override) => {
		context.commit('setOverride', override)
	},
}
