
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Faq } from '~/app/domain/content/Faq'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import InfoBtn from '~/components/domain/help/InfoBtn.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'

	@Component({
		components: {
			DetailLayout,
			UserNotifications,
			InfoBtn,
			RichTextEditor,
			CategoryContainer,
		},
		created() {
			this.important = !!this.newFaq
		},

		// components: { },
	})
	export default class FaqEdit extends Vue {
		@Model('change', { required: true }) faq: Faq
		@Prop({ default: false }) newFaq: boolean
		@Prop({ default: null }) nodeId: string
		@Prop({ type: Object, default: null }) parent: any
		Image = Image

		important = false

		sending = false
		async sendFaq() {
			// fill the payload
			const pl: any = {
				question: this.faq.question,
				answer: this.faq.answer,
				roleTypes: this.faq.roleTypes,
				isImportant: this.faq.isImportant,
			}
			if (this.newFaq) {
				pl.parentNode = this.nodeId
			} else {
				pl.id = this.faq.id
			}

			// submit it
			try {
				this.sending = true
				const response = (
					await this.$axios.$post(
						this.newFaq
							? 'etg24:internal:command:content.faqs.create'
							: 'etg24:internal:command:content.faqs.update',
						{
							faq: pl,
							important: this.important,
						},
					)
				).faq as Faq
				if (!this.newFaq) {
					this.$emit('updateFaq', response)
					this.$emit('changePage', null)
					this.$nuxt.$emit('faqUpdated', response)
				} else {
					await this.$router.push({
						query: {
							...this.$route.query,
							newContent: undefined,
							newContentId: undefined,
							contentType: 'faq',
							contentId: `${response?.id}`,
							contentPage: undefined,
						},
					})
					this.$nuxt.$emit('faqAdded', response)
				}
			} catch (e) {
				Utils.handleError(e)
				this.sending = false
			}
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 1),
					value: r,
				}
			})
		}

		get canSend() {
			return this.faq.question && this.faq.answer
		}
	}
