
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Node, ScopeType } from '@shared/types'
	import { Contact, ContactObjectData, contactTitle } from '~/app/domain/contact/Contact'
	import DetailCard from '~/components/utils/DetailCard.vue'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'
	import ContactInformation from '~/components/domain/contact/ContactInformation.vue'
	import ContactActions from '~/components/domain/contact/ContactActions.vue'
	import ContactEdit from '~/components/domain/contact/ContactEdit.vue'
	import SearchSplash from '~/components/utils/SearchSplash.vue'
	import CallRecordCard from '~/modules/call-record/components/CallRecordCard.vue'
	import { CallRecord } from '~/modules/call-record/core/model'
	import sort from '~/app/common/filters/iterator/Sort'
	import ConversationCard from '~/components/domain/conversation/ConversationCard.vue'
	import { Conversation, conversationSort } from '~/app/domain/communication/Conversation'
	import ContactRoles from '~/components/domain/contact/ContactRoles.vue'
	import { Process, processSort } from '~/app/domain/content/Process'
	import ProcessCard from '~/components/domain/process/ProcessCard.vue'
	import UiTooltip from '~/components/atoms/UiTooltip.vue'
	import UiButton from '~/components/atoms/UiButton.vue'
	import UiMenu from '~/components/atoms/UiMenu.vue'
	import UiTypography from '~/components/atoms/UiTypography.vue'
	import UiIcon from '~/components/atoms/UiIcon.vue'
	import UiChip from '~/components/atoms/UiChip.vue'
	import UiTabs from '~/components/atoms/UiTabs.vue'
	import BankAccountContactDetails from '~/modules/accounting/components/BankAccountContactDetails.vue'

	@Component({
		components: {
			ProcessCard,
			ContactRoles,
			ConversationCard,
			CallRecordCard,
			SearchSplash,
			ContactEdit,
			ContactActions,
			ContactInformation,
			UserAvatar,
			DetailCard,
			UiTooltip,
			UiButton,
			UiMenu,
			UiTypography,
			UiIcon,
			UiChip,
			UiTabs,
			BankAccountContactDetails,
		},
	})
	export default class ContactDetail extends Vue {
		@Model('change', { required: true }) contact: Contact
		@Prop({ default: false }) editable: boolean
		@Prop({ default: false }) loading: boolean
		@Prop({ type: Array, default: () => [] }) nodes: Node[]
		@Prop({ type: Object, default: () => ({}) }) objects: Record<string, ContactObjectData>

		ScopeType = ScopeType

		/**
		 * Global events
		 */
		updateContact(contact) {
			this.$emit('updateContact', contact)
			this.$nuxt.$emit('contactUpdated', contact)
		}

		deleteContact() {
			this.$emit('deleted')
			this.$nuxt.$emit('contactDeleted', this.contact)
		}

		/**
		 * Tabs
		 */

		get fullName() {
			return contactTitle(this.contact)
		}

		currentTab = 'info'

		get tabs() {
			return [
				{
					label: this.$t('layout.overview'),
					value: 'info',
					callback: () => {},
				},
				{
					label: this.$tc('domain.conversation.base', 2),
					value: 'conversations',
					callback: async () => {
						await this.loadConversations()
					},
				},
				...(this.$store.getters['features/isEnabled']('pm:callRecords')
					? [
							{
								label: this.$tc('domain.callRecord.base', 2),
								value: 'calls',
								callback: async () => {
									await this.loadCalls()
								},
							},
					  ]
					: []),
				{
					label: this.$tc('domain.process.base', 2),
					value: 'processes',
					callback: async () => {
						await this.loadProcesses()
					},
				},
			]
		}

		/**
		 * Pages
		 */
		get qPage() {
			return this.$route.query.contentPage
		}

		setPage(page) {
			this.$router.push({
				query: {
					...this.$route.query,
					contentPage: page,
				},
			})
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		get parent() {
			return {
				text: this.fullName,
				to: { query: { ...this.$route.query, contentPage: undefined } },
			}
		}

		get phoneNumbers() {
			return [...this.contact.phoneNumbers, ...(this.contact.user?.phoneNumbers ?? [])]
		}

		/**
		 * Call records
		 */
		loadingCalls = false
		records: CallRecord[] | null = null
		async loadCalls() {
			if (this.records) return
			this.loadingCalls = true
			try {
				const records = (
					await this.$axios.$get('etg24:internal:query:communication.callrecords.search', {
						params: {
							contact: this.contact.id,
						},
					})
				).callRecords
				this.records = sort(records, 'recordedOn', 'desc')
			} catch (e) {
				Utils.handleError(e)
			}
			this.loadingCalls = false
		}

		/**
		 * Conversations
		 */
		conversations: Conversation[] | null = null
		loadingConversations = false
		async loadConversations() {
			if (this.conversations) return
			this.loadingConversations = true
			try {
				this.conversations = conversationSort(
					(
						await this.$axios.$get('etg24:internal:query:communication.conversations', {
							params: {
								contact: this.contact.id,
							},
						})
					).conversations,
				)
			} catch (e) {
				Utils.handleError(e)
			}
			this.loadingConversations = false
		}

		/**
		 * Processes
		 */
		processes: Process[] | null = null
		processNodes: Node[] | null = null
		loadingProcesses = false

		async loadProcesses() {
			if (this.processes) return
			this.loadingProcesses = true
			try {
				const response = await this.$axios.$get('etg24:internal:query:content.processes', {
					params: {
						contact: this.contact.id,
					},
				})
				this.processes = processSort(response.processes)
				this.processNodes = response.nodes
			} catch (e) {
				Utils.handleError(e)
			}
			this.loadingProcesses = false
		}

		newCallrecord(number?: string) {
			this.$router.push({
				query: {
					...this.$route.query,
					callRecord: 'new',
					...(number ? { callRecordNumber: number } : {}),
					direction: 'outbound',
					contact: this.contact?.id,
					durationStart: 'false',
				},
			})
		}
	}
