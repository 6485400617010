
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Provider } from '~/app/domain/content/ContactPerson'

	@Component({})
	export default class ProviderLine extends Vue {
		@Model('changed', { required: true }) provider: Provider
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: false }) noItalic: boolean
		@Prop({ default: null }) tooltip: string

		Image = Image

		get hasTooltip() {
			return this.tooltip != null
		}
	}
