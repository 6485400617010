/**
 * - Sleep for a given amount of time
 * @param ms - The amount of time to sleep in milliseconds
 * @returns - A promise that resolves after the given amount of time
 * @complexity O(1)
 *
 * @example
 * ```ts
 * await sleep(1000)
 * console.log('This will be printed after 1 second')
 * ```
 */
export default function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}
