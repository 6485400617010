var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.UiTabs,{staticClass:"tw-mb-6 tw-sticky tw-top-0 tw-z-20 tw-bg-background-soft",attrs:{"tabs":_setup.tabs,"model-value":_setup.currentTab},on:{"update:modelValue":function($event){_setup.currentTab = $event}}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.currentTab === _setup.Tab.upload),expression:"currentTab === Tab.upload"}]},[_c(_setup.MatcherFileUpload,{ref:"fileUpload",attrs:{"batch":_setup.batch,"object-id":_vm.delivery.objectId,"targets":_vm.targets,"target-property-name-options":_vm.targetPropertyNameOptions,"upload-function":_setup.uploadFile,"accepted-files":".pdf","existing-files":(_setup.batch.files || []).map((f) => {
					return {
						...f,
						formdata: {
							node: f.contractId || null,
						},
					}
				}),"target-picker-options":{
				selectUnit: false,
				selectContract: true,
				hideTenants: _setup.batch.contractType === 'owner',
				hideOwners: _setup.batch.contractType === 'tenant',
				multipleContracts: true,
			}}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.currentTab === _setup.Tab.documents),expression:"currentTab === Tab.documents"}]},[_c(_setup.DeliveryBatchFileList,{attrs:{"delivery-aggregate-id":_vm.delivery.id,"object-id":_vm.delivery.objectId,"batch-files":_setup.batch.files,"delivery":_vm.delivery,"targets":_vm.targets,"units":_setup.objectUnits,"deleteable":"","reassignable":"","show-unit":""},on:{"delete":function($event){return _setup.deleteFile($event)},"reassign":function($event){return _setup.startReassignFile($event)}}})],1),_vm._v(" "),_c(_setup.MatcherTargetPicker,{attrs:{"title":_vm.$t('domain.contract.selectContract'),"target-options":_setup.manualTargetOptions,"options":{
			hideOwners: _setup.batch.contractType === 'tenant',
			hideTenants: _setup.batch.contractType === 'owner',
			selectContract: true,
			selectUnit: false,
			multipleContracts: true,
		},"can-abort":false,"object-id":_vm.delivery.objectId},on:{"selected":function($event){return _setup.reassignFile($event)}},model:{value:(_setup.nodeSelectDialog),callback:function ($$v) {_setup.nodeSelectDialog=$$v},expression:"nodeSelectDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }