import { Count, DateString, HtmlString, ShortString, Uuid } from '../Types'
import { Entity } from '../common/Interfaces'
import { MicroUser, UserId } from '../identifyAndAccess/User'
import { FileAsset, fileAssetSearchKeys, FileDownload } from '../assets/File'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import {
	Contract,
	ContractId,
	contractSearchKeys,
	ContractType,
	hasUsersWithOnlineAccess,
	isActiveContract,
	Signatory,
} from '../property/Contract'
import { Unit, UnitId, unitSearchKeys } from '../property/Unit'
import { ProviderObjectId as ObjectId } from '../provider/ProviderObject'
import { NodeId } from '../content/Common'
import sort from '~/app/common/filters/iterator/Sort'
import { countBy, uniq } from 'lodash'

/*
 * Model
 */

export type DeliveryDraftOrCollectionId = Uuid
export type DeliveryId = Uuid
export type DeliveryNoticeId = Uuid
export type DeliveryBatchId = Uuid
export type DeliveryBatchFileId = Uuid

// Contract

// BO published Delivery detail view, target contracts grouped by unit
export const unitWithDeliveriesSearchKeys = [
	...prefixSearchKeys('unit', unitSearchKeys),
	...prefixSearchKeys('contracts.contract', contractSearchKeys),
]

// Unit

// BatchFile
export interface DeliveryBatchFile extends Entity<DeliveryBatchFileId> {
	contractId: ContractId
	unitId: UnitId
	batchType: DeliveryFileType
	title: ShortString
	file: FileAsset
	contractCopyCreatedOn: DateString | null
}

export const deliveryBatchFileSearchKeys: (keyof DeliveryBatchFile)[] = [
	// todo: add contract search keys somewhere
	// todo: add unit search keys somewhere
	...(prefixSearchKeys('file', fileAssetSearchKeys) as (keyof DeliveryBatchFile)[]),
]

// Batch
export interface DeliveryBatch extends Entity<DeliveryBatchId> {
	entityType: DeliveryBatchEntityType
	batchType: DeliveryFileType
	title: ShortString
	deliveryId: DeliveryDraftOrCollectionId
	contractType: ContractType
	createContractCopy: boolean
	createOwnerContractCopy: boolean
}

export enum DeliveryBatchEntityType {
	PdfBatch = 'PdfBatch',
	SerialLetter = 'SerialLetter',
}
export interface PdfBatch extends DeliveryBatch {
	entityType: DeliveryBatchEntityType.PdfBatch
	files: DeliveryBatchFile[]
}
export interface SerialLetter extends DeliveryBatch {
	entityType: DeliveryBatchEntityType.SerialLetter
	subject: ShortString
	body: HtmlString
	contractIds: ContractId[]
	webAccessInfo: boolean
}

export function isPdfBatch(batch: DeliveryBatch | PdfBatch | SerialLetter): batch is PdfBatch {
	return batch.entityType === DeliveryBatchEntityType.PdfBatch
}

export function isSerialLetter(batch: DeliveryBatch | PdfBatch | SerialLetter): batch is SerialLetter {
	return batch.entityType === DeliveryBatchEntityType.SerialLetter
}

export enum DeliveryFileType {
	letter = 100,
	invitation = 200,
	voting = 230,
	authority = 250,
	bill_weg = 300,
	bill_single = 400,
	bill_heating = 500,
	bescheinigung35 = 550,
	budget = 600,
	extra_costs = 700,
	payments = 800,
}

export const deliveryBatchSearchKeys: (keyof DeliveryBatch)[] = [
	'title',
	...(prefixSearchKeys('files', deliveryBatchFileSearchKeys) as (keyof DeliveryBatch)[]),
]

// Notice
export interface DeliveryNotice extends Entity<DeliveryNoticeId> {
	deliveryId: DeliveryDraftOrCollectionId
	contractId: ContractId
	userId: UserId
	receivedOn: DateString
}

// Export
export interface DeliveryExport {
	// id: Uuid
	// deliveryId: DeliveryId
	contractIds: ContractId[]
	// isMerged: boolean
	exportedOn: DateString
	// exportedBy: User|null
}

// DeliveryListPartial
export interface DeliveryListView extends Entity<DeliveryDraftOrCollectionId> {
	nodeId: NodeId
	title: ShortString
	contractType: ContractType
	publishedOn: DateString | null
	batchTypes: DeliveryFileType[]
	batchTitles: ShortString[]
	attachmentsCount: number
	isArchived: boolean
}

// Delivery
export interface DeliveryDraft extends Entity<DeliveryDraftOrCollectionId> {
	title: ShortString
	message: HtmlString | null
	description: HtmlString
	objectId: ObjectId
	filesForEverybody: FileAsset[]
	batches: DeliveryBatch[]
}
export interface DeliveryCollection extends Entity<DeliveryDraftOrCollectionId> {
	objectId: ObjectId
	title: ShortString
	description: HtmlString
	message: HtmlString | null
	publishedOn: DateString | null
	publishedBy: MicroUser | null
	deliveries: Delivery[]
	filesForEverybody: FileAsset[]
	isArchived: boolean
}

export interface Delivery extends Entity<DeliveryId> {
	collectionId: DeliveryDraftOrCollectionId
	contractId: ContractId
	unitId: UnitId
	batchFiles: DeliveryBatchFile[]
	notices: DeliveryNotice[]
	publishedOn: DateString | null
	isDelivered: boolean
	isExported: boolean
}

export const deliveryBatchTypesByContractType = {
	[ContractType.owner]: [
		DeliveryFileType.letter,
		DeliveryFileType.invitation,
		DeliveryFileType.voting,
		DeliveryFileType.authority,
		DeliveryFileType.bill_weg,
		DeliveryFileType.bill_single,
		DeliveryFileType.bill_heating,
		DeliveryFileType.bescheinigung35,
		DeliveryFileType.budget,
		DeliveryFileType.extra_costs,
		DeliveryFileType.payments,
	],
	[ContractType.tenant]: [
		DeliveryFileType.letter,
		DeliveryFileType.bill_single,
		DeliveryFileType.bill_heating,
		DeliveryFileType.bescheinigung35,
		DeliveryFileType.budget,
		DeliveryFileType.payments,
	],
}

export const deliveryBatchTypesByMode = {
	[DeliveryBatchEntityType.SerialLetter]: [DeliveryFileType.letter, DeliveryFileType.invitation],
	[DeliveryBatchEntityType.PdfBatch]: [
		DeliveryFileType.letter,
		DeliveryFileType.invitation,
		DeliveryFileType.voting,
		DeliveryFileType.authority,
		DeliveryFileType.bill_weg,
		DeliveryFileType.bill_single,
		DeliveryFileType.bill_heating,
		DeliveryFileType.bescheinigung35,
		DeliveryFileType.budget,
		DeliveryFileType.extra_costs,
		DeliveryFileType.payments,
	],
}

export const ENTITY_TYPE_TO_ICON = {
	[DeliveryBatchEntityType.PdfBatch]: 'fal fa-folder',
	[DeliveryBatchEntityType.SerialLetter]: 'fal fa-typewriter',
}

export enum DeliveryStatus {
	DELIVERED = 'delivered', // The receiver has opened the delivery
	PENDING = 'pending', // The receiver is technically able to receive the delivery, but hasn't opened the delivery
	UNDELIVERABLE = 'undeliverable', // // the receiver is not able to receive the delivery
}

export enum PrintStatus {
	PRINTED = 'printed', // The delivery has already been printed
	PRINT_NEEDED = 'print_needed', // The delivery has not been printed, but needs to be printed
	UNPRINTED = 'unprinted', // The delivery has not been printed and doesn't need to be printed
}

export enum ProgressStatus {
	DONE = 'done', // Nothing needs to be done
	ACTION_REQUIRED = 'action_required', // The contract needs to be acted upon
	PENDING = 'pending', // The action might be done automatically
}

export enum ExportGroup {
	PRINTED = 'printed', // already printed
	MUST_PRINT = 'must_print', // must be printed
	OPENED = 'opened', // user has already opened
	UNOPENED = 'unopened', // user has not yet opened
}

// FrontOffice

export interface FrontOfficeDelivery extends Entity<DeliveryDraftOrCollectionId> {
	object: {
		id: ObjectId
		title: ShortString
	}
	title: ShortString
	description: string
	message: HtmlString | null
	publishedOn: DateString
	isReceived: boolean
	filesForEverybody: FileAsset[]
	batchFiles: DeliveryBatchFile[]
	units: Unit[]
	isArchived: boolean
}

export interface FrontOfficeDeliveryListView extends Entity<DeliveryDraftOrCollectionId> {
	object: {
		id: ObjectId
		title: ShortString
	}
	title: ShortString
	description: string
	publishedOn: DateString
	isReceived: boolean
	isArchived: boolean
}

/*
 * Validation
 */

/*
 * Search
 */

export const deliveryBoPartialSearchKeys: (keyof DeliveryListView)[] = ['title']
export const deliveryFoPartialSearchKeys: (keyof FrontOfficeDeliveryListView)[] = ['title']

/*
 * Api
 */

/*
 * Sorting
 */
export function deliveryFoPartialSort(deliveries: FrontOfficeDeliveryListView[]) {
	return sort(deliveries, 'publishedOn', 'desc')
}

/*
 * Functions
 */

export function doContractsGetUnevenCountOfFiles(deliveryBatch: DeliveryBatch) {
	return (
		uniq(
			// unique file counts
			Object.values(
				// list of file counts
				countBy(deliveryBatch.files, (batchFile) => batchFile.contractId), // object with contractId: file count
			),
		).length > 1
	)
}

export function getOmittedActiveContractsCount(deliveryBatch: DeliveryBatch, contracts: Contract[]) {
	const relevantContractsIds = contracts
		.filter((c) => isActiveContract(c) && c.contractType === deliveryBatch.contractType)
		.map((c) => c.id)
	const deliveredContractsIds = uniq(deliveryBatch.files.map((file) => file.contractId))
	return relevantContractsIds.filter((c) => !deliveredContractsIds.includes(c)).length
}

export interface DeliveryData {
	contract: Contract
	delivery: Delivery
	printStatus: PrintStatus
	deliveryStatus: DeliveryStatus
	progressStatus: ProgressStatus
	exportGroup: ExportGroup
	isActionRequired: boolean
	receivers: {
		signatory: Signatory
		hasReceived: boolean
		receivedDate: DateString | null
	}[]
}
export function getDeliveryData(delivery: Delivery, contract: Contract): DeliveryData {
	const printStatus = getContractPrintStatus(delivery, contract)
	const deliveryStatus = getDeliveryStatus(delivery, contract)
	const progressStatus = getDeliveryProgressStatus(printStatus, deliveryStatus)
	const isActionRequired = isActionForDeliveryRequired(delivery, contract)
	const exportGroup = getDeliveryExportGroup(printStatus, deliveryStatus, isActionRequired)
	return {
		delivery,
		contract,
		printStatus,
		deliveryStatus,
		progressStatus,
		exportGroup,
		isActionRequired,
		receivers: contract.signatories.map((signatory) => ({
			signatory,
			hasReceived: signatory.user?.id && hasUserReceivedDelivery(delivery, signatory.user.id),
			receivedDate: signatory.user?.id ? userReceivedDate(delivery, signatory.user.id) : null,
		})),
	}
}

// --- Contracts

// Returns list of unique Contracts from the provided Delivery.
export function extractUniqueContractIdsFromDelivery(subject: DeliveryDraft): ContractId[] {
	return uniq(extractBatchFilesFromDelivery(subject).map((dbf) => dbf.contractId))
}

export function extractUniqueUnitIdsFromDelivery(deliveryDraft: DeliveryCollection): UnitId[] {
	return uniq(deliveryDraft.deliveries?.map((delivery) => delivery.unitId))
}

export function getDeliveryStatus(delivery: Delivery, contract: Contract): DeliveryStatus {
	if (delivery.isDelivered) {
		return DeliveryStatus.DELIVERED
	}

	if (hasUsersWithOnlineAccess(contract)) {
		return DeliveryStatus.PENDING
	}

	return DeliveryStatus.UNDELIVERABLE
}

export function getContractPrintStatus(delivery: Delivery, contract: Contract): PrintStatus {
	if (delivery.isExported) {
		return PrintStatus.PRINTED
	} else if (
		contract.isPostal || // postal contracts always have to be printed // undelivered contracts must be printed if there are no online users
		(!delivery.isDelivered && !hasUsersWithOnlineAccess(contract))
	) {
		return PrintStatus.PRINT_NEEDED
	}

	return PrintStatus.UNPRINTED
}

export function getDeliveryProgressStatus(printStatus: PrintStatus, deliveryStatus: DeliveryStatus): ProgressStatus {
	switch (printStatus) {
		case PrintStatus.PRINTED:
			return ProgressStatus.DONE
		case PrintStatus.PRINT_NEEDED:
			return ProgressStatus.ACTION_REQUIRED
		case PrintStatus.UNPRINTED:
			switch (deliveryStatus) {
				case DeliveryStatus.DELIVERED:
					return ProgressStatus.DONE
				case DeliveryStatus.PENDING:
					return ProgressStatus.PENDING
				case DeliveryStatus.UNDELIVERABLE:
					return ProgressStatus.ACTION_REQUIRED
			}
	}
}

export function getDeliveryExportGroup(
	printStatus: PrintStatus,
	deliveryStatus: DeliveryStatus,
	isActionRequired: boolean,
): ExportGroup {
	if (printStatus === PrintStatus.PRINTED) {
		return ExportGroup.PRINTED
	}
	if (isActionRequired) {
		return ExportGroup.MUST_PRINT
	}
	if (deliveryStatus === DeliveryStatus.DELIVERED) {
		return ExportGroup.OPENED
	}
	return ExportGroup.UNOPENED
}

export function isActionForDeliveryRequired(delivery: Delivery, contract: Contract) {
	// Action is required if the contract is postal or undeliverable and is not already exported
	return (
		!delivery.isExported &&
		(contract.isPostal || getDeliveryStatus(delivery, contract) === DeliveryStatus.UNDELIVERABLE)
	)
}

// --- Receivers

// Tells whether or not the given UserId has received this the Delivery of a Contract
export function hasUserReceivedDelivery(delivery: Delivery, userId: UserId) {
	return !!delivery.notices.find((notice) => notice.userId === userId)
}

// Tells when the given UserId has received this Delivery.
export function userReceivedDate(delivery: Delivery, userId: UserId) {
	const notice = delivery.notices.find((notice: DeliveryNotice) => notice.userId === userId)

	return notice ? notice.receivedOn : null
}

// --- Files

// Returns list of unique DeliveryBatchFiles from the provided Delivery.
export function extractBatchFilesFromDelivery(delivery: DeliveryDraft): DeliveryBatchFile[] {
	return delivery?.batches.flatMap((batch) => batch.files) || []
}

// Returns count of Files in all Batches of the provided Delivery.
export function countBatchFilesOfDelivery(subject: DeliveryDraft): number {
	return extractBatchFilesFromDelivery(subject).length
}

// Returns all files that are targeted at the given contract
export function extractFilesForContract(
	delivery: DeliveryDraft,
	contractId: ContractId,
	withAttachments = true,
): FileAsset[] {
	return [
		...extractBatchFilesFromDelivery(delivery)
			.filter((batchFile: DeliveryBatchFile) => batchFile.contractId === contractId)
			.map((batchFile) => batchFile.file),
		...delivery.filesForEverybody.filter((file: FileAsset) => withAttachments),
	]
}

// Returns all batch files that are targeted at the given contract
export function extractBatchFilesForContract(delivery: DeliveryDraft, contractId: ContractId): DeliveryBatchFile[] {
	return [
		...extractBatchFilesFromDelivery(delivery).filter(
			(batchFile: DeliveryBatchFile) => batchFile.contractId === contractId,
		),
	]
}
