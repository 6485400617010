import { render, staticRenderFns } from "./NewModal.vue?vue&type=template&id=45ac0427&scoped=true"
import script from "./NewModal.vue?vue&type=script&lang=ts"
export * from "./NewModal.vue?vue&type=script&lang=ts"
import style0 from "./NewModal.vue?vue&type=style&index=0&id=45ac0427&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ac0427",
  null
  
)

export default component.exports