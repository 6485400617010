import { isString } from 'util'
import { parse, isValid, format } from 'date-fns'

// Tells if the value is empty
export const isEmpty = (value: any): boolean => {
	// empty array
	if (Array.isArray(value)) return !value.length

	// undefined / null
	if (value === undefined || value === null) {
		return true
	}

	if (value instanceof Date) {
		// valid date
		return isNaN(value.getTime())
	}

	// empty object
	if (typeof value === 'object') {
		for (const _ in value) return false
		return true
	}

	// empty string
	return !String(value).length
}

// Tells if the value complies to a value the is required
export const isNotEmpty = (value: any): boolean => !isEmpty(value)

// Tells if the value is a Number
export const isNumber = (value: any): boolean => isEmpty(value) || typeof value === 'number'

// Tells if the value is an Integer
export const isInteger = (value: any): boolean => isEmpty(value) || Number.isInteger(value)

// Tells if the Number has at most n digits behind the radix
export const hasMaxPrecision = (maximumPrecisionAllowed: number): Function => {
	return function (value: any): boolean {
		if (isEmpty(value)) return true
		const match = ('' + value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
		if (!match) {
			return false
		}
		return (
			Math.max(
				0,
				// Number of digits right of decimal point.
				(match[1] ? match[1].length : 0) -
					// Adjust for scientific notation.
					(match[2] ? +match[2] : 0),
			) <= maximumPrecisionAllowed
		) // compare
	}
}

// Tells if the Number has at most n digits before the radix
export const hasMaxDigits = (maximumDigitsAllowed: number): Function => {
	return (value: any): boolean => {
		if (isEmpty(value)) return true
		return (
			Math.floor(Math.log(Math.abs(value)) / Math.LN10 + 1) <= // number of digits
			maximumDigitsAllowed
		) // compare
	}
}

// Tells if the Number is between min and max
// Pass null to omit min or max, pass true for excluding to not include min and max as valid value
export const isBetween = (min: number | null, max: number | null, excluding: boolean = false): Function => {
	return (value: any): boolean => {
		if (isEmpty(value)) return true
		if (excluding === true) {
			if (min !== null && value <= min) return false
			if (max !== null && value >= max) return false
		} else {
			if (min !== null && value < min) return false
			if (max !== null && value > max) return false
		}
		return true
	}
}

// Tells if the Number is positive, allowing zero
// Combine with isNotZero if you want to exclude zero
export const isPositive = (value: any): boolean => isEmpty(value) || value >= 0

// Tells if the Number is negative, allowing zero
// Combine with notZero if you want to exclude zero
export const isNegative = (value: any): boolean => isEmpty(value) || value <= 0

// Tells if the Number is not equal to 0
export const isNotZero = (value: any): boolean => isEmpty(value) || value !== 0

// Tells if a string contains only valid filename characters
export const isFilename = (value: any): boolean => isEmpty(value) || /^[^<>:"\/\\|?*]+$/.test(value)

// Tells if the string has at most n characters
export const hasMaxCharacters = (maximumCharactersAllowed: number): Function => {
	return (value: any): boolean => {
		if (isEmpty(value)) return true
		return isString(value) && value.length <= maximumCharactersAllowed
	}
}

// Tells if the string has at least n characters
export const hasMinCharacters = (minimumCharactersAllowed: number): Function => {
	return (value: any): boolean => {
		if (isEmpty(value)) return true
		return isString(value) && value.length >= minimumCharactersAllowed
	}
}

// Tells if a value is a valid email address string
export const EmailRegex =
	/^[a-z\u00C0-\u00FF0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z\u00C0-\u00FF0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z\u00C0-\u00FF0-9](?:[a-z\u00C0-\u00FF0-9-]*[a-z\u00C0-\u00FF0-9])?\.)+[a-z\u00C0-\u00FF0-9](?:[a-z\u00C0-\u00FF0-9-]*[a-z\u00C0-\u00FF0-9])?$/i
export const isEmail = (value: any): boolean => {
	if (isEmpty(value)) return true
	if (typeof value !== 'string') return false
	return EmailRegex.test(value)
}

// Tells if a one-line CSV string contains only valid email addresses
export const isEmailCsv = (value: any, separator: string = ','): boolean => {
	if (isEmpty(value)) return true
	const emailArray = value.split(separator)
	for (let i = 0; i < emailArray.length; i++) {
		if (!isEmail(emailArray[i])) {
			return false
		}
	}
	return true
}

// Tells if a string has a valid phone number format
export const isPhoneNumber = (value: any): boolean => isEmpty(value) || /^\+[1-9]\d{1,14}$/.test(value)

// Tells if a string is a valid url, optionally with 'https' as protocol
export const isFullUrl = (value: any): boolean => isEmpty(value) || /^https?:\/\/[^\.]+\.[^\.]+/.test(value)

// Tells if a string is a valid etg24 subdomain
export const isSubdomain = (value: any): boolean =>
	isEmpty(value) || (/^[a-z0-9][a-z0-9äöüß-]{2,30}[a-z0-9]$/.test(value) && !value.includes('--'))

const date_format = 'yyyy-MM-dd'
// Tells if a string is a valid date
export const isDate = (value: any): boolean => isEmpty(value) || isValid(parse(value, date_format, new Date()))

const time_format = 'HH:mm'
// Tells if a string is a valid time
export const isTime = (value: any): boolean => isEmpty(value) || isValid(parse(value, time_format, new Date()))
