
	import { PropType } from '@nuxt/bridge/dist/runtime'
	import { h } from 'vue'
	import UiTooltip, { Size } from '~/components/atoms/UiTooltip.vue'

	const uuid = crypto.randomUUID()
	export default {
		props: {
			size: {
				type: String as PropType<Size>,
				default: 'md',
			},
			clickPrevent: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { slots }) {
			if (!slots.tooltip) {
				return () =>
					// This is a hack, to avoid issues with multiple components of the same type
					// and keys while using MaybeTooltip without tooltip
					slots.default?.({
						key: uuid,
					})
			} else {
				return () =>
					h(
						UiTooltip,
						{
							props,
							scopedSlots: {
								trigger: slots.default,
								default: slots.tooltip,
							},
						},
						[],
					)
			}
		},
	}
