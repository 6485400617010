
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { MicroUser } from '~/app/domain/identifyAndAccess/User'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'

	@Component({
		components: { UserAvatar },
	})
	export default class UserListItem extends Vue {
		@Model('changed', { required: true }) user: MicroUser
		@Prop({ default: true }) logoutButton: boolean
		@Prop({ default: null }) subtitle: string
		@Prop({ default: null }) to: string
		@Prop({ type: Boolean, default: false }) selected: boolean
		@Prop({ type: Boolean, default: false }) highlight: boolean
		@Prop({ type: Boolean, default: false }) disabled: boolean

		Image = Image

		get clickFn() {
			if (this.$listeners && this.$listeners.click != null) return true
			else return false
		}
	}
