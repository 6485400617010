import { DateString, EmailAddressString, HtmlString, ShortString, TimeString } from '../Types'
import { Content, ContentId, Node } from './Common'
import { RoleType } from '../identifyAndAccess/Role'
import { Attachments } from '../assets/Attachments'
import { Validators } from '../../common/validation/Validators'
import isAfter from 'date-fns/isAfter'
import { Conversation } from '~/app/domain/communication/Conversation'
import sort from '~/app/common/filters/iterator/Sort'
import { isBefore, max, sub } from 'date-fns'
import { ContractId } from '~/app/domain/property/Contract'

/*
 * Model
 */

export type PostId = ContentId

export interface Post extends Content<PostId> {
	title: ShortString
	description: HtmlString
	attachments: Attachments
	roleTypes: RoleType[]
	isArchived: boolean
	hasContractVisibility: boolean
	contractIds: ContractId[]
	contractSummary?: any

	publishDate: DateString // this is the date set by the editor, do not confuse with publishedOn which tells when it actually got published
	publishedOn: DateString | null // this is the date the post has been published

	shouldNotify: boolean
	additionalReceivers: EmailAddressString[]
	notifiedOn: DateString | null

	isListed: boolean
	pinnedUntil: DateString | null
}

/*
 * Validation
 */

export const postValidations = {
	roleTypes: {
		required: Validators.required,
	},
	title: {
		required: Validators.required,
	},
	publishDate: {},
	pinnedUntil: {},
	description: {},
}

export const vvPost = {
	title: 'required',
	description: 'required',
}

/*
 * Search
 */

export const postSearchKeys: (keyof Post)[] = ['title', 'description']

/*
 * Api
 */

export interface PostsQueryResponse {
	posts: Post[]
	nodes: Node[]
}

/*
 * Functions
 */
export function postSort(posts: Post[]) {
	return sort(posts, 'publishedOn', 'desc')
}
