
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import Md from '~/components/utils/Md.vue'

	@Component({ components: { Md } })
	export default class SearchSplash extends Vue {
		@Prop({ default: null }) title: string
		@Prop({ default: null }) body: string
		@Prop({ default: 'fal fa-search' }) icon: string

		get rTitle() {
			return this.title || this.$t('components.searchSplash.noResults.title')
		}

		get rBody() {
			return this.body || this.$t('components.searchSplash.noResults.body')
		}
	}
