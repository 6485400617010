
	import Image from '~/app/utils/globals/Image'
	import Messages from '~/app/utils/globals/Messages'
	import sleep from '~/app/utils/sleep'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Watch, Prop } from 'nuxt-property-decorator'
	import debounce from 'lodash/debounce'
	import { Conversation, getMessages, isGroupConversation } from '~/app/domain/communication/Conversation'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import DateFt from '~/components/utils/Date.vue'
	import ConversationActions from '~/components/domain/conversation/ConversationActions.vue'
	import ConversationParticipants from '~/components/domain/conversation/ConversationParticipants.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import { findClosestAncestorOfType, Node, NodeType } from '~/app/domain/content/Common'
	import RichText from '~/components/utils/RichText.vue'
	import ConversationScheduleBtn from '~/components/domain/conversation/ConversationScheduleBtn.vue'
	import sort from '~/app/common/filters/iterator/Sort'
	import CallRecordCard from '~/modules/call-record/components/CallRecordCard.vue'
	import { CallRecord } from '~/modules/call-record/core/model'
	import ConversationEdit from '~/components/domain/conversation/ConversationEdit.vue'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import { ScopeType } from '~/app/domain/notes/Note'
	import { Email } from '~/app/domain/content/InboundEmail'
	import EmailPreview from '~/components/domain/email/EmailPreview.vue'
	import Tooltip from '~/components/utils/Tooltip.vue'

	@Component({
		components: {
			EmailPreview,
			CategoryContainer,
			ConversationEdit,
			CallRecordCard,
			ConversationScheduleBtn,
			RichText,
			Attachments,
			FileList,
			ListSelector,
			DetailCard,
			ConversationParticipants,
			ConversationActions,
			DateFt,
			RichTextEditor,
			Dropzone,
			UserAvatar,
			Tooltip,
		},
	})
	export default class ConversationDetail extends Vue {
		@Model('change', { required: true }) conversation: Conversation
		@Prop({ default: false }) dark: boolean
		@Prop({ default: false }) backOffice: boolean
		@Prop({ default: () => [] }) nodes: Node[]
		@Prop({ type: Boolean, default: false }) loading: boolean

		Image = Image

		ScopeType = ScopeType

		localConversation: Conversation|null = null
		callRecords: CallRecord[]|null = null

		@Watch('conversation', { immediate: true })
		async updateExternalConversation() {
			if (
				!this.callRecords &&
				this.conversation &&
				this.backOffice &&
				this.$store.getters['features/isEnabled']('pm:callRecords')
			) {
				try {
					this.callRecords = (
						await this.$axios.$get('etg24:internal:query:communication.callrecords.search', {
							params: {
								conversation: this.conversation.id,
							},
						})
					).callRecords
				} catch (e) {
					Utils.handleError(e)
				}
			}

			this.localConversation = this.conversation

			if (this.localConversation) {
				this.setSendOnDatesFromState()
				if (this.conversation.draft) {
					this.newMessage = this.conversation.draft.text
					this.editMode = true
				}
			}
		}

		setSendOnDatesFromState() {
			if (!this.localConversation.draft || !this.localConversation.draft.sendOn) {
				this.sendOn = null
			} else {
				this.sendOn = this.conversation.draft.sendOn
			}
		}

		@Watch('localConversation') updateLocalConversation() {
			this.$emit('change', this.localConversation)
		}

		updateConversation(conversationPl) {
			this.localConversation = conversationPl.conversation
			this.$emit('updateNodes', conversationPl.nodes)
			this.$nuxt.$emit('conversationUpdated', this.localConversation)
		}

		get userImage() {
			return this.$auth.user.image
		}

		get objectId() {
			return (
				findClosestAncestorOfType(
					this.conversation.nodeId,
					this.nodes,
					NodeType['propertymanagement.object'] as any,
				)?.id || this.$route.params.object_id
			)
		}

		get messages() {
			if (!this.localConversation) return []
			let results = getMessages(this.localConversation)
				.map((msg) => {
					return {
						...msg,
						participant: this.localConversation?.participants.find((p) => p.id === msg.causedById),
					}
				})
				.map((msg) => {
					return {
						type: 'message',
						...msg,
						...this.getCardColor(msg),
						date: msg.occurredOn,
					}
				})
			if (this.callRecords?.length)
				results = results.concat(
					(this.callRecords as any).map((r) => {
						return {
							...r,
							type: 'callRecord',
							date: r.recordedOn,
						}
					}),
				)
			return sort(results, ['date'], ['asc']).reverse()
		}

		get scheduledMessages() {
			if (!this.localConversation?.scheduledMessages) return []
			return this.localConversation.scheduledMessages
				.map((msg) => {
					return {
						...msg,
						participant: this.localConversation.participants.find((p) => p.id === msg.participantId),
					}
				})
				.reverse()
		}

		// new message
		editMode = false
		newMessage = ''
		sending = false

		@Watch('editMode')
		updateEditMode() {
			if (this.editMode && (this.$refs as any).newMessage) {
				;(this.$refs as any).newMessage.focus()
			}
		}

		get canSend() {
			return this.newMessage && this.newMessage !== '<p></p>'
		}

		async sendMessage() {
			// init
			this.sending = true

			// build the form data
			const fb = new FormData()
			fb.append('conversation', this.conversation.id)
			fb.append('content', this.newMessage)
			try {
				const response = await this.$axios.$post('etg24:internal:command:communication.conversations.reply', fb)
				this.editMode = false
				;(this.$refs as any).newMessage.reset()
				this.newMessage = ''
				this.updateConversation(response)
				this.sendOn = null
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
			await sleep(1000)
			this.sending = false
		}

		get tenant() {
			const t = this.$store.state.tenant
			return t && t.tenant
		}

		startEdit() {
			if (!this.editMode) this.editMode = true
		}

		isCurrentUser(message) {
			return message.participant.user?.id === this.$auth.user.id
		}

		isFollowupMessage(message) {
			const mIndex = this.messages.indexOf(message)
			if (mIndex === 0) return false
			return this.messages[mIndex - 1].participant?.id === message.participant.id
		}

		/**
		 * Common actions
		 */
		sendingCopy = false
		async sendCopy() {
			this.sendingCopy = true
			try {
				await this.$axios.$post('etg24:internal:command:communication.conversations.sendcopypermail', {
					conversation: this.conversation.id,
				})

				// notify
				await this.$store.dispatch('snackbar/success', this.$t('domain.conversation.sendToEmailSuccess'))
			} catch (e) {
				Utils.handleError(e)
			}
			this.sendingCopy = false
		}

		get conversationStates() {
			return [
				{
					text: this.$t('domain.conversation.status.open'),
					icon: 'fal fa-comments',
					value: true,
				},
				{
					text: this.$t('domain.conversation.status.closed'),
					icon: 'fal fa-check-circle',
					value: false,
				},
			]
		}

		/**
		 * Participant management
		 */
		get isGroupConversation() {
			return isGroupConversation(this.conversation)
		}

		get showParticipants() {
			if (!this.backOffice && !this.isGroupConversation) return false
			if (this.localConversation?.participants?.length < 1) return false
			else if (this.localConversation?.participants?.length === 1) {
				if (this.localConversation.participants[0].user.id === this.$auth.user.id) return false
			}
			return true
		}

		async addUsers(newParticipants) {
			const newUsers =
				newParticipants.users?.map((p) => {
					return p.id || p
				}) || undefined
			if (newUsers.length === 0) return
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:communication.conversations.addusers',
					{
						conversation: this.conversation.id,
						users: newUsers,
					},
				)
				this.localConversation.participants = response.conversation.participants
			} catch (e) {
				Utils.handleError(e)
			}
		}

		async addProviders(newParticipants) {
			const newProviders =
				newParticipants.providers?.map((p) => {
					return p.id || p
				}) || undefined
			if (newProviders.length === 0) return
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:communication.conversations.addproviders',
					{
						conversation: this.conversation.id,
						providers: newProviders,
					},
				)
				this.localConversation.participants = response.conversation.participants
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Draft
		 */
		sendOn = null
		today = this.$dateFns.format(new Date(), 'yyyy-MM-dd')

		updateDraft = debounce(() => this.updateDraftInt.call(this), 1000)

		deletingDraft: boolean = false
		async deleteDraft() {
			this.deletingDraft = true
			try {
				this.newMessage = ''
				await this.$axios.$post('etg24:internal:command:communication.conversations.deletedraft', {
					conversation: this.conversation.id,
				})
			} catch (e) {
				Utils.handleError(e)
			}
			this.editMode = false
			this.deletingDraft = false
		}

		async updateDraftInt() {
			if (this.sending || !this.newMessage) return
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:communication.conversations.updatedraft',
					{
						conversation: this.conversation.id,
						content: this.newMessage,
						sendOn: this.sendOn,
					},
				)
				this.updateConversation(response)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		async uploadAttachment(file) {
			const formData = new FormData()
			formData.set('conversation', this.conversation.id)
			formData.append('attachment', file)
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:communication.conversations.uploadattachment',
					formData,
				)
				this.updateConversation(response)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		async deleteAttachment(file) {
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:communication.conversations.deleteattachment',
					{
						conversation: this.conversation.id,
						attachment: file.id,
					},
				)
				this.updateConversation(response)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		getCardColor(message) {
			if (this.isCurrentUser(message)) {
				return {
					headerColor: 'green lighten-4',
					cardColor: 'green lighten-5',
				}
			}
			if (message.participant.isTenantRepresentative) {
				return {
					headerColor: 'blue lighten-4',
					cardColor: 'blue lighten-5',
				}
			}
			if (message.participant.type === 'provider') {
				return {
					headerColor: 'brown lighten-4',
					cardColor: 'brown lighten-5',
				}
			}
			return {
				headerColor: 'grey lighten-2',
				cardColor: 'grey lighten-3',
			}
		}

		/**
		 * Pages
		 */
		get qPage() {
			return this.$route.query.contentPage
		}

		setPage(page) {
			this.$router.push({
				query: {
					...this.$route.query,
					contentPage: page,
				},
			})
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		get parent() {
			return {
				text: this.conversation?.title,
				to: { query: { ...this.$route.query, contentPage: undefined } },
			}
		}

		deleted() {
			this.$emit('deleted')
			this.$nuxt.$emit('conversationDeleted', this.localConversation)
		}

		get processParent() {
			if (this.localConversation?.process && this.backOffice)
				return {
					text: this.localConversation.process?.title,
					to: {
						query: {
							...this.$route.query,
							contentPage: undefined,
							contentType: 'process',
							contentId: this.localConversation.process.id,
						},
					},
				}
		}

		previewEmailDialog: boolean = false
		previewedEmail: Email | null = null
		previewEmail(email) {
			this.previewedEmail = email
			this.previewEmailDialog = true
		}
	}
