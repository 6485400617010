
	import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { Object as PropertyObject } from '~/app/domain/property/Object'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { prefixSearchKeys, search } from '~/app/common/filters/iterator/Search'
	import ObjectCard from '~/components/domain/object/ObjectCard.vue'
	import ObjectLine from '~/components/domain/object/ObjectLine.vue'
	import { UnitId, unitSearchKeys, UnitType } from '~/app/domain/property/Unit'
	import UnitCard from '~/components/domain/unit/UnitCard.vue'
	import { signatoryName } from '~/app/common/filters/domain/Signatory'
	import {
		ContractId,
		contractSearchKeys,
		ContractType,
		getRegisteredUsers,
		getUsers,
		isContractAddressable,
		sortContracts,
		isOwnerOccupied,
		Contract,
	} from '~/app/domain/property/Contract'
	import { addressToString } from '~/app/domain/common/Address'
	import ContractSignatoryChip from '~/components/domain/contract/signatory/ContractSignatoryChip.vue'
	import { UserId } from '~/app/domain/identifyAndAccess/User'
	import ContractDate from '~/components/domain/contract/ContractDate.vue'
	import MultiBtn from '~/components/inputs/MultiBtn.vue'
	import ListPaginator from '~/components/containers/ListPaginator.vue'
	import ContractIcons from '~/components/domain/contract/ContractIcons.vue'

	@Component({
		components: {
			ContractIcons,
			MultiBtn,
			ListPaginator,
			ContractDate,
			ContractSignatoryChip,
			UnitCard,
			ObjectLine,
			ObjectCard,
			SearchLayout,
		},
		filters: { signatoryName },
	})
	export default class UnitContractPicker extends Vue {
		// The Object
		@Prop({ required: true }) object: PropertyObject

		// Filter units by this string
		@Prop({ default: '' }) searchString: string
		@Prop({ type: Boolean, default: true }) header: boolean
		@Prop({ type: Boolean, default: false }) hideOwners: boolean
		@Prop({ type: Boolean, default: false }) hideTenants: boolean
		@Prop({ type: Boolean, default: false }) multipleContracts: boolean
		@Prop({ type: Array, default: () => [] }) multipleSelection: string[]
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: Boolean, default: false }) processing: boolean

		// listener overrides
		@Prop({ type: Boolean, default: null }) contractListener: boolean
		@Prop({ type: Boolean, default: null }) unitListener: boolean
		@Prop({ type: Boolean, default: null }) signatoryListener: boolean
		@Prop({ type: Boolean, default: null }) userListener: boolean

		@Prop({ type: String, default: null }) selectionMode: string
		@Prop({ type: Array, default: null }) selectedUnits: UnitId[]
		@Prop({ type: Array, default: null }) selectedUsers: UserId[]
		@Prop({ type: Array, default: null }) selectedContracts: ContractId[]
		@Prop({ type: Boolean, default: false }) showNotAddressable: boolean

		@Watch('selectedUnits', { immediate: true }) updateSelectedUnits() {
			this.selection.units = [...(this.selectedUnits || [])]
		}

		@Watch('selectedUsers', { immediate: true }) updateSelectedUsers() {
			this.selection.users = [...(this.selectedUsers || [])]
		}

		@Watch('selectedContracts', { immediate: true })
		updateSelectedContracts() {
			this.selection.contracts = [...(this.selectedContracts || [])]
		}

		addressToString = addressToString
		isContractAddressable = isContractAddressable
		/**
		 * Get the info for the selected object
		 */
		created() {
			// make sure all the data is there
			this.$store.dispatch('property/objectAddresses/loadForObject', this.object.id)
			this.$store.dispatch('property/units/loadForObject', this.object.id)
			this.$store.dispatch('property/unitGroups/loadForObject', this.object.id)
			this.$store.dispatch('property/contracts/loadForObject', this.object.id)
		}

		get units() {
			return this.$store.getters['property/units/byObject'](this.object.id)
		}

		get contracts() {
			return this.$store.getters['property/contracts/byObject'](this.object.id)
		}

		get objectAddresses() {
			return this.$store.getters['property/objectAddresses/byObject'](this.object.id).map((a) => {
				return {
					...a,
					name: addressToString(a.address),
				}
			})
		}

		/**
		 * Listener state
		 */
		get hasUnitListener() {
			if (this.unitListener != null) return this.unitListener
			return this.multipleSelection.includes('units') || (this.$listeners && this.$listeners.unitSelected != null)
		}

		get hasContractListener() {
			if (this.contractListener != null) return this.contractListener
			return (
				this.multipleSelection.includes('contracts') ||
				(this.$listeners && this.$listeners.contractSelected != null)
			)
		}

		get hasSignatoryListener() {
			if (this.signatoryListener != null) return this.signatoryListener

			return (
				this.multipleSelection.includes('signatories') ||
				(this.$listeners && this.$listeners.signatorySelected != null)
			)
		}

		get hasUserListener() {
			if (this.userListener != null) return this.userListener
			return this.multipleSelection.includes('users') || (this.$listeners && this.$listeners.userSelected != null)
		}

		/**
		 * Select function
		 */
		selection = {
			units: [],
			contracts: [],
			signatories: [],
			users: [],
		}

		selectItem({ signatory, contract, unit, user }) {
			if (this.hasSignatoryListener && signatory) {
				// signatories
				if (this.multipleSelection.includes('signatories')) {
					if (this.selection.signatories.includes(signatory.id)) {
						this.selection.signatories.splice(this.selection.signatories.indexOf(signatory.id), 1)
					} else this.selection.signatories.push(signatory.id)
					this.$emit('signatoriesSelected', this.selection.signatories)
				} else this.$emit('signatorySelected', signatory)
			} else if (this.hasContractListener && contract) {
				// contracts
				if (this.multipleSelection.includes('contracts')) {
					if (this.selection.contracts.includes(contract.id)) {
						this.selection.contracts.splice(this.selection.contracts.indexOf(contract.id), 1)
					} else this.selection.contracts.push(contract.id)
					this.$emit('contractsSelected', this.selection.contracts)
				} else this.$emit('contractSelected', contract)
			} else if (this.hasUserListener && user) {
				// users
				if (this.multipleSelection.includes('users')) {
					if (this.selection.users.includes(user.id)) {
						this.selection.users.splice(this.selection.users.indexOf(user.id), 1)
					} else this.selection.users.push(user.id)
					this.$emit('usersSelected', this.selection.users)
				} else this.$emit('userSelected', user)
			} else if (this.hasUnitListener && unit) {
				// units
				if (this.multipleSelection.includes('units')) {
					if (this.selection.units.includes(unit.id)) {
						this.selection.units.splice(this.selection.units.indexOf(unit.id), 1)
					} else this.selection.units.push(unit.id)
					this.$emit('unitsSelected', this.selection.units)
				} else this.$emit('unitSelected', unit)
			}
		}

		/**
		 * Search logic
		 */
		searchKeys = [
			// unit
			...prefixSearchKeys('unit', unitSearchKeys),

			// owner and tenant contract
			...prefixSearchKeys('contracts.tenant', contractSearchKeys),
			...prefixSearchKeys('contracts.owner', contractSearchKeys),
		] as (keyof UnitRow)[]

		changeRowsOnReload = false

		// the entries to show
		get rows(): UnitRow[] {
			// build a concatenated list of contracts
			const sContracts = sortContracts(this.contracts)
			const contracts = {} as { owner: Contract[]; tenant: Contract[] }
			for (const t of ['owner', 'tenant']) {
				contracts[t] = [
					...(this.multipleContracts ? sContracts[t].future : []),
					...sContracts[t].present,
					...(this.multipleContracts ? sContracts[t].past : []),
				]
			}

			// create rows
			return this.units.map((unit) => {
				const owner = contracts.owner.filter((co) => co.unitId === unit.id) as Contract[]
				const tenant = contracts.tenant.filter((co) => co.unitId === unit.id) as Contract[]

				const activeTenantContract = tenant.find((t) => t.isActive)
				const activeOwnerContract = owner.find((o) => o.isActive)
				return {
					id: unit.id,
					unit,
					contracts: {
						owner,
						tenant,
					},
					address: this.objectAddresses.find((oa) => oa.id === unit.addressId),
					isOwnerOccupied:
						!this.multipleContracts && isOwnerOccupied(activeTenantContract, activeOwnerContract),
				}
			})
		}

		get filteredRows() {
			if (!this.searchString) {
				this.changeRowsOnReload = false
				return this.rows // TODO
			}
			// filter
			this.changeRowsOnReload = true
			return search(this.rows, this.searchString, {
				fuzzy: true,
				keys: this.searchKeys,
			})
		}

		get showAddress() {
			const objectAddressIds = this.objectAddresses.map((a) => a.id)
			for (const row of this.filteredRows) {
				if (objectAddressIds.includes(row.unit.addressId)) return true
			}
			return false
		}

		/**
		 * Stats
		 */
		get stats() {
			const uData = {}
			for (const u of ['tenant', 'owner']) {
				let users = {}
				let contracts = []
				for (const r of this.rows) {
					contracts = contracts.concat(r.contracts[u])
				}
				users = {
					unitsWithRegisteredUsers: contracts.filter((r) => getRegisteredUsers(r).length).length,
					unitsWithUsers: contracts.filter((r) => getUsers(r).length).length,
				}
				const data = {
					total: this.rows.length,
					invited: 0,
					others: 0,
					...users,
				}
				data.invited = data.unitsWithUsers - data.unitsWithRegisteredUsers
				data.others = data.total - data.invited - data.unitsWithRegisteredUsers
				uData[u] = {
					// section 1: blue bar is number of units where at least one signatory is registered
					section1: (100 * data.unitsWithRegisteredUsers) / data.total,
					// section 2: grey bar is number of units where noone is registered, but at least one person is invited
					section2: (100 * data.invited) / data.total,
					// section 3: faded out gray bar is the rest
					section3: 100 * (data.others / data.total),
					// absolute numbers
					registered: data.unitsWithRegisteredUsers,
					invited: data.invited,
					others: data.others,
				}
			}
			return {
				count: this.units.length,
				...uData,
			}
		}

		get statBars() {
			return [
				{
					color: 'secondary',
					value: 'section1',
					count: 'registered',
				},
				{
					color: 'grey',
					value: 'section2',
					count: 'invited',
				},
				{
					color: 'grey darken-2',
					value: 'section3',
					count: 'others',
				},
			]
		}

		/**
		 * Selection Actions
		 */

		get selectControls() {
			let result = []
			if (this.selectionMode === 'units') {
				result = result.concat(['selectUnits'])
			} else if (this.selectionMode === 'contracts' || this.selectionMode === 'users') {
				if (!this.hideOwners) {
					result = result.concat(['selectCurrentOwners', 'selectCurrentResidents'])
				}
				if (!this.hideTenants) {
					result = result.concat(['selectCurrentTenants'])
				}
			}

			// add selection by address / group / types
			if (this.selectionMode) {
				result = result.concat(['fromObjectAddress', 'fromUnitGroups', 'fromUnitTypes'])
			}

			return result
		}

		selectFromContracts(contracts) {
			for (const c of contracts) {
				if (this.selectionMode === 'contracts') {
					if (!this.selection.contracts.includes(c.id)) this.selection.contracts.push(c.id)
				} else if (this.selectionMode === 'users') {
					for (const s of c.signatories)
						if (s.user && !this.selection.users.includes(s.user.id)) {
							this.selection.users.push(s.user.id)
						}
				}
			}
		}

		unselectFromContracts(contracts) {
			for (const c of contracts) {
				if (this.selectionMode === 'contracts') {
					if (this.selection.contracts.includes(c.id))
						this.selection.contracts.splice(this.selection.contracts.indexOf(c.id), 1)
				} else if (this.selectionMode === 'users') {
					for (const s of c.signatories)
						if (s.user && this.selection.users.includes(s.user.id)) {
							this.selection.users.splice(this.selection.users.indexOf(s.user.id), 1)
						}
				}
			}
		}

		emitSelection() {
			// emit selection
			if (this.selectionMode === 'contracts') this.$emit('contractsSelected', this.selection.contracts)
			else this.$emit('usersSelected', this.selection.users)
		}

		get generalSelections() {
			return [
				{
					if: this.selectControls.includes('selectUnits'),
					title: this.$t('domain.unitContractPicker.allUnits'),
					btns: [
						{
							icon: 'fal fa-check-double',
							tooltip: this.$t('activity.selection.selectAll'),
							callback: () => {
								for (const unit of this.units) {
									if (!this.selection.units.includes(unit.id)) {
										this.selection.units.push(unit.id)
									}
								}
								this.$emit('unitsSelected', this.selection.units)
							},
							attributes: {
								'data-qa': 'unit-contract-picker:select-all-units',
							},
						},
						{
							icon: 'fal fa-times',
							tooltip: this.$t('activity.selection.unselectAll'),
							callback: () => {
								this.selection.units = []
								this.$emit('unitsSelected', [])
							},
							attributes: {
								'data-qa': 'unit-contract-picker:unselect-all-units',
							},
						},
					],
				},
				{
					if: this.selectControls.includes('selectCurrentOwners'),
					title: this.$t('domain.unitContractPicker.owners'),
					btns: [
						{
							icon: 'fal fa-check-double',
							tooltip: this.$t('activity.selection.selectAll'),
							callback: () => {
								for (const unit of this.rows) {
									this.selectFromContracts(unit.contracts.owner.filter((co) => !!co.isActive))
								}
								this.emitSelection()
							},
							attributes: {
								'data-qa': 'unit-contract-picker:select-all-owners',
							},
						},
						{
							icon: 'fal fa-times',
							tooltip: this.$t('activity.selection.unselectAll'),
							callback: () => {
								for (const unit of this.rows) {
									this.unselectFromContracts(unit.contracts.owner.filter((co) => !!co.isActive))
								}
								// emit selection
								this.emitSelection()
							},
							attributes: {
								'data-qa': 'unit-contract-picker:unselect-all-owners',
							},
						},
					],
				},
				{
					if: this.selectControls.includes('selectCurrentTenants'),
					title: this.$t('domain.unitContractPicker.tenants'),
					btns: [
						{
							icon: 'fal fa-check-double',
							tooltip: this.$t('activity.selection.selectAll'),
							callback: () => {
								for (const unit of this.rows) {
									this.selectFromContracts(
										unit.contracts.tenant.filter(
											(co) =>
												!!co.isActive &&
												co.contractType === ContractType.tenant &&
												!unit.isOwnerOccupied,
										),
									)
								}

								// emit selection
								this.emitSelection()
							},
							attributes: {
								'data-qa': 'unit-contract-picker:select-all-tenants',
							},
						},
						{
							icon: 'fal fa-times',
							tooltip: this.$t('activity.selection.unselectAll'),
							callback: () => {
								for (const unit of this.rows) {
									this.unselectFromContracts(
										unit.contracts.tenant.filter((co) => !!co.isActive && !unit.isOwnerOccupied),
									)
								}
								// emit selection
								this.emitSelection()
							},
							attributes: {
								'data-qa': 'unit-contract-picker:unselect-all-tenants',
							},
						},
					],
				},
				{
					if: this.selectControls.includes('selectCurrentResidents'),
					title: this.$t('domain.unitContractPicker.residents'),
					btns: [
						{
							icon: 'fal fa-check-double',
							tooltip: this.$t('activity.selection.selectAll'),
							callback: () => {
								this.selectFromContracts(this._getResidents())
								this.emitSelection()
							},
							attributes: {
								'data-qa': 'unit-contract-picker:select-all-residents',
							},
						},
						{
							icon: 'fal fa-times',
							tooltip: this.$t('activity.selection.unselectAll'),
							callback: () => {
								this.unselectFromContracts(this._getResidents())
								this.emitSelection()
							},
							attributes: {
								'data-qa': 'unit-contract-picker:unselect-all-residents',
							},
						},
					],
				},
			]
		}

		_getResidents() {
			return this.rows.flatMap((r) => {
				return r.contracts.tenant.filter((co) => !!co.isActive)
			})
		}

		// targeted selections (address, group...)
		targetDialogs = {
			addressSelectDialog: false,
			unitGroupSelectDialog: false,
			unitTypeSelectDialog: false,
		}

		get unitTypes() {
			return Object.keys(UnitType).map((t) => {
				return {
					text: this.$t(`domain.unit.edit.type.options.${t}.label`),
					value: t,
				}
			})
		}

		get targetedSelections() {
			return [
				{
					if: this.selectControls.includes('fromObjectAddress'),
					dialog: 'addressSelectDialog',
					title: this.$t('domain.unitContractPicker.addresses'),
					targets: this.objectAddresses,
					targetTitle: (t) => {
						return t.name
					},
					select: (address, type) => {
						this._selectForTarget(
							this.rows.filter((u) => {
								return u.unit.addressId === address.id
							}),
							type,
						)
					},
					unselect: (address, type) => {
						this._unselectForTarget(
							this.rows.filter((u) => {
								return u.unit.addressId === address.id
							}),
							type,
						)
					},
				},
				{
					if: this.selectControls.includes('fromUnitGroups'),
					dialog: 'unitGroupSelectDialog',
					title: this.$t('domain.unitContractPicker.groups'),
					targets: this.unitGroups,
					targetTitle: (t) => {
						return t.title
					},
					select: (group, type) => {
						this._selectForTarget(
							this.rows.filter((u) => {
								return group.unitIds.includes(u.unit.id)
							}),
							type,
						)
					},
					unselect: (group, type) => {
						this._unselectForTarget(
							this.rows.filter((u) => {
								return group.unitIds.includes(u.unit.id)
							}),
							type,
						)
					},
				},
				{
					if: this.selectControls.includes('fromUnitTypes'),
					dialog: 'unitTypeSelectDialog',
					title: this.$t('domain.unitContractPicker.types'),
					targets: this.unitTypes,
					targetTitle: (t) => {
						return t.text
					},
					select: (unitType, type) => {
						this._selectForTarget(
							this.rows.filter((u) => {
								return u.unit.type === unitType.value
							}),
							type,
						)
					},
					unselect: (unitType, type) => {
						this._unselectForTarget(
							this.rows.filter((u) => {
								return u.unit.type === unitType.value
							}),
							type,
						)
					},
				},
			]
		}

		_selectForTarget(rows, type) {
			for (const row of rows) {
				if (type === 'unit') {
					if (!this.selection.units.includes(row.unit.id)) {
						this.selection.units.push(row.unit.id)
					}
					this.$emit('unitsSelected', this.selection.units)
				} else
					for (const ct of ['owner', 'tenant']) {
						if (type === ct) {
							this.selectFromContracts(row.contracts[ct])
							this.emitSelection()
						}
					}
			}
		}

		_unselectForTarget(rows, type) {
			for (const row of rows) {
				if (type === 'unit') {
					if (this.selection.units.includes(row.unit.id)) {
						this.selection.units.splice(this.selection.units.indexOf(row.unit.id), 1)
					}
					this.$emit('unitsSelected', this.selection.units)
				} else
					for (const ct of ['owner', 'tenant']) {
						if (type === ct) {
							this.unselectFromContracts(row.contracts[ct])
							this.emitSelection()
						}
					}
			}
		}

		// select by unit groups
		get unitGroups() {
			return this.$store.getters['property/unitGroups/byObject'](this.object.id)
		}
	}
