
	import { Component, Prop, Vue } from 'nuxt-property-decorator'

	@Component({
		components: {},
	})
	export default class GreyCard extends Vue {
		@Prop({ default: null }) icon: string
		@Prop({ default: null }) title: string
		@Prop({ default: null }) to: string
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: Boolean, default: false }) disabled: boolean
	}
