import { FallbackImage, ImageTransform, imageSrc } from '~/app/common/filters/Image'
import { ImageAsset } from '~/app/domain/assets/Image'
import { Pixels } from '~/app/domain/Types'

export interface IImage {
	getIconForExt(src: string): string
	resolveImg(
		image: ImageAsset,
		maxWidth?: Pixels,
		maxHeight?: Pixels,
		filter?: ImageTransform,
		fallback?: FallbackImage,
	): string
	getIconForDomain(domain: string): string
}

enum IconVariant {
	light = 'light',
	regular = 'regular',
	solid = 'solid',
	duotone = 'duotone',
}

export default {
	/**
	 * Resolves the URL from an ImageAsset
	 */
	resolveImg: imageSrc,

	/**
	 * Given a file, retrieve it's icon
	 * @param path
	 */
	getIconForExt(path: string): string {
		const ext = path.split('.').pop()
		switch (ext) {
			case 'pdf':
				return 'fal fa-file-pdf'
			case 'xls':
				return 'fal fa-file-spreadsheet'
			case 'xlsx':
				return 'fal fa-file-spreadsheet'
			default:
				return 'fal fa-file'
		}
	},

	/**
	 * Get an icon for a domain
	 */
	getIconForDomain(domain: string, variant: IconVariant = IconVariant.light): string {
		const prefix = {
			[IconVariant.light]: 'fal fa-',
			[IconVariant.regular]: 'far fa-',
			[IconVariant.solid]: 'fas fa-',
			[IconVariant.duotone]: 'fad fa-',
		}[variant]
		switch ((domain || '').toLowerCase()) {
			case 'address':
				return prefix + 'map-marker-alt'
			case 'advisor':
				return prefix + 'comments-alt'
			case 'agent':
				return prefix + 'user-friends'
			case 'batch':
				return prefix + 'layer-group'
			case 'callrecord':
				return prefix + 'phone'
			case 'contact':
				return prefix + 'address-card'
			case 'company':
				return prefix + 'home'
			case 'company-group':
				return prefix + 'house-day'
			case 'connect':
				return prefix + 'project-diagram'
			case 'contact-person':
				return prefix + 'user-circle'
			case 'contract':
				return prefix + 'file-signature'
			case 'conversation':
				return prefix + 'comments'
			case 'customer':
				return prefix + 'handshake'
			case 'delivery':
				return prefix + 'mailbox'
			case 'document':
				return prefix + 'file-alt'
			case 'email':
				return prefix + 'envelope'
			case 'event':
				return prefix + 'calendar'
			case 'faq':
				return prefix + 'question-square'
			case 'integration':
				return prefix + 'puzzle-piece'
			case 'law':
				return prefix + 'gavel'
			case 'note':
				return prefix + 'sticky-note'
			case 'object':
				return prefix + 'home'
			case 'object-group':
				return prefix + 'house-day'
			case 'offer-request':
				return prefix + 'comments-alt-dollar'
			case 'order':
				return prefix + 'tools'
			case 'owner':
				return prefix + 'users-crown'
			case 'process':
				return prefix + 'clipboard-list'
			case 'process-update':
				return prefix + 'sync'
			case 'provider':
				return prefix + 'user-circle'
			case 'post':
				return prefix + 'newspaper'
			case 'responsibility':
				return prefix + 'user-tie'
			case 'tenant':
				return prefix + 'house-user'
			case 'settings':
				return prefix + 'cog'
			case 'service-form':
				return prefix + 'file-invoice'
			case 'subdomain':
				return prefix + 'link'
			case 'supplier':
				return prefix + 'wrench'
			case 'support':
				return prefix + 'lightbulb-on'
			case 'unit':
				return prefix + 'home'
			case 'unit-group':
				return prefix + 'home'
			case 'upgrade':
				return prefix + 'rocket'
			case 'user':
				return prefix + 'user'
			case 'employee':
				return prefix + 'user-circle'
			case 'voting':
				return prefix + 'box-ballot'
			default:
				return prefix + 'circle'
		}
	},
}
