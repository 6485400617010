var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.UiCard,{staticClass:"tw-ring-1 tw-py-3 tw-px-4 tw-bg-background-soft",class:{
		'tw-ring-background-stronger': !_vm.active,
		'tw-ring-accent': _vm.active,
	},attrs:{"to":_vm.to},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-2"},[_c('span',{staticClass:"tw-text-text-soft tw-font-bold tw-mr-auto"},[_c(_setup.UiIcon,{staticClass:"-tw-my-1",attrs:{"icon":"fal fa-phone","size":"md"}}),_vm._v(" "),_c('span',{staticClass:"tw-mx-1"},[_vm._v("\n\t\t\t\t"+_vm._s(_setup.getCallRecordTitle(_vm.record))+"\n\t\t\t")]),_vm._v(" "),(_vm.record.direction)?_c(_setup.UiIcon,{class:{
					'tw-rotate-45': _vm.record.direction === _setup.CallDirection.inbound,
					'-tw-rotate-[135deg]': _vm.record.direction === _setup.CallDirection.outbound,
				},attrs:{"icon":"fal fa-long-arrow-down","size":"md"}}):_vm._e()],1),_vm._v(" "),_c(_setup.UiTooltip,{staticClass:"tw-shrink-0",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_setup.UserAvatar,{attrs:{"user":_vm.record.recordedBy,"size":"sm"}})]},proxy:true}])},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.callRecord.recordedBy', {
						name: _setup.recordedByName,
					}))+"\n\t\t\t")])]),_vm._v(" "),_c(_setup.DateFt,{staticClass:"tw-shrink-0 tw-text-text-soft",attrs:{"date":_vm.record.recordedOn,"format":"short","precision":_setup.precision}})],1),_vm._v(" "),(_vm.record.note)?_c('div',{directives:[{name:"user-content",rawName:"v-user-content",value:(_vm.record.note),expression:"record.note"}],staticClass:"user-generated-content tw-mt-2 tw-text-text-soft tw-line-clamp-3"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }