
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { Document } from '~/app/domain/content/Document'
	import bytes from '~/app/common/filters/Bytes'
	import Date from '~/components/utils/Date.vue'
	import { uppercase } from '~/app/common/filters/text/Case'

	@Component({
		components: { Date },
	})
	export default class DocumentListItem extends Vue {
		Image = Image
		@Prop({ required: true }) document: Document

		bytes = bytes
		uppercase = uppercase
	}
