import { itemStoreActions, itemStoreGetters, itemStoreMutations } from '~/app/store/ItemStore'
import { pick } from 'lodash'
import { ObjectGroupId } from '~/app/domain/property/ObjectGroup'
import { PropertyObjectId } from '~/app/domain/property/Object'

export const state = () => ({
	initialized: false,
	processing: false,
	items: [],
	context: null,
})

export const getters = {
	...itemStoreGetters({
		sortIteratee: 'title',
		lifetime: 30e3,
	}),
}

export const mutations = {
	...itemStoreMutations({ sortIteratee: 'title' }),

	resetForContext(state, context: string): void {
		state.initialized = false
		state.processing = false
		state.items = []
		state.context = context
	},
}

export const actions = {
	...itemStoreActions({}),

	async fetchAll(context) {
		// check if we need to reset because we're in a new context
		const currentContext = `${context.rootGetters['tenant/subdomain']}-${
			context.rootGetters['routing/isBackoffice'] ? 'bo' : 'fo'
		}`
		if (!context.state.context || context.state.context !== currentContext) {
			context.commit('resetForContext', currentContext)
		}

		// don't reload if we're already reloading
		if (context.getters.isProcessing) {
			return
		}
		// only reload if we're not initialized or if reload is forced
		if (context.getters.isInitialized) {
			return
		}
		const objectGroups = (await this.$axios.get('etg24:internal:query:propertymanagement.objectgroups')).data
		context.commit('setItems', objectGroups)
		context.commit('setInitialized', true)
	},

	async fetch(context, groupId: ObjectGroupId) {
		const objectGroup = (
			await this.$axios.get('etg24:internal:query:propertymanagement.objectgroup', {
				params: { objectGroup: groupId },
			})
		).data
		context.commit('setItem', objectGroup)
	},

	async create(context, objectGroups) {
		// reduce to allowed properties
		objectGroups = objectGroups.map((objectGroup) => pick(objectGroup, ['title', 'description']))
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectgroups.create', {
			objectGroups,
		})
		context.commit('addItems', response.data)
		return response.data
	},

	async update(context, objectGroup) {
		// reduce to allowed properties
		objectGroup = pick(objectGroup, ['id', 'title', 'description'])
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectgroups.update', {
			objectGroup,
		})
		context.commit('updateItem', response.data)
		return response.data
	},

	async delete(context, objectGroupId) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectgroups.delete', {
			objectGroup: objectGroupId,
		})
		context.commit('removeItem', objectGroupId)
	},

	async addObjectsToGroup(context, payload: { objectGroupId: ObjectGroupId; objectIds: PropertyObjectId[] }) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectgroups.addobjects', {
			objectGroup: payload.objectGroupId,
			objects: payload.objectIds,
		})
		context.commit('updateItem', response.data)
	},

	async addObjectToGroups(context, payload: { objectGroupIds: ObjectGroupId[]; object: PropertyObjectId }) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectgroups.addgroups', {
			objectGroups: payload.objectGroupIds,
			object: payload.object,
		})
		for (const group of response.data) context.commit('updateItem', group)
	},

	async removeObjectsFromGroup(context, payload: { objectGroupId: ObjectGroupId; objectIds: PropertyObjectId[] }) {
		const response = await this.$axios.post(
			'etg24:internal:command:propertymanagement.objectgroups.removeobjects',
			{
				objectGroup: payload.objectGroupId,
				objects: payload.objectIds,
			},
		)
		context.commit('updateItem', response.data)
	},
}
