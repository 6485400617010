import { FileExtension, FileName } from '../../domain/Types'
import { getFileExtension } from '~/app/utils/libs/FileUtil'

const fileExtensionToColorMap = {
	pdf: '#ff0020',
	doc: '#2a5599',
	docx: '#2a5599',
	xls: '#247648',
	xlsx: '#247648',
	ppt: '#d14524',
	pptx: '#d14524',
	pps: '#d14524',
	ppsx: '#d14524',
	msg: '#0073c6',
	eml: '#0073c6',
	zip: '#ffc107',
	rar: '#ffc107',
	'7z': '#ffc107',
	tar: '#ffc107',
	gz: '#ffc107',
	bz2: '#ffc107',
	jpg: '#c0ca33',
	jpeg: '#c0ca33',
	png: '#c0ca33',
	gif: '#c0ca33',
	bmp: '#c0ca33',
	tiff: '#c0ca33',
	tif: '#c0ca33',
	psd: '#c0ca33',
}
const fallbackFileExtensionColor = '#2f97b9'

/**
 * vue.js filter: renders a color that matches with the provided file extension
 */
export function fileExtensionToColor(extension: string) {
	return extension in fileExtensionToColorMap ? fileExtensionToColorMap[extension] : fallbackFileExtensionColor
}

// Strips a possible file extension off the provided file name.
export function stripFileExtension(name: FileName & FileExtension): FileName {
	return name ? name.replace(/\.[\w\d]+$/, '') : ''
}

// Enumerates the provided file extensions.
export function enumerateFileExtensions(list: FileExtension[]): string {
	return list.map((ext) => ext.toUpperCase()).join(', ')
}

// Checks whether the provided file extension is a part of the provided list of file extensions.
export function includesFileExtension(
	ext: File | FileExtension,
	list: FileExtension[],
	isGoodWhenEmpty: boolean = false,
): boolean {
	if (ext instanceof File) {
		ext = getFileExtension(ext)
	}

	if (!ext || !list || !list.length) {
		return isGoodWhenEmpty
	}

	return list.map((ext) => ext.toUpperCase()).includes(ext.toUpperCase())
}
