var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"min-width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : 400},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menuOn }){return [_c('v-tooltip',{attrs:{"bottom":"","nudge-top":20,"color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},{ ...tooltipOn, ...menuOn }),[_c(_setup.UiBadge,{attrs:{"overlap":"","visible":!!_setup.badgeNumber,"content":_setup.badgeNumber}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_setup.Image.getIconForDomain('email'))+"\n\t\t\t\t\t\t")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('domain.inboundEmailNotifications.title')))])])]}}]),model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}},[_vm._v(" "),_c('v-card',{attrs:{"min-width":"300","max-width":"min(500px, calc(100vw - 24px))","max-height":"min(600px, calc(100vh - 24px))"}},[_c('div',{staticClass:"tw-p-2 tw-flex tw-justify-between tw-items-center"},[_c('NuxtLink',{staticClass:"tw-grow tw-p-2 tw-rounded tw-overflow-hidden hoverable",attrs:{"to":`${_setup.appPath}/inbound-emails/inbox?assignee=${_setup.auth.user?.id}`}},[_c('div',{staticClass:"title primary--text"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.inboundEmailNotifications.title'))+"\n\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"tw-leading-none tw-text-sm tw-font-bold"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.inboundEmailNotifications.goToInbox'))+"\n\t\t\t\t")])]),_vm._v(" "),(_setup.badgeNumber)?_c('strong',{staticClass:"tw-text-sm tw-p-2 secondary--text"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.inboundEmailNotifications.newCount', {
						count: _setup.badgeNumber,
					}))+"\n\t\t\t")]):_vm._e()],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(_setup.list?.length)?_c('div',[_c('v-list',_vm._l((_setup.list),function(inboundEmail){return _c(_setup.InboundEmailListItem,{key:inboundEmail.id,attrs:{"inbound-email":inboundEmail,"to":{
						query: {
							..._vm.$route.query,
							...{
								contentType: 'inboundEmail',
								contentId: inboundEmail.id,
							},
						},
					},"objects":_setup.objects,"has-actions":false}})}),1)],1):_c('div',{staticClass:"pa-12"},[_c(_setup.SearchSplash,{attrs:{"title":_vm.$t('domain.inboundEmail.emptyList.title'),"body":_vm.$t('domain.inboundEmail.emptyList.body'),"icon":_setup.Image.getIconForDomain('email')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }