import { DateString } from '~/app/domain/Types'

export const state = () => ({
	newsCheckedOn: null,
})

export const getters = {
	newsCheckedOn: (state, _, rootState) => state.newsCheckedOn || rootState.auth?.user?.newsCheckedOn,
}

export const mutations = {
	setNewsCheckedOn: (state, newsCheckedOn: DateString) => {
		state.newsCheckedOn = newsCheckedOn
	},
}

export const actions = {
	// updates the newsCheckedOn date
	newsChecked(context) {
		this.$axios.post('etg24:internal:command:news.markchecked')
		context.commit('setNewsCheckedOn', new Date().toISOString())
	},
}
