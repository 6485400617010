
	import { Component, Vue, Prop } from 'nuxt-property-decorator'

	@Component({})
	export default class CategoryContainer extends Vue {
		@Prop({ default: null }) icon: string
		@Prop({ default: 24 }) avatarWidth: number
		@Prop({ default: 0 }) iconWidth: number
		@Prop({ default: true }) hideIconXs: boolean

		get hiddenIcons() {
			return this.hideIconXs && this.$vuetify.breakpoint.xsOnly
		}
	}
