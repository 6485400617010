
	import { Vue, Component, Prop, Watch, Model } from 'nuxt-property-decorator'
	import List from './List.vue'
	import ListItem from './ListItem.vue'
	import SortingPicker from './SortingPicker.vue'
	import { Document, DocumentId, documentToLinks, documentSortingOptions } from '~/app/domain/content/Document'
	import { WithNode } from '~/app/domain/utility/ContentUtilities'
	import { WithCategory } from '~/app/domain/content/Category'
	import { NodeId } from '~/app/domain/content/Common'
	import sort from '~/app/common/filters/iterator/Sort'
	import bytes from '~/app/common/filters/Bytes'
	import Date from '~/components/utils/Date.vue'
	import { uppercase } from '~/app/common/filters/text/Case'
	import DocumentPreview from '~/components/domain/document/DocumentPreview.vue'
	import DocumentListItem from '~/components/domain/document/DocumentListItem.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import ContextLocation from '~/components/utils/ContextLocation.vue'

	@Component({
		components: {
			ContextLocation,
			BasicDialog,
			DocumentListItem,
			DocumentPreview,
			Date,
			SortingPicker,
			ListItem,
			List,
		},
	})
	export default class DocumentList extends Vue {
		@Model('change', { required: true }) selection: DocumentId[]

		// The Documents to be displayed within the list
		@Prop({ required: true, type: Array }) documents: (Document & WithNode & WithCategory)[]

		// The NodeId where these Documents are currently shown
		@Prop({ type: String }) nodeId: NodeId

		// The Nodes needed to display the individual document's context location
		@Prop({ type: Array, default: null }) nodes: Node[]

		// Optionally an Id of one Document to be highlighted
		@Prop({ default: null, type: String }) highlight: DocumentId

		// Whether or not the list items should be editable by the user
		@Prop({ default: false, type: Boolean }) editable: boolean
		@Prop({ required: true }) allDocuments: Document[]
		@Prop({ type: Boolean, default: false }) showLocation

		// imported functions
		bytes = bytes
		uppercase = uppercase
		documentToLinks = documentToLinks

		sortingOptions = documentSortingOptions

		sorting = this.sortingOptions[0]

		// The Selection of items in this list
		localSelection: DocumentId[] = []

		// this is how many documents will be displayed
		// it is increased automatically if the end of the document list is reached
		// or by a press on the trailing button, if the respective api is not supported by the browser (ie, safari)
		itemLimit = 0

		@Watch('documents', { immediate: true }) onDocumentsChanged() {
			this.itemLimit = 10
		}

		get sortedDocuments() {
			return sort(this.documents, this.sorting.iteratee as keyof Document, this.sorting.order as 'asc' | 'desc')
		}

		get croppedDocuments() {
			return this.sortedDocuments.slice(0, this.itemLimit)
		}

		increaseItemLimit() {
			this.itemLimit += 10
		}

		// selection
		@Watch('selection') selectionUpdated() {
			this.localSelection = this.selection
		}

		@Watch('localSelection') updateLocalSelection() {
			this.$emit('change', this.localSelection)
		}

		toggleSelection(id) {
			const i = this.localSelection.indexOf(id)
			if (i >= 0) this.localSelection.splice(i, 1)
			else {
				this.localSelection.push(id)
			}
			if (this.localSelection.length === 0) this.selectedDocsMenu = false
		}

		// selects / unselects all items
		get selectedCurrentDocs() {
			const currentDocs = this.documents.map((d) => {
				return d.id
			})
			const selected = []
			for (const doc of currentDocs) {
				if (this.localSelection.includes(doc)) selected.push(doc)
			}
			return selected
		}

		toggleSelectMode() {
			this.selectMode = !this.selectMode
			this.localSelection = []
		}

		toggleGlobalSelection() {
			const currentDocs = this.documents.map((d) => {
				return d.id
			})
			if (this.selectedCurrentDocs.length > 0) {
				if (this.selectedCurrentDocs.length === currentDocs.length) {
					for (const doc of currentDocs) {
						const i = this.localSelection.indexOf(doc)
						if (i >= 0) this.localSelection.splice(i, 1)
					}
				} else {
					for (const doc of currentDocs) {
						const i = this.localSelection.indexOf(doc)
						if (i < 0) this.localSelection.push(doc)
					}
				}
			} else {
				this.localSelection = this.localSelection.concat(currentDocs)
			}
		}

		clearSelection() {
			this.localSelection = []
			this.selectedDocsMenu = false
		}

		get selectedDocuments() {
			return this.allDocuments.filter((d) => {
				return this.localSelection.includes(d.id)
			})
		}

		get selectedDocumentsSize() {
			let total = 0
			for (const doc of this.selectedDocuments) total += doc.size
			return bytes(total)
		}

		/**
		 * Select mode & states
		 */
		selectMode: boolean = false
		selectedDocsMenu: boolean = false

		visibilityChanged(isVisible) {
			if (isVisible) this.increaseItemLimit()
		}

		/**
		 * Misc
		 */
		get mobile() {
			return this.$vuetify.breakpoint.xsOnly
		}

		/**
		 * Document actions
		 */
		get downloadAction() {
			return {
				text: this.$t('layout.download'),
				icon: 'fal fa-download',
				emit: 'documents:download',
			}
		}

		get otherActions() {
			const result = []
			if (this.editable) {
				result.push({
					text: this.$t('domain.document.actions.moveToCategory'),
					icon: 'fal fa-tag',
					emit: 'documents:movetocategory',
				})
				result.push({
					text: this.$t('domain.document.actions.setTag'),
					icon: 'fal fa-hashtag',
					emit: 'documents:addtag',
				})
				result.push({
					text: this.$t('domain.document.actions.setFinancialYear'),
					icon: 'fal fa-calendar',
					emit: 'documents:setfinancialyear',
				})
				result.push({
					text: this.$t('domain.document.actions.delete'),
					icon: 'fal fa-trash',
					emit: 'documents:delete',
				})
			}
			return result.filter((d) => {
				return this.$listeners && this.$listeners[d.emit]
			})
		}

		// default handler for document actions
		documentAction(document: Document) {
			if (this.selectMode) {
				this.toggleSelection(document.id)
			} else {
				this.$router.push({
					query: { ...this.$route.query, document: document.id },
				})
			}
		}
	}
