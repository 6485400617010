import { Capacitor } from '@capacitor/core'

export const state = () => ({
	isNative: Capacitor.isNativePlatform(), // true for android / ios, false for web
	name: Capacitor.getPlatform(), // web / ios / android
	isCypress: !!window['Cypress'],
})

export const getters = {
	isNative: (state, getters, rootState, rootGetters) => {
		return state.isNative
	},

	isCypress: (state, getters, rootState, rootGetters) => {
		return state.isCypress
	},

	name: (state, getters, rootState, rootGetters) => {
		return state.name
	},

	/**
	 * Tells if the platform is able to render PDFs natively
	 *
	 * @see https://github.com/featurist/browser-pdf-support/blob/master/index.js
	 */
	canRenderPdf: (state, getters, rootState, rootGetters) => {
		if (state.isNative) return false

		const hasAcrobatInstalled = () => {
			function getActiveXObject(name) {
				try {
					// @ts-ignore
					return new ActiveXObject(name)
				} catch (e) {}
			}

			return getActiveXObject('AcroPDF.PDF') || getActiveXObject('PDF.PdfCtrl')
		}
		const isInlinePdfCapable = () =>
			// @ts-ignore
			navigator?.pdfViewerEnabled || navigator.mimeTypes?.['application/pdf']

		return !!(isInlinePdfCapable() || hasAcrobatInstalled())
	},

	// isPluginAvailable: (state, getters, rootState, rootGetters) => (
	// 	pluginName: string
	// ): boolean => {
	// 	return Capacitor.isPluginAvailable(pluginName)
	// },
}
