import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as dateFns from 'date-fns';
import { enUS, de } from 'date-fns/locale';
export default (function (ctx, inject) {
  var locales = {
    en: enUS,
    de: de
  };
  dateFns.setDefaultOptions({
    locale: locales[ctx.app.i18n.locale || 'de']
  });
  inject('dateFns', _objectSpread(_objectSpread({}, dateFns), {}, {
    format: function format(date) {
      var _format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd';
      var options = arguments.length > 2 ? arguments[2] : undefined;
      return dateFns.format(date, _format, _objectSpread({
        locale: locales[ctx.app.i18n.locale || 'de']
      }, options));
    }
  }));
});