import { Deploy } from 'cordova-plugin-ionic'

interface AppflowConfig {
	appId: string
	binaryVersionCode: string
	channel: string
	disabled: boolean
	host: string
	maxVersions: number
	minBackgroundDuration: number
	updateMethod: 'none' | 'auto' | 'background'
}

interface AppflowSnapshotInfo {
	binaryVersionCode: string
	binaryVersionName: string
	buildId: string
	channel: string
	versionId: string
}

export const state = () => ({
	config: null, // @see https://ionic.io/docs/appflow/deploy/api#icurrentconfig
	currentVersion: null,
	latestVersion: null,
	updateProgress: null,
})

export const getters = {
	config: (state, getters, rootState, rootGetters): AppflowConfig => {
		return state.config
	},

	currentVersion: (state, getters, rootState, rootGetters): AppflowSnapshotInfo => {
		return state.currentVersion
	},

	latestVersion: (state, getters, rootState, rootGetters): string | null => {
		return state.latestVersion
	},

	updateProgress: (state, getters, rootState, rootGetters): string | null => {
		return state.updateProgress
	},
}

export const mutations = {
	setConfig: (state, config: AppflowConfig) => {
		state.config = config
	},

	setCurrentVersion: (state, currentVersion: AppflowSnapshotInfo) => {
		state.currentVersion = currentVersion
	},

	setLatestVersion: (state, latestVersion: string) => {
		state.latestVersion = latestVersion
	},

	setUpdateProgress: (state, updateProgress: number) => {
		state.updateProgress = updateProgress
	},
}

interface CheckForUpdateResponse {
	available: boolean
	build?: string
}

export const actions = {
	loadConfig: async (context) => {
		if (!context.rootGetters['platform/isNative']) {
			return
		}

		try {
			context.commit('setConfig', await Deploy.getConfiguration())
		} catch (e) {
			context.commit('setConfig', false)
		}
	},

	checkForUpdate: async (context) => {
		const update: CheckForUpdateResponse = await Deploy.checkForUpdate()
		context.commit('setLatestVersion', update.available ? update.build : null)
	},

	setChannel: async (context, channelName) => {
		await Deploy.configure({
			// ...context.getters['config'],
			channel: channelName,
		})
		await context.dispatch('loadConfig')
		await context.dispatch('checkForUpdate')
	},

	setUpdateMethod: async (context, method: 'none' | 'auto' | 'background') => {
		await Deploy.configure({ updateMethod: method })
		await context.dispatch('loadConfig')
	},

	update: async (context) => {
		try {
			const currentVersion = await Deploy.getCurrentVersion()
			const setUpdateProgress = (percentDone) => {
				console.log(`updateProgress: ${percentDone}%`)
				context.commit('setUpdateProgress', percentDone / 100)
			}
			const response = await Deploy.sync({ updateMethod: 'auto' }, setUpdateProgress)
			if (!currentVersion || currentVersion.versionId !== response.versionId) {
				// We found an update, and are in process of redirecting you since we defined updateMethod: auto
			} else {
				// No update available
			}
		} catch (err) {
			// We encountered an error :(
		}
	},
}
