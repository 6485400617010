
	import Utils from '~/app/utils/globals/Utils'
	import Vue from 'vue'
	import { Component, Model, Watch, Prop } from 'nuxt-property-decorator'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import DateComponent from '~/components/utils/Date.vue'
	import { PRECISION_MINUTE } from '~/app/common/filters/Date'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import SfTime from '~/components/domain/conversation/service_forms/blocks/SfTime.vue'
	import SfDateTime from '~/components/domain/conversation/service_forms/blocks/SfDateTime.vue'

	@Component({
		components: {
			SfDateTime,
			SfTime,
			SfDate,
			BasicDialog,
			ActionButton,
			date: DateComponent,
		},
	})
	export default class WaitUntilInput extends Vue {
		@Model('changed', { default: '' }) value: string
		@Prop({ type: Function, required: true }) snoozeFn: any
		@Prop({ type: String, default: 'content-actions' }) qaPrefix: string

		PRECISION_MINUTE = PRECISION_MINUTE

		localValue: string = null

		@Watch('value', { immediate: true })
		updateValue() {
			this.localValue = this.value
		}

		@Watch('localValue')
		updateLocalValue() {
			this.$emit('changed', this.localValue)
		}

		/**
		 * Snooze
		 */
		get snoozeBtn() {
			return {
				callback: () => {
					this.snoozeDialog = true
				},
				icon: 'fal fa-snooze',
				color: 'primary',
				attributes: {
					'data-qa': `${this.qaPrefix}`,
				},
			}
		}

		today: string = this.$dateFns.format(new Date(), 'yyyy-MM-dd')

		snoozeDialog: boolean = false

		snoozing: any = {
			remove: false,
			set: false,
		}

		async snooze(remove = false) {
			try {
				this.snoozing[remove ? 'remove' : 'set'] = true
				await this.snoozeFn(remove ? null : this.localValue)
				this.snoozing[remove ? 'remove' : 'set'] = false
				this.snoozeDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
		}
	}
