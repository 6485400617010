
	import { Component, Vue, Watch, Prop } from 'nuxt-property-decorator'

	@Component({})
	export default class SfRadio extends Vue {
		@Prop({ default: null }) rules: string
		@Prop({ default: null }) label: string
		@Prop({ default: undefined }) selected: any
		@Prop({ default: () => [] }) items: any[]
		@Prop({ default: false }) required: false

		localSelected = null
		@Watch('selected', { immediate: true })
		selectedUpdate() {
			const f = this.items.find((i) => {
				return i.value === this.selected
			})
			this.localSelected = f || this.selected
		}

		@Watch('localSelected', { immediate: true })
		updateModel() {
			this.$emit(
				'changed',
				this.localSelected.value !== undefined
					? this.localSelected.value
					: this.items.indexOf(this.localSelected),
			)
		}

		get rLabel() {
			return `${this.label}${this.required ? '*' : ''}`
		}
	}
