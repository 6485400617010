var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Teleport,{attrs:{"to":"#__layout"}},[_c('div',{ref:"dialog",staticClass:"tw-inset-0 tw-m-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-bg-transparent tw-p-0 before:tw-fixed before:tw-inset-0 before:tw-h-full before:tw-w-full before:tw-bg-primary-strong before:tw-opacity-40 before:tw-content-['']",class:{
			'tw-invisible tw-pointer-events-none': !_setup.model,
			'tw-animate-fade': _setup.model,
		},attrs:{"role":"dialog","popover":"manual"},on:{"click":_setup.onClick}},[(_setup.model)?_c(_setup.UiCard,_vm._b({staticClass:"tw-animate-appear tw-shadow-2xl",class:{
				'tw-h-full tw-w-full md:tw-h-auto md:tw-w-auto': _vm.mobileFullscreen,
				'tw-h-full tw-w-full': _vm.fullscreen,
				[`${_vm.classes}`]: true,
			}},'UiCard',_vm.$attrs,false),[_vm._t("default")],2):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }