import { DateString, FileExtension, FileName, FileSize, LongString, ShortString, Uuid } from '../Types'
import { Category, CategoryGroup, WithCategoryId } from './/Category'
import { Content, ContentId, NodeId, Node, NodeType } from './/Common'
import { Validators } from '../../common/validation/Validators'
import { FileAsset } from '~/app/domain/assets/File'
import { resolveApiLink } from '~/app/utils/apiLinks'

/*
 * Model
 */

export enum BatchType {}

export type DocumentId = ContentId
export interface Document extends Content<DocumentId>, WithCategoryId {
	id: DocumentId
	title: ShortString
	tag: ShortString | null
	description: LongString | null
	name: FileName
	extension: FileExtension
	size: FileSize
	nodeId: NodeId
	date: DateString
	financialYear: number
	batchId: Uuid
	batchType: string
	associatedWith: string
}

/*
 * Validation
 */

export const documentValidations = {
	title: {
		required: Validators.required,
	},
	description: {},
	name: {
		required: Validators.required,
		filename: Validators.filename,
	},
}

/*
 * Sort
 */

export const documentSortingOptions = [
	{
		label: 'layout.date',
		iteratee: 'date',
		order: 'desc',
	},
	{
		label: 'layout.title',
		iteratee: 'title',
		order: 'asc',
	},
	{
		label: 'layout.size',
		iteratee: 'size',
		order: 'desc',
	},
]

/*
 * Search
 */

export const documentSearchKeys: (keyof Document)[] = [
	'title',
	'description',
	'tag',
	'name',
	'extension',
	'financialYear',
]

/*
 * Api
 */

export interface DocumentsQueryResponse {
	documents: Document[]
	nodes: Node[]
	categories: Category[]
	categoryGroups: CategoryGroup[]
}

/*
 * Functions
 */

export const documentToLinks = (subdomain, document: Document | FileAsset, $store) => {
	const needsPreview = documentNeedsPreview(document)
	if (needsPreview) {
		return {
			// @ts-ignore
			to: `/app/${subdomain}/documents/${document.id}`,
			nuxt: true,
			target: '_blank',
		}
	} else {
		return {
			href: resolveApiLink($store, 'etg24:internal:query:download.document', { document: document.id }),
			target: '_blank',
		}
	}
}

/**
 * True if we should show the preview page every time for this document
 * @param document
 */
export const documentNeedsPreview = (document: Document | FileAsset) => {
	return false // todo: dirty fix until document previews are implemented
	const ext = (document as any).extension || `${document && document.name ? document.name.split('.').pop() : null}`
	return ['msg', 'eml', 'png', 'jpeg', 'jpg'].includes(ext)
}

/**
 * True if we need to preview the document every time
 * @param document
 */
export const documentCanBePreviewed = (document: Document) => {
	return documentNeedsPreview(document) || ['pdf'].includes(document.extension)
}
