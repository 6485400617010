// Declare the module state and its initial value

import sort, { Collation, Iteratee, SortOrder } from '~/app/common/filters/iterator/Sort'

interface Item {
	id: string
}

interface ItemScope {
	key: string
	processing: boolean
	initialized?: boolean
	timestamp: number
	stale: boolean
	itemIds: string[]
}

interface State {
	items: { [key: string]: Item }
	scopes: { [key: string]: ItemScope }
	scopePropertyName: string
}

interface ScopedItemStoreGetterConfig<T> {
	lifetime: number // the store does not refetch items if they're not older than this many ms
	sortIteratee?: Iteratee<T>
	sortOrder?: SortOrder
	sortCollation?: Collation
}

export const scopedItemStoreGetters = <T>(config: ScopedItemStoreGetterConfig<T>) => ({
	// Tells if the scope's data can be taken as current and correct
	_isClean: (state: State, getters) => (key: string) =>
		getters.isInitialized(key) &&
		state.scopes[key].stale === false &&
		(config.lifetime ? state.scopes[key].timestamp >= Date.now() - config.lifetime : false),

	// sort items in state according to the configured sort settings
	_sort(state: State, getters) {
		if (!config.sortIteratee) {
			return (items) => items
		}

		return (items) => sort(items, config.sortIteratee, config.sortOrder || 'asc', config.sortCollation || 'natural')
	},

	all(state: State, getters): Item[] {
		return getters._sort(Object.values(state.items))
	},

	isInitialized: (state: State, getters) => (key: string) => key in state.scopes && state.scopes[key].initialized,

	isProcessing:
		(state: State, getters) =>
		(key: string): boolean =>
			key in state.scopes && state.scopes[key].processing,

	byId:
		(state: State, getters) =>
		(id: string): Item =>
			state.items[id] || null,

	byScope:
		(state: State, getters) =>
		(key: string): Item[] =>
			key in state.scopes
				? getters._sort(
						// sort results
						Object.values(state.items).filter((item: Item) => state.scopes[key].itemIds.includes(item.id)),
				  )
				: [],

	byProperty:
		(state: State, getters) =>
		(propertyName: string, propertyValue: any): Item[] =>
			getters._sort(
				// sort results
				getters.all.filter((item) => item[propertyName] === propertyValue), // filter items by property
			),

	byProperties:
		(state: State, getters) =>
		(properties: { [key: string]: any }): Item[] =>
			getters._sort(
				// sort results
				getters.all.filter((item) =>
					Object.keys(properties).reduce(
						// filter items by properties
						(res, propertyName) => res && item[propertyName] === properties[propertyName], // property compare reducer
						true,
					),
				),
			),
})

interface ScopedItemStoreMutationConfig<T> {
	sortIteratee?: Iteratee<T>
	sortOrder?: SortOrder
}

export const scopedItemStoreMutations = <T>(config: ScopedItemStoreMutationConfig<T>) => ({
	/**
	 * Adds the item to the store and to its scope
	 */
	addOrUpdateItem(state: State, newItem: Item) {
		// We add the new item to the store
		state.items = {
			...state.items,
			[newItem.id]: newItem,
		}

		// And then we add the new item to the respective scope, if present
		if (newItem[state.scopePropertyName] in state.scopes) {
			state.scopes = {
				...state.scopes,
				[newItem[state.scopePropertyName]]: {
					...state.scopes[newItem[state.scopePropertyName]],
					itemIds: [
						...state.scopes[newItem[state.scopePropertyName]].itemIds,
						newItem.id, // we add the id of the new item id here, that's all we do
					],
				},
			}
		}
	},

	/**
	 * Adds the items to the state
	 */
	setItems(state: State, items: Item[]) {
		const newItems = {}
		items.forEach((item) => (newItems[item.id] = { ...item }))
		state.items = { ...state.items, ...newItems }
	},

	/**
	 * Removes the item from the state
	 */
	removeItems(state: State, ids: string[]) {
		const temp = { ...state.items }
		ids.forEach((id) => delete temp[id]) // we can mutate this new object safely before we put it in the state
		state.items = temp
	},

	/**
	 * Sets a scope with the given properties
	 */
	_setScope(state: State, { key, initialized, processing, itemIds }) {
		// don't mutate the scopes object because of reactivity issues
		state.scopes = {
			...state.scopes,
			[key]: {
				key,
				initialized: initialized || (state.scopes[key] && state.scopes[key].initialized),
				processing,
				timestamp: Date.now(),
				itemIds: [...itemIds],
				stale: false,
			},
		}
	},

	expireScope(state: State, key) {
		// don't mutate the scopes object because of reactivity issues
		state.scopes = {
			...state.scopes,
			[key]: {
				...state.scopes[key],
				stale: true,
			},
		}
	},

	/**
	 * Sets a scope that is loading, eventual initialization status remains
	 */
	setScopeProcessing(state: State, key) {
		// don't mutate the scopes object because of reactivity issues
		state.scopes = {
			...state.scopes,
			[key]: {
				key,
				initialized: key in state.scopes && state.scopes[key].initialized,
				processing: true,
				timestamp: Date.now(),
				stale: false,
				itemIds: key in state.scopes ? [...state.scopes[key].itemIds] : [],
			},
		}
	},
})

interface ScopedItemStoreActionConfig {}

export const scopedItemStoreActions = (config: ScopedItemStoreActionConfig) => ({
	setScopeWithItems(context, payload: { key: string; items: Item[] }) {
		// cleanup items that are not in the scope anymore 123
		const newItemIds = payload.items.map((i) => i.id)
		const itemsToRemove = context.getters.all
			.filter((i) => i[context.state.scopePropertyName] === payload.key && !newItemIds.includes(i.id))
			.map((i) => i.id)
		context.commit('removeItems', itemsToRemove)

		context.commit('setItems', payload.items)
		context.commit('_setScope', {
			key: payload.key,
			initialized: true,
			processing: false,
			itemIds: newItemIds,
		})
	},

	expireScope(context, key) {
		context.commit('expireScope', key)
	},
})
