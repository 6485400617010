
	import Vue from 'vue'
	import { Component, Model, Prop, Watch } from 'nuxt-property-decorator'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import ContractSignatoryChip from '~/components/domain/contract/signatory/ContractSignatoryChip.vue'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import ButtonToggle from '~/components/inputs/ButtonToggle.vue'

	@Component({
		components: {
			ButtonToggle,
			UnitContractPicker,
			SearchInput,
			ContractSignatoryChip,
			SearchLayout,
			BasicDialog,
		},
	})
	export default class MatcherTargetPicker extends Vue {
		@Model('change', { required: true }) visible: boolean
		@Prop({ required: true }) targetOptions: any[]
		@Prop({ default: null }) title: string
		@Prop({ default: null }) selectedFile: any
		@Prop({ default: false }) canAbort: boolean
		@Prop({ default: false }) showAbort: boolean
		@Prop({ type: Object, default: () => {} }) options: any
		@Prop({ type: String, required: true }) objectId: string

		localVisible = false
		searchText: string = ''

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
		}

		get object() {
			return this.$store.getters['property/objects/byId'](this.objectId)
		}

		showAllContracts: boolean = false
	}
