
	import { Component, Prop, Vue, Model } from 'nuxt-property-decorator'
	import { Object } from '~/app/domain/property/Object'
	import Image from '~/app/utils/globals/Image'

	@Component({})
	export default class ObjectLine extends Vue {
		@Prop({ default: 80 }) height: number
		@Model('change', { required: true }) object: Object
		@Prop({ default: false }) noImage: boolean
		@Prop({ type: Boolean, default: false }) provider: boolean

		Image = Image
		get mobile() {
			return this.$vuetify.breakpoint.smAndDown
		}
	}
