
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import AttachmentsLine from '~/components/domain/attachments/AttachmentsLine.vue'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import AddressLine from '~/components/domain/address/AddressLine.vue'
	import { Event } from '~/app/domain/content/Event'
	import DateComponent from '~/components/utils/Date.vue'
	import ContextVisibility from '~/components/utils/ContextVisibility.vue'
	import html2text from '~/app/common/filters/text/Html2text'
	import { PRECISION_DAY, PRECISION_MINUTE } from '~/app/common/filters/Date'
	import RichText from '~/components/utils/RichText.vue'
	import ContextLocation from '~/components/utils/ContextLocation.vue'
	import { Node } from '~/app/domain/content/Common'
	import ContextProcess from '~/components/utils/ContextProcess.vue'
	import ContextGeneric from '~/components/utils/ContextGeneric.vue'
	import PersistedContractSelectionSummary from '~/components/domain/contract/PersistedContractSelectionSummary.vue'

	@Component({
		components: {
			PersistedContractSelectionSummary,
			ContextGeneric,
			ContextProcess,
			ContextLocation,
			RichText,
			ContextVisibility,
			date: DateComponent,
			AddressLine,
			UserLine,
			CardLargeContainer,
			AttachmentsLine,
		},
	})
	export default class EventCard extends Vue {
		@Model('change', { required: true }) value: Event
		@Prop({ default: false }) simple: boolean
		@Prop({ default: '100%' }) width: string
		@Prop({ default: false }) backOffice: boolean
		@Prop({ default: () => [] }) nodes: Node[]

		Image = Image

		get description() {
			return html2text(this.value.description)
		}

		get startDateValue() {
			let value
			if (this.value.fromDate) {
				if (this.value.fromTime)
					value = this.$dateFns.parse(
						`${this.value.fromDate} ${this.value.fromTime}`,
						'yyyy-MM-dd HH:mm',
						new Date(),
					)
				else value = this.$dateFns.parse(`${this.value.fromDate}`, 'yyyy-MM-dd', new Date())
			}

			return value?.toISOString()
		}

		get startDatePrecision() {
			return this.value.fromTime ? PRECISION_MINUTE : PRECISION_DAY
		}
	}
