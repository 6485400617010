
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import DateFt from '~/components/utils/Date.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import sort from '~/app/common/filters/iterator/Sort'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import MultiBtn from '~/components/inputs/MultiBtn.vue'
	import Image from '~/app/utils/globals/Image'

	interface Item {
		id: string
		type: string
		title: string
	}

	@Component({
		components: { MultiBtn, SearchLayout, BasicDialog, SfDate, DateFt },
	})
	export default class FObjectGroupPicker extends Vue {
		@Model('changed', { type: Array, default: () => [] }) value: string[]
		@Prop({ type: Number, default: 50 }) height: number
		@Prop({ type: Boolean, default: false }) hideObjects: boolean
		@Prop({ type: Boolean, default: false }) hideGroups: boolean
		Image = Image

		firstLoad = false
		@Watch('items', { immediate: true }) updateItems() {
			if (this.items && this.isInitialized && !this.firstLoad) {
				this.localValue = this.items.map((i) => {
					return i.id
				})
				this.$emit('changed', [...this.localValue])
				this.$emit('initialized')
				this.firstLoad = true
			}
		}

		localValue: string[] = []
		@Watch('value', { immediate: true })
		valueUpdated() {}

		close() {
			this.$emit('changed', [...this.localValue])
		}

		get btnText() {
			const vl = (this.localValue || []).length
			if (vl > 0 && vl !== this.items.length) return this.$t('layout.nSelected', { count: vl })
			else return this.$tc('domain.object.base', 2)
		}

		get isInitialized() {
			if (
				!this.$store.getters['property/objects/isInitialized'] ||
				!this.$store.getters['property/objectGroups/isInitialized']
			)
				return false
			return true
		}

		get items() {
			// make sure it's initialized
			if (!this.isInitialized) return []

			// return the result
			let result = []

			if (!this.hideObjects) {
				const objects = this.$store.getters['property/objects/all']
				result = result.concat(
					objects.map((o) => {
						return {
							...o,
							type: 'object',
						}
					}),
				)
			}

			if (!this.hideGroups) {
				const objectGroups = this.$store.getters['property/objectGroups/all']
				result = result.concat(
					objectGroups.map((g) => {
						return {
							...g,
							type: 'object-group',
						}
					}),
				)
			}

			return sort(result, ['title'], ['asc'])
		}

		/**
		 * Object / group picker
		 */
		get filteredItems() {
			let filtered = this.items
			if (this.searchText) {
				filtered = search(filtered, this.searchText, {
					fuzzy: true,
					sort: true,
					keys: ['title'],
				})
			}
			return filtered
		}

		pickerDialog: boolean = false
		searchText: string = ''

		selectItem(item) {
			if (!this.localValue) this.localValue = []
			if (this.localValue.includes(item.id)) {
				this.localValue.splice(this.localValue.indexOf(item.id), 1)
			} else {
				this.localValue.push(item.id)
			}
		}

		get filterActive() {
			const activeItems = (this.localValue || []).length
			return activeItems > 0 && activeItems !== this.items.length
		}

		selectAll() {
			this.localValue = [...this.filteredItems.map((i) => i.id)]
		}

		selectNone() {
			this.localValue = []
		}
	}
