import { defer } from 'lodash'
import { UrlString } from '../../domain/Types'

/**
 * Works similar to window.open(), but opens the provided URL with the POST HTTP-method.
 */
export function openWindowPOST(url: UrlString, params: { [key: string]: any }, newWindow = false) {
	const form = document.createElement('form')

	// Setup form element.
	form.setAttribute('method', 'POST')
	form.setAttribute('action', url)
	form.setAttribute('target', newWindow ? '_blank' : '_self')

	// Setup param form children.
	for (const key in params) {
		const value = params[key]

		if (Array.isArray(value)) {
			value.forEach((value) => appendHiddenInputField(form, key + '[]', value))
		} else {
			appendHiddenInputField(form, key, value)
		}
	}

	// Append form to document and submit it.
	document.body.appendChild(form)
	form.submit()

	// Remove form again after current code has executed.
	defer(() => {
		document.body.removeChild(form)
	})
}

function appendHiddenInputField(form: HTMLFormElement, key: string, value: string) {
	const field = document.createElement('input')

	field.setAttribute('type', 'hidden')
	field.setAttribute('name', key)
	field.setAttribute('value', value)

	form.appendChild(field)
}

/**
 * Converts arbitrary json data to a FormData object
 * @see https://github.com/hyperatom/json-form-data
 */
export const appendJsonToFormData = (formData: FormData, key: string, jsonObject, options = {}) => {
	const defaultOptions = {
		showLeafArrayIndexes: true,
		sendEmptyArrays: true, // custom option for property mapper. Sends also empty arrays if they should be reset.
		includeNullValues: false,
		mapping: (v) => (typeof v === 'boolean' ? (v ? '1' : '0') : v),
	}

	const mergedOptions = { ...defaultOptions, ...options }
	const isJsonObject = (val) => {
		return (
			!Array.isArray(val) && typeof val === 'object' && !!val && !(val instanceof Blob) && !(val instanceof Date)
		)
	}
	const convertRecursively = (jsonObject, options, formData, parentKey) => {
		let index = 0

		for (const key in jsonObject) {
			if (jsonObject.hasOwnProperty(key)) {
				let propName = parentKey || key
				const value = options.mapping(jsonObject[key])

				if (parentKey && isJsonObject(jsonObject)) {
					propName = parentKey + '[' + key + ']'
				}

				if (parentKey && Array.isArray(jsonObject)) {
					if (Array.isArray(value) || options.showLeafArrayIndexes) {
						propName = parentKey + '[' + index + ']'
					} else {
						propName = parentKey + '[]'
					}
				}

				if (Array.isArray(value) && options.sendEmptyArrays) {
					formData.append(propName, '')
				}
				if (Array.isArray(value) || isJsonObject(value)) {
					convertRecursively(value, options, formData, propName)
				} else if (value instanceof FileList) {
					for (let j = 0; j < value.length; j++) {
						formData.append(propName + '[' + j + ']', value.item(j))
					}
				} else if (value instanceof Blob) {
					formData.append(propName, value, (value as any).name)
				} else if (value instanceof Date) {
					formData.append(propName, value.toISOString())
				} else if (((value === null && options.includeNullValues) || value !== null) && value !== undefined) {
					formData.append(propName, value)
				}
			}
			index++
		}
		return formData
	}

	return convertRecursively(jsonObject, mergedOptions, formData, key)
}
