import { name } from '../Name'
import { Signatory } from '~/app/domain/property/Contract'

/**
 * renders the provided signatory as a string
 *
 * Falls back to the first present value
 * - name of the attached user, if registered
 * - name of the signatory entity, if set
 * - email address of the signatory entity, if set
 * - empty string
 */
export function signatoryName(signatory: Signatory, formal = true, withSymbol = true): string {
	if (!signatory) {
		return ''
	}

	if (
		(!signatory.user || !signatory.user.isRegistered) &&
		!signatory.firstName &&
		!signatory.lastName &&
		!signatory.salutation
	) {
		return (withSymbol ? signatoryRegistrationStatusSymbol(signatory) + ' ' : '') + (signatory.emailAddress || '')
	}

	const salutation = signatorySalutation(signatory)
	const firstName = signatoryFirstName(signatory)
	const lastName = signatoryLastName(signatory)

	return (
		(withSymbol ? signatoryRegistrationStatusSymbol(signatory) + ' ' : '') +
		name(firstName, lastName, salutation, formal)
	)
}

export function signatorySalutation(signatory: Signatory) {
	return signatory.salutation
}

export function signatoryFirstName(signatory: Signatory) {
	return signatory.firstName || (signatory.user && signatory.user.firstName)
}

export function signatoryLastName(signatory: Signatory) {
	return signatory.lastName || (signatory.user && signatory.user.lastName)
}

export type SignatoryRegistrationStatus = 0 | 1 | 2

/**
 * vue.js filter: tells the registration status of the Signatory
 *
 * registered user: 2
 * invited user: 1
 * otherwise 0
 */
export function signatoryRegistrationStatus(signatory: Signatory): SignatoryRegistrationStatus {
	if (!signatory || !signatory.user) {
		return 0
	}
	if (!signatory.user.isRegistered) {
		return 1
	}

	return 2
}

/**
 * vue.js filter: converts the Signatory registration status into a symbol
 *
 * registered user: ✓
 * invited user: ●
 * otherwise ⨯
 */
export function signatoryRegistrationStatusSymbol(signatory: Signatory, formal: boolean = true): string {
	return {
		0: '⨯',
		1: '●',
		2: '✓',
	}[signatoryRegistrationStatus(signatory)]
}
