
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import { Conversation } from '~/app/domain/communication/Conversation'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import UserListItem from '~/components/domain/user/UserListItem.vue'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import UserPicker from '~/components/domain/user/UserPicker.vue'
	import { MicroUser } from '~/app/domain/identifyAndAccess/User'
	import ConversationScheduleBtn from '~/components/domain/conversation/ConversationScheduleBtn.vue'
	import ProviderPicker from '~/components/domain/provider/ProviderPicker.vue'
	import { Provider } from '~/app/domain/content/ContactPerson'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import NewConversationNotifications from '~/components/domain/conversation/NewConversationNotifications.vue'

	@Component({
		components: {
			NewConversationNotifications,
			DetailLayout,
			UserNotifications,
			ProviderListItem,
			ProviderPicker,
			ConversationScheduleBtn,
			UserPicker,
			RichTextEditor,
			UserListItem,
			ActionButton,
			SearchInput,
			UnitContractPicker,
			BasicDialog,
			Dropzone,
			CategoryContainer,
		},
	})
	export default class ConversationEdit extends Vue {
		@Model('change', { required: true }) conversation: Conversation
		@Prop({ default: null }) nodeId: string
		@Prop({ type: String, required: true }) objectId: string
		@Prop({ type: String, default: null }) contractId: string
		@Prop({ default: false }) newConversation: boolean
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		/**
		 * Contract & object
		 */
		get contract() {
			const cId = this.contractId
			if (cId) {
				return this.$store.getters['property/contracts/byObject'](this.objectId).find((c) => {
					return c.id === cId
				})
			}
		}

		@Watch('contract', { immediate: true }) updateContract() {
			if (this.contract && this.newConversation) {
				for (const signatory of this.contract.signatories) {
					this.addParticipant(signatory)
				}
			}
		}

		get object() {
			return this.$store.getters['property/objects/all'].find((o) => {
				return o.id === this.objectId
			})
		}

		/**
		 * Send conversation
		 */
		get canSend() {
			if (this.newConversation)
				return (
					(this.participants?.length || this.providers?.length) && this.messageText && this.conversation.title
				)
			else return this.conversation.title
		}

		attachments: File[] = []
		dropzoneFilesUpdated(files) {
			this.attachments = files
		}

		important = false
		messageText = ''
		sending = false

		get processId() {
			return this.$route.query.newContentProcess
		}

		async sendConversation() {
			// submit it
			try {
				this.sending = true
				if (!this.newConversation) {
					const response = await this.$axios.$post(
						'etg24:internal:command:communication.conversations.update',
						{
							conversation: {
								id: this.conversation.id,
								title: this.conversation.title,
							},
						},
					)
					this.$emit('updateConversation', response)
					this.$emit('changePage', null)
				} else {
					// build the form data
					const formData = new FormData()
					for (const a of this.attachments || []) {
						formData.append('attachments[]', a)
					}
					formData.set('node', this.contractId || this.nodeId)
					formData.set('title', this.conversation.title)
					formData.set('messageText', this.messageText)
					if (this.sendOn) formData.set('sendOn', new Date(this.sendOn).toISOString())
					// @ts-ignore
					if (this.processId) formData.set('process', this.processId)
					for (const participant of this.participants) formData.append('participants[]', participant.id)
					for (const provider of this.providers) formData.append('providers[]', provider.id)

					// send the request
					const response = await this.$axios.$post(
						'etg24:internal:command:communication.conversations.create',
						formData,
						{
							headers: { 'content-type': 'multipart/form-data' },
						},
					)

					// navigate to the next url
					if (this.processId) {
						await this.$router.push({
							query: {
								...this.$route.query,
								newContent: undefined,
								newContentId: undefined,
								newContentContractId: undefined,
								newContentProcess: undefined,
								contentType: 'process',
								contentId: this.processId,
							},
						})
					} else {
						await this.$router.push({
							query: {
								...this.$route.query,
								newContent: undefined,
								newContentId: undefined,
								newContentContractId: undefined,
								newContentProcess: undefined,
								contentType: 'conversation',
								contentId: response.conversation.id,
							},
						})
					}
					this.$nuxt.$emit('conversationAdded', response.conversation)
				}
			} catch (e) {
				Utils.handleError(e)
				this.sending = false
			}
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 1),
					value: r,
				}
			})
		}

		/**
		 * Add participants
		 */
		participants: any[] = []
		removeParticipant(pIndex) {
			if (pIndex >= 0) this.participants.splice(pIndex, 1)
		}

		addParticipantsDialog = false
		participantSearchText = ''

		addParticipant(signatory) {
			if (
				signatory.user &&
				!this.participants.find((p) => {
					if (p.user && signatory.user && p.user.id === signatory.user.id) return true
					return false
				})
			) {
				this.participants.push({ ...signatory.user })
			}
		}

		addParticipants() {
			this.participants = this.participants.concat(
				this.selectedUsers.filter((u) => !this.participants.find((p) => p.id === u.id)),
			)
			this.addParticipantsDialog = false
			this.selectedUsers = []
		}

		selectedUsers = []
		selectUser(user: MicroUser) {
			if (user) {
				const i = this.selectedUsers.findIndex((u) => u.id === user.id)
				if (i >= 0) {
					this.selectedUsers.splice(i, 1)
				} else {
					this.selectedUsers.push(user)
				}
			}
		}

		setUsers(users: MicroUser[]) {
			this.selectedUsers = users.filter((u) => !!u?.id)
		}

		/**
		 * Add providers
		 */
		providers: Provider[] = []
		addProvidersDialog = false
		addProviders(providers) {
			for (const p of providers) {
				if (!this.providers.find((pr) => pr.id === p.id)) {
					this.providers.push(p)
				}
			}
			this.addProvidersDialog = false
		}

		removeProvider(pIndex) {
			if (pIndex >= 0) this.providers.splice(pIndex, 1)
		}

		/**
		 * Scheduling
		 */
		sendOn: Date = null
	}
