var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list-item',[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.file.parsingState === _setup.FileParsingState.UNREAD || _vm.file.parsingState === _setup.FileParsingState.READING)?_c('v-list-item-avatar',{key:"loading",attrs:{"size":20}},[_c('v-progress-circular',{attrs:{"color":"secondary","size":20,"indeterminate":""}})],1):_c('v-tooltip',{attrs:{"top":"","color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-icon',[(!!_setup.getMatchCertaintyBadgeDotColor)?_c(_setup.UiBadge,{attrs:{"bordered":"","color":_setup.getMatchCertaintyBadgeDotColor,"dot":"","overlap":"","position":"bottom"}},[_c('v-icon',_vm._g({key:"loaded",attrs:{"color":"black"}},on),[_vm._v(_vm._s(_setup.getMatchCertaintyIcon))])],1):_c('v-icon',_vm._g({key:"loaded",attrs:{"color":"black"}},on),[_vm._v(_vm._s(_setup.getMatchCertaintyIcon))])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_setup.getMatchCertaintyTooltip)+"\n\t\t\t")])])],1),_vm._v(" "),_c('v-list-item-content',{key:_vm.file.formdata[_vm.targetArgumentName]},[_c('v-list-item-title',{class:{
				'font-weight-bold': true,
			}},[_vm._v("\n\t\t\t"+_vm._s(_setup.getMultiselectOption?.title ||
				(_setup.isAborted
					? _vm.$t('layout.aborted')
					: _vm.matchDropdownPlaceholder || _vm.$t('domain.batchUpload.matchDropdownPlaceholder')))+"\n\t\t")]),_vm._v(" "),(_setup.getMultiselectOption && _setup.getMultiselectOption.users.length)?_c('v-list-item-title',[_vm._v("\n\t\t\t"+_vm._s(_setup.getMultiselectOption.users.join(', '))+"\n\t\t")]):_vm._e(),_vm._v(" "),_c('v-list-item-subtitle',{class:{
				'aborted-file': _setup.isAborted,
			},staticStyle:{"white-space":"normal"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.file.title)+"\n\t\t")])],1),_vm._v(" "),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":"","color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('preview', _vm.file)}}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('layout.preview'))+"\n\t\t\t")])])],1),_vm._v(" "),_c('v-list-item-action',{staticClass:"!tw-ml-3"},[_c('v-tooltip',{attrs:{"top":"","color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('reassign', _vm.file)}}},on),[_c('v-icon',[_vm._v("fal fa-crosshairs")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.batchFile.reassignTarget'))+"\n\t\t\t")])])],1),_vm._v(" "),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":"","color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-checkbox',_vm._g({attrs:{"input-value":!_setup.isAborted},on:{"click":function($event){return _vm.$emit('toggle', _vm.file)}}},on))]}}])},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.batchUpload.removeAssignment'))+"\n\t\t\t")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }