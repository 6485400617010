
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Model, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { OfferRequest, RequestOfferStrategy, Process } from '~/app/domain/content/Process'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'
	import SfSelect from '~/components/domain/conversation/service_forms/blocks/SfSelect.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import sort from '~/app/common/filters/iterator/Sort'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import MultiBtn from '~/components/inputs/MultiBtn.vue'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import ProviderPicker from '~/components/domain/provider/ProviderPicker.vue'
	import InvoiceRecipient from '~/components/domain/process/InvoiceRecipient.vue'

	@Component({
		components: {
			InvoiceRecipient,
			ProviderPicker,
			Attachments,
			ProviderListItem,
			Dropzone,
			MultiBtn,
			ConfirmationDialog,
			SearchLayout,
			BasicDialog,
			UserLine,
			RichTextEditor,
			SfDate,
			SfSelect,
			SfTextArea,
			SfTextField,
			CategoryContainer,
			DetailLayout,
		},
	})
	export default class ProcessOfferRequestEdit extends Vue {
		@Model('change', { required: false }) process: Process
		@Prop({ required: false }) offerId: string
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		tomorrow = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1), 'yyyy-MM-dd')

		async mounted() {
			await this.fetchProviders()
		}

		offer: OfferRequest | any = {
			providers: [],
			requestOfferStrategy: RequestOfferStrategy.concurrent,
		}

		@Watch('process') updateProcess() {
			// update attachments
			const fOffer = this.process.offerRequests.find((o: any) => o.id === this.offerId)
			if (this.offer && fOffer) {
				this.offer = {
					...this.offer,
					// @ts-ignore
					files: fOffer.files,
					// @ts-ignore
					images: fOffer.images,
				}
			}
		}

		@Watch('offerId', { immediate: true }) updateOfferId() {
			if (this.offerId) {
				const fOffer = this.process.offerRequests.find((o: any) => o.id === this.offerId)
				if (fOffer) {
					this.offer = {
						...fOffer,
						offerExpectedUntil: fOffer.offerExpectedUntil
							? this.$dateFns.format(new Date(fOffer.offerExpectedUntil), 'yyyy-MM-dd')
							: '',
						expectedFulfilmentDate: fOffer.expectedFulfilmentDate
							? this.$dateFns.format(new Date(fOffer.expectedFulfilmentDate), 'yyyy-MM-dd')
							: '',
					}
				}
			}
		}

		get newOffer() {
			return !this.offerId
		}

		get published() {
			return !!this.offer?.requestedOn
		}

		get requestOfferStrategies() {
			return [
				{
					text: this.$t(
						'domain.process.offerRequest.properties.requestOfferStrategy.options.concurrent.label',
					),
					description: this.$t(
						'domain.process.offerRequest.properties.requestOfferStrategy.options.concurrent.description',
					),
					value: RequestOfferStrategy.concurrent,
				},
				{
					text: this.$t(
						'domain.process.offerRequest.properties.requestOfferStrategy.options.sequential.label',
					),
					description: this.$t(
						'domain.process.offerRequest.properties.requestOfferStrategy.options.sequential.description',
					),
					value: RequestOfferStrategy.sequential,
				},
				{
					text: this.$t('domain.process.offerRequest.properties.requestOfferStrategy.options.manual.label'),
					description: this.$t(
						'domain.process.offerRequest.properties.requestOfferStrategy.options.manual.description',
					),
					value: RequestOfferStrategy.manual,
				},
			]
		}

		/**
		 * Providers
		 */
		providers = []

		get currentProviders() {
			return this.providers.filter((p) => {
				return (this.offer.providers || []).includes(p.id)
			})
		}

		async fetchProviders() {
			try {
				this.providers = sort(
					(await this.$axios.$get('etg24:internal:query:providers')).providers,
					['title'],
					['desc'],
				)
				this.filterProviders()
			} catch (e) {
				Utils.handleError(e)
			}
		}

		providerDialog = false

		filterProviders(searchString = '') {
			let filtered = this.providers
			if (searchString) {
				filtered = search(filtered, searchString, {
					fuzzy: true,
					keys: ['title'],
				})
			}
			this.filteredProviders = filtered
		}

		filteredProviders = []

		selectProvider(provider) {
			if (!this.offer.providers.includes(provider.id)) this.offer.providers.push(provider.id)
			else this.offer.providers.splice(this.offer.providers.indexOf(provider.id), 1)
		}

		get allSelected() {
			return this.offer.providers.length === this.providers.length
		}

		/**
		 * Save
		 */
		get canSave() {
			return !!this.offer.title && !!this.offer.description
		}

		saving = false

		getOfferPl() {
			return {
				id: this.newOffer ? undefined : this.offer.id,
				process: this.process.id,
				providers: this.offer.providers,
				title: this.offer.title,
				description: this.offer.description,
				offerExpectedUntil: this.offer.offerExpectedUntil
					? this.$dateFns.parse(this.offer.offerExpectedUntil, 'yyyy-MM-dd', new Date()).toISOString() ||
					  undefined
					: undefined,
				expectedFulfilmentDate: this.offer.expectedFulfilmentDate
					? this.$dateFns.parse(this.offer.expectedFulfilmentDate, 'yyyy-MM-dd', new Date()).toISOString() ||
					  undefined
					: undefined,
				maximumCost: this.offer.maximumCost,
				invoiceRecipient: this.offer.invoiceRecipient,
			}
		}

		async save() {
			this.saving = true
			try {
				const response = await this.$axios.$post(
					this.newOffer
						? 'etg24:internal:command:offers.request.create'
						: 'etg24:internal:command:offers.request.update',
					{
						offerRequest: this.getOfferPl(),
					},
				)
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
			this.saving = false
		}

		/**
		 * Publish
		 */
		get canPublish() {
			return this.canSave && this.offer?.providers.length > 0 && this.offer?.invoiceRecipient
		}

		publishing = false

		async publish() {
			this.publishing = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.publish', {
					offerRequest: this.getOfferPl(),
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
			this.publishing = false
		}

		/**
		 * Remove
		 */

		removeDialog = false

		async remove() {
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.delete', {
					offerRequest: this.offer.id,
				})
				this.$emit('updateProcess', response.process)
				this.$emit('changePage', null)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Attachments
		 */
		async uploadAttachment(file) {
			// build the form
			const formData = new FormData()
			formData.append('file', file)
			formData.set('offerRequest', this.offer.id)

			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:offers.request.attachment.create',
					formData,
					{
						headers: { 'content-type': 'multipart/form-data' },
					},
				)
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}

		async deleteAttachment(file) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:offers.request.attachment.delete', {
					offerRequest: this.offer.id,
					attachment: file.id,
				})
				// update only the given image
				this.$emit('updateProcess', response.process)
			} catch (e) {
				await Utils.handleError(e)
			}
		}
	}
