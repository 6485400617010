/**
 * A function to escape a string to be used in a regular expression
 * @param str - The string to escape
 * @returns The escaped string
 * @complexity O(n) where n is the length of the string
 *
 * @example
 * ```ts
 * escapeRegExpString('hello.world') // returns 'hello\\.world'
 * ```
 *
 * @example
 * ```ts
 * escapeRegExpString('hello*world') // returns 'hello\\*world'
 * ```
 */
export default function escapeRegExpString(str: string) {
	return str.replace(/[-.*+?^${}()|[\]\\/]/g, '\\$&')
}
