import { render, staticRenderFns } from "./VotingSignatoryRequest.vue?vue&type=template&id=b6b0a532&scoped=true"
import script from "./VotingSignatoryRequest.vue?vue&type=script&setup=true&lang=ts"
export * from "./VotingSignatoryRequest.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./VotingSignatoryRequest.vue?vue&type=style&index=0&id=b6b0a532&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6b0a532",
  null
  
)

export default component.exports