import { scopedItemStoreActions, scopedItemStoreGetters, scopedItemStoreMutations } from '~/app/store/ScopedItemStore'
import { PropertyObjectId } from '~/app/domain/property/Object'
import { Unit, UnitId } from '~/app/domain/property/Unit'

export const state = () => ({
	scopes: {},
	items: {},
	scopePropertyName: 'objectId',
})

export const getters = {
	...scopedItemStoreGetters({
		sortIteratee: 'number',
		lifetime: 30e3,
	}),

	byObject: (state, getters) => (objectId: PropertyObjectId) => getters.byProperty('objectId', objectId),
}

export const mutations = {
	...scopedItemStoreMutations({ sortIteratee: 'number' }),
}

export const actions = {
	...scopedItemStoreActions({}),

	async loadForObject(context, objectId: PropertyObjectId) {
		// don't reload if we're already reloading
		if (context.getters.isProcessing(objectId)) {
			return
		}
		// don't reload if we have clean data
		if (context.getters._isClean(objectId)) {
			return
		}

		// set scope processing
		context.commit('setScopeProcessing', objectId)

		const response = await this.$axios.get('etg24:internal:query:propertymanagement.units', {
			params: { object: objectId },
		})
		await context.dispatch('setScopeWithItems', {
			key: objectId,
			items: response.data,
		})
	},

	async fetch(context, unitId: UnitId) {
		const unit = (
			await this.$axios.get('etg24:internal:query:propertymanagement.unit', {
				params: { unit: unitId },
			})
		).data
		context.commit('setItems', [unit])
	},

	async create(context, items) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.units.create', {
			units: items,
		})
		context.commit('setItems', response.data)
	},

	async createDeep(context, data) {
		if (!Array.isArray(data) || !data.length) {
			return
		}
		await this.$axios.post('etg24:internal:command:propertymanagement.units.createdeep', { data: data })

		// reload data affected by the operation
		const objectId = data[0].object
		await context.dispatch('expireScope', objectId)
		await context.dispatch('property/contracts/expireScope', objectId, {
			root: true,
		})
		await Promise.all([
			context.dispatch('loadForObject', objectId),
			context.dispatch('property/contracts/loadForObject', objectId, {
				root: true,
			}),
		])
	},

	async update(context, units: Unit[]) {
		// reduce and transform properties
		const payload = {
			units: units.map((unit) => ({
				id: unit.id,
				address: unit.addressId,
				area: unit.area,
				extId: unit.extId,
				number: unit.number,
				position: unit.position,
				share: unit.share,
				type: unit.type,
				votingWeight: unit.votingWeight,
			})),
		}
		const response = (await this.$axios.post('etg24:internal:command:propertymanagement.units.update', payload))
			.data as Unit[]
		context.commit('setItems', response)
		return response
	},

	async delete(context, itemId: UnitId) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.units.delete', {
			unit: itemId,
		})
		context.commit('removeItems', [itemId])
	},
}
