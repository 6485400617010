
	import { Component, Vue, Prop, Watch, Model } from 'nuxt-property-decorator'
	import UiBadge from '../atoms/UiBadge.vue'

	@Component({
		components: {
			UiBadge,
		},
	})
	export default class BottomSheet extends Vue {
		@Model('change', { required: true }) visible: boolean
		@Prop({ default: 'fal fa-ellipsis-v' }) icon: string
		@Prop({ default: 'white' }) color: string
		@Prop({ default: false }) dark: boolean
		@Prop({ default: 0 }) order: number
		@Prop({ default: 0 }) badgeCount: number

		localVisible = false

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
		}

		get style() {
			return {
				right: `${this.order * 72 + 12}px`,
			}
		}
	}
