
	import Vue from 'vue'
	import { Component, Model, Watch } from 'nuxt-property-decorator'
	import { QrcodeStream } from 'vue-qrcode-reader'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import VueI18n from 'vue-i18n'

	@Component({
		components: {
			ConfirmationDialog,
			BasicDialog,
			QrcodeStream,
		},
	})
	export default class QrReader extends Vue {
		@Model('changed', { type: Boolean }) visible: boolean

		/**
		 * Visibility
		 */
		localVisible = false

		@Watch('visible', { immediate: true })
		updateVisible() {
			this.localVisible = this.visible
		}

		@Watch('localVisible', { immediate: true })
		updateLocalVisible() {
			this.$emit('changed', this.localVisible)
		}

		/**
		 * Decode QR
		 */
		onDecode(decodedString) {
			// remove the url segment to get a local path
			const url = `https://app.${this.$config.appDomain}`
			const path = decodedString.replace(url, '')
			this.$router.push(path)
		}

		error: string | VueI18n.TranslateResult = null
		async onInit(promise) {
			// show loading indicator
			try {
				this.error = null
				await promise
				// successfully initialized
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					// user denied camera access permisson
					this.error = this.$t('components.qrscanner.errors.NotAllowedError.text')
				} else if (error.name === 'NotFoundError') {
					// no suitable camera device installed
					this.error = this.$t('components.qrscanner.errors.NotFoundError.text')
				} else if (error.name === 'NotSupportedError') {
					// page is not served over HTTPS (or localhost)
					this.error = this.$t('components.qrscanner.errors.NotSupportedError.text')
				} else if (error.name === 'NotReadableError') {
					// maybe camera is already in use
					this.error = this.$t('components.qrscanner.errors.NotReadableError.text')
				} else if (error.name === 'OverconstrainedError') {
					// did you requested the front camera although there is none?
					this.error = this.$t('components.qrscanner.errors.OverconstrainedError.text')
				} else if (error.name === 'StreamApiNotSupportedError') {
					// browser seems to be lacking features
					this.error = this.$t('StreamApiNotSupportedError')
				}
			}
		}
	}
