var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._g({ref:"activator",staticClass:"tw-relative",attrs:{"tabindex":"0"}},_setup.events),[_vm._t("trigger"),_vm._v(" "),_c('div',{ref:"tooltipRef",staticClass:"tw-m-0 tw-animate-fade tw-bg-transparent tw-p-0",attrs:{"popover":"manual","role":"tooltip"}},[_c('div',{ref:"fixedElementRef",staticClass:"tw-pointer-events-none tw-fixed tw-max-w-60 tw-p-1 before:tw-absolute before:tw-z-[-10] before:tw-flex before:tw-h-3 before:tw-w-3 before:tw-rotate-45 before:tw-bg-primary-base before:tw-content-[''] md:tw-max-w-96",class:{
				'before:tw-hidden': _setup.position === 'forced',
				'before:tw-bottom-1/2 before:-tw-mb-1.5': _setup.position === 'left' || _setup.position === 'right',
				'before:tw-left-0': _setup.position === 'right',
				'before:tw-right-0': _setup.position === 'left',
				'before:tw-left-1/2 before:-tw-ml-1.5': _setup.position === 'top' || _setup.position === 'bottom',
				'before:tw-bottom-0': _setup.position === 'top',
				'before:tw-top-0': _setup.position === 'bottom',
			},style:(_setup.inset)},[_c('div',{staticClass:"tw-h-max tw-w-max tw-max-w-full tw-text-left tw-rounded-md tw-bg-primary-base tw-text-primary-contrast",class:{
					'tw-px-2 tw-py-1 tw-text-xs': _vm.size === 'sm',
					'tw-px-3 tw-py-1 tw-text-sm': _vm.size === 'md',
				}},[_vm._t("default")],2)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }