
	import Utils from '~/app/utils/globals/Utils'
	import sleepDiff from '~/app/utils/sleepDiff'
	import { Vue, Component, Watch } from 'nuxt-property-decorator'
	import Toolbar from '~/components/navigation/toolbars/FoToolbar.vue'
	import ObjectSelect from '~/components/domain/object/ObjectSelect.vue'
	import LoadingScreen from '~/components/utils/LoadingScreen.vue'
	import FooterDefault from '~/components/navigation/footers/FooterDefault.vue'
	import NavigationDrawer from '~/components/navigation/drawers/FoDrawer.vue'
	import FooterFocused from '~/components/navigation/footers/FooterFocused.vue'
	import BoDrawer from '~/components/navigation/drawers/BoDrawer.vue'
	import BoToolbar from '~/components/navigation/toolbars/BoToolbar.vue'
	import { preloadData } from '~/app/utils/libs/Preload'
	import { setSystemLocale } from '~/app/utils/libs/Locales'
	import EtgApp from '~/components/utils/EtgApp.vue'
	import ConfigPanel from '~/components/utils/ConfigPanel.vue'
	import DetailModal from '~/components/utils/DetailModal.vue'
	import CallRecordModal from '~/modules/call-record/components/CallRecordModal.vue'
	import { parseInputAsE164 } from '~/app/common/filters/Phone'
	import DocumentPreview from '~/components/domain/document/DocumentPreview.vue'
	import NewModal from '~/components/utils/NewModal.vue'
	import EtgSnackbar from '~/components/EtgSnackbar.vue'

	@Component({
		components: {
			NewModal,
			DocumentPreview,
			CallRecordModal,
			DetailModal,
			ConfigPanel,
			EtgApp,
			BoToolbar,
			BoDrawer,
			FooterFocused,
			NavigationDrawer,
			FooterDefault,
			ObjectSelect,
			Toolbar,
			LoadingScreen,
			EtgSnackbar,
			// StatusBanner,
		},
	})
	export default class BackOfficeLayout extends Vue {
		Utils = Utils
		// loading screen
		sTime = +new Date()
		loading: boolean = true
		async mounted() {
			// load locales
			await setSystemLocale.call(this)

			// set loading to false
			await sleepDiff(this.sTime, 1500)
			this.loading = false
		}

		get basePath() {
			return Utils.appPath()
		}

		@Watch('basePath', { immediate: true })
		async basePathChanged() {
			if (this.$auth.loggedIn) await preloadData.call(this)
		}

		/**
		 * Toggle drawer
		 */
		drawerVisible = false
		toggleDrawer() {
			this.drawerVisible = !this.drawerVisible
		}

		configPanelVisible = false

		handleClick(event: Event) {
			// target required
			if (!event.target) {
				return
			}
			const element = event.target as HTMLAnchorElement

			// handle tel: links in property backoffice
			if (
				this.$store.getters['features/isEnabled']('pm:callRecords') &&
				this.$route.path.includes('/property/bo') &&
				element.closest('a[href^="tel:"]')
			) {
				const telephoneElement = element.closest('a[href^="tel:"]') as HTMLAnchorElement

				const number = parseInputAsE164(telephoneElement.href.replace('tel:', ''))
				const contact = telephoneElement.getAttribute('data-contact')

				this.$router.push({
					query: {
						...this.$route.query,
						...(contact && {
							contact: contact,
						}),
						callRecord: 'new',
						callRecordNumber: number,
					},
				})
			}
		}

		/**
		 * Presentation mode
		 */
		get presentationMode() {
			return this.$store.getters['ui/isInPresentationMode']
		}

		deactivatePresentationMode() {
			this.$store.dispatch('ui/deactivatePresentationMode')
		}
	}
