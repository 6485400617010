
	import Image from '~/app/utils/globals/Image'
	import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { isEqual } from 'lodash'
	import {
		Conversation,
		conversationSearchKeys,
		conversationSort,
		ConversationsQueryResponse,
		getWaitingStatus,
		waitingStatus,
	} from '~/app/domain/communication/Conversation'
	import { search } from '~/app/common/filters/iterator/Search'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import ConversationCard from '~/components/domain/conversation/ConversationCard.vue'
	import ButtonToggle from '~/components/inputs/ButtonToggle.vue'
	import SearchSplash from '~/components/utils/SearchSplash.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import Md from '~/components/utils/Md.vue'
	import ListPaginator from '~/components/containers/ListPaginator.vue'
	import FEmployeePicker from '~/components/domain/filters/FEmployeePicker.vue'
	import FObjectGroupPicker from '~/components/domain/filters/FObjectGroupPicker.vue'
	import FStatusSelector from '~/components/domain/filters/FStatusSelector.vue'
	import ConversationDetail from '~/components/domain/conversation/ConversationDetail.vue'
	import FYearPicker from '~/components/domain/filters/FYearPicker.vue'
	import FArchiveFilter from '~/components/domain/filters/FArchiveFilter.vue'

	@Component({
		components: {
			FArchiveFilter,
			FYearPicker,
			ConversationDetail,
			FStatusSelector,
			FObjectGroupPicker,
			FEmployeePicker,
			ListPaginator,
			Md,
			BasicDialog,
			SearchSplash,
			ButtonToggle,
			ConversationCard,
			SearchLayout,
		},
	})
	export default class ConversationList extends Vue {
		@Prop({ default: false }) editable: boolean
		@Prop({ default: false }) standalone: boolean
		@Prop({ default: null }) basePath: string
		@Prop({ type: Boolean, default: false }) helpBtn: boolean
		@Prop({ required: false, default: null }) nodeId: string
		@Prop({ default: null }) userId: string
		@Prop({ type: Boolean, default: false }) pinnedAddBtn: boolean
		@Prop({ type: Boolean, default: false }) hideEmployeeFilter: boolean

		Image = Image

		/**
		 * Global event handling
		 */
		created() {
			this.$nuxt.$on('conversationUpdated', this.conversationUpdated)
			this.$nuxt.$on('conversationDeleted', this.conversationDeleted)
			this.$nuxt.$on('conversationAdded', this.conversationAdded)
		}

		beforeDestroy() {
			this.$nuxt.$off('conversationUpdated')
			this.$nuxt.$off('conversationDeleted')
			this.$nuxt.$off('conversationAdded')
		}

		conversationUpdated(conversation) {
			const cIndex = this.response.conversations.findIndex((c) => c.id === conversation.id)
			if (cIndex >= 0) {
				this.response.conversations.splice(cIndex, 1, conversation)
			}
		}

		conversationDeleted(conversation) {
			const cIndex = this.response.conversations.findIndex((c) => c.id === conversation.id)
			if (cIndex >= 0) {
				this.response.conversations.splice(cIndex, 1)
			}
		}

		conversationAdded(conversation) {
			this.response.conversations.push(conversation)
		}

		/**
		 * Fetch
		 */
		response: ConversationsQueryResponse = null
		loading = true
		async fetchData() {
			this.loading = true

			// work out the node id
			const node = this.nodeId ? this.nodeId : undefined
			const nodeIds = this.nodeId ? undefined : this.filters.nodeIds

			// fetch the response
			try {
				this.response = await this.$axios.$post('etg24:internal:query:communication.conversations', {
					nodeIds,
					node,
					isOpen: !this.filters.archive,
					user: this.filters.selectedEmployee || undefined,
					watcher: this.filters.watcher || undefined,
					fromDate: this.filters.archive && this.filters.year ? `${this.filters.year}-01-01` : undefined,
					toDate: this.filters.archive && this.filters.year ? `${this.filters.year}-12-31` : undefined,
				})
			} catch (e) {}

			this.loading = false
		}

		@Watch('nodeId') async updateNodeId() {
			await this.fetchData()
		}

		/**
		 * Init & loading logic
		 */
		initialized = {
			objects: false,
			teams: false,
		}

		firstLoad = false
		@Watch('initialized', { immediate: true, deep: true })
		async hasInitialized() {
			if (
				!this.firstLoad &&
				(!this.standalone || this.initialized.objects) &&
				(!this.editable || this.hideEmployeeFilter || this.initialized.teams)
			) {
				await this.fetchData()
				this.firstLoad = true
			}
		}

		@Watch('filters', { deep: true }) async updateFilters(val, oldVal) {
			if (this.firstLoad) {
				await this.fetchData()
			}
		}

		cStatusFilter = []
		get states() {
			return [
				{
					text: this.$t('domain.conversation.isWaiting.overdue'),
					value: waitingStatus.overdue,
				},
				{
					text: this.$t('domain.conversation.isWaiting.onResubmission'),
					value: waitingStatus.waiting,
				},
				{
					text: this.$t('domain.conversation.isWaiting.none'),
					value: waitingStatus.none,
				},
			]
		}

		cNeedsAnswerFilter = []
		get needsAnswer() {
			return [
				{
					text: this.$t('domain.conversation.answered'),
					value: 'answered',
				},
				{
					text: this.$t('domain.conversation.unanswered'),
					value: 'needsAnswer',
				},
			]
		}

		/**
		 * Search & filtering logic
		 */
		filters: any = {
			nodeIds: [],
			// @ts-ignore
			selectedEmployee: this.userId || undefined,
			watcher: undefined,
			archive: false,
		}

		get sortedConversations() {
			return this.response ? conversationSort(this.response.conversations) : []
		}

		searchText = ''
		filtering = false
		get filteredConversations() {
			this.filtering = true
			let filtered = this.sortedConversations

			// filter by search text
			if (this.searchText) {
				filtered =
					search(filtered, this.searchText, {
						fuzzy: true,
						sort: true,
						keys: conversationSearchKeys,
					}) || []
			}

			// filter by status
			if (!this.filters.archive && this.cStatusFilter.length) {
				filtered = filtered.filter((conversation: Conversation) =>
					this.cStatusFilter.includes(getWaitingStatus(conversation)),
				)
			}

			if (!this.filters.archive && this.cNeedsAnswerFilter.length) {
				filtered = filtered.filter((c) => {
					if (this.cNeedsAnswerFilter.includes('answered') && !c.needsAnswer) return true
					if (this.cNeedsAnswerFilter.includes('needsAnswer') && c.needsAnswer) return true
					return false
				})
			}

			this.filtering = false
			return filtered
		}

		get openConversations() {
			if (!this.response) return []
			return this.response.conversations.filter((c) => {
				return c.isOpen
			})
		}

		/**
		 * New conversation
		 */
		openConversationsDialog = false
		newConversation() {
			const cPath = `${this.basePath}/service-forms`
			if (this.openConversations.length === 0) this.$router.push(cPath)
			else this.openConversationsDialog = true
		}

		get listeners() {
			return {
				click: this.$listeners?.click,
			}
		}

		/**
		 * Showing conversation
		 */
		showConversation(conversation) {
			this.$emit('click', conversation)
		}
	}
