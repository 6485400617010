/**
 * Parses a value to a boolean
 * @param value The value to parse
 * @returns a boolean
 * @complexity O(1)
 *
 * @example
 * ```ts
 * parseBoolean('ja') // returns true
 * ```
 *
 * @example
 * ```ts
 * parseBoolean('nein') // returns false
 * ```
 * TODO: Refactor to use i18n for parsing truthy/falsy values
 */
export default function parseBoolean(value: unknown) {
	if (value === null) return false
	if (typeof value === 'boolean') return value
	if (typeof value === 'string') {
		return ['ja', 'yes', 'wahr', 'true', '1'].includes(value.toLowerCase())
	}

	return false
}
