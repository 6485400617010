
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import parseBoolean from '~/app/utils/parseBoolean'

	@Component({
		components: {},
	})
	export default class FArchiveFilter extends Vue {
		@Model('changed', { default: '' }) value: number
		@Prop({ type: Number, default: 50 }) height
		@Prop({ type: String, default: 'fArchive' }) queryName

		mounted() {
			const q = this.$route.query[this.queryName]
			if (q && typeof q === 'string') {
				this.$emit('changed', parseBoolean(q))
			}
		}

		localValue = false

		@Watch('value', { immediate: true })
		valueUpdated() {
			if (this.value) this.localValue = parseBoolean(this.value)
		}

		@Watch('localValue', { immediate: true })
		localValueUpdated() {
			this.$emit('changed', this.localValue)
			if (this.localValue !== null) {
				this.$router
					.replace({
						query: {
							...this.$route.query,
							[this.queryName]: this.localValue ? this.localValue.toString() : undefined,
						},
					})
					.catch(() => true)
			}
		}

		get isActive() {
			return !!this.localValue
		}
	}
