
	import sleep from '~/app/utils/sleep'
	import Vue from 'vue'
	import { Component, Model, Watch, Prop } from 'nuxt-property-decorator'
	import RichText from '~/components/utils/RichText.vue'

	@Component({
		components: { RichText },
	})
	export default class ConfirmationDialog extends Vue {
		// keep the visibility synced
		@Model('change', { type: Boolean }) visible: boolean
		@Prop({ default: false, type: Boolean }) persistent: boolean
		@Prop({ default: null }) title: string
		@Prop({ default: null }) text: string
		@Prop({ required: true }) confirmFn: any
		@Prop({ default: 'primary' }) btnColor: string
		@Prop({ default: null }) btnText: string
		@Prop({ default: false }) disabled
		@Prop({ default: true }) cancellable

		localVisible = false

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
		}

		confirming: boolean = false
		async confirm() {
			this.confirming = true
			await this.confirmFn()
			await sleep(1000)
			this.confirming = false
		}
	}
