var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-relative"},[_vm._t("default"),_vm._v(" "),(_vm.visible)?_c('div',{staticClass:"tw-absolute tw-flex tw-justify-center tw-items-center tw-rounded-full tw-left-full tw-transition-colors",class:{
			// 'tw-h-5 tw-min-w-5 tw-p-1': !dot, // this is preferred
			'tw-ring-[1px] tw-ring-inset tw-ring-offset-0': _vm.bordered,
			'tw-h-[20px] tw-min-w-[20px] tw-p-[4px]': !_vm.dot, // remove this when we have some stable rem units
			'tw-w-[10px] tw-h-[10px]': _vm.dot,
			'!-tw-translate-x-full': _vm.dot && _vm.overlap,
			'!-tw-translate-y-full': _vm.dot && _vm.overlap && _vm.position === 'bottom',
			'!tw-translate-y-full': _vm.dot && _vm.overlap && _vm.position === 'default',
			'tw-bottom-full': _vm.position === 'default' && !_vm.overlap,
			'tw-bottom-full tw-translate-y-1/2 -tw-translate-x-1/2': _vm.position === 'default' && _vm.overlap,
			'tw-top-1/2 -tw-translate-y-1/2': _vm.position === 'inline' && !_vm.overlap,
			'tw-top-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2': _vm.position === 'inline' && _vm.overlap, //horizontal overlap wouldn't make sense
			'tw-top-full': _vm.position === 'bottom' && !_vm.overlap,
			'tw-top-full -tw-translate-y-1/2 -tw-translate-x-1/2': _vm.position === 'bottom' && _vm.overlap,
			'tw-bg-background-stronger tw-text-text-base tw-ring-text-base': _vm.color === 'default',
			'tw-bg-primary-base tw-text-primary-contrast tw-ring-primary-contrast': _vm.color === 'primary',
			'tw-bg-accent tw-text-white tw-ring-white': _vm.color === 'accent',
			'tw-bg-success-base tw-text-white tw-ring-white': _vm.color === 'success',
			'tw-bg-error-base tw-text-white tw-ring-white': _vm.color === 'error',
			'tw-bg-warning-base tw-text-white tw-ring-white': _vm.color === 'warning',
			'tw-bg-info-base tw-text-white tw-ring-white': _vm.color === 'info',
			'tw-bg-background-soft tw-text-accent tw-ring-accent': _vm.color === 'light',
		}},[(_vm.icon)?_c(_setup.UiIcon,{staticClass:"before:tw-text-sm",class:{
				'!before:tw-text-primary-contrast !tw-text-primary-contrast': _setup.iconColor === 'primary',
			},attrs:{"color":_setup.iconColor,"icon":_vm.icon}}):(_vm.content)?_c(_setup.UiTypography,{staticClass:"tw-leading-3",attrs:{"variant":"overline"}},[_vm._v(_vm._s(_vm.content))]):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }