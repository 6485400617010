export const state = () => ({})

export const getters = {
	area: (state, getters, rootState, rootGetters) => {
		switch (true) {
			case !rootState.route.name:
				return ''
			case rootState.route.name.startsWith('app-subdomain-property-bo'):
				return 'property/bo'
			case rootState.route.name.startsWith('app-subdomain-property-fo'):
				return 'property/fo'
			case rootState.route.name.startsWith('app-subdomain-provider-bo'):
				return 'provider/bo'
			case rootState.route.name.startsWith('app-subdomain-provider-fo'):
				return 'provider/fo'
			case rootState.route.name.startsWith('app-account'):
				return 'account'
		}
	},

	/* App sections */
	/** When we get a 404 on a route, the route name is undefined,
	 * therefor we rely on the path */

	isBackoffice: (state, getters, rootState, rootGetters) => rootState.route.path.includes('/bo/'),

	isFrontOffice: (state, getters, rootState, rootGetters) => rootState.route.path.includes('/fo/'),

	isProperty: (state, getters, rootState, rootGetters) => rootState.route.path.includes('/property/'),

	isProvider: (state, getters, rootState, rootGetters) => rootState.route.path.includes('/provider/'),

	/* These are just aliases to the respective areas' getters... */

	rootPath: (state, getters, rootState, rootGetters) => rootGetters[getters.area + '/routes/rootPath'],

	pathToLocationNode: (state, getters, rootState, rootGetters) =>
		rootGetters[getters.area + '/routes/pathToLocationNode'],

	pathToProcesses: (state, getters, rootState, rootGetters) => rootGetters[getters.area + '/routes/pathToProcesses'],

	pathToFaqs: (state, getters, rootState, rootGetters) => rootGetters[getters.area + '/routes/pathToFaqs'],

	pathToEvents: (state, getters, rootState, rootGetters) => rootGetters[getters.area + '/routes/pathToEvents'],

	pathToPosts: (state, getters, rootState, rootGetters) => rootGetters[getters.area + '/routes/pathToPosts'],

	pathToDeliveries: (state, getters, rootState, rootGetters) =>
		rootGetters[getters.area + '/routes/pathToDeliveries'],

	pathToVotings: (state, getters, rootState, rootGetters) => rootGetters[getters.area + '/routes/pathToVotings'],
}

export const mutations = {}

export const actions = {}
