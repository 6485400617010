
	import Vue from 'vue'
	import { Component, Prop, Model } from 'nuxt-property-decorator'
	import Image from '~/app/utils/globals/Image'

	@Component({
		components: {},
	})
	export default class ImageInput extends Vue {
		@Prop({ default: () => [] }) errorMessages: string[]
		@Model('change', { required: true }) media: any
		@Prop({ default: null }) placeholder: string
		@Prop({ default: 600 }) height: number
		@Prop({ default: 600 }) width: number
		@Prop({ default: 'pictureInput' }) id: string
		@Prop({ default: false }) deleteable: boolean
		@Prop({ default: false }) contain: boolean

		key: number = 1
		get resolvedMedia() {
			if (this.media) {
				const resolvedMedia = Image.resolveImg(this.media, this.width)
				return resolvedMedia
			} else return null
		}

		get hasMedia() {
			return this.disablePrefill || this.media
		}

		get canDelete() {
			return this.hasMedia && this.deleteable
		}

		disablePrefill: boolean = false
		onChange(image) {
			if (image) {
				this.disablePrefill = true
				this.$emit('loaded', (this.$refs[this.id] as any).file)
			} else {
				this.$emit('delete')
				this.key++
				this.disablePrefill = false
			}
		}
	}
