import { EmailAddressString } from '~/app/domain/Types'
import { AssetAttachments } from '~/app/domain/assets/Attachments'
import { prefixSearchKeys } from '~/app/common/filters/iterator/Search'
import { MicroUser, microUserSearchKeys } from '~/app/domain/identifyAndAccess/User'
import { Conversation } from '~/app/domain/communication/Conversation'
import { Contact, contactSearchKeys } from '~/app/domain/contact/Contact'
import { DateString } from '@shared/types'
import VCard from 'vcf'
import { differenceInMinutes } from 'date-fns'

export enum ProcessingResult {
	newConversation = 'newConversation',
	newConversationMessage = 'newConversationMessage',
}

export interface InboundEmail {
	id: string
	assignees: MicroUser[]
	contact?: Contact
	conversation?: Conversation
	email: Email
	fromEmailAddress: string
	fromName: string
	isArchived: boolean
	isDiscarded: boolean
	parsedOn: DateString
	processedOn: DateString
	processedBy: MicroUser | null
	processedAs: ProcessingResult | null
	recordedOn: DateString
	summary?: string
	tags: string[]
	text?: string
	objectId: string | null
}

interface EmailUser {
	name?: string | null
	emailAddress: EmailAddressString
}

export interface Email {
	id: string
	subject: string
	date: Date | DateString
	from: EmailUser
	to: EmailUser[]
	cc: EmailUser[]
	bcc: EmailUser[]
	replyTo: string | null
	htmlBody: string | null
	textBody: string | null
	attachments: AssetAttachments
}

export interface InboundEmailInbox {
	id: string
	token: string
	title: string
	autoAssignees: boolean
	assignees: MicroUser[]
}

/*
 * Functions
 */
export const emailSearchKeys: (keyof Email)[] = [
	'subject',
	'replyTo',
	'htmlBody',
	'textBody',
	'from.name',
	'from.emailAddress',
	'to.name',
	'to.emailAddress',
	'cc.name',
	'cc.emailAddress',
	'bcc.name',
	'bcc.emailAddress',
] as (keyof Email)[]

export const inboundEmailSearchKeys: (keyof InboundEmail)[] = [
	...(prefixSearchKeys('contact', contactSearchKeys) as (keyof InboundEmail)[]),
	'fromEmailAddress',
	'fromName',
	'summary',
	'text',
	...(prefixSearchKeys('email', emailSearchKeys) as (keyof InboundEmail)[]),
]

export const inboundEmailInboxSearchKeys: (keyof InboundEmailInbox)[] = [
	'title',
	...(prefixSearchKeys('assignees', microUserSearchKeys) as (keyof InboundEmailInbox)[]),
]

export function getEmailAddressForInboundEmailInbox(inboundEmailInbox: InboundEmailInbox) {
	return `inbox+${inboundEmailInbox.token}@${$nuxt.$config.inboundEmailDomain}`
}

export function convertInboundEmailInboxToVCF(inboundEmailInbox: InboundEmailInbox): string {
	const vCard = new VCard()

	// standard name
	vCard.set('n', `${inboundEmailInbox.title};;`)

	// company
	vCard.add('org', 'etg24')

	// email address
	vCard.add('email', getEmailAddressForInboundEmailInbox(inboundEmailInbox))

	return vCard.toString('4.0')
}

export enum InboundEmailStatus {
	recorded = 'recorded',
	parsingFailed = 'parsingFailed',
	parsed = 'parsed',
	processed = 'processed',
}

export const getInboundEmailStatus = (inboundEmail: InboundEmail) => {
	if (!inboundEmail.fromEmailAddress) {
		// if the inbound email has been recorded more than 5 minutes ago and still has no fromEmailAddress, it's a failure
		// calculate using date-fns
		if (differenceInMinutes(new Date(), new Date(inboundEmail.recordedOn)) > 5) {
			return InboundEmailStatus.parsingFailed
		}
		return InboundEmailStatus.recorded
	}
	if (!inboundEmail.conversation) {
		return InboundEmailStatus.parsed
	}
	return InboundEmailStatus.processed
}

export const getInboundEmailStatusIcon = (inboundEmail: InboundEmail) => {
	switch (getInboundEmailStatus(inboundEmail)) {
		case InboundEmailStatus.recorded:
			return 'fal fa-spinner'
		case InboundEmailStatus.parsingFailed:
			return 'fal fa-exclamation-triangle'
		case InboundEmailStatus.parsed:
			return 'fal fa-envelope'
		case InboundEmailStatus.processed:
			return 'fal fa-check-circle'
	}
}

export const inboundEmailActions = (inboundEmail: InboundEmail, handler: (e: string, id: string) => void) => {
	const emailStatus = getInboundEmailStatus(inboundEmail)

	return [
		{
			text: 'concept.archive.actions.moveToArchive.label',
			icon: 'fal fa-archive',
			callback: () => handler('archive', inboundEmail.id),
			render:
				!inboundEmail.isArchived &&
				!inboundEmail.isDiscarded &&
				[InboundEmailStatus.parsed, InboundEmailStatus.processed].includes(emailStatus),
		},
		{
			text: 'concept.archive.actions.restoreFromArchive.label',
			icon: 'fal fa-archive',
			callback: () => handler('unarchive', inboundEmail.id),
			render: inboundEmail.isArchived && emailStatus !== InboundEmailStatus.processed,
		},
		{
			text: 'concept.bin.actions.moveToBin.label',
			icon: 'fal fa-trash',
			callback: () => handler('discard', inboundEmail.id),
			render:
				!inboundEmail.isArchived && !inboundEmail.isDiscarded && emailStatus !== InboundEmailStatus.processed,
		},
		{
			text: 'layout.delete',
			icon: 'fal fa-trash-alt',
			callback: () => handler('delete', inboundEmail.id),
			render: inboundEmail.isDiscarded,
		},
		{
			text: 'concept.bin.actions.restore.label',
			icon: 'fal fa-trash-restore',
			callback: () => handler('undiscard', inboundEmail.id),
			render: inboundEmail.isDiscarded,
		},
	]
}
