import { DateString, FileExtension, FileName, FileSize, MediaType, Sha1Hash } from '../Types'
import { AssetId, AssetUrl } from './/Asset'
import { Entity, Identifiable } from '../common/Interfaces'
import { User } from '../identifyAndAccess/User'

export interface FileAsset extends Entity<AssetId> {
	name: FileName & FileExtension
	// sha1: Sha1Hash, not in use
	type: MediaType
	size: FileSize
	url: AssetUrl | null
}

export interface AccessibleFileAsset extends FileAsset {
	url: AssetUrl
}

export const fileAssetSearchKeys: (keyof FileAsset)[] = ['name']

export interface FileDownload extends Identifiable {
	downloadedOn: DateString
	file: FileAsset | null
	user: User | null
}
