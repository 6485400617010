
	import Messages from '~/app/utils/globals/Messages'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import { Post } from '~/app/domain/content/Post'
	import NotesList from '~/components/domain/notes/NotesList.vue'
	import { translateContractSelectionSummary } from '~/app/domain/property/Contract'
	import ContentLocationAction from '~/components/domain/location/ContentLocationAction.vue'

	@Component({
		components: {
			ContentLocationAction,
			NotesList,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
		},
	})
	export default class PostActions extends Vue {
		@Model('change', { required: true }) post: Post
		@Prop({ type: Array, default: () => [] }) nodes: Node[]

		get actions() {
			return [
				{
					icon: 'fal fa-pencil',
					callback: () => {
						this.$emit('changePage', 'editPost')
					},
					attributes: {
						'data-qa': 'post-actions:edit',
					},
				},
				{
					callback: () => {
						this.deleteDialog = true
					},
					icon: 'fal fa-trash',
					color: 'error',
					attributes: {
						'data-qa': 'post-actions:delete',
					},
				},
			]
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 2),
					value: r,
				}
			})
		}

		get visibilityData() {
			let selected = []
			if (this.post?.contractIds?.length) {
				selected = [...selected, 'contracts']
			}
			selected = [...selected, ...this.post.roleTypes]
			return {
				selected: selected,
				options: [
					{
						text: translateContractSelectionSummary(this.post.contractSummary, this),
						value: 'contracts',
					},
					...this.roles,
				],
			}
		}

		/**
		 * Delete logic
		 */
		deleteDialog: boolean = false
		async deletePost() {
			try {
				await this.$axios.$post('etg24:internal:command:content.delete', {
					ids: [this.post.id],
				})
				this.$emit('deleted')
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		deleteText: string = ''
	}
