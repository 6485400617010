
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import { Post, vvPost } from '~/app/domain/content/Post'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import SfTime from '~/components/domain/conversation/service_forms/blocks/SfTime.vue'
	import EmailsInput from '~/components/inputs/EmailsInput.vue'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import InfoBtn from '~/components/domain/help/InfoBtn.vue'
	import CalculatedContractSelectionSummary from '~/components/domain/contract/CalculatedContractSelectionSummary.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import Md from '~/components/utils/Md.vue'
	import { appendJsonToFormData } from '~/app/common/util/ApiUtil'
	import ContractBasedVisibility from '~/components/domain/contract/ContractBasedVisibility.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import SfDateTime from '~/components/domain/conversation/service_forms/blocks/SfDateTime.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'

	@Component({
		components: {
			DetailCard,
			DetailLayout,
			SfDateTime,
			UserNotifications,
			ContractBasedVisibility,
			Md,
			ActionButton,
			UnitContractPicker,
			BasicDialog,
			CalculatedContractSelectionSummary,
			InfoBtn,
			RichTextEditor,
			Dropzone,
			EmailsInput,
			SfTime,
			SfDate,
			CategoryContainer,
		},
	})
	export default class PostEdit extends Vue {
		@Model('change', { required: true, type: Object }) post: Post
		@Prop({ default: false }) newPost: boolean
		@Prop({ default: null }) nodeId: string
		@Prop({ type: Object, default: null }) parent: any
		Image = Image

		pinnedUntil = ''
		selectedContracts = []
		validators = vvPost
		today: string = this.$dateFns.format(new Date(), 'yyyy-MM-dd')
		roleTypes = []
		notifyUsersAboutChange = false

		get pinnedUntilMin() {
			let rDate = null
			const todayDate = this.$dateFns.parse(this.today, 'yyyy-MM-dd', new Date())
			if (this.post.publishDate) {
				const publishDate = this.$dateFns.parseISO(this.post.publishDate)
				rDate = this.$dateFns.isAfter(todayDate, publishDate) ? todayDate : publishDate
			} else {
				rDate = todayDate
			}
			return this.$dateFns.format(rDate, 'yyyy-MM-dd')
		}

		mounted() {
			if (this.post.pinnedUntil) {
				this.pinnedUntil = this.$dateFns.format(
					this.$dateFns.parseISO(this.post.pinnedUntil) as any,
					'yyyy-MM-dd',
				)
			}
			this.roleTypes = this.post.roleTypes || []
			this.selectedContracts = this.post.contractIds || []
		}

		/**
		 * Contracts & roles
		 */
		get hasContractVisibility() {
			return this.newPost
				? !!this.$store.getters['property/objects/byId'](this.nodeId)
				: this.post.hasContractVisibility
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 1),
					value: r,
				}
			})
		}

		/**
		 * Send logic
		 */
		get canSend() {
			// roles OR contract ids have to be defined
			if ((this.roleTypes || [])?.length === 0 && this.selectedContracts.length === 0) {
				return false
			}

			return !!this.post.title && (this.post.isListed || this.post.shouldNotify) && !!this.post.description
		}

		filesToSend: File[] = []
		sending = false
		async send() {
			this.sending = true
			try {
				let response
				const post = {
					title: this.post.title,
					description: this.post.description,
					listed: this.post.isListed,
					roleTypes: this.roleTypes,
					shouldNotify: this.post.shouldNotify,
					additionalReceivers: this.post.additionalReceivers || [],
					publishDate: this.post.publishDate,
					pinnedUntil: this.pinnedUntil,
					contractIds: this.hasContractVisibility ? this.selectedContracts : undefined,
				}

				// config the form data
				const formData = new FormData()
				const config = {
					headers: { 'content-type': 'multipart/form-data' },
				}
				if (!this.newPost) {
					formData.set('important', this.notifyUsersAboutChange ? '1' : '0')
					appendJsonToFormData(formData, 'post', {
						...post,
						id: this.post.id,
					})
					response = await this.$axios.$post('etg24:internal:command:content.posts.update', formData, config)
					this.$emit('updatePost', response.post)
					this.$emit('changePage', null)
				} else {
					appendJsonToFormData(formData, 'post', {
						...post,
						parentNode: this.nodeId,
					})
					for (const f of this.filesToSend) formData.append('files[]', f)
					response = await this.$axios.$post('etg24:internal:command:content.posts.create', formData, config)
					await this.$router.push({
						query: {
							...this.$route.query,
							newContent: undefined,
							newContentId: undefined,
							contentType: 'post',
							contentId: `${response?.post.id}_${this.nodeId}`,
							contentPage: undefined,
						},
					})
					this.$nuxt.$emit('postAdded', response.post)
				}
			} catch (e) {
				Utils.handleError(e)
			}
			this.sending = false
		}
	}
