import type { DeepKeyOf } from '@shared/types/helpers'
import { get, orderBy as alphabeticalOrderBy, sortBy } from 'lodash'
import { orderBy as naturalOrderBy } from 'natural-orderby'
import naturalSort from 'natural-sort'

export type SortOrder = 'asc' | 'desc'

export type Iteratee<T> = DeepKeyOf<T> | ((T) => string) | (DeepKeyOf<T> | ((T) => string))[]
/**
 * Sorts an array of items
 *
 * @param items the items to sort
 * @param iteratee the items' value by which to sort. Can be an array of iteratees, orders need to be an array then as well
 * @param order asc|desc
 * @param collation the sorting strategy
 * @returns the array of sorted items
 *
 * @example
 * sort( [...])
 * sort( [...], 'title')
 * sort( [...], 'child.createdOn', 'desc')
 * sort( [...], address => `${address.street} ${address.houseNumber}`, 'asc', 'natural')
 * sort( [...], ['name', 'size'], ['asc', 'desc'])
 */
export default function sort<T>(
	items: T[],
	iteratee: Iteratee<T> = null,
	order: SortOrder | SortOrder[] = 'asc',
	collation: Collation = 'alphabetical',
): T[] {
	// natural sort...
	if (collation === 'natural') {
		// natural-orderby handles multiple iteratees well, but refuses to sort if the iteratee is strings that contain alphabetical characters
		// natural-sort works well with all kinds of strings, but makes errors with multiple iteratees
		// so we use the least buggy one...

		if (Array.isArray(iteratee) && iteratee.length > 1) {
			return naturalOrderBy(items, iteratee as any, order)
		} else {
			const configuredNaturalSort = naturalSort({ direction: order })
			// ... with iteratee
			if (iteratee) {
				const iterateeAwareConfiguredNaturalSort = (a, b) =>
					configuredNaturalSort(stringifyWithIteratee(a, iteratee), stringifyWithIteratee(b, iteratee))

				return items.sort(iterateeAwareConfiguredNaturalSort)
			}

			// ... without iteratee
			return items.sort(configuredNaturalSort)
		}
	}

	// alphabetical sort...
	// ... with iteratee
	if (iteratee) {
		return alphabeticalOrderBy(items, iteratee, order)
	}

	// ... without iteratee
	return sortBy(items)
}

/**
 * Transforms an object into its string representation
 */
function stringifyWithIteratee<T>(subject: Object, iteratee: Iteratee<T> = null): string {
	if (!iteratee) {
		return subject.toString()
	}

	if (typeof iteratee === 'string') {
		return get(subject, iteratee)
	}

	if (typeof iteratee === 'function') {
		return (iteratee as (item) => string)(subject)
	}

	if (Array.isArray(iteratee)) {
		if (iteratee.length === 0) {
			return subject.toString()
		}

		return iteratee.reduce((stringRepresentation: string, currentIteratee) => {
			if (typeof currentIteratee === 'string') {
				return stringRepresentation + get(subject, currentIteratee as string)
			}

			if (typeof currentIteratee === 'function') {
				return stringRepresentation + (currentIteratee as (item) => string)(subject)
			}
		}, '') as string
	}
}

export type Collation = 'alphabetical' | 'natural'
