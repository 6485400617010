export enum SnackbarType {
	Success = 'success',
	Error = 'error',
	Warning = 'warning',
	Info = 'info',
}

export interface Snackbar {
	message: string
	id: number
	type: SnackbarType
	dismissable?: boolean
}

export default class SnackbarService {
	private static snackbars: Snackbar[] = []

	static init() {}

	static info(msg: string, duration = 10000, dismissable = true) {
		this.display(msg, duration, SnackbarType.Info, dismissable)
	}

	static success(msg: string, duration = 5000, dismissable = false) {
		this.display(msg, duration, SnackbarType.Success, dismissable)
	}

	static error(msg: string, duration = 10000, dismissable = true) {
		this.display(msg, duration, SnackbarType.Error, dismissable)
	}

	static warning(msg: string, duration = 5000, dismissable = true) {
		this.display(msg, duration, SnackbarType.Warning, dismissable)
	}

	private static display(msg: string, duration: number, type: SnackbarType, dismissable = true) {
		// Create a new snackbar
		const snackbar: Snackbar = { message: msg, type, id: Date.now(), dismissable }

		// Add it to the array
		this.snackbars.push(snackbar)

		this.emit()

		// Remove it after the duration
		setTimeout(() => {
			this.removeSnackbar(snackbar.id)
		}, duration)
	}

	static removeSnackbar(id: number) {
		this.snackbars = this.snackbars.filter(({ id: sId }) => sId !== id)
		this.emit()
	}

	private static emit() {
		window.dispatchEvent(new Event('snackbar'))
	}

	static getSnackbars() {
		return this.snackbars
	}
}
