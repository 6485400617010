
	import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import { getNodeBreadcrumbs, Node, NodeId } from '~/app/domain/content/Common'

	@Component({
		components: { ActionButton },
	})
	export default class ContentLocationAction extends Vue {
		@Prop({ required: true, type: Array }) nodes: Node[]
		@Prop({ required: true, type: String }) nodeId: NodeId

		get breadcrumbs() {
			if (this.nodes.length)
				return getNodeBreadcrumbs(this.$store.getters['tenant/rootNodeId'], this.nodeId, this.nodes).map(
					(node) => ({
						node,
						uri: this.$store.getters['property/bo/routes/pathToLocationNode'](node.id, this.nodes),
					}),
				)
		}
	}
