export enum ScopeType {
	'content.post' = 'content.post',
	'content.process' = 'content.process',
	'content.event' = 'content.event',
	'communication.conversation' = 'communication.conversation',
	'communication.delivery' = 'communication.delivery',
	'communication.voting' = 'communication.voting',
	'communication.votingItem' = 'communication.votingItem',
	'contact' = 'contact',
}
