import { render, staticRenderFns } from "./VotingMeetingProtocol.vue?vue&type=template&id=15ee2e89&scoped=true"
import script from "./VotingMeetingProtocol.vue?vue&type=script&setup=true&lang=ts"
export * from "./VotingMeetingProtocol.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./VotingMeetingProtocol.vue?vue&type=style&index=0&id=15ee2e89&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ee2e89",
  null
  
)

export default component.exports