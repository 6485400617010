import Auth from './auth';
import './middleware';

// Active schemes
import scheme_003d9a64 from './schemes/local.js';
export default function (ctx, inject) {
  // Options
  var options = {
    "resetOnError": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": true,
    "watchLoggedIn": true,
    "redirect": {
      "login": false,
      "logout": false,
      "home": false,
      "callback": "/login"
    },
    "vuex": {
      "namespace": "auth"
    },
    "cookie": {
      "prefix": "auth.",
      "options": {
        "path": "/"
      },
      "name": "nuxt-auth"
    },
    "localStorage": {
      "prefix": "auth."
    },
    "token": {
      "prefix": "_token.",
      "type": "Bearer",
      "name": "etg24_session"
    },
    "refresh_token": {
      "prefix": "_refresh_token."
    },
    "defaultStrategy": "local"
  };

  // Create a new Auth instance
  var $auth = new Auth(ctx, options);

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {
    "endpoints": {
      "login": {
        "url": "etg24:auth:authenticate",
        "method": "post",
        "propertyName": "etg24_session"
      },
      "logout": false,
      "user": {
        "url": "etg24:auth:user",
        "method": "get",
        "propertyName": "user"
      }
    },
    "_name": "local"
  }));

  // Inject it to nuxt context as $auth
  inject('auth', $auth);
  ctx.$auth = $auth;

  // Initialize auth
  return $auth.init().catch(function (error) {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error);
    }
  });
}