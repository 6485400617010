/**
 * This is a file that's supposed to run on client init
 * to set the screen orientation and other setup tasks
 */
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'

/** Lock screen orientation to portrait on small screens
 * This only works on Android https://capacitorjs.com/docs/apis/screen-orientation#ios
 */
if (Capacitor.getPlatform() === 'android') {
	const lockScreenOrientation = async () => {
		const hasSmallScreen = Math.min(window.innerWidth, window.innerHeight) < 640 // 640 is the minimum height for a viewport to make sense in landscape mode (empirical)
		if (hasSmallScreen) {
			// lock to portrait
			console.log('Locking to portrait')
			// https://capacitorjs.com/docs/apis/screen-orientation#lock
			await ScreenOrientation.lock({
				orientation: 'portrait',
			})
		} else {
			await ScreenOrientation.unlock()
		}
	}
	lockScreenOrientation()
	// todo: this should be on resize, but there's no event for it yet https://capacitorjs.com/docs/apis/screen-orientation
	ScreenOrientation.addListener('screenOrientationChange', lockScreenOrientation)
}

/**
 * Handle deep links
 * https://capacitorjs.com/docs/guides/deep-links
 */
App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
	// Example url: https://app.etg24.de/app/preisser/property/bo/
	const slug = event.url
		.split(
			'app.etg24.de', // this is hardcoded, because the deelink host is hardcoded in the capacitor/native config
		)
		.pop()

	// We only push to the route if there is a slug present
	if (slug) {
		// @ts-ignore because nuxt has a $router property, but it's not in the types
		$nuxt.$router.push({
			path: slug,
		})
	}
})

export const getters = {}
export const mutations = {}
export const actions = {}
