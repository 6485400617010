import VCard from 'vcf'
import { EmailAddressString, ShortString, UrlString, Uuid } from '../Types'

import { Address, vvAddress } from '../common/Address'
import { name } from '~/app/common/filters/Name'
import { ISO_3166_1 } from '~/app/common/static/ISO_3166_1'
import { PhoneNumber, libPhoneNumberToVCardTypeMap } from '~/app/domain/common/PhoneNumber'
import { User, userSearchKeys } from '~/app/domain/identifyAndAccess/User'
import { PropertyObject } from '~/app/domain/property/Object'
import { Unit } from '~/app/domain/property/Unit'
import { Contract, Signatory } from '~/app/domain/property/Contract'
import { RoleType } from '~/app/domain/identifyAndAccess/Role'
import { Responsibility } from '~/app/domain/content/ContactPerson'
import { Category } from '~/app/domain/content/Category'
import { prefixSearchKeys } from '~/app/common/filters/iterator/Search'
import { DeepKeyOf, DeepNotNullableObject } from '@shared/types/helpers'
import { ImageAsset } from '~/app/domain/assets/Image'

/*
 * Model
 */

export type ContactId = Uuid

export interface BaseContact {
	id: ContactId
	legalPersonHash: string
	user: User | null
	personType: 'natural' | 'legal'
	deliveryInstructions: ShortString | null
	address: Address | null
	emailAddress: EmailAddressString | null
	secondaryEmailAddresses: EmailAddressString[]
	phoneNumbers: PhoneNumber[]
}

export interface NaturalContact extends BaseContact {
	personType: 'natural'
	salutation: ShortString | null
	firstName: ShortString | null
	lastName: ShortString | null
}

export interface LegalContact extends BaseContact {
	personType: 'legal'
	companyName: ShortString
	avatar: ImageAsset | null
	url: UrlString | null
}

export type Contact = NaturalContact | LegalContact
export type AnyContact = Omit<NaturalContact, 'personType'> &
	Omit<LegalContact, 'personType'> & { personType: 'natural' | 'legal' }

export interface ContactDuplicate {
	reason: string
	label: string
	data: string
	duplicatesCount: number
}

/*
 * Validation
 */

export const vvUser = {
	address: vvAddress,
	phoneLandline: '',
	phoneMobile: '',
	phoneWork: '',
	locale: '',
	firstName: 'required|min:2',
	lastName: 'required|min:2',
	emailAddress: 'required|email',
	image: '',
}

export const vvContact = {
	firstName: '',
	lastName: '',
	salutation: '',
	emailAddress: 'email',
}

/*
 * Search
 */
export const contactSearchKeys: DeepKeyOf<DeepNotNullableObject<AnyContact>>[] = [
	'salutation',
	'firstName',
	'lastName',
	'companyName',
	'deliveryInstructions',
	'address',
	'emailAddress',
	'secondaryEmailAddresses',
	'phoneNumbers',
	'url',
	...prefixSearchKeys('user', userSearchKeys),
]

/*
 * Api
 */
export function convertContactToVCF({ address, phoneNumbers, emailAddress, ...rest }: Contact): string {
	const vCard = new VCard()

	if (address) {
		vCard.set(
			'adr',
			`;;${address.street} ${address.houseNumber};${address.city};;${address.zip};${ISO_3166_1[address.country]}`,
			{ type: 'work' },
		)
	}
	phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
		vCard.add('tel', phoneNumber.number, {
			type: libPhoneNumberToVCardTypeMap[phoneNumber.type],
		})
	})
	if (emailAddress) {
		vCard.add('email', emailAddress)
	}

	if (rest.personType === 'natural') {
		// full name
		vCard.set('fn', [rest.firstName, rest.lastName].filter((name) => name).join(' '))
		// standard name
		vCard.set('n', `${rest.lastName};${rest.firstName};;`)
	} else {
		vCard.set('fn', rest.companyName)
		vCard.set('org', rest.companyName)
	}

	return vCard.toString('4.0')
}

export function contactTitle(contact: Contact): string {
	if (!contact) {
		return ''
	}

	if (contact.personType === 'legal') {
		return contact.companyName ?? contact.emailAddress ?? ''
	}

	const contactName = name(contact.firstName, contact.lastName, contact.salutation, false)
	return contactName || contact.emailAddress || ''
}

export const getContactMailingAddress = (contact: NaturalContact) => {
	const firstName = contact.firstName
	const lastName = contact.lastName
	const address = contact.address
	if (!firstName || !lastName || !address) {
		return null
	}
	let mailingAddress = [contact.salutation, firstName, lastName].filter((v) => !!v).join(' ')
	if (contact.deliveryInstructions) {
		mailingAddress += `\n${contact.deliveryInstructions}`
	}
	mailingAddress += `\n${address.street} ${address.houseNumber}`
	mailingAddress += `\n${address.zip} ${address.city}`
	return mailingAddress
}

export const getContactUserNote = (
	contact: Contact,
): {
	icon: string
	messageKey: string
	messageArgs: { [key: string]: unknown }
} | null => {
	const notes = {
		// error: {
		// 	icon: 'blind',
		// 	messageKey: 'domain.contact.profileNote.error',
		// 	messageArgs: {},
		// },
		// noObjectUserAccess: {
		// 	icon: 'ban',
		// 	messageKey:
		// 		'domain.contact.profileNote.noObjectUserAccess',
		// 	messageArgs: {},
		// },
		// pastContract: {
		// 	icon: 'hourglass-end',
		// 	messageKey: 'domain.contact.profileNote.pastContract',
		// 	messageArgs: {},
		// },
		// futureContractInvitation: {
		// 	icon: 'calendar-alt',
		// 	messageKey:
		// 		'domain.contact.profileNote.futureContractInvitation',
		// 	messageArgs: { emailAddress: transientSignatory.emailAddress },
		// },
		noEmailAddressGiven: {
			icon: 'at',
			messageKey: 'domain.contact.profileNote.noEmailAddressGiven',
			messageArgs: {},
		},
		// signatoryAccessExpired: {
		// 	icon: 'hourglass-end',
		// 	messageKey:
		// 		'domain.contact.profileNote.signatoryAccessExpired',
		// 	messageArgs: {},
		// },
		// invitationWillBeSent: {
		// 	icon: 'paper-plane',
		// 	messageKey:
		// 		'domain.contact.profileNote.invitationWillBeSent',
		// 	messageArgs: { emailAddress: transientSignatory.emailAddress },
		// },
		invitationSent: {
			icon: 'paper-plane',
			messageKey: 'domain.contact.profileNote.invitationSent',
			messageArgs: { emailAddress: contact.emailAddress },
		},
		noInvitationSent: {
			icon: 'paper-plane',
			messageKey: 'domain.contact.profileNote.noInvitationSent',
			messageArgs: {},
		},
	}

	if (contact.user) {
		if (!contact.user.isRegistered) {
			return notes.invitationSent
		} else {
			// no note necessary
			return null
		}
	}
	if (!contact.emailAddress) {
		return notes.noEmailAddressGiven
	}
	if (contact.emailAddress && !contact.user) {
		return notes.noInvitationSent
	}

	// until here, all reasons to not have the user added are checked, so we'll invite the user
	// if (!persistedSignatory.user) {
	// 	return notes.invitationWillBeSent
	// }

	// there is a user, so show their profile
	return null
}

export interface ContactObjectData {
	object: PropertyObject
	units: {
		[key: string]: {
			unit: Unit
			contracts: {
				[key: string]: {
					contract: Contract
					signatories: Signatory[]
				}
			}
		}
	}
	roleTypes: RoleType[]
	responsibilities: {
		responsibility: Responsibility
		category: Category
	}[]
}

export interface ContactWithObjectsData {
	contact: Contact
	objects: { [key: string]: ContactObjectData }
}
