import { render, staticRenderFns } from "./ObjectCard.vue?vue&type=template&id=532c1e98&scoped=true"
import script from "./ObjectCard.vue?vue&type=script&lang=ts"
export * from "./ObjectCard.vue?vue&type=script&lang=ts"
import style0 from "./ObjectCard.vue?vue&type=style&index=0&id=532c1e98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532c1e98",
  null
  
)

export default component.exports