
	import Image from '~/app/utils/globals/Image'
	import { Component, Prop, Vue, Model, Watch } from 'nuxt-property-decorator'
	import { Unit } from '~/app/domain/property/Unit'

	@Component({})
	export default class UnitCard extends Vue {
		@Model('change', { required: true }) unit: Unit
		@Prop({ default: null }) to: string
		@Prop({ default: false }) checkboxMode: boolean
		@Prop({ default: false }) noExt: boolean
		@Prop({ default: false }) selected: boolean
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: false }) noIcon: boolean
		Image = Image

		get mobile() {
			return this.$vuetify.breakpoint.smAndDown
		}

		@Watch('selected')
		updateSelected() {
			this.$emit('selected', this.selected)
		}
	}
