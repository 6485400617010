import { DateString, DayString, HtmlString, ShortString, Uuid } from '../Types'
import { Entity } from '../common/Interfaces'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { MicroUser } from '../identifyAndAccess/User'
import { Attachments } from '../assets/Attachments'
import { RoleType } from '../identifyAndAccess/Role'
import { Validators } from '../../common/validation/Validators'
import { Content, ContentId, Node } from './Common'
import sort from '~/app/common/filters/iterator/Sort'
import { Provider, ProviderId } from '~/app/domain/content/ContactPerson'
import { ConversationId, ConversationListView } from '~/app/domain/communication/Conversation'
import { EventListView } from '~/app/domain/content/Event'
import { Contract, ContractId, signatoryName } from '~/app/domain/property/Contract'
import { Object as ObjectType, ObjectAddress, ObjectManagementType } from '~/app/domain/property/Object'

/*
 * Model
 */

export type ProcessId = ContentId

export type ProcessStatus = keyof typeof ProcessStatuses
export enum ProcessStatuses {
	waiting = 'waiting',
	in_progress = 'in_progress',
	completed = 'completed',
}

export type ProcessUpdateId = Uuid
export interface ProcessUpdate extends Entity<ProcessUpdateId> {
	publishedOn: DateString
	author: MicroUser | null
	description: HtmlString
	isInternal: boolean
	oldStatus: ProcessStatus
	newStatus: ProcessStatus | null
}

export type OfferRequestId = Uuid
export enum OfferRequestStatus {
	draft = 'draft',
	active = 'active',
	cancelled = 'cancelled',
	ended = 'ended',
}
export enum RequestOfferStrategy {
	sequential = 'sequential',
	concurrent = 'concurrent',
	manual = 'manual',
}
export interface OfferRequest {
	id: OfferRequestId
	processId: ProcessId
	requestStatus: OfferRequestStatus
	requestedOn: DateString | null
	lastActivityOn: DateString
	title: ShortString
	description: HtmlString
	invoiceRecipient: ShortString
	attachments: []
	requestOfferStrategy: RequestOfferStrategy
	automaticAcceptLimit: number | null
	revealAutomaticAcceptLimit: boolean
	offerExpectedUntil: DateString | null
	expectedFulfilmentDate: DateString | null
	maximumCost: DateString | null
	offers: Offer[]
	providers: ProviderId[]
	isWaiting: boolean
	waitUntil: DateString | null
}

export type OfferId = Uuid
export enum OfferStatus {
	waiting = 'waiting',
	pending = 'pending',
	rejected = 'rejected',
	available = 'available',
	accepted = 'accepted',
	declined = 'declined',
	withdrawn = 'withdrawn',
	expired = 'expired',
}
export interface Offer {
	id: OfferId
	requestId: OfferRequestId
	processId: ProcessId
	orderId: OrderId | null
	status: OfferStatus
	requestedOn: DateString | null
	provider: Provider
	offerNumber: ShortString | null
	description: HtmlString | null
	attachments: []
	cost: number | null
	validUntil: DateString | null
	expectedStart: DayString | null
	expectedEnd: DayString | null
	conversation: { title: ShortString; id: ConversationId } | null
}

export enum ConfirmationStatus {
	pending = 'pending',
	rejected = 'rejected',
	accepted = 'accepted',
	cancelled = 'cancelled',
	expired = 'expired',
}

export enum OrderStatus {
	draft = 'draft',
	waiting = 'waiting',
	active = 'active',
	cancelled = 'cancelled',
	done = 'done',
}

export type OrderId = Uuid
export interface Order {
	id: OrderId
	processId: ProcessId
	placedOn: DateString | null
	lastActivityOn: DateString
	provider: Provider
	offerId: OfferId | null
	mustBeConfirmed: boolean
	confirmedOn: DateString | null
	// confirmedBy:
	confirmationStatus: ConfirmationStatus | null
	orderStatus: OrderStatus
	title: ShortString
	description: HtmlString
	invoiceRecipient: ShortString
	deadline: DayString | null
	agreedCost: number | null
	maximumCost: number | null
	doneOn: DateString | null
	isWaiting: boolean
	waitUntil: DateString | null
	conversation: { title: ShortString; id: ConversationId } | null
}

export const processUpdateSearchKeys: (keyof ProcessUpdate)[] = ['description']
export interface ProcessListView extends Content<ProcessId> {
	roleTypes: RoleType[]
	title: ShortString
	description: HtmlString
	status: ProcessStatus
	lastActivityOn: DateString
	extId: ShortString
	extIdProvider: ShortString
	updates: ProcessUpdate[]
	hasWorkflows: boolean
	assignees: MicroUser[]
	hasContractVisibility: boolean
}
export interface Process extends ProcessListView {
	contractIds: ContractId[]
	attachments: Attachments
	offerRequests: OfferRequest[]
	orders: Order[]
	conversations: ConversationListView[]
	events: EventListView[]
	phases: {
		id: string
		title: string
		progress: number | null
		notes: string | null
		activities: {
			id: string
			title: string
			progress: number | null
			notes: string | null
		}[]
	}[]

	watchers: MicroUser[]
}

/*
 * Validation
 */

export const processValidations = {
	roleTypes: {},
	title: {
		required: Validators.required,
		maxCharacters: Validators.maxCharacters(250),
	},
	description: {
		description: Validators.required,
	},
	extId: {
		maxCharacters: Validators.maxCharacters(250),
	},
	extIdProvider: {
		maxCharacters: Validators.maxCharacters(250),
	},
}

export const processUpdateValidations = {
	description: {
		description: Validators.required,
	},
	isInternal: {},
	newStatus: {},
}

/*
 * Search
 */

export const processSearchKeys: (keyof Process)[] = [
	'title',
	'description',
	'extId',
	'extIdProvider',
	...(prefixSearchKeys('updates', processUpdateSearchKeys) as (keyof Process)[]),
]

/*
 * Api
 */

export interface ProcessesQueryResponse {
	processes: Process[]
	nodes: Node[]
}

/*
 * Functions
 */

export function isProcessOpen(process: Process) {
	return process.status !== ProcessStatuses.completed
}

export function getLatestProcessUpdate(process: Process): ProcessUpdate | null {
	return process.updates.length ? [...process.updates].reverse()[0] : null
}

export function getLatestProcessActivityDate(process: Process): DateString {
	const latestUpdate = getLatestProcessUpdate(process)

	return latestUpdate ? latestUpdate.publishedOn : process.publishedOn
}

export function processSort(processes: Process[]) {
	return sort(processes, 'lastActivityOn', 'desc')
}

export function getUpdateAttachments(process: Process, update: ProcessUpdate) {
	return filterAttachments(process?.attachments, update)
}

export function filterAttachments(attachments: Attachments, update: ProcessUpdate) {
	const result = {}
	for (const k of Object.keys(attachments || {})) {
		result[k] = attachments[k].filter((a) => {
			return a.associatedWith === update.id
		})
	}
	return result
}

export function getInvoiceRecipientFromObject(object: ObjectType): string | null {
	// we only recommend object invoice recipient for owner objects
	if (object.managementType !== ObjectManagementType.owner) {
		return null
	}

	return `Wohnungseigentümergemeinschaft ${object.title}`
}

export function getInvoiceRecipientFromContract(contract: Contract): string | null {
	const nonAgents = contract.signatories.filter((s) => !s.isAgent)
	// must have non-agent signatories
	if (!nonAgents.length) {
		return null
	}

	return nonAgents.map(signatoryName).join('\n')
}

export function getEarliestRequestDate(offerRequest: OfferRequest) {
	return offerRequest.offers
		.map((offer) => offer.requestedOn)
		.filter((date) => !!date)
		.sort()[0]
}
