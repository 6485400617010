
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import UiAvatar from '~/components/atoms/UiAvatar.vue'

	@Component({ components: { UiAvatar } })
	export default class LanguageSwitch extends Vue {
		@Prop({ default: 'grey' }) color: string
		@Prop({ default: false }) list: boolean
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: false }) block: boolean
		@Prop({ default: true }) text: boolean
		@Prop({ default: undefined, type: String }) dataQa: string

		get selectedLocale() {
			return this.$i18n.locale
		}

		get locales() {
			return this.$i18n.locales.map((l) => (l as any).code)
		}

		async setLocale(l) {
			this.$i18n.setLocale(l)
			this.veeLocale = l

			// persist
			if (this.$auth.loggedIn) {
				this.$auth.setUser({ ...this.$auth.user, locale: l })
				try {
					await this.$axios.$post('etg24:platform:command:account.updatelocale', {
						locale: l,
					})
				} catch (e) {}
			}
		}
	}
