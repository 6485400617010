import { http, HttpResponse } from 'msw'
import { CallDirection, CallRecord, CallSource } from './model'

export const handlerFactory = (url: (path: string) => string) => [
	http.get(url('etg24:internal:query:communication.callrecords.show'), ({ request }) => {
		const query = new URLSearchParams(request.url)
		const id = query.get('callRecord') || crypto.randomUUID()

		return HttpResponse.json<{ callRecord: CallRecord }>({
			callRecord: {
				contact: null,
				conversation: null,
				id,
				note: 'test',
				direction: CallDirection.inbound,
				duration: 0,
				phoneNumber: '+49123456789',
				recordedOn: new Date().toISOString(),
				source: CallSource.manual,
				keyData: null,
				object: null,
				recording: null,
				transkript: null,
				recordedBy: {
					id: crypto.randomUUID(),
					isRegistered: true,
					firstName: 'Max',
					lastName: 'Mustermann',
					emailAddress: 'max.mustermann@test.com',
					image: {
						id: crypto.randomUUID(),
						size: 0,
						src: 'https://via.placeholder.com/150',
						caption: 'Max Mustermann',
						collectionName: 'new',
						height: 150,
						width: 150,
						sha1: 'sha1',
					},
				},
			},
		})
	}),
]
