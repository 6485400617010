
	import sleep from '~/app/utils/sleep'
	import { Component, Model, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { Object, objectSearchKeys } from '~/app/domain/property/Object'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import ObjectCard from '~/components/domain/object/ObjectCard.vue'
	import ObjectLine from '~/components/domain/object/ObjectLine.vue'
	import { isEqual } from 'lodash'
	import Image from '~/app/utils/globals/Image'

	@Component({
		components: {
			ObjectLine,
			ObjectCard,
			SearchLayout,
			Keypress: () => import('vue-keypress'),
		},
	})
	export default class ObjectSelect extends Vue {
		@Model('change', { required: true }) selectedObject: Object
		@Prop({ required: true }) items: Object[]
		@Prop({ default: false }) noImage: boolean
		@Prop({ default: false }) backOffice: boolean
		@Prop({ default: 80 }) height: number
		@Prop({ type: Boolean, default: false }) provider: boolean

		Image = Image

		menuOpen: boolean = false
		localSelectedObject: Object = null
		@Watch('selectedObject', { immediate: true })
		updateLocalSelectedObject() {
			if (
				!this.localSelectedObject ||
				this.localSelectedObject.id !== this.selectedObject.id ||
				!isEqual(this.localSelectedObject, this.selectedObject)
			)
				this.localSelectedObject = { ...this.selectedObject }
		}

		@Watch('localSelectedObject')
		updateSelectedObject() {
			if (this.localSelectedObject) {
				this.menuOpen = false
				this.$emit('change', this.localSelectedObject)
			}
		}

		get disabled() {
			return this.items.length < 2
		}

		/**
		 * Filtering logic
		 */
		searchText: string = ''

		get filteredObjects() {
			let filtered = this.items
			if (this.searchText) {
				filtered = search(filtered, this.searchText, {
					fuzzy: true,
					keys: objectSearchKeys,
				})
			}
			this.selectedIndex = -1
			return filtered
		}

		async toggleDialog(e) {
			if (this.items.length < 2) return
			this.menuOpen = !this.menuOpen
			await sleep(100)
			const input = (this.$refs as any).objectSearch
			input.focus()
		}

		selectedIndex: number = -1
		pressDown() {
			if (this.menuOpen) {
				if (this.selectedIndex < this.filteredObjects.length - 1) {
					this.selectedIndex++
				} else {
					this.selectedIndex = 0
				}
				this.scrollToSelected()
			}
		}

		pressUp() {
			if (this.menuOpen) {
				if (this.selectedIndex === -1) {
					this.selectedIndex = 0
				} else if (this.selectedIndex > 0) {
					this.selectedIndex--
				} else {
					this.selectedIndex = this.filteredObjects.length - 1
				}
				this.scrollToSelected()
			}
		}

		scrollToSelected() {
			/*			this.$vuetify.goTo(`#object-${this.selectedIndex}`, {
				duration: 0,
				offset: 0,
				easing: 'easeInOutCubic',
			})*/
		}

		selectObject() {
			if (this.menuOpen && this.selectedIndex >= 0) {
				this.localSelectedObject = this.filteredObjects[this.selectedIndex]
			}
		}

		get objectId() {
			return this.$route.params.object_id
		}

		@Watch('objectId') updateObjectId() {
			if (this.localSelectedObject.id !== this.objectId) {
				const f = this.items.find((i) => i.id === this.objectId)
				if (f) this.localSelectedObject = f
			}
		}

		/**
		 * Prev / next
		 */

		previous() {
			const cIndex = this.items.findIndex((c) => c.id === this.localSelectedObject.id)
			if (cIndex > 0) {
				this.localSelectedObject = this.items[cIndex - 1]
			}
		}

		next() {
			const cIndex = this.items.findIndex((c) => c.id === this.localSelectedObject.id)
			if (cIndex < this.items.length - 1) {
				this.localSelectedObject = this.items[cIndex + 1]
			}
		}
	}
