import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.starts-with.js";
export default function useAppPath() {
  var route = useRoute();
  var auth = useAuth();
  return computed(function () {
    var subdomain = route.params.subdomain;
    if (!subdomain) {
      return '';
    }

    // fetch the domain
    var domain = auth.user && auth.user.domains && auth.user.domains[subdomain];
    if (!domain.tenant) {
      return '';
    }

    // get the subdomain url
    var type = domain.tenant.tenantType.startsWith('property') ? 'property' : 'provider';
    var access = domain.accessAreas.frontOffice ? 'fo' : 'bo';
    return "/app/".concat(domain.tenant.subdomain, "/").concat(type, "/").concat(access);
  });
}