
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { ImageAsset } from '~/app/domain/assets/Image'
	import Image from '~/app/utils/globals/Image'

	@Component({})
	export default class ImageThumb extends Vue {
		@Prop({ required: true }) image: ImageAsset
		@Prop({ default: 140 }) height: number
		@Prop({ default: false, type: Boolean }) quickImageDelete: boolean

		get resImage(): string {
			return Image.resolveImg(this.image, null, this.height)
		}
	}
