import { DateString, DayString, HtmlString, ShortString, TimeString } from '../Types'
import { Content, ContentId, NodeId, Node } from './/Common'
import { Address, addressSearchKeys } from '../common/Address'
import { RoleType } from '../identifyAndAccess/Role'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { Attachments } from '../assets/Attachments'
import { Validators } from '../../common/validation/Validators'
import { addressValidations } from '../../common/model/Address'
import sort from '~/app/common/filters/iterator/Sort'
import { ContractId } from '~/app/domain/property/Contract'
import { ProcessId } from '~/app/domain/content/Process'
import { parseISO, isPast, isFuture, isAfter, isBefore } from 'date-fns'

/*
 * Model
 */

export type EventId = ContentId
export interface EventListView extends Content<EventId> {
	nodeId: NodeId
	title: ShortString
	description: HtmlString
	fromDate: DayString
	fromTime: TimeString | null
	toDate: DayString
	toTime: TimeString | null
	minStart: DateString
	maxEnd: DateString
	isCancelled: boolean
	address: Address | null
	addressNote: ShortString | null
	roleTypes: RoleType[]
	process: { id: ProcessId; title: ShortString } | null
	hasContractVisibility: boolean
}
export interface Event extends EventListView {
	attachments: Attachments
	contractIds: ContractId[]
}

export interface UserConfig {
	eventId: EventId
	notify: boolean
}

/*
 * Validation
 */

export const eventValidations = {
	roleTypes: {
		required: Validators.required,
	},
	title: {
		required: Validators.required,
		maxCharacters: Validators.maxCharacters(250),
	},
	description: {
		required: Validators.required,
	},
	fromDate: {
		required: Validators.required,
	},
	fromTime: {},
	toDate: {
		required: Validators.required,
	},
	toTime: {},
	address: {
		...addressValidations,
	},
	addressNote: {
		maxCharacters: Validators.maxCharacters(250),
	},
}

/*
 * Search
 */

export const eventSearchKeys: (keyof Event)[] = [
	'title',
	'description',
	'fromDate',
	'toDate',
	...(prefixSearchKeys('address', addressSearchKeys) as (keyof Event)[]),
]

/*
 * Api
 */

export interface EventsQueryResponse {
	events: Event[]
	nodes: Node[]
}

/*
 * Functions
 */

export function isFutureEvent(event: Event) {
	return isFuture(parseISO(event.minStart))
}

export function isPastEvent(event: Event) {
	return isPast(parseISO(event.maxEnd))
}

export function isPresentEvent(event: Event) {
	const now = new Date()
	return isAfter(now, parseISO(event.minStart)) && isBefore(now, parseISO(event.maxEnd))
}

export function isTimePreciseEvent(event: Event) {
	return !!event.fromTime && !!event.toTime
}

export function isMultiDayEvent(event: Event) {
	return event.fromDate !== event.toDate
}

export function eventSort(events: Event[], past: boolean = true) {
	return sort(events, (event: Event) => {
		return past ? event.maxEnd : event.minStart
	})
}
