import { Document } from '../content/Document'
import { ImageAsset } from './Image'
import { FileAsset } from './File'

// these are rich attachments with documents, usually editable
export interface Attachments {
	documents: Document[]
	images: ImageAsset[]
}

// these are simple attachments, usually not editable
export interface AssetAttachments {
	files: FileAsset[]
	images: ImageAsset[]
	documents?: Document[]
}

export function countAttachments(attachments: Attachments) {
	return attachments.documents.length + attachments.images.length
}

export function countAssetAttachments(attachments: AssetAttachments) {
	return (attachments.files || []).length + (attachments.images || []).length + (attachments.documents || []).length
}
