
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { SanityReference, SanityAsset } from '@sanity/image-url/lib/types/types'
	import SanityImage from '~/components/sanity/SanityImage.vue'

	@Component({
		components: { SanityImage },
	})
	export default class SanityBlockImage extends Vue {
		@Prop({ required: true }) asset: SanityReference | SanityAsset
		@Prop({ required: true }) alt: string
		@Prop({ default: null }) caption: string
		@Prop({ default: null }) linkUrl: string
		@Prop({ default: null }) crop: any // sanity image crop
		@Prop({ default: null }) hotspot: any // sanity image hotspot

		get wrapperAttributes() {
			// a link, if defined
			if (this.linkUrl) {
				return {
					is: 'a',
					href: this.linkUrl,
					target: '_blank',
				}
			}

			// default
			return {
				is: 'div',
			}
		}
	}
