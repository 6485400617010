
	import { Component, Prop, Vue, Model, Watch } from 'nuxt-property-decorator'
	import { Company, companySearchKeys } from '~/app/domain/provider/Provider'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import CompanyCard from '~/components/domain/company/CompanyCard.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'

	@Component({
		components: { BasicDialog, CompanyCard, SearchLayout },
	})
	export default class CompanyPicker extends Vue {
		// keep the visibility synced
		@Model('change', { type: Boolean }) visible: boolean
		@Prop({ required: true }) companies: Company[]
		@Prop({ default: null }) title: string
		@Prop({ type: Array, default: () => [] }) selected

		localVisible = false

		@Watch('visible')
		changeVisible(val) {
			this.localVisible = val
		}

		@Watch('localVisible')
		changeLocalVisible() {
			this.$emit('change', this.localVisible)
			if (this.localVisible && this.selected.length) {
				this.selectedCompanies = this.selected
			}
		}

		/**
		 * Company filtering
		 */
		searchText: string = ''

		get filteredCompanies() {
			let filtered = this.companies
			if (this.searchText) {
				filtered =
					search(filtered, this.searchText, {
						fuzzy: true,
						sort: true,
						keys: companySearchKeys,
					}) || []
			}
			return filtered || []
		}

		selectedCompanies: Company[] = []
		selectCompany(company) {
			if (!this.selectedCompanies.includes(company)) {
				this.selectedCompanies.push(company)
			} else {
				this.selectedCompanies = this.selectedCompanies.filter((c) => {
					return c !== company.id
				})
			}
		}

		sendSelected() {
			this.$emit('selected', this.selectedCompanies)
			this.selectedCompanies = []
			this.localVisible = false
		}
	}
