
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { RoleType } from '~/app/domain/identifyAndAccess/Role'

	@Component({})
	export default class ContextVisibility extends Vue {
		@Prop({ required: true }) roleTypes: RoleType[]
		@Prop({ type: Boolean, default: false }) inline: boolean

		get roles() {
			return (
				this.roleTypes.map((r) => {
					return this.$tc(`domain.user.role.${r}`, 0)
				}) || []
			)
		}
	}
