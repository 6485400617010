import { SalutationVariant } from '@shared/types/person'
export { SalutationVariant } from '@shared/types/person'

export enum NewsletterInterval {
	none = 'off',
	hourly = 'hourly',
	daily = 'daily',
	weekly = 'weekly',
	monthly = 'monthly',
}

export type NewsletterSettings = { newsletterInterval: NewsletterInterval; salutation: SalutationVariant }

export const channels = ['email', 'createAsNoticed'] as const

export type Channel = typeof channels[number]

export type ChannelValue = {
	value: boolean
	default: boolean
	isConfigurable: boolean
}

export type NotificationSetting = {
	notificationType: string
	isConfigurable: boolean
} & {
	[key in Channel]: ChannelValue
}

export type ConversationSettings = {
	newMessage: {
		isConfigurable: boolean
		email: ChannelValue
	}
}
