import { render, staticRenderFns } from "./InboundEmailActions.vue?vue&type=template&id=fc07052c&scoped=true"
import script from "./InboundEmailActions.vue?vue&type=script&setup=true&lang=ts"
export * from "./InboundEmailActions.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc07052c",
  null
  
)

export default component.exports