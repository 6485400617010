import { MicroUser, userSearchKeys } from '~/app/domain/identifyAndAccess/User'
import { DateString, PhoneNumberString } from '~/app/domain/Types'
import { prefixSearchKeys } from '~/app/common/filters/iterator/Search'
import { Uuid } from '@shared/types'
import { Contact, contactTitle } from '~/app/domain/contact/Contact'
import { Conversation } from '~/app/domain/communication/Conversation'
import { PropertyObject } from '~/app/domain/property/Object'

export enum CallDirection {
	inbound = 'inbound',
	outbound = 'outbound',
}

export enum CallSource {
	manual = 'manual',
	//inCall = 'incallPage',
	api = 'api',
}

export interface CallRecordCreateDto {
	note?: string
	source: CallSource
	recordedOn: DateString
	phoneNumber: PhoneNumberString | null
	direction: CallDirection | null
	duration: number | null
	contact?: string | null
	conversation?: string | null
	object?: string | null
}
export interface CallRecordUpdateDto {
	id: Uuid
	note?: string
	recordedOn: DateString
	direction: CallDirection | null
	duration: number | null
	contact?: string | null
	conversation?: string | null
	object?: string | null
}

export interface CallRecord {
	id: Uuid
	note?: string
	phoneNumber: PhoneNumberString
	recordedBy: MicroUser
	recordedOn: DateString
	source: CallSource
	direction: CallDirection | null
	contact: Contact | null
	conversation: Conversation | null
	object: PropertyObject | null
	recording: { id: string; name: string; size: number; type: string; url: string } | null
	duration: number | null
	keyData: string | null
	transkript: string | null
}

export const callRecordsSearchKeys = [
	'note',
	'phoneNumber',
	'contact.salutation',
	'contact.firstName',
	'contact.lastName',
	...prefixSearchKeys('recordedBy', userSearchKeys),
]

export function getCallRecordTitle(callRecord: CallRecord): string {
	return callRecord.contact ? contactTitle(callRecord.contact) : callRecord.phoneNumber
}
