import { withParams } from 'vuelidate/lib'
import {
	isNumber,
	hasMaxCharacters,
	hasMaxDigits,
	hasMaxPrecision,
	hasMinCharacters,
	isBetween,
	isEmailCsv,
	isEmpty,
	isFilename,
	isFullUrl,
	isInteger,
	isNegative,
	isNotEmpty,
	isNotZero,
	isPhoneNumber,
	isPositive,
	isSubdomain,
	isDate,
	isTime,
	isEmail,
} from './Validation'

export const Validators = {
	required: withParams({ type: 'required' }, isNotEmpty),
	empty: withParams({ type: 'empty' }, isEmpty),
	number: withParams({ type: 'number' }, isNumber),
	integer: withParams({ type: 'integer' }, isInteger),
	maxPrecision: (max) => withParams({ type: 'maxPrecision', max }, hasMaxPrecision(max)),
	maxDigits: (max) => withParams({ type: 'maxDigits', max }, hasMaxDigits(max)),
	atLeast: (min) => withParams({ type: 'atLeast', v: min }, (min) => isBetween(min, null, false)),
	atMost: (max) => withParams({ type: 'atMost', v: max }, (max) => isBetween(null, max, false)),
	greaterThan: (min) => withParams({ type: 'greaterThan', v: min }, (min) => isBetween(min, null, true)),
	lessThan: (max) => withParams({ type: 'lessThan', v: max }, (max) => isBetween(null, max, true)),
	positive: withParams({ type: 'positive' }, isPositive),
	negative: withParams({ type: 'negative' }, isNegative),
	notZero: withParams({ type: 'notZero' }, isNotZero),
	maxCharacters: (max) => withParams({ type: 'maxCharacters', max }, hasMaxCharacters(max)),
	minCharacters: (min) => withParams({ type: 'minCharacters', min }, hasMinCharacters(min)),
	filename: withParams({ type: 'fileName' }, isFilename),
	email: withParams({ type: 'email' }, isEmail),
	emailCsv: withParams({ type: 'emailCsv' }, isEmailCsv),
	phoneNumber: withParams({ type: 'phoneNumber' }, isPhoneNumber),
	fullUrl: withParams({ type: 'fullUrl' }, isFullUrl),
	subdomain: withParams({ type: 'subdomain' }, isSubdomain),
}
