
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { getNoteStatus, Note, NoteStatus } from '~/app/domain/notes/Note'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'
	import NoteContextScope from '~/components/domain/notes/NoteContextScope.vue'
	import ContextLocation from '~/components/utils/ContextLocation.vue'
	import RichText from '~/components/utils/RichText.vue'

	@Component({
		components: { RichText, ContextLocation, NoteContextScope, UserAvatar },
	})
	export default class NoteCard extends Vue {
		@Prop({ required: true, type: Object }) note: Note
		@Prop({ default: false, type: Boolean }) atTarget: boolean
		@Prop({ default: null }) scopeId: string
		@Prop({ default: () => [] }) nodes: Node[]

		get noteDeadline() {
			return this.note.deadline
		}

		get deadline() {
			if (this.noteDeadline) {
				const d = this.$dateFns.parse(this.noteDeadline, 'yyyy-MM-dd', new Date())
				if (this.$dateFns.isToday(d)) {
					// today
					return {
						icon: 'fal fa-bell-on',
						color: 'error',
						text: this.$t('domain.note.deadline.today'),
					}
				} else if (this.$dateFns.isTomorrow(d)) {
					// tomorrow
					return {
						icon: 'fal fa-bell',
						color: 'primary',
						text: this.$t('domain.note.deadline.tomorrow'),
					}
				} else if (this.$dateFns.isFuture(d)) {
					// after tomorrow
					return {
						icon: 'fal fa-bell',
						color: 'black',
						text: Utils.dateFormat(d.toDateString()),
					}
				} else {
					// before today
					return {
						icon: 'fal fa-bell-on',
						color: 'error',
						text: Utils.dateFormat(d.toDateString()),
					}
				}
			}
			return null
		}

		get status() {
			return getNoteStatus(this.note, this.$auth.user.id)
		}

		get indicator() {
			switch (this.status) {
				case NoteStatus.new:
					return {
						color: 'secondary',
						tooltip: this.$t('layout.new'),
					}
				case NoteStatus.updated:
					return {
						color: 'info',
						tooltip: this.$t('layout.updated'),
					}
			}
		}
	}
