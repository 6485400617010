/* This  temporary to facilitate the transition to a newer architecture that uses composition api
 * Most likely this is going to be removed and the generic error handling would be placed in an axios interceptor
 */

export function useError() {
  var i18n = useI18n();
  var store = useVuex();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var handler = function handler(error) {
    var resData = error.response && error.response.data;
    var message = null;
    if (resData) {
      if (resData.message) {
        message = String(i18n.t(resData.message, resData.messageData));
      }
    }
    store.dispatch('snackbar/error', error.response ? message || format(error, i18n) : i18n.t('errors.offline'));
  };
  return handler;
}

/**
 * Formats an error
 * @param error
 * @param options
 */
function format(inMsg, i18n) {
  var msgTrans = inMsg;
  var response = msgTrans.status ? inMsg : msgTrans.response;
  if (!response) return String(msgTrans.message || msgTrans);

  // 1: check if the payload has an error message prop
  var plMsg = (response.data || {}).errorMessage;
  if (plMsg && i18n.te(plMsg)) return String(i18n.t(plMsg));
  var status = response.status;

  // 2: return the default string for those errors
  return String(i18n.t("errors.statusCode.".concat(status, ".title")));
}