import { getPathFromRootNode, Node, NodeId, NodeType } from '~/app/domain/content/Common'
import { ContactId } from '~/app/domain/contact/Contact'

export const getters = {
	rootPath: (state, getters, rootState, rootGetters) => `/app/${rootGetters['tenant/tenant'].subdomain}/provider/bo`,

	pathToContact: (state, getters, rootState, rootGetters) => (contactId: ContactId) => {
		return `${getters.rootPath}/contacts/${contactId}`
	},

	pathToLocationNode: (state, getters, rootState, rootGetters) => (locationNodeId: NodeId, nodes: Node[]) => {
		let path = getters.rootPath

		getPathFromRootNode(locationNodeId, nodes).forEach((node) => {
			switch (node.type) {
				case NodeType['provider']:
					break

				case NodeType['provider.company']:
					path += `/companies/${node.id}`
					break

				case NodeType['provider.object']:
					path += `/objects/${node.id}`
					break

				case NodeType['provider.companygroup']:
					path += `/company-groups/${node.id}`
					break
			}
		})

		return path
	},

	pathToFaqs: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/faqs`,

	pathToProcesses: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/processes`,
}

export const mutations = {}

export const actions = {}
