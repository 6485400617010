import { Address } from '../model/Address'
import { ISO_3166_1 } from '../static/ISO_3166_1'

export const GLUE_COMMA = ', '
export const GLUE_NEWLINE = '\n'

export const LEVEL_ADDRESS = 0x1
export const LEVEL_LOCALITY = 0x2
export const LEVEL_COUNTRY = 0x4
export const LEVEL_WITHOUT_COUNTRY = LEVEL_ADDRESS | LEVEL_LOCALITY
export const LEVEL_ALL = LEVEL_ADDRESS | LEVEL_LOCALITY | LEVEL_COUNTRY

type LevelOrGlue = typeof LEVEL_ALL | typeof GLUE_COMMA

/**
 * vue.js filter: formats the provided Address model as a string, optionally restricted to a certain level (address < locality < country)
 */
export default function address(subject: Address, levelsOrGlue?: LevelOrGlue) {
	if (subject) {
		const parts = []
		const level = typeof levelsOrGlue === 'number' ? levelsOrGlue : LEVEL_ALL
		const glue = typeof levelsOrGlue === 'string' ? levelsOrGlue : GLUE_COMMA

		if (level & LEVEL_ADDRESS && subject.street) {
			parts.push(subject.street + ' ' + subject.houseNumber)
		}

		if (level & LEVEL_LOCALITY && subject.zip && subject.city) {
			parts.push(subject.zip + ' ' + subject.city)
		}

		if (level & LEVEL_COUNTRY && subject.country) {
			parts.push(ISO_3166_1[subject.country] || subject.country)
		}

		if (parts.length) {
			return parts.join(glue)
		}
	}

	return null
}

/**
 * vue.js filter: checks whether or not the provided address is filled and may be displayed
 */
export function addressIsFilled(subject: Address) {
	return subject && subject.street && subject.zip && subject.country
}
