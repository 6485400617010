
	import Image from '~/app/utils/globals/Image'
	import { Component, Prop } from 'nuxt-property-decorator'
	import Vue from 'vue'
	import { Node } from '~/app/domain/content/Common'
	import { Note, ScopeType } from '~/app/domain/notes/Note'

	@Component({})
	export default class NoteContextScope extends Vue {
		@Prop({ required: true, type: Object }) note: Note
		@Prop({ required: true, type: Array }) nodes: Node[]

		get info() {
			switch (this.note.scopeType) {
				case ScopeType['content.post']:
					return {
						icon: Image.getIconForDomain('post'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'post',
									contentId: this.note.scope,
								},
							}),
					}

				case ScopeType['content.process']:
					return {
						icon: Image.getIconForDomain('process'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'process',
									contentId: this.note.scope,
								},
							}),
					}

				case ScopeType['content.event']:
					return {
						icon: Image.getIconForDomain('event'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'event',
									contentId: this.note.scope,
								},
							}),
					}

				case ScopeType['communication.conversation']:
					return {
						icon: Image.getIconForDomain('conversation'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'conversation',
									contentId: this.note.scope,
								},
							}),
					}

				case ScopeType['communication.delivery']:
					return {
						icon: Image.getIconForDomain('delivery'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'delivery',
									contentId: this.note.scope,
								},
							}),
					}

				case ScopeType['communication.voting']:
					return {
						icon: Image.getIconForDomain('voting'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'voting',
									contentId: this.note.scope,
								},
							}),
					}

				case ScopeType['communication.votingItem']:
					return {
						icon: Image.getIconForDomain('voting'),
						text: this.note.scopeTitle,
						action: async () => {
							const result = await this.$axios.get('etg24:internal:query:communication.voting.item', {
								params: {
									item: this.note.scope,
								},
							})
							if (!result || !result.data) {
								return
							}
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'voting',
									contentId: result.data.item.votingId,
									contentPage: `itemShow_${this.note.scope}`,
								},
							})
						},
					}

				case ScopeType.contact:
					return {
						icon: Image.getIconForDomain('contact'),
						text: this.note.scopeTitle,
						action: () =>
							this.$router.push({
								query: {
									...this.$route.query,
									contentType: 'contact',
									contentId: this.note.scope,
								},
							}),
					}

				default:
					throw new Error(`Unknown ScopeType ${this.note.scopeType}`)
			}
		}
	}
