
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Process } from '~/app/domain/content/Process'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import FileLine from '~/components/domain/attachments/FileLine.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import RichText from '~/components/inputs/RichTextEditor.vue'
	import ProcessActions from '~/components/domain/process/ProcessActions.vue'
	import DateFt from '~/components/utils/Date.vue'
	import { Document } from '~/app/domain/content/Document'
	import ProcessTimelineUpdate from '~/components/domain/process/ProcessTimelineUpdate.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import ProcessUpdateEditDialog from '~/components/domain/process/ProcessUpdateEditDialog.vue'
	import ReadMore from '~/components/utils/ReadMore.vue'
	import ReadMoreCard from '~/components/utils/ReadMoreCard.vue'

	@Component({
		components: {
			ReadMoreCard,
			ReadMore,
			ProcessUpdateEditDialog,
			BasicDialog,
			ProcessTimelineUpdate,
			date: DateFt,
			ProcessActions,
			RichText,
			UserLine,
			FileLine,
			CategoryContainer,
		},
	})
	export default class ProcessTimeline extends Vue {
		@Model('change', { required: true }) process: Process
		@Prop({ default: false }) editable: boolean

		Image = Image

		/**
		 * Update logic
		 */
		get updates() {
			if (!this.process) return []
			const pDocuments = this.process.attachments.documents
				.map((p) => {
					return { type: 'document', sortDate: p.date, ...p }
				})
				.filter((u) => {
					return !u.associatedWith
				})
			const pComments = this.process.updates.map((c) => {
				return {
					type: 'comment',
					sortDate: c.publishedOn,
					...c,
				}
			})
			return pDocuments.concat(pComments as any).sort((a, b) => {
				return this.$dateFns.isAfter(this.$dateFns.parseISO(a.sortDate), this.$dateFns.parseISO(b.sortDate))
					? -1
					: 1
			})
		}

		getIcon(entry) {
			if (entry.isInternal) return 'fal fa-eye-slash'
			switch (entry.type) {
				case 'document':
					return Image.getIconForExt(entry.extension)
				case 'comment':
					return Image.getIconForDomain('process-update')
				default:
					return 'fal fa-scrubber'
			}
		}

		newUpdate = false
		editingUpdate = false
		selectedUpdate: any = {}

		openUpdate(update) {
			if (this.editable) {
				this.newUpdate = false
				this.selectedUpdate = update
				this.editingUpdate = true
			}
		}

		createNewUpdate() {
			this.newUpdate = true
			this.selectedUpdate = {
				isInternal: false,
			}
			this.editingUpdate = true
		}

		/**
		 * Document dialog
		 */
		openDocument(document: Document) {
			this.$router.push({
				query: { ...this.$route.query, document: document.id },
			})
		}

		updateEmit(process = null) {
			;(this.$refs['readmore-process'] as ReadMore)?.recalculate()
			if (process) this.$emit('updateProcess', process)
		}
	}
