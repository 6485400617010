
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import nl2br from '@shared/utils/nl2br'

	@Component({
		components: {},
	})
	export default class InfoBtn extends Vue {
		@Prop({ required: true }) tooltip: string
		@Prop({ default: false }) small: boolean

		get text() {
			return nl2br(this.tooltip)
		}
	}
