
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { MicroUser } from '~/app/domain/identifyAndAccess/User'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'

	@Component({
		components: { UserAvatar },
	})
	export default class UserLine extends Vue {
		@Model('changed', { required: true }) user: MicroUser
		@Prop({ default: false }) withCompany: boolean
		@Prop({ default: null }) company: string
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: false }) noItalic: boolean
		@Prop({ default: null }) tooltip: string

		get hasTooltip() {
			return this.tooltip != null
		}
	}
