
	import Utils from '~/app/utils/globals/Utils'
	import sleep from '~/app/utils/sleep'
	import { Component, Vue, Model, Watch, Prop } from 'nuxt-property-decorator'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import SfDateTime from '~/components/domain/conversation/service_forms/blocks/SfDateTime.vue'

	@Component({
		components: { SfDateTime, BasicDialog },
	})
	export default class ConversationScheduleBtn extends Vue {
		Utils = Utils
		@Model('changed', {}) sendOn: Date | string
		@Prop({ type: Boolean, default: true }) enabled: boolean

		@Watch('sendOn', { immediate: true }) updateSendOn() {
			this.localSendOn = this.sendOn
		}

		@Watch('localSendOn') updateLocalSendOn() {
			this.$emit('changed', this.localSendOn)
		}

		scheduleDraftDialog = false
		localSendOn = null
		today = this.$dateFns.format(new Date(), 'yyyy-MM-dd')
		get min() {
			// check if we are past 11pm
			let date
			if (this.$dateFns.format(new Date(), 'HH') === '23') {
				date = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1), 'yyyy-MM-dd')
			} else {
				date = this.$dateFns.format(new Date(), 'yyyy-MM-dd')
			}

			const time = this.$dateFns.format(this.$dateFns.add(new Date(), { hours: 1 }), 'HH') + ':00'
			return this.$dateFns.parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date()).toISOString()
		}

		async submitSchedule() {
			this.scheduleDraftDialog = false
			await sleep(100)
			this.$emit('submit')
		}
	}
