
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { filterAttachments, getUpdateAttachments, Process, ProcessUpdate } from '~/app/domain/content/Process'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import Date from '~/components/utils/Date.vue'
	import UserAvatar from '~/components/domain/user/UserAvatar.vue'
	import RichText from '~/components/utils/RichText.vue'

	@Component({
		components: {
			RichText,
			UserAvatar,
			Date,
			Attachments,
			ListSelector,
			Dropzone,
		},
	})
	export default class ProcessTimelineUpdate extends Vue {
		@Model('change', { required: true }) update: ProcessUpdate
		@Prop({ required: true }) process: Process
		@Prop({ default: false }) editable: boolean

		/**
		 * Attachments
		 */
		get attachments() {
			return getUpdateAttachments(this.process, this.update)
		}

		get attachmentsCount() {
			let count = 0
			for (const k of Object.keys(this.attachments)) count += this.attachments[k].length
			return count
		}

		filterAttachments(a) {
			return filterAttachments(a, this.update)
		}
	}
