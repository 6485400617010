
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import ContextLocation from '~/components/utils/ContextLocation.vue'

	@Component({
		components: { ContextLocation },
	})
	export default class ContactPersonResponsibilityCard extends Vue {
		// ContactPerson & WithCategory
		@Model('change', { required: true }) responsibility: any
		@Prop({ default: () => [] }) nodes: Node[]

		get categoryTitle() {
			return this.responsibility?.category?.title
		}
	}
