
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Attachments } from '~/app/domain/assets/Attachments'

	@Component({})
	export default class AttachmentsLine extends Vue {
		@Model('change', { required: false }) attachments: Attachments
		@Prop({ default: null }) count: number
		get attachmentsCount(): number {
			if (this.count != null) return this.count
			let result: number = 0
			for (const type of Object.keys(this.attachments || {})) result += this.attachments[type].length
			return result
		}
	}
