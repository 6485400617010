
	export default {
		functional: true,

		props: {
			expand: {
				default: false,
				type: Boolean,
			},
			scroll: {
				default: false,
				type: Boolean,
			},
		},
	}
