
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import DateFt from '~/components/utils/Date.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'

	@Component({
		components: { SfDate, DateFt },
	})
	export default class FDatePicker extends Vue {
		@Model('changed', { default: '' }) value: string
		@Prop({ type: Number, default: 50 }) height
		@Prop({ type: String, default: null }) min
		@Prop({ type: String, default: 'fDate' }) queryName

		mounted() {
			const q = this.$route.query[this.queryName]
			if (q && typeof q === 'string') {
				this.localValue = q
			}
		}

		localValue: string = null
		@Watch('value', { immediate: true })
		valueUpdated() {
			this.localValue = this.value
		}

		@Watch('localValue', { immediate: true })
		localValueUpdated() {
			this.$emit('changed', this.localValue)
			if (this.localValue !== null)
				this.$router.replace({
					query: {
						...this.$route.query,
						[this.queryName]: this.localValue,
					},
				})
		}

		dayFormat = 'yyyy-MM-dd'

		/**
		 * Controls
		 */
		previous() {
			this.localValue = this.$dateFns.format(
				this.$dateFns.subDays(this.$dateFns.parse(this.localValue, this.dayFormat, new Date()), 1),
				this.dayFormat,
			)
		}

		next() {
			this.localValue = this.$dateFns.format(
				this.$dateFns.addDays(this.$dateFns.parse(this.localValue, this.dayFormat, new Date()), 1),
				this.dayFormat,
			)
		}
	}
