import escapeRegExpString from '~/app/utils/escapeRegExpString'
import reverseString from '~/app/utils/reverseString'
import getCommonFirstCharacters from '~/app/utils/getCommonFirstCharacters'

// Interface: result of applying the matching/search algorithms against a subject
export interface MatchResult {
	key: number | string // Identifying key/index from the CandidateMap input Object/Array
	value: string // The matched candidate value
	value_compare: string // The matched candidate value, normalized
	subject: string // The subject value
	subject_compare: string // The subject value, normalized
	certainty: MatchCertainty // The matching certainty/score
	algorithms: { [matcher: string]: MatchCertainty } // A map of all executed matcher algorithms and their respective matching certainty/score
}

// Type: candidate string that is to be tested against a subject
export type CandidateMap =
	| {
			[key: string]: string
	  }
	| string[]

// Type: an algorithm certainty (i.e. search score)
export enum MatchCertainty {
	certain = 1.0,
	confident = 0.75,
	probable = 0.5,
	possible = 0.25,
	threshold = 0.1,
}

interface MatcherAlgorithm {
	name: string
	calculateCertainty(candidate: string, subject: string, subjectWithoutPreprocessing: string): MatchCertainty
}

const cache = {}
/**
 * Cached factory method for RegExp instances
 */
function cacheRegExp(matcher: string, candidate: string, factory) {
	const k = `${matcher}_${candidate}`

	if (!cache[k]) {
		cache[k] = factory()
	}

	return cache[k]
}

const foundAsWord = (candidate: string, subject: string, subjectWithoutPreprocessing: string) => {
	if (subject.split(/[\b_]/).includes(candidate)) {
		// word has been found in the subject
		if (
			// exactly once
			subject.split(/[\b_]/).filter((v) => v === candidate).length === 1
		) {
			return MatchCertainty.certain
		}
	}
	return 0
}

const foundOnce = (candidate: string, subject: string, subjectWithoutPreprocessing: string) => {
	if (subject.split(candidate).length - 1 === 1) {
		// occurs exactly once
		if (/^\d+/.test(subject.split(candidate)[1])) {
			// match appended with numbers, so e.g. 12 was found in 123. That's not a good match
			return MatchCertainty.possible
		}
		if (/[1-9]+$/.test(subject.split(candidate)[0])) {
			// match prepended with numbers (except 0 which is ok), so e.g. 23 was found in 123. That's not a good match
			return MatchCertainty.possible
		}
		return MatchCertainty.probable
	}
	return 0 // not found or found multiple times
}

const isSubstring = (candidate: string, subject: string) => subject.includes(candidate)

const substringCertainty = (candidate: string, subject: string) =>
	isSubstring(candidate, subject) ? candidate.length / subject.length : 0

const areSubstrings = (candidate, subject) => {
	if (subject.includes(candidate)) {
		return candidate.length / subject.length
	} else if (candidate.includes(subject)) {
		return subject.length / candidate.length
	}
	return 0
}
const containsWord = (candidate: string, subject: string, subjectWithoutPreprocessing: string) => {
	const regexp = cacheRegExp(
		'word',
		candidate,
		() => new RegExp(`(^|[^\\w\\dÀ-ÿ])${escapeRegExpString(candidate)}($|[^\\w\\dÀ-ÿ])`, 'i'),
	)

	return MatchCertainty.confident * regexp.test(subject)
}

/**
 * Builds an Object containing matcher algorithms usable by various search/match functions
 */
const matcherAlgorithms: MatcherAlgorithm[] = [
	{
		// Searches for the subject to be contained in the candidate
		name: 'found_as_word',
		calculateCertainty: foundAsWord,
	},
	{
		// Searches for the subject to be contained in the candidate
		name: 'found_once',
		calculateCertainty: foundOnce,
	},
	{
		// Searches for the candidate to be contained within the subject as a direct substring
		name: 'substring',
		calculateCertainty: areSubstrings,
	},

	{
		// Searches for the candidate to be contained within the subject as a direct substring after removing non-digits from both
		name: 'numeric_substring',
		calculateCertainty: (candidate, subject, subjectWithoutPreprocessing) => {
			const regexp = /\D+/g
			subject = subject.replace(regexp, '')
			candidate = candidate.replace(regexp, '')

			return subject.includes(candidate) ? candidate.length / subject.length : 0
		},
	},

	{
		// Matches against known file name patterns (e.g. files exported from known standard software)
		//
		// TODO: Implement some kind of negative-match; if subject is a known pattern but doesn't match the candidate,
		//  we shouldn't even consider trying the other matchers, as this might lead to errors
		name: 'known_pattern',
		calculateCertainty: (candidate, subject, subjectWithoutPreprocessing) => {
			const regexp = cacheRegExp('known_pattern', candidate, () => {
				const escaped = escapeRegExpString(candidate)
				const patterns = [
					// 2013 xxx E 001.00 -> E 001
					// 2015 xxx M 005 -> M 005
					`.+ (?:E|M|G) (0*${escaped})(?:\\.\\d+)?(?: .+)?`,
					// 0010+015 xxx 2016 -> 015
					`\\d+\\+(0*${escaped}) .+`,
					// 0015_0-1212_xxx_(SE_18) -> 18
					`\\d+_.+_\\(\\w*(0*${escaped})\\)`,
					// 0080_WE16_xxx -> 16
					`\\d+_(?:WE|VE|ME|MV)(0*${escaped})_.+`,
					// 037530758_xxx_0005_0 -> 5
					`\\d+_.+_(0*${escaped})_\\d`,
					// 100406_xxx_xxx_101527_VE_4480 -> 4480
					`\\d+_.+_VE_(0*${escaped})`,
					// 101 A 2015 EA  (18) -> 18
					// 5100 Hausgeldabrechnung 2015 WE (3) -> 3
					`\\d+ \\w{1,2} \\d{4} \\w+ \\((0*${escaped})\\)`,
					// 27_0002_01_xxx 01.01.2014-31.12.2014 -> 002
					// 27_0065 xxx_Wohngeldzahlungen 2014 -> 065
					`\\d+_(0*${escaped})(?:_\\d+_| ).+`,
					// 421_10102_00011_xxx_20161121121814411 -> 011
					`\\d+_\\d+_(0*${escaped})_.+`,
					// 5180 xxx WGV WE 52 xxx -> 52
					// 5180 xxx 2015 Einheit 27 xxx -> 27
					`\\d+ .+ (?:VE|ME|WE|EINHEIT) (0*${escaped}) .+`,
					// xxx-2017-Einheit Nr.2-xxx -> 2
					`.+-\\d{4}-\\w+ (?:NR\\.\\s*)?(0*${escaped})-.+`,
					// xxx_E01400_xxx -> 14
					// xxx_E015_xxx -> 15
					`.+_E(0*${escaped}(?:\\d{2})?)_.+`,
					// Segment 003 von xxx 2015 -> 003
					`SEGMENT (0*${escaped}) VON .+`,
					// 0029+002 WPL 2016 -> 2
					`\\d+\\+(0*${escaped}) .+`,
					// HHND 025123-01,12300 2015 -> 123
					`.+ \\d+(0*${escaped})-\\d{2},(0*${escaped})\\d+ .+`,
					// W055-0005H001 -> 5
					// xxx Z287Z287-0004H002 -> 4
					// xxx Z384Z384-0001H001 -> Z384-0001
					`.*\\b(?:\\w\\d{3})+(?:(${escaped})|-(0*${escaped}))\\w\\d{3}`,
					// 201706140834-001-000002-000020 -> 20
					`(?:19|20)\\d{10}-\\d{3}-\\d{6}-(0*${escaped})`,
				]

				return new RegExp(`^(${patterns.join('|')})$`, 'i')
			})

			return MatchCertainty.certain * regexp.test(subjectWithoutPreprocessing || subject)
		},
	},

	{
		// Searches for the candidate to be contained within the subject with clear word breaks around it
		name: 'contains_word',
		calculateCertainty: containsWord,
	},

	{
		// Searches only for the alphanumerical characters of the candidate as word or substring
		name: 'alphanumeric',
		calculateCertainty: (candidate, subject, subjectWithoutPreprocessing) => {
			const regexp = /[^\s\w\d\u00C0-\u00FF]/g
			subject = subject.replace(regexp, '')
			candidate = candidate.replace(regexp, '')

			return (
				MatchCertainty.confident *
				(containsWord(candidate, subject, subjectWithoutPreprocessing) ||
					substringCertainty(candidate, subject))
			)
		},
	},

	{
		// Searches for the candidate padded within zeroes
		name: 'zero_padded',
		calculateCertainty: (candidate, subject, subjectWithoutPreprocessing) => {
			const regexp = cacheRegExp('zero_pad', candidate, () => {
				return new RegExp(`0*${escapeRegExpString(candidate)}0*`, 'i')
			})
			subject = subject.replace(regexp, candidate)

			return (
				MatchCertainty.probable *
				(containsWord(candidate, subject, subjectWithoutPreprocessing) ||
					substringCertainty(candidate, subject))
			)
		},
	},
]

/**
 * This matcher allows for matching a bunch of candidate strings against one or multiple subject strings
 * The candidate strings are expected to appear as a substring of some quality within the subject
 * Various matching/search functions are applied for each candidate<>subject combination
 * and the results are post-processed to yield the best matching candidate
 *
 * @example ```
 *     const candidates_arr = ['001', '002', '003']
 *     const candidates_obj = { foo: '001', bar: '002', qux: '003' }
 *     const subject = '0029+003 WPL 2017'
 *
 *     matchSubjectToCandidates(candidates_arr, subject)
 *     // => { key: 2, value: '003', ... }
 *     matchSubjectToCandidates(candidates_obj, subject)
 *     // => { key: 'qux', value: '003', ... }
 * ```
 */

/**
 * Applies various search algorithms to find the candidate string that has the closest match with the given subject
 * The returned Object(s) will have these keys: { key:Number|String, value:String, certainty:Number, algorithms:Object }
 *
 * @param  candidates  The strings to find in the given subject (e.g. a list/map of addresses, names or ids)
 * @param  subject  The string to check against all candidates (e.g. a filename that is supposed to contain one of the candidates)
 * @param  subjectWithoutPreprocessing  (optional) The original subject string without any preprocessing applied to it
 */
export const matchSubjectToCandidates = (
	candidates: CandidateMap,
	subject: string,
	subjectWithoutPreprocessing,
): MatchResult => {
	let matchResults: MatchResult[] = []
	const subjectUpperCase = trimUpperCase(subject)
	const subjectWithoutPreprocessingUpperCase = trimUpperCase(subjectWithoutPreprocessing)

	// Calculate the match certainty for every candidate
	for (const [candidateKey, candidate] of Object.entries(candidates)) {
		let candidateVariations = [candidate]

		// Check if each candidate word is actually a compound word; if so, append its components to the list of candidate words
		const split = candidate.split(/[\.\-\/]/)

		if (split.length > 1) {
			candidateVariations = candidateVariations.concat(split)
		}

		// Iterate through list of candidate words and match each
		for (const [wordKey, word] of Object.entries(candidateVariations)) {
			const wordUpperCase = trimUpperCase(word)
			let wordMaxCertainty = 0
			const wordAlgorithmCertainties = {}

			// Apply every matcher algorithm to this candidate word
			matcherAlgorithms.forEach((algorithm) => {
				wordAlgorithmCertainties[algorithm.name] = algorithm.calculateCertainty(
					wordUpperCase,
					subjectUpperCase,
					subjectWithoutPreprocessingUpperCase,
				)

				if (wordMaxCertainty < wordAlgorithmCertainties[algorithm.name]) {
					wordMaxCertainty = wordAlgorithmCertainties[algorithm.name]
				}

				// Stop on certain match
				if (wordMaxCertainty >= MatchCertainty.certain) {
					return false
				}
			})

			// Commit match if at least one algorithm has scored
			if (wordMaxCertainty > 0) {
				matchResults.push({
					key: candidateKey,
					value: word,
					value_compare: wordUpperCase,
					subject,
					subject_compare: subjectUpperCase,
					algorithms: wordAlgorithmCertainties,
					certainty: wordMaxCertainty,
				})
			}
		}
	}

	// Run optimizations if there are multiple matches
	if (matchResults.length > 1) {
		// Iterate over the matches in cartesian-style
		matchResults.forEach((a, i) => {
			matchResults.forEach((b, j) => {
				if (i === j) return

				// Reduce certainty on partially duplicate candidates (e.g. both "2" and "200" matching)
				if (a['value_compare'].includes(b['value_compare'])) {
					a['certainty'] *= Math.min(1, a['certainty'] / b['certainty'])
					// we removed this on 2019-02-01 because it reduced certainty of obviously correct exact matches and all available tests still ran through
					// b['certainty'] *= Math.min(1, (b['certainty'] / a['certainty']))
				}

				// Reduce certainty on candidates when there is a longer candidate with the same certainty (e.g. both "030" and "2" matching "005030 2016")
				if (a['certainty'] === b['certainty'] && a['value_compare'].length > b['value_compare'].length) {
					b['certainty'] *= b['value_compare'].length / a['value_compare'].length
				}
			})
		})

		// Filter above threshold certainties
		matchResults = matchResults.filter((a) => a.certainty > MatchCertainty.threshold)

		// Sort by certainty descending
		matchResults = matchResults.sort((a, b) => b.certainty - a.certainty)
	}

	// Return the most certain match object
	return matchResults[0]
}

/**
 * Macro function that applies the above matcher function to all of the provided subjects, after optimizing them by removing duplicate and uninteresting strings
 *
 * @param  candidates  The strings to find in each subject; see matchSubjectToCandidates()
 * @param  subjects  The array of subject strings to match against; see matchSubjectToCandidates()
 */
export const matchSubjectsToCandidates = (candidates: CandidateMap, subjects: string[]) => {
	const matches: MatchResult[] = []
	const subjectsWordsCount = {}
	const subjectsUpperCase = subjects.map(trimUpperCase)
	const wordsFromCandidatesUpperCase = Object.values(candidates).map(trimUpperCase)
	const wordsToRemoveFromSubjects = []
	const regexCache = {}

	// Count the separate words in all subjects
	subjectsUpperCase.forEach((subject) => {
		subject.split(/[^\w\d\u00C0-\u00FF]+/).forEach((word) => {
			// A word must be at least 2 characters long (duh)
			if (word.length >= 2) {
				subjectsWordsCount[word] = (subjectsWordsCount[word] || 0) + 1
			}
		})
	})

	// remove entirely common start and end parts of the candidates
	subjects = trimCommonFirstAndLastCharacters(subjects)

	// After counting each word, mark those for removal, which:
	// 1) were encountered at least 3 times across all subjects, and
	// 2) were encountered in at least half of all subjects, but
	// 3) are not part of any candidate string
	Object.entries(subjectsWordsCount).forEach(([word, count]) => {
		if (
			count >= 3 &&
			count >= subjects.length / 2 &&
			wordsFromCandidatesUpperCase.filter((candidate) => candidate.includes(word)).length === 0
		) {
			wordsToRemoveFromSubjects.push(word)
		}
	})

	// Optimize each subject, then try to match it
	subjectsUpperCase.forEach((subject, i) => {
		// Remember the original subject string
		const subjectWithoutPreprocessing = String(subject)

		// Remove duplicate words from this subject before further processing
		wordsToRemoveFromSubjects.forEach((word) => {
			if (!regexCache[word]) {
				regexCache[word] = new RegExp(`(^|[^\\w\\dÀ-ÿ]+)${escapeRegExpString(word)}($|[^\\w\\dÀ-ÿ]+)`, 'i')
			}

			while (regexCache[word].test(subject)) {
				subject = subject.replace(regexCache[word], ' ')
			}
		})

		// Pass this processed subject along with the candidates array to the singular matcher function
		matches[i] = matchSubjectToCandidates(candidates, subject, subjectWithoutPreprocessing)
	})

	// Return the matches for all subjects
	return matches
}
/**
 * Trims and upper-cases the provided string
 */
const trimUpperCase = (input: string | string[]) => {
	if (typeof input === 'string') {
		return input.trim().toLocaleUpperCase()
	}

	if (Array.isArray(input)) {
		return input.map(trimUpperCase)
	}

	return input
}

const trimCommonFirstAndLastCharacters = (strings: string[]): string[] => {
	return trimCommonFirstCharacters(trimCommonLastCharacters(strings))
}

const getCommonLastCharacters = (strings: string[]): string => {
	return reverseString(getCommonFirstCharacters(strings.map(reverseString)))
}

const trimCommonFirstCharacters = (strings: string[]): string[] => {
	const commonCharacters = getCommonFirstCharacters(strings)

	const charCount = commonCharacters.length
	if (!charCount) {
		return strings
	}

	return strings.map((string) => string.substring(charCount))
}

const trimCommonLastCharacters = (strings: string[]): string[] => {
	const commonCharacters = getCommonLastCharacters(strings)

	const charCount = commonCharacters.length
	if (!charCount) {
		return strings
	}

	return strings.map((string) => string.substring(0, string.length - charCount))
}
