
	import { Component, Vue, Prop, Model, Watch } from 'nuxt-property-decorator'

	@Component({})
	export default class ButtonToggle extends Vue {
		@Model('change', { required: true }) selected: any
		@Prop({ required: true }) items: any[]
		@Prop({ type: Boolean, default: false }) returnValue: boolean

		localSelected: number = 0
		@Watch('selected', { immediate: true })
		updateLocalSelected() {
			if (this.localSelected !== this.selected) this.localSelected = this.selected
		}

		@Watch('localSelected')
		updateSelected() {
			this.$emit('change', this.localSelected)
		}
	}
