
	import Image from '~/app/utils/globals/Image'
	import Messages from '~/app/utils/globals/Messages'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Process, ProcessStatuses } from '~/app/domain/content/Process'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import { RoleType } from '~/app/domain/identifyAndAccess/Role'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import NotesList from '~/components/domain/notes/NotesList.vue'
	import { username } from '~/app/common/filters/domain/User'
	import { translateContractSelectionSummary } from '~/app/domain/property/Contract'
	import ContentLocationAction from '~/components/domain/location/ContentLocationAction.vue'
	import { Node } from '~/app/domain/content/Common'
	import EmployeeSelectBtn from '~/components/domain/employee/EmployeeSelectBtn.vue'

	@Component({
		components: {
			EmployeeSelectBtn,
			ContentLocationAction,
			NotesList,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
		},
	})
	export default class ProcessActions extends Vue {
		@Model('change', { required: true }) process: Process
		@Prop({ required: true, type: Array }) nodes: Node[]
		@Prop({ default: null }) baseUrl: string
		@Prop({ default: false }) standalone: boolean
		@Prop({ default: false }) dark: boolean

		Image = Image

		username = username

		get actions() {
			if (!this.baseUrl) return []
			return [
				{
					icon: 'fal fa-pencil',
					callback: () => {
						this.$emit('edit')
					},
					attributes: {
						'data-qa': 'process-actions:edit',
					},
				},
				{
					callback: () => {
						this.deleteDialog = true
					},
					icon: 'fal fa-trash',
					color: 'error',
					attributes: {
						'data-qa': 'process-actions:delete',
					},
				},
			]
		}

		get roles() {
			return Object.keys(RoleType).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 2),
					value: r,
				}
			})
		}

		get processStatuses() {
			return Object.keys(ProcessStatuses).map((s) => {
				return {
					text: this.$t(`domain.process.status.${s}`),
					value: s,
				}
			})
		}

		/**
		 * Delete logic
		 */
		deleteDialog = false
		async deleteProcess() {
			try {
				await this.$axios.$post('etg24:internal:command:content.delete', {
					ids: [this.process.id],
				})
				this.$emit('deleted')
				this.$nuxt.$emit('processDeleted', this.process)
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		get visibilityData() {
			let selected = []
			if (this.process?.contractIds?.length) {
				selected = [...selected, 'contracts']
			}
			selected = [...selected, ...this.process.roleTypes]
			return {
				selected,
				options: [
					{
						text: translateContractSelectionSummary(
							// @ts-ignore
							this.process.contractSummary,
							this,
						),
						value: 'contracts',
					},
					...this.roles,
				],
			}
		}

		/**
		 * Create actions
		 */

		get eventBaseUrl() {
			return this.baseUrl.replace('/processes', '/events')
		}

		get conversationBaseUrl() {
			return this.baseUrl.replace('/processes', '/communication/conversations')
		}

		get createActions() {
			let actions = [
				{
					text: this.$tc('domain.conversation.base', 1),
					callback: () => {
						this.$router.push({
							query: {
								...this.$route.query,
								contentType: undefined,
								contentId: undefined,
								newContent: 'conversation',
								newContentId: this.process.nodeId,
								newContentProcess: this.process.id,
							},
						})
					},
					attributes: {
						'data-qa': 'process-actions:create-conversation',
					},
				},
				{
					text: this.$tc('domain.event.base', 1),
					callback: () => {
						this.$router.push({
							query: {
								...this.$route.query,
								contentType: undefined,
								contentId: undefined,
								newContent: 'event',
								newContentId: this.process.nodeId,
								newContentProcess: this.process.id,
							},
						})
					},
					attributes: {
						'data-qa': 'process-actions:create-event',
					},
				},
			]

			actions = [
				...actions,
				{
					text: this.$tc('domain.process.offerRequest.base', 1),
					callback: () => {
						this.$emit('changePage', 'offerEdit')
					},
					attributes: {
						'data-qa': 'process-actions:create-offer-request',
					},
				},
			]

			actions = [
				...actions,
				{
					text: this.$tc('domain.process.order.base', 1),
					callback: () => {
						this.$emit('changePage', 'orderEdit')
					},
					attributes: {
						'data-qa': 'process-actions:create-order',
					},
				},
			]

			return actions
		}

		async changeStatus(status) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:content.processes.update', {
					important: false,
					process: {
						id: this.process.id,
						status: status.value,
					},
				})
				this.$emit('updateProcess', response.process)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Watchers
		 */
		async setWatchers(items) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:content.processes.update', {
					process: {
						id: this.process.id,
						watchers: items,
					},
				})
				this.$emit('updateProcess', response.process)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Asignees
		 */
		async setAssignees(items) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:content.processes.update', {
					process: {
						id: this.process.id,
						assignees: items,
					},
				})
				this.$emit('updateProcess', response.process)
			} catch (e) {
				Utils.handleError(e)
			}
		}
	}
