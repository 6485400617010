
	import { ScopeType } from '@shared/types'
	import { Component, Model, Prop, Vue } from 'nuxt-property-decorator'
	import sort from '~/app/common/filters/iterator/Sort'
	import html2text from '~/app/common/filters/text/Html2text'
	import { Node } from '~/app/domain/content/Common'
	import { Process } from '~/app/domain/content/Process'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import FileLine from '~/components/domain/attachments/FileLine.vue'
	import ConversationCard from '~/components/domain/conversation/ConversationCard.vue'
	import ConversationList from '~/components/domain/conversation/ConversationList.vue'
	import EventCard from '~/components/domain/event/EventCard.vue'
	import EventDetail from '~/components/domain/event/EventDetail.vue'
	import EventList from '~/components/domain/event/EventList.vue'
	import NotesList from '~/components/domain/notes/NotesList.vue'
	import ProcessActions from '~/components/domain/process/ProcessActions.vue'
	import ProcessEdit from '~/components/domain/process/ProcessEdit.vue'
	import ProcessPhases from '~/components/domain/process/ProcessPhases.vue'
	import ProcessTimeline from '~/components/domain/process/ProcessTimeline.vue'
	import OfferRequestCard from '~/components/domain/process/offer/OfferRequestCard.vue'
	import ProcessOfferRequestEdit from '~/components/domain/process/offer/ProcessOfferRequestEdit.vue'
	import ProcessOfferRequestShow from '~/components/domain/process/offer/ProcessOfferRequestShow.vue'
	import OrderCard from '~/components/domain/process/order/OrderCard.vue'
	import ProcessOrderEdit from '~/components/domain/process/order/ProcessOrderEdit.vue'
	import ProcessOrderShow from '~/components/domain/process/order/ProcessOrderShow.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'
	import ReadMore from '~/components/utils/ReadMore.vue'
	import RichText from '~/components/utils/RichText.vue'

	@Component({
		components: {
			ProcessPhases,
			ProcessEdit,
			ProcessOrderShow,
			ProcessOrderEdit,
			OrderCard,
			ConversationCard,
			EventCard,
			OfferRequestCard,
			CardLargeContainer,
			ProcessOfferRequestShow,
			ProcessOfferRequestEdit,
			ReadMore,
			ConversationList,
			EventDetail,
			EventList,
			RichText,
			NotesList,
			DetailCard,
			ProcessTimeline,
			ProcessActions,
			UserLine,
			FileLine,
			Attachments,
			CategoryContainer,
		},
	})
	export default class ProcessDetail extends Vue {
		@Model('change', { required: false }) process: Process
		@Prop({ default: false }) editable: boolean
		@Prop({ default: null }) baseUrl: string
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: Array, default: () => [] }) nodes: Node[]

		ScopeType = ScopeType
		html2text = html2text

		updateProcess(process) {
			this.$nuxt.$emit('processUpdated', process)
			this.$emit('updateProcess', process)
		}

		get hasAttachments() {
			for (const k of Object.keys(this.process.attachments)) if (this.process.attachments[k].length) return true
			return false
		}

		/**
		 * Pages
		 */

		get qPage() {
			return this.$route.query.contentPage
		}

		setPage(page) {
			this.$router.push({
				query: {
					...this.$route.query,
					contentPage: page,
				},
			})
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		get parent() {
			return {
				text: this.process?.title,
				to: { query: { ...this.$route.query, contentPage: undefined } },
			}
		}

		/**
		 * Content list
		 */
		dateFormat = 'yyyy-MM-dd'
		today = new Date()

		showArchivedContents = false
		get contents() {
			let result = []
			if (!this.process) return result

			// add the events
			result = result.concat(
				sort(
					this.process.events.map((e) => {
						return {
							type: 'event',
							archived: this.$dateFns.isBefore(
								this.$dateFns.parse(e.fromDate, this.dateFormat, new Date()),
								this.today,
							),
							item: e,
						}
					}),
					// @ts-ignore
					'item.minStart',
					'desc',
				),
			)

			// add the conversations
			result = result.concat(
				sort(
					this.process.conversations.map((e) => {
						return {
							type: 'conversation',
							archived: !e.isOpen,
							item: e,
						}
					}),
					// @ts-ignore
					'item.lastMessagedOn',
					'desc',
				),
			)

			// add offers
			if (this.process.hasWorkflows)
				result = sort(
					result.concat(
						this.process.offerRequests.map((o) => {
							return { type: 'offer', archived: false, item: o }
						}),
					),
					'item.lastActivityOn',
					'desc',
				)

			// add orders
			if (this.process.hasWorkflows)
				result = sort(
					result.concat(
						this.process.orders.map((o) => {
							return { type: 'order', archived: false, item: o }
						}),
					),
					'item.lastActivityOn',
					'desc',
				)

			// put archived last
			result = sort(result, 'archived', 'asc')

			// add the conversations
			return result
		}

		get shownContents() {
			return this.contents.filter((r) => {
				return this.showArchivedContents || !r.archived
			})
		}

		get hiddenContents() {
			return this.contents.filter((c) => c.archived).length
		}
	}
