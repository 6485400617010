import * as Sentry from '@sentry/browser'

export default function () {
	return [
		new Sentry.Replay({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
	]
}
