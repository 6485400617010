var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-relative tw-w-full tw-animate-pulse",class:{
		'tw-justify-center tw-items-center tw-h-full': _vm.variant === 'spinner',
		'tw-visible': _vm.visible,
		'tw-invisible': !_vm.visible,
	}},[(_vm.variant === 'spinner')?_c(_setup.UiIcon,{staticClass:"tw-animate-spin",attrs:{"size":_setup.iconSize,"color":"accent","icon":"fal fa-spinner-third"}}):_c('div',{staticClass:"tw-w-full before:tw-animate-load tw-relative tw-overflow-hidden tw-bg-background-stronger before:tw-absolute before:tw-left-0 before:tw-top-0 before:tw-h-full before:tw-bg-text-soft before:tw-content-['']",class:{
			'tw-h-0.5': _vm.size === 'sm' || _vm.size === 'xs',
			'tw-h-1': _vm.size === 'md',
			'tw-h-1.5': _vm.size === 'lg',
		}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }