var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-icon',_vm._g(_vm._b({staticClass:"before:tw-leading-none",class:[
		_setup.iconSize,
		{
			'before:tw-text-white': _vm.color === 'white',
			'before:tw-text-text-base': _vm.color === 'default',
			'before:tw-text-text-softer': _vm.color === 'grey',
			'before:tw-text-success-base': _vm.color === 'success',
			'before:tw-text-error-base': _vm.color === 'error',
			'before:tw-text-warning-base': _vm.color === 'warning',
			'before:tw-text-info-base': _vm.color === 'info',
			'before:tw-text-primary-base': _vm.color === 'primary',
			'before:tw-text-accent': _vm.color === 'accent',
		},
	]},'v-icon',_vm.$attrs,false),_vm.$listeners),[_vm._v("\n\t"+_vm._s(_vm.icon)+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }