import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import { search } from '~/app/common/filters/iterator/Search';
import ProgrammaticPicker from '~/components/picker/ProgrammaticPicker.vue';
import ContactDisplay from '~/components/picker/ContactDisplay.vue';
import EmployeeDisplay from '~/components/picker/EmployeeDisplay.vue';
import ObjectDisplay from '~/components/picker/ObjectDisplay.vue';
//TODO: make picker configs domain specific
export var PickerConfig = {
  contacts: {
    elementKey: 'contact.id',
    component: shallowRef(ContactDisplay),
    // @ts-expect-error - TODO: fix this
    filterFn: function filterFn(elements, searchTerm, appliedFilters) {
      if (!searchTerm && !Object.keys(appliedFilters).length) {
        return elements;
      }
      var filtered = _toConsumableArray(elements);
      if (searchTerm) {
        filtered = search(filtered, searchTerm, {
          keys: ['contact.firstName', 'contact.lastName', 'contact.emailAddress'],
          fuzzy: true,
          sort: true
        });
      }
      return filtered;
    }
  },
  employees: {
    component: shallowRef(EmployeeDisplay),
    elementKey: 'id'
  },
  objects: {
    component: shallowRef(ObjectDisplay),
    elementKey: 'id'
  }
};
export default function (_, inject) {
  var PickerConstructor = Vue.extend(ProgrammaticPicker);
  var currentInstance = null;
  function mountPicker() {
    var rootInstance = getRootInstance();
    if (currentInstance) {
      currentInstance.onSubmit();
    }
    var instance = new PickerConstructor({
      parent: rootInstance
    });
    instance.$mount();
    var etg24Element = document.getElementById('etg24');
    if (!etg24Element) {
      throw new Error('etg24 element not found');
    }
    etg24Element.appendChild(instance.$el);
    currentInstance = instance;
    return instance;
  }
  inject('pick', function (options) {
    return mountPicker().open(options);
  });
  inject('pickContacts', function (options) {
    return mountPicker().open(_objectSpread(_objectSpread({}, PickerConfig.contacts), options));
  });
  inject('pickEmployees', function (options) {
    return mountPicker().open(_objectSpread(_objectSpread({}, PickerConfig.employees), options));
  });
}
function getRootInstance() {
  var _document$getElementB;
  // @ts-expect-error - HtmlElement doesn't have __vue__ property
  return (_document$getElementB = document.getElementById('__nuxt')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.__vue__;
}