import { ref } from 'vue'
import { useAsyncData } from '@nuxt/bridge/dist/runtime/asyncData'
import { Ref } from '@nuxt/bridge/dist/runtime'

export function useCustomFetch<T extends (...args: unknown[]) => unknown>(key: string, fetcher: T) {
	const fetchState = ref({
		pending: true,
		error: null,
	})

	const data = ref<unknown>()

	const reusableFetch = async () => {
		try {
			data.value = await fetcher()
			fetchState.value.pending = false
		} catch (error) {
			// @ts-expect-error - unknown not assignable to Error
			fetchState.value.error = error
			fetchState.value.pending = false
		}
	}

	useAsyncData(key, reusableFetch)

	const refresh = async () => {
		fetchState.value.pending = true
		fetchState.value.error = null
		data.value = await reusableFetch()
	}

	return { data: data as Ref<Awaited<ReturnType<T>>>, fetchState, refresh }
}
