export const resolveApiScheme = (path: string) => {
	if (path.startsWith('etg24:')) {
		path = path.replace(/^etg24:internal:query:/, '/api/internal/v1/query/')
		path = path.replace(/^etg24:internal:command:/, '/api/internal/v1/exec/')
		path = path.replace(/^etg24:platform:query:/, '/api/platform/v1/query/')
		path = path.replace(/^etg24:platform:command:/, '/api/platform/v1/exec/')
		path = path.replace(/^etg24:auth:/, '/api/auth/')
	}
	return path
}
