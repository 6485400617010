
	import Image from '~/app/utils/globals/Image'
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { Process } from '~/app/domain/content/Process'

	@Component({})
	export default class ContextProcess extends Vue {
		@Prop({ default: false, type: Object }) process: Partial<Process>
		Image = Image

		openProcess() {
			this.$router.push({
				query: {
					...this.$route.query,
					contentType: 'process',
					contentId: this.process.id,
				},
			})
		}
	}
