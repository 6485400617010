
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import { Post } from '~/app/domain/content/Post'
	import PostActions from '~/components/domain/post/PostActions.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'
	import RichText from '~/components/utils/RichText.vue'
	import DateComponent from '~/components/utils/Date.vue'
	import PostEdit from '~/components/domain/post/PostEdit.vue'
	import { ScopeType } from '~/app/domain/notes/Note'

	@Component({
		components: {
			PostEdit,
			RichText,
			DetailCard,
			PostActions,
			Attachments,
			CategoryContainer,
			date: DateComponent,
		},
	})
	export default class PostDetails extends Vue {
		ScopeType = ScopeType
		@Model('change', { required: true }) post: Post
		@Prop({ default: false }) editable: boolean
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: String, required: true }) nodeId: string
		@Prop({ type: Array, default: () => [] }) nodes: Node[]
		Image = Image

		/**
		 * Global events
		 */
		updatePost(post) {
			this.$emit('updatePost', post)
			this.$nuxt.$emit('postUpdated', post)
		}

		postDeleted() {
			this.$emit('deleted')
			this.$nuxt.$emit('postDeleted', this.post)
		}

		get isScheduled() {
			return this.post.publishDate && this.$dateFns.isFuture(this.$dateFns.parseISO(this.post.publishDate))
		}

		/**
		 * Pages
		 */

		get qPage() {
			return this.$route.query.contentPage
		}

		setPage(page) {
			this.$router.push({
				query: {
					...this.$route.query,
					contentPage: page,
				},
			})
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		get parent() {
			return {
				text: this.post?.title,
				to: { query: { ...this.$route.query, contentPage: undefined } },
			}
		}
	}
