import { Middleware } from '@nuxt/types'

const accessMiddleware: Middleware = async ({ route, redirect, store, app, error }) => {
	// debug traces
	if (app.$config.isDev) console.log('new page:', route.path)

	// variables
	const routeSegments = route.path.split('/')
	if (app.$config.isDev && route.path.startsWith('/dev')) {
		return
	}
	const isApp = route.path.startsWith('/app')
	const isAdmin = route.path.startsWith('/admin')
	const subdomain =
		route.params.subdomain || (isApp && routeSegments.length > 1)
			? routeSegments[2] !== 'account'
				? routeSegments[2]
				: null
			: null
	const user = store.$auth.user
	const loggedIn = store.$auth.loggedIn
	const safeRoutes = ['login', 'forgot-password', 'password-recovery', 'register', 'register_key', 'logout_redirect']
	const accountPath = subdomain ? `/app/${subdomain}/account` : '/app/account'
	const oldVersion = `https://${subdomain}.${app.$config.appDomain}`
	let validRoute = null

	// routes that are allowed regardless of where you are
	const allowedRoutes = [
		`/app/${subdomain}/unknown`,
		`/app/${subdomain}/public/forms`,
		`/app/${subdomain}/account/no_access`,
		`/app/${subdomain}/property/knowledge`,
		`/app/${subdomain}/imprint`,
		'/app/portal',
		'/app/services',
		'/app/imprint',
		'/app/data-protection',
		'/permalink',
	]
	// check if we are in a native app
	if (store.getters['platform/isNative'] && !isApp) {
		redirect('/app')
	}
	for (const r of allowedRoutes) {
		if (route.path.startsWith(r)) {
			return
		}
	}

	// basic logged - app access
	if (loggedIn && !isApp && !isAdmin) {
		// redirect logged users to their my-etg page if they are outside the app
		return redirect(`${accountPath}/user`)
	} else if (!loggedIn && (isApp || isAdmin)) {
		// only these routes are accessible when logged out
		validRoute = safeRoutes.find((r) => {
			return route.path.startsWith(`${accountPath}/${r}`)
		})
		if (!validRoute) {
			app.$auth.$state.redirect = route.fullPath
			return redirect(`${accountPath}/login`)
		}
	} else if (!isApp && !isAdmin) {
		// default redirect if not in app
		redirect('/app')
	}

	// tenant related logic
	if (subdomain) {
		// fetch the tenant
		{
			// try vuex first
			const sTenant = store.state.tenant.tenant
			if (!sTenant || sTenant.subdomain !== subdomain) {
				// retrieve from the api otherwise
				try {
					const response = await app.$axios.get('etg24:platform:query:platform.tenant', {
						headers: { Tenant: subdomain || null },
					})
					await store.dispatch('tenant/setFromPlatformTenantResponse', response.data)
				} catch (e) {
					if (e.response) {
						return redirect(`/app/${subdomain}/unknown`)
					} else {
						error(e)
					}
				}
			}
		}

		// // check the user has access to this subdomain
		if (user && subdomain && !Object.keys(user.domains).includes(subdomain))
			return redirect(`/app/${subdomain}/account/no_access`)

		// if it's a valid route, return
		if (validRoute) return

		// check if we are trying to access compromised area
		let cArea = null
		for (const realm of ['property', 'provider']) {
			for (const access of ['bo', 'fo'])
				if (route.path.startsWith(`/app/${subdomain}/${realm}/${access}`))
					cArea = {
						realm,
						access,
					}
		}

		// if it's a compromised area check if have the required permissions, else redirect
		if (cArea) {
			// check if the feature is enabled
			// if (
			// 	!store.getters['features/isEnabled'](
			// 		`app:${cArea.realm}:${cArea.access}`
			// 	)
			// ) {
			// 	console.log(
			// 		`app:${cArea.realm}:${cArea.access} disabled, redirecting to legacy app`
			// 	)
			// 	return redirect(oldVersion)
			// }

			// find all the subdomains the user has access to
			const allTenants = Object.keys(user.domains || {})

			// check the access info for that subdomain
			if (!allTenants.includes(subdomain)) {
				return redirect(`${accountPath}/user`)
			}

			// get tenant info
			const tenantInfo = user.domains[subdomain]

			// find the user area for the subdomain
			const uArea = {
				realm: tenantInfo.tenant.tenantType === 'provider' ? 'provider' : 'property',
				access: tenantInfo.accessAreas.frontOffice ? 'fo' : 'bo',
			}

			//TODO: this is a workaround since while in Nuxt bridge, we have one meta object per API, remove when in vue
			const [obj1, obj2] = route.meta as { feature?: string }[]
			const metaObject = { ...(obj1 || {}), ...(obj2 || {}) }

			if (metaObject.feature && !store.getters['features/isEnabled'](metaObject.feature)) {
				redirect(`/app/${subdomain}/property/bo/upgrade`)
			}

			// redirect if the area we are trying to access is not permitted
			if (cArea.realm !== uArea.realm || cArea.access !== uArea.access) {
				return redirect(`/app/${subdomain}/${uArea.realm}/${uArea.access}`, route.query)
			}
		}
	}
}

export default accessMiddleware
