/**
 * Get the common first characters of a list of strings
 * @param strings The list of strings either as separate arguments or as an array
 * @returns The common first characters
 * @complexity O(n * m) where n is the length of the first string and m is the number of strings
 *
 * @example
 * ```ts
 * getCommonFirstCharacters('hello', 'helloworld', 'hello-world') // returns 'hello'
 * ```
 *
 * @example
 * ```ts
 * getCommonFirstCharacters(['hello', 'helloworld', 'hello-world']) // returns 'hello'
 * ```
 */
export default function getCommonFirstCharacters(...strings: string[] | [string[]]) {
	// allows the function to accept an array of strings or separate strings
	if (Array.isArray(strings[0])) {
		strings = strings[0]
	}

	if (strings.length <= 1) {
		return ''
	}

	let commonFirstCharacters = ''
	const firstString = strings[0] as string

	// for loop is used in favour of performance (breaks when a character is not common)
	for (let i = 0; i < firstString.length; i++) {
		const currentChar = firstString[i]

		if (strings.every((string) => string[i] === currentChar)) {
			commonFirstCharacters += currentChar
		} else {
			break
		}
	}

	return commonFirstCharacters
}
