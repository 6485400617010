export const state = () => ({
	initialized: false,
	processing: false,
	editors: [],
})

export const getters = {
	isInitialized: (state) => state.initialized,
	editors: (state) => state.editors,
}

export const mutations = {
	reset: (state) => {
		state.editors = null
		state.initialized = false
	},

	setResponse: (state, response) => {
		state.editors = response.withBackendRole
		state.initialized = true
	},
}

export const actions = {
	async fetchAll(context) {
		context.commit('reset')
		const response = (await this.$axios.get('etg24:internal:query:tenant.team')).data
		context.commit('setResponse', response)
	},
}
