/** Temporary, to be removed overall */
export function useContext() {
  return {
    $route: useRoute(),
    $router: useRouter(),
    $axios: useAxios(),
    $pickContacts: useContactPicker(),
    $i18n: useI18n(),
    $store: useVuex()
  };
}