var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.MaybeTooltip,{scopedSlots:_vm._u([(_vm.$slots.tooltip || _vm.tooltip)?{key:"tooltip",fn:function(){return [_vm._t("tooltip",function(){return [_vm._v(_vm._s(_vm.tooltip))]})]},proxy:true}:null],null,true)},[_c(_setup.MaybeLink,_vm._b({staticClass:"tw-inline-flex tw-justify-center tw-items-center tw-overflow-hidden focus:before:tw-opacity-10 active:before:tw-opacity-10",class:{
			'tw-bg-[var(--base)] tw-text-[var(--contrast)]': _vm.variant === 'default',
			'legacy:tw-shadow-md': _vm.variant === 'default' && _vm.color !== 'default',
			'tw-bg-transparent tw-text-[var(--base)]': _vm.variant === 'text',
			'tw-bg-transparent tw-text-[var(--base)] tw-ring-1 tw-ring-[var(--base)]': _vm.variant === 'outlined',
			'tw-bg-transparent tw-text-[var(--base)] tw-border-dashed tw-border tw-border-[var(--base)]':
				_vm.variant === 'dashed',
			'hoverable tw-cursor-pointer': !_vm.disabled,
			'tw-pointer-events-none tw-cursor-not-allowed tw-opacity-50': _vm.disabled,
			'tw-text-lg tw-font-medium': _vm.size === 'lg' || 'xl',
			'tw-text-base tw-font-semibold': _vm.size === 'md',
			'tw-text-sm tw-font-semibold': _vm.size === 'sm',
			'tw-text-xs tw-font-semibold': _vm.size === 'xs',
			'tw-h-14': _vm.size === 'xl' && !_vm.dense,
			'tw-h-12': _vm.size === 'lg' && !_vm.dense,
			'tw-h-11': (_vm.size === 'md' && !_vm.dense) || (_vm.size === 'xl' && _vm.dense),
			'tw-h-9': (_vm.size === 'sm' && !_vm.dense) || (_vm.size === 'lg' && _vm.dense),
			'tw-h-8': (_vm.size === 'xs' && !_vm.dense) || (_vm.size === 'md' && _vm.dense),
			'tw-h-7': _vm.size === 'sm' && _vm.dense,
			'tw-h-6': _vm.size === 'xs' && _vm.dense,
			'tw-px-8': _vm.size === 'xl' && !_vm.dense && !(_vm.icon && _vm.rounded),
			'tw-px-6': _vm.size === 'lg' && !_vm.dense && !(_vm.icon && _vm.rounded),
			'tw-px-5': ((_vm.size === 'md' && !_vm.dense) || (_vm.size === 'xl' && _vm.dense)) && !(_vm.icon && _vm.rounded),
			'tw-px-4': ((_vm.size === 'sm' && !_vm.dense) || (_vm.size === 'lg' && _vm.dense)) && !(_vm.icon && _vm.rounded),
			'tw-px-3': ((_vm.size === 'xs' && !_vm.dense) || (_vm.size === 'md' && _vm.dense)) && !(_vm.icon && _vm.rounded),
			'tw-px-2': (_vm.size === 'sm' || 'xs') && _vm.dense && !(_vm.icon && _vm.rounded),
			'tw-aspect-square': _vm.icon && _vm.rounded,
			'tw-rounded-full': _vm.rounded,
			'tw-rounded': !_vm.rounded,
			'tw-uppercase': _vm.transform === 'uppercase',
			'tw-lowercase': _vm.transform === 'lowercase',
			'tw-capitalize': _vm.transform === 'capitalize',
			'tw-w-full': _vm.$slots.tooltip || _vm.tooltip,
		},style:(`--base: ${_setup.baseColors[_vm.color]}; --contrast: ${_setup.contrastColors[_vm.color]};`),attrs:{"tag":_vm.tag,"active-class":_vm.exact ? undefined : _setup.activeClass,"exact-active-class":_vm.exact ? _setup.activeClass : undefined},on:{"click":function($event){return _setup.emit('click', $event)}}},'MaybeLink',{ ..._vm.$attrs, ..._vm.link },false),[(!_vm.icon)?[(_vm.$slots.left || _vm.leftIcon)?_c('span',{staticClass:"tw-mr-2",class:{
					'tw-opacity-0': _vm.loading,
				}},[_vm._t("left",function(){return [_c(_setup.UiIcon,{class:{
							'before:tw-text-[var(--contrast)]': _vm.variant === 'default' || _vm.color === 'default',
							'before:tw-text-[var(--base)]': _vm.variant !== 'default' && _vm.color !== 'default',
						},attrs:{"size":_vm.size,"icon":_vm.leftIcon}})]})],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tw-relative tw-leading-normal tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap",class:{
					'tw-text-[var(--contrast)]': _vm.variant === 'default' || _vm.color === 'default',
					'tw-text-[var(--base)]': _vm.variant !== 'default' && _vm.color !== 'default',
					'tw-opacity-0': _vm.loading,
				}},[_vm._t("default",function(){return [_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.text)+"\n\t\t\t\t")]})],2),_vm._v(" "),(_vm.$slots.right || _vm.rightIcon)?_c('span',{staticClass:"tw-ml-2",class:{
					'tw-opacity-0': _vm.loading,
				}},[_vm._t("right",function(){return [_c(_setup.UiIcon,{class:{
							'before:tw-text-[var(--contrast)]': _vm.variant === 'default' || _vm.color === 'default',
							'before:tw-text-[var(--base)]': _vm.variant !== 'default' && _vm.color !== 'default',
						},attrs:{"size":_vm.size,"icon":_vm.rightIcon}})]})],2):_vm._e()]:_c(_setup.UiIcon,{class:{
				'before:tw-text-[var(--contrast)]': _vm.variant === 'default' || _vm.color === 'default',
				'before:tw-text-[var(--base)]': _vm.variant !== 'default' && _vm.color !== 'default',
				'tw-opacity-0': _vm.loading,
			},attrs:{"size":`${_vm.size}+2`,"icon":_vm.icon}}),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-transparent"},[_c(_setup.UiIcon,{staticClass:"tw-animate-spin",class:{
					'before:tw-text-[var(--contrast)]': _vm.variant === 'default' || _vm.color === 'default',
					'before:tw-text-[var(--base)]': _vm.variant !== 'default' && _vm.color !== 'default',
				},attrs:{"size":`${_vm.size}+2`,"icon":"fal fa-spinner-third"}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }