/**
 * - Joins an array of strings into a single string with a glue between all items and a different glue between the last two items.
 * @param items - The array of strings to join.
 * @param glue - The glue to use between all items.
 * @param lastGlue - The glue to use between the last two items.
 * @returns - The joined string.
 * @complexity O(n) - Where n is the length of the items array.
 * @example
 * ```ts
 * enumerate(['a', 'b', 'c']) // 'a, b und c'
 * enumerate(['a', 'b']) // 'a und b'
 * ```
 *
 * @example
 * ```ts
 * enumerate(['a', 'b', 'c'], '; ', ' or ') // 'a; b or c'
 * ```
 */
export default function enumerate(items: string[], glue = ', ', lastGlue = ' und ') {
	switch (items.length) {
		case 0:
			return ''
		case 1:
			return items[0]
		default:
			return items.slice(0, -1).join(glue) + lastGlue + items[items.length - 1]
	}
}
