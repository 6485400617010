import { Ref, watch } from 'vue'
import { nextTick } from 'vue'

export default function useFocusTrap(element: Ref<HTMLElement | undefined>, activeOn: Ref<boolean>, delay = 300) {
	const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
	// eslint-disable-next-line no-undef
	let focusableElements: NodeListOf<HTMLElement> | null = null
	let lastActiveElement: HTMLElement | null = null

	function trapFocus(event: KeyboardEvent) {
		if (focusableElements && event.key === 'Tab') {
			const firstFocusableElement = focusableElements[0]
			const lastFocusableElement = focusableElements[focusableElements.length - 1]

			if (event.shiftKey) {
				if (document.activeElement === firstFocusableElement) {
					lastFocusableElement.focus()
					event.preventDefault()
				}
			} else {
				if (document.activeElement === lastFocusableElement) {
					firstFocusableElement.focus()
					event.preventDefault()
				}
			}
		}
	}

	watch(
		activeOn,
		async (isActive) => {
			if (isActive) {
				setTimeout(async () => {
					lastActiveElement = document.activeElement as HTMLElement
					await nextTick()
					if (!element.value) return
					focusableElements = element.value.querySelectorAll(focusableSelector)

					if (focusableElements[0]) {
						focusableElements[0].focus()
					}

					await nextTick()
					window.addEventListener('keydown', trapFocus)
				}, delay)

				return
			}

			lastActiveElement?.focus()
			window.removeEventListener('keydown', trapFocus)
		},
		{ immediate: true },
	)
}
