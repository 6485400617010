/**
 * Returns the keys of an object as an array of strings while preserving the type of the keys.
 * @param obj The object to get the keys from
 * @returns An array of the keys of the object
 * @complexity O(1)
 *
 * @example
 * ```ts
 * // test is of type ('a' | 'b')[]
 * const test = objectKeys({ a: 1, b: 2 }) // returns ['a', 'b']
 * ```
 */
export default function objectKeys<T extends object>(obj: T) {
	return Object.keys(obj) as (keyof T)[]
}
