/**
 * - Waits for the difference between the current time and the start time to be greater than the minimum time
 * @param start - The start time
 * @param min - The minimum time to wait
 * @returns - A promise that resolves after the minimum time has passed
 * @complexity O(1)
 *
 * @example
 * ```ts
 * const start = +new Date()
 * await sleepDiff(start, 1000)
 * console.log('This will be printed after 1 second')
 * ```
 *
 * @example
 * ```ts
 * const start = +new Date()
 * await sleep(500)
 * await sleepDiff(start, 1000)
 * console.log('This will be printed after 1 second')
 * ```
 */
export default function sleepDiff(start: number, min: number): Promise<void> {
	const currentTime = +new Date()
	const diff = currentTime - start
	if (diff < min) {
		// If the difference is less than min, wait for the remaining time
		return new Promise((resolve) => setTimeout(resolve, min - diff))
	}
	// If the difference is already greater than or equal to min, resolve immediately
	return Promise.resolve()
}
