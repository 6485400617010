var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.item)?_c('div',{staticClass:"pb-6"},[(_setup.actions.length > 0)?_c('div',{staticClass:"pb-6"},[_c('div',{class:{
				title: true,
				'primary--text': !_vm.dark,
				'pb-1': true,
				'white--text': _vm.dark,
			}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('layout.actions'))+"\n\t\t")]),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_vm._l((_setup.actions),function(action,actionId){return [_c('v-col',{key:actionId,attrs:{"cols":action.size || 12}},[_c(_setup.ActionButton,{attrs:{"btn":action,"disabled":action.disabled}})],1)]}),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.ActionButton,{attrs:{"btn":{
						icon: 'fal fa-chevron-left',
						callback: () => _setup.emit('previous'),
						attributes: {
							'data-qa': 'votingItem-actions:previous',
						},
					},"disabled":_setup.item.number === 1}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[(_setup.item.number < _setup.voting.items.length)?_c(_setup.ActionButton,{attrs:{"btn":{
						icon: 'fal fa-chevron-right',
						callback: () => _setup.emit('next'),
						attributes: {
							'data-qa': 'votingItem-actions:next',
						},
					}}}):(_setup.gCanCreateItems)?_c('v-btn',{attrs:{"color":"primary","block":"","height":"50","nuxt":"","disabled":_setup.voting.status === 'published'},on:{"click":function($event){return _vm.$emit('changePage', { page: `itemEdit` })}}},[_c('v-icon',[_vm._v("fal fa-plus")])],1):_vm._e()],1),_vm._v(" "),(_setup.canEditItem && _setup.voting.items.length > 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","max-height":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"action-card action-card--hover c-btn justify-btn-left",attrs:{"width":"100%","min-height":"50","height":"100%","loading":_setup.settingPosition}},on),[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("fal fa-sort")]),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('domain.voting.items.action.reorder.button'))+"\n\t\t\t\t\t\t")],1)]}}],null,false,2569298600)},[_vm._v(" "),_c('v-list',[_vm._l((_setup.voting.items),function(order,orderId){return [_c('v-list-item',{key:order.number,attrs:{"disabled":order.number === _setup.item.number},on:{"click":function($event){return _setup.setItemPosition(orderId)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t(`domain.voting.items.action.reorder.option`, {
												position: order.number,
											}))+"\n\t\t\t\t\t\t\t\t\t")])],1)],1)]})],2)],1)],1):_vm._e(),_vm._v(" "),(_setup.mainAction)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',_vm._b({attrs:{"color":"primary","height":"50","block":"","large":"","loading":_setup.mainActionLoading,"disabled":_setup.mainAction.disabled},on:{"click":_setup.mainAction.callback}},'v-btn',_setup.mainAction.attributes,false),[_vm._v("\n\t\t\t\t\t"+_vm._s(_setup.mainAction.text)+"\n\t\t\t\t")])],1):_vm._e(),_vm._v(" "),(_vm.$store.getters['features/isEnabled']('content:processes'))?_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.ActionButton,{attrs:{"btn":_setup.linkToProcessBtn,"dashed":!_setup.item?.process},scopedSlots:_vm._u([(_setup.item.process)?{key:"rightAction",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","loading":_setup.linkingProcess,"data-qa":"votingItem-actions:unlink-from-process"},on:{"click":function($event){$event.stopPropagation();return _setup.selectProcess(null)}}},on),[_c('v-icon',[_vm._v("fal fa-times")])],1)]}}],null,false,672937819)},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('domain.process.actions.unlinkFromProcess'))+"\n\t\t\t\t\t\t\t")])])]},proxy:true}:null],null,true)})],1):_vm._e(),_vm._v(" "),(_setup.canCreateAddendum)?_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.ActionButton,{attrs:{"btn":{
						icon: 'fal fa-thumbtack',
						text: _vm.$t('domain.voting.item.addendum.actions.create.label'),
						attributes: {
							'data-qa': 'votingItem-actions:createAddendum',
						},
					}},on:{"click":function($event){return _vm.$emit('newAddendum')}}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.NotesList,{staticClass:"mt-4",attrs:{"scope-type":_setup.ScopeType['communication.votingItem'],"scope":_setup.item.id},model:{value:(_setup.notes),callback:function ($$v) {_setup.notes=$$v},expression:"notes"}})],1)],2)],1):_vm._e(),_vm._v(" "),_c(_setup.ConfirmationDialog,{attrs:{"confirm-fn":_setup.deleteItem,"btn-color":"error"},model:{value:(_setup.deleteDialog),callback:function ($$v) {_setup.deleteDialog=$$v},expression:"deleteDialog"}},[_c('div',[_c('div',{staticClass:"error--text headline pb-4"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('layout.danger'))+"\n\t\t\t")]),_vm._v(" "),_c('div',{directives:[{name:"user-content",rawName:"v-user-content",value:(_vm.$t('domain.voting.item.deleteText')),expression:"$t('domain.voting.item.deleteText')"}],staticClass:"black--text"})])]),_vm._v(" "),_c(_setup.BasicDialog,{attrs:{"title":_vm.$t('domain.process.actions.linkToProcess'),"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":1400},model:{value:(_setup.linkToProcessDialog),callback:function ($$v) {_setup.linkToProcessDialog=$$v},expression:"linkToProcessDialog"}},[(_setup.nodeId)?_c('div',[_c(_setup.ProcessList,{attrs:{"node-id":_setup.nodeId,"editable":true,"help-btn":false},on:{"click":_setup.selectProcess,"new":_setup.newProcess}})],1):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }