import { render, staticRenderFns } from "./DeliveryDraftBatchErrors.vue?vue&type=template&id=7c7ccc77&scoped=true"
import script from "./DeliveryDraftBatchErrors.vue?vue&type=script&setup=true&lang=ts"
export * from "./DeliveryDraftBatchErrors.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7ccc77",
  null
  
)

export default component.exports