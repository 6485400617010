
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { getNodeBreadcrumbs, Node, NodeId } from '~/app/domain/content/Common'
	import { getContextNode } from '~/app/domain/utility/Routes'

	@Component({})
	export default class ContextLocation extends Vue {
		@Prop({ required: true, type: String }) nodeId: NodeId
		@Prop({ required: true, type: Array }) nodes: Node[]
		@Prop({ type: Boolean, default: false }) textOnly: boolean

		get contextNodeId() {
			return getContextNode(this.$route) || this.$store.getters['tenant/rootNodeId']
		}

		get locations() {
			if (this.nodes.length)
				return getNodeBreadcrumbs(this.contextNodeId, this.nodeId, this.nodes).map((node) => ({
					node,
					uri: this.$store.getters['property/bo/routes/pathToLocationNode'](node.id, this.nodes),
				}))
		}

		get show() {
			return this.contextNodeId && !!this.locations?.length
		}

		get uri() {
			return this.$store.getters['routing/pathToLocationNode'](this.nodeId, this.nodes)
		}
	}
