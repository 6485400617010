
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Prop, Watch, Model } from 'nuxt-property-decorator'
	import { getNoteLists, Note, ScopeType } from '~/app/domain/notes/Note'
	import NoteCard from '~/components/domain/notes/NoteCard.vue'
	import NoteEdit from '~/components/domain/notes/NoteEdit.vue'

	@Component({
		components: { NoteEdit, NoteCard },
	})
	export default class NotesList extends Vue {
		Utils = Utils
		@Model('changed', { default: null }) notes: Note[]
		@Prop({ required: true }) scope: string
		@Prop({ type: String, required: true }) scopeType: ScopeType
		@Prop({ default: false }) dark: boolean
		@Prop({ default: true }) title: boolean
		@Prop({ default: () => [] }) nodes: Node[]

		/**
		 * Load the notes
		 */
		noteLists = null
		localNotes: Note[] = []
		@Watch('notes', { immediate: true }) notesUpdated() {
			this.localNotes = this.notes
		}

		@Watch('localNotes', { immediate: true }) localNotesUpdated() {
			if (this.localNotes) {
				this.noteLists = getNoteLists(this.localNotes, this.$auth.user.id)
				if (this.activeTab) {
					for (const cat of Object.keys(this.noteLists)) {
						if (!this.activeTab && this.noteLists[cat].length) {
							this.activeTab = cat
							break
						}
					}
					if (!this.activeTab) this.activeTab = Object.keys(this.noteLists)[0]
				} else {
					this.activeTab = 'relevant'
				}
			}
			this.$emit('changed', this.localNotes)
		}

		/**
		 * Tabs
		 */
		activeTab: string = null
		get categories() {
			return [
				{
					text: this.$t('domain.note.types.relevant'),
					tooltip: this.$t('domain.note.tooltips.relevant'),
					value: 'relevant',
				},
				{
					text: this.$t('domain.note.types.others'),
					tooltip: this.$t('domain.note.tooltips.others'),
					value: 'others',
				},
				{
					text: this.$t('domain.note.types.done'),
					tooltip: this.$t('domain.note.tooltips.done'),
					value: 'done',
				},
			].map((c) => {
				return {
					...c,
					class: this.getCategoryStyle(c.value),
				}
			})
		}

		getCategoryStyle(cat) {
			if (this.noteLists) {
				if (cat === this.activeTab) return 'list-active'
				else if (this.noteLists[cat].length === 0) return 'list-disabled'
				else return 'list-inactive'
			}
		}

		selectTab(tab) {
			this.activeTab = tab.value
		}

		/**
		 * Note edition
		 */
		editDialog = false
		newNote = false
		editingNote: Note | {} = null
		createNote() {
			this.editingNote = {}
			this.newNote = true
			this.editDialog = true
		}

		async editNote(note) {
			try {
				const fetchedNote = (await this.$axios.$get(`etg24:internal:query:notes.detail?note=${note.id}`)).note
				this.editingNote = { ...fetchedNote }
				this.newNote = false
				this.editDialog = true
			} catch (e) {
				Utils.handleError(e)
			}
		}

		noteAdded(note) {
			this.localNotes.push(note)
		}

		noteUpdated(note) {
			const index = this.localNotes.findIndex((i) => i.id === note.id)
			if (index >= 0) this.localNotes.splice(index, 1, note)
		}

		noteDeleted(note) {
			const nIndex = this.localNotes.findIndex((n) => {
				return n.id === note.id
			})
			if (nIndex >= 0) this.localNotes.splice(nIndex, 1)
		}

		get enabled() {
			const path = Utils.appPath()
			return path.startsWith(`/app/${this.$route.params.subdomain}/property`) || path.startsWith('/app/property')
		}
	}
