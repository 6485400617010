var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-w-full tw-gap-2"},_vm._l((Object.values(_vm.objects)),function(objectData){return _c('div',{key:objectData.object.id},[_c('div',{staticClass:"tw-mb-1"},[_c(_setup.UiButton,{attrs:{"variant":"text","size":"sm","transform":"none","exact":"","dense":"","to":`${_setup.appPath}/objects/${objectData.object.id}`}},[_vm._v("\n\t\t\t\t"+_vm._s(objectData.object.title)+"\n\t\t\t")])],1),_vm._v(" "),_c('div',{staticClass:"tw-p-1 tw-flex tw-flex-wrap tw-gap-1.5"},[(objectData.roleTypes && objectData.roleTypes.length > 0)?_c('div',{staticClass:"tw-flex tw-gap-1"},_vm._l((objectData.roleTypes),function(role,roleIndex){return _c(_setup.UiButton,{key:roleIndex,attrs:{"outlined":"","dense":"","size":"sm","variant":"outlined","to":`${_setup.appPath}/objects/${objectData.object.id}/users/${role}`}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$tc(`domain.user.role.${role}`, 1))+"\n\t\t\t\t")])}),1):_vm._e(),_vm._v(" "),_vm._l((Object.values(objectData.units)),function(unitData){return _c('div',{key:unitData.unit.id,staticClass:"tw-flex tw-items-center tw-w-full tw-gap-2"},[_c(_setup.UiButton,{staticClass:"tw-shrink-0",attrs:{"color":"primary","size":"sm","transform":"none","to":`${_setup.appPath}/objects/${objectData.object.id}/units/${unitData.unit.id}`}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$tc('domain.unit.base', 1))+"\n\t\t\t\t\t"+_vm._s(unitData.unit.number)+"\n\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"tw-shrink-0 tw-grow tw-flex tw-flex-col tw-gap-1.5"},_vm._l((Object.values(unitData.contracts)),function(contractData,contractIndex){return _c('div',{key:contractIndex,staticClass:"tw-flex tw-w-full tw-items-center tw-gap-1"},[_c(_setup.UiButton,{key:contractData.contract.id,class:{
								'tw-w-full tw-max-w-64 tw-shrink': _vm.hideSignatories,
								'tw-w-1/3': !_vm.hideSignatories,
							},attrs:{"to":`${_setup.appPath}/objects/${objectData.object.id}/units/${unitData.unit.id}/contracts/${contractData.contract.id}`,"variant":"outlined","size":"sm","transform":"none"}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_setup.getContractLabel(contractData.contract))+"\n\t\t\t\t\t\t")]),_vm._v(" "),(!_vm.hideSignatories)?_c('div',{staticClass:"tw-flex tw-w-2/3 tw-items-start tw-grow tw-flex-col tw-gap-1"},_vm._l((contractData.signatories),function(signatory){return _c(_setup.ContractSignatoryChip,{key:signatory.id,staticClass:"tw-w-full !tw-bg-background-stronger",attrs:{"signatory":signatory,"no-ripple":""}})}),1):_vm._e()],1)}),0)],1)}),_vm._v(" "),(objectData.responsibilities.length)?_vm._l((objectData.responsibilities),function(responsibilityData){return _c(_setup.UiButton,{key:responsibilityData.responsibility.id,attrs:{"left-icon":responsibilityData.responsibility.isInternal ? 'fal fa-eye-slash' : undefined,"variant":"outlined","color":"accent","dense":"","size":"sm","tooltip":responsibilityData.responsibility.isInternal ? String(_vm.$t('layout.internal')) : undefined,"exact":"","to":{
						..._vm.$route,
						query: {
							..._vm.$route.query,
							contentType: 'contactPersonNode',
							contentId: `${responsibilityData.responsibility.contactPersonId}_${objectData.object.id}`,
						},
					}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(responsibilityData.category.title)+"\n\t\t\t\t")])}):_vm._e()],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }