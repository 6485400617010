import { PhoneNumberString, ShortString, Uuid } from '../Types'

export enum PhoneNumberType {
	'FIXED_LINE' = 'FIXED_LINE',
	'MOBILE' = 'MOBILE',
	'FIXED_LINE_OR_MOBILE' = 'FIXED_LINE_OR_MOBILE',
	'TOLL_FREE' = 'TOLL_FREE',
	'PREMIUM_RATE' = 'PREMIUM_RATE',
	'SHARED_COST' = 'SHARED_COST',
	'VOIP' = 'VOIP',
	'PERSONAL_NUMBER' = 'PERSONAL_NUMBER',
	'PAGER' = 'PAGER',
	'UAN' = 'UAN',
	'UNKNOWN' = 'UNKNOWN',
	'EMERGENCY' = 'EMERGENCY',
	'VOICEMAIL' = 'VOICEMAIL',
	'SHORT_CODE' = 'SHORT_CODE',
	'STANDARD_RATE' = 'STANDARD_RATE',
}

export const libPhoneNumberToVCardTypeMap = {
	[PhoneNumberType.MOBILE]: 'CELL',
	[PhoneNumberType.FIXED_LINE]: 'HOME',
	[PhoneNumberType.PAGER]: 'PAGER',
	[PhoneNumberType.PERSONAL_NUMBER]: 'PCS',
	[PhoneNumberType.FIXED_LINE_OR_MOBILE]: 'CELL',
	[PhoneNumberType.VOIP]: 'VOICE',
	[PhoneNumberType.TOLL_FREE]: 'VOICE',
	[PhoneNumberType.PREMIUM_RATE]: 'VOICE',
	[PhoneNumberType.SHARED_COST]: 'VOICE',
	[PhoneNumberType.VOICEMAIL]: 'VOICE',
	[PhoneNumberType.SHORT_CODE]: 'VOICE',
	[PhoneNumberType.STANDARD_RATE]: 'VOICE',
}

export interface PhoneNumber {
	id: Uuid
	number: PhoneNumberString
	isValid: boolean
	type: PhoneNumberType
	note: ShortString | null
}

export const isMobilePhoneNumber = (phoneNumber: PhoneNumber) => {
	return [PhoneNumberType.MOBILE].includes(phoneNumber.type)
}

export const isLandlinePhoneNumber = (phoneNumber: PhoneNumber) => {
	return [PhoneNumberType.FIXED_LINE].includes(phoneNumber.type)
}

export const isEmergencyPhoneNumber = (phoneNumber: PhoneNumber) => {
	return [PhoneNumberType.EMERGENCY].includes(phoneNumber.type)
}

export const getPhoneNumberLabel = (phoneNumber: Partial<PhoneNumber> | null) => {
	let label =
		phoneNumber?.type && $nuxt.$te(`domain.telephone.type.${phoneNumber.type}.label`)
			? $nuxt.$t(`domain.telephone.type.${phoneNumber.type}.label`)
			: $nuxt.$t('layout.phone')
	if (phoneNumber?.note) {
		label += ` - ${phoneNumber.note}`
	}
	return label
}
