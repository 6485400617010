
	import Utils from '~/app/utils/globals/Utils'
	import { Vue, Component } from 'nuxt-property-decorator'
	import UserMenu from '~/components/navigation/elements/UserMenu.vue'
	import SubdomainSelect from '~/components/navigation/elements/SubdomainSelect.vue'
	import QrReader from '~/components/utils/QrReader.vue'
	import Notifications from '~/pages/app/account/user/index/notifications.vue'
	import NotificationsMenu from '@modules/notifications/components/NotificationsMenu.vue'
	import ConversationNotifications from '~/components/domain/conversation/ConversationNotifications.vue'
	import DeliveryNotifications from '~/components/domain/deliveries/DeliveryNotifications.vue'
	@Component({
		components: {
			DeliveryNotifications,
			ConversationNotifications,
			NotificationsMenu,
			Notifications,
			QrReader,
			SubdomainSelect,
			UserMenu,
		},
	})
	export default class FoToolbar extends Vue {
		Utils = Utils
		get tenant() {
			const t = this.$store.state.tenant
			return t && t.tenant
		}

		get noNav() {
			const cRoute = this.$route.path
			return cRoute.startsWith('/app/account') || cRoute.startsWith(`${Utils.appPath()}/knowledge`)
		}

		get country() {
			return (this.tenant && this.tenant.country) || this.$auth.user.locale
		}

		get appPath() {
			return Utils.appPath()
		}

		get knowledgeBaseEnabled() {
			if (!this.appPath) {
				return false
			}
			return (
				['de'].includes(this.country) &&
				this.appPath.startsWith(`/app/${this.$route.params.subdomain}/property/fo`)
			)
		}

		qrDialog: boolean = false

		get deliveriesPath() {
			return `${Utils.appPath()}/deliveries`
		}
	}
