
	import { Component, Vue } from 'nuxt-property-decorator'
	import LanguageSwitch from '~/components/inputs/LanguageSwitch.vue'
	import FooterBranding from '~/components/domain/tenant/branding/FooterBranding.vue'

	@Component({
		components: { LanguageSwitch, FooterBranding },
	})
	export default class FooterDefault extends Vue {
		get tenant() {
			const t = this.$store.state.tenant
			return t && t.tenant
		}

		get branding() {
			const t = this.$store.state.tenant
			return t && t.branding
		}

		get showBranding() {
			return this.$route.path.startsWith(`/app/${this.$route.params.subdomain}`)
		}

		get imprintPath() {
			const sd = this.$route.params.subdomain
			return sd ? `/app/${sd}/imprint` : '/app/imprint'
		}
	}
