
	import { Component, Model, Vue } from 'nuxt-property-decorator'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import { ConfirmationStatus, Order, OrderStatus } from '~/app/domain/content/Process'
	import formatDate from '~/app/common/filters/Date'

	@Component({
		components: {
			CardLargeContainer,
		},
	})
	export default class OrderCard extends Vue {
		@Model('change', { required: true }) value: Order

		formatDate = formatDate

		get orderStatus() {
			const config = {
				text: this.$t(`domain.process.order.orderStatus.${this.value.orderStatus}`),
				class: '',
			}
			switch (this.value.orderStatus) {
				case OrderStatus.draft:
				case OrderStatus.cancelled:
					config.class = 'grey--text'
			}
			return config
		}

		get confirmationStatus() {
			if (!this.value.confirmationStatus) {
				return
			}
			const config = {
				text: this.$t(`domain.process.order.confirmationStatus.${this.value.confirmationStatus}`),
				class: '',
			}
			switch (this.value.confirmationStatus) {
				case ConfirmationStatus.rejected:
				case ConfirmationStatus.expired:
					config.class = 'secondary--text'
					break
			}
			return config
		}
	}
