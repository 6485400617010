/**
 * vue.js filter: renders the provided name as string
 */
export function name(
	firstName?: string | null,
	lastName?: string | null,
	salutation?: string | null,
	formal = true,
): string {
	return formal
		? `${salutation ? salutation + ' ' : ''}${lastName || '?'}${firstName ? ', ' + firstName : ''}`
		: [salutation, firstName, lastName].filter((e) => e).join(' ')
}
