import { DateString, HtmlString, ShortString, Uuid } from '../Types'
import { Entity, EntityId } from '../common/Interfaces'
import { Node, NodeId } from '../content/Common'
import { MicroUser, microUserSearchKeys, UserId } from '../identifyAndAccess/User'
import { AssetAttachments } from '../assets/Attachments'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import sort from '~/app/common/filters/iterator/Sort'
import { FileAsset } from '~/app/domain/assets/File'
import { ImageAsset } from '~/app/domain/assets/Image'
import { Document } from '~/app/domain/content/Document'
import { isBefore, parseISO } from 'date-fns'
import { Provider } from '~/app/domain/content/ContactPerson'
import { ProcessId } from '~/app/domain/content/Process'
import { Email } from '~/app/domain/content/InboundEmail'

/*
 * Model
 */

export enum EventType {
	Message = 'Message',
	OpenStateChanged = 'OpenStateChanged',
	ParticipantAdded = 'ParticipantAdded',
	ParticipantActivationChanged = 'ParticipantActivationChanged',
}

export type EventId = EntityId
export interface Event extends Entity<EventId> {
	eventType: EventType
	occurredOn: DateString
	causedById: ParticipantId
}

export const eventSearchKeys: (keyof Event)[] = ['text' as keyof Event]

export interface Message extends Event {
	text: HtmlString
	files: FileAsset[]
	images: ImageAsset[]
	documents?: Document[]
	isConfidential: boolean
	email?: Email
}
export function isMessage(event: Event): event is Message {
	return event.eventType === EventType.Message
}

export interface OpenStateChanged extends Event {
	isOpen: boolean
}
export function isOpenStateChanged(event: Event): event is OpenStateChanged {
	return event.eventType === EventType.OpenStateChanged
}

export interface ParticipantAdded extends Event {
	participantId: ParticipantId
}
export function isParticipantAdded(event: Event): event is ParticipantAdded {
	return event.eventType === EventType.ParticipantAdded
}

export interface ParticipantActivationChanged extends Event {
	isActive: boolean
	participantId: ParticipantId
}
export function isParticipantActivationChanged(event: Event): event is ParticipantActivationChanged {
	return event.eventType === EventType.ParticipantActivationChanged
}

export type ParticipantId = EntityId
export enum ParticipantType {
	user = 'user',
	provider = 'provider',
}
export interface Participant extends Entity<ParticipantId> {
	type: ParticipantType
	user: MicroUser | null
	provider: Provider | null
	isTenantRepresentative: boolean
	isActive: boolean
	readOn: DateString
	isSatisfied: boolean
}

export enum NotificationType {
	newConversation = 'newConversation',
	newConversationMessage = 'newConversationMessage',
}
export interface Notification {
	id: Uuid
	isRead: boolean
	isNoticed: boolean
	type: string
	occurredOn: DateString
	data: {
		type: NotificationType
		conversation: {
			id: ConversationId
			title: ShortString
		}
		messages: {
			id: EventId
			html: HtmlString
			occurredOn: DateString
			author: Participant
		}[]
	}
}

/*
 * Validation
 */

/*
 * Search
 */

export const participantSearchKeys: (keyof Participant)[] = [
	...(prefixSearchKeys('user', microUserSearchKeys) as (keyof Participant)[]),
]

export interface ScheduledMessage {
	participantId: ParticipantId
	sendOn: DateString
	text: HtmlString
	files: FileAsset[]
	images: ImageAsset[]
	isConfidential: boolean
}

export interface MessageDraft {
	sendOn: DateString
	text: HtmlString
	files: FileAsset[]
	images: ImageAsset[]
}

export type ConversationId = EntityId
export interface ConversationListView extends Entity<ConversationId> {
	nodeId: NodeId
	lastMessagedOn: DateString | null
	lastActivityOn: DateString
	participants: Participant[]
	events: Event[]
	isOpen: boolean
	needsAnswer: boolean
	isWaiting: boolean
	waitUntil: DateString | null
	title: ShortString
	process: { id: ProcessId; title: ShortString } | null
}
export interface Conversation extends ConversationListView {
	draft: MessageDraft
	allowScheduledReplies: boolean
	scheduledMessages: ScheduledMessage[]
	assignees: MicroUser[]
}

export interface ConversationResponse {
	conversation: Conversation
	nodes: Node[]
}

export const conversationSearchKeys: (keyof Conversation)[] = [
	'title',
	...(prefixSearchKeys('participants', participantSearchKeys) as (keyof Conversation)[]),
	...(prefixSearchKeys('events', eventSearchKeys) as (keyof Conversation)[]),
]

/*
 * Api
 */

export interface ConversationsQueryResponse {
	conversations: Conversation[]
	nodes: Node[]
}

/*
 * Functions
 */

export function getMessages(conversation: Conversation): Message[] {
	return [...conversation.events].filter(isMessage)
}
export function getLatestMessage(conversation: Conversation): Message {
	// we expect conversation events to be in chronological order
	return [...conversation.events].reverse().find(isMessage)
}
export function getFirstMessage(conversation: Conversation): Message {
	// we expect conversation events to be in chronological order
	return [...conversation.events].find(isMessage)
}

export function getParticipantFromEvent(conversation: Conversation, event: Event) {
	return conversation.participants.find((participant: Participant) => participant.id === event.causedById)
}

// tells if at least one tenant representative has responded
export function hasAnswer(conversation: Conversation): boolean {
	const representativeIds = conversation.participants
		.filter((participant: Participant) => participant.isTenantRepresentative)
		.map((participant: Participant) => participant.id)

	return conversation.events
		.filter(isMessage)
		.some((message: Message) => representativeIds.includes(message.causedById))
}
// tells if the last response is from a tenant representative
export function isLastMessageFromTenantRepresentative(conversation: Conversation): boolean {
	const representativeIds = conversation.participants
		.filter((participant: Participant) => participant.isTenantRepresentative)
		.map((participant: Participant) => participant.id)

	return representativeIds.includes(getMessages(conversation).reverse().shift().causedById)
}
// returns all Participants with the given tenant representative status
export function getParticipantsByTenantRepresentation(
	conversation: Conversation,
	isTenantRepresentative: boolean,
): Participant[] {
	return conversation.participants.filter(
		(participant: Participant) => participant.isTenantRepresentative === isTenantRepresentative,
	)
}
export function isGroupConversation(conversation: Conversation) {
	return getParticipantsByTenantRepresentation(conversation, false).length > 1
}
export function getUsersFromConversation(conversation: Conversation) {
	return conversation.participants.filter((participant) => !!participant.user).map((participant) => participant.user)
}
export function getUniqueUsersFromConversations(conversations: Conversation[]): MicroUser[] {
	return Object.values(
		conversations
			.flatMap((conversation) => conversation.participants)
			.filter((participant) => !!participant.user)
			.map((participant) => participant.user)
			.reduce((map, user) => {
				map[user.id] = user

				return map
			}, {}),
	)
}

export function getParticipantByUserId(conversation: Conversation, userId: UserId): Participant | null {
	return conversation.participants.find((participant) => !!participant.user && participant.user?.id === userId)
}
export function isUserParticipatingInConversation(userId: UserId, conversation: Conversation) {
	return !!getParticipantByUserId(conversation, userId)
}

export function getAttachmentsFromConversation(conversation: Conversation): AssetAttachments {
	const attachments = {
		files: [],
		images: [],
	}

	getMessages(conversation).forEach((message) => {
		attachments.images = attachments.images.concat(message.images || [])
		attachments.files = attachments.files.concat(message.files || [])
	})

	return attachments
}

export function getAttachmentsCountFromConversation(conversation: Conversation): number {
	const attachments = getAttachmentsFromConversation(conversation)

	return attachments.images.length + attachments.files.length
}

export function getActiveParticipantsFromConversation(conversation: Conversation): Participant[] {
	return conversation.participants.filter((p) => p.isActive && p.user)
}

export function conversationSort(conversations: Conversation[]) {
	return sort(conversations, 'lastMessagedOn', 'desc')
}

export function doesConversationHaveUnreadMessageForUser(conversation: Conversation, userId: UserId) {
	const participant = getParticipantByUserId(conversation, userId)
	const latestMessage = getLatestMessage(conversation)
	return participant && latestMessage && (!participant.readOn || participant.readOn < latestMessage.occurredOn)
}

export function isConversationSuggestedToBeClosed(conversation: Conversation) {
	return (
		!conversation.isOpen &&
		conversation.participants.every((participant) => participant.isTenantRepresentative || participant.isSatisfied)
	)
}

export function canUserChangeSatisfiedStatus(conversation: Conversation, userId: UserId) {
	const participant = getParticipantByUserId(conversation, userId)
	return participant && !participant.isTenantRepresentative
}

export function canUserSendConfidentialMessagesInConversation(conversation: Conversation, userId: UserId) {
	const userParticipant = getParticipantByUserId(conversation, userId)
	if (!userParticipant) {
		return false
	}
	// tenant representatives can never send confidential messages
	if (userParticipant.isTenantRepresentative) {
		return false
	}
	// customers can send confidential messages when there are other customers in the conversation
	return conversation.participants.some(
		(participant) => participant.id !== userParticipant.id && !participant.isTenantRepresentative,
	)
}

export enum waitingStatus {
	none = 'none',
	waiting = 'waiting',
	overdue = 'overdue',
}
export const getWaitingStatus = (conversation: Conversation): waitingStatus => {
	if (conversation.isWaiting) {
		return waitingStatus.waiting
	}
	if (conversation.waitUntil) {
		return waitingStatus.overdue
	}
	return waitingStatus.none
}
