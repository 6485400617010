
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { find } from 'lodash'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import {
		Conversation,
		doesConversationHaveUnreadMessageForUser,
		getAttachmentsFromConversation,
		getFirstMessage,
		getLatestMessage,
	} from '~/app/domain/communication/Conversation'
	import Date from '~/components/utils/Date.vue'
	import { Node } from '~/app/domain/content/Common'
	import ContextProcess from '~/components/utils/ContextProcess.vue'
	import ContextLocation from '~/components/utils/ContextLocation.vue'
	import html2text from '~/app/common/filters/text/Html2text'
	import AttachmentsLine from '~/components/domain/attachments/AttachmentsLine.vue'
	import RichText from '~/components/utils/RichText.vue'
	import { FORMAT_DEFAULT, PRECISION_DAY, PRECISION_MINUTE } from '~/app/common/filters/Date'
	import ProviderLine from '~/components/domain/provider/ProviderLine.vue'
	import Md from '~/components/utils/Md.vue'

	@Component({
		components: {
			Md,
			ProviderLine,
			RichText,
			AttachmentsLine,
			ContextProcess,
			ContextLocation,
			Date,
			UserLine,
			CardLargeContainer,
		},
	})
	export default class ConversationCard extends Vue {
		@Model('change', { required: true }) value: Conversation
		@Prop({ default: null }) to: string
		@Prop({ default: false }) simple: boolean
		@Prop({ default: '100%' }) width: string
		@Prop({ default: null }) nodes: Node[]
		@Prop({ default: false }) backOffice: boolean
		@Prop({ default: 0 }) minHeight: number

		get latestMessage() {
			const msg = getLatestMessage(this.value)
			if (!msg) return null
			const latestParticipant = find(this.value.participants, {
				id: msg.causedById,
			})
			return {
				user: latestParticipant ? latestParticipant.user : null,
				text: html2text(msg.text),
			}
		}

		get isUnread() {
			return doesConversationHaveUnreadMessageForUser(this.value, this.$auth.user.id)
		}

		get shouldBeAnswered() {
			return this.value.isOpen && this.backOffice && this.value.needsAnswer
		}

		get highlightStatus() {
			if (!this.value.isOpen) {
				return
			}
			if (this.shouldBeAnswered) {
				return {
					text: this.$t('domain.conversation.unanswered'),
				}
			}
			if (!this.backOffice && this.isUnread) {
				return {
					text: this.$t('domain.conversation.unread'),
				}
			}
			if (!this.value.isWaiting && this.value.waitUntil) {
				const date = Utils.dateFormat(this.value.waitUntil, FORMAT_DEFAULT, PRECISION_MINUTE)
				return {
					text: this.$t('domain.conversation.resubmissionFrom', {
						date,
					}),
				}
			}
		}

		get startedDate() {
			const firstMessage = getFirstMessage(this.value)
			return firstMessage ? Utils.dateFormat(firstMessage.occurredOn) : null
		}

		get attachments() {
			return getAttachmentsFromConversation(this.value)
		}

		get icon() {
			if (this.simple) return null
			if (!this.backOffice) return 'fal fa-comment-alt-lines'
			if (this.value.waitUntil) return 'fal fa-snooze'
			return 'fal fa-comment-alt-lines'
		}
	}
