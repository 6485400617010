
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import UiBadge from '~/components/atoms/UiBadge.vue'

	@Component({
		components: {
			UiBadge,
		},
	})
	export default class UserNotifications extends Vue {
		// tells whether users will get notified or not
		@Model('change', { required: true }) notify: boolean

		// locks the UI to the current state, disallowing modification
		@Prop({ default: false, type: Boolean }) locked: boolean

		// tells that the users will get notified via email instead of just notification
		@Prop({ default: false, type: Boolean }) email: boolean
	}
