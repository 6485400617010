import { itemStoreActions, itemStoreGetters, itemStoreMutations } from '~/app/store/ItemStore'
import { pick } from 'lodash'
import { CompanyId } from '~/app/domain/provider/Provider'

export const state = () => ({
	initialized: false,
	processing: false,
	items: [],
	context: null,
})

export const getters = {
	...itemStoreGetters({
		sortIteratee: 'title',
		lifetime: 1e3,
	}),
}

export const mutations = {
	...itemStoreMutations({ sortIteratee: 'title' }),

	resetForContext(state, context: string): void {
		state.initialized = false
		state.processing = false
		state.items = []
		state.context = context
	},
}

export const actions = {
	...itemStoreActions({}),

	async fetchAll(context) {
		// check if we need to reset because we're in a new context
		const currentContext = `${context.rootGetters['tenant/subdomain']}-${
			context.rootGetters['routing/isBackoffice'] ? 'bo' : 'fo'
		}`
		if (!context.state.context || context.state.context !== currentContext) {
			context.commit('resetForContext', currentContext)
		}

		if (context.getters.isClean()) {
			return
		}

		const companies = (await this.$axios.get('etg24:internal:query:provider.companies')).data
		context.commit('setItems', companies)
		context.commit('setInitialized', true)
	},

	async createCompanies(context, companiesToCreate: { title: string; extId?: string }[]) {
		// reduce to allowed properties
		companiesToCreate = companiesToCreate.map((item) => pick(item, ['title', 'extId']))

		const newCompanies = (
			await this.$axios.post('etg24:internal:command:provider.companies.create', {
				companies: companiesToCreate,
			})
		).data
		context.commit('addItems', newCompanies)
		return newCompanies
	},

	async updateCompanies(context, companiesToUpdate: { id: CompanyId; title?: string; extId?: string }[]) {
		// reduce to allowed properties
		companiesToUpdate = companiesToUpdate.map((item) => pick(item, ['id', 'title', 'extId']))

		const newCompanies = (
			await this.$axios.post('etg24:internal:command:provider.companies.update', {
				companies: companiesToUpdate,
			})
		).data
		context.commit('updateItems', newCompanies)
		return newCompanies
	},

	async deleteCompanies(context, companyIds: CompanyId[]) {
		await this.$axios.post('etg24:internal:command:provider.companies.delete', {
			companies: companyIds,
		})
		context.commit('removeItems', companyIds)
	},
}
