
	import Utils from '~/app/utils/globals/Utils'
	import sleepDiff from '~/app/utils/sleepDiff'
	import { Vue, Component, Watch } from 'nuxt-property-decorator'
	import FoToolbar from '~/components/navigation/toolbars/FoToolbar.vue'
	import ObjectSelect from '~/components/domain/object/ObjectSelect.vue'
	import LoadingScreen from '~/components/utils/LoadingScreen.vue'
	import FooterDefault from '~/components/navigation/footers/FooterDefault.vue'
	import NavigationDrawer from '~/components/navigation/drawers/FoDrawer.vue'
	import { preloadData } from '~/app/utils/libs/Preload'
	import { setSystemLocale } from '~/app/utils/libs/Locales'
	import EtgApp from '~/components/utils/EtgApp.vue'
	import ConfigPanel from '~/components/utils/ConfigPanel.vue'
	import DetailModal from '~/components/utils/DetailModal.vue'
	import DocumentPreview from '~/components/domain/document/DocumentPreview.vue'
	import EtgSnackbar from '~/components/EtgSnackbar.vue'

	@Component({
		components: {
			DocumentPreview,
			DetailModal,
			ConfigPanel,
			EtgApp,
			NavigationDrawer,
			FooterDefault,
			ObjectSelect,
			FoToolbar,
			LoadingScreen,
			EtgSnackbar,
		},
	})
	export default class DefaultLayout extends Vue {
		Utils = Utils
		// loading screen
		sTime = +new Date()
		loading: boolean = true
		async mounted() {
			// load locales
			await setSystemLocale.call(this)

			// set loading to false
			await sleepDiff(this.sTime, 1500)
			this.loading = false
		}

		get basePath() {
			return Utils.appPath()
		}

		@Watch('basePath', { immediate: true })
		async basePathChanged() {
			if (this.$auth.loggedIn) await preloadData.call(this)
		}

		/**
		 * Toggle drawer
		 */
		drawerVisible = false
		toggleDrawer() {
			this.drawerVisible = !this.drawerVisible
		}

		get subdomain() {
			return this.$route.params.subdomain
		}

		configPanelVisible = false
	}
