var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.DetailCard,{staticClass:"tw-h-full",attrs:{"auto-back-path":_vm.$store.getters['routing/isBackoffice'],"loading":_vm.loading,"data-qa":"contact-person-detail"},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([(_vm.response.contactPerson)?{key:"title",fn:function(){return [_c('div',{staticClass:"tw-inline-flex tw-items-center tw-gap-3"},[_c(_setup.UserAvatar,{staticClass:"tw-rounded",attrs:{"squared":"","size":"lg","image":_vm.response.contactPerson?.company?.avatar},scopedSlots:_vm._u([{key:"fallback",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-bg-primary-softer tw-shrink-0"},[_c(_setup.UiIcon,{staticClass:"before:tw-text-text-soft tw-text-text-soft",attrs:{"icon":_vm.response.contactPerson.company?.personType === 'legal'
									? 'fal fa-building'
									: 'fal fa-user'}})],1)]},proxy:true}],null,false,3712313271)}),_vm._v(" "),_c(_setup.UiTypography,{attrs:{"variant":"h2"}},[_vm._v(_vm._s(_setup.title))])],1)]},proxy:true}:null,(_vm.response.contactPerson && _vm.$store.getters['routing/isBackoffice'])?{key:"actions",fn:function(){return [_c(_setup.ContactPersonActions,{attrs:{"contact-person":_vm.response.contactPerson,"nodes":_vm.response.nodes,"node-id":_vm.nodeId,"at-node":!!_vm.nodeId,"has-contact":_setup.hasContact,"creating-description":_setup.activeDescription},on:{"changePage":_setup.setPage,"deleted":_setup.deletePerson,"addContact":function($event){_setup.newContactModal = true},"addDescription":function($event){_setup.activeDescription = true},"addProvider":function($event){_setup.newProviderModal = true}}})]},proxy:true}:null,(_vm.response.contactPerson && _vm.$store.getters['routing/isBackoffice'])?{key:"actions-fab",fn:function(){return [_c(_setup.ContactPersonActions,{attrs:{"contact-person":_vm.response.contactPerson,"nodes":_vm.response.nodes,"node-id":_vm.nodeId,"at-node":!!_vm.nodeId,"has-contact":_setup.hasContact,"creating-description":_setup.activeDescription},on:{"changePage":_setup.setPage,"deleted":_setup.deletePerson,"addContact":function($event){_setup.newContactModal = true},"addDescription":function($event){_setup.activeDescription = true},"addProvider":function($event){_setup.newProviderModal = true}}})]},proxy:true}:null],null,true)},[_vm._v(" "),_c('div',{staticClass:"main-class"},[(!_setup.hasContact && _setup.providers?.length > 1 && _vm.$store.getters['routing/isBackoffice'])?_c(_setup.UiAlert,{staticClass:"tw-mb-6",attrs:{"variant":"error","title":String(_vm.$t('domain.contactPerson.alert.noCompany.title')),"text":String(_vm.$t('domain.contactPerson.alert.noCompany.text'))}}):_vm._e(),_vm._v(" "),(
				_vm.response.contactPerson?.company?.personType === 'natural' && _vm.$store.getters['routing/isBackoffice']
			)?_c(_setup.UiAlert,{staticClass:"tw-mb-6",attrs:{"variant":"error","title":String(_vm.$t('domain.contactPerson.alert.naturalPerson.title')),"text":String(_vm.$t('domain.contactPerson.alert.naturalPerson.text'))}}):_vm._e(),_vm._v(" "),(_setup.hasContact)?_c(_setup.CategoryContainer,{staticClass:"tw-pb-6",attrs:{"icon":"fal fa-info-square"}},[_c('div',{staticClass:"tw-flex tw-pb-4"},[_c('div',{staticClass:"primary--text headline tw-flex tw-items-center tw-pb-2 tw-mr-auto"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.contactPerson.contactInformation.label'))+"\n\t\t\t\t")]),_vm._v(" "),(_vm.$store.getters['routing/isBackoffice'])?_c('div',{staticClass:"tw-flex"},[_c(_setup.UiButton,{attrs:{"icon":"fal fa-address-card","link":{
							to: {
								..._vm.$route,
								query: {
									..._vm.$route.query,
									contentType: 'contact',
									contentId: _vm.response.contactPerson?.company?.id,
								},
							},
						},"variant":"text","dense":"","exact":"","rounded":"","size":"lg","data-qa":"contact-person-detail:contact-link","tooltip":String(_vm.$t('domain.contactPerson.contact.link'))}}),_vm._v(" "),_c(_setup.UiButton,{attrs:{"icon":"fal fa-times","variant":"text","loading":_setup.loadingUnassign,"dense":"","rounded":"","size":"lg","tooltip":String(_vm.$t('domain.contactPerson.edit.unassignCompany'))},on:{"click":_setup.unassignContact}})],1):_vm._e()]),_vm._v(" "),(_vm.response.contactPerson?.company)?_c(_setup.ContactInformation,{attrs:{"contact":_vm.response.contactPerson?.company}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c(_setup.ContactPersonDetailDescription,{attrs:{"contact-person":_vm.response.contactPerson,"active":_setup.activeDescription},on:{"update:contactPerson":function($event){return _setup.updatePayload({ ..._vm.response, contactPerson: $event })},"blur":function($event){_setup.activeDescription = false},"focus":function($event){_setup.activeDescription = true}}}),_vm._v(" "),_c(_setup.ContactPersonDetailProviders,{attrs:{"response":_vm.response,"new-provider-modal":_setup.newProviderModal,"providers":_setup.providers,"node-id":_vm.nodeId},on:{"update:payload":_setup.updatePayload,"update:newProviderModal":function($event){_setup.newProviderModal = $event}}})],1),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c(_setup.ContactGetOrCreate,{attrs:{"visible":_setup.newContactModal,"is-parent-loading":_setup.loadingNewContact,"submit-btn-text":String(_vm.$t('layout.create')),"initial-contact-data":{ personType: 'legal' },"picker-extra-filters":{ personType: 'legal' }},on:{"update:visible":function($event){_setup.newContactModal = $event},"submit":_setup.createAndAssignContact}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }