import Vue from 'vue'
import { DateString, FileExtension, UrlString } from '~/app/domain/Types'
import SanityBlockImage from '~/components/sanity/SanityBlockImage.vue'
import Youtube from '~/components/sanity/Youtube.vue'
import SanityTable from '~/components/sanity/SanityTable.vue'

export interface ObjectReference {
	_ref: string
	_type: string
}

export interface Asset {
	_createdAt: DateString
	_id: string
	_rev: string
	_type: string
	_updatedAt: DateString
	assetId: string
	extension: FileExtension
	metadata: any
	mimeType: string
	originalFilename: string
	path: string
	sha1hash: string
	size: number
	uploadId: string
	url: UrlString
}

export interface Slug {
	current: string
}

// Portable Text
export type PortableText = any

/**
 * In order to query a sanity richText field to contain the necessary fields from the target object, query a field named `body` like this
 body[]{
		...,
		markDefs[]{
			...,
			_type == "internalLink" => {
				...,
				"slug": @.reference->slug,
				"type": @.reference->_type,
			},
		},
	}

 * and create the serializers like
 makeBlockContentSerializers({
	internalLinks: {
			blogPost: slug => `/blog/${slug}`,
		},
	})
 */
interface SLugToUrlByType {
	[key: string]: (string) => string
}
const makeInternalLinkComponent = (typeToUrl) => {
	return Vue.component('InternalLink', {
		props: ['reference', 'slug', 'type'],
		data: () => ({ typeToUrl }),
		computed: {
			url() {
				// return the url or null
				return this.type in this.typeToUrl ? this.typeToUrl[this.type](this.slug.current) : null
			},
		},
		render(createElement) {
			return this.url
				? createElement(
						// creates a link if the url could be created
						'a',
						{
							attrs: {
								href: this.url, // url target
								'data-type': this.type, // could be used for css styling
							},
						},
						this.$slots.default,
				  )
				: createElement(
						// creates a span if the url could not be created
						'span',
						this.$slots.default,
				  )
		},
	})
}

interface BlockContentSerializerOptions {
	internalLinks?: SLugToUrlByType
}

export const makeBlockContentSerializers = (options: BlockContentSerializerOptions) => {
	const serializers = {
		types: {
			image: SanityBlockImage,
			youtubeEmbed: Youtube,
			table: SanityTable,
		},
		marks: {
			color1: Vue.component('ColorSecondary', {
				props: [],
				render(createElement) {
					return createElement('span', { style: { color: 'var(--v-secondary-base)' } }, this.$slots.default)
				},
			}),
		},
	}

	// add internal links
	if (options.internalLinks) {
		// @ts-ignore
		serializers.marks.internalLink = makeInternalLinkComponent(options.internalLinks)
	}

	return serializers
}

export const DefaultBlockContentSerializers = makeBlockContentSerializers({})

export interface NewUpdate {
	_id: string
	title: string
	headline: string
	forceOpen: boolean
	body: string
	href: string
	_createdAt: string
	publishedAt: string
	type: 'feature' | 'warning' | 'update' | 'info'
}
