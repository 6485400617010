import { itemStoreActions, itemStoreGetters, itemStoreMutations } from '~/app/store/ItemStore'
import { pick } from 'lodash'
import { CompanyId } from '~/app/domain/provider/Provider'
import { CompanyGroupId } from '~/app/domain/provider/CompanyGroup'

export const state = () => ({
	initialized: false,
	processing: false,
	items: [],
	context: null,
})

export const getters = {
	...itemStoreGetters({
		sortIteratee: 'title',
		lifetime: 1e3,
	}),
}

export const mutations = {
	...itemStoreMutations({ sortIteratee: 'title' }),

	resetForContext(state, context: string): void {
		state.initialized = false
		state.processing = false
		state.items = []
		state.context = context
	},
}

export const actions = {
	...itemStoreActions({}),

	async fetchAll(context) {
		// check if we need to reset because we're in a new context
		const currentContext = `${context.rootGetters['tenant/subdomain']}-${
			context.rootGetters['routing/isBackoffice'] ? 'bo' : 'fo'
		}`
		if (!context.state.context || context.state.context !== currentContext) {
			context.commit('resetForContext', currentContext)
		}

		if (context.getters.isClean()) {
			return
		}

		const companyGroups = (await this.$axios.get('etg24:internal:query:provider.companygroups')).data
		context.commit('setItems', companyGroups)
		context.commit('setInitialized', true)
	},

	async createCompanyGroups(context, companyGroupsToCreate: { title: string; description?: string }[]) {
		// reduce to allowed properties
		companyGroupsToCreate = companyGroupsToCreate.map((item) => pick(item, ['title', 'description']))

		const newCompanyGroups = (
			await this.$axios.post('etg24:internal:command:provider.companygroups.create', {
				companyGroups: companyGroupsToCreate,
			})
		).data
		context.commit('addItems', newCompanyGroups)
		return newCompanyGroups
	},

	async updateCompanyGroups(
		context,
		companyGroupsToUpdate: {
			id: CompanyId
			title?: string
			description?: string
		}[],
	) {
		// reduce to allowed properties
		companyGroupsToUpdate = companyGroupsToUpdate.map((item) => pick(item, ['id', 'title', 'description']))

		const newCompanyGroups = (
			await this.$axios.post('etg24:internal:command:provider.companygroups.update', {
				companyGroups: companyGroupsToUpdate,
			})
		).data
		context.commit('updateItems', newCompanyGroups)
		return newCompanyGroups
	},

	async deleteCompanyGroups(context, companyGroupIds: CompanyId[]) {
		await this.$axios.post('etg24:internal:command:provider.companygroups.delete', {
			companyGroups: companyGroupIds,
		})
		context.commit('removeItems', companyGroupIds)
	},

	async addCompaniesToGroup(
		context,
		{ companyGroup, companies }: { companyGroup: CompanyGroupId; companies: CompanyId[] },
	) {
		const updatedCompanyGroup = (
			await this.$axios.post('etg24:internal:command:provider.companygroups.addcompanies', {
				companyGroup,
				companies,
			})
		).data
		context.commit('updateItem', updatedCompanyGroup)
	},

	async removeCompaniesFromGroup(
		context,
		{ companyGroup, companies }: { companyGroup: CompanyGroupId; companies: CompanyId[] },
	) {
		const updatedCompanyGroup = (
			await this.$axios.post('etg24:internal:command:provider.companygroups.removecompanies', {
				companyGroup: companyGroup,
				companies: companies,
			})
		).data
		context.commit('updateItem', updatedCompanyGroup)
	},

	async addGroupsToCompany(
		context,
		{ companyGroups, company }: { companyGroups: CompanyGroupId[]; company: CompanyId },
	) {
		const updatedCompanyGroups = (
			await this.$axios.post('etg24:internal:command:provider.companygroups.addgroupstocompany', {
				companyGroups: companyGroups,
				company: company,
			})
		).data
		context.commit('updateItems', updatedCompanyGroups)
	},

	async removeGroupsFromCompany(
		context,
		{ companyGroups, company }: { companyGroups: CompanyGroupId[]; company: CompanyId },
	) {
		const updatedCompanyGroups = (
			await this.$axios.post('etg24:internal:command:provider.companygroups.removegroupsfromcompany', {
				companyGroups: companyGroups,
				company: company,
			})
		).data
		context.commit('updateItems', updatedCompanyGroups)
	},
}
