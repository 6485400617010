import { scopedItemStoreActions, scopedItemStoreGetters, scopedItemStoreMutations } from '~/app/store/ScopedItemStore'
import { ObjectAddress, PropertyObjectId } from '~/app/domain/property/Object'
import { UnitId } from '~/app/domain/property/Unit'

type NewObjectAddress = Pick<ObjectAddress, 'address' | 'objectId'>

export const state = () => ({
	scopes: {},
	items: {},
	scopePropertyName: 'objectId',
})

export const getters = {
	...scopedItemStoreGetters({
		sortIteratee: (o: ObjectAddress) => `${o.address.street} ${o.address.houseNumber}`,
		lifetime: 30e3,
	}),

	byObject: (state, getters) => (objectId: PropertyObjectId) => getters.byProperty('objectId', objectId),
}

export const mutations = {
	...scopedItemStoreMutations({
		sortIteratee: (o: ObjectAddress) => `${o.address.street} ${o.address.houseNumber}`,
	}),
}

export const actions = {
	...scopedItemStoreActions({}),

	async loadForObject(context, objectId: PropertyObjectId) {
		// don't reload if we're already reloading
		if (context.getters.isProcessing(objectId)) {
			return
		}
		// don't reload if we have clean data
		if (context.getters._isClean(objectId)) {
			return
		}

		// set scope processing
		context.commit('setScopeProcessing', objectId)

		const response = await this.$axios.get('etg24:internal:query:propertymanagement.objectaddresses', {
			params: { object: objectId },
		})
		await context.dispatch('setScopeWithItems', {
			key: objectId,
			items: response.data,
		})
	},

	async fetch(context, objectAddressId: UnitId) {
		const objectAddress = (
			await this.$axios.get('etg24:internal:query:propertymanagement.objectaddress', {
				params: { objectAddress: objectAddressId },
			})
		).data
		context.commit('setItems', [objectAddress])
	},

	async create(context, items: NewObjectAddress[]) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectaddresses.create', {
			objectAddresses: items,
		})
		context.commit('setItems', response.data)
		return response.data as ObjectAddress
	},

	async update(context, items: ObjectAddress[]) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.objectaddresses.update', {
			objectAddresses: items,
		})
		context.commit('setItems', response.data)
		return response.data as ObjectAddress[]
	},

	async delete(context, itemId) {
		await this.$axios.post('etg24:internal:command:propertymanagement.objectaddresses.delete', {
			objectAddress: itemId,
		})
		context.commit('removeItems', [itemId])
		return itemId
	},
}
