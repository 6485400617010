
	import { Component, Vue, Watch, Prop, Model } from 'nuxt-property-decorator'

	@Component({})
	export default class SFTextArea extends Vue {
		@Model('changed') value: string
		@Prop({ default: null }) rules: string
		@Prop({ default: null }) label: string
		@Prop({ default: null }) validatorPrefix: string
		@Prop({ default: null }) placeholder: string
		@Prop({ default: false }) required: false
		@Prop({ default: null }) height: number
		@Prop({ type: Boolean, default: false }) translate: boolean
		@Prop({ type: Boolean, default: false }) clearable: boolean

		localValue = null
		@Watch('value', { immediate: true })
		updateAddress() {
			this.localValue = this.value
		}

		@Watch('localValue')
		updateText() {
			this.$emit('changed', this.localValue)
		}

		get rLabel() {
			if (this.label) return `${this.translate ? this.$t(this.label) : this.label}${this.required ? '*' : ''}`
		}
		/**
		 * focus functionality
		 */
		focus() {
			;(this.$refs.input as any).focus()
		}
	}
