/**
 * This middleware responsible for fetching and populating the tenant data depending the URL subdomain
 * @param redirect
 * @param req
 * @param app
 * @param store
 */
export default async function ({ app, store, route }) {
	// extract the subdomain
	const routeSegments = route.path.split('/')
	const isApp = route.path.startsWith('/app')
	const subdomain =
		route.params.subdomain || (isApp && routeSegments.length > 1)
			? routeSegments[2] !== 'account'
				? routeSegments[2]
				: null
			: null

	// configure the scope
	if (!app.$config.isDev && app.$sentry) {
		await app.$sentry.configureScope((scope) => {
			const tenant = store.state.tenant && store.state.tenant.tenant
			if (store.$auth.user) {
				scope.setUser({ id: store.$auth.user.id })
			}
			// scope.setUser()
			scope.setTag('subdomain', subdomain || tenant || 'localhost')
		})
	}
}
