import { DirectiveOptions, VNodeDirective } from 'vue'

function applyChanges(el: HTMLElement, binding: VNodeDirective) {
	// Set the element's HTML to the provided string, like v-html.
	el.innerHTML = sanitize(binding.value)

	// Now augment all <a>-elements to open in a separate window.
	Array.from(el.getElementsByTagName('a')).forEach((link) => {
		link.setAttribute('target', '_blank')
		link.setAttribute('rel', 'nofollow')
	})
}

/**
 * vue.js directive: like v-html, but adds functionality tailored to user generated HTML content.
 */
export default {
	bind: applyChanges,
	update: applyChanges,
	inserted: (el: HTMLElement, binding: VNodeDirective) => {
		// Stop events on links from bubbling further up.
		el.addEventListener('click', (e) => {
			if ((e.target as Element).tagName === 'A') {
				e.stopPropagation()
			}
		})
	},
} as DirectiveOptions

// This function should sanitize the provided HTML string so that it may be displayed within the application without concern.
function sanitize(html: string) {
	return html
}
