
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import AddressForm from '~/components/inputs/AddressForm.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import SfTime from '~/components/domain/conversation/service_forms/blocks/SfTime.vue'
	import { Event } from '~/app/domain/content/Event'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import Md from '~/components/utils/Md.vue'
	import ContractBasedVisibility from '~/components/domain/contract/ContractBasedVisibility.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import { ContractId } from '~/app/domain/property/Contract'
	import DetailLayout from '~/components/utils/DetailLayout.vue'

	@Component({
		components: {
			DetailLayout,
			UserNotifications,
			ContractBasedVisibility,
			RichTextEditor,
			SfTextField,
			SfTextArea,
			SfTime,
			SfDate,
			AddressForm,
			CategoryContainer,
			Md,
		},

		// components: { },
	})
	export default class EventEdit extends Vue {
		@Model('change', { required: true }) event: Event
		@Prop({ default: false }) newEvent: boolean
		@Prop({ default: null }) nodeId: string
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		async mounted() {
			const contract = this.$route.query.contract as ContractId | null
			this.selectedContracts = this.event.contractIds || (contract ? [contract] : [])

			if (this.votingId) {
				try {
					// fetch the voting
					const voting = (
						await this.$axios.$get('etg24:internal:query:communication.voting.voting', {
							params: {
								voting: this.votingId,
							},
						})
					).voting
					// prefill
					this.localEvent = {
						...(this.event || {}),
						title: voting.title,
						description: voting.description,
						fromDate: voting.meeting?.startsOn
							? this.$dateFns.format(this.$dateFns.parseISO(voting.meeting.startsOn), 'yyyy-MM-dd')
							: null,
						fromTime: voting.meeting?.startsOn
							? this.$dateFns.format(this.$dateFns.parseISO(voting.meeting.startsOn), 'HH:mm')
							: null,
						toDate: voting.meeting?.endsOn
							? this.$dateFns.format(this.$dateFns.parseISO(voting.meeting.endsOn), 'yyyy-MM-dd')
							: null,
						toTime: voting.meeting?.endsOn
							? this.$dateFns.format(this.$dateFns.parseISO(voting.meeting.endsOn), 'HH:mm')
							: null,
						address: voting.meeting?.address,
						addressNote: voting.meeting?.addressNote,
					}
				} catch (e) {
					Utils.handleError(e)
				}
				try {
					// @ts-expect-error typescript does not know about this method
					this.$refs.contractBasedVisibility?.selectAllCurrentOwners()
				} catch (e) {
					Utils.handleError(e)
				}
			}
		}

		important = false
		selectedContracts: ContractId[] = []

		localEvent: Event | any = {}
		@Watch('event', { immediate: true }) updateEventExt() {
			this.localEvent = { ...this.event }
		}

		/**
		 * Roles
		 */
		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 1),
					value: r,
				}
			})
		}

		/**
		 * Send
		 */
		sending = false
		async send() {
			// submit it
			try {
				this.sending = true
				this.newEvent ? await this.createEvent() : await this.updateEvent()
			} catch (e) {
				this.sending = false
				Utils.handleError(e)
			}
		}

		get canSend() {
			// both or no times have to be defined
			if (
				(this.localEvent.fromTime && !this.localEvent.toTime) ||
				(!this.localEvent.fromTime && this.localEvent.toTime)
			)
				return false
			return this.localEvent.title && this.localEvent.fromDate
		}

		async createEvent() {
			const processId = this.$route.query.newContentProcess
			const response = (
				await this.$axios.post('etg24:internal:command:content.events.create', {
					event: {
						parentNode: this.nodeId,
						roleTypes: this.localEvent.roleTypes,
						title: this.localEvent.title,
						description: this.localEvent.description,
						process: processId || undefined,
						eventTime: {
							fromDate: this.localEvent.fromDate,
							fromTime: this.localEvent.fromTime,
							toDate: this.localEvent.toDate,
							toTime: this.localEvent.toTime,
						},
						address: this.localEvent.address ? this.localEvent.address : null,
						addressNote: this.localEvent.address && this.localEvent.addressNote,
						contractIds: this.hasContractVisibility ? this.selectedContracts : undefined,
					},
					voting: this.votingId || undefined,
				})
			).data as { event: Event }
			if (processId) {
				await this.$router.push({
					query: {
						...this.$route.query,
						newContent: undefined,
						newContentId: undefined,
						newContentProcess: undefined,
						contentType: 'process',
						contentId: processId,
					},
				})
			} else
				await this.$router.push({
					query: {
						...this.$route.query,
						newContent: undefined,
						newContentId: undefined,
						contentType: 'event',
						contentId: response.event.id,
					},
				})
			this.$nuxt.$emit('eventAdded', response.event)
		}

		async updateEvent() {
			const eventData = {
				id: this.localEvent.id,
				title: this.localEvent.title,
				description: this.localEvent.description,
				contractIds: this.hasContractVisibility ? this.selectedContracts : undefined,
			}

			const eventTime = {
				fromDate: this.localEvent.fromDate,
				fromTime: this.localEvent.fromTime,
				toDate: this.localEvent.toDate,
				toTime: this.localEvent.toTime,
			}

			const response = (
				await this.$axios.post('etg24:internal:command:content.events.update', {
					event: eventData,
					roleTypes: this.localEvent.roleTypes,
					eventTime,
					address: this.localEvent.address ? this.localEvent.address : null,
					addressNote: this.localEvent.address && this.localEvent.addressNote,
				})
			).data as { event: Event }
			this.$emit('eventUpdated', response.event)
			this.$emit('changePage', null)
		}

		get minDate() {
			return this.newEvent ? this.$dateFns.format(new Date(), 'yyyy-MM-dd') : null
		}

		startDateUpdated(sDate) {
			const isBefore = this.$dateFns.isBefore(
				this.$dateFns.parse(this.localEvent.toDate, 'yyyy-MM-dd', new Date()),
				new Date(sDate),
			)
			if (!this.localEvent.toDate || isBefore) this.localEvent.toDate = sDate
		}

		/**
		 * Voting
		 */
		get votingId() {
			return this.$route.query.voting
		}

		get hasContractVisibility() {
			return this.newEvent
				? !!this.$store.getters['property/objects/byId'](this.nodeId)
				: this.event.hasContractVisibility
		}
	}
