// Company
import { Uuid } from '../Types'
import { Entity } from '../common/Interfaces'

/*
 * Model
 */

export type CompanyId = Uuid
export interface Company extends Entity<CompanyId> {
	title: string
	extId: string
}

/*
 * Validation
 */

/*
 * Search
 */

export const companySearchKeys: (keyof Company)[] = ['title', 'extId']

/*
 * Api
 */

/*
 * Functions
 */
