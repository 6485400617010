
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Provider, Responsibility } from '~/app/domain/content/ContactPerson'
	import { Button } from '~/app/common/types/Button'
	import { contactTitle } from '~/app/domain/contact/Contact'
	import Image from '~/app/utils/globals/Image'
	import UiIcon from '~/components/atoms/UiIcon.vue'

	@Component({
		components: { UiIcon },
	})
	export default class ProviderCard extends Vue {
		@Model('changed', { required: true }) provider: Provider & {
			responsibilities: Responsibility[] | unknown[]
		}
		@Prop({})
		@Prop({ type: Array, default: () => [] })
		actions: Button[]
		@Prop({ type: Boolean, default: false })
		opened: boolean

		Image = Image

		get clickFn() {
			if (this.$listeners && this.$listeners.click != null) return true
			else return false
		}

		get fullName() {
			return contactTitle(this.provider.contact)
		}

		click(e) {
			this.opened = !this.opened
			this.$emit('click', e)
		}
	}
