
	import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import { Object as PropertyObject } from '~/app/domain/property/Object'
	import { Button } from '~/app/common/types/Button'
	import UserListItem from '~/components/domain/user/UserListItem.vue'
	import SearchLayout from '~/components/containers/SearchLayout.vue'
	import { prefixSearchKeys, search } from '~/app/common/filters/iterator/Search'
	import { sortUsers, userSearchKeys } from '~/app/domain/identifyAndAccess/User'
	const searchKeys = prefixSearchKeys('user', userSearchKeys || [])

	@Component({
		components: {
			SearchLayout,
			UserListItem,
			UnitContractPicker,
			SearchInput,
		},
	})
	export default class UserPicker extends Vue {
		@Prop({ required: true }) object: PropertyObject
		@Prop({
			type: Array,
			default: () => ['signatories', 'advisors', 'suppliers', 'employees'],
		})
		tabs: string[]

		mounted() {
			if (this.tabs.includes('signatories')) {
				// @ts-expect-error - Incorrect interface
				this.data.signatories = this.object
			}
			this.currentTab = this.tabs.length ? this.tabs[0] : null
		}

		@Watch('currentTab', { immediate: true }) async updateTab() {
			this.searchText = ''
			this.externalSearchText = ''
			await this.loadData(this.currentTab)
		}

		/**
		 * Tab & Search
		 */
		searchText = ''
		externalSearchText = ''
		currentTab = ''

		get cTabs(): Button[] {
			let tabs = []
			if (this.tabs.includes('signatories')) {
				tabs = [
					...tabs,
					{
						text: this.$tc('domain.user.role.owner', 2) + ' & ' + this.$tc('domain.user.role.tenant', 2),
						value: 'signatories',
					},
				]
			}
			if (this.$store.getters['features/isEnabled']('pm:objectAdvisors') && this.tabs.includes('advisors')) {
				tabs = [
					...tabs,
					{
						text: this.$tc('domain.user.role.advisor', 2),
						value: 'advisors',
					},
				]
			}

			if (this.tabs.includes('suppliers')) {
				tabs = [
					...tabs,
					{
						text: this.$tc('domain.user.role.supplier', 2),
						value: 'suppliers',
					},
				]
			}

			if (this.tabs.includes('employees')) {
				tabs = [
					...tabs,
					{
						text: this.$tc('domain.employee.base', 2),
						value: 'employees',
					},
				]
			}

			return tabs
		}

		data = {}
		loadingData = false
		async loadData(tab) {
			const objectId = this.object.id
			if (!this.data[tab]) {
				this.loadingData = true
				if (tab === 'advisors') {
					const response = await this.$axios.$get(
						`etg24:internal:query:propertymanagement.backoffice.objectusers?object=${objectId}&roleTypes=advisor`,
					)
					this.data[tab] = sortUsers(response.roles, 'user')
				} else if (tab === 'suppliers') {
					const response = await this.$axios.$get(
						`etg24:internal:query:propertymanagement.backoffice.objectusers?object=${objectId}&roleTypes=supplier`,
					)
					this.data[tab] = sortUsers(response.roles, 'user')
				} else if (tab === 'employees') {
					const response = await this.$axios.$get('etg24:internal:query:tenant.editors.withroles')
					this.data[tab] = sortUsers(
						response.users.map((u) => {
							return {
								user: u,
							}
						}),
						'user',
					)
				}
				this.loadingData = false
			}
		}

		get contracts() {
			return this.$store.getters['property/contracts/byObject'](this.object.id)
		}

		get signatories() {
			let result = []
			for (const c of this.contracts) {
				result = result.concat(c.signatories)
			}
			return result
		}

		get currentData() {
			let filtered = this.data[this.currentTab]
			if (this.externalSearchText) {
				filtered = search(filtered, this.externalSearchText, {
					fuzzy: true,
					sort: true,
					keys: searchKeys,
				})
			}
			return filtered
		}

		/**
		 * Selection
		 */
		selectedUsers = []

		selected(item) {
			if (item && item.user) {
				this.$emit('selected', item.user)
				if (!this.selectedUsers.includes(item.user?.id)) {
					this.selectedUsers.push(item.user?.id)
				} else {
					this.selectedUsers.splice(this.selectedUsers.indexOf(item.user.id), 1)
				}
			}
		}

		selectUsers(userIds) {
			this.selectedUsers = userIds
			this.$emit(
				'set',
				this.users.filter((u) => userIds.includes(u.id)),
			)
		}

		get users() {
			const result = []
			for (const c of this.contracts || []) {
				for (const sig of c.signatories || [])
					if (sig.user && !result.find((u) => u.id === sig.user.id)) result.push(sig.user)
			}
			return result
		}
	}
