import { getPathFromRootNode, Node, NodeId, NodeType } from '~/app/domain/content/Common'
import { PropertyObjectId } from '~/app/domain/property/Object'
import { ContactId } from '~/app/domain/contact/Contact'

export const getters = {
	rootPath: (state, getters, rootState, rootGetters) => `/app/${rootGetters['tenant/tenant'].subdomain}/property/bo`,

	pathToContact: (state, getters, rootState, rootGetters) => (contactId: ContactId) => {
		return `${getters.rootPath}/contacts/${contactId}`
	},

	pathToLocationNode: (state, getters, rootState, rootGetters) => (locationNodeId: NodeId, nodes: Node[]) => {
		let path = getters.rootPath

		getPathFromRootNode(locationNodeId, nodes).forEach((node) => {
			switch (node.type) {
				case NodeType['propertymanagement']:
					break

				case NodeType['propertymanagement.object']:
					path += `/objects/${node.id}`
					break

				case NodeType['propertymanagement.objectunit']:
					path += `/units/${node.id}`
					break

				case NodeType['propertymanagement.contract']:
					path += `/contracts/${node.id}`
					break

				case NodeType['propertymanagement.objectgroup']:
					path += `/object-groups/${node.id}`
					break
			}
		})

		return path
	},

	pathToObject: (state, getters, rootState, rootGetters) => (objectId: PropertyObjectId) =>
		`${getters.rootPath}/objects/${objectId}`,

	pathToEvents: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/events`,

	pathToFaqs: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/faqs`,

	pathToPosts: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/communication/posts`,

	pathToProcesses: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/processes`,

	pathToDeliveries: (state, getters) => (objectId: PropertyObjectId) =>
		// we show deliveries only at object
		`${getters.pathToObject(objectId)}/communication/deliveries`,

	pathToVotings: (state, getters) => (objectId: PropertyObjectId) =>
		// we show votings only at object
		`${getters.pathToObject(objectId)}/communication/votings`,
}

export const mutations = {}

export const actions = {}
