
	import { Component, Prop, Vue } from 'nuxt-property-decorator'
	import {
		Contract,
		ContractId,
		isActiveContract,
		isOwnerContract,
		isTenantContract,
		signatoryName,
	} from '~/app/domain/property/Contract'
	import enumerate from '~/app/utils/enumerate'

	@Component
	export default class CalculatedContractSelectionSummary extends Vue {
		@Prop({ required: true, type: Array }) contracts: Contract[]
		@Prop({ required: true, type: Array }) selection: ContractId[]

		get selectedContracts() {
			return this.contracts.filter((c) => this.selection.includes(c.id))
		}

		get allActiveOwnerContracts() {
			return this.contracts.filter(isActiveContract).filter(isOwnerContract)
		}

		get selectedActiveOwnerContracts() {
			return this.allActiveOwnerContracts
				.filter(isActiveContract)
				.filter((contract) => this.selection.includes(contract.id))
		}

		get allActiveTenantContracts() {
			return this.contracts.filter(isActiveContract).filter(isTenantContract)
		}

		get selectedActiveTenantContracts() {
			return this.allActiveTenantContracts
				.filter(isActiveContract)
				.filter((contract) => this.selection.includes(contract.id))
		}

		get selectedSignatoryNames() {
			return this.selectedContracts
				.flatMap((contract) => contract.signatories)
				.map((signatory) => signatoryName(signatory))
				.filter((x) => !!x)
		}

		get ownerPart(): string {
			if (!this.selectedActiveOwnerContracts.length) {
				return null
			} else if (this.selectedActiveOwnerContracts.length === this.allActiveOwnerContracts.length) {
				return this.$t('domain.contract.selectionSummary.allOwners') as string
			} else {
				return `${this.selectedActiveOwnerContracts.length} ${this.$tc(
					'domain.user.role.owner',
					this.selectedActiveOwnerContracts.length,
				)}`
			}
		}

		get tenantPart(): string {
			if (!this.selectedActiveTenantContracts.length) {
				return null
			} else if (this.selectedActiveTenantContracts.length === this.allActiveTenantContracts.length) {
				return this.$t('domain.contract.selectionSummary.allTenants') as string
			} else {
				return `${this.selectedActiveTenantContracts.length} ${this.$tc(
					'domain.user.role.tenant',
					this.selectedActiveTenantContracts.length,
				)}`
			}
		}

		get summary() {
			// if there's nothing selected at all
			if (this.selection.length === 0) {
				return this.$t('domain.contract.selectionSummary.none')
			}

			// if there's only a few signatories with names selected
			if (this.selectedSignatoryNames.length && this.selectedSignatoryNames.length <= 3) {
				return enumerate(this.selectedSignatoryNames, ', ', ` ${this.$t('layout.and')} ` as string)
			}

			// just tell how many owners and tenants
			return enumerate(
				[this.ownerPart, this.tenantPart].filter((x) => !!x),
				', ',
				` ${this.$t('layout.and')} ` as string,
			)
		}
	}
