var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-inline-flex tw-flex-row-reverse"},_vm._l((_setup.groupedUsers),function(user,index){return _c(_setup.UiTooltip,{key:index,scopedSlots:_vm._u([{key:"trigger",fn:function(){return [(Array.isArray(user))?_c(_setup.UiAvatar,{staticClass:"tw-rounded-full tw-ring-2 tw-ring-background-soft",class:{
					'-tw-ml-1.5': index !== _setup.groupedUsers.length - 1,
				},attrs:{"text":`+${user.length}`,"size":_vm.size}}):_c(_setup.UserAvatar,{staticClass:"tw-rounded-full tw-ring-2 tw-ring-background-soft",class:{
					'-tw-ml-1.5': index !== _setup.groupedUsers.length - 1,
				},attrs:{"image":user.image || undefined,"user":user,"size":_vm.size}})]},proxy:true}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n\t\t\t"+_vm._s(Array.isArray(user)
					? user.map((singleUser) => _setup.username(singleUser, false)).join(', ')
					: _setup.username(user, false))+"\n\t\t")])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }