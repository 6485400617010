import { memoize } from 'lodash'

/**
 * vue.js filter: renders the provided byte value as human-readable si-suffixed string
 */
export default function bytes(bytes: number, decimals: number = null, useSiUnits: boolean = doesOsRespectSiUnits()) {
	if (!bytes && bytes !== 0) {
		return ''
	}

	const quotient = useSiUnits ? 1000 : 1024
	const suffixes = ['', 'k', 'M', 'G', 'T', 'E', 'Z', 'Y']
	const imax = suffixes.length - 1

	let size = bytes
	let i = 0

	while (size >= quotient && i < imax) {
		size /= quotient
		i++
	}

	const pow = Math.pow(10, decimals !== null ? decimals : i > 1 ? 1 : 0)
	const unit = i > 0 ? suffixes[i] + 'B' : 'Byte'

	size = Math.round(size * pow) / pow

	return size + ' ' + unit
}

/**
 * Does the user's operating system use SI-Units?
 */
export const doesOsRespectSiUnits = memoize((): boolean => {
	if (!process.client || !window.navigator) {
		return true
	}

	const { oscpu, platform } = window.navigator as any

	if (oscpu) {
		return !/(Windows|Mac OS X 10\.[0-5]\D)/.test(oscpu)
	}

	return !/^Win/.test(platform)
})
