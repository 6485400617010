var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-shrink-0 tw-overflow-hidden",class:{
		'tw-rounded-full': !_vm.squared,
		'tw-w-40 tw-h-40': _vm.size === '2xl',
		'tw-w-16 tw-h-16': _vm.size === 'xl',
		'tw-w-11 tw-h-11': _vm.size === 'lg',
		'tw-w-7 tw-h-7': _vm.size === 'md',
		'tw-w-6 tw-h-6': _vm.size === 'sm',
		'tw-w-5 tw-h-5': _vm.size === 'xs',
	}},[(_vm.showImage && !_setup.loadingError)?_c('img',{staticClass:"tw-w-full tw-h-full tw-object-cover",attrs:{"src":_vm.src,"alt":_vm.alt},on:{"error":function($event){_setup.loadingError = true}}}):(_vm.$slots.fallback)?_vm._t("fallback"):(_setup.loadingError)?_c('img',{staticClass:"tw-w-full tw-h-full tw-bg-background-strong",attrs:{"src":"/images/thumbs/image_avatar.png","alt":_vm.alt}}):_c('div',{staticClass:"tw-w-full tw-h-full tw-bg-background-stronger tw-flex tw-items-center tw-justify-center"},[_c(_setup.UiTypography,{staticClass:"tw-text-text-softer tw-text-xs tw-uppercase",attrs:{"variant":"caption"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.text)+"\n\t\t")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }