
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'

	@Component({
		components: {},
	})
	export default class YoutubeEmbed extends Vue {
		@Prop({ required: true }) videoId: string

		get videoArgs() {
			const args = [
				'rel=0', // show related videos
				'autoplay=0', // autoplay
				'color=white', // play bar color
				// 'controls=0',
				'showinfo=0', // title and uploader
				'cc_load_policy=0', // captions
				'fs=1', // allow fullscreen
			]
			return args.join('&')
		}
	}
