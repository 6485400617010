import {
	format,
	min,
	max,
	isWithinInterval,
	sub,
	add,
	isBefore,
	isPast,
	parse,
	set,
	subDays,
	addDays,
	isSameDay,
} from 'date-fns'
import { Validators } from '../../common/validation/Validators'
import { DayString, EmailAddressString, PhoneNumberString, ShortString, UrlString, Uuid } from '../Types'
import { Entity } from '../common/Interfaces'
import { User, UserId, userSearchKeys } from '../identifyAndAccess/User'
import { Address, addressSearchKeys } from '../common/Address'
import { prefixSearchKeys } from '../../common/filters/iterator/Search'
import { PropertyObject, PropertyObjectId } from './Object'
import { UnitId } from './Unit'
import unique from '~/app/common/filters/iterator/Unique'
import enumerate from '~/app/utils/enumerate'
import { Contact, NaturalContact } from '~/app/domain/contact/Contact'

const dateFormat = 'yyyy-MM-dd'

/*
 * Model
 */

export type SignatoryId = Uuid
export type ContractId = Uuid

// Contract
export enum ContractType {
	owner = 'owner',
	tenant = 'tenant',
}
export interface Signatory extends Entity<SignatoryId> {
	contractId: ContractId
	contractType: ContractType
	isAccessGranted: boolean
	accessGrantedFrom: DayString
	accessGrantedTo: DayString
	isAgent: boolean
	canVote: boolean
	isAddressee: boolean
	contact: Contact

	// deprecated properties
	phoneLandline: PhoneNumberString | null
	phoneMobile: PhoneNumberString | null
	phoneWork: PhoneNumberString | null
	legalPersonHash: string
	user: User | null
	salutation: ShortString | null
	firstName: ShortString | null
	lastName: ShortString | null
	deliveryInstructions: ShortString | null // https://de.wikipedia.org/wiki/Zustellanweisung
	address: Address | null
	emailAddress: EmailAddressString | null
	fax: PhoneNumberString | null
	url: UrlString | null
}
export const signatorySearchKeys: (keyof Signatory)[] = [
	'contact.salutation',
	'contact.firstName',
	'contact.lastName',
	'contact.deliveryInstructions',
	...(prefixSearchKeys('contact.address', addressSearchKeys) as (keyof Signatory)[]),
	'contact.emailAddress',
	'contact.phoneNumbers.number',
	...(prefixSearchKeys('contact.user', userSearchKeys) as (keyof Signatory)[]),
]
export const signatoryValidations = {
	emailAddress: {
		email: Validators.email,
	},
	salutation: {
		email: Validators.maxCharacters(250),
	},
	firstName: {
		email: Validators.maxCharacters(250),
	},
	lastName: {
		email: Validators.maxCharacters(250),
	},
	phoneLandline: {
		phoneNumber: Validators.phoneNumber,
	},
	phoneMobile: {
		phoneNumber: Validators.phoneNumber,
	},
	phoneWork: {
		phoneNumber: Validators.phoneNumber,
	},
	deliveryInstructions: {
		maxCharacters: Validators.maxCharacters(250),
	},
}

export interface Contract extends Entity<ContractId> {
	objectId: PropertyObjectId
	contractualPartnerHash: string
	unitId: UnitId | null
	contractType: ContractType
	validFrom: DayString
	validTo: DayString
	isActive: boolean
	isPostal: boolean
	isVacancy: boolean
	isSev: boolean
	// title: string
	extId: string
	signatories: Signatory[]
	// agents: User[]
}

export interface ContractSelectionSummary {
	ownerCount: number
	tenantCount: number
	names: string[]
}

/*
 * Validation
 */

export const contractValidations = {
	validFrom: {
		required: Validators.required,
	},
}

/*
 * Search
 */

export const contractSearchKeys: (keyof Contract)[] = [
	'extId',
	...(prefixSearchKeys('signatories', signatorySearchKeys) as (keyof Contract)[]),
]

/*
 * Api
 */

/*
 * Functions
 */

export const isActiveContract = (contract) => contract.isActive

export function isPastContract(contract: Contract): boolean {
	return contract.validTo !== null && contract.validTo < format(new Date(), 'yyyy-MM-dd')
}

export function isFutureContract(contract: Contract): boolean {
	return contract.validFrom > format(new Date(), 'yyyy-MM-dd')
}

export const isOwnerContract = (contract: Contract): boolean => contract.contractType === ContractType.owner

export const isTenantContract = (contract: Contract): boolean => contract.contractType === ContractType.tenant

export const hasUser = (contract: Contract, userId: UserId, isAgent: boolean | null = null): boolean =>
	contract.signatories.some((signatory) => {
		if (isAgent !== null && isAgent !== signatory.isAgent) {
			return false
		}
		if (!signatory.contact.user || signatory.contact.user.id !== userId) {
			return false
		}
		return true
	})

export const hasLegalPerson = (contract: Contract, legalPersonHash: string, isAgent: boolean | null = null): boolean =>
	contract.signatories.some((signatory) => {
		if (isAgent !== null && isAgent !== signatory.isAgent) {
			return false
		}
		return signatory.contact.legalPersonHash === legalPersonHash
	})

export const getActiveContractForUnit = (contracts: Contract[], unitId: UnitId, contractType: ContractType) => {
	return contracts.find(
		(contract) =>
			isActiveContract(contract) && contract.unitId === unitId && contract.contractType === contractType,
	)
}
export const getActiveOwnerContractForUnit = (contracts: Contract[], unitId: UnitId) => {
	return contracts
		.filter((contract) => contract.unitId === unitId)
		.filter(isOwnerContract)
		.find(isActiveContract)
}
export const getActiveTenantContractForUnit = (contracts: Contract[], unitId: UnitId) => {
	return contracts
		.filter((contract) => contract.unitId === unitId)
		.filter(isTenantContract)
		.find(isActiveContract)
}

export const hasOnlineAccess = (signatory: Signatory) => {
	// todo: we might want to either add the date check here or do this serverside in a new property
	return !!signatory.contact.user && signatory.isAccessGranted
}

export const hasUsersWithOnlineAccess = (contract: Contract): boolean => {
	return contract.isActive && contract.signatories.some(hasOnlineAccess)
}

export const getUsers = (contract: Contract): User[] =>
	contract.signatories.filter((signatory) => signatory.contact.user).map((signatory) => signatory.contact.user)

export const getRegisteredUsers = (contract: Contract): User[] =>
	contract.signatories
		.filter((signatory) => signatory.contact.user?.isRegistered)
		.map((signatory) => signatory.contact.user)

export const getInvitedUsers = (contract: Contract): User[] =>
	contract.signatories
		.filter((signatory) => signatory.contact.user && signatory.contact.user.isRegistered === false)
		.map((signatory) => signatory.contact.user)

export const getOpenContract = (contracts: Contract[]): Contract | null =>
	contracts.find((contract) => !contract.validTo)

export const getUniqueLastNames = (contract: Contract) => {
	return unique(
		contract.signatories
			.map(
				(signatory) =>
					signatory.contact.lastName || (signatory.contact.user && signatory.contact.user.lastName),
			)
			.filter((x) => x),
	)
}

export function isContractValidOnDay(day: DayString, contract: Contract) {
	return contract.validTo
		? isWithinInterval(new Date(day), {
				start: new Date(contract.validFrom),
				end: new Date(contract.validTo),
		  })
		: contract.validFrom <= day
}

export function isDayBlocked(day: DayString, contracts: Contract[]): boolean {
	return contracts.some((contract) => isContractValidOnDay(day, contract))
}

export function isContractAddressable(contract: Contract) {
	return !getContractAddressabilityError(contract)
}

export enum contractAddressabilityError {
	hasNoAddressees = 'contractHasNoAddressees',
	hasAddresseeWithoutAddress = 'contractHasAddresseeWithoutAddress',
	hasAddresseeWithInvalidAddress = 'contractHasAddresseeWithInvalidAddress',
}

export function getContractAddressabilityError(contract: Contract): contractAddressabilityError | null {
	const addressees = getContractAddressees(contract)
	if (addressees.length === 0) {
		return contractAddressabilityError.hasNoAddressees
	}
	if (addressees.some((addressee) => !addressee.contact.address)) {
		return contractAddressabilityError.hasAddresseeWithoutAddress
	}
	if (addressees.some((addressee) => addressee.contact.address?.isValid === false)) {
		return contractAddressabilityError.hasAddresseeWithInvalidAddress
	}
	return null
}

export function getContractAddressees(contract: Contract) {
	return contract.signatories.filter((signatory) => signatory.isAddressee)
}

export function getLastDayBeforeContracts(contracts: Contract[]): DayString | null {
	if (!contracts.length) return null // no contract

	return format(
		sub(
			min(
				contracts.map((contract) => {
					return new Date(contract.validFrom)
				}),
			),
			{ days: 1 },
		),
		'yyyy-MM-dd',
	)
}

export function getFirstDayAfterContracts(contracts: Contract[]): DayString | null {
	if (!contracts.length) return null // no contract
	if (getOpenContract(contracts)) return null // open contract

	return format(
		add(
			max(
				contracts.map((contract) => {
					return new Date(contract.validFrom)
				}),
			),
			{ days: 1 },
		),
		'yyyy-MM-dd',
	)
}

export function getGapAroundDay(
	contracts: Contract[],
	day: DayString,
): { validFrom: DayString | null; validTo: DayString | null } {
	const precedingContracts = contracts.filter((contract) => contract.validTo < day)
	const futureContracts = contracts.filter((contract) => contract.validFrom > day)

	return {
		validFrom: precedingContracts.length
			? format(
					add(
						max(
							contracts.map((contract) => {
								return new Date(contract.validFrom)
							}),
						),
						{ days: 1 },
					),
					'yyyy-MM-dd',
			  )
			: null,
		validTo: futureContracts.length
			? format(
					sub(
						min(
							contracts.map((contract) => {
								return new Date(contract.validFrom)
							}),
						),
						{ days: 1 },
					),
					'yyyy-MM-dd',
			  )
			: null,
	}
}

export function canContractHaveSignatories(contract: Contract): boolean {
	return !contract.isVacancy
}

export function getContractInfo(contract: Contract, dateFns: dateFns) {
	if (contract) {
		// get time
		let time
		if (contract.isActive) time = 'present'
		else if (dateFns.isFuture(new Date(contract.validFrom))) time = 'future'
		else time = 'past'

		// get type
		const type = contract.contractType

		// merge both
		return { time, type }
	}
}

export function getReasonableStartDateForFollowupContract(contracts: Contract[]): DayString {
	if (getOpenContract(contracts)) {
		return format(
			max([
				new Date(),
				add(new Date(getOpenContract(contracts).validFrom), {
					days: 1,
				}),
			]),
			'yyyy-MM-dd',
		)
	} else if (!contracts.length) {
		return format(new Date(), 'YYYY-MM-DD')
	} else {
		return getFirstDayAfterContracts(contracts)
	}
}

export interface ContactInformation {
	salutation?: ShortString | null
	firstName?: ShortString | null
	lastName?: ShortString | null
	deliveryInstructions?: ShortString | null
	address?: Address | null
	emailAddress?: EmailAddressString | null
	phoneLandline?: PhoneNumberString | null
	phoneMobile?: PhoneNumberString | null
	phoneWork?: PhoneNumberString | null
}

export const getMergedSignatoryContactInformation = (signatory: Signatory): ContactInformation => {
	const data = {
		salutation: null,
		firstName: null,
		lastName: null,
		deliveryInstructions: null,
		address: null,
		emailAddress: null,
		phoneLandline: null,
		phoneMobile: null,
		phoneWork: null,
	}

	// set defaults with user profile data
	if (signatory.contact.user && signatory.contact.user.isRegistered) {
		data.firstName = signatory.contact.user.firstName
		data.lastName = signatory.contact.user.lastName
		data.address = signatory.contact.user.address
		data.emailAddress = signatory.contact.user.emailAddress
		data.phoneLandline = signatory.contact.user.phoneLandline
		data.phoneMobile = signatory.contact.user.phoneMobile
		data.phoneWork = signatory.contact.user.phoneWork
	}

	// override with signatory data
	data.salutation = signatory.salutation || data.salutation
	data.firstName = signatory.contact.firstName || data.firstName
	data.lastName = signatory.contact.lastName || data.lastName
	data.deliveryInstructions = signatory.deliveryInstructions || data.deliveryInstructions
	data.address = signatory.address || data.address
	data.emailAddress = signatory.emailAddress || data.emailAddress
	data.phoneLandline = signatory.phoneLandline || data.phoneLandline
	data.phoneMobile = signatory.phoneMobile || data.phoneMobile
	data.phoneWork = signatory.phoneWork || data.phoneWork

	return data
}

export enum SignatoryAccessStatus {
	noUser = 'noUser',
	denied = 'denied',
	expired = 'expired',
	invited = 'invited',
	active = 'active',
}
export function getSignatoryAccessStatus(signatory: Signatory): SignatoryAccessStatus {
	if (!signatory) {
		return SignatoryAccessStatus.noUser
	}
	if (
		signatory.accessGrantedTo &&
		format(new Date(), 'yyyy-MM-dd') > signatory.accessGrantedTo // access not granted anymore
	) {
		return SignatoryAccessStatus.expired
	}
	if (
		!signatory.isAccessGranted || // access generally disabled
		(signatory.accessGrantedFrom && format(new Date(), 'yyyy-MM-dd') < signatory.accessGrantedFrom) // access not yet granted
	) {
		return SignatoryAccessStatus.denied
	}
	if (!signatory.contact.user) {
		// special case, e.g. when the invited user already has BO rights or can otherwise not be registered
		// or when the contract isn't active, we could decouple this into a separate case
		return SignatoryAccessStatus.noUser
	}
	if (signatory.contact.user.isRegistered) {
		// user is registered and should have access
		return SignatoryAccessStatus.active
	}
	// This should never happen, we could decouple this into a separate case
	return SignatoryAccessStatus.invited
}

export function signatoryName(signatory: Signatory): string {
	if (!signatory) {
		return ''
	}
	//legal contacts have no underlaying user, so we need to check for that
	if (signatory.contact.personType === 'legal') {
		return signatory.contact.companyName || signatory.contact.emailAddress || ''
	}
	if (
		(!signatory.contact.user || !signatory.contact.user.isRegistered) &&
		!signatory.contact.firstName &&
		!signatory.contact.lastName
	) {
		return signatory.contact.emailAddress || ''
	}

	const salutation = signatory.contact.salutation
	const firstName = signatoryFirstName(signatory.contact)
	const lastName = signatoryLastName(signatory.contact)

	return [salutation, firstName, lastName].filter((e) => e).join(' ')
}

export function signatoryFirstName(contact: NaturalContact) {
	return contact.firstName || (contact.user && contact.user.firstName)
}

export function signatoryLastName(contact: NaturalContact) {
	return contact.lastName || (contact.user && contact.user.lastName)
}

export function sort(contracts: Contract[]) {
	return contracts.sort((a, b) => {
		return isBefore(new Date(a.validFrom), new Date(b.validFrom)) ? 1 : -1
	})
}

interface SignatoryModalNote {
	icon: string
	messageKey: string
	messageArgs: { [key: string]: any }
}

export function getSignatoryModalNote(
	object: PropertyObject,
	contract: Contract,
	persistedSignatory: Signatory,
	transientSignatory: Partial<Signatory>,
): SignatoryModalNote | null {
	const notes = {
		error: {
			icon: 'blind',
			messageKey: 'domain.contract.signatory.profileNote.error',
			messageArgs: {},
		},
		noObjectUserAccess: {
			icon: 'ban',
			messageKey: 'domain.contract.signatory.profileNote.noObjectUserAccess',
			messageArgs: {},
		},
		pastContract: {
			icon: 'hourglass-end',
			messageKey: 'domain.contract.signatory.profileNote.pastContract',
			messageArgs: {},
		},
		futureContractInvitation: {
			icon: 'calendar-alt',
			messageKey: 'domain.contract.signatory.profileNote.futureContractInvitation',
			messageArgs: { emailAddress: transientSignatory.emailAddress },
		},
		noEmailAddressGiven: {
			icon: 'at',
			messageKey: 'domain.contract.signatory.profileNote.noEmailAddressGiven',
			messageArgs: {},
		},
		noSignatoryAccess: {
			icon: 'ban',
			messageKey: 'domain.contract.signatory.profileNote.noSignatoryAccess',
			messageArgs: {},
		},
		signatoryAccessExpired: {
			icon: 'hourglass-end',
			messageKey: 'domain.contract.signatory.profileNote.signatoryAccessExpired',
			messageArgs: {},
		},
		invitationWillBeSent: {
			icon: 'paper-plane',
			messageKey: 'domain.contract.signatory.profileNote.invitationWillBeSent',
			messageArgs: { emailAddress: transientSignatory.emailAddress },
		},
	}

	// higher level access deactivated?
	if (!object.userAccess) {
		return notes.noObjectUserAccess
	}
	if (!contract.isActive) {
		if (isPastContract(contract)) {
			return notes.pastContract
		} else if (isFutureContract(contract)) {
			if (transientSignatory.emailAddress) {
				return notes.futureContractInvitation
			}
			return notes.noEmailAddressGiven
		}
		return notes.error
	}

	// signatory access not granted?
	if (!transientSignatory.isAccessGranted) {
		return notes.noSignatoryAccess
	}
	if (
		transientSignatory.isAccessGranted &&
		transientSignatory.accessGrantedTo &&
		isPast(
			set(parse(transientSignatory.accessGrantedTo, 'yyyy-MM-dd', new Date()), {
				hours: 23,
				minutes: 59,
				seconds: 59,
			}),
		)
	) {
		return notes.signatoryAccessExpired
	}

	// no email address given?
	if (!transientSignatory.emailAddress) {
		if (persistedSignatory.user) {
			return null // email address has been removed after user has been added, we just show the user
		}
		return notes.noEmailAddressGiven
	}

	// until here, all reasons to not have the user added are checked, so we'll invite the user
	if (!persistedSignatory.contact.user) {
		return notes.invitationWillBeSent
	}

	// there is a user, so show their profile
	return null
}

export function sortContracts(contracts: Contract[]) {
	const result = {
		tenant: {
			present: [],
			future: [],
			past: [],
		},
		owner: {
			present: [],
			future: [],
			past: [],
		},
	}
	for (const contract of contracts) {
		const cat = isPastContract(contract) ? 'past' : isFutureContract(contract) ? 'future' : 'present'
		const type = isOwnerContract(contract) ? 'owner' : 'tenant'
		result[type][cat].push(contract)
	}

	// sort by dates
	for (const type of ['tenant', 'owner'])
		for (const time of ['past', 'present', 'future']) result[type][time] = sort(result[type][time])
	return result
}

export function getContractDates(contracts) {
	const result: any = {}
	// latest
	result.latest = getContractDatesForPeriod(['future', 'present', 'past'], contracts)
	// earliest
	result.earliest = getContractDatesForPeriod(['past', 'present', 'future'], contracts)
	return result
}

function getContractDatesForPeriod(period, contracts) {
	const result = { owner: {}, tenant: {} }
	for (const type of ['owner', 'tenant'])
		for (const p of period) {
			result[type] = {
				from: '',
				fromBefore: '',
				fromAfter: '',
				to: '',
				toBefore: '',
				toAfter: '',
			}
			const contract = contracts[type][p].length ? contracts[type][p][0] : null
			if (contract) {
				result[type] = {
					...result[type],
					from: contract.validFrom,
					fromBefore: getDayBefore(contract.validFrom),
					fromAfter: getDayAfter(contract.validFrom),
					to: contract.validTo,
					toBefore: getDayBefore(contract.validTo),
					toAfter: getDayAfter(contract.validTo),
				}
				break
			}
		}
	return result
}

function getDayBefore(date) {
	if (!date) return ''
	return format(subDays(parse(date, dateFormat, new Date()), 1), dateFormat) || ''
}

function getDayAfter(date) {
	if (!date) return ''
	return format(addDays(parse(date, dateFormat, new Date()), 1), dateFormat) || ''
}

export function getGapContracts(contracts: Contract[]) {
	const gaps = { owner: [], tenant: [] } // reset
	contracts.forEach((contract) => {
		const precedingContracts = contracts.filter((i) => !!i.validTo && i.validTo < contract.validFrom)

		// no preceding contracts
		if (!precedingContracts.length) return
		const firstGapDay = add(
			max(precedingContracts.map((i) => this.$dateFns.parse(i.validTo, this.dateFormat, new Date()))),
			{ days: 1 },
		)

		// previous contract ends one day before
		if (isSameDay(firstGapDay, parse(contract.validFrom, this.dateFormat, new Date()))) return

		// now create a gap
		gaps[contract.contractType].push({
			gap: true,
			contractType: contract.contractType,
			validFrom: format(firstGapDay, this.dateFormat),
			validTo: this.$dateFns.format(
				sub(parse(contract.validFrom, this.dateFormat, new Date()), {
					days: 1,
				}),
				this.dateFormat,
			),
		})
	})
	return gaps
}

export const translateContractSelectionSummary = (summary: ContractSelectionSummary, $i18n) => {
	// if there's nothing selected at all
	if (!summary?.ownerCount && !summary?.tenantCount) {
		return $i18n.$t('domain.contract.selectionSummary.none')
	}

	// if there's only a few signatories with names selected
	if (summary.names?.length && summary.names.length <= 3) {
		return enumerate(summary.names, ', ', ` ${$i18n.$t('layout.and')} ` as string)
	}

	let parts = []
	if (summary.ownerCount) {
		parts = [...parts, `${summary.ownerCount} ${$i18n.$tc('domain.user.role.owner', summary.ownerCount)}`]
	}
	if (summary.tenantCount) {
		parts = [...parts, `${summary.tenantCount} ${$i18n.$tc('domain.user.role.tenant', summary.tenantCount)}`]
	}

	// just tell how many owners and tenants
	return enumerate(parts, ', ', ` ${$i18n.$t('layout.and')} ` as string)
}

export function isOwnerOccupied(contract?: Contract, ownerContract?: Contract): boolean {
	if (!contract || !ownerContract || !contract.isActive || !ownerContract.isActive) {
		return false
	}

	if ([...contract.signatories, ...ownerContract.signatories].some((signatory) => signatory.isAgent)) {
		return false
	}

	if (
		!contract.signatories.some((signatory) =>
			ownerContract.signatories.some((ownerSignatory) => signatory.contact.id === ownerSignatory.contact.id),
		)
	) {
		return false
	}

	return true
}
