
	import { Component, Vue, Model } from 'nuxt-property-decorator'
	import { Address, addressToString } from '~/app/domain/common/Address'

	@Component({
		components: {},
	})
	export default class AddressLine extends Vue {
		@Model('change', { required: true }) address: Address

		get addressStr() {
			return this.address ? addressToString(this.address) : null
		}
	}
