// A map of (almost all) ISO-3166-1 alpha-2 codes and their respective German names.
export const ISO_3166_1: { [key: string]: string } = {
	af: 'Afghanistan',
	eg: 'Ägypten',
	ax: 'Åland',
	al: 'Albanien',
	dz: 'Algerien',
	as: 'Amerikanisch-Samoa',
	vi: 'Amerikanische Jungferninseln',
	ad: 'Andorra',
	ao: 'Angola',
	ai: 'Anguilla',
	aq: 'Antarktika',
	ag: 'Antigua und Barbuda',
	gq: 'Äquatorialguinea',
	ar: 'Argentinien',
	am: 'Armenien',
	az: 'Aserbaidschan',
	et: 'Äthiopien',
	au: 'Australien',

	bs: 'Bahamas',
	bh: 'Bahrain',
	bd: 'Bangladesch',
	bb: 'Barbados',
	by: 'Belarus',
	be: 'Belgien',
	bz: 'Belize',
	bj: 'Benin',
	bm: 'Bermuda',
	bt: 'Bhutan',
	bo: 'Bolivien',
	ba: 'Bosnien und Herzegowina',
	bw: 'Botswana',
	br: 'Brasilien',
	vg: 'Britische Jungferninseln',
	bn: 'Brunei Darussalam',
	bg: 'Bulgarien',
	bf: 'Burkina Faso',
	bi: 'Burundi',

	cl: 'Chile',
	cn: 'China (Volksrepublik)',
	tw: 'China (Taiwan)',
	cr: 'Costa Rica',
	ci: 'Elfenbeinküste',
	cw: 'Curaçao',

	dk: 'Dänemark',
	cd: 'Demokratische Republik Kongo',
	de: 'Deutschland',
	dm: 'Dominica',
	do: 'Dominikanische Republik',
	dj: 'Dschibuti',

	ec: 'Ecuador',
	sv: 'El Salvador',
	er: 'Eritrea',
	ee: 'Estland',

	fo: 'Färöer',
	fj: 'Fidschi',
	fi: 'Finnland',
	fr: 'Frankreich',
	gf: 'Französisch-Guayana',
	pf: 'Französisch-Polynesien',

	ga: 'Gabun',
	gm: 'Gambia',
	ge: 'Georgien',
	gh: 'Ghana',
	gi: 'Gibraltar',
	gd: 'Grenada',
	gr: 'Griechenland',
	gl: 'Grönland',
	gp: 'Guadeloupe',
	gu: 'Guam',
	gt: 'Guatemala',
	gn: 'Guinea',
	gy: 'Guyana',

	ht: 'Haiti',
	hn: 'Honduras',
	hk: 'Hongkong',

	in: 'Indien',
	id: 'Indonesien',
	iq: 'Irak',
	ir: 'Iran',
	ie: 'Irland',
	is: 'Island',
	im: 'Isle of Man',
	il: 'Israel',
	it: 'Italien',

	jm: 'Jamaika',
	jp: 'Japan',
	ye: 'Jemen',
	jo: 'Jordanien',

	ky: 'Kaimaninseln',
	kh: 'Kambodscha',
	cm: 'Kamerun',
	ca: 'Kanada',
	cv: 'Kap Verde',
	kz: 'Kasachstan',
	qa: 'Katar',
	ke: 'Kenia',
	kg: 'Kirgisistan',
	ki: 'Kiribati',
	co: 'Kolumbien',
	km: 'Komoren',

	hr: 'Kroatien',
	cu: 'Kuba',
	kw: 'Kuwait',

	la: 'Laos',
	ls: 'Lesotho',
	lv: 'Lettland',
	lb: 'Libanon',
	lr: 'Liberia',
	ly: 'Libyen',
	li: 'Liechtenstein',
	lt: 'Litauen',
	lu: 'Luxemburg',

	mo: 'Macau',
	mg: 'Madagaskar',
	mw: 'Malawi',
	my: 'Malaysia',
	mv: 'Malediven',
	ml: 'Mali',
	mt: 'Malta',
	ma: 'Marokko',
	mh: 'Marshallinseln',
	mq: 'Martinique',
	mr: 'Mauretanien',
	mu: 'Mauritius',
	yt: 'Mayotte',
	mk: 'Mazedonien',
	mx: 'Mexiko',
	fm: 'Mikronesien',
	md: 'Moldawien',
	mc: 'Monaco',
	mn: 'Mongolei',
	me: 'Montenegro',
	mz: 'Mosambik',
	mm: 'Myanmar (Burma)',

	na: 'Namibia',
	np: 'Nepal',
	nc: 'Neukaledonien',
	nz: 'Neuseeland',
	ni: 'Nicaragua',
	nl: 'Niederlande',
	ne: 'Niger',
	ng: 'Nigeria',
	kp: 'Nordkorea ',
	no: 'Norwegen',

	om: 'Oman',
	at: 'Österreich',

	pk: 'Pakistan',
	ps: 'Palästina',
	pw: 'Palau',
	pa: 'Panama',
	pg: 'Papua-Neuguinea',
	py: 'Paraguay',
	pe: 'Peru',
	ph: 'Philippinen',
	pl: 'Polen',
	pt: 'Portugal',
	pr: 'Puerto Rico',

	cg: 'Republik Kongo',
	re: 'Réunion',
	rw: 'Ruanda',
	ro: 'Rumänien',
	ru: 'Russland',

	sb: 'Salomonen',
	mf: 'Saint-Martin',
	zm: 'Sambia',
	ws: 'Samoa',
	sm: 'San Marino',
	st: 'São Tomé und Príncipe',
	sa: 'Saudi-Arabien',
	se: 'Schweden',
	ch: 'Schweiz',
	sn: 'Senegal',
	rs: 'Serbien',
	sc: 'Seychellen',
	sl: 'Sierra Leone',
	zw: 'Simbabwe',
	sg: 'Singapur',
	sx: 'Sint Maarten',
	sk: 'Slowakei',
	si: 'Slowenien',
	so: 'Somalia',
	es: 'Spanien',
	lk: 'Sri Lanka',
	kn: 'St. Kitts und Nevis',
	lc: 'St. Lucia',
	za: 'Südafrika',
	sd: 'Sudan',
	kr: 'Südkorea',
	ss: 'Südsudan',
	sr: 'Suriname',
	sj: 'Svalbard',
	sz: 'Swasiland',
	sy: 'Syrien',

	tj: 'Tadschikistan',
	tz: 'Tansania',
	th: 'Thailand',
	tg: 'Togo',
	to: 'Tonga',
	tt: 'Trinidad und Tobago',
	td: 'Tschad',
	cz: 'Tschechische Republik',
	tn: 'Tunesien',
	tr: 'Türkei',
	tm: 'Turkmenistan',
	tv: 'Tuvalu',

	ug: 'Uganda',
	ua: 'Ukraine',
	hu: 'Ungarn',
	uy: 'Uruguay',
	uz: 'Usbekistan',

	vu: 'Vanuatu',
	ve: 'Venezuela',
	ae: 'Vereinigte Arabische Emirate (UAE)',
	us: 'Vereinigte Staaten von Amerika (USA)',
	gb: 'Vereinigtes Königreich (UK)',
	vn: 'Vietnam',

	cf: 'Zentralafrikanische Republik',
	cy: 'Zypern',
}

type CountryCode = string

interface LabelledCountry {
	value: CountryCode
	label: string
}

export function ISO_3166_1_array(): LabelledCountry[] {
	return Object.keys(ISO_3166_1).map((k) => ({
		value: k,
		label: ISO_3166_1[k],
	}))
}

export function resolveCountryCode(subject, fallback = null): CountryCode {
	subject = String(subject).trim().toLowerCase()
	switch (true) {
		// valid country codes
		case Object.keys(ISO_3166_1).includes(subject):
			return subject

		// known fully spelled countries
		case subject.includes('schland'):
			return 'de'

		// no country found, falling back to most likely country
		default:
			return fallback
	}
}
