
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import HelpBtn from '~/components/domain/help/HelpBtn.vue'

	@Component({
		components: { HelpBtn },
	})
	export default class InfoContainer extends Vue {
		@Prop({ default: null }) title: string
		@Prop({ default: null }) subtitle: string
		@Prop({ default: false }) trim: boolean
		@Prop({ default: null }) back: { label: string; to: string; fn?: Function }
		@Prop({ default: false }) embedded
		@Prop({ default: false }) helpArticle: string

		backClick() {
			if (this.back?.fn) {
				this.back.fn()
			}
		}
	}
