
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Branding, Tenant } from '~/app/domain/identifyAndAccess/Tenant'
	import Image from '~/app/utils/globals/Image'

	@Component({})
	export default class FooterBranding extends Vue {
		@Prop({ required: true, type: Object }) tenant: Tenant
		@Prop({ required: true, type: Object }) branding: Branding
		@Prop({ default: false }) preview: boolean

		Image = Image

		get styles() {
			const styles = {
				padding: '26px',
				'background-color': this.branding.frontOffice.isBgColorEnabled
					? this.branding.frontOffice.bgColor
					: null,
			}

			if (this.branding.frontOffice.isImageEnabled) {
				if (this.branding.frontOffice.bgImageAlign) {
					styles['text-align'] = this.branding.frontOffice.imageAlign
				}
			}

			if (this.branding.frontOffice.isBgImageEnabled && this.branding.frontOffice.bgImage) {
				styles['background-image'] = `url('${Image.resolveImg(this.branding.frontOffice.bgImage, 1200)}')`

				if (this.branding.frontOffice.bgImageAlign) {
					styles['background-position'] = this.branding.frontOffice.bgImageAlign
				}

				switch (this.branding.frontOffice.bgImageStrategy) {
					case 'none':
						break
					case 'repeat':
						styles['background-repeat'] = 'repeat'
						break
					case 'cover':
						styles['background-size'] = 'cover'
						break
				}
			}

			if (this.branding.frontOffice.bgColor) {
				styles['background-color'] = this.branding.frontOffice.bgColor
			}

			return styles
		}
	}
