
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import LanguageSwitch from '~/components/inputs/LanguageSwitch.vue'
	import FooterBranding from '~/components/domain/tenant/branding/FooterBranding.vue'

	@Component({
		components: { FooterBranding, LanguageSwitch },
	})
	export default class FooterFocused extends Vue {
		@Prop({ default: false }) app: boolean
		get tenant() {
			const t = this.$store.state.tenant
			return t && t.tenant
		}

		get branding() {
			const t = this.$store.state.tenant
			return t && t.branding
		}

		get absolute() {
			return true
		}

		get fixed() {
			return this.$vuetify.breakpoint.smAndDown
		}

		get showBranding() {
			return !this.$route.path.startsWith(`/app/${this.$route.params.subdomain}/account`)
		}

		get imprintPath() {
			const sd = this.$route.params.subdomain
			return sd ? `/app/${sd}/imprint` : '/app/imprint'
		}
	}
