import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import { required, email, min, max } from 'vee-validate/dist/rules'
import { parsePhoneNumber } from 'awesome-phonenumber'

// install the locales
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'
import { isFullUrl } from '~/app/common/validation/Validation'
localize({
	en: {
		messages: {
			...en.messages,
			password: 'The passwords do not match.',
			phone: 'Invalid phone number.',
			url: 'Invalid URL.',
		},
	},
	de: {
		messages: {
			...de.messages,
			password: 'Die Passwörter stimmen nicht überein.',
			phone: 'Ungültige Telefonnummer',
			url: 'Ungültige URL.',
		},
	},
})

let LOCALE = process.env.LOCALE || 'en'
Object.defineProperty(Vue.prototype, 'veeLocale', {
	get() {
		return LOCALE
	},
	set(val) {
		LOCALE = val
		localize(val)
	},
})
localize(LOCALE)

declare module 'vue/types/vue' {
	interface Vue {
		veeLocale: string
	}
}

// register rules
extend('email', email)
extend('required', required)
extend('min', min)
extend('max', max)
extend('password', {
	params: ['target'],
	// @ts-ignore
	validate(value, { target }) {
		return value === target
	},
})
extend('phone', {
	validate(value) {
		return new Promise((resolve) => {
			const phone = parsePhoneNumber(value)
			resolve({ valid: phone.valid })
		})
	},
})
extend('url', {
	validate(value) {
		return new Promise((resolve) => {
			resolve(isFullUrl(value))
		})
	},
})
extend('iban', {
	validate(value) {
		return new Promise((resolve) => {
			resolve(
				new RegExp(
					/^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/,
				).test(value),
			)
		})
	},
})
extend('bic', {
	validate(value) {
		return new Promise((resolve) => {
			resolve(new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/).test(value))
		})
	},
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
