var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.inboundEmail)?_c('div',[_c('div',{class:{
			title: true,
			'primary--text': true,
			'pb-1': true,
		}},[_vm._v("\n\t\t"+_vm._s(_vm.$t('layout.actions'))+"\n\t")]),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_vm._l((_setup.actions),function({ render = true, ...action },actionId){return [(render)?_c('v-col',{key:actionId,attrs:{"cols":12}},[_c(_setup.ActionButton,{attrs:{"btn":{ ...action, text: _vm.$t(action.text) }}})],1):_vm._e()]}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.EmployeeSelectBtn,{staticClass:"pb-3",attrs:{"items":_vm.inboundEmail.assignees,"i18keys":{
					noItems: 'concept.assignees.noAssignees',
					nItems: 'concept.assignees.nAssignees',
					addUser: 'concept.assignees.selfAssign',
					remove: 'concept.assignees.selfUnassign',
				},"icons":{
					userActive: _setup.Image.getIconForDomain('employee'),
					otherActive: _setup.Image.getIconForDomain('employee'),
					noneActive: 'fal fa-user-slash',
				},"qa-prefix":"email-actions:assignees"},on:{"set":_setup.setAssignees}})],1)],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }