
	import Utils from '~/app/utils/globals/Utils'
	import Vue from 'vue'
	import { Component, Model, Watch } from 'nuxt-property-decorator'
	import LanguageSwitch from '~/components/inputs/LanguageSwitch.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import { search } from '~/app/common/filters/iterator/Search'
	import { helpArticleSearchKeys } from '~/app/domain/content/Help'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'

	@Component({
		components: {
			SfTextArea,
			SearchInput,
			SfTextField,
			LanguageSwitch,
			Keypress: () => import('vue-keypress'),
		},
	})
	export default class ConfigPanel extends Vue {
		@Model('changed', { required: true }) visible: boolean
		// control visibility
		localVisible = false
		@Watch('visible')
		updateVisible() {
			this.localVisible = this.visible
		}

		@Watch('localVisible')
		updateLocalVisible() {
			this.$emit('changed', this.localVisible)
			if (!this.localVisible) this.$emit('close')
		}

		get categories() {
			return [
				{
					text: 'API',
					value: 'api',
				},
				{
					text: this.$t('components.configPanel.locales.title'),
					value: 'locales',
				},
				{
					text: this.$t('components.configPanel.navigation.title'),
					value: 'router',
				},

				{
					text: this.$t('components.configPanel.features.title'),
					value: 'features',
				},
			]
		}

		/**
		 * Router
		 */
		routerUrl: string = null

		/**
		 * Features
		 */
		get features() {
			const features = this.$store.getters['features/merged']
			return Object.keys(this.$store.getters['features/merged'])
				.map((f) => {
					return {
						text: f,
						value: features[f],
					}
				})
				.filter((f) => f.text !== '_')
				.sort((a, b) => {
					return a.text > b.text ? 1 : -1
				})
		}

		toggleFeature(feature) {
			const cValue = this.$store.getters['features/merged'][feature] || false
			this.$store.dispatch('features/override', { [feature]: !cValue })
			this.filterFeatures()
		}

		get isAdmin() {
			return this.$store.getters['tenant/isAdministrator']
		}

		toggleAdmin() {
			return this.$store.dispatch('tenant/overrideAdministrator', !this.isAdmin)
		}

		featureSearch = ''
		filteredFeatures = []
		@Watch('featureSearch', { immediate: true }) updateFeatureSearch() {
			this.filterFeatures()
		}

		filterFeatures() {
			let filtered = this.features
			if (this.featureSearch) {
				filtered =
					search(filtered, this.featureSearch, {
						fuzzy: true,
						keys: ['text'],
					}) || []
			}
			this.filteredFeatures = filtered
		}

		/**
		 * Locales
		 */
		get locales() {
			return this.$i18n.locales.map((l) => (l as any).code)
		}

		changeLocale() {
			const cIndex = this.locales.findIndex((l) => {
				return this.$i18n.locale === l
			})
			const newLocaleIndex = cIndex >= this.locales.length - 1 ? 0 : cIndex + 1
			const newLocale = this.locales[newLocaleIndex]
			this.$i18n.setLocale(newLocale)
			this.veeLocale = newLocale
		}

		/**
		 * Api
		 */
		get currentApi() {
			return this.$store.getters['api/override'] || this.$config.apiDomain
		}

		get apiBtn() {
			const apiIndex = this.apiItems.findIndex((i) => i.value === this.currentApi)
			if (apiIndex >= 0) {
				return this.apiItems[apiIndex]
			}
		}

		get apiItems() {
			return [
				{
					text: 'develop',
					value: 'etg24test.de',
				},
				{
					text: 'production',
					value: 'etg24.de',
				},
			]
		}

		async apiChanged(value) {
			if (value) {
				try {
					await this.$axios.$post('etg24:auth:logout')
					await this.$auth.logout()
					await this.$router.push('/app/account/login')
					await this.$store.dispatch('api/setOverride', value)
				} catch (e) {
					Utils.handleError(e)
				}
			}
		}

		toggleApi() {
			const apiIndex = this.apiItems.findIndex((i) => i.value === this.currentApi)

			if (apiIndex >= 0) {
				const newApiIndex = apiIndex === 0 ? 1 : 0
				this.apiChanged(this.apiItems[newApiIndex]?.value)
			}
		}

		/**
		 * Navigation
		 */
		goToUrl(url) {
			const sUrl = url.split('/app/')
			if (sUrl.length > 1) {
				const nUrl = `/app/${sUrl[1]}`
				this.$router.push(nUrl)
			}
		}
	}
