
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import { Process } from '~/app/domain/content/Process'
	import DocumentList from '~/components/domain/document/DocumentList.vue'
	import SimpleDocumentList from '~/components/domain/document/SimpleDocumentList.vue'
	import ChipDocumentList from '~/components/domain/document/ChipDocumentList.vue'

	@Component({
		components: { ChipDocumentList, SimpleDocumentList, DocumentList },
	})
	export default class ProcessPhases extends Vue {
		@Model('change', { required: false }) process: Process

		get phases() {
			return this.process.phases.map((phase) => ({
				...phase,
				documents: this.process.attachments.documents.filter(
					(document) => document.associatedWith === phase.id,
				),
				activities: phase.activities.map((activity) => ({
					...activity,
					documents: this.process.attachments.documents.filter(
						(document) => document.associatedWith === activity.id,
					),
				})),
			}))
		}

		selectedDocuments = []
		selectedPhase = null
		togglePhase(id) {
			this.selectedPhase === id ? (this.selectedPhase = null) : (this.selectedPhase = id)
		}
	}
