interface KeyValue {
	key: string
	value: string
}

export const state = () => ({
	keys: {},
})

export const getters = {
	key: (state) => (key: string) => {
		return state.keys[key] || false
	},
	keys: (state) => {
		return state.keys
	},
}

export const mutations = {
	set: (state, keyValue: KeyValue) => {
		state.keys[keyValue.key] = keyValue.value
	},
}

export const actions = {
	set: async (context, kv: KeyValue) => {
		await context.commit('set', kv)
	},
}
