
	import Image from '~/app/utils/globals/Image'
	import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
	import { Node } from '~/app/domain/content/Common'
	import { Note, ScopeType, getNoteLists } from '~/app/domain/notes/Note'
	import BottomSheet from '~/components/containers/BottomSheet.vue'
	import NotesList from '~/components/domain/notes/NotesList.vue'
	import DetailLayout, { TitleConfiguration } from '~/components/utils/DetailLayout.vue'

	@Component({
		components: { BottomSheet, DetailLayout, NotesList },
	})
	export default class DetailCard extends Vue {
		@Prop({ default: null }) backPath: string
		@Prop({ type: Boolean, default: false }) fullWidth: boolean
		@Prop({ default: false }) noFab: boolean
		@Prop({ type: String, default: null }) notesScope: ScopeType
		@Prop({ default: null }) notesScopeId: string
		@Prop({ default: false, type: Boolean }) autoBackPath: boolean
		@Prop({ type: Boolean, default: false }) standalone: boolean
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: Boolean, default: false }) disableFixedHeight: boolean
		@Prop({ type: Object, default: null }) title: TitleConfiguration
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		get prevPath() {
			if (!this.autoBackPath) return null
			const pPath = this.$store.state.route.from.fullPath
			return pPath === '/' || pPath.startsWith(this.$route.path) ? null : pPath
		}

		get closeFn() {
			if (this.$listeners && this.$listeners.close != null) return true
			else return false
		}

		/**
		 * Notes
		 */
		notes: Note[] = []
		noteNodes: Node[] = []
		get notesEnabled() {
			return this.notesScope && this.notesScopeId
		}

		@Watch('notesEnabled', { immediate: true })
		async notesEnabledUpdated() {
			if (this.notesEnabled) {
				const response = (
					await this.$axios.get('etg24:internal:query:notes.atscope', {
						params: { scope: this.notesScopeId },
					})
				).data as { notes: Note[]; nodes: Node[] }
				this.notes = response.notes || []
				this.noteNodes = response.nodes
			}
		}

		get relevantNotes() {
			return getNoteLists(this.notes, this.$auth.user.id).relevant.length
		}

		/**
		 * Fab visibility
		 */
		activeFab = null

		get fabsOpen() {
			return this.activeFab != null
		}

		switchFabs(fab, value) {
			if (value) this.activeFab = fab
		}

		/**
		 * Page sync
		 */
		get qPage() {
			return this.$route.query.contentPage
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		get presentationMode() {
			return this.$store.getters['ui/isInPresentationMode']
		}

		get isFixedHeightEnabled() {
			if (this.disableFixedHeight) return false
			else return this.$vuetify.breakpoint.mdAndUp
		}
	}
