
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { CompanyGroup } from '~/app/domain/provider/CompanyGroup'

	@Component({})
	export default class CompanyGroupCard extends Vue {
		// ContactPerson & WithCategory
		@Model('change', { required: true }) group: CompanyGroup
		@Prop({ default: null }) to: string
		@Prop({ default: false }) small: boolean
		@Prop({ default: false }) extId: boolean

		Image = Image

		get companies() {
			return this.group.companies || []
		}
	}
