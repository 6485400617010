
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import formatDate, { FORMAT_NUMBERS, PRECISION_MINUTE } from '~/app/common/filters/Date'

	@Component({
		components: { BasicDialog },
	})
	export default class SfDateTime extends Vue {
		@Model('changed', { default: '' }) value: string
		@Prop({ default: null }) rules: string
		@Prop({ default: null }) label: string
		@Prop({ default: null }) preset: string
		@Prop({ default: null }) description: string
		@Prop({ type: Boolean, default: false }) required: false
		@Prop({ default: null }) validatorPrefix: string
		@Prop({ default: null }) placeholder: string
		@Prop({ default: false }) disabled: boolean
		@Prop({ default: null }) min: string
		@Prop({ default: null }) max: string
		@Prop({ default: true }) clearable: boolean
		@Prop({ default: undefined }) allowedMinutes: any[]

		isMenuOpen = false

		localValue: string = null
		picker = 'date'
		localDate: string = ''
		localTime: string = ''

		isMounted: boolean = false
		mounted() {
			this.isMounted = true
		}

		@Watch('value', { immediate: true })
		valueUpdated() {
			if (this.value) {
				this.localDate = this.$dateFns.format(this.$dateFns.parseISO(this.value), 'yyyy-MM-dd')
				this.localTime = this.$dateFns.format(this.$dateFns.parseISO(this.value), 'HH:mm')
				this.localValue = `${this.localDate} ${this.localTime}`
			} else {
				this.value = this.preset
			}
		}

		@Watch('localValue', { immediate: true })
		localValueUpdated() {
			if (this.localValue)
				this.$emit(
					'changed',
					this.$dateFns.parse(this.localValue, 'yyyy-MM-dd HH:mm', new Date()).toISOString(),
				)
		}

		@Watch('isMenuOpen', { immediate: false })
		closeDialog() {
			this.picker = 'date'
			if (!this.isMenuOpen) (this.$refs.textfield as any).focus()
		}

		get rLabel() {
			return `${this.label || ''}${this.required ? '*' : ''}`
		}

		/**
		 * focus functionality
		 */
		focus() {
			if (!this.isMenuOpen) {
				;(this.$refs.textfield as any).focus()
			}

			this.isMenuOpen = true
		}

		get rValue() {
			return formatDate(this.$i18n, this.localValue, FORMAT_NUMBERS, PRECISION_MINUTE)
		}

		setDate() {
			this.localValue = `${this.localDate} ${this.localTime}`
			this.isMenuOpen = false
			this.picker = 'date'
		}

		textFieldClick() {
			if (!this.disabled) this.isMenuOpen = true
		}

		setHour(hour) {
			if (this.allowedMinutes?.length === 1) {
				this.localTime = `${hour}:${this.allowedMinutes[0]}`
				this.setDate()
			}
		}

		/**
		 * Mins
		 */
		get minDate() {
			if (this.min) {
				return this.$dateFns.format(this.$dateFns.parseISO(this.min), 'yyyy-MM-dd')
			}
		}

		get minTime() {
			if (this.minDate && this.minDate === this.localDate) {
				return this.$dateFns.format(this.$dateFns.parseISO(this.min), 'HH:mm')
			}
		}
	}
