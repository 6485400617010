// Unit
import { DayString, Uuid } from '../Types'
import { Entity } from '../common/Interfaces'
import { Validators } from '../../common/validation/Validators'
import { ObjectAddressId, PropertyObjectId } from './Object'
import { Contract } from '~/app/domain/property/Contract'

/*
 * Model
 */

export enum UnitType {
	apartment = 'apartment',
	business = 'business',
	room = 'room',
	garage = 'garage',
	parking_space = 'parking_space',
}

export type UnitId = Uuid
export interface Unit extends Entity<UnitId> {
	number: string
	extId: string
	share: number
	position: string
	area: number
	addressId: ObjectAddressId
	objectId: PropertyObjectId
	type: UnitType
	votingWeight: number
}

/*
 * Validation
 */

export const unitValidations = {
	number: {
		required: Validators.required,
	},
	share: {
		number: Validators.number,
		maxDigits: Validators.maxDigits(6),
		maxPrecision: Validators.maxPrecision(6),
		positive: Validators.positive,
	},
	area: {
		number: Validators.number,
		maxDigits: Validators.maxDigits(6),
		maxPrecision: Validators.maxPrecision(6),
		positive: Validators.positive,
	},
	extId: {},
	position: {
		required: Validators.required,
	},
	address: {
		required: Validators.required,
	},
}

interface ContractConfig {
	// the current contract
	contract: Contract | null
	// tells if the column should be shown
	view: boolean

	// tells if there should be a placeholder to create a new current/future contract (in case there is no current/future contract)
	createContract: boolean

	// tells if there should be an option to edit the validity of the current/future contract
	manageValidity: boolean

	// tells what range (min date / max date) the user can edit validity of this contract (relevant during creation of contract and when managing validity)
	validityFrom: DayString | null
	validityTo: DayString | null

	// tells if there should be an option to edit the current/future contract's signatories (non-agents)
	manageSignatories: boolean

	// tells if there should be an option to edit the current/future contract's signatories (agents)
	manageAgents: boolean
}

export interface UnitOverviewResponse {
	owner: {
		enabled: boolean
		current: ContractConfig
		future: ContractConfig
	}
	tenant: {
		enabled: boolean
		current: ContractConfig
		future: ContractConfig
	}
}

/*
 * Search
 */

export const unitSearchKeys: (keyof Unit)[] = ['number', 'extId', 'position']

/*
 * Api
 */

/*
 * Functions
 */
