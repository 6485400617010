import { Entity } from '../common/Interfaces'
import { Uuid } from '../Types'
import { MicroUser, userSearchKeys } from './User'
import { NodeId, NodeType } from '../content/Common'
import { Post } from '~/app/domain/content/Post'

/*
 * Model
 */

export type RoleId = Uuid
export interface Role<T extends RoleId = RoleId> extends Entity<T> {
	user: MicroUser
	nodeId: NodeId
	nodeType: NodeType
	type: RoleType
}

export interface RoleWithResponsibilities extends Role {
	responsibilities: {
		handleServiceNotes: boolean
	}
}

// The enumerated available role type strings
export enum RoleType {
	admin = 'admin',
	manager = 'manager',
	owner = 'owner',
	advisor = 'advisor',
	tenant = 'tenant',
	customer = 'customer',
	supplier = 'supplier',
}

// The enumerated available role context strings
export enum RoleContexts {
	frontend,
	backend,
}

// The availability of the role types within the role contexts
const roleTypeContextMap = {
	[RoleType.admin]: RoleContexts.backend,
	[RoleType.manager]: RoleContexts.backend,
	[RoleType.owner]: RoleContexts.frontend,
	[RoleType.advisor]: RoleContexts.frontend,
	[RoleType.tenant]: RoleContexts.frontend,
	[RoleType.customer]: RoleContexts.frontend,
	[RoleType.supplier]: RoleContexts.frontend,
}

export type RegistrationKeyId = Uuid
export interface RegistrationKey extends Entity<RegistrationKeyId> {
	nodeId: NodeId
	key: string
	registrationCount: number
}

/*
 * Validation
 */

/*
 * Search
 */
/*
 * Search
 */
export const roleSearchKeys: any[] = userSearchKeys.map((u) => {
	return `user.${u}`
})

/*
 * Api
 */

/*
 * Functions
 */

export function reduceToFrontendRoleTypes(roleTypes: RoleType[]) {
	return roleTypes.filter((roleType) => roleTypeContextMap[roleType] === RoleContexts.frontend)
}

export function reduceToBackendRoleTypes(roleTypes: RoleType[]) {
	return roleTypes.filter((roleType) => roleTypeContextMap[roleType] === RoleContexts.backend)
}
