var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.component,_vm._b({tag:"component",on:{"click":function($event){return _setup.emit('click', $event)}}},'component',{
		to: _vm.to,
		href: _setup.component === 'a' ? _vm.to : undefined,
		class: _setup.component === 'a' ? 'tw-block' : undefined,
		exactActiveClass: _vm.exactActiveClass,
		activeClass: _vm.activeClass,
		target: _vm.target,
		rel: _vm.rel,
		noRel: _vm.noRel,
		ariaCurrentValue: _vm.ariaCurrentValue,
		replace: _vm.replace,
	},false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }