
	import Image from '~/app/utils/globals/Image'
	import Messages from '~/app/utils/globals/Messages'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import { AssetAttachments } from '~/app/domain/assets/Attachments'
	import ImageThumb from '~/components/domain/attachments/ImageThumb.vue'
	import FileLine from '~/components/domain/attachments/FileLine.vue'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import Date from '~/components/utils/Date.vue'
	import ImageGallery from '~/components/domain/attachments/ImageGallery.vue'
	import { Document } from '~/app/domain/content/Document'
	import DocumentListStandalone from '~/components/domain/document/DocumentListStandalone.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import ReadMore from '~/components/utils/ReadMore.vue'
	import FileList from '~/components/domain/attachments/FileList.vue'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'

	@Component({
		components: {
			UserNotifications,
			FileList,
			ReadMore,
			BasicDialog,
			DocumentListStandalone,
			ImageGallery,
			Date,
			Dropzone,
			FileLine,
			ImageThumb,
		},
	})
	export default class Attachments extends Vue {
		Image = Image
		@Model('change', { required: true }) attachments: AssetAttachments
		@Prop({ default: false, type: Boolean }) hideLabels: boolean
		@Prop({ default: true, type: Boolean }) link: boolean
		@Prop({ default: false, type: Boolean }) editable: boolean
		@Prop({ default: null, type: String }) nodeId: string
		@Prop({ default: false, type: Boolean }) minimal: boolean
		@Prop({ default: false, type: Boolean }) quickImageDelete: boolean
		@Prop({ default: false, type: Boolean }) uploadNotifications: boolean
		@Prop({ default: null }) associatedWith: string
		@Prop({ type: Boolean, default: false }) collapseAttachments: boolean
		@Prop({ type: Function, default: null }) uploadFunction: any
		@Prop({ type: Function, default: null }) filterFn: any
		@Prop({ type: Boolean, default: false }) deleteableFiles: boolean
		@Prop({ type: Function, default: null }) filesToElementsFn: Function

		created() {
			this.$nuxt.$on('documentUpdated', this.updateDocument)
			this.$nuxt.$on('documentDeleted', this.deleteDocuments)
		}

		beforeDestroy() {
			this.$nuxt.$off('documentUpdated')
			this.$nuxt.$off('documentDeleted')
		}

		/**
		 * Manage local / external state
		 */
		localAttachments: AssetAttachments = null
		@Watch('attachments', { immediate: true })
		updateAttachments() {
			this.localAttachments = this.attachments
		}

		@Watch('localAttachments')
		updateLocalAttachments() {
			this.$emit('change', this.localAttachments)
		}

		onUpdateGalleryVisible(value) {
			this.showGallery = value

			if (!value) {
				this.selectedImage = null
			}
		}

		/**
		 * Attachment actions
		 */

		filesUploaded(pl) {
			this.localAttachments = this.filterFn ? this.filterFn(pl.attachments) : pl.attachments
			this.$emit('filesUploaded', pl)
		}

		updateDocument(doc) {
			const i = this.localAttachments.documents.findIndex((d) => {
				return d.id === doc.id
			})
			if (i >= 0) this.localAttachments.documents.splice(i, 1, doc)
		}

		deleteDocuments(docs) {
			for (const doc of docs) {
				const i = this.localAttachments.documents.findIndex((d) => {
					return d.id === doc
				})
				if (i >= 0) this.localAttachments.documents.splice(i, 1)
			}
		}

		notifyUpload = true

		/**
		 * Gallery
		 */
		showGallery = false
		selectedImage: any = null
		activeImage = 0
		showInGallery(image) {
			this.showGallery = true
			this.selectedImage = image
		}

		async deleteImage(img) {
			try {
				const resp = await this.$axios.$post('etg24:internal:command:content.attachment.image.delete', {
					attachment: img.id,
				})
				this.localAttachments = resp.attachments
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		/**
		 * Document dialog
		 */

		openDocument(document: Document) {
			this.$router.push({
				query: { ...this.$route.query, document: document.id },
			})
		}

		updateImage(e) {
			this.localAttachments = e.attachments
		}
	}
