import { Context } from '@nuxt/types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { resolveApiScheme } from '~/app/utils/api'
import { downloadBlobAsFile } from '~/app/utils/libs/FileUtil'
import { cloneDeep } from 'lodash'

const CQRSProtocol = 'https' // protocol for all CQRS requests
const CQRSSubdomain = 'rest' // subdomain for all CQRS requests

/**
 * Axios constructor
 */
export default function (context: Context) {
	// interceptor for default axios instance
	context.$axios.interceptors.request.use((config: AxiosRequestConfig) => {
		// send the request
		try {
			return resolveCustomSchema(context, config)
		} catch (e) {
			return config
		}
	})
	//HACK: come back in the future to fix this please remove isValid from body to prevent backend errors
	context.$axios.interceptors.request.use((config: AxiosRequestConfig) => {
		//remove isValid from body to prevent backend errors
		const isFormData = config.data instanceof FormData
		if (config.data && typeof config.data === 'object' && !isFormData) {
			const cleanedObj = deepRemoveIsValidFromObject(config.data)
			config = { ...config, data: cleanedObj }
		}
		return config
	})
}

export const url = (apiDomain: string) => {
	return (path: string) => `${CQRSProtocol}://${CQRSSubdomain}.${apiDomain}${resolveApiScheme(path)}`
}

//remove isValid from body at any depth by copy not by reference and return the cleaned object
function deepRemoveIsValidFromObject(obj: object): object {
	const cleanedObj = cloneDeep(obj)
	const keys = Object.keys(cleanedObj)
	for (const key of keys) {
		if (key === 'isValid') {
			delete cleanedObj[key]
		} else if (cleanedObj[key] && typeof cleanedObj[key] === 'object') {
			cleanedObj[key] = deepRemoveIsValidFromObject(cleanedObj[key])
		}
	}
	return cleanedObj
}

/**j
 * Resolves the custom schema to the correct request config
 *
 * @throws Error if unable to read schema
 */
export function resolveCustomSchema(context: Context, config: AxiosRequestConfig): AxiosRequestConfig {
	const apiDomain = context.store.getters['api/override'] || context.$config.apiDomain

	// add general api config
	if (config.url.startsWith('etg24:')) {
		config.headers = {
			...config.headers,
			'Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
			'Client-Language': navigator.language,
			'Client-Git-Hash': context.$config.gitSha,
			'Client-Git-Date': context.$config.gitDate,
			'Client-Platform': context.store.getters['platform/name'],
		}
		if (context.$config.deployPreview) {
			config.headers = {
				...config.headers,
				'Deploy-Preview': context.$config.deployPreview,
			}
		}
	}

	switch (true) {
		// internal api
		case config.url.startsWith('etg24:internal:query:'):
			return {
				...config,
				url: resolveApiScheme(config.url),
				headers: {
					...(config.headers || {}),
					Tenant: context.route.params.subdomain || config.headers.Tenant,
				},
				baseURL: `${CQRSProtocol}://${CQRSSubdomain}.${apiDomain}`,
			}
		case config.url.startsWith('etg24:internal:command:'):
			return {
				...config,
				url: resolveApiScheme(config.url),
				headers: {
					...(config.headers || {}),
					Tenant: context.route.params.subdomain || config.headers.Tenant,
				},
				baseURL: `${CQRSProtocol}://${CQRSSubdomain}.${apiDomain}`,
			}

		// platform api
		case config.url.startsWith('etg24:platform:query:'):
			return {
				...config,
				url: resolveApiScheme(config.url),
				baseURL: `${CQRSProtocol}://${CQRSSubdomain}.${apiDomain}`,
			}
		case config.url.startsWith('etg24:platform:command:'):
			return {
				...config,
				url: resolveApiScheme(config.url),
				baseURL: `${CQRSProtocol}://${CQRSSubdomain}.${apiDomain}`,
				headers: {
					...(config.headers || {}),
					Tenant: context.route.params.subdomain || config.headers.Tenant,
				},
			}

		// auth api
		case config.url.startsWith('etg24:auth:'):
			return {
				...config,
				url: resolveApiScheme(config.url),
				baseURL: `${CQRSProtocol}://${CQRSSubdomain}.${apiDomain}`,
			}

		// unknown scheme
		default:
			throw new Error(`Unknown custom schema <${config.url}>`)
	}
}

export const getFilenameFromResponse = (response, fallbackFilename = 'Download'): string => {
	const contentDisposition = response.headers['content-disposition']
	let filename = fallbackFilename
	if (contentDisposition) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		const matches = filenameRegex.exec(contentDisposition)
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '')
		}
	}
	return filename
}

export const getBlobFromResponse = (response: AxiosResponse): Blob => {
	return new Blob([response.data], { type: response.data.type })
}

export const getFileExtensionFromResponse = (response: AxiosResponse): string => {
	const fileName = getFilenameFromResponse(response)
	return fileName.split('.').pop()
}

export async function downloadBlobResponseAsFile(response: AxiosResponse, fallbackFilename = 'Download') {
	await downloadBlobAsFile(getBlobFromResponse(response), getFilenameFromResponse(response, fallbackFilename))
}
