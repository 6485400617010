import Vue from 'vue'

const clampElement = (el, binding) => {
	// despite the element being inserted in the dom, the content might not be rendered yet
	if (el.offsetHeight === 0 && !!el.innerText) {
		setTimeout(() => {
			clampElement(el, binding)
		}, 25)
		return
	}
	const lineHeight = parseFloat(window.getComputedStyle(el)['line-height'])
	// avoid pixel rounding issues
	const maxHeight = Math.round(lineHeight * binding.value)

	// Add full text to data attribute to save it for later usage
	if (!el.dataset.originalText) {
		el.dataset.originalText = el.innerText
	}

	const words = el.dataset.originalText.split(' ')

	// Remove words until the height is small enough
	while (el.clientHeight > maxHeight && words.length > 0) {
		words.pop()
		el.innerText = words.join(' ') + '...'
	}
}

Vue.directive('clamp', {
	inserted: function (el, binding) {
		clampElement(el, binding)
	},
})
