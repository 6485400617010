
	import { Component, Vue, Watch, Prop, Model } from 'nuxt-property-decorator'
	import { getInvoiceRecipientFromContract, getInvoiceRecipientFromObject } from '~/app/domain/content/Process'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'

	@Component({
		components: { BasicDialog, UnitContractPicker },
	})
	export default class InvoiceRecipient extends Vue {
		@Model('changed') value: string
		@Prop({ default: null }) rules: string
		@Prop({ required: true }) objectId: string
		@Prop({ default: null }) validatorPrefix: string
		@Prop({ default: null }) placeholder: string
		@Prop({ default: true }) required: false
		@Prop({ default: null }) height: number

		label = this.$t('domain.process.offerRequest.properties.invoiceRecipient.label')
		localValue = null
		@Watch('value', { immediate: true })
		updateAddress() {
			this.localValue = this.value
		}

		@Watch('localValue')
		updateText() {
			this.$emit('changed', this.localValue)
		}

		get rLabel() {
			if (this.label) return `${this.label}${this.required ? '*' : ''}`
		}

		/**
		 * focus functionality
		 */
		focus() {
			;(this.$refs.input as any).focus()
		}

		/**
		 * Setters
		 */
		get object() {
			return this.$store.getters['property/objects/all'].find((o) => {
				return o.id === this.objectId
			})
		}

		get objectAddresses() {
			return this.$store.getters['property/objectAddresses/byObject'](this.objectId)
		}

		selectContract: boolean = false
		setFromOwner(contract) {
			this.localValue = getInvoiceRecipientFromContract(contract)
			this.selectContract = false
		}

		setFromObject() {
			this.localValue = getInvoiceRecipientFromObject(this.object)
		}
	}
