
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { FitMode } from '@sanity/image-url/lib/types/types'

	@Component({})
	export default class CustomSanityImage extends Vue {
		@Prop({ required: true }) image: any
		@Prop({ default: 'max' }) fit: FitMode

		// width
		@Prop({ default: undefined, type: Number }) width: number
		@Prop({ default: undefined, type: Number }) minWidth: number
		@Prop({ default: undefined, type: Number }) maxWidth: number

		// height
		@Prop({ default: undefined, type: Number }) height: number
		@Prop({ default: undefined, type: Number }) minHeight: number
		@Prop({ default: undefined, type: Number }) maxHeight: number

		get src() {
			let builder = this.$sanityImageUrl(this.image)

			// width & height
			if (this.width) {
				builder = builder.width(this.width)
			}
			if (this.minWidth) {
				builder = builder.minWidth(this.minWidth)
			}
			if (this.maxWidth) {
				builder = builder.maxWidth(this.maxWidth)
			}
			if (this.height) {
				builder = builder.height(this.height)
			}
			if (this.minHeight) {
				builder = builder.minHeight(this.minHeight)
			}
			if (this.maxHeight) {
				builder = builder.maxHeight(this.maxHeight)
			}

			// fit
			if (this.fit) {
				builder = builder.fit(this.fit)
			}

			return builder
		}
	}
