import { render, staticRenderFns } from "./ContractSignatoryChip.vue?vue&type=template&id=768ce70c&scoped=true"
import script from "./ContractSignatoryChip.vue?vue&type=script&setup=true&lang=ts"
export * from "./ContractSignatoryChip.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ContractSignatoryChip.vue?vue&type=style&index=0&id=768ce70c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768ce70c",
  null
  
)

export default component.exports