
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Provider } from '~/app/domain/content/ContactPerson'
	import { contactTitle } from '~/app/domain/contact/Contact'
	import UiIcon from '~/components/atoms/UiIcon.vue'

	@Component({
		components: { UiIcon },
		methods: { contactTitle },
	})
	export default class ProviderListItem extends Vue {
		@Model('changed', { required: true }) provider: Provider
		@Prop({ default: null }) to: string
		@Prop({ type: Boolean, default: false }) showCompany: boolean
		@Prop({ type: Boolean, default: false }) selected: boolean

		getTitle(provider: Provider) {
			return contactTitle(provider.contact)
		}

		get clickFn() {
			if (this.$listeners && this.$listeners.click != null) return true
			else return false
		}
	}
