import SnackbarService from '~/services/SnackbarService'

export const state = () => ({})

export const getters = {}

export const actions = {
	success: async (context, message: string) => {
		SnackbarService.success(message)
	},
	error: async (context, message: string) => {
		SnackbarService.error(message)
	},
	warning: async (context, message: string) => {
		SnackbarService.warning(message)
	},
	info: async (context, message: string) => {
		SnackbarService.info(message)
	},
}
