
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'

	@Component({
		components: {},
	})
	export default class FYearPicker extends Vue {
		@Model('changed', { default: '' }) value: number
		@Prop({ type: Number, default: 50 }) height
		@Prop({ type: String, default: null }) min
		@Prop({ type: String, default: 'fYear' }) queryName

		mounted() {
			const q = this.$route.query[this.queryName]
			if (q && typeof q === 'string') {
				this.localValue = parseInt(q)
			}
		}

		today: number = parseInt(this.$dateFns.format(new Date(), 'yyyy-MM-dd'))
		localValue: number = this.today

		@Watch('value', { immediate: true })
		valueUpdated() {
			if (this.value) this.localValue = this.value
		}

		@Watch('localValue', { immediate: true })
		localValueUpdated() {
			this.$emit('changed', this.localValue)
			if (this.localValue !== null)
				this.$router
					.replace({
						query: {
							...this.$route.query,
							[this.queryName]: this.localValue || undefined,
						},
					})
					.catch(() => true)
		}

		/**
		 * Controls
		 */
		previous() {
			this.localValue--
		}

		next() {
			this.localValue++
		}
	}
