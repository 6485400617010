
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { DateString, TimeString } from '~/app/domain/Types'
	import DateDetail from '~/components/domain/date/DateDetail.vue'

	@Component({
		components: { DateDetail },
	})
	export default class DateRange extends Vue {
		@Prop({ required: true }) fromDate: DateString
		@Prop({ default: null }) fromTime: TimeString
		@Prop({ required: true }) toDate: DateString
		@Prop({ default: null }) toTime: TimeString

		get mobile() {
			return this.$vuetify.breakpoint.xsOnly
		}
	}
