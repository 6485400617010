import { render, staticRenderFns } from "./UiButton.vue?vue&type=template&id=46a5d1c8"
import script from "./UiButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./UiButton.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports