import { Branding, Tenant } from '~/app/domain/identifyAndAccess/Tenant'
import { RoleType } from '~/app/domain/identifyAndAccess/Role'
import { NodeId } from '~/app/domain/content/Common'

export const state = () => ({
	tenant: null,
	features: null,
	roleTypes: null,
	rootNodeId: null,
	branding: null,
	publicRequests: null,
	accessAreas: null,
	adminOverride: null,
})

export const getters = {
	subdomain: (state) => state.tenant?.subdomain,
	tenant: (state) => state.tenant,
	features: (state) => state.features,
	roleTypes: (state) => state.roleTypes,
	rootNodeId: (state) => state.rootNodeId,
	branding: (state) => state.branding,
	publicRequests: (state) => state.publicRequests,
	currentSubdomain: (state, getters, rootState) => rootState.route.params.subdomain,
	fromUser: (state, getters, rootState) =>
		getters.currentSubdomain && rootState.auth?.user?.domains?.[getters.currentSubdomain],
	isAdministrator: (state, getters, rootState) => {
		// check for override
		if (state.adminOverride !== null) {
			return state.adminOverride
		}

		// prioritize user data
		if (getters.fromUser) {
			return getters.fromUser.accessAreas?.admin
		}

		// fallback to tenant data
		return state.accessAreas?.admin
	},
}

export const mutations = {
	setTenant: (state, tenant: Tenant) => {
		state.tenant = tenant
	},
	setFeatures: (state, features: { [key: string]: any }) => {
		state.features = features
	},
	setRoleTypes: (state, roleTypes: RoleType[]) => {
		state.roleTypes = roleTypes
	},
	setRootNodeId: (state, rootNodeId: NodeId) => {
		state.rootNodeId = rootNodeId
	},
	setBranding: (state, branding: Branding) => {
		state.branding = branding
	},
	setPublicRequests: (state, publicRequests) => {
		state.publicRequests = publicRequests
	},
	setAccessAreas: (state, accessAreas) => {
		state.accessAreas = accessAreas
	},
	overrideAdministrator: (state, isAdministrator) => {
		state.adminOverride = isAdministrator
	},
}

export const actions = {
	// sets the data coming from the tenant query endpoint
	async setFromPlatformTenantResponse(
		context,
		{
			tenant,
			features,
			roleTypes,
			rootNodeId,
			branding,
			publicRequests,
			accessAreas,
		}: {
			tenant: Tenant
			features: any
			roleTypes: RoleType[]
			rootNodeId: NodeId
			branding: Branding
			publicRequests: any
			accessAreas: { [key: string]: boolean }
		},
	) {
		await context.commit('setTenant', tenant)
		await context.commit('setFeatures', features)
		await context.commit('setRoleTypes', roleTypes)
		await context.commit('setRootNodeId', rootNodeId)
		await context.commit('setBranding', branding)
		await context.commit('setPublicRequests', publicRequests)
		await context.commit('setAccessAreas', accessAreas)
	},

	// refetches the current tenant's data
	async refetch(context) {
		if (!context.getters['tenant']) {
			throw new Error('cannot refetch tenant data, no tenant is currently set')
		}
		const data = (
			await this.$axios.get('etg24:platform:query:platform.tenant', {
				headers: { Tenant: context.getters['tenant'].subdomain },
			})
		).data
		context.dispatch('setFromPlatformTenantResponse', data)
	},

	setBranding: async (context, branding: Branding) => {
		await context.commit('setBranding', branding)
	},

	overrideAdministrator: async (context, isAdministrator: boolean) => {
		await context.commit('overrideAdministrator', isAdministrator)
	},
}
