
	import { Component, Model, Vue } from 'nuxt-property-decorator'
	import CardLargeContainer from '~/components/containers/CardLargeContainer.vue'
	import { getEarliestRequestDate, OfferRequest, OfferRequestStatus, OfferStatus } from '~/app/domain/content/Process'
	import formatDate from '~/app/common/filters/Date'

	@Component({
		components: {
			CardLargeContainer,
		},
	})
	export default class OfferRequestCard extends Vue {
		@Model('change', { required: true }) value: OfferRequest

		get subtitle() {
			switch (this.value.requestStatus) {
				case OfferRequestStatus.draft:
					return {
						text: this.$t('domain.process.offerRequest.requestStatus.draft'),
						class: 'grey--text',
					}
				case OfferRequestStatus.active:
					// check if an offer has been accepted
					const acceptedOffer = this.value.offers.find((offer) => offer.status === OfferStatus.accepted)
					if (acceptedOffer) {
						console.log(acceptedOffer)
						return {
							text: this.$t('domain.process.offerRequest.requestStatus.acceptedOffer', {
								provider: acceptedOffer.provider.company,
							}),
						}
					}

					// now we'll collect elements and show them all
					let elements = []

					// add an element for the earliest request date
					const earliestPublicationDate = getEarliestRequestDate(this.value)
					if (earliestPublicationDate) {
						elements = [
							...elements,
							this.$t('domain.process.offerRequest.requested', {
								date: formatDate(this.$i18n, earliestPublicationDate, 'short', 'minute'),
							}),
						]
					}

					// add elements for status
					const offerStatusCount = {
						[OfferStatus.waiting]: 0,
						[OfferStatus.pending]: 0,
						[OfferStatus.rejected]: 0,
						[OfferStatus.available]: 0,
						[OfferStatus.accepted]: 0,
						[OfferStatus.declined]: 0,
						[OfferStatus.withdrawn]: 0,
						[OfferStatus.expired]: 0,
					}
					this.value.offers.forEach((offer) => offerStatusCount[offer.status]++)

					// only if the one of these status are present, we notify about counts
					;[OfferStatus.available, OfferStatus.pending, OfferStatus.waiting].forEach((offerStatus) => {
						if (offerStatusCount[offerStatus]) {
							elements = [
								...elements,
								this.$tc(
									`domain.process.offerRequest.requestStatus.offerStatusCount.${offerStatus}`,
									offerStatusCount[offerStatus],
								),
							]
						}
					})

					// if there's something worth mentioning
					if (elements.length) {
						return {
							elements: elements,
							class: 'primary--text',
						}
					}

					// nothing will happen by waiting
					return {
						text: this.$t('domain.process.offerRequest.requestStatus.mustAddProviders'),
						class: 'secondary--text',
					}
				case OfferRequestStatus.ended:
					return {
						text: this.$t('domain.process.offerRequest.requestStatus.ended'),
						class: 'primary--text',
					}
				case OfferRequestStatus.cancelled:
					return {
						text: this.$t('domain.process.offerRequest.requestStatus.canceled'),
						class: 'secondary--text',
					}
			}
		}
	}
