
	import Utils from '~/app/utils/globals/Utils'
	import Image from '~/app/utils/globals/Image'
	import Messages from '~/app/utils/globals/Messages'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Process, ProcessStatuses } from '~/app/domain/content/Process'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import FileLine from '~/components/domain/attachments/FileLine.vue'
	import UserLine from '~/components/domain/user/UserLine.vue'
	import ProcessActions from '~/components/domain/process/ProcessActions.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'
	import InfoBtn from '~/components/domain/help/InfoBtn.vue'
	import ContractBasedVisibility from '~/components/domain/contract/ContractBasedVisibility.vue'
	import Md from '~/components/utils/Md.vue'
	import { ContractId } from '~/app/domain/property/Contract'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import DetailLayout from '~/components/utils/DetailLayout.vue'

	@Component({
		components: {
			DetailLayout,
			UserNotifications,
			ContractBasedVisibility,
			InfoBtn,
			RichTextEditor,
			ProcessActions,
			UserLine,
			FileLine,
			CategoryContainer,
			Md,
		},
	})
	export default class ProcessEdit extends Vue {
		Utils = Utils
		@Model('change', { required: true }) process: Process
		@Prop({ default: false }) editable: boolean
		@Prop({ default: null }) baseUrl: string
		@Prop({ required: false }) nodeId: string
		@Prop({ default: false }) newProcess!: boolean
		@Prop({ type: Object, default: null }) parent: any

		Image = Image

		mounted() {
			const contract = this.$route.query.contract as ContractId | null
			this.selectedContracts = this.process.contractIds || (contract ? [contract] : [])
			this.notifyUsers = this.newProcess
		}

		selectedContracts: ContractId[] = []

		/**
		 * Submit
		 */
		submitting = false
		notifyUsers = false

		async submit() {
			this.submitting = true
			const eventId = this.$route.query.newContentEvent
			const conversationId = this.$route.query.newContentConversation
			const votingItemId = this.$route.query.newContentVotingItem
			try {
				// build the payload
				const processInfo = {
					title: this.process.title,
					description: this.process.description,
					extId: this.process.extId,
					roleTypes: this.process.roleTypes || [],
					contractIds: this.hasContractVisibility ? this.selectedContracts : undefined,
				}

				let pl
				if (this.newProcess) {
					pl = {
						important: this.notifyUsers,
						process: {
							...processInfo,
							parentNode: this.nodeId,
							status: ProcessStatuses.in_progress,
						},
						linkedEvent: eventId || undefined,
						linkedConversation: conversationId || undefined,
						linkedVotingItem: votingItemId || undefined,
					}
				} else {
					pl = {
						important: this.notifyUsers,
						process: {
							id: this.process.id,
							...processInfo,
							status: this.process.status,
						},
					}
				}

				// send it
				const resp = await this.$axios.$post(
					`etg24:internal:command:content.processes.${this.newProcess ? 'create' : 'update'}`,
					pl,
				)

				if (!this.newProcess) {
					this.$emit('updateProcess', resp.process)
					this.$emit('changePage', null)
					this.$nuxt.$emit('processUpdated', resp.process)
				} else {
					this.$nuxt.$emit('processAdded', resp.process)
					// redirect
					await this.$router.push({
						query: {
							...this.$route.query,
							newContent: undefined,
							newContentId: undefined,
							contentType: 'process',
							contentId: resp.process.id,
						},
					})
				}
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
				this.submitting = false
			}
		}

		get mobile() {
			return this.$vuetify.breakpoint.smAndDown
		}

		get canSubmit() {
			return this.process.title && this.process.description
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 1),
					value: r,
				}
			})
		}

		get statuses() {
			return Object.keys(ProcessStatuses).map((s) => {
				return {
					text: this.$t(`domain.process.status.${s}`),
					value: s,
				}
			})
		}

		get appPath() {
			return Utils.appPath()
		}

		get hasContractVisibility() {
			return this.newProcess
				? !!this.$store.getters['property/objects/byId'](this.nodeId)
				: this.process.hasContractVisibility
		}
	}
