import { DeepKeyOf, DeepPick, UnionToIntersection } from '@shared/types/helpers'
import { pick, get, set } from 'lodash'

type Declutter<T, K extends DeepKeyOf<T>> = UnionToIntersection<DeepPick<T, K>>

/**
 * `declutter` is a function that takes an object and a list of properties and returns a new object with only the specified properties.
 * @param subject The object to declutter
 * @param properties The properties to keep
 * @returns A new object with only the specified properties
 * @complexity O(n * d) where n is the number of properties and d is the maximum depth of the properties
 *
 * @example
 * ```ts
 * declutter({ a: { b: { c: 'c' }, d: 'd' }, b: 'b' }, ['a.b.c'])
 * // returns { a: { b: { c: 'c' } } }
 * ```
 *
 * @example
 * ```ts
 * declutter({ a: { b: { c: 'c' }, d: 'd' }, b: 'b' }, ['a.b.c', 'b'])
 * // returns { a: { b: { c: 'c' } }, b: 'b' }
 * ```
 */
export default function declutter<T extends object, K extends DeepKeyOf<T>[]>(subject: T, properties: K | Readonly<K>) {
	let result = {} as T
	properties.forEach((property) => {
		if (String(property).includes('.')) {
			set(result, property, get(subject, property))
		} else {
			result = { ...result, ...pick(subject, property) }
		}
	})
	return result as Declutter<T, K[number]>
}
