import { render, staticRenderFns } from "./SubdomainSelect.vue?vue&type=template&id=a4001384&scoped=true"
import script from "./SubdomainSelect.vue?vue&type=script&lang=ts"
export * from "./SubdomainSelect.vue?vue&type=script&lang=ts"
import style0 from "./SubdomainSelect.vue?vue&type=style&index=0&id=a4001384&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4001384",
  null
  
)

export default component.exports