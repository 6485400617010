import imageUrlBuilder from '@sanity/image-url';
export default (function (_ref, inject) {
  var $sanity = _ref.$sanity;
  var builder = imageUrlBuilder($sanity.config);
  function sanityImageUrl(source) {
    return builder.image(source).auto('format');
  }
  if (inject) {
    inject('sanityImageUrl', sanityImageUrl);
  }
  return sanityImageUrl;
});