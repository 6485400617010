
	import { Component, Vue, Model } from 'nuxt-property-decorator'
	import { MicroUser } from '~/app/domain/identifyAndAccess/User'
	import UserListItem from '~/components/domain/user/UserListItem.vue'

	@Component({
		components: { UserListItem },
	})
	export default class UserInfoMenu extends Vue {
		@Model('changed', { default: null }) user: MicroUser
	}
