var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"tw-border-b tw-border-b-background-stronger"},[_c('td',{staticClass:"tw-py-4 tw-text-left"},[_vm._v("\n\t\t"+_vm._s(_vm.section === 'notifications'
				? _vm.$t(`domain.generalNotifications.type.${_vm.setting.notificationType}.description`)
				: _vm.$t(`domain.conversationNotifications.type.${_vm.setting.notificationType}.description`))+"\n\t")]),_vm._v(" "),_vm._l((_vm.channels),function(channel){return _c('td',{key:channel,staticClass:"tw-text-center tw-p-2",class:{
			'tw-cursor-not-allowed': !_vm.setting[channel].isConfigurable,
		}},[(!_vm.setting[channel].isConfigurable)?_c('button',{staticClass:"tw-relative tw-w-full tw-p-2 tw-cursor-not-allowed"},[_c(_setup.UiTooltip,{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_setup.NotificationsSettingsTableIcon,{attrs:{"disabled":"","notification-type":_vm.setting.notificationType,"channel":channel,"value":_vm.setting[channel].value}})]},proxy:true}],null,true)},[_vm._v("\n\t\t\t\t"+_vm._s(`${
						_vm.setting[channel].value
							? _vm.$t(`domain.generalNotifications.settings.notificationChannels.${channel}.active`)
							: _vm.$t(`domain.generalNotifications.settings.notificationChannels.${channel}.disabled`)
					}.`)+"\n\t\t\t\t"),_c('br'),_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.generalNotifications.settings.notConfigurable'))+"\n\t\t\t")])],1):_c('button',{staticClass:"tw-p-2 tw-rounded-full tw-overflow-hidden tw-cursor-pointer hoverable",on:{"click":function($event){return _setup.updateNotificationsRow(_vm.setting.notificationType, channel, _vm.section)}}},[_c(_setup.UiTooltip,{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_setup.NotificationsSettingsTableIcon,{attrs:{"notification-type":_vm.setting.notificationType,"channel":channel,"value":_vm.setting[channel].value}})]},proxy:true}],null,true)},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.setting[channel].value
						? _vm.$t(`domain.generalNotifications.settings.notificationChannels.${channel}.active`)
						: _vm.$t(`domain.generalNotifications.settings.notificationChannels.${channel}.disabled`))+"\n\t\t\t")])],1)])}),_vm._v(" "),_c('td',{staticClass:"tw-w-6"},[_c(_setup.UiLoaderWithResult,{attrs:{"loading":_setup.loading,"error":_setup.error}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }