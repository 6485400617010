
	import { Vue, Component, Watch } from 'nuxt-property-decorator'
	import ProcessEdit from '~/components/domain/process/ProcessEdit.vue'
	import EventEdit from '~/components/domain/event/EventEdit.vue'
	import PostEdit from '~/components/domain/post/PostEdit.vue'
	import FaqEdit from '~/components/domain/faq/FaqEdit.vue'
	import ConversationEdit from '~/components/domain/conversation/ConversationEdit.vue'
	import VotingEdit from '~/components/domain/votings/VotingEdit.vue'
	import DeliveryBoEdit from '~/components/domain/deliveries/DeliveryBoEdit.vue'
	@Component({
		components: {
			DeliveryBoEdit,
			VotingEdit,
			ConversationEdit,
			FaqEdit,
			PostEdit,
			EventEdit,
			ProcessEdit,
		},
	})
	export default class NewModal extends Vue {
		get contentData() {
			// get all the params
			const newContentParams = Object.keys(this.$route.query || {}).filter((q) => q.startsWith('newContent'))

			// assign them values
			const newContentParamsObj = {}
			for (const param of newContentParams) newContentParamsObj[param] = this.$route.query[param]

			const type = this.$route.query.newContent
			if (type)
				return {
					type,
					id: this.$route.query.newContentId,
					params: newContentParamsObj,
				}
		}

		content: any = {}
		@Watch('contentData', { immediate: true }) async updateContentData(val, oldVal) {
			if (val?.type !== oldVal?.type || val?.id !== oldVal?.id) {
				this.content = {}
			}
		}

		closeContent() {
			const newContentParams = Object.keys(this.$route.query || {}).filter((q) => q.startsWith('newContent'))
			const query = { ...this.$route.query }
			for (const p of newContentParams || []) query[p] = undefined
			this.$router
				.push({
					query: {
						...this.$route.query,
						...query,
					},
				})
				.catch(() => true)
		}
	}
