
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { Branding } from '~/app/domain/identifyAndAccess/Tenant'
	import Image from '~/app/utils/globals/Image'

	@Component({})
	export default class LoginPageBackground extends Vue {
		@Prop({ default: null, type: Object }) branding: Branding
		@Prop({ default: false }) preview: boolean
		@Prop({ default: false }) disableBackground: boolean

		get styles() {
			const styles = {
				position: 'relative',
				height: '100%',
			}

			if (this.preview) {
				styles['padding'] = '80px 12px'
			}

			if (this.branding && !this.disableBackground) {
				if (this.branding.public.coverImage && this.branding.public.isCoverImageEnabled)
					styles['background-image'] = `url('${Image.resolveImg(
						this.branding.public.coverImage,
						2200,
					)}') !important`

				if (this.branding.public.coverImagePosition) {
					styles['background-position'] = this.branding.public.coverImagePosition
				}
			}
			if (this.branding && this.branding.public.isBackgroundColorEnabled) {
				styles['background-color'] = this.branding.primaryColor
			}
			styles['background-size'] = 'cover'

			return styles
		}
	}
