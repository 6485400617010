
	import Vue from 'vue'
	import { Component, Model, Watch, Prop } from 'nuxt-property-decorator'
	import YoutubeEmbed from '~/components/external/YoutubeEmbed.vue'

	@Component({
		components: { YoutubeEmbed },
	})
	export default class Youtube extends Vue {
		@Prop({ required: true }) videoId: string
		@Prop({ default: null }) caption: string
	}
