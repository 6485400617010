import { Validators } from '../validation/Validators'
import { CountryCodeString, ShortString } from '../../domain/Types'

export interface AddressDTO {
	street: ShortString
	houseNumber: ShortString
	zip: ShortString
	city: ShortString
	country: CountryCodeString
}

export interface Address extends AddressDTO {
	isValid?: boolean
}

export interface ExtendedAddress extends Address {
	note?: string
}

export const addressValidations = {
	street: {
		required: Validators.required,
	},
	houseNumber: {
		required: Validators.required,
	},
	zip: {
		required: Validators.required,
	},
	city: {
		required: Validators.required,
	},
	country: {
		required: Validators.required,
	},
	note: {},
}

export const addressSearchKeys: (keyof Address)[] = ['street', 'houseNumber', 'zip', 'city', 'country']

export const addressToDTO = (address: Address | null | undefined): AddressDTO | null => {
	if (!address || !Object.values(address || {}).filter((v) => !!v).length) return null
	return {
		street: address?.street,
		houseNumber: address?.houseNumber,
		zip: address?.zip,
		city: address?.city,
		country: address?.country,
	}
}
