export const ALPHA_LC = 'abcdefghijklmnopqrstuvwxyz'
export const ALPHA_UC = 'ABCDEFGHIJKLMNOPQRSTUVXYZ'
export const ALPHA = ALPHA_LC + ALPHA_UC
export const NUMS = '0123456789'
export const ALNUM = ALPHA + NUMS

/**
 * Generates a random string with the desired length from the provided chars.
 * @param length desired length of the string
 * @param chars characters to use for the random string
 * @returns random string
 * @complexity O(n) where n is the length of the string
 *
 * @example
 * generateRandomString(10) // 'a1B2c3D4e5'
 *
 * @example
 * generateRandomString(10, 'abc') // 'bcaacbacab'
 */
export default function generateRandomString(length: number, chars: string = ALNUM): string {
	let str = ''

	if (chars.length) {
		while (length--) {
			str += chars[Math.floor(Math.random() * chars.length)]
		}
	}

	return str
}
