var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"min-width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : 400},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menuOn }){return [_c('v-tooltip',{attrs:{"bottom":"","nudge-top":20,"color":"black","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},{ ...tooltipOn, ...menuOn }),[_c(_setup.UiBadge,{attrs:{"overlap":"","visible":!!_setup.unreadNotifications,"content":_setup.unreadNotifications}},[_c('v-icon',{attrs:{"color":_vm.iconColor}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_setup.Image.getIconForDomain('conversation'))+"\n\t\t\t\t\t\t")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('domain.conversationNotifications.title')))])])]}}]),model:{value:(_setup.conversationMenu),callback:function ($$v) {_setup.conversationMenu=$$v},expression:"conversationMenu"}},[_vm._v(" "),_c('v-card',{attrs:{"min-width":"300","max-width":"min(500px, calc(100vw - 24px))","max-height":"min(600px, calc(100vh - 24px))"}},[_c('div',{staticClass:"tw-p-2 tw-flex tw-justify-between tw-border-b tw-border-b-background-stronger"},[(_setup.isFo)?_c('div',{staticClass:"tw-grow tw-p-2 title primary--text"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.conversationNotifications.title'))+"\n\t\t\t")]):_c('NuxtLink',{staticClass:"tw-grow tw-p-2 tw-rounded tw-overflow-hidden hoverable",attrs:{"to":`${_setup.Utils.appPath()}/conversations?fWatcher=${_vm.$auth.user?.id}&refresh=${
					Number(_setup.route.query.refresh) + 1 || 0
				}`}},[_c('div',{staticClass:"title primary--text"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.conversationNotifications.title'))+"\n\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"tw-leading-none tw-text-sm tw-font-bold"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('domain.conversationNotifications.goToConversations'))+"\n\t\t\t\t")])]),_vm._v(" "),(_setup.unreadNotifications)?_c('strong',{staticClass:"tw-flex tw-justify-center tw-items-center tw-p-2 tw-text-sm secondary--text hoverable tw-rounded tw-overflow-hidden tw-cursor-pointer",on:{"click":_setup.markAllAsNoticed}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.conversationNotifications.newCount', {
						count: _setup.unreadNotifications,
					}))+"\n\t\t\t\t"),_c(_setup.UiIcon,{staticClass:"tw-ml-2",attrs:{"size":"lg","color":"accent","icon":"far fa-check-double"}})],1):_vm._e()],1),_vm._v(" "),(_setup.notifications.length > 0)?_c('div',[_c('v-list',[_vm._l((_setup.notifications),function(item){return [_c('li',{key:item.id,staticClass:"tw-flex tw-py-1.5 tw-px-1.5 tw-border-b tw-border-b-background-stronger",class:{
							'tw-items-center': item.isNoticed,
						}},[_c('div',{staticClass:"tw-w-1.5 tw-rounded tw-bg-accent tw-flex-shrink-0",class:{
								'tw-opacity-0': item.isRead,
								'tw-h-1.5': item.isNoticed,
							}}),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-items-center tw-flex-grow tw-py-1 tw-pl-1.5 tw-pr-2 tw-cursor-pointer hoverable tw-rounded tw-overflow-hidden",on:{"click":function($event){return _setup.selectNotification(item)}}},[_c('div',{staticClass:"tw-mr-2.5"},[(item.data.messages[0].author)?_c(_setup.UserAvatar,{attrs:{"image":item.data.messages[0].author.image}}):_c('v-icon',{attrs:{"size":"32","color":"grey","left":""}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_setup.Image.getIconForDomain('provider'))+"\n\t\t\t\t\t\t\t\t")])],1),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-flex-grow"},[_c(_setup.UiTypography,{staticClass:"tw-leading-tight tw-mb-0.5",attrs:{"variant":"h6","tag":"div"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.data.conversation.title)+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),_vm._l((item.data.messages),function(notification){return _c(_setup.UiTypography,{key:notification.id,staticClass:"tw-line-clamp-2 tw-text-text-soft",attrs:{"variant":"caption","tag":"span"}},[(notification.author.type === 'user' && notification.author.user)?_c('strong',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(notification.author.user?.firstName)+"\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(notification.author.user?.lastName)+":\n\t\t\t\t\t\t\t\t\t")]):(
											notification.author.type === 'provider' && notification.author.provider
										)?_c('strong',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(notification.author.provider?.salutation)+"\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(notification.author.provider?.firstName)+"\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(notification.author.provider?.lastName)+":\n\t\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_setup.html2text(notification.html))+"\n\t\t\t\t\t\t\t\t")])})],2),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-shrink-0 tw-text-xs grey--text tw-max-w-16 tw-ml-2"},[_c(_setup.DateFt,{attrs:{"date":item.data.messages[0].occurredOn,"precision":'minute'}})],1)]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-flex-shrink-0 tw-p-2 tw-w-8 tw-rounded tw-cursor-pointer tw-overflow-hidden hoverable",class:{
								'tw-invisible': item.isRead || item.isNoticed,
							},on:{"click":function($event){return _setup.markAsNoticed(item)}}},[_c(_setup.UiIcon,{staticClass:"before:tw-text-text-softer tw-text-text-softer",attrs:{"size":"lg","icon":"fas fa-check-circle"}})],1)])]})],2)],1):_c('div',{staticClass:"pa-12"},[_c(_setup.SearchSplash,{attrs:{"title":_vm.$t('domain.conversationNotifications.empty_list.title'),"body":_vm.$t('domain.conversationNotifications.empty_list.body'),"icon":_setup.Image.getIconForDomain('conversation')}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }