var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.UiModal,{attrs:{"classes":"tw-px-6 tw-py-4 tw-gap-3 tw-flex tw-flex-col tw-w-[90vw] tw-max-w-md","model-value":_setup.model},on:{"update:modelValue":_setup.cancel}},[_vm._t("title",function(){return [_c(_setup.UiTypography,{class:{
				'tw-text-text-base': _vm.variant === 'default',
				'tw-text-success': _vm.variant === 'success',
				'tw-text-warning': _vm.variant === 'warning',
				'tw-text-error': _vm.variant === 'error',
				'tw-text-info': _vm.variant === 'info',
			},attrs:{"variant":"h3"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.title || _vm.$t(_setup.VARIANT_TO_TITLE[_vm.variant]))+"\n\t\t")])]}),_vm._v(" "),_vm._t("text",function(){return [_c(_setup.UiTypography,{staticClass:"tw-mb-2",attrs:{"variant":"body"}},[(_vm.text)?[_vm._v("\n\t\t\t\t"+_vm._s(_vm.text)+"\n\t\t\t")]:_c(_setup.Md,{attrs:{"content":_vm.content}})],2)]}),_vm._v(" "),_c('div',{staticClass:"tw-w-full tw-flex tw-gap-2"},[_c(_setup.UiButton,{staticClass:"tw-w-full",attrs:{"loading":_setup.isLoading,"size":"md","color":_vm.variant === 'error' ? 'error' : 'success'},on:{"click":_setup.confirm}},[_vm._v("\n\t\t\t"+_vm._s(_vm.confirmText)+"\n\t\t")]),_vm._v(" "),_c(_setup.UiButton,{staticClass:"tw-w-full",attrs:{"size":"md","color":"default"},on:{"click":_setup.cancel}},[_vm._v("\n\t\t\t"+_vm._s(_vm.cancelText || _vm.$t('layout.cancel'))+"\n\t\t")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }