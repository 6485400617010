
	import Utils from '~/app/utils/globals/Utils'
	import { Vue, Component, Watch } from 'nuxt-property-decorator'
	import bytes from '~/app/common/filters/Bytes'
	import { resolveApiLink } from '~/app/utils/apiLinks'
	import { Document } from '~/app/domain/content/Document'
	import RemoteAssetRenderer from '~/components/asset-renderers/RemoteAssetRenderer.vue'
	import SfTextField from '~/components/domain/conversation/service_forms/blocks/SfTextField.vue'
	import SfTextArea from '~/components/domain/conversation/service_forms/blocks/SfTextArea.vue'
	import { Category, CategoryGroup } from '~/app/domain/content/Category'
	import sort from '~/app/common/filters/iterator/Sort'
	import SfSelect from '~/components/domain/conversation/service_forms/blocks/SfSelect.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import { findClosestAncestorOfType, getNodeBreadcrumbs, Node, NodeType } from '~/app/domain/content/Common'
	import { downloadUrlAsFile } from '~/app/utils/libs/FileUtil'

	@Component({
		components: {
			BasicDialog,
			ConfirmationDialog,
			SfSelect,
			SfTextArea,
			SfTextField,
			RemoteAssetRenderer,
		},
	})
	export default class DocumentPreview extends Vue {
		Utils = Utils
		/**
		 * Fetch the document
		 */
		documentData: {
			document: Document
			category: Category | null
			categoryGroup: CategoryGroup | null
			nodes: Node[]
		} | null = null

		downloading = false

		get documentId() {
			return this.$route.query.document
		}

		loading = false
		@Watch('documentId', { immediate: true }) async updateDocumentId() {
			if (this.documentId) {
				try {
					this.loading = true
					this.documentData = await this.$axios.$get('etg24:internal:query:content.document', {
						params: {
							document: this.documentId,
						},
					})
				} catch (e) {
					Utils.handleError(e)
				}
				this.loading = false
			} else {
				// reset the state
				this.documentData = null
				this.existingTagsLoaded = false
				this.existingTags = []
				this.categoriesPl = null
				this.categoriesLoaded = false
				this.editing = false
			}
		}

		get document() {
			return this.documentData?.document
		}

		get editable() {
			return !(
				Utils.appPath().startsWith(`/app/${this.$route.params.subdomain}/property/fo`) ||
				Utils.appPath().startsWith(`/app/${this.$route.params.subdomain}/provider/fo`)
			)
		}

		tab = 0
		tabs = ['preview', 'properties'] as const

		get section() {
			return this.tabs[this.tab]
		}

		bytes = bytes
		downloadEndpoint = 'etg24:internal:query:download.document'
		get downloadQueryParameters() {
			return { document: this.document.id }
		}

		get downloadLink() {
			return resolveApiLink(this.$store, this.downloadEndpoint, this.downloadQueryParameters)
		}

		async downloadDocument() {
			this.downloading = true
			try {
				await downloadUrlAsFile(this.downloadLink, `${this.document.name}.${this.document.extension}`)
			} catch (e) {
				console.error(e)
			} finally {
				this.downloading = false
			}
		}

		close() {
			this.$router.push({
				query: { ...this.$route.query, document: undefined },
			})
		}

		/**
		 * Properties
		 */
		get properties() {
			if (!this.documentData || !this.document) return []
			let properties = []

			// title
			properties = [...properties, { label: this.$t('layout.title'), value: this.document.title }]

			// description
			if (this.document.description) {
				// optional
				properties = [
					...properties,
					{
						label: this.$t('layout.description'),
						value: this.document.description,
					},
				]
			}

			// category
			if (this.documentData?.category) {
				// optional
				properties = [
					...properties,
					{
						label: this.$t('layout.category'),
						value: `${this.documentData?.categoryGroup.title} » ${this.documentData?.category.title}`,
					},
				]
			}

			// size, filename, date
			properties = [
				...properties,
				{
					label: this.$t('layout.size'),
					value: bytes(this.document.size),
				},
				{
					label: this.$t('layout.filename'),
					value: `${this.document.name}.${this.document.extension}`,
				},
				{
					label: this.$t('layout.date'),
					value: Utils.dateFormat(this.document.date),
				},
			]

			// financial year
			if (this.document.financialYear) {
				// optional
				properties = [
					...properties,
					{
						label: this.$tc('domain.financial_year.base', 1),
						value: this.document.financialYear.toString(),
					},
				]
			}

			// tag
			if (this.document.tag) {
				// optional
				properties = [
					...properties,
					{
						label: this.$tc('domain.document.tag.base', 1),
						value: `#${this.document.tag}`,
					},
				]
			}

			// batch
			const objectId = findClosestAncestorOfType(
				this.document.nodeId,
				this.documentData.nodes,
				NodeType['propertymanagement.object'],
			)
			if (this.document.batchId && this.document.batchType && this.$store.getters['routing/isBackoffice']) {
				properties = [
					...properties,
					{
						label: this.$tc('domain.document.batch.base', 1),
						value: this.$t(`domain.document.batch.types.${this.document.batchType}`).toString(),
						to: `${Utils.appPath()}/objects/${objectId}/document-batches/${this.document.batchId}`,
					},
				]
			}

			// location
			if (this.$store.getters['routing/isBackoffice'] && this.documentData) {
				properties = [
					...properties,
					{
						label: this.$t('layout.location'),
						value: getNodeBreadcrumbs(
							this.$store.getters['tenant/rootNodeId'],
							this.documentData.document.nodeId,
							this.documentData.nodes,
						)
							.map((n) => n.title)
							.join(' » '),
					},
				]
			}

			return properties
		}

		get categoryWithGroups() {
			if (!this.categoriesPl) return []
			const result = []

			const groups: any[] = []
			for (const category of this.categoriesPl.categories) {
				let group = groups.find((cg) => {
					return cg.id === category.groupId
				})
				if (!group) {
					const oGroup = this.categoriesPl.categoryGroups.find((cg) => {
						return cg.id === category.groupId
					})
					group = {
						...oGroup,
						categories: [],
					}
					groups.push(group)
				}
				group.categories.push(category)
			}

			// flatten it

			for (const group of this.categoriesPl.categoryGroups) {
				result.push({ header: group.title })
				result.push({ divider: true })
				const categories = this.categoriesPl.categories.filter((c) => {
					return c.groupId === group.id
				})
				for (const cat of sort(categories, 'title', 'asc')) result.push(cat)
			}

			return result
		}

		/**
		 * Edit
		 */
		editing = false
		editingDocument = null

		async startEditing() {
			this.editing = true
			this.editingDocument = {
				...this.document,
				category: this.documentData.category,
			}
			if (this.documentData.category) await this.loadCategories()
		}

		saving = false
		async saveEdits() {
			this.saving = true
			try {
				const d = this.editingDocument
				const response = await this.$axios.$post('etg24:internal:command:content.document.update', {
					document: {
						id: d.id,
						title: d.title,
						description: d.description,
						tag: d.tag,
						financialYear: d.financialYear,
						name: d.name,
					},
					category: d.category?.id,
				})
				this.$nuxt.$emit('documentUpdated', response)
				this.documentData.document = response
				this.editing = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.saving = false
		}

		get currentYear() {
			return parseInt(new Date().getFullYear() as any)
		}

		get financialYears() {
			const result = []
			for (let i = 1950; i < this.currentYear + 2; i++) {
				// we allow the next year as well
				result.push(i)
			}
			return result.reverse().map((y) => {
				return { text: y, value: y }
			})
		}

		/**
		 * Replace
		 */
		openReplaceDialog() {
			document.getElementById('file-upload').click()
		}

		key = 0
		replacing = false
		async replace(e) {
			this.replacing = true
			const file = e.target.files ? e.target.files[0] : e.dataTransfer.files ? e.dataTransfer.files[0] : null
			try {
				const fd = new FormData()
				fd.append('file', file)
				fd.set('document', this.document.id)
				await this.$axios.$post('etg24:internal:command:content.document.update', fd)
				this.$nuxt.$emit('documentUpdated', this.document)
				this.key++
			} catch (e) {
				Utils.handleError(e)
			}
			this.replacing = false
		}

		/**
		 * Delete
		 */
		selectedDocuments = null
		documentDeleteDialog = false

		deleting = false
		async deleteDocumentsCall() {
			this.deleting = true
			const docId = this.document.id
			try {
				await this.$axios.$post('etg24:internal:command:content.delete', {
					ids: [docId],
				})
				await this.$router.push({
					query: { ...this.$route.query, document: undefined },
				})
				this.documentDeleteDialog = false
				this.$nuxt.$emit('documentDeleted', [docId])
			} catch (e) {
				Utils.handleError(e)
			}
			this.deleting = false
		}

		/**
		 * Existing tags
		 */
		existingTagsLoaded = false
		existingTags = []
		async loadExistingTags() {
			if (!this.existingTagsLoaded) {
				try {
					this.existingTags = (
						await this.$axios.$get('etg24:internal:query:content.document.tags', {
							params: {
								document: this.documentId,
							},
						})
					).tags
					this.existingTagsLoaded = true
				} catch (e) {
					Utils.handleError(e)
				}
			}
		}

		/**
		 * Categories
		 */
		categoriesLoaded = false
		categoriesPl = null
		async loadCategories() {
			if (!this.categoriesLoaded) {
				try {
					this.categoriesPl = await this.$axios.$get('etg24:internal:query:content.document.categories', {
						params: {
							document: this.documentId,
						},
					})
					this.categoriesLoaded = true
				} catch (e) {
					Utils.handleError(e)
				}
			}
		}
	}
