import { scopedItemStoreActions, scopedItemStoreGetters, scopedItemStoreMutations } from '~/app/store/ScopedItemStore'
import { CompanyId } from '~/app/domain/provider/Provider'
import { pick } from 'lodash'
import { ProviderObject, ProviderObjectId } from '~/app/domain/provider/ProviderObject'

export const state = () => ({
	scopes: {},
	items: {},
	scopePropertyName: 'companyId',
})

export const getters = {
	...scopedItemStoreGetters({
		sortIteratee: 'title',
		lifetime: 1e3,
	}),
}

export const mutations = {
	...scopedItemStoreMutations({ sortIteratee: 'title' }),
}

export const actions = {
	...scopedItemStoreActions({}),

	async loadForCompany(context, companyId: CompanyId) {
		// don't reload if we're already reloading
		if (context.getters.isProcessing(companyId)) {
			return
		}
		// don't reload if we have clean data
		if (context.getters._isClean(companyId)) {
			return
		}

		// set scope processing
		context.commit('setScopeProcessing', companyId)

		const response = await this.$axios.get('etg24:internal:query:provider.objects', {
			params: { company: companyId },
		})
		await context.dispatch('setScopeWithItems', {
			key: companyId,
			items: response.data,
		})
	},

	async create(context, objects: { company: CompanyId; title: string; extId?: string }[]) {
		// reduce to allowed properties
		objects = objects.map((item) => pick(item, ['company', 'title', 'extId']))
		const newObjects = (await this.$axios.post('etg24:internal:command:provider.objects.create', { objects }))
			.data as ProviderObject[]
		newObjects.forEach((object) => context.commit('addOrUpdateItem', object))
		return newObjects
	},

	async update(context, objects: { id: ProviderObjectId; title?: string; extId?: string }[]) {
		// reduce to allowed properties
		objects = objects.map((item) => pick(item, ['id', 'title', 'extId']))

		const updatedObjects = (await this.$axios.post('etg24:internal:command:provider.objects.update', { objects }))
			.data as ProviderObject[]
		updatedObjects.forEach((object) => context.commit('addOrUpdateItem', object))
		return updatedObjects
	},

	async delete(context, objectIds: ProviderObjectId[]) {
		await this.$axios.post('etg24:internal:command:provider.objects.delete', {
			objects: objectIds,
		})
		context.commit('removeItems', objectIds)
	},
}
