
	import Messages from '~/app/utils/globals/Messages'
	import sleep from '~/app/utils/sleep'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Watch, Prop } from 'nuxt-property-decorator'
	import { Process, ProcessUpdate } from '~/app/domain/content/Process'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import Date from '~/components/utils/Date.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import RichTextEditor from '~/components/inputs/RichTextEditor.vue'

	@Component({
		components: {
			RichTextEditor,
			BasicDialog,
			Date,
			ListSelector,
			Dropzone,
		},
	})
	export default class ProcessUpdateEditDialog extends Vue {
		@Model('change', { required: true }) visible: boolean
		@Prop({ required: true }) update: ProcessUpdate
		@Prop({ required: true }) process: Process
		@Prop({ default: false }) newUpdate: boolean

		/**
		 * Visibility
		 */
		localVisible = false
		@Watch('visible')
		async updateVisible() {
			this.localVisible = this.visible
			if (this.localVisible) {
				await sleep(200)
				;(this.$refs as any).newMessage.focus()
			}
		}

		@Watch('localVisible')
		updateLocalVisible() {
			this.$emit('change', this.localVisible)
		}

		/**
		 * Update
		 */
		localUpdate: ProcessUpdate = {} as any
		description = ''
		@Watch('update', { immediate: true })
		updateProcess() {
			this.localUpdate = this.update
			this.description = this.localUpdate.description
		}

		@Watch('localUpdate')
		localUpdateProcess() {
			this.$emit('change', this.localUpdate)
		}

		/**
		 * Editing logic
		 */
		editing = false
		sending = false

		startEditing() {
			if (!this.editing) this.editing = true
		}

		stopEditing() {
			this.editing = false
		}

		async send() {
			this.sending = true
			try {
				const resp = await this.$axios.$post(
					`etg24:internal:command:content.processes.${
						this.newUpdate ? 'createprocessupdate' : 'updateprocessupdate'
					}`,
					{
						processUpdate: {
							id: !this.newUpdate ? this.localUpdate.id : undefined,
							description: this.description,
							internal: this.localUpdate.isInternal || false,
							newStatus: this.localUpdate.oldStatus,
							process: this.newUpdate ? this.process.id : undefined,
						},
					},
				)
				;(this.$refs as any).newMessage.reset()
				this.description = ''
				this.localVisible = false
				this.$emit('updateProcess', resp.process)
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
			this.sending = false
		}

		get visibilityStates() {
			return [
				{
					text: this.$t('layout.visible'),
					value: false,
				},
				{
					text: this.$t('layout.internal'),
					value: true,
				},
			]
		}

		get canSend() {
			return this.description && this.localUpdate.isInternal != null
		}

		/**
		 * Delete
		 */
		deleteing = false
		async deleteUpdate() {
			this.deleteing = true
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:content.processes.deleteprocessupdate',
					{
						processUpdate: this.update.id,
					},
				)
				this.localVisible = false
				this.$emit('updateProcess', response.process)
				await sleep(500)
			} catch (e) {
				Utils.handleError(e)
			}
			this.deleteing = false
		}
	}
