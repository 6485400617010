
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Faq } from '~/app/domain/content/Faq'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import Attachments from '~/components/domain/attachments/Attachments.vue'
	import Dropzone from '~/components/inputs/Dropzone.vue'
	import FaqActions from '~/components/domain/faq/FaqActions.vue'
	import DetailCard from '~/components/utils/DetailCard.vue'
	import FaqEdit from '~/components/domain/faq/FaqEdit.vue'

	@Component({
		components: {
			FaqEdit,
			DetailCard,
			FaqActions,
			Dropzone,
			Attachments,
			CategoryContainer,
		},

		// components: { },
	})
	export default class FaqDetails extends Vue {
		@Model('change', { required: true }) faq: Faq
		@Prop({ default: false }) editable: boolean
		@Prop({ default: false }) loading: boolean
		@Prop({ type: Array, default: () => [] }) nodes: Node[]
		Image = Image

		/**
		 * Global events
		 */
		updateFaq(faq) {
			this.$emit('updateFaq', faq)
			this.$nuxt.$emit('faqUpdated', faq)
		}

		deleteFaq() {
			this.$emit('deleted')
			this.$nuxt.$emit('faqDeleted', this.faq)
		}

		get hasAttachments() {
			return this.faq.attachments.documents.length > 0 || this.faq.attachments.images.length > 0
		}

		/**
		 * Pages
		 */

		get qPage() {
			return this.$route.query.contentPage
		}

		setPage(page) {
			this.$router.push({
				query: {
					...this.$route.query,
					contentPage: page,
				},
			})
		}

		get cPage() {
			const result: any = {}
			if (this.qPage && typeof this.qPage === 'string') {
				const sPage = this.qPage.split('_')
				result.page = sPage[0]
				result.id = sPage.length > 1 ? sPage[1] : null
			}
			return result
		}

		get parent() {
			return {
				text: this.faq?.question,
				to: { query: { ...this.$route.query, contentPage: undefined } },
			}
		}
	}
