import { Entity } from '../common/Interfaces'
import { Node, NodeId } from '../content/Common'

export function makeHashMap<T extends Entity>(items: T[], propertyName: keyof T = 'id'): { [key: string]: T } {
	const hashMap: { [key: string]: T } = {}
	items.forEach((item: T) => (hashMap[String(item[propertyName])] = item))

	return hashMap
}

export function upsertFromListByProperty<T extends Entity>(a: T[], b: T[], propertyName: keyof T = 'id') {
	return Object.values({
		...makeHashMap(a, propertyName),
		...makeHashMap(b, propertyName),
	})
}

export interface WithNode {
	node: Node
}
/**
 * @deprecated
 * use addNodes instead and make sure the nodes were added to the node store before
 */
export function addNode<T extends { nodeId: NodeId }>(nodes: Node[]): (item: T) => T & WithNode {
	const nodeMap = makeHashMap(nodes)

	return (item: T) => ({
		...(item as any),
		node: nodeMap[item.nodeId],
	})
}
export function addNodeFromStore<T extends { nodeId: NodeId }>(item: T, store: any): T & WithNode {
	return Object.assign({}, item, {
		node: store.getters['nodes/byId'](item.nodeId),
	})
}
