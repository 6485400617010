import { getPathFromRootNode, Node, NodeId, NodeType } from '~/app/domain/content/Common'
import { PropertyObjectId } from '~/app/domain/property/Object'

export const getters = {
	rootPath: (state, getters, rootState, rootGetters) => `/app/${rootGetters['tenant/tenant'].subdomain}/property/fo`,

	pathToLocationNode: (state, getters, rootState, rootGetters) => (locationNodeId: NodeId, nodes: Node[]) => {
		let path = getters.rootPath

		getPathFromRootNode(locationNodeId, nodes).forEach((node) => {
			switch (node.type) {
				case NodeType['propertymanagement']:
					break

				case NodeType['propertymanagement.object']:
					path += `/${node.id}`
					break
			}
		})

		return path
	},

	pathToObject: (state, getters, rootState, rootGetters) => (objectId: PropertyObjectId) =>
		`${getters.rootPath}/${objectId}`,

	pathToEvents: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/events`,

	pathToFaqs: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/faqs`,

	pathToPosts: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/posts`,

	pathToProcesses: (state, getters) => (nodeId: NodeId, nodes: Node[]) =>
		`${getters.pathToLocationNode(nodeId, nodes)}/processes`,

	pathToDeliveries: (state, getters) => (objectId: PropertyObjectId) =>
		// we show deliveries only at object
		`${getters.pathToObject(objectId)}/documents/deliveries`,

	pathToVotings: (state, getters) => (objectId: PropertyObjectId) =>
		// we show votings only at object
		`${getters.pathToObject(objectId)}/votings`,
}

export const mutations = {}

export const actions = {}
