
	import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'

	@Component({
		components: {},
	})
	export default class ListPaginator extends Vue {
		@Prop({ required: true }) items: any[]
		@Prop({ type: Number, default: 10 }) pageSize
		@Prop({ type: Boolean, default: true }) changeOnReload

		@Watch('items', { immediate: true }) updateItems() {
			if (this.changeOnReload) {
				this.itemLimit = this.pageSize
			}
		}

		get showingItems() {
			return (this.items || []).slice(0, this.itemLimit)
		}

		visibilityChanged(isVisible) {
			if (isVisible) {
				this.itemLimit += this.pageSize
			}
		}

		itemLimit: number = 0
	}
