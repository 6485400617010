
	import Vue from 'vue'
	import { Component, Prop } from 'nuxt-property-decorator'
	import { NewUpdate } from '~/app/domain/sanity/common'

	@Component({})
	export default class UpdateIcon extends Vue {
		@Prop({ required: true }) new: NewUpdate

		get icon() {
			switch (this.new.type) {
				case 'feature':
					return 'fal fa-star'
				case 'warning':
					return 'fal fa-exclamation-triangle'
				case 'update':
					return 'fal fa-bullhorn'
				case 'info':
				default:
					return 'fal fa-info-circle'
			}
		}
	}
