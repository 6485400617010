import { scopedItemStoreActions, scopedItemStoreGetters, scopedItemStoreMutations } from '~/app/store/ScopedItemStore'
import declutter from '~/app/utils/declutter'
import { PropertyObjectId } from '~/app/domain/property/Object'
import { Contract, ContractId, Signatory, SignatoryId } from '~/app/domain/property/Contract'
import { Address } from '~/app/common/model/Address'

export const state = () => ({
	scopes: {},
	items: {},
	scopePropertyName: 'objectId',
})

const addressProperties = ['street', 'houseNumber', 'zip', 'city', 'country'] as const

const transformSignatory = ({
	id,
	emailAddress,
	isAccessGranted,
	accessGrantedFrom,
	accessGrantedTo,
	isAgent,
	canVote,
	salutation,
	firstName,
	lastName,
	phoneLandline,
	phoneMobile,
	phoneWork,
	deliveryInstructions,
	address,
	isAddressee,
	contact,
}) => ({
	id,
	emailAddress,
	accessGranted: isAccessGranted,
	accessGrantedFrom,
	accessGrantedTo,
	agent: isAgent,
	salutation,
	firstName,
	lastName,
	phoneLandline,
	phoneMobile,
	phoneWork,
	deliveryInstructions,
	address: address ? declutter(address as Address, addressProperties) : null,
	isAddressee,
	canVote,
	contact,
})

export const getters = {
	...scopedItemStoreGetters({
		sortIteratee: 'validFrom',
		sortOrder: 'desc',
		lifetime: 30e3,
	}),

	byObject: (state, getters) => (objectId) => getters.byProperties({ objectId }),

	byObjectsUnits: (state, getters) => (unitId) => getters.byProperties({ objectId: null, unitId }),

	byUnit: (state, getters) => (unitId) => getters.byProperty('unitId', unitId),

	bySignatoryId: (state, getters) => (signatoryId) =>
		getters.all.find((contract) => contract.signatories.find((signatory) => signatory.id === signatoryId)),
}

export const mutations = {
	...scopedItemStoreMutations({ sortIteratee: 'validFrom' }),
}

export const actions = {
	...scopedItemStoreActions({}),

	async loadForObject(context, objectId: PropertyObjectId) {
		// don't reload if we're already reloading
		if (context.getters.isProcessing(objectId)) {
			return
		}
		// don't reload if we have clean data
		if (context.getters._isClean(objectId)) {
			return
		}

		// set scope processing
		context.commit('setScopeProcessing', objectId)

		const response = await this.$axios.get('etg24:internal:query:propertymanagement.contracts', {
			params: { object: objectId },
		})
		await context.dispatch('setScopeWithItems', {
			key: objectId,
			items: response.data,
		})
	},

	async fetch(context, contractId: ContractId) {
		const contract = (
			await this.$axios.get('etg24:internal:query:propertymanagement.contract', {
				params: { contract: contractId },
			})
		).data
		context.commit('setItems', [contract])
	},

	async create(context, payload) {
		// reduce to creatable properties
		const contractsToCreate = payload.contracts.map(
			({ contractType, signatories, unitId, validFrom, validTo, extId, isPostal, isVacancy, isSev }) => ({
				unit: unitId, // this is because the client works with unit, the server works with units,
				contractType,
				validFrom,
				validTo,
				extId,
				isPostal,
				vacancy: isVacancy,
				initialSignatories: (signatories || []).map(transformSignatory),
				isSev,
			}),
		)

		// create
		const createdContracts = (
			await this.$axios.post('etg24:internal:command:propertymanagement.contracts.create', {
				contracts: contractsToCreate,
				closeConflicting: payload.closeConflicting,
			})
		).data

		// add to store
		context.commit('setItems', createdContracts)
		return createdContracts as Contract[]
	},

	async update(context, contracts: Contract[]) {
		// reduce to updatable properties
		const contractsToUpdate = contracts.map(
			({ id, /* signatories, agents, */ validFrom, validTo, extId, isPostal, isVacancy, isSev }) => ({
				id,
				// signatories: contracts.map(c => declutter(c, SignatoryUpdateProperties),
				// agents: [],
				isPostal,
				vacancy: isVacancy,
				isSev,
				validFrom,
				validTo,
				extId,
			}),
		)
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.contracts.update', {
			contracts: contractsToUpdate,
		})
		context.commit('setItems', response.data)
		return response.data as Contract[]
	},

	async addSignatory(context, payload: { contractId: ContractId; signatory: Signatory }) {
		const response = await this.$axios.post('etg24:internal:command:propertymanagement.signatory.create', {
			contract: payload.contractId,
			signatory: payload.signatory,
		})
		context.commit('setItems', [response.data])
		return response.data as Contract
	},

	async updateSignatory(context, { signatory }: { signatory: Signatory }) {
		const contract = (
			await this.$axios.post('etg24:internal:command:propertymanagement.signatory.update', {
				contract: signatory.contractId,
				signatory: {
					id: signatory.id,
					isAccessGranted: signatory.isAccessGranted,
					accessGrantedTo: signatory.accessGrantedTo,
					isAgent: signatory.isAgent,
					canVote: signatory.canVote,
					isAddressee: signatory.isAddressee,
				},
			})
		).data
		context.commit('setItems', [contract])
	},

	async deleteSignatory(context, payload: { signatoryId: SignatoryId }) {
		const contract = (
			await this.$axios.post('etg24:internal:command:propertymanagement.signatory.delete', {
				signatory: payload.signatoryId,
			})
		).data
		context.commit('setItems', [contract])
	},

	async registerAsOccupier(context, payload: { contract: ContractId }) {
		const contract = await this.$axios.post(
			'etg24:internal:command:propertymanagement.contracts.registerasoccupier',
			{
				contract: payload.contract,
			},
		)
		if (contract.data) {
			context.commit('setItems', [contract.data])
			return
		}
		return contract.message
	},

	async delete(context, itemIds) {
		await this.$axios.post('etg24:internal:command:propertymanagement.contracts.delete', {
			contracts: itemIds,
		})
		context.commit('removeItems', itemIds)
	},
}
