var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"tabsRef",staticClass:"tw-relative tw-flex tw-w-full tw-max-w-full tw-overflow-x-auto",class:{
		'tw-flex-col tw-h-full': _vm.vertical,
		'tw-flex-row': !_vm.vertical,
		'tw-gap-2': !_vm.dense,
	}},[_c('div',{staticClass:"tw-bg-blue-500 tw-absolute tw-bg-accent tw-transition-all tw-duration-300",class:{
			'tw-bottom-0 tw-h-1': !_vm.vertical,
			'tw-left-0 tw-w-1': _vm.vertical,
		},style:(_setup.computedSliderStyle)}),_vm._v(" "),_vm._l((_vm.tabs),function(tab,index){return _c(_setup.MaybeLink,{key:index,staticClass:"tw-w-full tw-cursor-pointer tw-rounded tw-border-accent tw-fill-text-soft tw-px-3 tw-py-2 tw-font-bold tw-text-text-soft tw-transition-colors hover:tw-bg-background-strong",class:{
			'tw-min-w-max': !_vm.vertical,
			[_setup.activeClass]: _setup.model && _setup.model === tab.value,
			'tw-opacity-50 tw-pointer-events-none': tab.disabled,
		},attrs:{"to":tab.to,"active-class":tab.exact ? undefined : _setup.activeClass,"exact-active-class":tab.exact ? _setup.activeClass : undefined},on:{"click":() => _setup.onTabClick(tab)}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-gap-2",class:[_vm.vertical ? 'tw-justify-start' : 'tw-justify-center']},[(tab.icon)?_c(_setup.UiIcon,{staticClass:"tw-h-4 tw-w-4",class:{
					'!before:tw-fill-text-soft !tw-fill-text-soft': !_setup.model || _setup.model !== tab.value,
				},attrs:{"icon":tab.icon,"small":"","color":"default"}}):_vm._e(),_vm._v(" "),_c(_setup.UiTypography,{staticClass:"tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap"},[_vm._v("\n\t\t\t\t"+_vm._s(tab.label)+"\n\t\t\t")]),_vm._v(" "),(tab.badge)?_c(_setup.UiTypography,{staticClass:"tw-rounded-full",class:{
					'tw-ml-auto': _vm.vertical,
				}},[_vm._v("\n\t\t\t\t"+_vm._s(tab.badge)+"\n\t\t\t")]):_vm._e()],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }