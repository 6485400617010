//TODO: move domain types to shared and consume correct types

export const getFirstOrSelectContact = async ({ contacts, pickContacts }: any) => {
	if (contacts.length === 1) {
		return contacts[0]
	}

	return await pickContacts({
		elements: contacts,
		emitElements: true,
	})
}

export const getContactByUser = async ({ pickContacts, axios, user }: any) => {
	const { data } = await axios.get('etg24:internal:query:propertymanagement.contacts', {
		params: {
			user: user.id,
		},
	})

	if (!data.contacts?.length) {
		return
	}

	const selected = await getFirstOrSelectContact({
		contacts: data.contacts,
		pickContacts,
	})

	return selected || null
}

export async function goToContactByUser(this: any, user: any) {
	const contact = await getContactByUser({
		pickContacts: this.$pickContacts,
		axios: this.$axios,
		user,
	})
	if (contact === null) {
		return
	}
	if (contact) {
		this.$router.push({
			query: {
				...this.$route.query,
				contentType: 'contact',
				contentId: contact.contact.id,
			},
		})
		return
	}
	this.$store.dispatch(
		'snackbar/error',
		this.$i18n.t('domain.contact.errors.noContactForUserFound', {
			emailAddress: user.emailAddress,
		}),
	)
}
