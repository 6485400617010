
	import Image from '~/app/utils/globals/Image'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import { Company } from '~/app/domain/provider/Provider'

	@Component({})
	export default class CompanyCard extends Vue {
		Image = Image
		// ContactPerson & WithCategory
		@Model('change', { required: true }) company: Company
		@Prop({ default: null }) to: string
		@Prop({ default: false }) small: boolean
		@Prop({ default: false }) backOffice: boolean
	}
