/**
 * - Convert newlines to br tags
 * @param str - The string to convert
 * @returns - The converted string
 * @example
 * ```ts
 * nl2br('Hello\nWorld') // 'Hello<br>World'
 * ```
 *
 * @example
 * ```ts
 * nl2br('Hello\nWorld\n') // 'Hello<br>World<br>'
 * ```
 *
 */
export default function nl2br(str: string) {
	if (typeof str === 'undefined' || str === null) {
		return ''
	}
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
}
