
	import { Component, Vue, Prop } from 'nuxt-property-decorator'
	import { HelpArticle } from '~/app/domain/content/Help'
	import HelpArticleDetail from '~/components/domain/help/HelpArticleDetail.vue'

	@Component({
		components: {
			HelpArticleDetail,
		},
	})
	export default class HelpBtn extends Vue {
		@Prop({ required: true }) articleKey: string

		/**
		 * Help loading
		 */
		article: HelpArticle = null
		loading = false
		error = false
		helpDialog = false
		async openHelp() {
			this.article = null
			this.loading = true
			this.helpDialog = true
			try {
				const article = await this.$sanity.fetch(
					`*[_type == "helpArticle" && target == "property:bo" && key == $key && locale == $locale][0]
					{
						slug,
						title,
						brief,
						tags,
						icon,
						content,
						workflows,
					}`,
					{
						locale: this.$auth.$state.user.locale || this.$config.defaultLocale,
						key: this.articleKey,
					},
				)
				// @ts-ignore
				'content' in article
					? // @ts-ignore
					  (this.article = article)
					: (this.error = true)
			} catch (e) {
				console.error(e)
				this.error = true
			}
			this.loading = false
		}
	}
