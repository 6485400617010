
	import { Vue, Component, Prop } from 'nuxt-property-decorator'
	import NotesList from '~/components/domain/notes/NotesList.vue'
	import BottomSheet from '~/components/containers/BottomSheet.vue'

	export interface TitleConfiguration {
		text: string
		icon?: string
		to?: any
	}
	@Component({
		components: { BottomSheet, NotesList },
	})
	export default class DetailLayout extends Vue {
		@Prop({ default: null }) backPath: string
		@Prop({ type: Boolean, default: false }) fullWidth: boolean
		@Prop({ type: Boolean, default: true }) noFab: boolean
		@Prop({ type: Boolean, default: true }) standalone: boolean
		@Prop({ type: Boolean, default: false }) disableClose: boolean
		@Prop({ type: Boolean, default: false }) hasHeader: boolean
		@Prop({ type: Boolean, default: true }) inlineActions: boolean
		@Prop({ type: Boolean, default: true }) fixedHeight: boolean
		@Prop({ type: Number, default: 90 }) fixedHeightVh: boolean
		@Prop({ type: Object, default: null }) title: TitleConfiguration
		@Prop({ type: Object, default: null }) parent: TitleConfiguration

		get closeFn() {
			if (this.$listeners && this.$listeners.close != null) return true
			else return false
		}

		/**
		 * Fab visibility
		 */
		activeFab = null

		get fabsOpen() {
			return this.activeFab != null
		}

		switchFabs(fab, value) {
			if (value) this.activeFab = fab
		}

		get presentationMode() {
			return this.$store.getters['ui/isInPresentationMode']
		}

		get hasActions() {
			return !!this.$slots.actions
		}

		get showActionSidebar() {
			return this.hasActions && this.$vuetify.breakpoint.mdAndUp && !this.presentationMode
		}

		get fixedHeightStyle() {
			if (this.fixedHeight && this.$vuetify.breakpoint.mdAndUp) {
				return {
					height: `${this.fixedHeightVh}vh !important`,
					overflow: 'hidden',
				}
			} else {
				return {}
			}
		}
	}
