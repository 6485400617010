
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'

	@Component({
		components: {},
	})
	export default class BasicDialog extends Vue {
		@Model('change') visible: boolean
		@Prop({ default: '90%' }) maxWidth: number | string
		@Prop({ default: undefined }) width: number | string
		@Prop({ default: true }) closable: boolean
		@Prop({ default: null }) title: string
		@Prop({ default: false }) eager: boolean
		@Prop({ default: false }) persistent: boolean
		@Prop({ default: false }) noClickAnimation: boolean
		@Prop({ default: false }) fullscreen: boolean
		@Prop({ default: undefined }) overlayOpacity: boolean
		@Prop({ default: null }) color: boolean
		@Prop({ type: Boolean, default: false }) large: boolean
		@Prop({ type: Boolean, default: false }) small: boolean
		@Prop({ type: Boolean, default: true }) verticalLimit: boolean
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: Boolean, default: true }) header: boolean
		@Prop({ type: Boolean, default: false }) fillHeight: boolean

		localVisible: boolean = false
		@Watch('visible', { immediate: true })
		updateVisible() {
			this.localVisible = this.visible
		}

		@Watch('localVisible')
		updateLocalVisible() {
			this.$emit('change', this.localVisible)
			if (!this.localVisible) this.$emit('close')
		}

		get rWidth() {
			if (this.width !== undefined) return this.width
			return this.large ? '1000px' : this.small ? '350px' : '680px'
		}

		get dialogClasses() {
			const classes: string[] = []
			if (this.fillHeight) classes.push('full-height-dialog')
			return classes.join(' ')
		}
	}
