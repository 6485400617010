import { Ref, ref } from 'vue'

export type XAxis = 'left' | 'right'
export type YAxis = 'top' | 'bottom'
export type Position = XAxis | YAxis

const DEFAULT_POSITIONS: Record<Position, number> = {
	top: 4,
	bottom: 3,
	right: 2,
	left: 1,
}

const PADDING_PX = 10

const POSITION_TO_RESULT = {
	top: topResult,
	right: rightResult,
	bottom: bottomResult,
	left: leftResult,
}

/** This avoids that the element overflows the document */
function normalizePosition(result: { top: number; left: number }, relative: DOMRect, fixed: DOMRect) {
	const top =
		result.top < 0
			? PADDING_PX
			: result.top > window.innerHeight - fixed.height
			? window.innerHeight - fixed.height - PADDING_PX
			: result.top
	const left =
		result.left < 0
			? PADDING_PX
			: result.left > window.innerWidth - fixed.width
			? window.innerWidth - fixed.width - PADDING_PX
			: result.left

	return {
		top,
		left,
		forced: Math.abs(result.top - top) > relative.height / 2 || Math.abs(result.left - left) > relative.width / 2,
	}
}

function topResult(relative: DOMRect, fixed: DOMRect) {
	const result = {
		top: relative.top - fixed.height,
		left: relative.left + relative.width / 2 - fixed.width / 2,
	}
	return {
		result,
		normalized: normalizePosition(result, relative, fixed),
		score: relative.top - fixed.height,
	}
}

function leftResult(relative: DOMRect, fixed: DOMRect) {
	const result = {
		top: relative.top + relative.height / 2 - fixed.height / 2,
		left: relative.left - fixed.width,
	}
	return {
		result,
		normalized: normalizePosition(result, relative, fixed),
		score: relative.left - fixed.width,
	}
}

function bottomResult(relative: DOMRect, fixed: DOMRect) {
	const relativeBottom = window.innerHeight - relative.bottom
	const result = {
		top: relative.bottom,
		left: relative.left + relative.width / 2 - fixed.width / 2,
	}
	return {
		result,
		normalized: normalizePosition(result, relative, fixed),
		score: relativeBottom - fixed.height,
	}
}

function rightResult(relative: DOMRect, fixed: DOMRect) {
	const relativeRight = window.innerWidth - relative.right
	const result = {
		top: relative.top + relative.height / 2 - fixed.height / 2,
		left: relative.right,
	}
	return {
		result,
		normalized: normalizePosition(result, relative, fixed),
		score: relativeRight - fixed.width,
	}
}

export default function useElementAnchor(
	relativeElement: Ref<HTMLElement | undefined>,
	fixedElement: Ref<HTMLElement | undefined>,
	positions: Record<Position, number> = DEFAULT_POSITIONS,
) {
	const position = ref<Position | 'forced'>(positions[0] || 'top')
	const inset = ref({
		top: '0px',
		left: '0px',
	})

	const orderedPositionCalculations = Object.keys(positions).map((position) => ({
		position,
		positionScore: positions[position],
		operation: POSITION_TO_RESULT[position],
	}))

	function calculate() {
		if (!relativeElement.value || !fixedElement.value) {
			inset.value = {
				...inset.value,
				top: '0px',
				left: '0px',
			}
			return
		}
		const relativePosition = relativeElement.value.getBoundingClientRect()
		const fixedPosition = fixedElement.value.getBoundingClientRect()

		const orderedResults = orderedPositionCalculations
			.map((calculation) => {
				const calculationResult = calculation.operation(relativePosition, fixedPosition)

				const score = calculationResult.score * calculation.positionScore

				return {
					...calculation,
					...calculationResult,
					score,
				}
			})
			.sort((a, b) => b.score - a.score)

		const bestResult = orderedResults[0]

		if (bestResult && bestResult.normalized) {
			inset.value = {
				...inset.value,
				top: `${bestResult.normalized.top}px`,
				left: `${bestResult.normalized.left}px`,
			}
			position.value = bestResult.normalized.forced ? 'forced' : bestResult.position
			return
		}

		inset.value = {
			...inset.value,
			top: '0px',
			left: '0px',
		}
	}

	return { inset, position, calculate }
}
