import { UrlString } from '~/app/domain/Types'
import { resolveApiScheme } from '~/app/utils/api'
import { openWindowPOST } from '~/app/common/util/ApiUtil'
import { downloadBlobAsFile } from './libs/FileUtil'

export const resolveApiLink = (
	$store,
	commandOrQueryInSchemeFormat: UrlString,
	queryParameters: { [key: string]: string | number | boolean | null } = {},
) => {
	//

	// replace command scheme
	const commandOrQueryInPathFormat = resolveApiScheme(commandOrQueryInSchemeFormat)

	// add a session argument if we're on native so the newly opened browser tab's request is authenticated
	if ($store.$auth?.loggedIn) {
		if (
			$store.getters['platform/isNative'] ||
			$store.$config.appDomain !== ($store.getters['api/override'] || $store.$config.apiDomain)
		) {
			if ($store.$auth.getToken) {
				queryParameters = {
					...queryParameters,
					'x-auth-session': $store.$auth.getToken('local').split(' ')[1],
				}
			}
		}
	}

	// choose correct subdomain
	let subdomain = 'rest'
	if (commandOrQueryInSchemeFormat.startsWith('etg24:internal')) {
		subdomain = $store.getters['tenant/tenant']?.subdomain
	}

	// build url
	return `https://${subdomain}.${
		$store.getters['api/override'] || $store.$config.apiDomain
	}${commandOrQueryInPathFormat}?${new URLSearchParams(queryParameters as Record<string, string>).toString()}`
}

const MAX_BROWSER_URL_LENGTH = 2083

// TODO: remove this function when we move to a different way of downloading files
export const openEndpointInNewWindow = async (
	$store,
	commandOrQueryInSchemeFormat: string,
	data: object | null = {},
) => {
	if ($store.getters['platform/isNative']) {
		const apiLink = resolveApiLink($store, commandOrQueryInSchemeFormat)

		const config = {
			method: 'GET',
			url: apiLink,
			params: data,
			responseType: 'blob',
		} as const

		const fullUrl = $nuxt.$axios.getUri(config)

		if (fullUrl.length > MAX_BROWSER_URL_LENGTH) {
			// No translation added since this will be removed soon
			$store.dispatch('snackbar/error', 'Zu viele Dokumente. Bitte versuchen Sie es in der Web-App')
			return
		}
		const response = await $nuxt.$axios.request(config)

		// No translation added since this will be removed soon
		await downloadBlobAsFile(response.data, `${response.headers['content-disposition'].split('"')[1]}`)
		return
	}
	openWindowPOST(resolveApiLink($store, commandOrQueryInSchemeFormat), data, true)
}
