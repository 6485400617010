
	import Vue from 'vue'
	import { Component, Prop, Watch } from 'nuxt-property-decorator'
	import SanityBlockStyles from './SanityBlockStyles.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import UpdateIcon from '~/components/sanity/UpdateIcon.vue'
	import { DefaultBlockContentSerializers, NewUpdate } from '~/app/domain/sanity/common'
	import { getDateDifferenceString } from '~/app/utils/date'

	@Component({
		components: {
			BasicDialog,
			UpdateIcon,
			SanityBlockStyles,
		},
	})
	export default class UpdateDetails extends Vue {
		@Prop({ required: true }) news: NewUpdate[]
		@Prop({ default: false }) visible: boolean
		@Prop({ default: false }) hint: boolean
		@Prop({ default: 1, required: false }) initialPage: number

		page = 1
		mounted() {
			this.page = this.initialPage
		}

		@Watch('initialPage') onInitialPageChange() {
			this.page = this.initialPage
		}

		get currentNew() {
			return this.news[this.page - 1]
		}

		get serializers() {
			return DefaultBlockContentSerializers
		}

		getDateDifferenceString(date: string) {
			return getDateDifferenceString(date, this.$i18n.locale)
		}

		onPrev() {
			if (this.page > 1) this.page--
		}

		onNext() {
			if (this.page < this.news.length) this.page++
		}
	}
