
	import { Component, Prop, Vue, Watch, Model } from 'nuxt-property-decorator'
	import { Debounce } from 'lodash-decorators'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import SearchSplash from '~/components/utils/SearchSplash.vue'
	import HelpBtn from '~/components/domain/help/HelpBtn.vue'

	interface AppendText {
		tooltip?: string
		text: string
	}

	@Component({
		components: { HelpBtn, SearchSplash, SearchInput },
	})
	export default class SearchLayout extends Vue {
		@Model('changed', {}) searchText: string
		@Prop({ required: false }) searchFunction: Function
		@Prop({}) placeholder: string
		@Prop({ type: Array, default: null }) items: any[]
		@Prop({}) watchList: any
		@Prop({ default: true }) autofocus: boolean
		@Prop({ default: null }) resultCount: number
		@Prop({ default: null }) maxHeight: number
		@Prop({ default: false }) disableEmptyList: boolean
		@Prop({ default: null }) helpArticle: string
		@Prop({ default: false }) inlinePostSearch: boolean
		@Prop({ default: false }) hideSearch: boolean
		@Prop({ default: false, type: Boolean }) fullWidthSearch: boolean
		@Prop({ default: false }) appendText: AppendText
		@Prop({ type: Boolean, default: false }) loading: boolean
		@Prop({ type: String, default: null }) qaPrefix: string

		/**
		 * Search & filtering logic
		 */
		localSearchText: string = null
		searchLoading = false
		transitionKey: number = null

		@Watch('searchText', { immediate: true })
		updateSearchText() {
			if (this.searchText) this.localSearchText = this.searchText
		}

		@Watch('localSearchText')
		updateLocalSearchText() {
			this.searchLoading = true
			this.applyFilter()
			this.$emit('changed', this.localSearchText)
		}

		@Watch('watchList', { deep: true })
		updatedFilters() {
			this.searchLoading = true
			this.applyFilter()
		}

		localItems: any[] = null
		@Watch('items', { immediate: true }) updateItems() {
			this.localItems = this.items
		}

		@Debounce(250)
		async applyFilter() {
			if (!this.searchFunction) return
			if (this.items) {
				this.localItems = this.searchFunction(this.localSearchText, this.items)
			} else {
				await this.searchFunction(this.localSearchText)
			}

			this.transitionKey = +new Date()
			this.searchLoading = false
		}

		get filtered() {
			return !!this.localSearchText
		}

		get showEmptyList() {
			return (
				!this.disableEmptyList && !this.filtered && this.resultCount === 0 && this.$slots['empty-list'] !== null
			)
		}

		/**
		 * Helpers
		 */
		get mobileSize() {
			return this.$vuetify.breakpoint.xsOnly
		}

		focus() {
			;(this.$refs as any).searchLayoutInput.focus()
		}
	}
