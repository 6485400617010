// copies arbitrary text to the clipboard
export const copyToClipboard = async (text: string) => {
	try {
		await navigator.clipboard.writeText(text)
		console.log('Copied to clipboard:', text)
	} catch (err) {
		console.error('Failed to copy text: ', err)
	}
}

/**
 * Returns the url for the preview of the given url
 * @param url - url to get preview url for
 * @returns preview url
 */
export const getPreviewAwareUrl = (url: string) => {
	if (!$nuxt.$config.deployPreview) {
		return url
	}
	const urlObj = new URL(url)
	urlObj.hostname = `deploy-preview-${$nuxt.$config.deployPreview}.${urlObj.hostname}`
	return urlObj.toString()
}

interface UrlData {
	domain: string
	protocol?: string
	path?: string
	query?: Record<string, string>
}

/**
 * Creates a url from the given config
 * @param config
 * @param config.protocol - defaults to https
 * @param config.domain - domain name
 * @param config.path - path to append to domain
 * @param config.query - query params
 * @returns url
 */
export const createUrl = ({ domain, protocol = 'https', path = '', query = {} }: UrlData) => {
	const url = new URL(`${protocol}://${domain}`)
	url.pathname = path
	Object.entries(query).forEach(([key, value]) => url.searchParams.set(key, value))
	return url.toString()
}
