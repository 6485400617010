
	import { Vue, Component } from 'nuxt-property-decorator'
	import UserMenu from '~/components/navigation/elements/UserMenu.vue'
	import SubdomainSelect from '~/components/navigation/elements/SubdomainSelect.vue'
	import ConfigPanel from '~/components/utils/ConfigPanel.vue'
	import ConversationNotifications from '~/components/domain/conversation/ConversationNotifications.vue'
	import NotificationsMenu from '@modules/notifications/components/NotificationsMenu.vue'
	import InboundEmailNotifications from '~/components/domain/email/InboundEmailNotifications.vue'
	@Component({
		components: {
			NotificationsMenu,
			ConversationNotifications,
			InboundEmailNotifications,
			ConfigPanel,
			SubdomainSelect,
			UserMenu,
		},
	})
	export default class BoToolbar extends Vue {
		get tenant() {
			const t = this.$store.state.tenant
			return t && t.tenant
		}
	}
