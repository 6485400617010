/**
 * vue.js filter: returns the upper case version of the provided string or list of strings
 */
export const uppercase = _factory((s) => s.toLocaleUpperCase())

/**
 * vue.js filter: returns the lower case version of the provided string or list of strings
 */
export const lowercase = _factory((s) => s.toLocaleLowerCase())

/**
 * vue.js filter: returns the capitalized version of the provided string or list of strings
 */
export const capitalize = _factory((s) => s.substr(0, 1).toLocaleUpperCase() + s.substr(1))

/**
 * vue.js filter: returns the uncapitalized version of the provided string or list of strings
 */
export const uncapitalize = _factory((s) => s.substr(0, 1).toLocaleLowerCase() + s.substr(1))

// Types used by the factory
type SubjectFunction = (subject: string) => string
type CaseFunction = (subjects: string | string[]) => typeof subjects

// Builds a new function that will apply the provided SubjectFunction to its argument if it is a string,
// or, if it is an array, iterate over it and apply the SubjectFunction to each element.
function _factory(fs: SubjectFunction): CaseFunction {
	return function f(subject: string | string[]): typeof subject {
		if (subject) {
			if (subject instanceof Array) {
				return subject.map(fs)
			} else {
				return fs(subject)
			}
		}
		return subject
	}
}
