import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e79d4e0c = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _20fb1f88 = () => interopDefault(import('../pages/admin/emails.vue' /* webpackChunkName: "pages/admin/emails" */))
const _1babe674 = () => interopDefault(import('../pages/admin/functions.vue' /* webpackChunkName: "pages/admin/functions" */))
const _84e02bda = () => interopDefault(import('../pages/admin/notifications.vue' /* webpackChunkName: "pages/admin/notifications" */))
const _2d568034 = () => interopDefault(import('../pages/admin/pdfs.vue' /* webpackChunkName: "pages/admin/pdfs" */))
const _16ff65e2 = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _77f012ad = () => interopDefault(import('../pages/app/account/index.vue' /* webpackChunkName: "pages/app/account/index" */))
const _94c368ba = () => interopDefault(import('../pages/app/account/index/forgot-password.vue' /* webpackChunkName: "pages/app/account/index/forgot-password" */))
const _ddb672f2 = () => interopDefault(import('../pages/app/account/index/login.vue' /* webpackChunkName: "pages/app/account/index/login" */))
const _1c0074b6 = () => interopDefault(import('../pages/app/account/index/password-recovery.vue' /* webpackChunkName: "pages/app/account/index/password-recovery" */))
const _44ebf0f5 = () => interopDefault(import('../pages/app/account/index/register.vue' /* webpackChunkName: "pages/app/account/index/register" */))
const _54d0cdd9 = () => interopDefault(import('../pages/app/data-protection.vue' /* webpackChunkName: "pages/app/data-protection" */))
const _6e78d786 = () => interopDefault(import('../pages/app/imprint.vue' /* webpackChunkName: "pages/app/imprint" */))
const _79f792c3 = () => interopDefault(import('../pages/app/account/user/index.vue' /* webpackChunkName: "pages/app/account/user/index" */))
const _dca489f4 = () => interopDefault(import('../pages/app/account/user/index/index.vue' /* webpackChunkName: "pages/app/account/user/index/index" */))
const _38b5017e = () => interopDefault(import('../pages/app/account/user/index/change_password.vue' /* webpackChunkName: "pages/app/account/user/index/change_password" */))
const _6308af58 = () => interopDefault(import('../pages/app/account/user/index/contact.vue' /* webpackChunkName: "pages/app/account/user/index/contact" */))
const _fe765fc8 = () => interopDefault(import('../pages/app/account/user/index/notifications.vue' /* webpackChunkName: "pages/app/account/user/index/notifications" */))
const _5ecb7888 = () => interopDefault(import('../pages/app/portal/registration/index.vue' /* webpackChunkName: "pages/app/portal/registration/index" */))
const _b907e582 = () => interopDefault(import('../pages/app/portal/registration/index/index.vue' /* webpackChunkName: "pages/app/portal/registration/index/index" */))
const _ba179cfa = () => interopDefault(import('../pages/app/portal/registration/index/trial.vue' /* webpackChunkName: "pages/app/portal/registration/index/trial" */))
const _5ac204fd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6511e456 = () => interopDefault(import('../pages/app/services/offers/_offer_id/index.vue' /* webpackChunkName: "pages/app/services/offers/_offer_id/index" */))
const _b7b5e21c = () => interopDefault(import('../pages/app/services/orders/_order_id/index.vue' /* webpackChunkName: "pages/app/services/orders/_order_id/index" */))
const _9fb2ffa0 = () => interopDefault(import('../pages/permalink/register/_token/index.vue' /* webpackChunkName: "pages/permalink/register/_token/index" */))
const _dc12e2ca = () => interopDefault(import('../pages/app/_subdomain/index.vue' /* webpackChunkName: "pages/app/_subdomain/index" */))
const _95fbef8e = () => interopDefault(import('../pages/app/_subdomain/account/index.vue' /* webpackChunkName: "pages/app/_subdomain/account/index" */))
const _5f0ba92f = () => interopDefault(import('../pages/app/_subdomain/account/index/forgot-password.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/forgot-password" */))
const _42136313 = () => interopDefault(import('../pages/app/_subdomain/account/index/login.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/login" */))
const _e42e5fca = () => interopDefault(import('../pages/app/_subdomain/account/index/logout_redirect.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/logout_redirect" */))
const _b5bf2068 = () => interopDefault(import('../pages/app/_subdomain/account/index/no_access.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/no_access" */))
const _14e81f9e = () => interopDefault(import('../pages/app/_subdomain/account/index/password-recovery.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/password-recovery" */))
const _0c6778e9 = () => interopDefault(import('../pages/app/_subdomain/account/index/register.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/register" */))
const _aa5233ee = () => interopDefault(import('../pages/app/_subdomain/account/index/register_key.vue' /* webpackChunkName: "pages/app/_subdomain/account/index/register_key" */))
const _f13633dc = () => interopDefault(import('../pages/app/_subdomain/imprint.vue' /* webpackChunkName: "pages/app/_subdomain/imprint" */))
const _3c2df99f = () => interopDefault(import('../pages/app/_subdomain/property/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/index" */))
const _4df5aebb = () => interopDefault(import('../pages/app/_subdomain/provider/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/index" */))
const _3df84dd3 = () => interopDefault(import('../pages/app/_subdomain/unknown.vue' /* webpackChunkName: "pages/app/_subdomain/unknown" */))
const _43e797d3 = () => interopDefault(import('../pages/app/_subdomain/property/bo/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/index" */))
const _62ae2d4f = () => interopDefault(import('../pages/app/_subdomain/property/fo/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/index" */))
const _5b147d37 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/index" */))
const _79db12b3 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index" */))
const _aae4f214 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/index" */))
const _1615bc63 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/index" */))
const _0b3544e5 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/contact-persons/index" */))
const _6700a9a4 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/conversations/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/conversations/index" */))
const _4c776a8c = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/documents/index" */))
const _f802a806 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/documents/index/company/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/documents/index/company/index" */))
const _f22e8de6 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/documents/index/objects/_object_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/documents/index/objects/_object_id/index" */))
const _7488b89f = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/faqs/index" */))
const _2bab1b32 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/posts/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/posts/index" */))
const _2b6ace1e = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/processes/index" */))
const _0e0149a8 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/conversations/service-forms/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/conversations/service-forms/index" */))
const _284f7a06 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/settings/connect.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/settings/connect" */))
const _39fd084a = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/settings/users.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/settings/users" */))
const _04c5d9f4 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/conversations/service-forms/_service_form_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/conversations/service-forms/_service_form_id/index" */))
const _2d1dea67 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/contact-persons/_contact_person_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/contact-persons/_contact_person_id/index" */))
const _51ff3539 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/conversations/_conversation_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/conversations/_conversation_id/index" */))
const _dac2ac4e = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/posts/_post_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/posts/_post_id/index" */))
const _6d5df5a8 = () => interopDefault(import('../pages/app/_subdomain/provider/fo/index/_company_id/processes/_process_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/fo/index/_company_id/processes/_process_id/index" */))
const _3753793d = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/index" */))
const _d07404e0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/calendar/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/calendar/index" */))
const _233e3559 = () => interopDefault(import('../pages/app/_subdomain/property/bo/calls/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/calls/index" */))
const _12424055 = () => interopDefault(import('../pages/app/_subdomain/property/bo/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/contact-persons/index" */))
const _6f466736 = () => interopDefault(import('../pages/app/_subdomain/property/bo/contacts/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/contacts/index" */))
const _d2147dd8 = () => interopDefault(import('../pages/app/_subdomain/property/bo/conversations/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/conversations/index" */))
const _37e30aee = () => interopDefault(import('../pages/app/_subdomain/property/bo/employees/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/employees/index" */))
const _4e67dd53 = () => interopDefault(import('../pages/app/_subdomain/property/bo/help/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/help/index" */))
const _be804cd4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/help/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/help/index/index" */))
const _60780734 = () => interopDefault(import('../pages/app/_subdomain/property/bo/help/index/_article/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/help/index/_article/index" */))
const _c1d73406 = () => interopDefault(import('../pages/app/_subdomain/property/bo/inbound-emails/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/inbound-emails/index" */))
const _742f8fe9 = () => interopDefault(import('../pages/app/_subdomain/property/bo/notifications.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/notifications" */))
const _0f143a66 = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/index" */))
const _8a2140d0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/index" */))
const _e0562f3e = () => interopDefault(import('../pages/app/_subdomain/property/bo/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/processes/index" */))
const _1ecda435 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/index" */))
const _17ee0671 = () => interopDefault(import('../pages/app/_subdomain/property/bo/todos/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/todos/index" */))
const _3a18eac0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/upgrade/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/upgrade/index" */))
const _65ef7c28 = () => interopDefault(import('../pages/app/_subdomain/property/fo/deliveries/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/deliveries/index" */))
const _12b6e965 = () => interopDefault(import('../pages/app/_subdomain/property/fo/notifications.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/notifications" */))
const _5976b914 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/index" */))
const _8fb35990 = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/index" */))
const _4f1471c3 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/index" */))
const _67b1665c = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/index" */))
const _7140448e = () => interopDefault(import('../pages/app/_subdomain/provider/bo/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/contact-persons/index" */))
const _d9191508 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/contact-persons/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/contact-persons/index/index" */))
const _9d121a26 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/employees/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/employees/index" */))
const _2d16177c = () => interopDefault(import('../pages/app/_subdomain/provider/bo/general-content/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/general-content/index" */))
const _318bde02 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/general-content/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/general-content/index/index" */))
const _0e963cfe = () => interopDefault(import('../pages/app/_subdomain/provider/bo/general-content/index/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/general-content/index/contact-persons/index" */))
const _72c0a7b0 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/general-content/index/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/general-content/index/documents/index" */))
const _1b2ce583 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/general-content/index/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/general-content/index/faqs/index" */))
const _3e7f704b = () => interopDefault(import('../pages/app/_subdomain/provider/bo/general-content/index/contact-persons/_contact_person_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/general-content/index/contact-persons/_contact_person_id/index" */))
const _78028f51 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/index" */))
const _0de5acf2 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/index" */))
const _7068a712 = () => interopDefault(import('../pages/app/_subdomain/property/bo/contact-persons/new-multi/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/contact-persons/new-multi/index" */))
const _12dabe45 = () => interopDefault(import('../pages/app/_subdomain/property/bo/contacts/duplicates.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/contacts/duplicates" */))
const _59420d2d = () => interopDefault(import('../pages/app/_subdomain/property/bo/inbound-emails/archive.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/inbound-emails/archive" */))
const _8a653f1c = () => interopDefault(import('../pages/app/_subdomain/property/bo/inbound-emails/bin.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/inbound-emails/bin" */))
const _465865b1 = () => interopDefault(import('../pages/app/_subdomain/property/bo/inbound-emails/inbox.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/inbound-emails/inbox" */))
const _5ea8fc62 = () => interopDefault(import('../pages/app/_subdomain/property/bo/inbound-emails/inboxes/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/inbound-emails/inboxes/index" */))
const _9e9ebf92 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/edit/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/edit/index" */))
const _4d3efee9 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/new/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/new/index" */))
const _7749c6eb = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/branding/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/branding/index" */))
const _5cfb9da4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/branding/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/branding/index/index" */))
const _4a8723b2 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/branding/index/footer/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/branding/index/footer/index" */))
const _1301d627 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/branding/index/login-screen/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/branding/index/login-screen/index" */))
const _fd261ff4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/branding/index/pdf-backgrounds.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/branding/index/pdf-backgrounds" */))
const _3b064a50 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/connect/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/connect/index" */))
const _234d74ce = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/general/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/general/index" */))
const _a512ae5e = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/general/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/general/index/index" */))
const _0d4592a2 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/general/index/contact/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/general/index/contact/index" */))
const _4003966b = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/general/index/emails/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/general/index/emails/index" */))
const _46d603fd = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/service-forms/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/service-forms/index" */))
const _5c5cd8c0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/service-forms/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/service-forms/index/index" */))
const _b9acbe28 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/service-forms/index/customer-portal/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/service-forms/index/customer-portal/index" */))
const _3cfa072e = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/service-forms/index/public/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/service-forms/index/public/index" */))
const _11273160 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/service-forms/index/customer-portal/_form_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/service-forms/index/customer-portal/_form_id/index" */))
const _1a9f84b6 = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/fire-protection.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/fire-protection" */))
const _efeb6926 = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/garage-regulations.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/garage-regulations" */))
const _493989a8 = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/judgements-fcj.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/judgements-fcj" */))
const _765343d7 = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/laws-regulations.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/laws-regulations" */))
const _3a0ba5cf = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/printable-forms.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/printable-forms" */))
const _c9ec3366 = () => interopDefault(import('../pages/app/_subdomain/property/knowledge/de/residential-property-act.vue' /* webpackChunkName: "pages/app/_subdomain/property/knowledge/de/residential-property-act" */))
const _5b759b4f = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/branding/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/branding/index" */))
const _0723aadc = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/branding/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/branding/index/index" */))
const _ac52dc64 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/branding/index/footer/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/branding/index/footer/index" */))
const _31b53f43 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/branding/index/login-screen/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/branding/index/login-screen/index" */))
const _98d963ce = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/service-forms/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/service-forms/index" */))
const _44adc448 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/service-forms/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/service-forms/index/index" */))
const _b03fa3f0 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/service-forms/index/customer-portal/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/service-forms/index/customer-portal/index" */))
const _0831eadc = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/service-forms/index/public/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/service-forms/index/public/index" */))
const _0accd9b4 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/settings/service-forms/index/customer-portal/_form_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/settings/service-forms/index/customer-portal/_form_id/index" */))
const _3378cb6f = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/addons/ai.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/addons/ai" */))
const _36dd3e4c = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/addons/call-records.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/addons/call-records" */))
const _04b8c472 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/addons/inbound-emails.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/addons/inbound-emails" */))
const _24f267b6 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/addons/letter-printing.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/addons/letter-printing" */))
const _2f7e4708 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/addons/letter-writing.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/addons/letter-writing" */))
const _3145d0c0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/addons/time-tracking.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/addons/time-tracking" */))
const _0bb51e2a = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/aareon-connect.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/aareon-connect" */))
const _3ac8104a = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/bpt.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/bpt" */))
const _64b4058e = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/brunata-metrona.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/brunata-metrona" */))
const _6b06267e = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/caya.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/caya" */))
const _1c4c2ff3 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/csn.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/csn" */))
const _c66007ba = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/e-post.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/e-post" */))
const _4ea5ae10 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/hv-office.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/hv-office" */))
const _1deb93e7 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/hv2000.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/hv2000" */))
const _20bc6056 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/impower.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/impower" */))
const _56ab0d1c = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/incon.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/incon" */))
const _2d282d1c = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/ista.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/ista" */))
const _17c6c698 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/kalo.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/kalo" */))
const _46567cb4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/managbl-ai.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/managbl-ai" */))
const _641eb1e4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/minol.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/minol" */))
const _2a3bc4e2 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/pantaenius.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/pantaenius" */))
const _e9d6fc4e = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/techem.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/techem" */))
const _2b009603 = () => interopDefault(import('../pages/app/_subdomain/property/bo/addons-integrations/integrations/villa.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/addons-integrations/integrations/villa" */))
const _6af181a7 = () => interopDefault(import('../pages/app/_subdomain/property/bo/inbound-emails/inboxes/_inbox.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/inbound-emails/inboxes/_inbox" */))
const _62565a14 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/connect/_link_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/connect/_link_id/index" */))
const _2578c8f9 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/connect/_link_id/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/connect/_link_id/index/index" */))
const _9c177f68 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/connect/_link_id/index/settings.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/connect/_link_id/index/settings" */))
const _49eb4a70 = () => interopDefault(import('../pages/app/_subdomain/property/bo/settings/connect/_link_id/index/_object_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/settings/connect/_link_id/index/_object_id/index" */))
const _45462fa9 = () => interopDefault(import('../pages/app/_subdomain/property/bo/employees/_employee_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/employees/_employee_id/index" */))
const _583a04ab = () => interopDefault(import('../pages/app/_subdomain/property/bo/incall/_number.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/incall/_number" */))
const _9c1d485e = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index" */))
const _57f7fcd8 = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/index" */))
const _1cd9a67d = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/documents/index" */))
const _7ab0c190 = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/edit.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/edit" */))
const _2f129a8a = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/events/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/events/index" */))
const _4d5b0a0e = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/faqs/index" */))
const _8aa6563c = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/processes/index" */))
const _72ea5b72 = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/todos/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/todos/index" */))
const _ad7285c2 = () => interopDefault(import('../pages/app/_subdomain/property/bo/object-groups/_group_id/index/communication/posts/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/object-groups/_group_id/index/communication/posts/index" */))
const _3e0fbac9 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index" */))
const _1a6d468c = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/index" */))
const _3d52c3cb = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/accounting.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/accounting" */))
const _2b8c5f48 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/addresses.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/addresses" */))
const _308cd62a = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/batch-upload.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/batch-upload" */))
const _1100b7da = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/index" */))
const _032545e4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/contact-persons/index" */))
const _8db17322 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/document-batches/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/document-batches/index" */))
const _310b1575 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/documents/index" */))
const _02984e92 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/events/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/events/index" */))
const _514e2016 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/faqs/index" */))
const _d0724ce6 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/groups/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/groups/index" */))
const _6243784c = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/processes/index" */))
const _619575d9 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/settings.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/settings" */))
const _675a5632 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/settings/general.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/settings/general" */))
const _08755914 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/settings/votings.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/settings/votings" */))
const _254bf32c = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/todos/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/todos/index" */))
const _0d29238c = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/index" */))
const _5776e476 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/users/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/users/index" */))
const _3f394558 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/conversations/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/conversations/index" */))
const _1abae2e4 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/deliveries/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/deliveries/index" */))
const _595f67bf = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/issue-forms/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/issue-forms/index" */))
const _7693d617 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/posts/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/posts/index" */))
const _10e3e75b = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/service-forms/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/service-forms/index" */))
const _2b89b0f0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/votings/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/votings/index" */))
const _73498c08 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/new.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/new" */))
const _c10cbc3a = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/edit/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/edit/index" */))
const _6db917bd = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/new/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/new/index" */))
const _15bc32f3 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/users/advisor.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/users/advisor" */))
const _05737f26 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/users/owner.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/users/owner" */))
const _3c7ef929 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/users/supplier.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/users/supplier" */))
const _2ae93647 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/users/tenant.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/users/tenant" */))
const _5bb8edc1 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/communication/service-forms/_form_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/communication/service-forms/_form_id/index" */))
const _2be7d082 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/document-batches/_batch_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/document-batches/_batch_id/index" */))
const _8eee52b6 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/_group_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/_group_id/index" */))
const _71aebde0 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/index" */))
const _1c9b6407 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/_group_id/edit.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/unit-groups/_group_id/edit" */))
const _c66bca0e = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/documents/index" */))
const _ce19bf88 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/edit.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/edit" */))
const _307daf68 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/index" */))
const _05e5fa0b = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/accounting.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/accounting" */))
const _20eeec66 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/conversations/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/conversations/index" */))
const _5f3fc735 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/documents/index" */))
const _58627d00 = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/edit.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/edit" */))
const _1992165c = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/events/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/events/index" */))
const _05da14cc = () => interopDefault(import('../pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/bo/objects/_object_id/index/units/_unit_id/contracts/_contract_id/processes/index" */))
const _dc9a4866 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index" */))
const _690c9490 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/index" */))
const _33582312 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/contact-persons/index" */))
const _40af2f11 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/index" */))
const _06f3ae79 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/documents/index" */))
const _0e49f40e = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/events/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/events/index" */))
const _6ee2f492 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/faqs/index" */))
const _3b17b4d4 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/posts/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/posts/index" */))
const _b6724644 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/processes/index" */))
const _6d368520 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/units/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/units/index" */))
const _42de5726 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/votings/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/votings/index" */))
const _64510658 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/report-issue/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/report-issue/index" */))
const _ea65444e = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/service-forms/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/service-forms/index" */))
const _7be91ff9 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/service-forms/_service_form_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/conversations/service-forms/_service_form_id/index" */))
const _7e8b0a8c = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/units/_unit_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/units/_unit_id/index" */))
const _24784d16 = () => interopDefault(import('../pages/app/_subdomain/property/fo/objects/_object_id/index/units/_unit_id/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/property/fo/objects/_object_id/index/units/_unit_id/documents/index" */))
const _370c48a0 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index" */))
const _4c7ede33 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/index" */))
const _817e7296 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/contact-persons/index" */))
const _06bb4118 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/conversations/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/conversations/index" */))
const _6f06ef48 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/documents/index" */))
const _191f32b9 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/edit.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/edit" */))
const _eac78e62 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/faqs/index" */))
const _941caff4 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/groups/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/groups/index" */))
const _664fb6c1 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/processes/index" */))
const _768ce7a8 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/users/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/users/index" */))
const _7531cf2e = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/index" */))
const _7821f712 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/documents/index" */))
const _35cbd9c3 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/edit.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/edit" */))
const _15f52577 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/processes/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/companies/_company_id/index/objects/_object_id/processes/index" */))
const _5851573d = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/_group_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/_group_id/index" */))
const _045cbc00 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/_group_id/index/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/_group_id/index/index" */))
const _59cb6a82 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/_group_id/index/contact-persons/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/_group_id/index/contact-persons/index" */))
const _8ce56c2e = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/_group_id/index/documents/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/_group_id/index/documents/index" */))
const _4857e74c = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/_group_id/index/edit.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/_group_id/index/edit" */))
const _9c9c19bc = () => interopDefault(import('../pages/app/_subdomain/provider/bo/company-groups/_group_id/index/faqs/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/company-groups/_group_id/index/faqs/index" */))
const _9bf90c76 = () => interopDefault(import('../pages/app/_subdomain/provider/bo/employees/_employee_id/index.vue' /* webpackChunkName: "pages/app/_subdomain/provider/bo/employees/_employee_id/index" */))
const _5c4feaea = () => interopDefault(import('../pages/app/_subdomain/permalink/joinMeeting/_access_key.vue' /* webpackChunkName: "pages/app/_subdomain/permalink/joinMeeting/_access_key" */))
const _7889b83c = () => interopDefault(import('../pages/app/_subdomain/permalink/notifications/_notification_id.vue' /* webpackChunkName: "pages/app/_subdomain/permalink/notifications/_notification_id" */))
const _56a2b427 = () => interopDefault(import('../pages/app/_subdomain/public/forms/_form/index.vue' /* webpackChunkName: "pages/app/_subdomain/public/forms/_form/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _e79d4e0c,
    name: "admin",
    children: [{
      path: "emails",
      component: _20fb1f88,
      name: "admin-emails"
    }, {
      path: "functions",
      component: _1babe674,
      name: "admin-functions"
    }, {
      path: "notifications",
      component: _84e02bda,
      name: "admin-notifications"
    }, {
      path: "pdfs",
      component: _2d568034,
      name: "admin-pdfs"
    }]
  }, {
    path: "/app",
    component: _16ff65e2,
    name: "app"
  }, {
    path: "/app/account",
    component: _77f012ad,
    name: "app-account",
    children: [{
      path: "forgot-password",
      component: _94c368ba,
      name: "app-account-index-forgot-password"
    }, {
      path: "login",
      component: _ddb672f2,
      name: "app-account-index-login"
    }, {
      path: "password-recovery",
      component: _1c0074b6,
      name: "app-account-index-password-recovery"
    }, {
      path: "register",
      component: _44ebf0f5,
      name: "app-account-index-register"
    }]
  }, {
    path: "/app/data-protection",
    component: _54d0cdd9,
    name: "app-data-protection"
  }, {
    path: "/app/imprint",
    component: _6e78d786,
    name: "app-imprint"
  }, {
    path: "/app/account/user",
    component: _79f792c3,
    children: [{
      path: "",
      component: _dca489f4,
      name: "app-account-user-index"
    }, {
      path: "change_password",
      component: _38b5017e,
      name: "app-account-user-index-change_password"
    }, {
      path: "contact",
      component: _6308af58,
      name: "app-account-user-index-contact"
    }, {
      path: "notifications",
      component: _fe765fc8,
      name: "app-account-user-index-notifications"
    }]
  }, {
    path: "/app/portal/registration",
    component: _5ecb7888,
    children: [{
      path: "",
      component: _b907e582,
      name: "app-portal-registration-index"
    }, {
      path: "trial",
      component: _ba179cfa,
      name: "app-portal-registration-index-trial"
    }]
  }, {
    path: "/",
    component: _5ac204fd,
    name: "index"
  }, {
    path: "/app/services/offers/:offer_id",
    component: _6511e456,
    name: "app-services-offers-offer_id"
  }, {
    path: "/app/services/orders/:order_id",
    component: _b7b5e21c,
    name: "app-services-orders-order_id"
  }, {
    path: "/permalink/register/:token",
    component: _9fb2ffa0,
    name: "permalink-register-token"
  }, {
    path: "/app/:subdomain",
    component: _dc12e2ca,
    name: "app-subdomain"
  }, {
    path: "/app/:subdomain/account",
    component: _95fbef8e,
    name: "app-subdomain-account",
    children: [{
      path: "forgot-password",
      component: _5f0ba92f,
      name: "app-subdomain-account-index-forgot-password"
    }, {
      path: "login",
      component: _42136313,
      name: "app-subdomain-account-index-login"
    }, {
      path: "logout_redirect",
      component: _e42e5fca,
      name: "app-subdomain-account-index-logout_redirect"
    }, {
      path: "no_access",
      component: _b5bf2068,
      name: "app-subdomain-account-index-no_access"
    }, {
      path: "password-recovery",
      component: _14e81f9e,
      name: "app-subdomain-account-index-password-recovery"
    }, {
      path: "register",
      component: _0c6778e9,
      name: "app-subdomain-account-index-register"
    }, {
      path: "register_key",
      component: _aa5233ee,
      name: "app-subdomain-account-index-register_key"
    }]
  }, {
    path: "/app/:subdomain/imprint",
    component: _f13633dc,
    name: "app-subdomain-imprint"
  }, {
    path: "/app/:subdomain/property",
    component: _3c2df99f,
    name: "app-subdomain-property"
  }, {
    path: "/app/:subdomain/provider",
    component: _4df5aebb,
    name: "app-subdomain-provider"
  }, {
    path: "/app/:subdomain/unknown",
    component: _3df84dd3,
    name: "app-subdomain-unknown"
  }, {
    path: "/app/:subdomain/property/bo",
    component: _43e797d3,
    name: "app-subdomain-property-bo"
  }, {
    path: "/app/:subdomain/property/fo",
    component: _62ae2d4f,
    name: "app-subdomain-property-fo"
  }, {
    path: "/app/:subdomain/provider/bo",
    component: _5b147d37,
    name: "app-subdomain-provider-bo"
  }, {
    path: "/app/:subdomain/provider/fo",
    component: _79db12b3,
    children: [{
      path: "",
      component: _aae4f214,
      name: "app-subdomain-provider-fo-index"
    }, {
      path: ":company_id",
      component: _1615bc63,
      name: "app-subdomain-provider-fo-index-company_id"
    }, {
      path: ":company_id/contact-persons",
      component: _0b3544e5,
      name: "app-subdomain-provider-fo-index-company_id-contact-persons"
    }, {
      path: ":company_id/conversations",
      component: _6700a9a4,
      name: "app-subdomain-provider-fo-index-company_id-conversations"
    }, {
      path: ":company_id/documents",
      component: _4c776a8c,
      name: "app-subdomain-provider-fo-index-company_id-documents",
      children: [{
        path: "company",
        component: _f802a806,
        name: "app-subdomain-provider-fo-index-company_id-documents-index-company"
      }, {
        path: "objects/:object_id",
        component: _f22e8de6,
        name: "app-subdomain-provider-fo-index-company_id-documents-index-objects-object_id"
      }]
    }, {
      path: ":company_id/faqs",
      component: _7488b89f,
      name: "app-subdomain-provider-fo-index-company_id-faqs"
    }, {
      path: ":company_id/posts",
      component: _2bab1b32,
      name: "app-subdomain-provider-fo-index-company_id-posts"
    }, {
      path: ":company_id/processes",
      component: _2b6ace1e,
      name: "app-subdomain-provider-fo-index-company_id-processes"
    }, {
      path: ":company_id/conversations/service-forms",
      component: _0e0149a8,
      name: "app-subdomain-provider-fo-index-company_id-conversations-service-forms"
    }, {
      path: ":company_id/settings/connect",
      component: _284f7a06,
      name: "app-subdomain-provider-fo-index-company_id-settings-connect"
    }, {
      path: ":company_id/settings/users",
      component: _39fd084a,
      name: "app-subdomain-provider-fo-index-company_id-settings-users"
    }, {
      path: ":company_id/conversations/service-forms/:service_form_id",
      component: _04c5d9f4,
      name: "app-subdomain-provider-fo-index-company_id-conversations-service-forms-service_form_id"
    }, {
      path: ":company_id/contact-persons/:contact_person_id",
      component: _2d1dea67,
      name: "app-subdomain-provider-fo-index-company_id-contact-persons-contact_person_id"
    }, {
      path: ":company_id/conversations/:conversation_id",
      component: _51ff3539,
      name: "app-subdomain-provider-fo-index-company_id-conversations-conversation_id"
    }, {
      path: ":company_id/posts/:post_id",
      component: _dac2ac4e,
      name: "app-subdomain-provider-fo-index-company_id-posts-post_id"
    }, {
      path: ":company_id/processes/:process_id",
      component: _6d5df5a8,
      name: "app-subdomain-provider-fo-index-company_id-processes-process_id"
    }]
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations",
    component: _3753793d,
    name: "app-subdomain-property-bo-addons-integrations"
  }, {
    path: "/app/:subdomain/property/bo/calendar",
    component: _d07404e0,
    name: "app-subdomain-property-bo-calendar"
  }, {
    path: "/app/:subdomain/property/bo/calls",
    component: _233e3559,
    name: "app-subdomain-property-bo-calls"
  }, {
    path: "/app/:subdomain/property/bo/contact-persons",
    component: _12424055,
    name: "app-subdomain-property-bo-contact-persons"
  }, {
    path: "/app/:subdomain/property/bo/contacts",
    component: _6f466736,
    name: "app-subdomain-property-bo-contacts"
  }, {
    path: "/app/:subdomain/property/bo/conversations",
    component: _d2147dd8,
    name: "app-subdomain-property-bo-conversations"
  }, {
    path: "/app/:subdomain/property/bo/employees",
    component: _37e30aee,
    name: "app-subdomain-property-bo-employees"
  }, {
    path: "/app/:subdomain/property/bo/help",
    component: _4e67dd53,
    children: [{
      path: "",
      component: _be804cd4,
      name: "app-subdomain-property-bo-help-index"
    }, {
      path: ":article",
      component: _60780734,
      name: "app-subdomain-property-bo-help-index-article"
    }]
  }, {
    path: "/app/:subdomain/property/bo/inbound-emails",
    component: _c1d73406,
    name: "app-subdomain-property-bo-inbound-emails"
  }, {
    path: "/app/:subdomain/property/bo/notifications",
    component: _742f8fe9,
    name: "app-subdomain-property-bo-notifications"
  }, {
    path: "/app/:subdomain/property/bo/object-groups",
    component: _0f143a66,
    name: "app-subdomain-property-bo-object-groups"
  }, {
    path: "/app/:subdomain/property/bo/objects",
    component: _8a2140d0,
    name: "app-subdomain-property-bo-objects"
  }, {
    path: "/app/:subdomain/property/bo/processes",
    component: _e0562f3e,
    name: "app-subdomain-property-bo-processes"
  }, {
    path: "/app/:subdomain/property/bo/settings",
    component: _1ecda435,
    name: "app-subdomain-property-bo-settings"
  }, {
    path: "/app/:subdomain/property/bo/todos",
    component: _17ee0671,
    name: "app-subdomain-property-bo-todos"
  }, {
    path: "/app/:subdomain/property/bo/upgrade",
    component: _3a18eac0,
    name: "app-subdomain-property-bo-upgrade"
  }, {
    path: "/app/:subdomain/property/fo/deliveries",
    component: _65ef7c28,
    name: "app-subdomain-property-fo-deliveries"
  }, {
    path: "/app/:subdomain/property/fo/notifications",
    component: _12b6e965,
    name: "app-subdomain-property-fo-notifications"
  }, {
    path: "/app/:subdomain/property/fo/objects",
    component: _5976b914,
    name: "app-subdomain-property-fo-objects"
  }, {
    path: "/app/:subdomain/property/knowledge/de",
    component: _8fb35990,
    name: "app-subdomain-property-knowledge-de"
  }, {
    path: "/app/:subdomain/provider/bo/companies",
    component: _4f1471c3,
    name: "app-subdomain-provider-bo-companies"
  }, {
    path: "/app/:subdomain/provider/bo/company-groups",
    component: _67b1665c,
    name: "app-subdomain-provider-bo-company-groups"
  }, {
    path: "/app/:subdomain/provider/bo/contact-persons",
    component: _7140448e,
    children: [{
      path: "",
      component: _d9191508,
      name: "app-subdomain-provider-bo-contact-persons-index"
    }]
  }, {
    path: "/app/:subdomain/provider/bo/employees",
    component: _9d121a26,
    name: "app-subdomain-provider-bo-employees"
  }, {
    path: "/app/:subdomain/provider/bo/general-content",
    component: _2d16177c,
    children: [{
      path: "",
      component: _318bde02,
      name: "app-subdomain-provider-bo-general-content-index"
    }, {
      path: "contact-persons",
      component: _0e963cfe,
      name: "app-subdomain-provider-bo-general-content-index-contact-persons"
    }, {
      path: "documents",
      component: _72c0a7b0,
      name: "app-subdomain-provider-bo-general-content-index-documents"
    }, {
      path: "faqs",
      component: _1b2ce583,
      name: "app-subdomain-provider-bo-general-content-index-faqs"
    }, {
      path: "contact-persons/:contact_person_id",
      component: _3e7f704b,
      name: "app-subdomain-provider-bo-general-content-index-contact-persons-contact_person_id"
    }]
  }, {
    path: "/app/:subdomain/provider/bo/settings",
    component: _78028f51,
    name: "app-subdomain-provider-bo-settings"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations",
    component: _0de5acf2,
    name: "app-subdomain-property-bo-addons-integrations-integrations"
  }, {
    path: "/app/:subdomain/property/bo/contact-persons/new-multi",
    component: _7068a712,
    name: "app-subdomain-property-bo-contact-persons-new-multi"
  }, {
    path: "/app/:subdomain/property/bo/contacts/duplicates",
    component: _12dabe45,
    name: "app-subdomain-property-bo-contacts-duplicates"
  }, {
    path: "/app/:subdomain/property/bo/inbound-emails/archive",
    component: _59420d2d,
    name: "app-subdomain-property-bo-inbound-emails-archive"
  }, {
    path: "/app/:subdomain/property/bo/inbound-emails/bin",
    component: _8a653f1c,
    name: "app-subdomain-property-bo-inbound-emails-bin"
  }, {
    path: "/app/:subdomain/property/bo/inbound-emails/inbox",
    component: _465865b1,
    name: "app-subdomain-property-bo-inbound-emails-inbox"
  }, {
    path: "/app/:subdomain/property/bo/inbound-emails/inboxes",
    component: _5ea8fc62,
    name: "app-subdomain-property-bo-inbound-emails-inboxes"
  }, {
    path: "/app/:subdomain/property/bo/objects/edit",
    component: _9e9ebf92,
    name: "app-subdomain-property-bo-objects-edit"
  }, {
    path: "/app/:subdomain/property/bo/objects/new",
    component: _4d3efee9,
    name: "app-subdomain-property-bo-objects-new"
  }, {
    path: "/app/:subdomain/property/bo/settings/branding",
    component: _7749c6eb,
    children: [{
      path: "",
      component: _5cfb9da4,
      name: "app-subdomain-property-bo-settings-branding-index"
    }, {
      path: "footer",
      component: _4a8723b2,
      name: "app-subdomain-property-bo-settings-branding-index-footer"
    }, {
      path: "login-screen",
      component: _1301d627,
      name: "app-subdomain-property-bo-settings-branding-index-login-screen"
    }, {
      path: "pdf-backgrounds",
      component: _fd261ff4,
      name: "app-subdomain-property-bo-settings-branding-index-pdf-backgrounds"
    }]
  }, {
    path: "/app/:subdomain/property/bo/settings/connect",
    component: _3b064a50,
    name: "app-subdomain-property-bo-settings-connect"
  }, {
    path: "/app/:subdomain/property/bo/settings/general",
    component: _234d74ce,
    children: [{
      path: "",
      component: _a512ae5e,
      name: "app-subdomain-property-bo-settings-general-index"
    }, {
      path: "contact",
      component: _0d4592a2,
      name: "app-subdomain-property-bo-settings-general-index-contact"
    }, {
      path: "emails",
      component: _4003966b,
      name: "app-subdomain-property-bo-settings-general-index-emails"
    }]
  }, {
    path: "/app/:subdomain/property/bo/settings/service-forms",
    component: _46d603fd,
    children: [{
      path: "",
      component: _5c5cd8c0,
      name: "app-subdomain-property-bo-settings-service-forms-index"
    }, {
      path: "customer-portal",
      component: _b9acbe28,
      name: "app-subdomain-property-bo-settings-service-forms-index-customer-portal"
    }, {
      path: "public",
      component: _3cfa072e,
      name: "app-subdomain-property-bo-settings-service-forms-index-public"
    }, {
      path: "customer-portal/:form_id",
      component: _11273160,
      name: "app-subdomain-property-bo-settings-service-forms-index-customer-portal-form_id"
    }]
  }, {
    path: "/app/:subdomain/property/knowledge/de/fire-protection",
    component: _1a9f84b6,
    name: "app-subdomain-property-knowledge-de-fire-protection"
  }, {
    path: "/app/:subdomain/property/knowledge/de/garage-regulations",
    component: _efeb6926,
    name: "app-subdomain-property-knowledge-de-garage-regulations"
  }, {
    path: "/app/:subdomain/property/knowledge/de/judgements-fcj",
    component: _493989a8,
    name: "app-subdomain-property-knowledge-de-judgements-fcj"
  }, {
    path: "/app/:subdomain/property/knowledge/de/laws-regulations",
    component: _765343d7,
    name: "app-subdomain-property-knowledge-de-laws-regulations"
  }, {
    path: "/app/:subdomain/property/knowledge/de/printable-forms",
    component: _3a0ba5cf,
    name: "app-subdomain-property-knowledge-de-printable-forms"
  }, {
    path: "/app/:subdomain/property/knowledge/de/residential-property-act",
    component: _c9ec3366,
    name: "app-subdomain-property-knowledge-de-residential-property-act"
  }, {
    path: "/app/:subdomain/provider/bo/settings/branding",
    component: _5b759b4f,
    children: [{
      path: "",
      component: _0723aadc,
      name: "app-subdomain-provider-bo-settings-branding-index"
    }, {
      path: "footer",
      component: _ac52dc64,
      name: "app-subdomain-provider-bo-settings-branding-index-footer"
    }, {
      path: "login-screen",
      component: _31b53f43,
      name: "app-subdomain-provider-bo-settings-branding-index-login-screen"
    }]
  }, {
    path: "/app/:subdomain/provider/bo/settings/service-forms",
    component: _98d963ce,
    children: [{
      path: "",
      component: _44adc448,
      name: "app-subdomain-provider-bo-settings-service-forms-index"
    }, {
      path: "customer-portal",
      component: _b03fa3f0,
      name: "app-subdomain-provider-bo-settings-service-forms-index-customer-portal"
    }, {
      path: "public",
      component: _0831eadc,
      name: "app-subdomain-provider-bo-settings-service-forms-index-public"
    }, {
      path: "customer-portal/:form_id",
      component: _0accd9b4,
      name: "app-subdomain-provider-bo-settings-service-forms-index-customer-portal-form_id"
    }]
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/addons/ai",
    component: _3378cb6f,
    name: "app-subdomain-property-bo-addons-integrations-addons-ai"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/addons/call-records",
    component: _36dd3e4c,
    name: "app-subdomain-property-bo-addons-integrations-addons-call-records"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/addons/inbound-emails",
    component: _04b8c472,
    name: "app-subdomain-property-bo-addons-integrations-addons-inbound-emails"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/addons/letter-printing",
    component: _24f267b6,
    name: "app-subdomain-property-bo-addons-integrations-addons-letter-printing"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/addons/letter-writing",
    component: _2f7e4708,
    name: "app-subdomain-property-bo-addons-integrations-addons-letter-writing"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/addons/time-tracking",
    component: _3145d0c0,
    name: "app-subdomain-property-bo-addons-integrations-addons-time-tracking"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/aareon-connect",
    component: _0bb51e2a,
    name: "app-subdomain-property-bo-addons-integrations-integrations-aareon-connect"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/bpt",
    component: _3ac8104a,
    name: "app-subdomain-property-bo-addons-integrations-integrations-bpt"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/brunata-metrona",
    component: _64b4058e,
    name: "app-subdomain-property-bo-addons-integrations-integrations-brunata-metrona"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/caya",
    component: _6b06267e,
    name: "app-subdomain-property-bo-addons-integrations-integrations-caya"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/csn",
    component: _1c4c2ff3,
    name: "app-subdomain-property-bo-addons-integrations-integrations-csn"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/e-post",
    component: _c66007ba,
    name: "app-subdomain-property-bo-addons-integrations-integrations-e-post"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/hv-office",
    component: _4ea5ae10,
    name: "app-subdomain-property-bo-addons-integrations-integrations-hv-office"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/hv2000",
    component: _1deb93e7,
    name: "app-subdomain-property-bo-addons-integrations-integrations-hv2000"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/impower",
    component: _20bc6056,
    name: "app-subdomain-property-bo-addons-integrations-integrations-impower"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/incon",
    component: _56ab0d1c,
    name: "app-subdomain-property-bo-addons-integrations-integrations-incon"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/ista",
    component: _2d282d1c,
    name: "app-subdomain-property-bo-addons-integrations-integrations-ista"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/kalo",
    component: _17c6c698,
    name: "app-subdomain-property-bo-addons-integrations-integrations-kalo"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/managbl-ai",
    component: _46567cb4,
    name: "app-subdomain-property-bo-addons-integrations-integrations-managbl-ai"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/minol",
    component: _641eb1e4,
    name: "app-subdomain-property-bo-addons-integrations-integrations-minol"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/pantaenius",
    component: _2a3bc4e2,
    name: "app-subdomain-property-bo-addons-integrations-integrations-pantaenius"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/techem",
    component: _e9d6fc4e,
    name: "app-subdomain-property-bo-addons-integrations-integrations-techem"
  }, {
    path: "/app/:subdomain/property/bo/addons-integrations/integrations/villa",
    component: _2b009603,
    name: "app-subdomain-property-bo-addons-integrations-integrations-villa"
  }, {
    path: "/app/:subdomain/property/bo/inbound-emails/inboxes/:inbox",
    component: _6af181a7,
    name: "app-subdomain-property-bo-inbound-emails-inboxes-inbox"
  }, {
    path: "/app/:subdomain/property/bo/settings/connect/:link_id",
    component: _62565a14,
    children: [{
      path: "",
      component: _2578c8f9,
      name: "app-subdomain-property-bo-settings-connect-link_id-index"
    }, {
      path: "settings",
      component: _9c177f68,
      name: "app-subdomain-property-bo-settings-connect-link_id-index-settings"
    }, {
      path: ":object_id",
      component: _49eb4a70,
      name: "app-subdomain-property-bo-settings-connect-link_id-index-object_id"
    }]
  }, {
    path: "/app/:subdomain/property/bo/employees/:employee_id",
    component: _45462fa9,
    name: "app-subdomain-property-bo-employees-employee_id"
  }, {
    path: "/app/:subdomain/property/bo/incall/:number?",
    component: _583a04ab,
    name: "app-subdomain-property-bo-incall-number"
  }, {
    path: "/app/:subdomain/property/bo/object-groups/:group_id",
    component: _9c1d485e,
    children: [{
      path: "",
      component: _57f7fcd8,
      name: "app-subdomain-property-bo-object-groups-group_id-index"
    }, {
      path: "documents",
      component: _1cd9a67d,
      name: "app-subdomain-property-bo-object-groups-group_id-index-documents"
    }, {
      path: "edit",
      component: _7ab0c190,
      name: "app-subdomain-property-bo-object-groups-group_id-index-edit"
    }, {
      path: "events",
      component: _2f129a8a,
      name: "app-subdomain-property-bo-object-groups-group_id-index-events"
    }, {
      path: "faqs",
      component: _4d5b0a0e,
      name: "app-subdomain-property-bo-object-groups-group_id-index-faqs"
    }, {
      path: "processes",
      component: _8aa6563c,
      name: "app-subdomain-property-bo-object-groups-group_id-index-processes"
    }, {
      path: "todos",
      component: _72ea5b72,
      name: "app-subdomain-property-bo-object-groups-group_id-index-todos"
    }, {
      path: "communication/posts",
      component: _ad7285c2,
      name: "app-subdomain-property-bo-object-groups-group_id-index-communication-posts"
    }]
  }, {
    path: "/app/:subdomain/property/bo/objects/:object_id",
    component: _3e0fbac9,
    children: [{
      path: "",
      component: _1a6d468c,
      name: "app-subdomain-property-bo-objects-object_id-index"
    }, {
      path: "accounting",
      component: _3d52c3cb,
      name: "app-subdomain-property-bo-objects-object_id-index-accounting"
    }, {
      path: "addresses",
      component: _2b8c5f48,
      name: "app-subdomain-property-bo-objects-object_id-index-addresses"
    }, {
      path: "batch-upload",
      component: _308cd62a,
      name: "app-subdomain-property-bo-objects-object_id-index-batch-upload"
    }, {
      path: "communication",
      component: _1100b7da,
      name: "app-subdomain-property-bo-objects-object_id-index-communication"
    }, {
      path: "contact-persons",
      component: _032545e4,
      name: "app-subdomain-property-bo-objects-object_id-index-contact-persons"
    }, {
      path: "document-batches",
      component: _8db17322,
      name: "app-subdomain-property-bo-objects-object_id-index-document-batches"
    }, {
      path: "documents",
      component: _310b1575,
      name: "app-subdomain-property-bo-objects-object_id-index-documents"
    }, {
      path: "events",
      component: _02984e92,
      name: "app-subdomain-property-bo-objects-object_id-index-events"
    }, {
      path: "faqs",
      component: _514e2016,
      name: "app-subdomain-property-bo-objects-object_id-index-faqs"
    }, {
      path: "groups",
      component: _d0724ce6,
      name: "app-subdomain-property-bo-objects-object_id-index-groups"
    }, {
      path: "processes",
      component: _6243784c,
      name: "app-subdomain-property-bo-objects-object_id-index-processes"
    }, {
      path: "settings",
      component: _619575d9,
      name: "app-subdomain-property-bo-objects-object_id-index-settings",
      children: [{
        path: "general",
        component: _675a5632,
        name: "app-subdomain-property-bo-objects-object_id-index-settings-general"
      }, {
        path: "votings",
        component: _08755914,
        name: "app-subdomain-property-bo-objects-object_id-index-settings-votings"
      }]
    }, {
      path: "todos",
      component: _254bf32c,
      name: "app-subdomain-property-bo-objects-object_id-index-todos"
    }, {
      path: "unit-groups",
      component: _0d29238c,
      name: "app-subdomain-property-bo-objects-object_id-index-unit-groups"
    }, {
      path: "users",
      component: _5776e476,
      name: "app-subdomain-property-bo-objects-object_id-index-users"
    }, {
      path: "communication/conversations",
      component: _3f394558,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-conversations"
    }, {
      path: "communication/deliveries",
      component: _1abae2e4,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-deliveries"
    }, {
      path: "communication/issue-forms",
      component: _595f67bf,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-issue-forms"
    }, {
      path: "communication/posts",
      component: _7693d617,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-posts"
    }, {
      path: "communication/service-forms",
      component: _10e3e75b,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-service-forms"
    }, {
      path: "communication/votings",
      component: _2b89b0f0,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-votings"
    }, {
      path: "unit-groups/new",
      component: _73498c08,
      name: "app-subdomain-property-bo-objects-object_id-index-unit-groups-new"
    }, {
      path: "units/edit",
      component: _c10cbc3a,
      name: "app-subdomain-property-bo-objects-object_id-index-units-edit"
    }, {
      path: "units/new",
      component: _6db917bd,
      name: "app-subdomain-property-bo-objects-object_id-index-units-new"
    }, {
      path: "users/advisor",
      component: _15bc32f3,
      name: "app-subdomain-property-bo-objects-object_id-index-users-advisor"
    }, {
      path: "users/owner",
      component: _05737f26,
      name: "app-subdomain-property-bo-objects-object_id-index-users-owner"
    }, {
      path: "users/supplier",
      component: _3c7ef929,
      name: "app-subdomain-property-bo-objects-object_id-index-users-supplier"
    }, {
      path: "users/tenant",
      component: _2ae93647,
      name: "app-subdomain-property-bo-objects-object_id-index-users-tenant"
    }, {
      path: "communication/service-forms/:form_id",
      component: _5bb8edc1,
      name: "app-subdomain-property-bo-objects-object_id-index-communication-service-forms-form_id"
    }, {
      path: "document-batches/:batch_id",
      component: _2be7d082,
      name: "app-subdomain-property-bo-objects-object_id-index-document-batches-batch_id"
    }, {
      path: "unit-groups/:group_id",
      component: _8eee52b6,
      name: "app-subdomain-property-bo-objects-object_id-index-unit-groups-group_id"
    }, {
      path: "units/:unit_id",
      component: _71aebde0,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id"
    }, {
      path: "unit-groups/:group_id?/edit",
      component: _1c9b6407,
      name: "app-subdomain-property-bo-objects-object_id-index-unit-groups-group_id-edit"
    }, {
      path: "units/:unit_id?/documents",
      component: _c66bca0e,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-documents"
    }, {
      path: "units/:unit_id?/edit",
      component: _ce19bf88,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-edit"
    }, {
      path: "units/:unit_id?/contracts/:contract_id",
      component: _307daf68,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id"
    }, {
      path: "units/:unit_id?/contracts/:contract_id?/accounting",
      component: _05e5fa0b,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id-accounting"
    }, {
      path: "units/:unit_id?/contracts/:contract_id?/conversations",
      component: _20eeec66,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id-conversations"
    }, {
      path: "units/:unit_id?/contracts/:contract_id?/documents",
      component: _5f3fc735,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id-documents"
    }, {
      path: "units/:unit_id?/contracts/:contract_id?/edit",
      component: _58627d00,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id-edit"
    }, {
      path: "units/:unit_id?/contracts/:contract_id?/events",
      component: _1992165c,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id-events"
    }, {
      path: "units/:unit_id?/contracts/:contract_id?/processes",
      component: _05da14cc,
      name: "app-subdomain-property-bo-objects-object_id-index-units-unit_id-contracts-contract_id-processes"
    }]
  }, {
    path: "/app/:subdomain/property/fo/objects/:object_id",
    component: _dc9a4866,
    children: [{
      path: "",
      component: _690c9490,
      name: "app-subdomain-property-fo-objects-object_id-index"
    }, {
      path: "contact-persons",
      component: _33582312,
      name: "app-subdomain-property-fo-objects-object_id-index-contact-persons"
    }, {
      path: "conversations",
      component: _40af2f11,
      name: "app-subdomain-property-fo-objects-object_id-index-conversations"
    }, {
      path: "documents",
      component: _06f3ae79,
      name: "app-subdomain-property-fo-objects-object_id-index-documents"
    }, {
      path: "events",
      component: _0e49f40e,
      name: "app-subdomain-property-fo-objects-object_id-index-events"
    }, {
      path: "faqs",
      component: _6ee2f492,
      name: "app-subdomain-property-fo-objects-object_id-index-faqs"
    }, {
      path: "posts",
      component: _3b17b4d4,
      name: "app-subdomain-property-fo-objects-object_id-index-posts"
    }, {
      path: "processes",
      component: _b6724644,
      name: "app-subdomain-property-fo-objects-object_id-index-processes"
    }, {
      path: "units",
      component: _6d368520,
      name: "app-subdomain-property-fo-objects-object_id-index-units"
    }, {
      path: "votings",
      component: _42de5726,
      name: "app-subdomain-property-fo-objects-object_id-index-votings"
    }, {
      path: "conversations/report-issue",
      component: _64510658,
      name: "app-subdomain-property-fo-objects-object_id-index-conversations-report-issue"
    }, {
      path: "conversations/service-forms",
      component: _ea65444e,
      name: "app-subdomain-property-fo-objects-object_id-index-conversations-service-forms"
    }, {
      path: "conversations/service-forms/:service_form_id",
      component: _7be91ff9,
      name: "app-subdomain-property-fo-objects-object_id-index-conversations-service-forms-service_form_id"
    }, {
      path: "units/:unit_id",
      component: _7e8b0a8c,
      name: "app-subdomain-property-fo-objects-object_id-index-units-unit_id"
    }, {
      path: "units/:unit_id?/documents",
      component: _24784d16,
      name: "app-subdomain-property-fo-objects-object_id-index-units-unit_id-documents"
    }]
  }, {
    path: "/app/:subdomain/provider/bo/companies/:company_id",
    component: _370c48a0,
    children: [{
      path: "",
      component: _4c7ede33,
      name: "app-subdomain-provider-bo-companies-company_id-index"
    }, {
      path: "contact-persons",
      component: _817e7296,
      name: "app-subdomain-provider-bo-companies-company_id-index-contact-persons"
    }, {
      path: "conversations",
      component: _06bb4118,
      name: "app-subdomain-provider-bo-companies-company_id-index-conversations"
    }, {
      path: "documents",
      component: _6f06ef48,
      name: "app-subdomain-provider-bo-companies-company_id-index-documents"
    }, {
      path: "edit",
      component: _191f32b9,
      name: "app-subdomain-provider-bo-companies-company_id-index-edit"
    }, {
      path: "faqs",
      component: _eac78e62,
      name: "app-subdomain-provider-bo-companies-company_id-index-faqs"
    }, {
      path: "groups",
      component: _941caff4,
      name: "app-subdomain-provider-bo-companies-company_id-index-groups"
    }, {
      path: "processes",
      component: _664fb6c1,
      name: "app-subdomain-provider-bo-companies-company_id-index-processes"
    }, {
      path: "users",
      component: _768ce7a8,
      name: "app-subdomain-provider-bo-companies-company_id-index-users"
    }, {
      path: "objects/:object_id",
      component: _7531cf2e,
      name: "app-subdomain-provider-bo-companies-company_id-index-objects-object_id"
    }, {
      path: "objects/:object_id?/documents",
      component: _7821f712,
      name: "app-subdomain-provider-bo-companies-company_id-index-objects-object_id-documents"
    }, {
      path: "objects/:object_id?/edit",
      component: _35cbd9c3,
      name: "app-subdomain-provider-bo-companies-company_id-index-objects-object_id-edit"
    }, {
      path: "objects/:object_id?/processes",
      component: _15f52577,
      name: "app-subdomain-provider-bo-companies-company_id-index-objects-object_id-processes"
    }]
  }, {
    path: "/app/:subdomain/provider/bo/company-groups/:group_id",
    component: _5851573d,
    children: [{
      path: "",
      component: _045cbc00,
      name: "app-subdomain-provider-bo-company-groups-group_id-index"
    }, {
      path: "contact-persons",
      component: _59cb6a82,
      name: "app-subdomain-provider-bo-company-groups-group_id-index-contact-persons"
    }, {
      path: "documents",
      component: _8ce56c2e,
      name: "app-subdomain-provider-bo-company-groups-group_id-index-documents"
    }, {
      path: "edit",
      component: _4857e74c,
      name: "app-subdomain-provider-bo-company-groups-group_id-index-edit"
    }, {
      path: "faqs",
      component: _9c9c19bc,
      name: "app-subdomain-provider-bo-company-groups-group_id-index-faqs"
    }]
  }, {
    path: "/app/:subdomain/provider/bo/employees/:employee_id",
    component: _9bf90c76,
    name: "app-subdomain-provider-bo-employees-employee_id"
  }, {
    path: "/app/:subdomain/permalink/joinMeeting/:access_key?",
    component: _5c4feaea,
    name: "app-subdomain-permalink-joinMeeting-access_key"
  }, {
    path: "/app/:subdomain/permalink/notifications/:notification_id?",
    component: _7889b83c,
    name: "app-subdomain-permalink-notifications-notification_id"
  }, {
    path: "/app/:subdomain/public/forms/:form",
    component: _56a2b427,
    name: "app-subdomain-public-forms-form"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
