var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.DetailLayout,{attrs:{"no-fab":false,"parent":_vm.parent,"title":{
		icon: _setup.extBatch?.entityType ? _setup.ENTITY_TYPE_TO_ICON[_setup.extBatch.entityType] : 'fal fa-folder',
		text: _setup.extBatch?.title,
	}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(_setup.DeliveryDraftBatchActions,{attrs:{"delivery":_vm.delivery,"batch-id":_vm.batchId},on:{"edit":_setup.editDraft,"delete":function($event){return _vm.$emit('updatePage', null)}}}),_vm._v(" "),(!_setup.uploading)?_c(_setup.DeliveryDraftBatchTargets,{staticClass:"tw-mb-4",attrs:{"assignment-counts":_setup.targetAssignmentCounts,"targets":_vm.targets}}):_vm._e(),_vm._v(" "),_c(_setup.DeliveryDraftBatchErrors,{attrs:{"delivery-type":_setup.extBatch?.entityType,"assignment-counts":_setup.targetAssignmentCounts,"addressability-errors":_setup.serialLetter?.addressabilityErrors}})]},proxy:true},{key:"actions-fab",fn:function(){return [_c(_setup.DeliveryDraftBatchActions,{attrs:{"delivery":_vm.delivery,"batch-id":_vm.batchId},on:{"edit":_setup.editDraft,"delete":function($event){return _vm.$emit('updatePage', null)}}}),_vm._v(" "),(!_setup.uploading)?_c(_setup.DeliveryDraftBatchTargets,{staticClass:"tw-mb-4",attrs:{"assignment-counts":_setup.targetAssignmentCounts,"targets":_vm.targets}}):_vm._e(),_vm._v(" "),_c(_setup.DeliveryDraftBatchErrors,{attrs:{"delivery-type":_setup.extBatch?.entityType,"assignment-counts":_setup.targetAssignmentCounts,"addressability-errors":_setup.serialLetter?.addressabilityErrors}})]},proxy:true}])},[(_setup.extBatch)?_c(_setup.CategoryContainer,[_c('div',{staticClass:"tw-mb-6 tw-text-sm tw-uppercase tw-font-bold tw-text-text-softer"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('domain.delivery.batch.subtitle', {
					batchType: _vm.$t(`domain.delivery.batch.types.${_setup.extBatch?.batchType}`),
					roleType: _vm.$tc(`domain.user.role.${_setup.extBatch?.contractType}`, 2),
				}))+"\n\t\t\t•\n\t\t\t"),(_setup.extBatch?.entityType === _setup.DeliveryBatchEntityType.PdfBatch)?[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tc('domain.delivery.batch.filesCount', _setup.extBatch?.files?.length, {
						count: _setup.extBatch?.files?.length,
					}))+"\n\t\t\t")]:[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('domain.delivery.batch.writtenLetter'))+"\n\t\t\t")],_vm._v(" "),(_setup.extBatch?.createContractCopy)?[_vm._v("\n\t\t\t\t•\n\t\t\t\t"+_vm._s(_vm.$tc('domain.delivery.batch.edit.createContractCopy.title'))+"\n\t\t\t")]:_vm._e()],2),_vm._v(" "),(_setup.extBatch?.entityType === _setup.DeliveryBatchEntityType.PdfBatch)?_c(_setup.DeliveryDraftBatchPdf,_vm._b({ref:"pdfBatch"},'DeliveryDraftBatchPdf',{
				batchId: _vm.batchId,
				delivery: _vm.delivery,
				targetPropertyNameOptions: _vm.targetPropertyNameOptions,
				targets: _vm.targets,
				extBatch: _setup.extBatch,
			},false)):_c(_setup.DeliveryDraftBatchSerialLetter,_vm._b({ref:"serialLetter"},'DeliveryDraftBatchSerialLetter',{
				delivery: _vm.delivery,
				batchId: _vm.batchId,
				extBatch: _setup.extBatch,
			},false))],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }