
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import {
		ContactPerson,
		contactPersonResponsibilitySort,
		Provider,
		Responsibility,
	} from '~/app/domain/content/ContactPerson'
	import CategoryContainer from '~/components/containers/CategoryContainer.vue'
	import { addCategoryWithGroup, Category, CategoryGroup, groupResponsibilities } from '~/app/domain/content/Category'
	import ContactPersonResponsibilityCard from '~/components/domain/contact_person/ContactPersonResponsibilityCard.vue'
	import ContactPersonResponsibilitiesDialog from '~/components/domain/contact_person/ContactPersonResponsibilitiesDialog.vue'
	import ProviderListItem from '~/components/domain/provider/ProviderListItem.vue'
	import ContactInformation from '~/components/domain/contact/ContactInformation.vue'

	@Component({
		components: {
			ProviderListItem,
			ContactPersonResponsibilitiesDialog,
			ContactPersonResponsibilityCard,
			CategoryContainer,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
			ContactInformation,
		},
	})
	export default class ContactPersonResponsibilities extends Vue {
		@Model('change', { required: true }) person: ContactPerson
		@Prop({ default: () => [], type: Array }) providers: Provider[]
		@Prop({ required: true }) responsibilities: Responsibility[]
		@Prop({ required: true }) categories: Category[]
		@Prop({ required: true }) categoryGroups: CategoryGroup[]
		@Prop({ type: Boolean, default: false }) inline: boolean
		@Prop({ default: null }) nodeId: string
		@Prop({ default: () => [] }) nodes: Node[]

		get responsibilitiesWithProvider() {
			return this.responsibilities.map((res) => ({
				...res,
				provider: this.providers.find((provider) => provider.id === res.providerId),
			}))
		}

		get providersWithResponsibilities() {
			return this.providers
				.map((provider) => ({
					provider,
					responsibilities: this.responsibilities
						.filter((responsibility) => responsibility.providerId === provider.id)
						.map((responsibility) => ({
							...responsibility,
							category: this.categories.find((category) => category.id === responsibility.categoryId),
						})),
				}))
				.filter((p) => p.responsibilities.length > 0)
		}

		get groupedResponsibilities() {
			return groupResponsibilities(
				this.responsibilitiesWithProvider.filter((r) => {
					return r.contactPersonId === this.person.id
				}),
				this.categories,
				this.categoryGroups,
			).map((g) => {
				let st = null
				if (g.roleTypes.length) {
					st = `(${g.roleTypes.map((t) => this.$tc(`domain.user.role.${t}`, 2)).join(', ')})`
				}
				return {
					...g,
					subtitle: st,
				}
			})
		}

		get listedResponsibilities() {
			const result = []
			// dedupe
			for (const group of this.groupedResponsibilities) {
				for (const res of group.responsibilities) {
					const eRes = result.find((r) => {
						return r.category.title === res.category.title
					})
					if (!eRes) {
						result.push(res)
					}
				}
			}
			return result
		}

		get sortedResponsibilities(): Responsibility[] {
			const responsibilities = (this.responsibilitiesWithProvider as any)
				.map(addCategoryWithGroup(this.categories, this.categoryGroups))
				.map((r) => {
					const fTarget = this.targets.find((t) => {
						return t.id === r.nodeId
					})
					return {
						...r,
						target: fTarget,
					}
				})
			return contactPersonResponsibilitySort(responsibilities)
		}

		/**
		 * Add / edit logic
		 */
		editDialog: boolean = false
		newResponsibility: boolean = false
		editingResponsibility: any = null
		addNewResponsibility(provider: Provider) {
			this.newResponsibility = true
			this.editingResponsibility = {
				provider: provider.id,
				parentNode: this.nodeId || null,
				isInternal: false,
				job: null,
				note: '',
			}
			this.editDialog = true
		}

		editResponsibility(responsibility: Responsibility) {
			this.newResponsibility = false
			this.editingResponsibility = {
				id: responsibility.id,
				job: responsibility.categoryId,
				parentNode: !this.nodeId ? responsibility.nodeId : undefined,
				isInternal: responsibility.isInternal,
				note: responsibility.note,
				provider: responsibility.providerId,
			}
			this.editDialog = true
		}

		get targets() {
			let result = []
			for (const g of this.isProvider
				? [
						{
							data: this.companies,
							type: this.$tc('domain.company.base', 1),
							icon: 'company',
						},
				  ]
				: [
						{
							data: this.objects,
							type: this.$tc('domain.object.base', 1),
							icon: 'object',
						},
				  ]) {
				result = result.concat(
					(g.data || []).map((c) => {
						return {
							...c,
							id: c.id,
							type: g.type,
							icon: g.icon,
						}
					}),
				)
			}
			return result
		}

		responsibilityChanged(pl) {
			this.$emit('updated', pl)
		}

		responsibilityRevoked(resId) {
			this.$emit('revoked', resId)
		}

		/**
		 * Provider / Property Dependencies
		 */
		get companies() {
			if (!this.isProvider) return []
			return this.$store.getters['provider/companies/all']
		}

		get companyGroups() {
			if (!this.isProvider) return []
			return this.$store.getters['provider/companyGroups/all']
		}

		get objects() {
			if (this.isProvider) return []
			return this.$store.getters['property/objects/all']
		}

		get objectGroups() {
			if (this.isProvider) return []
			return this.$store.getters['property/objectGroups/all']
		}

		get isProvider() {
			return this.$store.getters['routing/isProvider']
		}
	}
