
	import Image from '~/app/utils/globals/Image'
	import Messages from '~/app/utils/globals/Messages'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop } from 'nuxt-property-decorator'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import { reduceToFrontendRoleTypes } from '~/app/domain/identifyAndAccess/Role'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import { Event } from '~/app/domain/content/Event'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import { translateContractSelectionSummary } from '~/app/domain/property/Contract'
	import UserNotifications from '~/components/domain/process/UserNotifications.vue'
	import ContentLocationAction from '~/components/domain/location/ContentLocationAction.vue'
	import { resolveApiLink } from '~/app/utils/apiLinks'

	@Component({
		components: {
			ContentLocationAction,
			UserNotifications,
			BasicDialog,
			ActionButton,
			ConfirmationDialog,
			ListSelector,
			ProcessList: () => import('~/components/domain/process/ProcessList.vue'),
		},
	})
	export default class EventActions extends Vue {
		@Model('change', { required: true }) event: Event
		@Prop({ default: null }) baseUrl: string
		@Prop({ default: false }) dark: boolean
		@Prop({ type: Array, default: () => [] }) nodes: Node[]

		Image = Image

		get actions() {
			if (!this.baseUrl) return []
			return [
				{
					id: 'edit',
					icon: 'fal fa-pencil',
					size: 6,
					callback: () => {
						this.$emit('changePage', `eventEdit_${this.event.id}`)
					},
					attributes: {
						'data-qa': 'event-actions:edit',
					},
				},
				{
					id: 'cancel',
					callback: () => {
						if (!this.event.isCancelled) this.cancelDialog = true
						else this.deleteDialog = true
					},
					icon: this.event.isCancelled ? 'fal fa-trash' : 'fal fa-times',
					color: 'error',
					size: 6,
					attributes: {
						'data-qa': this.event.isCancelled ? 'event-actions:delete' : 'event-actions:cancel',
					},
				},
			]
		}

		get downloadIcsAction() {
			return {
				id: 'download-ics',
				href: resolveApiLink(this.$store, 'etg24:internal:query:content.events.ical', { event: this.event.id }),
				text: this.$t('domain.event.downloadIcs'),
				icon: 'fal fa-download',
				attributes: {
					'data-qa': 'event-actions:download-ics',
				},
			}
		}

		get roles() {
			return reduceToFrontendRoleTypes(this.$store.getters['tenant/roleTypes']).map((r) => {
				return {
					text: this.$tc(`domain.user.role.${r}`, 2),
					value: r,
				}
			})
		}

		/**
		 * Cancel logic
		 */
		cancelDialog = false
		async cancelEvent() {
			try {
				const response = (await this.$axios.$post('etg24:internal:command:content.events.cancel', {
					event: this.event.id,
				})) as { event: Event }
				this.$emit('eventUpdated', response.event)
				this.cancelDialog = false
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		/**
		 * Delete logicÓ
		 */
		deleteDialog = false
		async deleteEvent() {
			try {
				await this.$axios.$post('etg24:internal:command:content.delete', {
					ids: [this.event.id],
				})
				this.$emit('deleted')
			} catch (e) {
				await this.$store.dispatch('snackbar/error', Messages.format.call(this, e))
			}
		}

		/**
		 * Link to process
		 */
		get linkToProcessBtn() {
			return {
				icon: Image.getIconForDomain('process'),
				text: this.event.process ? this.event.process.title : this.$t('domain.process.actions.linkToProcess'),
				callback: async () => {
					if (this.event.process) {
						// open process if linked
						this.$router.push({
							query: {
								...this.$route.query,
								contentType: 'process',
								contentId: this.event.process.id,
							},
						})
					} else {
						// open process linking dialog if unlinked
						this.linkToProcessDialog = true
					}
				},
				color: 'primary',
				attributes: {
					'data-qa': 'event-actions:link-to-process',
				},
			}
		}

		linkToProcessDialog = false

		linkingProcess = false
		async selectProcess(process) {
			this.linkingProcess = true
			try {
				const response = await this.$axios.$post('etg24:internal:command:content.events.linktoprocess', {
					event: this.event.id,
					process: process?.id || null,
				})
				this.$emit('eventUpdated', response.event)
				this.linkToProcessDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.linkingProcess = false
		}

		newProcess() {
			this.$router.push({
				query: {
					...this.$route.query,
					contentId: undefined,
					contentType: undefined,
					newContent: 'process',
					newContentId: this.event.nodeId,
					newContentEvent: this.event.id,
				},
			})
		}

		get visibilityData() {
			let selected = []
			if (this.event?.contractIds?.length) {
				selected = [...selected, 'contracts']
			}
			selected = [...selected, ...this.event.roleTypes]
			return {
				selected,
				options: [
					{
						text: translateContractSelectionSummary(
							// @ts-ignore
							this.event.contractSummary,
							this,
						),
						value: 'contracts',
					},
					...this.roles,
				],
			}
		}
	}
