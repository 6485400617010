import { AxiosError, AxiosResponse } from 'axios'

export interface IMessages {
	format(inMsg: AxiosError | AxiosResponse, options?: MessageOptions): string
}

interface MessageOptions {
	domain?: string
	data?: any
}

export default {
	/**
	 * Formats an error
	 * @param error
	 * @param options
	 */
	format(inMsg: AxiosError | AxiosResponse, options?: MessageOptions): string {
		const msgTrans = inMsg as any
		const fOptions = options || {}
		const response = msgTrans.status ? inMsg : msgTrans.response
		if (!response) return msgTrans.message || msgTrans

		// 1: check if the payload has an error message prop
		const plMsg = (response.data || {}).errorMessage
		if (plMsg && this.$te(plMsg)) return this.$t(plMsg)

		// 2: if the domain is supplied, check if we have an specific error for it
		const status = response.status
		if (fOptions.domain) {
			const eStr = `messages.${fOptions.domain}.${status}`
			if (this.$te(eStr)) return this.$t(eStr)
		}

		// 3: return the default string for those errors
		return this.$t(`errors.statusCode.${status}.title`)
	},
}
