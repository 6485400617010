
	import Image from '~/app/utils/globals/Image'
	import Utils from '~/app/utils/globals/Utils'
	import { Component, Vue, Model, Prop, Watch } from 'nuxt-property-decorator'
	import { Conversation, getMessages } from '~/app/domain/communication/Conversation'

	import { Button } from '~/app/common/types/Button'
	import ActionButton from '~/components/inputs/ActionButton.vue'
	import ListSelector from '~/components/inputs/ListSelector.vue'
	import ConfirmationDialog from '~/components/utils/ConfirmationDialog.vue'
	import BasicDialog from '~/components/inputs/BasicDialog.vue'
	import NotesList from '~/components/domain/notes/NotesList.vue'
	import { getNodeBreadcrumbs, getNode, NodeType, findClosestAncestorOfType } from '~/app/domain/content/Common'
	import { getContextNode } from '~/app/domain/utility/Routes'
	import InfoContainer from '~/components/containers/InfoContainer.vue'
	import SupportCard from '~/components/domain/support/SupportCard.vue'
	import GreyCard from '~/components/containers/GreyCard.vue'
	import UnitContractPicker from '~/components/domain/unit/UnitContractPicker.vue'
	import ProcessList from '~/components/domain/process/ProcessList.vue'
	import SfDate from '~/components/domain/conversation/service_forms/blocks/SfDate.vue'
	import SfTime from '~/components/domain/conversation/service_forms/blocks/SfTime.vue'
	import DateComponent from '~/components/utils/Date.vue'
	import { PRECISION_MINUTE } from '~/app/common/filters/Date'
	import SearchInput from '~/components/inputs/SearchInput.vue'
	import ContentLocationAction from '~/components/domain/location/ContentLocationAction.vue'
	import WaitUntilInput from '~/components/inputs/WaitUntilInput.vue'
	import EmployeeSelectBtn from '~/components/domain/employee/EmployeeSelectBtn.vue'

	@Component({
		components: {
			EmployeeSelectBtn,
			WaitUntilInput,
			ContentLocationAction,
			SearchInput,
			SfTime,
			SfDate,
			ProcessList,
			UnitContractPicker,
			GreyCard,
			SupportCard,
			InfoContainer,
			NotesList,
			BasicDialog,
			ConfirmationDialog,
			ListSelector,
			ActionButton,
			date: DateComponent,
		},
	})
	export default class ConversationActions extends Vue {
		@Model('change', { required: true }) conversation: Conversation
		@Prop({ type: Array, default: () => [] }) nodes
		@Prop({ default: false }) editable: boolean
		@Prop({ default: false }) dark: boolean
		@Prop({ default: null }) baseUrl: string

		Image = Image

		PRECISION_MINUTE = PRECISION_MINUTE

		@Watch('conversation', { immediate: true }) updateConversation() {
			if (this.conversation?.waitUntil) {
				this.snoozeDate = this.$dateFns.format(
					this.$dateFns.parseISO(this.conversation.waitUntil),
					'yyyy-MM-dd',
				)
				this.snoozeTime = this.$dateFns.format(this.$dateFns.parseISO(this.conversation.waitUntil), 'HH:mm')
			}
		}

		get nodeId() {
			return findClosestAncestorOfType(
				this.conversation.nodeId,
				this.nodes,
				NodeType['propertymanagement.object'],
			)?.id
		}

		get object() {
			return findClosestAncestorOfType(
				this.conversation.nodeId,
				this.nodes,
				NodeType['propertymanagement.object'],
			)
		}

		get actions(): Button[] {
			let result = []
			if (this.editable && this.conversation) {
				result = result.concat([
					{
						icon: 'fal fa-pencil',
						size: 6,
						callback: () => {
							this.$emit('changePage', `editConversation_${this.conversation.id}`)
						},
						attributes: { 'data-qa': 'conversation-actions:edit' },
					},
				])
			}
			return result
		}

		/**
		 * Toggle conversation
		 * @param item
		 */
		get openDoneToggleBtn() {
			return {
				text: this.$t(
					this.conversation.isOpen
						? 'domain.conversation.mark.resolved'
						: 'domain.conversation.mark.unresolved',
				),
				callback: this.toggleConversationOpen,
				icon: this.conversation.isOpen ? 'fal fa-check' : 'fal fa-check-circle',
				color: 'primary',
				attributes: {
					'data-qa': 'conversation-actions:is-open',
				},
			}
		}

		async toggleConversationOpen() {
			if (!this.conversation) return
			const open = this.conversation.isOpen
			try {
				const response = await this.$axios.$post(
					`etg24:internal:command:communication.conversations.${!open ? 'reopen' : 'close'}?conversation=${
						this.conversation.id
					}`,
				)
				this.$emit('change', response)
				this.$emit('toggleOpen', response)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Snooze
		 */
		get snoozeBtn() {
			if (!this.conversation) return
			return {
				callback: () => {
					this.snoozeDialog = true
				},
				icon: 'fal fa-snooze',
				color: 'primary',
			}
		}

		today: string = this.$dateFns.format(new Date(), 'yyyy-MM-dd')
		currentTime: string = this.$dateFns.format(new Date(), 'HH:mm')

		snoozeDialog: boolean = false
		snoozeDate: string = this.$dateFns.format(this.$dateFns.addDays(new Date(), 3), 'yyyy-MM-dd')

		snoozeTime: string = '07:00'
		snoozing: any = {
			remove: false,
			set: false,
		}

		async snooze(waitUntil) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:communication.conversations.update', {
					conversation: {
						id: this.conversation.id,
						waitUntil,
					},
				})
				this.$emit('change', response)
				this.$emit('updateNodes', response.nodes)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Location
		 */
		get contextNodeId() {
			return getContextNode(this.$route) || this.$store.getters['tenant/rootNodeId']
		}

		get breadcrumbs() {
			if (!this.nodes.length) return
			const bc = getNodeBreadcrumbs(this.contextNodeId, this.conversation.nodeId, this.nodes)
			return bc?.length ? bc : [{ title: this.object?.title }]
		}

		get nodeType() {
			if (!this.nodes.length) return
			return getNode(this.conversation.nodeId, this.nodes)?.type
		}

		moveConversationDialog = false
		openMoveDialog() {
			if (this.object?.id) {
				this.moveConversationDialog = true
			}
		}

		movingToObject: boolean = false
		get moveCategories() {
			return [
				{
					icon: Image.getIconForDomain('object'),
					text: this.$t('domain.object.moveToObject'),
					loading: this.movingToObject,
					callback: async () => {
						const node = findClosestAncestorOfType(
							this.conversation.nodeId,
							this.nodes,
							NodeType['propertymanagement.object'],
						)
						await this.move(node.id)
						this.movingToObject = false
					},
				},
				{
					icon: Image.getIconForDomain('contract'),
					text: this.$t('domain.contract.moveToContract'),
					callback: () => {
						this.moveConversationDialog = false
						this.contractSelectDialog = true
					},
				},
			]
		}

		contractSelectDialog: boolean = false
		selectContractSearchText: string = ''
		async move(id) {
			try {
				this.movingToObject = true
				const response = await this.$axios.$post('etg24:internal:command:communication.conversations.move', {
					conversation: this.conversation.id,
					node: id,
				})
				this.$emit('change', response)
				this.$emit('updateNodes', response.nodes)
				this.moveConversationDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.movingToObject = false
			this.contractSelectDialog = false
		}

		/**
		 * Link to process
		 */
		get linkToProcessBtn() {
			return {
				icon: Image.getIconForDomain('process'),
				'white--text': this.dark,
				text: this.conversation.process
					? this.conversation.process.title
					: this.$t('domain.process.actions.linkToProcess'),
				callback: async () => {
					if (this.conversation.process) {
						// open process if linked
						await this.$router.push({
							query: {
								...this.$route.query,
								contentType: 'process',
								contentId: this.conversation.process.id,
							},
						})
					} else {
						// open process linking dialog if unlinked
						this.linkToProcessDialog = true
					}
				},
				color: 'primary',
				attributes: {
					'data-qa': 'conversation-actions:link-to-process',
				},
			}
		}

		linkToProcessDialog: boolean = false

		linkingProcess: boolean = false
		async selectProcess(process) {
			this.linkingProcess = true
			try {
				const response = await this.$axios.$post(
					'etg24:internal:command:communication.conversations.linktoprocess',
					{
						conversation: this.conversation.id,
						process: process?.id || null,
					},
				)
				this.$emit('change', response)
				this.linkToProcessDialog = false
			} catch (e) {
				Utils.handleError(e)
			}
			this.linkingProcess = false
		}

		newProcess() {
			this.$router.push({
				query: {
					...this.$route.query,
					contentId: undefined,
					contentType: undefined,
					newContent: 'process',
					newContentId: findClosestAncestorOfType(
						this.conversation.nodeId,
						this.nodes,
						NodeType['propertymanagement.object'],
					)?.id,
					newContentConversation: this.conversation.id,
				},
			})
		}

		/**
		 * Delete
		 */
		get canBeDeleted() {
			if (this.$store.getters['features/isEnabled']('support:superUser')) return true
			return getMessages(this.conversation)?.length === 0
		}

		deleteDialog: boolean = false

		async deleteConversation() {
			try {
				await this.$axios.$post('etg24:internal:command:communication.conversations.delete', {
					conversation: this.conversation.id,
					force: !!this.$store.getters['features/isEnabled']('support:superUser') || undefined,
				})
				this.$emit('deleted')
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Watchers
		 */
		async setWatchers(items) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:communication.conversations.update', {
					conversation: {
						id: this.conversation.id,
						watchers: items,
					},
				})
				this.$emit('change', response)
			} catch (e) {
				Utils.handleError(e)
			}
		}

		/**
		 * Asignees
		 */
		async setAssignees(items) {
			try {
				const response = await this.$axios.$post('etg24:internal:command:communication.conversations.update', {
					conversation: {
						id: this.conversation.id,
						assignees: items,
					},
				})
				this.$emit('change', response)
			} catch (e) {
				Utils.handleError(e)
			}
		}
	}
